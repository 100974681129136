import React from 'react';
import { l } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';

import LastSignAtBannerContainer from './styles';

const LastSignAtBanner = () => {
  const [session] = useReduxQuery('SESSION');

  const parsedDate = session?.user.lastSignInAt
    ? l('date.formats.medium', session.user.lastSignInAt)
    : '...';

  return (
    <LastSignAtBannerContainer>
      <p>
        Bienvenido
        {' '}
        <span>{session?.user?.fullName ?? '--'}</span>
        |
      </p>
      <p>
        Último inicio de sesión:
        {' '}
        <span>{parsedDate}</span>
      </p>
    </LastSignAtBannerContainer>
  );
};

export default LastSignAtBanner;
