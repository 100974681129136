import { useState } from 'react';
import { useReduxQuery } from 'utils/redux-query';

export const useController = () => {
  const [session] = useReduxQuery('SESSION');
  const needsUpdateFiscalInformation = session?.settings?.needsUpdateFiscalInformation;
  const [isOpen, setOpen] = useState(false);
  const [isActiveInvoicingOpen, setActiveOpen] = useState(false);
  const openForm = () => setOpen(true);
  const closeForm = () => setOpen(false);
  const openActiveInvoicingModal = () => {
    if (needsUpdateFiscalInformation || needsUpdateFiscalInformation === undefined) openForm();
    else setActiveOpen(true);
  };
  const closeActiveInvoicingModal = () => setActiveOpen(false);

  return {
    isOpen,
    openForm,
    closeForm,
    isActiveInvoicingOpen,
    openActiveInvoicingModal,
    closeActiveInvoicingModal,
  };
};
