import React from 'react';
import { t } from 'i18n';
import { Box, Flex } from 'styles';
import { Heading, CardLoader } from 'components';
import { ConditionsAccepted, ConditionsDescription, SignedCER } from './components';
import { useController } from './hooks/useController';
import styles from './Confirm.style';

const ts = (key) => t(key, { scope: 'components.CER.Confirm' });

export const Confirm = () => {
  const {
    step,
    riskList,
    isSavingRisk,
    isLoadingRiskList,
    acceptTermHandler,
    goToDashboardHandler,
  } = useController();

  if (riskList.signed_at) {
    return <SignedCER onGoToDashboard={goToDashboardHandler} />;
  }

  return (
    <Flex {...styles.container}>
      <Box>
        <Heading as="h3" {...styles.title}>
          {ts('title')}
        </Heading>
      </Box>

      {isLoadingRiskList && (
        <Box {...styles.loaderContainer}>
          <CardLoader style={{ width: '100%' }} />
        </Box>
      )}

      {!isLoadingRiskList && (
        <>
          <Box {...styles.termsCard}>
            <ConditionsAccepted stepCounter={step} />
            <ConditionsDescription
              confirmPasswordStackedStyle
              stepCounter={step}
              isSubmitting={isSavingRisk}
              onStepChange={acceptTermHandler}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};
