import { gql } from '@apollo/client';
import { useMutation, useQuery } from 'hooks/apollo';

export const BENEFICIARY_GQL = gql`
query Query {
  user {
    id
    donee {
      fullName
      address {
        zip
        country
        state {
          id
        }
      }
    }
  }
}
`;

export const useBeneficiaryQuery = (options) => useQuery(BENEFICIARY_GQL, options);

export const REMOVE_BENEFICIARY_GQL = gql`
mutation ($input: RemoveDoneeMutationInput!) {
  removeDonee(input: $input) {
    user {
      id
      donee {
        fullName
        relationType
        relationTypeOther
        birthDate
        address {
          city
          country
          extNumber
          intNumber
          municipality
          neighborhood
          state {
            name
          }
          street
          zip
        }
      }
    }
  }
}
`;

export const useRemoveBeneficiary = (options) => useMutation(REMOVE_BENEFICIARY_GQL, options);
