import React from 'react';
import { t } from 'i18n';
import {
  Td,
  Th,
  Tr,
  Text,
  Table,
  Tbody,
  Thead,
  DeleteIcon,
  IconButton,
  TableContainer,
  Container,
} from 'ui';
import DotsLoader from 'components/DotsLoader';
import { useController } from './hooks/useController';
import { style } from './BanksTable.style';
import { StatusIndicator } from './components/StatusIndicator';
import { EditableClabeAlias } from './components/EditableClabeAlias/EditableClabeAlias';

export const BanksTable = (props) => {
  const {
    setCurrentBank,
    setOpenDeleteModal,
  } = props;

  const {
    loading,
    bankAccounts,
    handleOpenDeleteModal,
  } = useController({
    setCurrentBank,
    setOpenDeleteModal,
  });

  if (loading) return (<DotsLoader />);

  if (!bankAccounts?.length) return (null);

  return (
    <TableContainer padding={2}>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th {...style.nameHead}>
              <Text {...style.text}>{t('settings.bankAccounts.tableHeaders.0')}</Text>
            </Th>
            <Th {...style.bankHead}>
              <Text {...style.text}>{t('settings.bankAccounts.tableHeaders.1')}</Text>
            </Th>
            <Th {...style.clabeHead}>
              <Text {...style.text}>{t('settings.bankAccounts.tableHeaders.2')}</Text>
            </Th>
            <Th {...style.statusHead}>
              <Text {...style.text}>{t('settings.bankAccounts.tableHeaders.3')}</Text>
            </Th>
            <Th {...style.actionsHead} />
          </Tr>
        </Thead>
        <Tbody>
          {bankAccounts?.map((bankAccount) => (
            <Tr key={bankAccount.id}>
              <Td {...style.nameHead}>
                <EditableClabeAlias
                  bankAccount={bankAccount}
                />
              </Td>
              <Td
                {...style.tableCells}
                display={{ base: 'none', sm: 'table-cell' }}
              >
                <Text {...style.text}>
                  {bankAccount.bank}
                </Text>
              </Td>
              <Td
                {...style.tableCells}
              >
                <Text style={{ direction: 'rtl' }} {...style.text} {...style.clabeText}>
                  {bankAccount.clabe}
                </Text>
              </Td>
              <Td
                {...style.tableCells}
                display={{ base: 'none', sm: 'table-cell' }}
              >
                <StatusIndicator status={bankAccount.status} />
              </Td>
              <Td {...style.tableCells}>
                <Container {...style.iconsContainer}>
                  <IconButton
                    {...style.icon}
                    data-testid="delete-bank-button"
                    aria-label={t('settings.bankAccounts.delete.button')}
                    isDisabled={bankAccount.isDisabled}
                    onClick={() => handleOpenDeleteModal(bankAccount)}
                    icon={<DeleteIcon />}
                  />
                </Container>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
