import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const { largeBreakPoint, midBreakPoint, smallBreakPoint } = breakPoints;

const containerDiv = (props) => <div {...props} />;

const DailyIncomesContainer = styled(containerDiv)`
  width: 100%;
  min-height: 100%;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  @media (max-width: ${midBreakPoint}px) {
    background: #f8fafc;
    padding-top: 30px;
  }
  @media (max-width: ${smallBreakPoint}px) {
    padding-top: 14px;
  }
  .calendar-circle {
    display: flex;
    width: 40px;
    height: 40px;
    background: white;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 4px 9px 0 rgba(37, 103, 141, 0.07);
    display: none;
    @media (max-width: ${smallBreakPoint}px) {
      display: flex;
    }
  }
  .day-header {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    .title-short {
      display: none;
    }
    @media (max-width: ${smallBreakPoint}px) {
      .title-large {
        display: none;
      }
      .title-short {
        display: block;
        font-size: 20px;
        text-transform: capitalize;
      }
    }
    .group {
      display: flex;
      justify-content: space-between;
      .arrows {
        display: flex;
        align-items: baseline;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 83px;
        .left-arrow,
        .right-arrow {
          cursor: pointer;
        }
      }
      .left-arrow {
        margin-right: 9px;
        transform: scaleX(-1);
      }
    }
  }
  .day-items {
    display: grid;
    grid-gap: 26px;
    padding-left: 16px;
    padding-right: 16px;
    grid-template-columns: repeat(6, minmax(0, auto));
    @media (max-width: ${largeBreakPoint}px) {
      grid-template-columns: repeat(4, minmax(0, auto));
    }

    @media (max-width: ${midBreakPoint}px) {
      grid-template-columns: repeat(3, minmax(0, auto));
      grid-gap: 12px;
    }
  }
  .day-item {
    user-select: none;
    font-size: 18px;
    font-weight: 600;
    color: ${colors.gray.g6};
    line-height: 22px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.03);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 10px 15px 10px;
    transition: all 0.3s ease;
    @media (max-width: ${smallBreakPoint}px) {
      font-size: 14px;
      padding: 10px;
    }
    &:hover {
      background-color: #0076ff;
      color: white;
      cursor: pointer;
      transform: translateY(-2px);
      box-shadow: -1px 13px 27px -16px rgba(1, 46, 71, 0.75);
    }
  }
`;

export default DailyIncomesContainer;
