const endpoints = {
  public: {
    appStoreAppUrl: 'https://apps.apple.com/app/yotepresto-inversionistas/id1488957971?mt=8',
    playStoreAppUrl:
      'https://play.google.com/store/apps/details?id=com.yotepresto.investor&hl=es_419',
    aboutOrders: 'https://centrodeayuda.yotepresto.com/hc/es-419/articles/360057737451',
  },
};

export default endpoints;
