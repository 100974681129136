import { isDevelopment } from 'config';

let records = {};

if (isDevelopment()) {
  window.debug_query_records = records;
}

export const registerQuery = (keystore, query) => {
  const queryRecord = records[keystore];
  records[keystore] = {
    ...queryRecord,
    query,
  };
};

export const getQuery = (keystore) => {
  if (!records[keystore]) return null;

  return records[keystore].query;
};

export const getQueryRecord = (keystore) => records[keystore];

export const setLoadQueryTimestamp = (keystore, timestamp) => {
  const queryRecord = records[keystore];

  records[keystore] = {
    ...queryRecord,
    initializedAt: timestamp,
  };
};

export const invalidateQueryRecords = (keystores = []) => {
  keystores.forEach((keystore) => {
    const record = records[keystore];

    if (keystore) {
      record.initializedAt = null;
    }
  });
};

export const resetCache = () => {
  records = Object
    .entries(records)
    .reduce(
      (acc, [keystore, record]) => ({ ...acc, [keystore]: { ...record, initializedAt: null } }), {},
    );
};
