import React from 'react';
import styled, { css } from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';
import getStatusColors from '../../utils';

const { midBreakPoint } = breakPoints;

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const headerColor = '#93AFB9';
export const payedColor = colors.green;

const barSharedStyles = css`
  align-items: center;
  justify-content: center;
  & .progressbar {
    margin-left: 1rem;
    width: 2.5rem;
  }
`;

const PortfolioListContainer = styled(ContainerDiv)`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  & .portfolio {
    &__list {
      display: table;
      border-collapse: separate;
      border-spacing: 0px 1.5rem;
      font-size: 1.4rem;
      width: 90%;
      &__header {
        display: flex;
        justify-content: space-between;
        color: ${headerColor};
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }
      &__body {
        width: 100%;
        transition: all 0.3s ease;
        display: block;
        &:hover {
          & .portfolio__list__body__item {
            opacity: 0.6;
            &:hover {
              opacity: 1;
            }
          }
        }
        .table__row {
          display: flex;
        }
        &__item {
          transition: all 0.3s ease;
          display: flex;
          background-color: white;
          justify-content: space-evenly;
          border-radius: 0.6rem;
          box-shadow: 0 4px 3px 0 rgba(37, 103, 141, 0.03);
          margin-bottom: 1.5rem;
          &:hover {
            cursor: pointer;
          }
          & .list__item {
            height: 5.2rem;
            color: #04475c;
            font-weight: 500;
            font-size: 1.3rem;
            text-align: center;
            @media (max-width: ${midBreakPoint}px) {
              font-size: 1rem;
            }
            &:first-child {
              border-top-left-radius: 0.6rem;
              border-bottom-left-radius: 0.6rem;
            }
            &:last-child {
              border-top-right-radius: 0.6rem;
              border-bottom-right-radius: 0.6rem;
            }
            & .cell__content {
              justify-content: center;
              align-items: center;
              display: flex;
              height: 100%;
            }
            &.product_type {
              & span.type {
                font-size: 1.2rem;
                border-radius: 0.6rem;
                width: auto;
                border: 0;
                background: ${colors.gray.g2};
                font-weight: 600;
              }
            }
            &.status {
              font-size: 1.2rem;
              min-width: 92px;
            }
            &.lent {
              color: #346071;
            }
            &.payed {
              .cell__content {
                ${barSharedStyles}
                color: ${colors.green};
                justify-content: flex-end;
              }
            }
            &.owes {
              .cell__content {
                ${barSharedStyles}
              }
            }
            &.user {
              color: #93afb9;
              font-weight: normal;
            }
            &.qualification {
              color: #346071;
              & .qualification__text {
                font-size: 1.5rem;
                font-weight: 500;
                margin-right: 1rem;
              }
              & .qualification__percentage {
                font-size: 1.4rem;
                font-weight: 400;
              }
            }
            &.term .cell__content {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
`;

export const ListItem = styled.div`
  text-decoration: none;
  .payed,
  .status {
    color: ${(props) => getStatusColors(props.status)};
  }
  .lent,
  .owes,
  .qualification__text,
  .qualification__percentage,
  .term {
    color: #186a83 !important;
  }

  .lent,
  .qualification__text {
    font-weight: bold !important;
  }
`;

export default PortfolioListContainer;
