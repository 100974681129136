import React from 'react';
import styled from 'styled-components';
import {
  Flex,
  boxHelpers,
} from 'styles';
import { Panel } from '../Panel';
import Button from '../Button/Button';
import Modal, { CloseButton } from '../Modal';

export const ModalContent = styled(Modal)`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  max-width: 420px !important;
  box-sizing: border-box;
  position: relative;

  ${boxHelpers}
`;

export const AlertDialog = (props) => {
  const {
    body,
    title,
    isOpen,
    onClose,
    actions,
  } = props;

  return (
    <ModalContent
      isOpen={isOpen}
      onRequestClose={onClose}
      {...props}
    >
      <CloseButton onClick={onClose} />
      <Panel
        title={title}
        textHeaderProps={{ textAlign: 'center' }}
        footerComponent={(
          <Flex
            gap="1rem"
            justifyContent="center"
          >
            {actions.map(({
              id,
              text,
              variant,
              onClick,
              colorSchema,
              ...other
            }) => (
              <Button
                {...other}
                key={id}
                $type={variant}
                onClick={onClick}
                schema={colorSchema}
              >
                {text}
              </Button>
            ))}
          </Flex>
        )}
      >
        {body}
      </Panel>
    </ModalContent>
  );
};
