import { ZIPCODE_LENGTH } from 'config/constants';

export const getIsAddressCompleted = (address) => {
  if (!address) return null;
  const {
    zip,
    city,
    street,
    stateId,
    extNumber,
    neighborhood,
    municipality,
  } = address;
  const isZipValid = zip && zip.length === ZIPCODE_LENGTH;
  return [
    zip,
    isZipValid,
    city,
    street,
    stateId,
    extNumber,
    municipality,
    neighborhood,
  ].every(Boolean);
};
