import { useMemo } from 'react';
import { features } from 'config/constants';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { useExpedientFlag } from './useExpedientFlag';
import { getCerFlag, getDiversificationFlag, getPageFlags } from './utils';

export const useFeatureFlags = () => {
  const [session] = useReduxQuery('SESSION');
  const [personalArchives] = useReduxQuery('PERSONAL_ARCHIVES');
  const [, { isCompleted: isDiversificationCompleted }] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');

  const { needsUpdateInformation } = useExpedientFlag({ session, personalArchives });
  const diversificationFlag = getDiversificationFlag({ session, isDiversificationCompleted });
  const cerFlag = getCerFlag({ session, needsUpdateInformation });
  const pageFlags = useMemo(() => getPageFlags({ session }), [session]);

  const featureFlags = useMemo(
    () => ({
      ...features,
      needsUpdateInformation,
      cer: cerFlag,
      diversification: diversificationFlag,
      ...pageFlags,
    }),
    [
      cerFlag,
      pageFlags,
      diversificationFlag,
      needsUpdateInformation,
    ],
  );
  return featureFlags;
};
