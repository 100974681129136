import { getAppSubdirectory } from 'config';
import { createPathHelpers } from 'utils/router/createPaths';

const subDirectory = getAppSubdirectory();

export const rootPath = `${subDirectory}/`;
export const loginRoute = `${subDirectory}/public/login`;
export const dashboardRoute = `${subDirectory}/dashboard`;
export const investorTransactions = `${subDirectory}/transactions`;
export const investorCalendar = `${subDirectory}/calendar`;
export const investorAddFunds = `${subDirectory}/add-funds`;
export const investorRemoveFunds = `${subDirectory}/withdraw-funds`;
export const animoZapopanRoute = `${subDirectory}/animo-zapopan`;
export const investorSettings = `${subDirectory}/settings`;
export const investorSettingsNav = `${subDirectory}/settings/nav`;
export const profileRoute = `${subDirectory}/register/profile`;
export const contractRoute = `${subDirectory}/register/contract`;
export const userSettingsAccount = `${subDirectory}/settings/account`;
export const userSettingsSecurity = `${subDirectory}/settings/security`;
export const userSettingsSecurityChangePhone = `${subDirectory}/settings/account`;
export const userSettingsReferral = `${subDirectory}/settings/referrals`;
export const userSettingsAutoinvest = `${subDirectory}/settings/autoinvest`;
export const userSettingsAutoinvestInitialConfig = `${subDirectory}/settings/autoinvest/config`;
export const userSettingsAutoinvestTermsAndConditions = `${subDirectory}/settings/autoinvest/terms`;
export const userSettingsAutoinvestActiveAutoinvest = `${subDirectory}/settings/autoinvest/active`;
export const investorCerRoute = `${subDirectory}/electronic-risk-certificated`;
export const mainRoute = dashboardRoute;

const endpoints = {
  public: `${subDirectory}/public`,
  investorBlockedAccount: `${subDirectory}/blockedAccount`,
  investorWaitingForCommittee: `${subDirectory}/commiteeVerification`,
  investorRequisition: `${subDirectory}/requisitions/:requisitionId`,
  investorRequisitions: `${subDirectory}/requisitions`,
  investorTransactions: `${subDirectory}/transactions`,
  investorPortfolio: `${subDirectory}/portfolio`,
  investorPortfolioRequisition: `${subDirectory}/portfolio/:requisitionId`,
  investorOrders: `${subDirectory}/orders`,
  investorOrdersRequisition: `${subDirectory}/orders/requisition/:requisitionId`,
  investorCalendarRequisition: `${subDirectory}/calendar/requisition/:requisitionId`,
  investorSignContract: `${subDirectory}/signContract`,
  investorRegulationOccupation: `${subDirectory}/regulation/occupation`,
  investorConfirmIdentity: `${subDirectory}/confirm-identity`,
  investorCalendar: `${subDirectory}/calendar`,
  investorCer: `${subDirectory}/electronic-risk-certificated`,
  investorSettings: `${subDirectory}/settings/:section`,
  investorSettingsLimits: `${subDirectory}/settings/limits`,
  investorSettingsLimitsLevelOne: `${subDirectory}/settings/limits/level-one`,
  investorSettingsLimitsLevelOneUpdateAddress: `${subDirectory}/settings/limits/level-one/update-address`,
  investorSettingsLimitsLevelOneUpdateIdentification: `${subDirectory}/settings/limits/level-one/update-identification`,
  investorSettingsLimitsLevelOneConfirmPersonalInformation: `${subDirectory}/settings/limits/level-one/confirm-personal-information`,
  investorSettingsUpdateAddress: `${subDirectory}/settings/updateAddress`,
  // stop using investor as prefix, since whole project is about it
  expedientsProfile: `${subDirectory}/expedients/profile`,
  expedientsProfileShow: `${subDirectory}/expedients/profile/show`,
  expedientsProfileUpdate: `${subDirectory}/expedients/profile/:section/update`,
  officialIdentifications: `${subDirectory}/official_identifications`,
  regulationProfileUpdate: `${subDirectory}/regulation/profile/:section/update`,
  cnbv: `${subDirectory}/cnbv`,
  cnbvUpdate: `${subDirectory}/cnbv/:resource/update`,
};

const PATHS = createPathHelpers(endpoints);

export default PATHS;
