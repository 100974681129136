import { WITHDRAWAL_BANK_ACCOUNT_STATUS } from 'config/constants';
import { tscope } from 'i18n';
import * as utilString from 'utils/strings';

const errorsT = tscope('Forms.fields.errors');
const ts = tscope('components.WithdrawalForm.fields.errors');

const buildError = ({ t = errorsT, i18nKey }) => ({
  message: t(i18nKey),
  type: 'danger',
});

export const resolver = (values, context) => {
  const {
    bankAccounts,
    accountStatements,
  } = context;
  const {
    otp,
    amount,
    account,
  } = values;
  const errors = {};
  const bankAccountStatus = bankAccounts?.find(({ id }) => id === account)?.status;
  if (!amount || utilString.toFloatNumber(amount) === 0) errors.amount = buildError({ i18nKey: 'required' });
  if (amount && utilString.toFloatNumber(amount) > accountStatements?.saldoDisponible) errors.amount = buildError({ i18nKey: 'exceededAmount' });
  if (!account) errors.account = buildError({ i18nKey: 'required' });
  if (account && bankAccountStatus !== WITHDRAWAL_BANK_ACCOUNT_STATUS.VALID) errors.account = buildError({ t: ts, i18nKey: 'clabeInvalid' });
  if (!otp) errors.otp = buildError({ i18nKey: 'required' });

  return {
    values,
    errors,
  };
};
