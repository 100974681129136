import styled from 'styled-components';
import FooterComponent from 'views/DashboardContent/FooterDashboard';

export const LoadingSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  width: 100%;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const Footer = styled(FooterComponent)`
  justify-self: flex-end;
  flex-grow: unset;
  width: 100%;
`;
