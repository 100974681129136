import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import ReactCodeInput from 'react-code-input';
import get from 'lodash.get';
import cls from 'classnames';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { useReduxQuery } from 'utils/redux-query';
import { t } from 'i18n';
import { OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import { OTPWidgetContainer } from './styles';

const ts = (id, options) => t(id, { scope: 'common.secondFactor', ...options });

const OTPWidget = (props) => {
  const {
    expirationTime,
    buttonText,
    handleFormSubmit,
    handleIncorrectCode,
    closeOtpForm,
    color,
    errors,
    label,
    startTimer,
    disabled,
    shrinkInputs,
  } = props;
  let timer = null;
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');
  const otpType = secondFactor?.otpType;

  const setTimer = (timerId) => {
    timer = timerId;
  };

  const clearTimer = () => {
    if (!timer) return;
    clearTimeout(timer);
    setTimer(null);
  };

  const submit = async (values) => {
    clearTimer();
    try {
      await handleFormSubmit(values.OTP);
    } catch (error) {
      const status = get(error, 'response.status');
      if (status !== 422) throw error;
      handleIncorrectCode();
    }
  };

  useEffect(() => {
    if (!expirationTime) return () => {};
    if (startTimer === true || startTimer === undefined) {
      setTimer(setTimeout(() => closeOtpForm(true), expirationTime));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [expirationTime]);

  useEffect(() => {
    if (startTimer === true) {
      setTimer(setTimeout(() => closeOtpForm(true), expirationTime));
    } else if (startTimer === false) {
      clearTimer();
    }
  }, [startTimer]);

  const renderWidget = () => (
    <Form
      onSubmit={submit}
      validate={(values) => {
        const errorsValidation = {};
        const { OTP } = values;
        if (!OTP) errorsValidation.OTP = 'Este valor es requerido';
        if (OTP && OTP.length < OTP_CODE_LENGHT_DEFAULT) errorsValidation.OTP = `El código es menor de ${OTP_CODE_LENGHT_DEFAULT} dígitos`;
        return errorsValidation;
      }}
      render={({ handleSubmit, submitting }) => (
        <form
          onSubmit={handleSubmit}
          className={cls('otp__form', { 'shrink-input': shrinkInputs })}
        >
          <Field name="OTP">
            {({ meta, input }) => (
              <label data-testid="secondFactorModule.input">
                {label === null ? (
                  <div className="no-label" />
                ) : (
                  label || `${ts('authorizationCode')} (${ts(`types.${otpType}`)})`
                )}
                <ReactCodeInput
                  type="number"
                  fields={OTP_CODE_LENGHT_DEFAULT}
                  disabled={submitting}
                  {...input}
                />
                {meta.touched && meta.error && <p className="error__message">{meta.error}</p>}
              </label>
            )}
          </Field>
          <Button
            type="submit"
            color={color}
            disabled={disabled || Object.keys(errors || {}).length > 0 || submitting}
          >
            {submitting ? <DotsLoader /> : buttonText}
          </Button>
        </form>
      )}
    />
  );
  return <OTPWidgetContainer>{renderWidget()}</OTPWidgetContainer>;
};

export default OTPWidget;
