import React from 'react';
import { t } from 'i18n';
import { publicUrls } from 'config/constants';
import {
  Button, DotsLoader, Heading, Text, Divider,
} from 'components';
import { Box, Grid } from 'styles';
import PasswordConfirmation from 'modules/PasswordConfirmation';
import { TERMS_LIST } from '../../constants/termsList';
import AnimatedStep from './utils/AnimatedStep';
import ConditionsDescriptionContainer from './ConditionsDescription.style';
import { useController } from './hooks/useController';

const tsTerms = (key, options) => t(key, { scope: 'components.CER.Terms', ...options });

export const ConditionsDescription = (props) => {
  const {
    stepCounter,
    onStepChange,
    isSubmitting,
    confirmPasswordStackedStyle,
  } = props;

  const {
    transitions,
    onSigningRisks,
    onGoBackHandler,
    buttonsAnimation,
  } = useController({
    stepCounter,
    onStepChange,
  });

  return (
    <ConditionsDescriptionContainer>
      <div className={`container ${stepCounter === 1 ? 'grow' : ''}`}>
        {stepCounter < TERMS_LIST.length && (
          <>
            <Box p="2rem">
              {transitions.map(({ item, props: styleProp, key }) => (item ? (
                <AnimatedStep key={key} styles={styleProp}>
                  {item.title && (
                    <Heading as="h3" fontSize="2rem" data-testid="conditions-description-title">
                      {item.title}
                    </Heading>
                  )}
                  <Text as="b" fontWeight="500" className="counter" letterSpacing="1.5px">
                    {tsTerms(`sections.termsListing.${item.key}.stepOf`, { id: item.id, total: TERMS_LIST.length })}
                  </Text>
                  <Text fontSize="1.4rem" data-testid="conditions-description-description">
                    {item.description}
                  </Text>
                </AnimatedStep>
              ) : null))}
            </Box>
            <Divider />
          </>
        )}

        <div className="confirmationButtons">
          {buttonsAnimation.map(({ props: styleProp, item, key }) => (item ? (
            <AnimatedStep styles={styleProp} key={key}>
              <div className="password_confirmation">
                <PasswordConfirmation
                  onSubmitForm={onSigningRisks}
                  textButton="Aceptar y continuar"
                  isSubmitting={isSubmitting}
                  stacked={confirmPasswordStackedStyle}
                />
                <Text data-testid="conditions-description-text-info" fontSize="1rem" color="schema.gray.500">
                  He leído este formulario y comprendo los riesgos de mi inversión.
                </Text>
              </div>
            </AnimatedStep>
          ) : (
            <AnimatedStep
              styles={styleProp}
              key={key}
              className={`confirmationButtons--container ${stepCounter === 1 ? 'grow' : ''}`}
            >
              <Grid w="100%" templateColumns="1fr 1fr" gap="1rem">
                <Button
                  disabled={isSubmitting}
                  onClick={() => onStepChange(TERMS_LIST[stepCounter]?.stepName)}
                  data-testid="conditions-description-ok-button"
                  px="0"
                  gridArea="1 / 1"
                >
                  {!isSubmitting ? 'Entiendo' : <DotsLoader />}
                </Button>
                <Button onClick={onGoBackHandler} $type="transparent" gridArea="1 / 2">
                  No lo entiendo
                </Button>
                <Text
                  as="a"
                  href={publicUrls.termConditions}
                  target="_blank"
                  rel="noreferrer"
                  fontSize="1rem"
                  gridArea="2 / 1 / 2 / 2"
                >
                  Conocer más
                </Text>
              </Grid>
            </AnimatedStep>
          )))}
        </div>
      </div>
    </ConditionsDescriptionContainer>
  );
};
