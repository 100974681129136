import { USER_STATUSES } from 'config/constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag } from 'utils/featureFlags';
import { useReduxQuery } from 'utils/redux-query';

export const useController = () => {
  const location = useLocation();
  const [session] = useReduxQuery('SESSION');
  const [alertIsOpen, setIsOpen] = useState(false);
  const { needsUpdateInformation } = useFeatureFlag();
  const name = session?.user?.fullName?.split(' ')[0];
  const isUserPartiallyBlocked = session?.user?.status === USER_STATUSES.PARTIALLY_BLOCKED;

  const closeAlert = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const isFocusedView = location.pathname.match('cnbv');
    if (isFocusedView) return;

    if (needsUpdateInformation
      || (isUserPartiallyBlocked && needsUpdateInformation)) {
      setIsOpen(true);
    }
  }, [needsUpdateInformation, isUserPartiallyBlocked, location]);

  return {
    name,
    closeAlert,
    alertIsOpen,
    needsUpdateInformation,
    isUserPartiallyBlocked,
  };
};
