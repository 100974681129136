import React, { createContext, useContext } from 'react';
import useController from './useController';

export const controllerContext = createContext();

export const profileContext = () => useContext(controllerContext);

export function Provider(props) {
  const { children, ...options } = props;
  const value = useController(options);

  return <controllerContext.Provider value={value}>{children}</controllerContext.Provider>;
}
