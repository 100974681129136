import i18n from 'i18n-js';
import { buildJsx } from './extends/buildJSX';
import locales from './locales';

i18n.translations = locales;
i18n.defaultLocale = 'es-MX';
i18n.fallbacks = true;

export const { t, l } = i18n;
export const toPercentage = i18n.toPercentage.bind(i18n);
export const toCurrency = i18n.toCurrency.bind(i18n);
export const toNumber = i18n.toNumber.bind(i18n);

export const tscope = (scope) => (exactScope, options) => t(exactScope, { scope, ...options });

export const lmed = (date, format = 'date.formats.medium', options) => i18n.l(format, date, options);

export const jsx = buildJsx(t);

export const resolveT = (t1 = t, t2 = t) => (key, options) => t1(
  key,
  { defaultValue: t2(key) },
  options,
);

export default i18n;
