import React from 'react';
import { t } from 'i18n';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import {
  BadgeContainer, Text, Suffix, types, typesKeys,
} from './Badge.style';

function Badge(props) {
  const {
    id, value, hasTooltip, type, hasSuffix = true, ...contentProps
  } = props;
  const suffix = t(`numbers.ordinals.${value}`, {
    defaultValue: t('numbers.ordinals.default'),
  });
  const displayValue = ` ${value}${hasSuffix ? suffix : ''} `;

  return (
    <BadgeContainer $type={type} {...contentProps} data-for={`tooltip-badge${id}`} data-tip>
      <Text $type={type}>{value}</Text>
      {hasSuffix && <Suffix $type={type}>{suffix}</Suffix>}
      {hasTooltip && (
        <Tooltip {...props} suffix={suffix}>
          {t('listRequisition.tooltip.paragraphOne')}
          <strong>
            {' '}
            {displayValue}
            {' '}
          </strong>
          {t('listRequisition.tooltip.paragraphtwo')}
          <br />
          <span>{t('listRequisition.tooltip.url')}</span>
        </Tooltip>
      )}
    </BadgeContainer>
  );
}

Badge.defaultProps = {
  type: typesKeys.success,
  hasTooltip: false,
  id: null,
};

Badge.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  value: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasTooltip: PropTypes.bool,
};

export default Badge;
