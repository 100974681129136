import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import Button from 'components/Button/Button';
import { ReactComponent as InfoIconComponent } from 'assets/icons/info-v2.svg';

const types = {
  warning: {
    colors: colors.alerts.warning,
  },
};

export const FlashNoticeContent = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 4rem;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ $type }) => types[$type].colors.bgColor};
  border-color: ${({ $type }) => types[$type].colors.borderColor};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 2rem 2rem;
  }
`;

export const FlashNoticeBody = styled.p`
  color: ${({ $type }) => types[$type].colors.textColor};
  font-weight: bold;
`;

export const LevelUpButton = styled(Button)`
  margin-left: 4rem;
  white-space: nowrap;
`;

export const WarningIcon = styled(InfoIconComponent)`
  margin-right: 10px;
`;
