import { tscope } from 'i18n';
import { LENDING_AMOUNT_MULTIPLIER, MAX_LENDING_AMOUNT, MIN_LENDING_AMOUNT } from 'config/constants';

const ts = tscope('Forms.fields.errors');

const buildError = ({ message }) => ({
  type: 'danger',
  message,
});

const resolveAmount = (amount) => {
  if (!amount) return null;
  const amountNumber = Number(amount);
  if (amountNumber < MIN_LENDING_AMOUNT) return buildError({ message: ts('amountUnder200') });
  if (amountNumber % LENDING_AMOUNT_MULTIPLIER !== 0) return buildError({ message: ts('amountMultipleOf100') });
  if (amountNumber > MAX_LENDING_AMOUNT) return buildError({ message: ts('amountExceded') });
  return null;
};

export const resolver = (values) => {
  const {
    grade,
    term,
    amount,
    purpose,
    otherAmount,
  } = values;
  const errors = {};
  if (grade.length === 0) errors.grade = buildError({ message: ts('required') });
  if (term.length === 0) errors.term = buildError({ message: ts('required') });
  if (purpose.length === 0) errors.purpose = buildError({ message: ts('required') });
  if (!amount && !otherAmount) errors.amount = buildError({ message: ts('required') });
  const otherAmountError = resolveAmount(otherAmount.replace(/[$,]/g, ''));
  if (!amount && otherAmountError) errors.otherAmount = otherAmountError;

  return {
    values,
    errors,
  };
};
