import React from 'react';
import {
  Tag,
  Panel,
  Button,
  Heading,
  Text,
  Flex,
} from 'components';
import { tscope } from 'i18n';
import { Icon } from 'components/Icon/Icon';
import { InvoicingInstructions, RequireUpdateFiscalData } from './components';
import { styles } from './InvoicingPanel.styles';
import { useController } from './hooks';
import { INVOICING_STATUS } from '../../constants';

const t = tscope('settings.account.invoicingPanel');

const statusToSchemaColor = {
  [INVOICING_STATUS.ENABLED]: 'green',
  [INVOICING_STATUS.DISABLED]: 'gray',
};

export const InvoicingPanel = ({
  onEditFiscal,
  onUploadClick,
  onActiveInvoicing,
}) => {
  const {
    badgeStatus,
    displayActivateButton,
    toggleInvoicingPreview,
    displayEditFiscalButton,
    displayUpdateFiscalContent,
    displayInvoicingErrorAlert,
  } = useController();

  return (
    <Panel
      textHeaderProps={styles.header}
      title={(
        <>
          <Flex gap="1rem">
            <Heading {...styles.title}>{t('title')}</Heading>
            <Tag
              {...styles.badge}
              schemaColor={statusToSchemaColor[badgeStatus]}
            >
              {t(`badge.${badgeStatus}`)}
            </Tag>
          </Flex>
          <Flex {...styles.actionsHeader}>
            {displayEditFiscalButton
              && (
                <Button
                  {...styles.edit}
                  onClick={onEditFiscal}
                  data-testid="invoicingPanel.edit"
                >
                  {t('actions.edit')}
                </Button>
              )}
            {displayActivateButton
              && (
                <Button
                  {...styles.enable}
                  onClick={onActiveInvoicing}
                  data-testid="invoicingPanel.activate"
                >
                  {toggleInvoicingPreview}
                </Button>
              )}
          </Flex>
        </>
      )}
    >
      {displayInvoicingErrorAlert && (
        <Flex {...styles.invoicingError.container}>
          <Icon iconName="DANGER" boxSize="2.3rem" color="red.500" />
          <Text {...styles.invoicingError.text}>
            {t('error')}
          </Text>
        </Flex>
      )}
      {displayUpdateFiscalContent
        ? (
          <RequireUpdateFiscalData
            onUploadClick={onUploadClick}
          />
        )
        : (
          <InvoicingInstructions
            status={badgeStatus}
          />
        )}
    </Panel>
  );
};
