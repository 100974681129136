import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { Form, Field } from 'react-final-form';
import { Box } from 'styles';
import { Button } from 'components';
import DotsLoader from 'components/DotsLoader';
import ButtonIcon from 'components/Button/ButtonIcon';
import { useReduxQuery } from 'utils/redux-query';
import MovementsFilterContainer from './styles';
import Card from '../../../Card';
import InputField from '../../../InputField';
import InfoWidget from './InfoWidget';

registerLocale('es', es);
const MAX_DATE = new Date();

const MovementsFilter = (props) => {
  const {
    isMobile,
    onToggleF,
    onResetClick,
    handleFilter,
    transactions,
    paymentDetails,
    onToggleFilter,
    filtersApplied,
    disabled = false,
  } = props;
  const [, { status: movementsStatus }] = useReduxQuery('MOVEMENTS');
  const type = filtersApplied.type || false;
  const [pickers, setPickers] = useState({ firstOpenMin: false, firstOpenMax: false });

  const handlePickerOpen = (ev, prop) => {
    if (isMobile && pickers[prop] === false) {
      ev.target.blur();
      setPickers((prev) => ({ ...prev, [prop]: true }));
    }
  };

  const handleFilterReset = (reset) => {
    reset();
    onResetClick();
  };

  const renderOptions = () => {
    const options = ['Pago', 'Comisión', 'Retiro', 'Préstamo', 'Abono'];
    return options.map((option) => (
      <option value={option} key={option}>
        {option === 'Abono' ? 'Fondos Agregados' : option}
      </option>
    ));
  };

  const renderFilterForm = () => (
    <div>
      <p className="titleFilter">
        Filtrar movimientos
        {isMobile ? (
          <ButtonIcon iconName="CLOSE" className="closeFilter" onClick={onToggleFilter} />
        ) : null}
      </p>
      <hr />
      <div className="formContainer">
        <Form
          onSubmit={handleFilter}
          initialValues={filtersApplied}
          render={({
            handleSubmit, form, values,
          }) => (
            <form
              autoComplete="off"
              onSubmit={(ev) => {
                handleSubmit(ev);
              }}
            >
              <div className="fieldGroup">
                <Field name="min_date">
                  {({ input }) => (
                    <Box as="fieldset" border="0" disabled={disabled}>
                      <p className="dateFilterLabel">Desde</p>
                      <DatePicker
                        {...input}
                        autoComplete="off"
                        role="presentation"
                        selected={values.min_date}
                        locale="es"
                        onChange={(date) => {
                          input.onChange(date);
                        }}
                        onFocus={(ev) => handlePickerOpen(ev, 'firstOpenMin')}
                        disabled={disabled}
                        maxDate={MAX_DATE}
                      />
                    </Box>
                  )}
                </Field>
                <Field name="max_date">
                  {({ input }) => (
                    <Box as="fieldset" border="0" disabled={disabled}>
                      <p className="dateFilterLabel">Hasta</p>
                      <DatePicker
                        {...input}
                        autoComplete="off"
                        role="presentation"
                        selected={values.max_date}
                        locale="es"
                        onChange={(date) => input.onChange(date)}
                        onFocus={(ev) => handlePickerOpen(ev, 'firstOpenMax')}
                        popperPlacement="top-end"
                        disabled={disabled}
                        maxDate={MAX_DATE}
                        popperModifiers={{
                          offset: {
                            enabled: true,
                            offset: '5px, 10px',
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </Box>
                  )}
                </Field>
              </div>
              <Field name="type" parse={(v) => v}>
                {({ input, meta }) => (
                  <Box as="fieldset" border="0" className="selectContainer" disabled={disabled}>
                    <InputField
                      isActive={input.value}
                      isFocused={meta.active}
                      isPristine={meta.pristine}
                      name={input.name}
                      labelText={input.value && type ? type : 'Tipo de movimiento'}
                      inputType="select"
                      input={{ ...input }}
                    >
                      <option value="">Seleccionar</option>
                      {renderOptions()}
                    </InputField>
                  </Box>
                )}
              </Field>
              <Box as="fieldset" border="0" className="buttonGroup" disabled={disabled}>
                <Button $type="outline" type="button" disabled={movementsStatus.pending} onClick={() => handleFilterReset(form.reset)}>
                  Borrar
                </Button>
                <Button type="submit" disabled={movementsStatus.pending}>
                  {!transactions ? <DotsLoader /> : 'Filtrar'}
                </Button>
              </Box>
            </form>
          )}
        />
      </div>
      {paymentDetails && (
        <div className="InfoWidgetContainer">
          <Card>
            <InfoWidget type={type} paymentDetails={paymentDetails} />
          </Card>
        </div>
      )}
    </div>
  );

  return (
    <MovementsFilterContainer
      isMobile={isMobile}
      onToggleFilter={onToggleFilter}
      onToggleF={onToggleF}
    >
      {renderFilterForm()}
    </MovementsFilterContainer>
  );
};

export default MovementsFilter;
