import { useEffect, useMemo } from 'react';

export const FocusField = ({
  form,
  errors,
}) => {
  const { submitFailed, modifiedSinceLastSubmit } = form.getState();
  const firstErrorFieldName = useMemo(() => {
    const errorPair = Object.entries(errors).find(([, error]) => Boolean(error));
    return errorPair ? errorPair[0] : null;
  }, [errors]);

  useEffect(() => {
    if (modifiedSinceLastSubmit || !submitFailed || !firstErrorFieldName) return;
    const [inputField] = document.getElementsByName(firstErrorFieldName);
    if (inputField) inputField.focus();
  }, [
    submitFailed,
    firstErrorFieldName,
    modifiedSinceLastSubmit,
  ]);

  return null;
};
