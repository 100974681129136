import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  useChat,
  useTracker,
  useCrashReporter,
} from 'hooks';
import { checkApiStatus } from 'actions/auth';
import {
  TOAST_AUTO_CLOSE_TIME,
} from 'config/constants';

export const useController = () => {
  /**
   * Tracking Hooks are placed here
   */
  useChat();
  useTracker();
  useCrashReporter();
  /**
   * Tracking Hooks END
   */
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkApiStatus());
  }, []);

  useEffect(() => {
    toast.configure({
      autoClose: TOAST_AUTO_CLOSE_TIME,
      hideProgressBar: true,
    });
  }, []);
};
