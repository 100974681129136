import styled from 'styled-components';
import { colors } from 'styles';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: ${colors.shadow.card};
  padding: 2rem;
  margin-bottom: 2rem;
`;
