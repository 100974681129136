import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import Modal from 'components/Modal';
import { TextStyle } from 'components/Typography';
import ButtonContainer from 'components/Button/styles';

export const UpdateAddressNotifyModalContainer = styled(Modal)`
  padding: 3rem 4rem !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 420px !important;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 2rem !important;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeText = styled.h2`
  ${TextStyle}
  color: #134052;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 3rem;
`;

export const BodyText = styled.p`
  ${TextStyle}
  text-align: center;
  margin-bottom: 2rem;
`;

export const RedirectToUpdateAddreesLink = styled(ButtonContainer)`
  padding: 2rem 3rem;
  background-color: #05c574;
  font-size: 1.8rem;

  &:hover {
    background-color: #05c574;
  }
`;
