import { request } from '../../fetchers/yotepresto';

const update = async ({ password }) => {
  const config = {
    method: 'put',
    path: '/v1/investor/contract',
    extraHeaders: { password },
  };

  const { data } = await request(config);

  return data;
};

export default update;
