import { messages } from 'config/constants';
import isNil from 'utils/isNil';
import { regexAcceptOnlyLetters } from 'utils/inputRestrictions';

export const isRequired = (name) => {
  const validationRules = [!isNil(name), name?.trim() !== ''];

  return validationRules.every(Boolean) ? null : messages.required;
};

export const onlyLetters = (name) => {
  const validationRules = [regexAcceptOnlyLetters.test(name)];

  return validationRules.every(Boolean) ? null : messages.invalid;
};
