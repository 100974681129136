export const styles = {
  modal: {
    size: 'md',
  },
  modalContent: {
    p: 3,
  },
  input: {
    pr: '24',
  },
  header: {
    color: 'gray.700',
    fontSize: 'xl',
    pt: 1,
  },
  hStack: {
    spacing: 4,
    justify: 'end',
    mt: 3,
  },
  links: {
    fontSize: 'md',
    fontWeight: '700',
    float: 'right',
    variant: 'link',
    animationType: 'none',
  },
  textHelper: {
    color: 'red.500',
  },
  text: {
    color: 'gray.500',
  },
  submit: {
    size: 'md',
    animationType: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  inputRightElement: {
    width: '22',
  },
  container: {
    w: 'full',
  },
};
