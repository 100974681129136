import React from 'react';
import { t } from 'i18n';
import { Heading } from 'components/Typography';
import { Flex } from 'styles';
import Image from 'components/Image/Image';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.initialState.header', ...options });

const HeaderWithImage2FA = ({ ...contentProps }) => (
  <Flex
    borderBottom="1px solid"
    columnGap="2rem"
    borderColor="schema.gray.200"
    alignItems="center"
    {...contentProps}
  >
    <Image imageName="SECOND_FACTOR" boxSize="96px" h="54px" flexShrink="0" />
    <Heading as="h3">{ts('title')}</Heading>
  </Flex>
);

export default HeaderWithImage2FA;
