import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';

const BatchBarDiv = ({ isMobile, ...otherProps }) => <animated.div {...otherProps} />;

const BatchActionBarContainer = styled(BatchBarDiv)`
  position: fixed;
  bottom: 0;
  height: 7rem;
  width: 100%;
  background-color: white;
  border-top: 1px #e7ebef solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #04475C;
  & .requisitions_quantity {
    display: flex;
    align-items: center;
    margin-right: 4rem;
    font-size: 1.1rem;
    & .quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
      margin-right: 1.5rem;
      background-color: #e7ebef;
      border-radius: 5rem;
      font-size: 1.4rem
      color: #718e97;
      height: 3rem;
      width: 3rem;
    }
  }
  & .quick_options {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      margin-right: 2.5rem;
    }
    & .quick_selection_options {
      display: flex;
    }
    & button {
      display: flex;
      align-items: center;
      border: 1px #0076FF solid ;
      color: #0076FF;
      height: 3.5rem;
      padding: 0 2rem;
      margin-right: 1.5rem;
      font-size: 1.5rem;
      & svg {
        height: 2rem;
        width: 2rem;
        margin-right: 0.7rem;
      }
    }
    & .input__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & button{
        margin-left: 1rem;
        & svg {
          margin: 0;
          & * {
            fill: white;
          }
        }
      }
      &__body {
        display: flex;
        border: 1px solid #0076FF;
        color: #0076FF;
        background-color: #F8FAFC;
        height: 100%;
        align-items: center;
        font-size: 1.5rem;
        & span {
          margin: 0 1rem;
        }
        & input {
          background-color: #F8FAFC;
          border: none;
          outline: none;
          height: 100%;
          font-size: 1.5rem;
        }
      }
    }
  }
  & .requisitions_settings {
    flex: 1;
    & button {
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 4rem;
      background-color: transparent;
      border-radius: 5rem;
      transition: all .4s ease;
      &:hover {
        background-color: #F8FAFC;
        cursor: pointer;
        & svg {
          & * {
            fill: #0076FF;
          }
        }
      }
    }
  }
`;

export default BatchActionBarContainer;
