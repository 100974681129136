import {
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

const SearchGroupContainer = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  justify-content: center;
  align-items: center;
  &.panel {
    background-color: white;
    flex-direction: column;
    .search__group__container {
      .input__body {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
    .search__group__return {
      width: 90%;
      display: flex;
      align-items: center;
      & button {
        display: flex;
        background-color: transparent;
        align-items: center;
        color: #0f7eff;
        font-size: 1.4rem;
        padding: 1rem;
        border: none;
        outline: none;
        &:hover {
          cursor: pointer;
        }
        & svg {
          margin-right: 1rem;
        }
      }
    }
  }
  &.right .search__group__container {
    justify-content: flex-end;
  }
  & .search__group {
    &__return {
      display: none;
    }
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      & .input__body {
        background-color: white;
        height: 4.32rem;
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
        border: 1px solid #a2bcc5;
        border-right: 0;
        & input {
          font-size: 1.5rem;
          outline: none;
          border: none;
          padding-left: 1rem;
          &::placeholder {
            color: #93afb9;
          }
        }
        & button {
          font-size: 1.5rem;
          background-color: #0f7eff;
          color: white;
          height: 106%;
          padding: 0 1rem;
          border: none;
          outline: none;
          border-radius: 0 0.4rem 0.4rem 0;
        }
      }
    }
  }

  ${boxHelpers};
`;

export default SearchGroupContainer;
