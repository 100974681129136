import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTab } from 'actions/dashboard';
import { Route } from 'react-router-dom';
import { useReduxQuery } from 'utils/redux-query';
import { ReactComponent as OrderIcon } from 'assets/orders/menu_icon.svg';
import { t } from 'i18n';
import Link from 'components/Link';
import { P } from 'components/Typography';
import endpoints from 'config/endpoints';
import PATHS from 'routes';
import OrdersTable from './Table/OrdersTable';
import DisplayDetails from '../DisplayDetails';
import Footer from '../FooterDashboard';
import OrdersVideoModal from './OrdersVideoModal';
import {
  Container,
  Content,
  Banner,
  BannerIcon,
  BannerText,
  PlayImage,
  PlayVideoBox,
  PlayVideoButton,
  PlayVideoButtonMobile,
} from './styles';

const { investorOrdersPath, investorOrdersRequisitionPath } = PATHS;

const Orders = () => {
  const dispatch = useDispatch();
  const [isOpenOrdersVideo, toggleOrderVideo] = useState();
  const [{ orders: ordersByCategory }] = useReduxQuery('ORDERS');
  const requisitions = useSelector(
    ({ requisitions: { fundingRequisitionList } }) => fundingRequisitionList.requisitions,
  );

  const handleOpenVideoModal = () => {
    toggleOrderVideo(!isOpenOrdersVideo);
  };

  useEffect(() => {
    dispatch(selectTab({ title: t('orders.orders') }));
  }, []);

  return (
    <Container>
      <Content>
        <Banner className="orders-banner">
          <BannerIcon>
            <OrderIcon title="" />
          </BannerIcon>
          <BannerText>
            <P size="1.5rem" color="#04475C">
              {t('orders.orders')}
            </P>
            <P size="1.4rem" color="#81ACBA">
              {t('orders.noticeNewOrderBanner')}
              {' '}
              <PlayVideoButtonMobile onClick={handleOpenVideoModal}>
                {t('orders.howItWorks')}
              </PlayVideoButtonMobile>
              {' o '}
              <Link as="a" target="blank" href={endpoints.public.aboutOrders} color="#1481FE">
                {t('orders.seeMore')}
              </Link>
            </P>
          </BannerText>
          <PlayVideoBox ml="auto" radius="7px">
            <P size="1.5rem" color="#04475C">
              {t('orders.howItWorks')}
            </P>
            <PlayVideoButton onClick={handleOpenVideoModal}>
              <PlayImage title="" />
            </PlayVideoButton>
          </PlayVideoBox>
        </Banner>
        {ordersByCategory?.map((categoryOrders) => {
          const [categoryName, orders] = Object.entries(categoryOrders)[0];

          return (
            <React.Fragment key={categoryName}>
              <P className="orders-subTitle" color="#04475C" size="1.5rem" align="center">
                {categoryName}
              </P>
              {orders.length > 0 && (
                <OrdersTable
                  className="orders-pendingTable"
                  orders={orders.sort((o1, o2) => o1.createdAt < o2.createdAt)}
                />
              )}
              {orders.length === 0 && (
                <P className="orders-noOrdersText" align="center">
                  {t('orders.NoPendignOrders')}
                </P>
              )}
            </React.Fragment>
          );
        })}
      </Content>
      <Route path={investorOrdersRequisitionPath()}>
        {({ match }) => (
          <DisplayDetails
            isShowing={match}
            requisitionsArray={requisitions}
            showVisibilityButton={false}
            backTo={{ pathname: investorOrdersPath(), state: { scrollToTop: false } }}
            isEnablePagination={false}
          />
        )}
      </Route>
      <Footer />
      <OrdersVideoModal isOpen={isOpenOrdersVideo} onRequestClose={() => toggleOrderVideo(false)} />
    </Container>
  );
};

Orders.defaultProps = {
  pendingOrders: [],
  finishedOrders: [],
};

export default Orders;
