import React from 'react';
import { tscope } from 'i18n';
import {
  Text,
  Flex,
  Button,
  Grid,
} from 'ui';
import { PasswordAuthorizeField } from 'components/PasswordAuthorizeField';
import { useController } from './hooks';

const ts = tscope('components.AutoInvestWidget.removeModal');

export const RemoveAutoInvestForm = (props) => {
  const { onCancel } = props;
  const {
    control,
    onSubmit,
    isLoading,
  } = useController();

  return (
    <Grid
      as="form"
      onSubmit={onSubmit}
    >
      <Flex
        mb="1rem"
        gap="1rem"
        flexWrap="wrap"
      >
        <PasswordAuthorizeField
          size="sm"
          name="password"
          control={control}
          data-testid="password"
        />
        <Flex justifyContent="space-between" width="full">
          <Button
            size="sm"
            variant="outline"
            onClick={onCancel}
            isDisabled={isLoading}
            order={{ base: '3', xs: '1' }}
          >
            {ts('form.actions.cancel')}
          </Button>
          <Button
            size="sm"
            type="submit"
            colorScheme="red"
            isDisabled={isLoading}
            order={{ base: '2', xs: '3' }}
            w={{ base: '100%', xs: 'auto' }}
          >
            {ts('form.actions.submit')}
          </Button>
        </Flex>
      </Flex>
      <Text fontSize="1.2rem" color="schema.gray.500" textAlign="center">
        {ts('notice')}
      </Text>
    </Grid>
  );
};
