import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { colors } from 'styles';

const ChartItem = (props) => {
  const { element, title, color = colors.schema.gray[500] } = props;
  const { totalRows, amount, percentage } = element;

  return (
    <div className="chart__item">
      <div className="chart__item__content title">
        <p>{title}</p>
      </div>
      <div className="chart__item__content quantity">
        <p style={{ color }}>{totalRows}</p>
      </div>
      <div className="chart__item__content percentage">
        <div className="percentage__container">
          <p style={{ color }}>
            {percentage}
            %
          </p>
          <div className="chart__container">
            <CircularProgressbar
              value={percentage}
              styles={buildStyles({
                pathColor: color,
              })}
              strokeWidth={13}
            />
          </div>
        </div>
      </div>
      <div className="chart__item__content amount">
        <p>{toCurrencyWithDecimals(amount)}</p>
      </div>
    </div>
  );
};

export default ChartItem;
