import React from 'react';
import {
  Box,
  Alert,
  Button,
  AlertIcon,
  AlertDescription,
  TimeIcon,
} from 'ui';
import { useController } from './hooks/useController';
import styles from './SessionExpirationToast.styles';

export const SessionExpirationToast = ({
  toastTime,
  showToast,
  onExtendSession,
  onSessionExpire,
  ...props
}) => {
  const { description, btnText } = useController({
    toastTime,
    showToast,
    onSessionExpire,
  });

  return (
    <Alert
      status="warning"
      variant="subtle"
      data-testid="sessionExpirationToast"
      {...styles.container}
      {...props}
    >
      <AlertIcon as={TimeIcon} {...styles.icon} />
      <AlertDescription {...styles.font}>
        {description}
      </AlertDescription>
      <Box {...styles.spaceBox} />
      <Button
        size="md"
        colorScheme="yellow"
        onClick={onExtendSession}
        {...styles.button}
      >
        {btnText}
      </Button>
    </Alert>
  );
};
