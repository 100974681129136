import styled from 'styled-components';
import { colors } from 'styles';

const ConditionsAcceptedContainer = styled.div`
  .item {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid ${colors.schema.gray['200']};
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    svg {
      margin-right: 1rem;
    }
  }
`;

export default ConditionsAcceptedContainer;
