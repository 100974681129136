import React from 'react';
import { t, tscope } from 'i18n';
import {
  ButtonIcon, Flex, Text,
} from 'components';
import FilterButton from 'views/FilterButton';
import { useController } from './hooks/useController';

const ts = tscope('Movements.default');

export const TopBar = ({
  openFilter,
  handleRefreshListing,
}) => {
  const {
    isNotLaptop,
    updatedAtText,
    onDownloadMovements,
    displayDownloadCSVFile,
    isEnableDownloadCSVFile,
  } = useController();

  return (
    <Flex
      className="controls_area"
      alignItems="center"
      columnGap="2rem"
      pb="2rem"
      px={{ base: '1rem', md: '1rem' }}
      rowGap="1rem"
      direction={{ base: 'column', md: 'row' }}
    >
      <Flex width="100%" order={{ base: 2, md: 1 }} columnGap="2rem" alignItems="center">
        {isNotLaptop ? (
          <FilterButton handleClick={openFilter} zIndex="auto">
            {ts('topBar.actions.filter')}
          </FilterButton>
        ) : null}
        <ButtonIcon
          variant="subtle"
          title={t('Movements.default.updateList')}
          schema="gray"
          iconName="REFRESH"
          isRounded
          onClick={handleRefreshListing}
        />
        {displayDownloadCSVFile && (
          <ButtonIcon
            variant="subtle"
            title={t('Movements.default.downloadMovements')}
            schema="gray"
            iconName="FILE_DOWNLOAD"
            isRounded
            onClick={onDownloadMovements}
            disabled={isEnableDownloadCSVFile}
          />
        )}
      </Flex>
      <Text
        order={{ base: 1, md: 2 }}
        fontSize="1.4rem"
        ml={{ base: '0', md: 'auto' }}
        color="schema.gray.400"
        whiteSpace="nowrap"
      >
        {ts('topBar.updatedAt', { date: updatedAtText })}
      </Text>
    </Flex>
  );
};
