import React from 'react';
import LoadingIcon from 'assets/loading-icon.gif';
import { t } from 'i18n';
import LoadingComponent from './styles';

const Loading = (props) => (
  <LoadingComponent {...props}>
    <div className="title">
      <h1>{t('Movements.default.loadingState.title')}</h1>
    </div>
    <div className="info">
      <div className="image-wrapper">
        <img src={LoadingIcon} alt="Cargando..." />
      </div>
      <div className="text-wrapper">
        <div className="note1">
          Esto puede tomar algo de tiempo, te notificaremos una vez que estén listos.
        </div>
        <div className="note2">
          Mientras tanto, puedes seguir navegando en nuestra plataforma.
        </div>
      </div>
    </div>
  </LoadingComponent>
);

export default Loading;
