import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { smallBreakPoint } = breakPoints;

const loadingDiv = (props) => <div {...props} />;

const LoadingComponent = styled(loadingDiv)`
  background-color: ${colors.gray.g0};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;

  h1 {
    color: ${colors.gray.g6};
    font-size: 3rem;
  }

  .title {
    text-align: center;
  }

  .info {
    display: flex;
    max-width: 600px;
    margin-top: 2rem;
  }

  .image-wrapper {
    min-width: 200px;
    max-width: 300px;
    img {
      width: 100%;
    }
  }

  @media (max-width: ${smallBreakPoint}px) {
    .info {
      flex-direction: column;
    }
    .image-wrapper {
      max-width: 100%;
      text-align: center;
      img {
        display: inline-block;
        width: 200px;
      }
    }
  }

  .text-wrapper {
    .note1 {
      font-size: 1.6rem;
      color: ${colors.gray.g4};
      padding: 15px;
    }

    .note2 {
      font-size: 1.5rem;
      color: ${colors.gray.g5};
      background-color: ${colors.gray.g1};
      padding: 15px;
      border-radius: 6px;
    }
  }
`;

export default LoadingComponent;
