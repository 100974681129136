import common from './common.json';
import login from './login.json';
import settings from './settings.json';
import detailRequisition from './detailRequisition.json';
import date from './date.json';
import summary from './summary.json';
import orders from './orders.json';
import numbers from './numbers.json';
import listRequisition from './listRequisition.json';
import addFunds from './addFunds.json';
import withdraws from './withdraws.json';
import * as RegisterFunnel from './RegisterFunnel';
import * as components from './components';
import * as Expedients from './Expedients';
import expedientsProfileShow from './Expedients/Profile/show.json';
import expedientsProfileUpdate from './Expedients/Profile/update.json';
import expedientsProfileConfirm from './Expedients/Profile/confirm.json';
import expedientsAlerts from './Expedients/Alerts.json';
import Contract from './Contract/Contract.json';
import * as Mati from './Mati';
import * as Dashboard from './Dashboard';
import * as Movements from './Movements';
import * as Portfolio from './Portfolio';
import * as Public from './Public';
import * as api from './api';
import * as catalog from './catalog.json';
import * as Forms from './Forms';
import * as Legal from './Legal';

export default {
  Public,
  api,
  catalog,
  components,
  login,
  common,
  settings,
  detailRequisition,
  date,
  summary,
  orders,
  numbers,
  listRequisition,
  addFunds,
  withdraws,
  Expedients,
  expedientsProfileShow,
  expedientsProfileUpdate,
  expedientsProfileConfirm,
  expedientsAlerts,
  Mati,
  Dashboard,
  Movements,
  Portfolio,
  Contract,
  Forms,
  Legal,
  RegisterFunnel,
};
