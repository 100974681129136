import React from 'react';
import PropTypes from 'prop-types';
import {
  css,
  colors,
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';
import { ReactComponent as InfoOutlineIcon } from 'assets/icons/info_outline.svg';
import { ReactComponent as WarnIcon } from 'assets/icons/info-v2.svg';
import Button from 'components/Button/Button';
import { Heading, Text } from 'components/Typography';

const variants = {
  'subtle-outline': {
    info: ({ colorSchema = colors.schema.blue } = {}) => ({
      Icon: InfoOutlineIcon,
      styles: {
        alert: css`
          border-radius: 1rem;
          background-color: ${colorSchema[100]};
          border: 1px solid ${colorSchema[300]};
        `,
        icon: {
          color: colorSchema[500],
        },
      },
    }),
    warn: ({ colorSchema = colors.schema.yellow } = {}) => ({
      Icon: WarnIcon,
      styles: {
        alert: css`
          border-radius: 1rem;
          background-color: ${colorSchema[100]};
          border: 1px solid ${colorSchema[300]};
        `,
        icon: {
          color: colorSchema[500],
        },
      },
    }),
    danger: ({ colorSchema = colors.schema.red } = {}) => ({
      Icon: WarnIcon,
      styles: {
        alert: css`
          border-radius: 1rem;
          background-color: ${colorSchema[100]};
          border: 1px solid ${colorSchema[300]};
        `,
        icon: {
          color: colorSchema[500],
        },
      },
    }),
  },
  subtle: {
    info: ({ colorSchema = colors.schema.blue } = {}) => ({
      Icon: InfoOutlineIcon,
      styles: {
        alert: css`
          border-radius: 1rem;
          background-color: ${colorSchema[100]};
        `,
        icon: {
          color: colorSchema[500],
        },
      },
    }),
  },
  normal: {
    warn: ({ colorSchema = colors.schema.yellow } = {}) => ({
      Icon: WarnIcon,
      styles: {
        alert: css`
          background-color: ${colorSchema[100]};
        `,
      },
      icon: {
        color: colorSchema[500],
      },
    }),
    danger: ({ colorSchema = colors.schema.red } = {}) => ({
      Icon: WarnIcon,
      styles: {
        alert: css`
          background-color: ${colorSchema[100]};
        `,
      },
      icon: {
        color: colorSchema[500],
      },
    }),
  },
};

const AlertContent = styled.div.withConfig({ shouldForwardProp })`
  padding: 2rem;
  display: grid;
  column-gap: 2rem;
  row-gap: 1rem;
  grid-template-columns: 18px 10fr;
  ${boxHelpers}
  ${(props) => props.config.styles.alert}

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 1.5rem;
    column-gap: 1.5rem;
  }
`;

const Body = styled.div``;

function Alert(props) {
  const {
    link,
    title,
    status,
    variant,
    message,
    colorSchema,
    actionButtonProps,
    iconProps = {},
    ...contentProps
  } = props;
  const config = variants[variant][status]({ colorSchema: colors.schema[colorSchema] });

  return (
    <AlertContent
      templateColumns={
        actionButtonProps ? { base: 'auto 10fr', sm: 'auto 10fr 12rem' } : 'auto 10fr'
      }
      config={config}
      {...contentProps}
    >
      <Text as={config.Icon} height="18px" width="18px" {...config.styles.icon} {...iconProps} />
      <Body>
        {title && (
          <Heading as="h3" size="1.4rem" lineHeight="1.6rem" mb="1rem">
            {title}
          </Heading>
        )}
        {(message && !link) && <Text size="1.4rem">{message}</Text>}
        {(message && link) && (
          <Text size="1.4rem">
            {message}
            {' '}
            <Text
              as="a"
              color="schema.blue.500"
              textDecoration="none"
              href={`mailto:${link}`}
            >
              {link}
            </Text>
          </Text>
        )}
      </Body>
      {actionButtonProps && (
        <Button
          gridArea={{ base: '3 / 1 / 3 / -1', sm: '1 / 3 / 1 / 3' }}
          px="1rem"
          {...actionButtonProps}
        />
      )}
    </AlertContent>
  );
}

Alert.defaultProps = {
  status: 'info',
  variant: 'subtle-outline',
  title: null,
  message: null,
};

Alert.propTypes = {
  status: PropTypes.oneOf(['info', 'danger', 'success', 'warn']),
  variant: PropTypes.oneOf(Object.keys(variants)),
  title: PropTypes.string,
  message: PropTypes.node,
};

export default Alert;
