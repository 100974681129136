import styled from 'styled-components';
import { ReactComponent as ZenfiLogoComponent } from 'assets/icons/zenfi-logo.svg';
import { breakPoints } from 'config/constants';
import { Text } from 'components/Typography';
import Button from 'components/Button/Button';
import { Flex } from 'styles';

export const ZenfiContent = styled(Flex)`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  align-items: flex-end;
  justify-content: space-between;
  background-color: #e7f4ff;

  @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
    padding-left: 0;
  }

  @media (max-width: ${breakPoints.tinyBreakPoint}px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const Body = styled.div`
  margin: 2rem 1rem 2rem 1rem;

  @media (max-width: ${breakPoints.tinyBreakPoint}px) {
    text-align: center;
  }
`;

export const Image1 = styled.img``;

export const Image2 = styled.img`
  @media (max-width: ${breakPoints.tinyBreakPoint}px) {
    display: none;
  }
`;

export const ZenfiLogo = styled(ZenfiLogoComponent)``;

export const BodyText = styled(Text)`
  @media (max-width: ${breakPoints.tinyBreakPoint}px) {
    text-align: center;
  }
`;

export const ZenfiLink = styled(Button)`
  margin-top: 1.5rem;
  white-space: nowrap;
`;
