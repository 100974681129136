export const styles = {
  container: {
    gap: '2rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    direction: { base: 'column', sm: 'row' },
  },
  tag: {
    size: 'md',
    variant: 'subtle',
    iconName: 'TIME',
    iconViewBox: '0 0 18 18',
    letterSpacing: '0.5pt',
  },
};
