export const style = {
  container: {
    display: 'flex',
    flexDirection: { base: 'column', sm: 'row' },
    columnGap: '3',
    maxWidth: '100%',
    justifyContent: 'space-between',
    padding: '1rem 0',
    alignItems: 'center',
  },
  innerContainer: {
    maxWidth: 'fit-content',
    margin: '0',
    padding: '1rem 0',
  },
  button: {
    width: { base: '100%', sm: 44 },
    minWidth: { base: '100%', sm: 44 },
  },
  loader: {
    color: 'schema.gray.200',
  },
};
