import { SET_CURRENT_FACTOR_TYPE, SET_SELECTED_FACTOR_TYPE } from 'actions/settings/security/types';

const initialState = {
  currentFactorType: '',
  selectedFactorType: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_FACTOR_TYPE:
      return { ...state, currentFactorType: action.payload };
    case SET_SELECTED_FACTOR_TYPE:
      return { ...state, selectedFactorType: action.payload };
    default:
      return state;
  }
};
