import styled from 'styled-components';
import React from 'react';
import { breakPoints } from 'config/constants';

const Container = ({ isMobile, ...otherProps }) => <div {...otherProps} />;
const CommentsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .comments {
    width: ${(props) => (props.isMobile ? '90%' : '100%')};
    padding-top: ${(props) => (props.isMobile ? '2rem' : '0')};
    &__areaInput {
      border-bottom: 1rem;
    }
    &__input {
      width: 99%;
      height: 11.5rem;
      margin-top: 1rem;
      border-radius: 0.8rem;
      resize: none;
      border: 1px solid #dce3eb;
      background-color: #f8fafc;
      font-size: 1.5rem;
      padding: 1.5rem;
      font-family: Roboto, serif;
      outline: none;
      &:focus {
        border-color: #0076ff;
      }
    }
    &__sendButton {
      position: relative;
      float: right;
      top: -4rem;
      right: 3rem;
      border: none;
      background-color: transparent;
      :hover {
        cursor: pointer;
      }
    }
    &__item {
      clear: both;
      position: relative;
      &__content {
        line-height: 1.5em;
        float: left;
        width: 97%;
        height: auto;
        background-color: #ffffff;
        border: 1px solid #dce3eb;
        padding: 2rem 3rem 1rem 3rem;
        border-radius: 5px;
        margin-bottom: 2rem;
        box-shadow: 0 2px 5px 0 rgba(4, 71, 92, 0.07);
        @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
          width: 96%;
        }
        p {
          -webkit-margin-before: 0;
          -webkit-margin-after: 0;
          margin: 0;
        }
        span {
          float: right;
        }
        &__username {
          color: #00c574;
          margin-bottom: 0.5rem !important;
          &.answer {
            color: white;
          }
        }
        &__text {
          width: 90%;
          word-wrap: break-word;
        }
        &.answer {
          width: 90%;
          background-color: #05c574;
          color: white;
          float: right;
        }
      }
      &__arrow {
        width: 12px;
        height: 20px;
        overflow: hidden;
        position: relative;
        float: left;
        top: 5px;
        right: -1px;
        .outer {
          width: 0;
          height: 0;
          border-right: 20px solid #dce3eb;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
        .inner {
          width: 0;
          height: 0;
          border-right: 20px solid #ffffff;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          position: absolute;
          top: 0;
          left: 2px;
        }
        &.answer {
          left: -1px;
          float: right;
          .inner {
            left: -7px;
            border-left: 20px solid #05c574;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
          .outer {
            left: 14px;
            border-left: 20px solid #05c574;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
      }
    }
  }
  .loaderComments {
    text-align: center;
    margin: 5rem auto 3rem;
  }
`;

export default CommentsContainer;
