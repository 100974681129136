import { useCallback, useState } from 'react';
import { OTP_TYPES } from 'config/constants';
import { useGenerateOTP } from 'hooks';

const onResendOTPDefault = () => {};

export const useAuthorizeWithOTP = ({
  telephone = '',
  is2FAComplete,
  currentSecondFactor,
  isRequestOTPDisabled,
  onResendOTP = onResendOTPDefault,
}) => {
  const factorType = currentSecondFactor?.otpType;
  const {
    time,
    loading,
    sendToSMS,
    sendToEmail,
    remainingSeconds,
  } = useGenerateOTP();
  const [
    countRequestOTPbySMS,
    setCountRequestOTPbySMS,
  ] = useState(0);
  const isFactorEnabled = is2FAComplete && currentSecondFactor !== null;
  const isFactorDisabled = is2FAComplete && currentSecondFactor === null;

  const isFactorTypeValid = [
    OTP_TYPES.SMS,
    OTP_TYPES.EMAIL,
  ].includes(factorType);

  const isWaitingToResend = time > 0;

  const isInvalidSMSToken = time === 0 && countRequestOTPbySMS > 0;

  const isDisabled = loading || isWaitingToResend;

  const onResendToken = useCallback(() => {
    setCountRequestOTPbySMS((count) => count + 1);
    if (factorType === OTP_TYPES.SMS) sendToSMS();
    else if (factorType === OTP_TYPES.EMAIL) sendToEmail();
    onResendOTP();
  }, [
    sendToSMS,
    factorType,
    sendToEmail,
    onResendOTP,
  ]);

  const showSMSContent = useCallback(() => {
    setCountRequestOTPbySMS(0);
  }, [
    setCountRequestOTPbySMS,
  ]);

  const otpFieldProps = {
    telephone,
    remainingSeconds,
    currentFactor: factorType,
    displayWaitingCounter: time > 0,
    isCounterDisabled: isRequestOTPDisabled || time > 0,
    onSendOTPbySMS: onResendToken,
    displayFactorDisabled: isFactorDisabled,
    isFieldDisabled: currentSecondFactor === null,
    displayCounterButton: isFactorTypeValid && countRequestOTPbySMS > 0,
    displayField: isFactorEnabled && (factorType !== OTP_TYPES.SMS || countRequestOTPbySMS > 0),
    displaySMSContent: factorType === OTP_TYPES.SMS && countRequestOTPbySMS === 0,
  };

  return {
    isDisabled,
    otpFieldProps,
    onResendToken,
    showSMSContent,
    isInvalidSMSToken,
    isOTPFieldVisible: factorType !== OTP_TYPES.SMS || countRequestOTPbySMS > 0,
  };
};
