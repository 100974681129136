import isNil from './isNil';

export const toCurrency = (value, digits = 0) => {
  if (isNil(value)) return '-';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MXN',
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
  return formatter.format(value).replace('MX', '');
};

export const toCurrencyWithDecimals = (value, fixed = null) => {
  if (parseFloat(value) === 0 && !fixed) return '$0';
  return toCurrency(value, 2);
};

export const calculatePercentage = (a, b) => {
  const [valA, valB] = [parseFloat(a), parseFloat(b)];
  if (!(valA * valB)) return 0;
  return ((valA / valB) * 100).toFixed(2);
};

export const calculateRemainingPercentage = (a, b) => Math.min(100 - (a * 100) / b, 100);

export const formatQuantityWhitK = (number) => {
  if (parseInt(number, 10) > 1000) return `${toCurrency(number / 1000)}K`;
  return toCurrency(number);
};
