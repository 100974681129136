import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { USER_STATUSES } from 'config/constants';
import { useReduxMutator, useReduxQuery, useReduxLazyQuery } from 'utils/redux-query';
import { dashboardRoute } from 'routes/';
import { handlePrint } from 'utils/window/print';

export const useController = () => {
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const [, { refetch: refetchProfile }] = useReduxLazyQuery('PROFILE');
  const [
    contract,
    {
      refetch: refetchContract,
      status: fetchContractStatus,
      reinitialize: reinitializeContract,
    },
  ] = useReduxQuery('CONTRACT');
  const [updateContract] = useReduxMutator('UPDATE_CONTRACT');
  const displayCard = (fetchContractStatus.completed || fetchContractStatus.pending);

  const onSubmit = async (values) => {
    const { password } = values;
    try {
      await updateContract({ password });
      reinitializeContract();
      refetchProfile();
      return null;
    } catch (error) {
      return {
        password: 'Contraseña invalida',
      };
    }
  };

  const printDocument = () => handlePrint({ htmlContent: contract.document });

  useEffect(() => {
    if (!session) return;
    if (session.user.status !== USER_STATUSES.CONTRACT_UNSIGNED) history.push(dashboardRoute);
  }, [session]);

  return {
    contract,
    onSubmit,
    displayCard,
    printDocument,
    refetchContract,
    fetchContractStatus,
  };
};
