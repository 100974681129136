import styled, { css } from 'styled-components';
import colors from 'styles/colors';

const ChoiceSelectorContainer = styled.div`
  ${({ disabled }) => (disabled
    ? css`
      opacity: 0.5;
    `
    : '')}
  margin-right: -1.4rem;
  .option__button {
    margin-right: 1.6rem;
    margin-bottom: 1rem;
    display: inline-block;
    & input {
      appearance: none;
      display: none;
    }
    & label {
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: white;
      border: 1px solid #a2bcc5;
      font-size: 1.3rem;
      color: ${colors.schema.gray[400]};
      height: 4rem;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
      transition: all 300ms ease-in-out;
      font-weight: 600;
    }
    & input:disabled + label {
      border-color: ${colors.schema.gray[300]};
      background-color: ${colors.schema.gray[100]};
    }
    & input:enabled + label {
      &:hover {
        transform: translateY(-2px);
        background-color: ${colors.gray.g2};
        color: ${colors.gray.g5};
        cursor: pointer;
        box-shadow: 0 12px 9px -6px rgba(1, 46, 71, 0.15) !important;
      }
      &:active {
        transform: translateY(1px);
      }
    }
    & input:enabled:checked + label {
      background-color: ${colors.schema.blue[100]};
      color: ${colors.schema.blue[500]};
      border: 1px solid ${colors.schema.blue[500]};
    }
    &.big-label {
      margin-right: 1.2rem;
    }
  }
`;

export default ChoiceSelectorContainer;
