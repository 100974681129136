/**
 *
 * @param {Object} object
 *
 * @description Filter object key which contains null or undefined as value
 *
 */

const clearErrorsObject = (object) => Object.entries(object).reduce(
  (outputObject, [key, value]) => (
    [undefined, null].includes(value) ? outputObject : { ...outputObject, [key]: value }
  ),
  {},
);

export default clearErrorsObject;
