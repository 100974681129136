import {
  css,
  colors,
  styled,
  shouldForwardProp,
  boxHelpers,
} from 'styles';
import { animated } from 'react-spring';

export const SelectFieldInput = styled(animated.select).withConfig({ shouldForwardProp })`
  border: 1px solid;
  border-radius: 10px;
  border-color: ${colors.schema.gray[300]};
  background-color: white;
  color:  ${colors.schema.gray[700]};
  outline: none;
  width: 100%;
  height: 4.5rem;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: normal;
  appearance: none;
  grid-area: ${(props) => props.gridArea};
  ${boxHelpers}

  ${(props) => (props.disabled
    ? css`
        cursor: not-allowed;
        border-color: ${colors.schema.gray[300]};
        background-color: ${colors.schema.gray[100]};
    `
    : '')}

  &:focus {
    border-width: 1.5px;
    border-color: ${({ hasError }) => (hasError
    ? colors.schema.red[500]
    : colors.schema.blue[500]
  )};
  }
`;
