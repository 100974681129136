import {
  isDevelopment,
  getSnowplowConfig,
  getGoogleTagManagerConfig,
} from 'config';
import googleTagManagerTracker from './google-tag-manager';
import snowplowTracker from './snowplow';

function getTracker() {
  const loggers = {
    snowplow: snowplowTracker,
    googleTagManager: googleTagManagerTracker,
  };

  const init = () => {
    if (isDevelopment()) return;
    loggers.googleTagManager.init(getGoogleTagManagerConfig());
    loggers.snowplow.init(getSnowplowConfig());
  };

  const addContext = (context) => {
    if (isDevelopment()) return;
    loggers.googleTagManager.addContext(context);
    loggers.snowplow.addContext(context);
  };

  return {
    init,
    addContext,
  };
}

export default getTracker();
