import styled, { css } from 'styled-components';
import { textHelper, shouldForwardProp } from 'styles';
import PropTypes from 'prop-types';
import colors from 'styles/colors';
import buttonTypes from './ButtonTypesCSS';

const sizes = {
  small: css`
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 0 2.2rem;
    height: 3rem;
  `,
  normal: css`
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 0 3rem;
    height: 4.2rem;
  `,
  big: css`
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 0 3rem;
    height: 5.6rem;
  `,
};

const Button = styled.button.withConfig({ shouldForwardProp })`
  display: Grid;
  place-items: center;
  transition: all 0.3s ease;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  border: none;
  text-align: center;
  text-decoration: none;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 3px 40px rgba(4, 71, 92, 0.1);
  }

  &:active {
    transform: translateY(1px);
  }

  ${({ $isNotAnimated }) => ($isNotAnimated
    ? css`
          &:hover {
            transform: none;
          }

          &:active {
            transform: none;
          }
        `
    : '')}

  &[disabled=''] {
    background-color: ${colors.schema.gray[200]} !important;
    color: ${colors.schema.gray[400]} !important;
    cursor: not-allowed;
    border: none;
    box-shadow: none;

    &:hover {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  ${({ $size }) => sizes[$size]}
  ${({ $type }) => buttonTypes[$type]}
  ${textHelper}
`;

Button.defaultProps = {
  $type: 'primary',
  $size: 'normal',
};

Button.propTypes = {
  $type: PropTypes.oneOf(Object.keys(buttonTypes)),
};

export default Button;
