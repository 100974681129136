import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import camelify from 'camelcase-keys-recursive';
import { ReactComponent as CellphoneIcon } from 'assets/icon-cellphone.svg';
import { ReactComponent as DesktopIcon } from 'assets/icon-desktop.svg';
import { t } from 'i18n';
import { MOBILE_DEVICES_TYPES } from 'config/constants';
import { LastConnectionContainer, LastConnectionBrowserTitle } from './DeviceInfoSection.style';

const sessionInfoDefaultValue = { session_information: {} };

function DeviceInfoSection() {
  const sessionInfo = useSelector((state) => state.auth?.sessionsInfo);

  const { sessionInformation: { operativeSystem, browser, deviceType } = {}, lastSignIn } = useMemo(
    () => camelify(sessionInfo || sessionInfoDefaultValue),
    [sessionInfo],
  );

  const formattedDate = useMemo(
    () => (lastSignIn ? moment(lastSignIn).format('DD/MM/Y h:mm:ss a') : ''),
    [lastSignIn],
  );
  const isDeviceMobile = useMemo(
    () => Object.values(MOBILE_DEVICES_TYPES).includes(deviceType),
    [deviceType],
  );

  return (
    <LastConnectionContainer>
      <LastConnectionBrowserTitle>
        {t('settings.security.lastConnection', { date: formattedDate })}
      </LastConnectionBrowserTitle>
      <div className="device_info">
        {isDeviceMobile && <CellphoneIcon title="" />}
        {!isDeviceMobile && <DesktopIcon title="" />}
        <span>
          {operativeSystem}
          {' '}
          {browser}
        </span>
      </div>
    </LastConnectionContainer>
  );
}

export default DeviceInfoSection;
