import React, { useState } from 'react';
import sendToast from 'utils/toastHandler';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { Flex } from 'styles';
import PasswordConfirmationContainer from './styles';

const PasswordConfirmation = ({
  onSubmitForm, textButton, isSubmitting, stacked,
}) => {
  const [password, setPassword] = useState('');
  const validateBeforeSubmit = (e) => {
    e.preventDefault();
    if (!password) return sendToast('error', 'Ingresa tu contraseña');
    return onSubmitForm(password);
  };

  return (
    <PasswordConfirmationContainer>
      <Flex as="form" direction={stacked ? 'column' : 'row'} onSubmit={validateBeforeSubmit}>
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          data-testid="sign-risks-input"
        />
        <Button
          color="success"
          className="button"
          data-testid="sign-risks-buttons"
          disabled={isSubmitting}
          handleClick={validateBeforeSubmit}
        >
          {!isSubmitting ? textButton : <DotsLoader />}
        </Button>
      </Flex>
    </PasswordConfirmationContainer>
  );
};

export default PasswordConfirmation;
