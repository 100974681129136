export const globalStyle = {
  html: { fontSize: '10px' },
  body: {
    fontSize: '10px',
    bgColor: 'gray.50',
    color: 'gray.700',
  },
  a: {
    _hover: {
      textDecoration: 'none',
    },
  },
  h1: {
    fontSize: '3.6rem',
    lineHeight: '4.4rem',
    color: 'gray.700',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: '3rem',
    lineHeight: '3.7rem',
    color: 'gray.700',
    fontWeight: 'bold',

  },
  h3: {
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    color: 'gray.700',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: 'gray.700',
    fontWeight: 'bold',
  },
  p: {
    fontSize: '1.4rem',
    lineHeight: '2rem',
    color: 'gray.500',
  },
};

export const themeConfig = {
  fontSizes: {
    '3xs': '6.3px',
    '2xs': '8.75px',
    xs: '10.5px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '21px',
    '3xl': '24.5px',
    '4xl': '28px',
    '5xl': '35px',
    '6xl': '42px',
    '7xl': '49px',
    '8xl': '56px',
    '9xl': '70px',
  },
  sizes: {
    1: '3.5px',
    2: '7px',
    3: '10.5px',
    4: '14px',
    5: '17.5px',
    6: '21px',
    7: '24.5px',
    8: '28px',
    9: '31.5px',
    10: '35px',
    12: '42px',
    14: '49px',
    16: '56px',
    20: '70px',
    24: '84px',
    28: '98px',
    32: '112px',
    36: '126px',
    40: '140px',
    44: '154px',
    48: '168px',
    52: '182px',
    56: '196px',
    60: '210px',
    64: '224px',
    72: '252px',
    80: '280px',
    96: '336px',
    100: '350px',
    '3xs': '196px',
    '2xs': '224px',
    xs: '280px',
    sm: '336px',
    md: '392px',
    lg: '448px',
    xl: '504px',
    '2xl': '588px',
    '3xl': '672px',
    '4xl': '784px',
    '5xl': '896px',
    '6xl': '1008px',
    '7xl': '1120px',
    '8xl': '1260px',
  },
  lineHeights: {
    3: '10.5px',
    4: '14px',
    5: '17.5px',
    6: '21px',
    7: '24.5px',
    8: '28px',
    9: '31.5px',
    10: '35px',
  },
  radii: {
    sm: '1.75px',
    base: '3.5px',
    md: '5.25px',
    lg: '7px',
    xl: '10.5px',
    '2xl': '14px',
    '3xl': '21px',
  },
  space: {
    px: '1px',
    0: '0px',
    1: '3.5px',
    2: '7px',
    3: '10.5px',
    4: '14px',
    5: '17.5px',
    6: '21px',
    7: '24.5px',
    8: '28px',
    9: '31.5px',
    10: '35px',
    12: '42px',
    14: '49px',
    16: '56px',
    20: '70px',
    24: '84px',
    28: '98px',
    32: '112px',
    36: '126px',
    40: '140px',
    44: '154px',
    48: '168px',
    52: '182px',
    56: '196px',
    60: '210px',
    64: '224px',
    72: '252px',
    80: '280px',
    96: '336px',
  },
};
