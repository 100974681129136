import React from 'react';
import DayPicker from 'react-day-picker';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import { setCurrentDate, getPaymentSchedule } from 'actions/calendar/calendarActions';
import 'react-day-picker/lib/style.css';
import 'moment/locale/es';
import CalendarPickerContainer from './styles';

function CalendarPicker({
  date, setDate, getNextSchedule, currentMonth,
}) {
  const getMonthId = (dateObj) => moment(dateObj).format('YYYYMM');

  const getDateComponents = (dateObj) => {
    const d = moment(dateObj);
    const year = d.format('YYYY');
    const month = d.format('MM');
    const day = d.format('DD');
    return { year, month, day };
  };

  const handleDayClick = (newDate) => {
    const { year, month, day } = getDateComponents(newDate);

    if (date.year === year && date.month === month) {
      setDate({ year, month, day });
    } else {
      getNextSchedule({ year, month, day });
    }
  };

  const handleMonthChange = (newDate) => getNextSchedule(getDateComponents(newDate));

  const selectedDate = date.month
    ? moment(`${date.year}-${date.month}-${date.day}`).toDate()
    : moment().toDate();

  const hasPayments = (dateObj) => {
    if (getMonthId(selectedDate) !== getMonthId(dateObj)) return false;

    const day = dateObj.getDate();
    const dayStr = `${day < 10 ? '0' : ''}${day}`;
    return (currentMonth[dayStr] || []).length > 0;
  };

  return (
    <CalendarPickerContainer>
      <DayPicker
        initialMonth={selectedDate}
        onDayClick={handleDayClick}
        onMonthChange={handleMonthChange}
        selectedDays={selectedDate}
        showOutsideDays
        localeUtils={MomentLocaleUtils}
        modifiers={{ hasPayments }}
        locale="es"
      />
    </CalendarPickerContainer>
  );
}

const mapStateToProps = ({ calendar }) => ({
  date: calendar.date,
  currentMonth: calendar.currentMonth || {},
});

const mapDispatchToProps = {
  setDate: setCurrentDate,
  getNextSchedule: getPaymentSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPicker);
