import * as apiSession from 'apis/auth/session';
import { createActions, createAsyncAction } from 'utils/redux-query';
import { getUpdatePasswordUrl, getPublicSiteUrl } from 'config';
import { CURRENT_ROLE } from 'config/constants';

export const {
  setMeta: setSessionMeta,
  setPayload: setSessionPayload,
  setError: setSessionError,
  update: updateSession,
} = createActions('SESSION');

export const {
  setMeta: setSessionStatusMeta,
  setPayload: setSessionStatusPayload,
  setError: setSessionStatusError,
  reinitialize: reinitializeSessionStatus,
  update: updateSessionStatus,
} = createActions('SESSION_STATUS');

export const fetchSession = createAsyncAction('SESSION', () => async () => {
  const session = await apiSession.fetchSession();
  if (!session.settings.passwordChangeDate) {
    window.location.assign(getUpdatePasswordUrl());
    return null;
  }

  if (!session.user.rolesName.includes(CURRENT_ROLE)) {
    window.location.href = getPublicSiteUrl();
    return null;
  }

  return session;
});

export const setSessionSetting = (setting, value) => (dispatch, getState) => {
  const currentSettings = getState().SESSION.payload;

  dispatch(
    setSessionPayload({
      ...currentSettings,
      settings: {
        ...currentSettings.settings,
        [setting]: value,
      },
    }),
  );
};

export const setSessionLimits = (callback) => (dispatch, getState) => {
  const { limits = null, ...restSession } = getState().SESSION.payload;

  const limitsUpdated = callback(limits);

  if (!limitsUpdated) return;

  dispatch(
    setSessionPayload({
      ...restSession,
      limits: limitsUpdated,
    }),
  );
};

export const createSession = createAsyncAction(
  'CREATE_SESSION',
  ({ email, password }) => async (dispatch) => {
    const session = await apiSession.createSession({ email, password });

    if (!session.settings.passwordChangeDate) {
      window.location.assign(getUpdatePasswordUrl());
      return null;
    }

    if (!session.user.rolesName.includes(CURRENT_ROLE)) {
      window.location.href = getPublicSiteUrl();
      return null;
    }

    dispatch(updateSession(session, { status: 'COMPLETED' }));

    return session;
  },
);

export const setExpiredByInactivity = () => updateSessionStatus(
  { reason: 'idle' },
  { status: 'COMPLETED' },
);
