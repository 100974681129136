import React, { useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Flex } from 'styles';
import Divider from 'components/Divider';
import { useReduxQuery } from 'utils/redux-query';
import { Section, Item } from 'components/AsideMenu/AsideMenu';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as DepositIcon } from 'assets/icons/incomes.svg';
import { ReactComponent as INEIcon } from 'assets/icons/ine.svg';
import { ReactComponent as OccupationIcon } from 'assets/icons/occupation.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/mapMarker.svg';
import { ReactComponent as BankIcon } from 'assets/icons/bank.svg';
import { ReactComponent as WrittenEvidenceIcon } from 'assets/icons/WrittenEvidence.svg';
import { t } from 'i18n';

function AsideMenu(contentProps) {
  const match = useRouteMatch();
  const lessThanTablet = useSelector(({ browser }) => browser.lessThan.tablet);
  const matchProfile = useRouteMatch(`${match.url}/profile`);
  const matchDeposits = useRouteMatch(`${match.url}/deposits`);
  const matchOccupation = useRouteMatch(`${match.url}/occupation`);
  const matchRiskCer = useRouteMatch(`${match.url}/risk_cer`);
  const matchAddress = useRouteMatch(`${match.url}/address`);
  const matchIdentification = useRouteMatch(`${match.url}/identification`);
  const matchBanking = useRouteMatch(`${match.url}/banking`);
  const [personalArchives, { status: personalArchivesStatus }] = useReduxQuery('PERSONAL_ARCHIVES');
  const orderedList = useMemo(
    () => Object.entries({
      personalInformation: personalArchives?.personalInformation,
      ocupation: personalArchives?.ocupation,
      kycActivities: personalArchives?.kycActivities,
      cer: personalArchives?.cer,
      id: personalArchives?.id,
      address: personalArchives?.address,
      bankAccount: personalArchives?.bankAccount,
    })
      .filter(([, value]) => value !== undefined)
      .reduce((acc, [key], index) => ({ ...acc, [key]: { step: index + 1 } }), {}),
    [personalArchives],
  );

  const totalRequirements = useMemo(() => Object.keys(orderedList).length, [orderedList]);

  const [isOpenMenu, setOpenMenu] = useState(false);
  const isOpenMenuOnMobile = lessThanTablet && isOpenMenu;

  const displayProfileItem = personalArchives?.personalInformation !== undefined
    && (lessThanTablet ? [isOpenMenu, matchProfile].some(Boolean) : true);

  const displayOccupationItem = personalArchives?.ocupation !== undefined
    && (lessThanTablet ? [isOpenMenu, matchOccupation].some(Boolean) : true);

  const displayKYCActivitiesItem = personalArchives?.kycActivities !== undefined
    && (lessThanTablet ? [isOpenMenu, matchDeposits].some(Boolean) : true);

  const displayIdItem = personalArchives?.id !== undefined
    && (lessThanTablet ? [isOpenMenu, matchIdentification].some(Boolean) : true);

  const displayAddressItem = personalArchives?.address !== undefined
    && (lessThanTablet ? [isOpenMenu, matchAddress].some(Boolean) : true);

  const displayBankAccountItem = personalArchives?.bankAccount !== undefined
    && (lessThanTablet ? [isOpenMenu, matchBanking].some(Boolean) : true);

  const displayCERItem = personalArchives?.cer !== undefined
    && (lessThanTablet ? [isOpenMenu, matchRiskCer].some(Boolean) : true);

  const displaySection1 = [displayProfileItem, isOpenMenuOnMobile].some(Boolean);

  const displaySection2 = [
    displayOccupationItem,
    displayKYCActivitiesItem,
    displayCERItem,
    isOpenMenuOnMobile,
  ].some(Boolean);

  const displaySection3 = [
    displayIdItem,
    displayAddressItem,
    displayBankAccountItem,
    isOpenMenuOnMobile,
  ].some(Boolean);

  return (
    <Flex
      direction="column"
      p={{ base: '0', sm: '4rem 0' }}
      rowGap="1rem"
      bgColor="schema.gray.100"
      position={{ base: 'absolute', sm: 'relative' }}
      w={{ base: '100%', sm: 'relative' }}
      zIndex={{ base: '10', sm: '1' }}
      {...contentProps}
    >
      {personalArchivesStatus.completed && (
        <>
          <Section
            display={{
              base: displaySection1 ? 'flex' : 'none',
              sm: 'flex',
            }}
          >
            {displayProfileItem && (
              <Item
                Icon={UserIcon}
                to={`${match.url}/profile/show`}
                actived={matchProfile || isOpenMenuOnMobile}
                hidden={!displayProfileItem}
                showProgressBar={matchProfile}
                checked={personalArchives?.personalInformation}
                disabled={personalArchives?.personalInformation === null}
                stepNumber={orderedList.personalInformation?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.personalInfo')}
              </Item>
            )}
          </Section>
          <Divider
            color="schema.gray.300"
            display={{ base: 'none', sm: displaySection2 ? 'block' : 'none' }}
          />
          <Section
            display={{
              base: displaySection2 ? 'flex' : 'none',
              sm: 'flex',
            }}
          >
            {displayOccupationItem && (
              <Item
                Icon={OccupationIcon}
                actived={matchOccupation || isOpenMenuOnMobile}
                hidden={!displayOccupationItem}
                showProgressBar={matchOccupation}
                to={`${match.url}/occupation/create`}
                checked={personalArchives?.ocupation}
                disabled={personalArchives?.ocupation === null}
                stepNumber={orderedList.ocupation?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.occupation')}
              </Item>
            )}
            {displayKYCActivitiesItem && (
              <Item
                Icon={DepositIcon}
                actived={matchDeposits || isOpenMenuOnMobile}
                hidden={!displayKYCActivitiesItem}
                showProgressBar={matchDeposits}
                to={`${match.url}/deposits/create`}
                checked={personalArchives?.kycActivities}
                disabled={personalArchives?.kycActivities === null}
                stepNumber={orderedList.kycActivities?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.deposits')}
              </Item>
            )}
            {displayCERItem && (
              <Item
                Icon={WrittenEvidenceIcon}
                actived={matchRiskCer || isOpenMenuOnMobile}
                hidden={!displayCERItem}
                showProgressBar={matchRiskCer}
                to={`${match.url}/risk_cer`}
                checked={personalArchives?.cer}
                disabled={personalArchives?.cer === null}
                stepNumber={orderedList.cer?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.cer')}
              </Item>
            )}
          </Section>
          <Divider
            color="schema.gray.300"
            display={{ base: 'none', sm: displaySection3 ? 'block' : 'none' }}
          />
          <Section
            display={{
              base: displaySection3 ? 'flex' : 'none',
              sm: 'flex',
            }}
          >
            {displayIdItem && (
              <Item
                Icon={INEIcon}
                actived={matchIdentification || isOpenMenuOnMobile}
                hidden={!displayIdItem}
                showProgressBar={matchIdentification}
                to={`${match.url}/identification/update`}
                checked={personalArchives?.id}
                disabled={personalArchives?.id === null}
                stepNumber={orderedList.id?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.id')}
              </Item>
            )}
            {displayAddressItem && (
              <Item
                Icon={MarkerIcon}
                actived={matchAddress || isOpenMenuOnMobile}
                hidden={!displayAddressItem}
                showProgressBar={matchAddress}
                to={`${match.url}/address/show`}
                checked={personalArchives?.address}
                disabled={
                  personalArchives?.address === null
                }
                stepNumber={orderedList.address?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.address')}
              </Item>
            )}
            {displayBankAccountItem && (
              <Item
                Icon={BankIcon}
                actived={matchBanking || isOpenMenuOnMobile}
                hidden={!displayBankAccountItem}
                showProgressBar={matchBanking}
                to={`${match.url}/banking/show`}
                checked={personalArchives?.bankAccount}
                disabled={personalArchives?.bankAccount === null}
                stepNumber={orderedList.bankAccount?.step}
                totalSteps={totalRequirements}
                onClick={() => setOpenMenu((isOpen) => !isOpen)}
              >
                {t('Expedients.AsideMenu.items.bankAccount')}
              </Item>
            )}
          </Section>
        </>
      )}
    </Flex>
  );
}

export default AsideMenu;
