export const styles = {
  fileField: {
    width: 'full',
  },
  alert: {
    width: '100%',
    display: 'flex',
    variant: 'subtle',
    borderRadius: 'lg',
    padding: 'lg',
    paddingY: '4',
    status: 'success',
    overflow: 'clip',
  },
  alertIcon: {
    boxSize: '5',
    marginRight: '2',
    color: 'green.500',
  },
  alertTitle: {
    fontSize: 'md',
  },
  fileIcon: {
    boxSize: '5',
    color: 'gray.500',
  },
};
