import React, { useState } from 'react';
import { useTransition, animated } from 'react-spring';

import { toggleHiddenRequisitions } from 'apis/requisitions';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { ReactComponent as HideIcon } from 'assets/hideIcon.svg';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import BatchActionBarContainer from './styles';

const BatchActionBar = ({
  selectedRequisitionsQuantity,
  selectedRequisitions,
  showHiddenRequisitions,
  updateFilter,
  appliedFilter,
  cleanHiddenRequisitions,
  cleanSelectedRequisitions,
  animation,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [hidingRequisitions, setHidingRequisitions] = useState(false);

  const handleInputChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setInputValue(event.target.value);
    }
  };

  const animationStyles = {
    from: { opacity: 0 },
    to: { opacity: 1 },
  };

  const inputTransition = useTransition(showInput, null, {
    from: animationStyles.from,
    enter: animationStyles.to,
    leave: animationStyles.from,
    unique: true,
  });

  const inputButtonTransition = useTransition(inputValue !== '', null, {
    from: animationStyles.from,
    enter: animationStyles.to,
    leave: animationStyles.from,
    unique: true,
  });

  const renderInputButton = inputButtonTransition.map(
    ({ item, key, props: inputButtonAnimation }) => item && (
    <animated.div style={inputButtonAnimation} key={key}>
      <Button type="button">
        <CartIcon title="" />
      </Button>
    </animated.div>
    ),
  );

  const renderInput = inputTransition.map(
    ({ item, key, props: inputAnimation }) => item && (
    <animated.div className="input__container" style={inputAnimation} key={key}>
      <div className="input__container__body">
        <span>$</span>
        {' '}
        <input type="text" value={inputValue} onChange={handleInputChange} />
      </div>
      {renderInputButton}
    </animated.div>
    ),
  );
  const showQuantityInput = () => setShowInput(true);
  const handleRequisitionHideability = async () => {
    setHidingRequisitions(true);
    const response = await toggleHiddenRequisitions(selectedRequisitions, true);
    if (response) {
      await updateFilter(appliedFilter);
      cleanSelectedRequisitions();
    } else {
      cleanHiddenRequisitions();
    }
    setHidingRequisitions(false);
    return response;
  };

  return (
    <BatchActionBarContainer style={animation}>
      <div className="requisitions_quantity">
        <span className="quantity">{selectedRequisitionsQuantity}</span>
        <div>
          <p>solicitudes</p>
          <p>seleccionadas</p>
        </div>
      </div>
      <div className="quick_options">
        <p> Aplicar a selección:</p>
        <div className="quick_selection_options">
          <Button color="info">
            <CartIcon />
            <p>$200</p>
          </Button>
          <Button color="info">
            <CartIcon />
            <p>$300</p>
          </Button>
          <Button color="info">
            <CartIcon />
            <p>$500</p>
          </Button>
          {showInput ? (
            renderInput
          ) : (
            <Button color="info" onClick={showQuantityInput}>
              <p>Otro</p>
            </Button>
          )}
        </div>
      </div>
      <div className="requisitions_settings">
        {!showHiddenRequisitions && (
          <button type="button" onClick={handleRequisitionHideability}>
            {hidingRequisitions ? <DotsLoader black /> : <HideIcon title="" />}
          </button>
        )}
      </div>
    </BatchActionBarContainer>
  );
};

export default BatchActionBar;
