import React from 'react';
import {
  Box, Button, Grid, HStack,
} from 'ui';
import { AuthorizeWithOTPField } from 'components/AuthorizeWithOTPField';
import { CheckboxField } from 'components/FormFields';
import { useController } from './hooks';

export const CreateAutoinvestForm = ({
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const {
    control,
    onSubmit,
    isLoading,
    otpFieldProps,
    contractDocument,
    isModalOpen,
    onConfirmLendingCriteria,
  } = useController({ onSuccess });

  if (!isModalOpen) {
    return (
      <HStack justifyContent="space-between" width="full">
        <Button
          variant="outline"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirmLendingCriteria}
        >
          Confirmar
        </Button>
      </HStack>
    );
  }

  return (
    <Grid id="contractForm" as="form" onSubmit={onSubmit} gap="5" width="full">
      <Box
        as="iframe"
        gridArea="1 / 1 / 1 / 3"
        width="full"
        bgColor="gray.50"
        borderRadius="lg"
        padding="5"
        height="3xl"
        srcDoc={contractDocument}
        frameBorder="0"
        title="Contrato de activación de Autoinvest"
      />
      <CheckboxField
        name="accept"
        control={control}
        gridArea="2 / 1 / 2 / 3"
      >
        Confirmo que he leído y acepto los términos y condiciones de Autoinvest
      </CheckboxField>
      <AuthorizeWithOTPField
        {...otpFieldProps}
        name="otp"
        control={control}
        isDisabled={isLoading}
        gridArea="3 / 1 / 3 / 3"
        maxWidth="xs"
        justifySelf="center"
      />
      <Button
        variant="outline"
        onClick={onCancel}
        justifySelf="start"
        isDisabled={isLoading}
        gridArea="4 / 1 / 4 / 2"
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        justifySelf="end"
        isDisabled={isLoading}
        gridArea="4 / 2 / 4 / 3"
      >
        Activar
      </Button>
    </Grid>
  );
};
