import { request } from '../../fetchers/yotepresto';

const fetch = async () => {
  const config = {
    method: 'get',
    path: '/v1/investor/contract',
  };
  const { data } = await request(config);

  return data.contract;
};

export default fetch;
