import React from 'react';
import Styles from './styles';

const sizes = (size) => ({
  normal: {
    radius: '5.5px',
    boxWidth: '64px',
    boxHeight: '12px',
    gap: ['6px', '6px', '26px', '45px'],
    translate: '19px',
  },
  small: {
    radius: '3px',
    boxWidth: '34px',
    boxHeight: '7px',
    gap: ['0px', '0px', '14px', '28px'],
    translate: '14px',
  },
}[size]);

const DotsLoader = ({ black, size, ...contentProps }) => {
  const config = {
    size: sizes(size),
  };

  return (
    <Styles black={black} config={config} {...contentProps}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </Styles>
  );
};

DotsLoader.defaultProps = {
  size: 'normal',
};

export default DotsLoader;
