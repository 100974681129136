import { tscope } from 'i18n';
import { CREDIT_CARD_PLAZA_CODE } from 'config/constants';

const errorsT = tscope('Forms.fields.errors');
const ts = tscope('components.AddBankAccountForm');

const buildError = ({ message }) => ({
  message,
  type: 'danger',
});

export const validateIsCreditCard = ({ clabe }) => {
  const creditCardCode = clabe?.slice(3, 6) || null;
  if (!creditCardCode) return null;
  return creditCardCode === String(CREDIT_CARD_PLAZA_CODE);
};

export const validateIsBankNotFound = ({ clabe, bankCatalog }) => {
  const ABMClabe = clabe?.slice(0, 3);
  if (!ABMClabe || ABMClabe.length < 3 || !bankCatalog) return null;
  const isFound = bankCatalog.filter(({ abmNumber }) => abmNumber === ABMClabe);
  return isFound.length === 0;
};

export const resolver = (values, context) => {
  const {
    bankCatalog,
  } = context;
  const {
    alias,
    clabe,
    bank,
    token,
  } = values;
  const errors = {};

  if (!alias) errors.alias = buildError({ message: errorsT('required') });
  if (!clabe) errors.clabe = buildError({ message: errorsT('required') });
  if (validateIsCreditCard({ clabe })) errors.clabe = buildError({ message: ts('formFields.clabe.errors.isCreditCard') });
  if (validateIsBankNotFound({ clabe, bankCatalog })) errors.clabe = buildError({ message: ts('formFields.clabe.errors.ABMNotFound') });
  if (clabe && clabe.length < 18) errors.clabe = buildError({ message: errorsT('length', { count: 18 }) });
  if (!bank) errors.bank = buildError({ message: errorsT('required') });
  if (!token) errors.token = buildError({ message: errorsT('required') });

  return {
    values,
    errors,
  };
};
