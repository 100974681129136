export const debounceLeading = (func, timeout = 300) => {
  let pid;
  return (...args) => {
    if (!pid) {
      func(args);
    }
    clearTimeout(pid);
    pid = setTimeout(() => {
      pid = undefined;
    }, timeout);
  };
};
