import flowright from 'lodash.flowright';

export const matchURLids = (values = {}) => (path) => path
  .split('/')
  .map((subStr) => (/^:\w+$/.test(subStr) && values[subStr.slice(1)] ? values[subStr.slice(1)] : subStr))
  .join('/');

export const addQueryParams = (params = {}) => (path) => [
  path,
  Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join('&'),
]
  .filter(Boolean)
  .join('?');

export const createPathHelpers = (endpoints) => Object.entries(endpoints).reduce(
  (paths, [endpointName, endpointPath]) => ({
    ...paths,
    [`${endpointName}Path`]: (params, queryParams) => flowright(addQueryParams(queryParams), matchURLids(params))(endpointPath),
  }),
  {},
);
