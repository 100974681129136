import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const COUNTRY_SELECT_GQL = gql`
query COUNTRY_SELECT_GQL {
  catalogs {
    id
    countries {
      id
      name
    }
  }
}
`;

export const useCatalog = (options = {}) => useQuery(
  COUNTRY_SELECT_GQL,
  {
    ...options,
    context: {
      clientName: 'public',
    },
  },
);
