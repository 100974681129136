import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import {
  t,
  tscope,
  toCurrency,
} from 'i18n';
import {
  Box,
  Grid,
  Flex,
  Modal,
  Center,
  Button,
  Heading,
  Divider,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalOverlay,
  ButtonCheckbox,
  RadioButtonField,
  CheckCircleIcon,
  Text,
  VStack,
  ModalCloseButton,
} from 'ui';
import {
  TextField,
  ButtonCheckboxGroup,
  RadioButtonGroupField,
} from 'components/FormFields';
import {
  useController,
  TERM_OPTIONS,
  GRADE_OPTIONS,
  PURPOSE_OPTIONS,
} from './hooks';
import { DisplayAutoinvestConfig } from '../DisplayAutoinvestConfig';
import {
  CreateAutoinvestForm,
  UpdateAutoinvestForm,
} from './components';
import { AMOUNT_OPTIONS } from './hooks/useController';

const ts = tscope('settings.autoinvest.EditAutoinvestForm');

export const EditAutoinvestForm = (props) => {
  const {
    control,
    onSubmit,
    onSelectAll,
    isAllSelected,
    onSelectAllTerm,
    onSuccessEdition,
    isAllSelectedTerm,
    isConfirmModalOpen,
    isAutoinvestEditing,
    onSelectAllPurposes,
    onCancelConfirmModal,
    isAllSelectedPurposes,
  } = useController();

  return (
    <>
      <Grid as="form" gap="10" onSubmit={onSubmit} {...props}>
        <Center flexDirection="column">
          <Heading>Selecciona tus criterios de préstamo</Heading>
          <Button
            rightIcon={isAllSelected ? <CheckCircleIcon /> : null}
            variant="link"
            onClick={onSelectAll}
          >
            {isAllSelected
              ? 'Deseleccionar todos los parámetros'
              : 'Seleccionar todos los parámetros'}
          </Button>
        </Center>
        <Box>
          <Flex
            marginBottom="5"
          >
            <Heading
              as="label"
              fontWeight="medium"
              fontSize="lg"
            >
              {ts('gradeTitle')}
            </Heading>
          </Flex>
          <ButtonCheckboxGroup
            control={control}
            name="grade"
            innerContentProps={{
              gap: '5',
              display: 'grid',
              justifyItems: 'stretch',
              gridTemplateColumns: {
                base: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
              },
            }}
          >
            {GRADE_OPTIONS.map((grade) => (
              <ButtonCheckbox key={grade} value={grade}>
                {ts(`gradeOptions.${grade}`)}
              </ButtonCheckbox>
            ))}
          </ButtonCheckboxGroup>
        </Box>

        <Box>
          <Flex
            marginBottom="5"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Heading
              as="label"
              fontWeight="medium"
              fontSize="lg"
            >
              {ts('termTitle')}
            </Heading>
            <Button
              variant="link"
              fontWeight="normal"
              alignSelf={{ base: 'flex-end', md: 'auto' }}
              onClick={onSelectAllTerm}
              rightIcon={isAllSelectedTerm ? <CheckCircleIcon /> : null}
            >
              {isAllSelectedTerm
                ? 'Deseleccionar todos los plazos'
                : ts('allTerm')}
            </Button>
          </Flex>
          <ButtonCheckboxGroup
            control={control}
            name="term"
            innerContentProps={{
              gap: '5',
              display: 'grid',
              justifyItems: 'stretch',
              gridTemplateColumns: { base: 'repeat(2, 1fr)', sm: 'repeat(6, 1fr)' },
            }}
          >
            {TERM_OPTIONS.map((term) => (
              <ButtonCheckbox key={term} value={term} flexGrow="1">
                {t('common.month', { count: term })}
              </ButtonCheckbox>
            ))}
          </ButtonCheckboxGroup>
        </Box>

        <Box>
          <Flex
            marginBottom="5"
            justifyContent="space-between"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Heading
              as="label"
              fontWeight="medium"
              fontSize="lg"
            >
              {ts('purposeTitle')}
              {' '}
            </Heading>
            <Button
              variant="link"
              fontWeight="normal"
              onClick={onSelectAllPurposes}
              alignSelf={{ base: 'flex-end', md: 'auto' }}
              rightIcon={isAllSelectedPurposes ? <CheckCircleIcon /> : null}
            >
              {isAllSelectedPurposes
                ? 'Deseleccionar todos los propósitos'
                : ts('allPurpose')}
            </Button>
          </Flex>
          <ButtonCheckboxGroup
            control={control}
            name="purpose"
            innerContentProps={{
              gap: '5',
              display: 'grid',
              justifyItems: 'stretch',
              gridTemplateColumns: {
                base: 'repeat(2, 1fr)',
                md: 'repeat(3, 3fr)',
              },
            }}
          >
            {PURPOSE_OPTIONS.map((purpose) => (
              <ButtonCheckbox key={purpose} value={purpose}>
                {ts(`purposeOptions.${purpose}`)}
              </ButtonCheckbox>
            ))}
          </ButtonCheckboxGroup>
        </Box>

        <Box>
          <Flex marginBottom="5">
            <Heading
              as="label"
              fontSize="lg"
              fontWeight="medium"
            >
              {ts('amountTitle1')}
              <br />
              {ts('amountTitle2')}
            </Heading>
          </Flex>
          <Flex
            gap="5"
            direction={{ base: 'column', md: 'row' }}
            justifyContent="flex-start"
          >
            <RadioButtonGroupField
              control={control}
              name="amount"
              width="auto"
            >
              {AMOUNT_OPTIONS.map((amount) => (
                <RadioButtonField
                  key={amount}
                  value={amount}
                  borderWidth="0"
                  bgColor="gray.200"
                  color="gray.500"
                  _checked={{
                    borderWidth: '0',
                    color: 'blue.500',
                    bgColor: 'white',
                  }}
                >
                  {toCurrency(amount, { precision: 0 })}
                </RadioButtonField>
              ))}
            </RadioButtonGroupField>
            <TextField
              placeholder="Otro monto"
              type="currency"
              minDecimals={0}
              maxDecimals={0}
              control={control}
              name="otherAmount"
              width={{ base: 'full', md: 'xs' }}
            />
          </Flex>
        </Box>

        <Text>Podrás pausar o reactivar autoinvest cuando tú decidas</Text>

        <Flex justifyContent="flex-end" gap="5">
          {isAutoinvestEditing && (
            <Button
              as={RRLink}
              to="show"
              variant="outline"
            >
              {ts('actions.cancel')}
            </Button>
          )}
          <Button type="submit">
            {isAutoinvestEditing
              ? ts('actions.save')
              : ts('actions.active')}
          </Button>
        </Flex>
      </Grid>

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={onCancelConfirmModal}
        size={isAutoinvestEditing ? 'md' : '4xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>
            Confirmar tus criterios para prestar
          </ModalHeader>
          <Divider borderColor="gray.300" />
          <ModalBody paddingY="6">
            <VStack gap="2">
              <Heading as="h4" fontWeight="medium" fontSize="md" textAlign="center">
                Criterios de préstamo
              </Heading>
              <DisplayAutoinvestConfig width="full" />
            </VStack>
          </ModalBody>
          <Divider borderColor="gray.300" />
          <ModalFooter justifyContent="center" paddingY="6">
            {isAutoinvestEditing
              ? (
                <UpdateAutoinvestForm
                  onCancel={onCancelConfirmModal}
                  onSuccess={onSuccessEdition}
                />
              )
              : (
                <CreateAutoinvestForm
                  onCancel={onCancelConfirmModal}
                  onSuccess={onSuccessEdition}
                />
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
