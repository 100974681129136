import { request } from '../../fetchers/yotepresto';
import parse from './parser';

const getPortfolio = async (params = {}) => {
  const paramsWithValue = Object.entries(params).reduce(
    (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
    {},
  );
  const method = 'get';
  const path = '/v2/investor/portfolio_investments/paginable';
  const { data } = await request({ method, path, params: paramsWithValue });

  return parse(data);
};

export default getPortfolio;
