export default {
  container: {
    direction: 'column',
    centered: true,
    rowGap: '2rem',
  },
  title: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
};
