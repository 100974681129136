import { fetchUserSessions } from 'apis/auth';
import { updateUserSettings } from 'apis/user';
import { fetchApiStatus } from 'apis/healthcheck';
import * as types from './types';
import * as fetcher from './fetcher';

export const updateOtpCountdown = (otpRemainingTime, otpCountdownStart) => {
  window.localStorage.setItem(
    'countdownTime',
    JSON.stringify({
      otpRemainingTime,
      otpCountdownStart,
    }),
  );
  return { type: types.SET_OTP_COUNTDOWN, payload: { otpRemainingTime, otpCountdownStart } };
};

export const setProfile = (profile) => ({ type: types.SET_USER_PROFILE_INFO, payload: profile });

export const setSessionLoading = (payload) => ({ type: types.SET_SESSION_LOADING, payload });

export const userAuthenticated = () => ({ type: types.USER_AUTHENTICATED });

export const setUserSettingsFlags = (setting, value) => async () => {
  await updateUserSettings(setting, value);
};

export const checkApiStatus = () => async (dispatch) => {
  const { inMaintenance } = await fetchApiStatus();
  dispatch(fetcher.setInMaintenance(inMaintenance));
};

export const updateExpiredSessionModal = (data) => (dispatch) => dispatch({
  type: types.SET_EXPIRED_SESSION_MODAL,
  payload: data,
});

export const fetchSessions = () => async (dispatch) => {
  const response = await fetchUserSessions();
  dispatch({ type: types.SET_SESSIONS_INFO, payload: response.data });
};

export const setPhoneNumberUpdate = (value) => ({
  type: types.SET_PHONE_NUMBER_UPDATE,
  payload: value,
});
