import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ytplogo from 'assets/ytplogo2.svg';
import { logOutUser } from 'actions/auth/fetcher';
import { publicUrls } from 'config/constants';
import { Flex } from 'styles';
import { ExpiredSessionModal } from 'components/ExpiredSessionModal';
import SignUpContainer from './styles';
import MainFooter from '../DashboardContent/FooterDashboard';

const SignUpForm = ({
  children,
  logOut,
  hideImageSection,
  pusherSessionClient,
  scrollToTopKey,
  pt = { base: '4rem', md: '5rem' },
  innerContentProps,
  renderFooter = () => <MainFooter justifySelf="flex-end" flexGrow="0" noLogo />,
  ...rest
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (!contentRef.current) return;

    if (scrollToTopKey) {
      contentRef.current.scrollTo({ top: 0 });
    }
  }, [scrollToTopKey]);

  return (
    <>
      <ExpiredSessionModal />
      <SignUpContainer {...rest}>
        <div className="header left">
          <img src={ytplogo} alt="YTP Logo" className="logo" />
        </div>
        <div className="header right">
          <div className="links">
            <Link to={publicUrls.faq}>FAQ</Link>
            <button type="button" onClick={logOut}>
              Salir
            </button>
          </div>
        </div>
        {hideImageSection || <div className="image-section" />}
        <Flex
          className={`form-section ${hideImageSection ? 'full-container' : ''}`}
          direction="column"
          ref={contentRef}
        >
          <Flex
            grow="1"
            width="100%"
            justifyContent="center"
            pt={pt}
            pb="2rem"
            px="4rem"
            {...innerContentProps}
          >
            {children}
          </Flex>
          {renderFooter()}
        </Flex>
      </SignUpContainer>
    </>
  );
};

const mapDispatchToProps = {
  logOut: logOutUser,
};

export default connect(null, mapDispatchToProps)(SignUpForm);
