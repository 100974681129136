import {
  GET_CART_ITEMS,
  UPDATE_SINGLE_IN_CART,
  UPDATE_CART_TOTAL,
  TOGGLE_LOADING_LENDING,
  ADD_SINGLE_TO_CART,
  UPDATE_CART_ITEMS_TOTAL,
  ADD_TICKET,
  UPDATE_TICKET,
  DELETE_TICKET,
} from 'actions/cart/types';

const INITIAL_STATE = {
  cartItems: [],
  cartTotal: 0,
  toggleLendingStatus: false,
  totalItemsInCart: null,
};

const calcTotalAmountCartTickets = (tickets) => tickets
  .reduce((a, b) => a + parseInt(b.amount, 10), 0);

const addTicket = (tickets, ticket) => {
  const cartItems = [tickets, ticket];

  return {
    cartItems,
    cartTotal: calcTotalAmountCartTickets(cartItems),
    totalItemsInCart: cartItems.length,
  };
};

const setTickets = (tickets) => ({
  cartItems: tickets,
  cartTotal: calcTotalAmountCartTickets(tickets),
  totalItemsInCart: tickets.length,
});

const updateTicket = (tickets, ticket) => {
  const cartItems = tickets.map((_ticket) => (ticket.id === _ticket.id ? ticket : _ticket));

  return {
    cartItems,
    cartTotal: calcTotalAmountCartTickets(cartItems),
    totalItemsInCart: cartItems.length,
  };
};

const deleteTicket = (tickets, ticket) => {
  const cartItems = tickets.filter((_ticket) => _ticket.id !== ticket.id);

  return {
    cartItems,
    cartTotal: calcTotalAmountCartTickets(cartItems),
    totalItemsInCart: cartItems.length,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CART_ITEMS:
      return { ...state, ...setTickets(action.payload) };
    case ADD_TICKET:
      return { ...state, ...addTicket(state.cartItems, action.payload) };
    case UPDATE_TICKET:
      return { ...state, ...updateTicket(state.cartItems, action.payload) };
    case DELETE_TICKET:
      return { ...state, ...deleteTicket(state.cartItems, action.payload) };
    case UPDATE_SINGLE_IN_CART:
      return { ...state, cartItems: action.payload };
    case UPDATE_CART_TOTAL:
      return { ...state, cartTotal: action.payload };
    case ADD_SINGLE_TO_CART:
      return { ...state, cartItems: action.payload };
    case TOGGLE_LOADING_LENDING:
      return { ...state, toggleLendingStatus: action.payload };
    case UPDATE_CART_ITEMS_TOTAL:
      return { ...state, totalItemsInCart: action.payload };
    default:
      return state;
  }
};
