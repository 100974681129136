import React from 'react';
import { Flex } from 'styles';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Typography';

const statusVariants = {
  default: {
    text: {
      color: 'gray.500',
    },
    icon: {
      iconName: 'QUESTION',
      color: 'gray.500',
    },
  },
  error: {
    text: {
      color: 'red.500',
    },
    icon: {
      iconName: 'CLOSE',
      color: 'red.500',
    },
  },
  success: {
    text: {
      color: 'green.500',
    },
    icon: {
      iconName: 'CHECK',
      color: 'green.500',
    },
  },
};

export const CheckListItem = ({ children, status, ...props }) => {
  const statusStyle = statusVariants[status];
  return (
    <Flex
      as="li"
      alignItems="center"
      columnGap="1rem"
      listStyleType="none"
      data-status={status}
      {...props}
    >
      <Icon boxSize="1rem" {...statusStyle.icon} />
      <Text fontSize="1.2rem" lineHeight="1.6rem" {...statusStyle.text}>
        {children}
      </Text>
    </Flex>
  );
};
