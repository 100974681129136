import styled from 'styled-components';
import colors from 'styles/colors';
import { ReactComponent as HideIcon } from 'assets/Arrow.svg';
import { breakPoints } from 'config/constants';

export const types = { success: { bgColor: colors.successLight, textColor: colors.success } };

export const typesKeys = Object.keys(types).reduce((acc, key) => ({ ...acc, [key]: key }), {});

export const CollapseContainer = styled.div`
  display: flex;
  padding-left: 0.7rem;
  height: 4rem;
  margin-right: 1rem;
  margin-left: 1rem;
  border-radius: 14.5rem;
  background-color: ${colors.white};
  text-align: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-right: 0.5rem;
    margin-left: 0rem;
  }
`;

export const Text = styled.p`
  color: ${colors.light};
  font-size: 1.3rem;
  line-height: 3.6rem;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    display: none;
  }
`;

export const Icon = styled(HideIcon)`
  margin-top: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: all 0.3s ease-out;
  ${({ open }) => open && 'transform: rotate(180deg)'};
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-left: 0rem;
  }
`;
