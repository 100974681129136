import styled from 'styled-components';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  .modal {
    &--header {
      background: #f8fafc;
      color: #93afb9;
      padding: 1.5rem;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      &--closeButton {
        float: right;
        top: -0.6rem;
        right: -0.6rem;
        position: relative;
        transition: all 0.3s ease;
        svg {
          width: 1.2rem;
          * {
            fill: #93afb9;
          }
        }
        :hover {
          cursor: pointer;
          transform: translateY(-2px);
          svg {
            * {
              fill: #012e47;
            }
          }
        }
      }
    }
    &--body {
      padding: 2rem;
      .loader {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding-left: 5rem;
      }
    }
  }
`;

export default ModalContainer;
