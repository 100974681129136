import React from 'react';
import { ReactComponent as WithdrawalsHistory } from 'assets/withdrawals-history.svg';
import { t } from 'i18n';
import { EmptyStateCardContent, Title, Description } from './EmptyStateCard.style';

function EmptyStateCard(props) {
  return (
    <EmptyStateCardContent {...props}>
      <WithdrawalsHistory />
      <Title>{t('addFunds.transactions.emptyStateTitle')}</Title>
      <Description>{t('addFunds.transactions.emptyStateDescription')}</Description>
    </EmptyStateCardContent>
  );
}

export default EmptyStateCard;
