import styled from 'styled-components';

const UpdateInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  .mati-container {
    max-width: initial;
  }
  .fieldset {
    display: block;
    padding: 1rem 0 1rem 0;
    width: 100%;
  }
  .fieldset-double {
    display: flex;
    width: 100%;
  }
  label {
    display: block;
    font-size: 1.2rem;
    color: #677d85;
  }
  span {
    color: #073f51;
    font-size: 1.5rem;
    font-weight: 600;
  }
  button {
    width: 90%;
    margin-top: 2rem;
    padding: 1.8rem;
  }
`;

export default UpdateInformationContainer;
