import { useEffect } from 'react';
import { tscope } from 'i18n';
import { showErrorAlert } from 'utils/toastHandler';
import { STATUS } from '../config';

const ts = tscope('common');

export const useErrorEffect = ({
  meta,
}) => {
  useEffect(() => {
    const isServerError = [STATUS.FAILED].includes(meta.status)
      && meta.error?.response?.status >= 500;
    if (isServerError) showErrorAlert({ t: ts, key: 'serverError' });
  }, [
    meta.status,
    meta.error,
  ]);
};
