export const csvMaker = ({ data = [] }) => {
  if (!Array.isArray(data)) return null;
  if (!data[0]) return null;
  // Empty array for storing the values
  const csvRows = [];

  // Headers is basically a keys of an
  // object which is id, name, and
  // profession
  const headers = Object.keys(data[0]);

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(','));

  // Pushing Object values into array
  // with comma separation
  data.forEach((obj) => {
    const values = Object.values(obj).join(',');
    csvRows.push(values);
  });

  // Returning the array joining with new line
  return csvRows.join('\n');
};

const BOM = '\uFEFF'; // Byte Order Mark

export const downloadCSV = ({ data, fileName = 'download.csv' }) => {
  const supportUTF8 = `${BOM}${data}`;
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([supportUTF8], { type: 'text/csv;charset=utf-8;' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', fileName);

  // Performing a download with click
  a.click();
};
