import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const SettingsContainer = styled(ContainerDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-y: hidden;

  & + #footer {
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      margin-left: 18rem;
    }
    @media (max-width: 700px) {
      margin-left: 0;
    }
  }
`;

export default SettingsContainer;
