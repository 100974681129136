import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { sendCommentMessage } from 'apis/requisitions';
import { ReactComponent as SendSvg } from 'assets/requisitions/plane.svg';
import sendToast from 'utils/toastHandler';
import DotsLoader from 'components/DotsLoader';
import CommentsContainer from './styles';

const CommentsRequisitionDetail = ({ idRequisition, mobile, commentsArray }) => {
  const [messageInput, setMessageInput] = useState('');
  const [questionsArray, setQuestionsArray] = useState(commentsArray);
  const [isLoading, setIsLoading] = useState(false);

  const sendComment = async () => {
    if (messageInput === '') {
      sendToast('error', 'No es posible enviar una pregunta vacía');
    } else {
      try {
        setIsLoading(true);
        const questions = await sendCommentMessage(idRequisition, messageInput);

        setQuestionsArray(questions);
        setMessageInput('');
      } catch (e) {
        sendToast('error', 'Ocurrió un error al crear la pregunta');
      }
      setIsLoading(false);
    }
  };

  const renderComments = () => questionsArray.map((comment) => {
    const renderAnswers = () => comment.answers.map((answer) => (
      <Fragment key={`${answer.id}--${answer.requisition_id}--answer`}>
        <div className="comments__item">
          <div className="comments__item__arrow answer">
            <div className="outer" />
            <div className="inner" />
          </div>
          <div className="comments__item__content answer">
            <p className="comments__item__content__username answer">{answer.username}</p>
            <p className="comments__item__content__text">{answer.content}</p>
            <span>{moment(answer.created_at).format('DD/MM/YY')}</span>
          </div>
        </div>
      </Fragment>
    ));

    return (
      <Fragment key={`${comment.question.id}--${comment.question.zell_app_id}`}>
        <div className="comments__item">
          <div className="comments__item__arrow">
            <div className="outer" />
            <div className="inner" />
          </div>
          <div className="comments__item__content">
            <p className="comments__item__content__username">{comment.question.username}</p>
            <p className="comments__item__content__text">{comment.question.content}</p>
            <span>{moment(comment.question.created_at).format('DD/MM/YY')}</span>
          </div>
        </div>
        {comment.answers.length > 0 && renderAnswers()}
      </Fragment>
    );
  });

  return (
    <CommentsContainer isMobile={mobile}>
      <div className="comments">
        <p>Comentarios</p>
        <form>
          <div className="comments__areaInput">
            <textarea
              name="commentsInput"
              id="commentsText"
              className="comments__input"
              onChange={(event) => {
                setMessageInput(event.target.value);
              }}
              value={messageInput}
            />
            <button
              type="button"
              tabIndex="0"
              className="comments__sendButton"
              onClick={sendComment}
              disabled={isLoading}
            >
              <SendSvg title="" />
            </button>
          </div>
        </form>
        {isLoading ? (
          <div className="loaderComments">
            <DotsLoader black />
          </div>
        ) : (
          renderComments()
        )}
      </div>
    </CommentsContainer>
  );
};

export default CommentsRequisitionDetail;
