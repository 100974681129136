import * as api from 'apis/investor/portfolio';
import { createAsyncAction, createActions } from 'utils/redux-query';

export const {
  setPayload: setPayloadPortfolio,
  setMeta: setMetaPortfolio,
  update: updatePortfolio,
} = createActions('PORTFOLIO');

export const fetchPortfolio = createAsyncAction('PORTFOLIO', (filters) => () => api.fetchPortfolio(filters));

export const { setPayload: setFilter } = createActions('PORTFOLIO_FILTER');
