import React, { forwardRef } from 'react';
import SelectField from '../../../SelectField/SelectField';
import { useController } from './hooks';

export const CountrySelect = forwardRef((props, ref) => {
  const {
    name,
    ignoreCountries,
    placeholder = 'Selecciona un país',
    'data-testid': dataTestId = 'countrySelect',
    ...contentProps
  } = props;
  const {
    isLoading,
    countryOptions,
  } = useController({
    name,
    ignoreCountries,
  });

  return (
    <SelectField
      name={name}
      ref={ref}
      iconName="CHEVRON_DOWN"
      disabled={isLoading}
      data-testid={dataTestId}
      {...contentProps}
      format={undefined}
    >
      <option value="">{placeholder}</option>
      {countryOptions.map(({ value, label }) => (
        <option key={value} value={label} data-testid={`${dataTestId}.option`}>
          {label}
        </option>
      ))}
    </SelectField>
  );
});
