import styled from 'styled-components';

import { breakPoints } from 'config/constants';
import colors from 'styles/colors';

const { smallBreakPoint, midBreakPoint } = breakPoints;

// TODO(yosantiagogarcia): Refactor by html elements
const AsideContainer = styled.div`
  grid-column: 1 / 4;
  width: 25rem;
  background-color: ${colors.gray.g1};
  @media (max-width: ${midBreakPoint}px) {
    grid-column: 1 / 5;
  }
  .title {
    text-align: center;
    color: #247590;
    padding-top: 2.3rem;
    padding-bottom: 2.3rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #ccdce2;
  }
  .steps {
    padding-top: 2rem;
    @media (max-width: ${smallBreakPoint}px) {
      .step {
        .name {
          display: none;
        }
      }
    }
    .step {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      .name {
        font-size: 1.4rem;
        font-weight: 600;
        width: 150px;
        color: #9fb8c2;
      }
      .badge {
        border: 2px solid ${colors.gray.g3};
        box-sizing: border-box;
        color: ${colors.gray.g3};
        font-size: 1.2rem;
        font-weight: 600;
      }
      &.active,
      &.checked {
        .name {
          color: #0f5168;
        }
        .badge {
          color: ${colors.blue};
          border-color: ${colors.blue};
        }
      }
      .checkmark {
        border: 2px solid transparent;
        background-color: ${colors.green};
        color: white;
      }
      .badge,
      .checkmark {
        margin-right: 1rem;
        position: relative;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .checkmark:before {
        content: '✔';
      }
      .separator {
        position: absolute;
        left: 9px;
        top: 25px;
        height: 15px;
        width: 2px;
        background: ${colors.gray.g3};
      }
    }
  }
`;

export default AsideContainer;
