import React from 'react';
import { tscope, toCurrency, l } from 'i18n';
import {
  Text,
  Grid,
  Modal,
  Image,
  Button,
  Divider,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Skeleton,
  Box,
} from 'ui';
import BrandDarkName from 'assets/images/brandDarkName.svg';
import { DEFAULT_CURRENCY } from 'config/constants';
import { Row } from './components';
import { useController } from './hooks/useController';
import { styles } from './VoucherModal.styes';

const t = tscope('Dashboard.Transactions.transactionReceiptModal');
const printDocument = () => { window.print(); };

export const VoucherModal = ({
  isOpen,
  onClose,
  transaction,
}) => {
  const {
    voucher,
    isLoading,
    isArrayOfFees,
  } = useController({
    onClose,
    transaction,
  });

  return (
    <Modal
      {...styles.modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent {...styles.modalContent}>
        <ModalHeader>{t('title')}</ModalHeader>
        <ModalCloseButton {...styles.modalCloseButton} />
        <Divider />
        <ModalBody {...styles.modalBody}>
          <Grid {...styles.bodyContent}>
            <Row
              templateColumns="1fr 2fr"
              isLoading={isLoading}
              label={t('list.operation')}
              data-testid="operationRow"
              value={voucher?.type}
            />
            <Divider {...styles.bodyDivider} />
            <Row
              isLoading={isLoading}
              label={t('list.id')}
              data-testid="referenceIdRow"
              value={voucher?.referenceId}
            />
            <Divider {...styles.bodyDivider} />
            <Row
              isLoading={isLoading}
              label={t('list.account')}
              data-testid="accountRow"
              value={voucher?.accountNumber}
            />
            <Divider {...styles.bodyDivider} />
            <Row
              isLoading={isLoading}
              label={t('list.date')}
              data-testid="dateRow"
              value={(
                <>
                  <Text as="span">
                    {l('date.formats.medium', voucher?.date)}
                  </Text>
                  {' '}
                  <Text as="span" {...styles.dateHour}>
                    {l('date.formats.hourMinutes12h', voucher?.date)}
                  </Text>
                </>
              )}
            />
            <Divider {...styles.bodyDivider} />
            <Row
              isLoading={isLoading}
              label={t('list.amount')}
              data-testid="amountRow"
              value={voucher?.amount ? `${toCurrency(voucher?.amount)} ${DEFAULT_CURRENCY}` : ''}
            />
            {isArrayOfFees === false && (
              <>
                <Divider {...styles.bodyDivider} />
                <Row
                  isLoading={isLoading}
                  label={t('list.fees')}
                  data-testid="feeRow"
                  value={`${toCurrency(voucher?.fees)} ${DEFAULT_CURRENCY}`}
                />
              </>
            )}
            {isArrayOfFees === true && (
              <>
                <Divider {...styles.bodyDivider} />
                <Text {...styles.label} {...styles.fullRow}>
                  {t('list.fees')}
                </Text>
                {voucher?.fees.map(({ id, label, value }) => (
                  <Box key={id}>
                    <Divider {...styles.bodyDivider} />
                    <Row
                      label={label}
                      level={2}
                      data-testid="feeRow"
                      value={`${toCurrency(value)} ${DEFAULT_CURRENCY}`}
                    />
                  </Box>
                ))}
              </>
            )}
            <Divider {...styles.bodyDivider} />
            <Text {...styles.label} {...styles.fullRow}>
              {t('list.originalChain')}
            </Text>
            <Skeleton isLoaded={!isLoading} {...styles.fullRow}>
              <Text {...styles.tokenText}>
                {voucher?.token}
              </Text>
            </Skeleton>
            {
              !isLoading && (
                <Button
                  {...styles.printButton}
                  {...styles.fullRow}
                  onClick={printDocument}
                >
                  {t('actions.print')}
                </Button>
              )
            }
          </Grid>
        </ModalBody>
        <Divider />
        <ModalFooter {...styles.modalFooter}>
          <Image width={32} src={BrandDarkName} />
          <Skeleton isLoaded={!isLoading}>
            <Text {...styles.companyText} data-testid="companyText">
              { voucher?.company && t('footer.address', { company: voucher.company })}
            </Text>
          </Skeleton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
