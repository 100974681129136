import React from 'react';
import ContentLoader from 'react-content-loader';
import { useReduxQuery } from 'utils/redux-query';
import ButtonIcon from 'components/Button/ButtonIcon';
import CashFlowModal from './CashFlowModal';
import DetailWonModal from './DetailWonModal';
import ModalContainer from './styles';

const LoaderModal = () => (
  <div className="loader">
    <ContentLoader
      speed={2}
      width={400}
      height={160}
      viewBox="0 0 400 160"
      primaryColor="#F2F5F7"
      secondaryColor="#E7EBEF"
    >
      <rect x="25" y="15" rx="5" ry="5" width="80%" height="10" />
      <rect x="25" y="45" rx="5" ry="5" width="80%" height="10" />
      <rect x="25" y="75" rx="5" ry="5" width="80%" height="10" />
      <rect x="25" y="105" rx="5" ry="5" width="80%" height="10" />
      <rect x="25" y="135" rx="5" ry="5" width="80%" height="10" />
    </ContentLoader>
  </div>
);

const ModalContent = ({ modalType, onCloseModal }) => {
  const [statement, { status: statementsStatus }] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');

  const RenderModal = () => (modalType === 'cashFlow' ? (
    <CashFlowModal dataModal={statement || {}} />
  ) : (
    <DetailWonModal dataModal={statement || {}} />
  ));

  const titleModal = modalType === 'cashFlow' ? 'Flujo de efectivo' : 'Resultados obtenidos';

  return (
    <ModalContainer>
      <div className="modal--header">
        <p>
          {titleModal}
          {' '}
          <ButtonIcon iconName="CLOSE" className="modal--header--closeButton" onClick={onCloseModal} />
        </p>
      </div>
      <div className="modal--body">
        {statement?.caching || statementsStatus.pending ? <LoaderModal /> : <RenderModal />}
      </div>
    </ModalContainer>
  );
};

export default ModalContent;
