import React from 'react';
import { isNil } from 'utils';
import DotsLoader from 'components/DotsLoader';
import { useReduxQuery } from 'utils/redux-query';
import { toCurrency } from 'i18n';
import { BalanceElementGroup, BalanceElement } from './styles';

const BalanceWidget = () => {
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const availableFunds = statements?.saldoDisponible;
  const accountValue = statements?.valorCuenta;

  return (
    <BalanceElementGroup>
      <BalanceElement>
        <div className="balance__description">Disponible:</div>
        <div className="balance__quantity" data-testid="balanceWidget-availableFunds">
          {isNil(availableFunds) ? <DotsLoader /> : toCurrency(availableFunds)}
        </div>
      </BalanceElement>
      <BalanceElement>
        <div className="balance__description">Valor de tu cuenta:</div>
        <div className="balance__quantity" data-testid="balanceWidget-accountValue">
          {isNil(accountValue) ? <DotsLoader /> : toCurrency(accountValue)}
        </div>
      </BalanceElement>
    </BalanceElementGroup>
  );
};

export default BalanceWidget;
