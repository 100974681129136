import { t } from 'i18n';

const tFields = (key, options) => t(key, { scope: 'settings.AccountStatements.downloadStatementForm.fields', ...options });
const buildError = ({ message }) => ({
  message,
  type: 'danger',
});

export const resolver = (values) => {
  const {
    month,
    password,
  } = values;
  const errors = {};
  if (!month) {
    errors.month = buildError({
      message: tFields('month.errors.required'),
    });
  }
  if (!password) {
    errors.password = buildError({
      message: tFields('password.errors.required'),
    });
  }

  return {
    values,
    errors,
  };
};
