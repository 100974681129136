import React from 'react';
import { ReactComponent as EmptyStateGroupSvg } from 'assets/ReqEmptyState.svg';
import EmptyStateContainer from './styles';

const EmptyState = (mobile) => (
  <EmptyStateContainer mobile={mobile}>
    <p className="empty__state__description__title">
      Por el momento no hay solicitudes en fondeo
    </p>
    <EmptyStateGroupSvg title="" />
    <p className="empty__state__description__description">
      Nuestro análisis es muy exigente y sólo el
      <br />
      <span>3% de los trámites </span>
      llega a estar en fondeo.
    </p>
  </EmptyStateContainer>
);

export default EmptyState;
