import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

export const scrollTop = (selector = '#contentArea > div') => {
  const contentArea = document.querySelector(selector);
  if (contentArea && contentArea.scrollIntoView) {
    contentArea.scrollIntoView(true);
  }
};

function CustomRoute(props) {
  const { scrollToTopOnEnter = true, ...routeProps } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollToTopOnEnter && pathname) {
      scrollTop();
    }
  }, [pathname, scrollToTopOnEnter]);

  useEffect(() => {
    scrollTop();
  }, []);

  return <Route {...routeProps} />;
}

export default CustomRoute;
