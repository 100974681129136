import { createGlobalStyle, css } from 'styled-components';
import colors from 'styles/colors';

const inheritTextClassName = css`
  &.inherited {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }

  html {
    overflow-x: hidden;
  }

  body {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 4.4rem;
    color: ${colors.gray.g7};
    font-weight: bold;

    ${inheritTextClassName}
  }

  h2 {
    font-size: 3rem;
    line-height: 3.7rem;
    color: ${colors.gray.g7};
    font-weight: bold;

    ${inheritTextClassName}
  }

  h3 {
    font-size: 2.4rem;
    line-height: 2.9rem;
    color: ${colors.gray.g7};
    font-weight: bold;

    ${inheritTextClassName}
  }

  h4 {
    font-size: 2rem;
    line-height: 2.4rem;
    color: ${colors.gray.g7};
    font-weight: bold;

    ${inheritTextClassName}
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${colors.gray.g5};

    ${inheritTextClassName}
  }

  .Toastify__toast-container--top-right{
    top:6.6rem;
  }
  .Toastify__toast {
    font-size: 1.6rem;
    border-radius: .4rem;
    font-weight:600;
    padding:1.2rem 2rem;
    border-radius:0.6rem;
    box-shadow:none;
    font-family: proxima-nova;

    @media print {
      display: none;
    }
  }
  .Toastify__toast--success{
    background-color:#CCFFE6;
    color:#00C574;
  }
  .Toastify__toast--info{
    background-color:#D9EDF7;
    color:#0076FF;
  }
  .Toastify__toast--error{
    background-color:#F8DFE6;
    color:#FF214E;
  }
  .Toastify__toast--warning{
    background-color:#FCF8E3;
    color:#C08A53;
    .Toastify__close-button {
      color: #C08A53;
    }
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  .width100 {
    width: 100%;
  }

  .no-print,
  .no-print * {
    @media print {
      display: none;
    }
  }

  @page {
    size:  auto;   /* auto is the initial value */
    margin: 0mm;  /* this affects the margin in the printer settings */
  }

  .contract-document {
    html {
      font-size: 12px;
    }
  }
`;

export default GlobalStyles;
