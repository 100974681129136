import React from 'react';
import get from 'lodash.get';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Line } from 'rc-progress';
import { t } from 'i18n';
import { toCurrency, toCurrencyWithDecimals } from 'utils/numberFormatters';
import PaymentsGraphCardContent from './styles';
import ElementCardContainer from '../../../Elements/ElementCardContainer';

const PaymentsGraphCard = ({
  isLoading, isFundedRequisition, loanInfo, isMobile,
}) => {
  if (!isFundedRequisition || isLoading) return null;

  const renderPaymentsContent = () => {
    const capital = get(loanInfo, 'capital');
    const loan = get(loanInfo, 'prestamo');
    const total = get(loanInfo, 'total');
    const ordinaryInterest = get(loanInfo, 'intereses_ordinarios');
    const moratoriumInterests = get(loanInfo, 'intereses_moratorios');
    const ivaInterests = get(loanInfo, 'iva_intereses');
    const totalPayedInt = parseFloat(capital || '0');
    const totalLendInt = parseFloat(loan || '0');
    const debtTotal = totalLendInt - totalPayedInt;
    const totalProgress = (totalPayedInt / totalLendInt) * 100;

    const TablePayments = () => (
      <table>
        <thead>
          <tr>
            <th>Prestado</th>
            <th>{toCurrency(totalLendInt)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Pagado</td>
            <td>{toCurrencyWithDecimals(total || 0)}</td>
          </tr>
          <tr>
            <td>Capital</td>
            <td>{toCurrencyWithDecimals(capital || 0)}</td>
          </tr>
          <tr>
            <td>Intereses Ordinarios</td>
            <td>{toCurrencyWithDecimals(ordinaryInterest || 0)}</td>
          </tr>
          <tr>
            <td>Intereses Moratorios</td>
            <td>{toCurrencyWithDecimals(moratoriumInterests || 0)}</td>
          </tr>
          <tr>
            <td>IVA Intereses</td>
            <td>{toCurrencyWithDecimals(ivaInterests || 0)}</td>
          </tr>
        </tbody>
      </table>
    );

    const renderGraph = () => {
      if (isMobile) {
        return (
          <div className="graph">
            <div className="graph__container">
              <div className="graph__numbers">
                <div className="graph__numbers--item">
                  <h3>{toCurrencyWithDecimals(totalPayedInt)}</h3>
                  <p>{t('detailRequisition.paidCapital')}</p>
                </div>
                <div className="graph__numbers--item">
                  <h3>{toCurrencyWithDecimals(debtTotal >= 0 ? debtTotal : 0)}</h3>
                  <p className="graph--debtText">{t('detailRequisition.owesYou')}</p>
                </div>
              </div>
              <div className="graph__line">
                <Line
                  percent={totalProgress}
                  strokeWidth="2"
                  strokeColor="#05C574"
                  trailColor="#A2BCC5"
                  trailWidth="2"
                />
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="graph">
          <div className="graph__container">
            <CircularProgressbarWithChildren
              value={totalProgress}
              strokeWidth={5}
              styles={buildStyles({ pathColor: '#00C574' })}
            >
              <div className="graph__text">
                <p>{toCurrencyWithDecimals(totalPayedInt)}</p>
                <p>{t('detailRequisition.paidCapital')}</p>
              </div>
              <div className="graph__text">
                <p>{toCurrencyWithDecimals(debtTotal >= 0 ? debtTotal : 0)}</p>
                <p>{t('detailRequisition.owesYou')}</p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      );
    };

    return (
      <>
        {renderGraph()}
        <div className="borrowInfo">
          <ElementCardContainer classNames="borrowInfo">
            <TablePayments />
          </ElementCardContainer>
        </div>
      </>
    );
  };

  return (
    <PaymentsGraphCardContent>
      <p>{t('detailRequisition.titles.payments')}</p>
      <div className={`content ${loanInfo ? '' : 'unavailableData'}`}>
        {!loanInfo ? (
          <p>
            Por ahora la información de pagos no está disponible para esta solicitud.
            {' '}
            <br />
            {' '}
            Estamos
            trabajando para solucionarlo.
          </p>
        ) : (
          renderPaymentsContent()
        )}
      </div>
    </PaymentsGraphCardContent>
  );
};

export default PaymentsGraphCard;
