import { useTransition } from 'react-spring';
import { useHistory } from 'react-router-dom';
import { TERMS_LIST } from 'components/CER/components/Confirm/constants/termsList';

export const useController = (options) => {
  const { onStepChange, stepCounter } = options;
  const history = useHistory();
  const transitions = useTransition(TERMS_LIST[stepCounter] || [], (item) => item.id, {
    from: { opacity: 0, minHeight: 0 },
    enter: { opacity: 1, minHeight: 10 },
    leave: { opacity: 0, minHeight: 0, display: 'none' },
  });

  const buttonsAnimation = useTransition(stepCounter === TERMS_LIST.length, null, {
    from: { opacity: 0, width: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0, display: 'none' },
  });

  const onSigningRisks = (data) => onStepChange(data, true);

  const onGoBackHandler = () => {
    history.goBack();
  };

  return {
    transitions,
    onSigningRisks,
    onGoBackHandler,
    buttonsAnimation,
  };
};
