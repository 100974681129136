import React from 'react';
import get from 'lodash.get';
import {
  colors,
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

const StylesDiv = ({ color, black, ...otherProps }) => <div {...otherProps} />;

const Styles = styled(StylesDiv).withConfig({ shouldForwardProp })`
  font-size: 0;
  line-height: normal;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    height: ${({ config }) => config.size.boxHeight};
    width: ${({ config }) => config.size.boxWidth};
  }
  .lds-ellipsis div {
    position: absolute;
    width: calc(${({ config }) => config.size.radius} * 2);
    height: calc(${({ config }) => config.size.radius} * 2);
    border-radius: 50%;
    background-color: ${(props) => get(colors, props.color, props.black ? '#C2D4DB' : 'white')};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: ${({ config }) => config.size.gap[0]};
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: ${({ config }) => config.size.gap[1]};
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: ${({ config }) => config.size.gap[2]};
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: ${({ config }) => config.size.gap[3]};
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${({ config }) => config.size.translate}, 0);
    }
  }

  ${boxHelpers}
`;

export default Styles;
