import { STATUS } from '../config';

const createActions = (keystore) => ({
  reinitialize: () => ({
    type: `SET_${keystore}`,
    meta: { status: STATUS.INITIAL, error: null },
    payload: null,
  }),
  setMeta: (meta) => ({ type: `SET_${keystore}_META`, meta }),
  setPayload: (payload) => ({
    type: `SET_${keystore}`,
    meta: { status: STATUS.COMPLETED },
    payload,
  }),
  initQuery: () => ({
    type: `INIT_QUERY_${keystore}`,
  }),
  setError: (error) => ({
    type: `SET_${keystore}`,
    meta: { status: STATUS.FAILED, error },
  }),
  update: (payload, meta) => ({
    type: `UPDATE_${keystore}`,
    payload,
    meta,
  }),
  patch: (payload, meta) => ({
    type: `PATCH_${keystore}`,
    payload,
    meta,
  }),
});

export default createActions;
