const colors = {
  primary: '#0076FF',
  success: '#00C574',
  danger: '#FF214E',
  info: '#0076FF',
  warning: '#FF8E0A',
  light: '#186A83',
  green: '#00C574',
  successLight: '#E4FCF2',
  textDefault: '#04475c',
  white: '#FFFFFF',
  greenLendingNow: {
    background: '#e0f9ee',
    text: '#18bc5c',
  },
  blue: '#0076FF',
  link: '#0076FF',
  red: '#FF214E',
  btn: {
    greenHover: '#079072',
    blueHover: '#005BC4',
    redHover: '#CC1439',
  },
  gray: {
    g0: '#F8FAFC',
    g1: '#F2F5F7',
    g2: '#E7EBEF',
    g3: '#C2D4DB',
    g4: '#93AFB9',
    g41: '#80939C',
    g5: '#4F6F7B',
    g6: '#346071',
    g7: '#134052',
    g8: '#012E47',
    g9: '#00243A',
    g10: '#677d85',
    g11: '#186A83',
    g12: '#a8c9d6',
  },
  schema: {
    white: '#FFFFFF',
    black: '#000000',
    gray: {
      50: '#F8FAFC',
      100: '#F2F5F7',
      200: '#E7EBEF',
      300: '#C2D4DB',
      400: '#93AFB9',
      500: '#4F6F7B',
      600: '#346071',
      700: '#04475C',
      800: '#012E47',
      900: '#00243A',
    },
    blue: {
      50: '#D9EDF7',
      100: '#E5F1FF',
      200: '#C6DEFE',
      300: '#78B2FF',
      400: '#4490FD',
      500: '#0076FF',
      600: '#005BC4',
      700: '#0051B4',
      800: '#003E8F',
      900: '#002A6A',
    },
    yellow: {
      50: '#FCF8E3',
      100: '#FFF7E5',
      200: '#FFECBF',
      300: '#FFD573',
      400: '#FFC640',
      500: '#FFB300',
      600: '#DD9B00',
      700: '#B67A01',
      800: '#935F00',
      900: '#704500',
    },
    red: {
      50: '#F8DFE6',
      100: '#FFE8ED',
      200: '#FFC7D3',
      300: '#FF859E',
      400: '#FF597B',
      500: '#FF214E',
      600: '#D81A40',
      700: '#B11333',
      800: '#8A0D26',
      900: '#630619',
    },
    green: {
      50: '#CCFFE6',
      100: '#CCFFE6',
      200: '#9CF6CE',
      300: '#69E6AF',
      400: '#33D890',
      500: '#00C66F',
      600: '#079072',
      700: '#00804E',
      800: '#005E3A',
      900: '#003B27',
    },
    zenfi: {
      500: '#5B0BE1',
      blue: {
        300: '#E7F4FF',
      },
    },
  },
  alerts: {
    info: '#D9EDF7',
    error: '#F8DFE6',
    danger: '#FCF8E3',
    success: '#CCFFE6',
    txt_danger: '#C08A53',
    txt_warning: '#6B421C',
    warning: {
      bgColor: '#FCF8E3',
      borderColor: '#E4C7A3',
      textColor: '#134052',
    },
  },
  status: {
    atDay: '#00C574',
    delay: '#F6B603',
    transit: '#228AD8',
    overdue: '#FF214E',
    funding: '#29DBD3',
    paid: '#00A4A7',
    formalize: '#9A04CC',
    toDeposit: '#77DDCC',
  },
  rates: {
    a: '#00BDCA',
    b: '#0069FF',
    c: '#703CDE',
  },
  shadow: {
    cards: '0px 0px 9px 2px rgba(37,103,141,0.04)',
    card: '0 5px 9px 0 rgba(37,103,141,0.03);',
    cart: '0 5px 9px 0 rgba(37, 103, 141, 0.2);;',
  },
  orderStatus: {
    pending: '#5C30EF',
    authorizing: '#0076FF',
    canceled: '#63B0C8',
    declined: '#FF214E',
    approved: '#00C66F',
  },
  requisition: {
    status: {
      startedRequisition: '#78DFCE',
      uptoDate: '#3FBF94',
      aboutFormalize: '#9A05CC',
      paidInProcess: '#0085D6',
      onFunding: '#79E3FB',
      duePayment: '#FFCC64',
      overduePayment: '#FC674A',
      settledBalance: '#5ADF70',
    },
  },
};

export const zenfiTheme = {
  primary: '#5B0BE1',
};

export default colors;
