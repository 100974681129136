import React from 'react';
import { ReactComponent as INEZoom } from 'assets/identityVerification/ine-zoom.svg';
import { t, tscope, jsx } from 'i18n';
import {
  Card,
  Link,
  Grid,
  Text,
  Heading,
  CardBody,
  CardFooter,
} from 'ui';
import { styles } from './UserValidation.style';

const ts = tscope('Mati.IdentityValidation.userValidation');

export const UserValidation = () => {
  const linkHref = ts('mailtoIDValidation', { email: t('common.inversionistaEmail') });

  return (
    <Grid {...styles.container}>
      <Heading {...styles.title}>
        {ts('title')}
      </Heading>
      <Card>
        <CardBody {...styles.cardBody}>
          <INEZoom {...styles.image} />
          <Text {...styles.desc}>
            {ts('desc')}
          </Text>
        </CardBody>
        <CardFooter>
          <Text {...styles.noteText} data-testid="noteText">
            {jsx('footer', {
              t: ts,
              email: (
                <Link href={linkHref}>
                  {t('common.inversionistaEmail')}
                </Link>
              ),
            })}
          </Text>
        </CardFooter>
      </Card>
    </Grid>
  );
};
