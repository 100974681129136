import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { isRequired } from 'utils/Validators/common';
import { t } from 'i18n';

const tOptions = { scope: 'expedientsProfileUpdate.rfc' };

function validator(values) {
  const { rfc, homoClave } = values;
  let errorRFC = null;
  let errorRFCHomo = null;

  if (homoClave?.length < 3) {
    errorRFCHomo = t('form.rfcField.errors.length', { ...tOptions, length: 3 });
  }

  if (!homoClave) {
    errorRFCHomo = t('form.rfcField.errors.requiredHomoClave', tOptions);
  }

  if (rfc?.length < 10) {
    errorRFC = t('form.rfcField.errors.lengthRFC', tOptions);
  }

  if (!rfc) {
    errorRFC = isRequired(rfc);
  }

  return clearErrorsObject({
    rfc: errorRFC,
    homoClave: errorRFCHomo,
  });
}

export default validator;
