import { tscope } from 'i18n';
import { useForm } from 'react-hook-form';
import {
  showSuccessAlert,
} from 'utils/toastHandler';
import { resolver } from '../resolver';
import { useErrorQueryHandler } from './useOnErrorQueryHandler';
import { useCreateBankAccountMutation, useUpdateBankAccountMutation } from './graphql/useBankAccount';

const ts = tscope('components.AddBankAccountForm');
const INVALID_OTP_ERROR_CODE = 'INVALID_OTP_ERROR';
const UNPROCESSABLE_REQUEST_ERROR_CODE = 'UNPROCESSABLE_REQUEST_ERROR';

const defaultValues = {
  bank: '',
  clabe: '',
  alias: '',
  token: '',
};

export const useAddBankAccountForm = ({
  onError,
  onSuccess,
  bankCatalog,
  bankAccount,
}) => {
  const isEditing = Boolean(bankAccount);
  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues,
    resolver,
    mode: 'onChange',
    context: {
      bankCatalog,
    },
  });
  const {
    onInvalidCodeError,
    onUnprocessableRequestError,
  } = useErrorQueryHandler({
    onError,
    setError,
  });
  const formValues = watch();
  const { errors } = resolver(
    formValues || defaultValues,
    { bankCatalog },
  );

  const [createBankAccount, { loading: isCreatingAccount }] = useCreateBankAccountMutation({
    onCompleted: () => {
      showSuccessAlert({ t: ts, key: 'createBankAccount' });
      onSuccess();
    },
    onError,
    onErrorCode: {
      [INVALID_OTP_ERROR_CODE]: onInvalidCodeError,
      [UNPROCESSABLE_REQUEST_ERROR_CODE]: onUnprocessableRequestError,
    },
  });
  const [updateBankAccount, { loading: isUpdatingAccount }] = useUpdateBankAccountMutation({
    onCompleted: () => {
      showSuccessAlert({ t: ts, key: 'updateBankAccount' });
      onSuccess();
    },
    onError,
    onErrorCode: {
      [INVALID_OTP_ERROR_CODE]: onInvalidCodeError,
    },
  });
  const isLoading = isCreatingAccount || isUpdatingAccount;

  const onSubmit = handleSubmit((values) => {
    const bankAccountInput = {
      bank: values.bank,
      clabe: values.clabe,
      otpCode: values.token,
      clabeAlias: values.alias,
    };
    const options = { variables: { input: bankAccountInput } };
    if (isEditing) updateBankAccount(options);
    else createBankAccount(options);
  });

  return {
    watch,
    reset,
    errors,
    control,
    onSubmit,
    setError,
    setValue,
    isLoading,
    clearErrors,
  };
};
