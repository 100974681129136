import styled from 'styled-components';
import { colors } from 'styles';

const CashFlowContainer = styled.div`
  table {
    width: 100%;
    font-size: 1.4rem;
    margin: 0 auto;
    border-collapse: collapse;
    tr {
      &:nth-child(3) {
        color: ${colors.green};
        font-size: 1.5rem;
        font-weight:700;
        td {
          border-bottom: 1px solid ${colors.gray.g2};
        }
      }
      &:nth-child(7) {
        td {
          color: ${colors.red};
          font-size: 1.5rem;
          font-weight:700;
          border-bottom: 1px solid ${colors.gray.g2};
        }
      }
      &:nth-child(10) {
        td {
          background-color:#f0fcf6;
          font-size: 1.5rem;
          font-weight:700;
          padding:2rem 1.5rem;
          color: ${colors.green};
          border-bottom: 0;
        }
      }
      td {
        padding:1rem 1.5rem;
        font-weight: 500;
        color:${colors.gray.g6}
        &:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export default CashFlowContainer;
