import { tscope } from 'i18n';
import { useMemo } from 'react';
import { useLocationQuery } from './useLocationQuery';

const t = tscope('Forms.fields.phoneField');

const buildOption = ({
  id,
  name,
  emoji,
  phoneCode,
}) => ({
  key: id,
  value: phoneCode,
  description: t('option', { name, emoji, phoneCode }),
});

export const usePhoneCodes = () => {
  const { data, loading } = useLocationQuery();

  const countries = useMemo(
    () => (data?.catalogs?.countries || [])
      .filter(({ phoneCode }) => !!phoneCode)
      .sort((a, b) => a.name.localeCompare(b.name)),
    [data],
  );

  const options = useMemo(
    () => countries.map(buildOption),
    [countries],
  );

  const validations = useMemo(
    () => countries.reduce((prev, country) => {
      const { phoneCode, phoneMinLength: min, phoneMaxLength: max } = country;
      if (!prev[phoneCode]) Object.assign(prev, { [phoneCode]: { min, max } });
      const minOverall = Math.min(min, prev[phoneCode].min);
      const maxOverall = Math.max(max, prev[phoneCode].max);
      Object.assign(prev, { [phoneCode]: { min: minOverall, max: maxOverall } });
      return prev;
    }, {}),
    [countries],
  );

  return {
    loading,
    options,
    validations,
  };
};
