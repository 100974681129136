import * as API from 'apis/investor/secondFactor';
import { createAsyncAction } from 'utils/redux-query';

export const fetchSecondFactor = createAsyncAction('SECOND_FACTOR', () => async () => {
  try {
    const secondFactor = await API.fetch();
    return secondFactor;
  } catch (error) {
    return null;
  }
});

export const deactivateSecondFactor = createAsyncAction(
  'DEACTIVATE_SECOND_FACTOR',
  () => async (dispatch) => {
    const secondFactor = await API.deactivate();
    dispatch(fetchSecondFactor());
    return secondFactor;
  },
);

export const verifyPhoneSecondFactor = createAsyncAction('VERIFY_PHONE', () => () => API.verifyPhone());

export const confirmSecondFactorChange = createAsyncAction(
  'CONFIRM_CHANGE_SECOND_FACTOR',
  (options) => async (dispatch) => {
    const secondFactor = await API.confirmChange(options);
    dispatch(fetchSecondFactor());
    return secondFactor;
  },
);

export const requestSecondFactorChange = createAsyncAction(
  'REQUEST_CHANGE_SECOND_FACTOR',
  (options) => () => API.requestChange(options),
);

export const validateOTPCode = createAsyncAction('VALIDATE_OTP', (OTPCode) => () => API.validateOTP(OTPCode));

export const generateOTPCode = createAsyncAction('GENERATE_OTP', (type) => () => API.generateOTP(type));
