import { request } from 'apis/fetchers/yotepresto';

export const fetch = async () => {
  const config = {
    method: 'get',
    path: '/v1/investor/contract/signed_contracts',
  };
  const response = await request(config);

  return response.data;
};
