import { showToast } from 'utils/toastHandler';
import camelify from 'camelcase-keys-recursive';
import { request } from './fetchers/yotepresto';

export const getCartItems = async () => {
  const path = '/v2/investor/shopping_carts';
  const method = 'get';
  const config = { method, path };
  const response = await request(config);
  return response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
};

const getTextError = (response, text) => (response?.data?.error ? response.data.error : text);

export const addRequisitionToCart = async (requisition) => {
  const { id, lendValue } = requisition;
  const path = '/v2/investor/shopping_carts';
  const method = 'post';
  const body = {
    ticket: {
      requisition_id: id,
      amount: lendValue,
      origin: 'front_end',
    },
  };
  const config = { method, path, body };
  const { data: tickets } = await request(config);
  showToast('success', 'Solicitud agregada al carrito');
  return tickets;
};

export const getCartDetailsRequest = async () => {
  const path = '/v2/investor/shopping_carts/tickets_count';
  const method = 'get';
  const config = { method, path };
  const {
    data: { tickets_count: ticketsCount },
  } = await request(config);

  return ticketsCount;
};

export const updateCartTicket = async (data) => {
  const { id, amount } = data;
  const path = `/v2/investor/shopping_carts/${id}`;
  const method = 'put';
  const body = { ticket: { amount } };
  const config = { method, path, body };
  try {
    return await request(config);
  } catch (e) {
    showToast('error', getTextError(e.response, 'Error al actualizar el ticket'));
    return null;
  }
};

export const deleteTicket = (ticketId) => {
  const path = `/v2/investor/shopping_carts/${ticketId}`;
  const method = 'delete';
  const config = { method, path };
  return request(config);
};

export const authorizeCart = (otp) => {
  const path = '/v2/investor/ticket_authorization';
  const method = 'post';
  const body = { otp_code: otp };
  const config = { method, path, body };
  return request(config);
};

export const fetchOrders = async () => {
  const path = '/v2/investor/ticket_orders';
  const method = 'get';
  const config = { method, path };
  const { data } = await request(config);

  const parseOrders = (orders) => orders.map((order) => ({
    ...order,
    alreadyLent: !!orders.find(
      (_order) => !['declined', 'canceled'].includes(_order.status)
        && _order.ticketId !== order.ticketId
        && _order.zellAppId === order.zellAppId,
    ),
  }));

  const ordersByCategory = data.orders
    .map((category) => Object.entries(category)[0])
    .reduce(
      (parsedOrders, [categoryName, orders]) => [
        ...parsedOrders,
        {
          [categoryName]: parseOrders(camelify(orders)),
        },
      ],
      [],
    );

  return { orders: ordersByCategory, processingOrders: data.processing_orders };
};

export const authorizingLendRequisition = (lendingData) => {
  const path = '/v2/investor/ticket_authorization/add_and_authorize';
  const method = 'post';
  const { amount, id, otp } = lendingData;
  const body = {
    ticket: {
      amount,
      requisition_id: id,
      origin: 'front_end',
    },
    otp_code: otp,
  };
  const config = { method, path, body };
  return request(config);
};

export const cancelAuthorizedTicket = async (ticketId, otp) => {
  const path = '/v2/investor/ticket_authorization/cancel';
  const method = 'post';
  const body = { ticket_id: ticketId, otp_code: otp };
  const config = { method, path, body };
  try {
    const response = await request(config);
    showToast('success', 'Ticket cancelado correctamente');
    return response;
  } catch (e) {
    showToast('error', e.response.data.error);
    return null;
  }
};

export const fetchTotalApprovedTickets = async () => {
  const path = '/v2/investor/ticket_orders/approved_tickets_number';
  const method = 'get';
  const config = { method, path };

  const { data } = await request(config);

  const { approvedTicketsNumber } = camelify(data);

  return approvedTicketsNumber;
};

export const fetchTotalActiveOrCloseTickets = async () => {
  const path = '/v2/investor/ticket_orders/actived_and_closed_tickets_number';
  const method = 'get';
  const config = { method, path };

  const { data } = await request(config);

  const { activedAndClosedTicketsNumber } = camelify(data);

  return activedAndClosedTicketsNumber;
};
