import { useEffect } from 'react';
import { t } from 'i18n';
import get from 'lodash.get';
import { showToast } from 'utils/toastHandler';
import { useReduxMutator } from 'utils/redux-query';
import {
  SERVER_ERROR_HTTP_STATUS,
  UNAUTHORIZED_HTTP_STATUS,
} from 'config/constants';

const LAST_ATTEMPT_ERROR = 'auth.last_attempt';
const LOCKED_ACCOUNT_ERROR = 'auth.not_confirmed';
const isLastAttemptError = (errors) => errors.includes(LAST_ATTEMPT_ERROR);
const isLockedAccountError = (errors) => errors.includes(LOCKED_ACCOUNT_ERROR);

export const useLogin = () => {
  const [createSession, { status: createSessionStatus, error }] = useReduxMutator('CREATE_SESSION');
  const isLoading = createSessionStatus.pending;
  const login = ({ email, password }) => createSession({ email, password });

  useEffect(() => {
    if (!error) return;
    const status = get(error, 'response.status');
    const errors = get(error, 'response.errors') || [];
    if (status === UNAUTHORIZED_HTTP_STATUS) {
      if (isLockedAccountError(errors)) {
        showToast('error', t('login.errors.lockedAccount'));
        return;
      }
      if (isLastAttemptError(errors)) {
        showToast('error', t('login.errors.lastAttempt'));
        return;
      }
      showToast('error', t('login.errors.invalidPassword'));
      return;
    }
    if (status >= SERVER_ERROR_HTTP_STATUS) {
      showToast('error', t('login.errors.server'));
      return;
    }
    showToast('error', t('login.errors.unknown'));
  }, [error]);

  return {
    login,
    error,
    isLoading,
  };
};
