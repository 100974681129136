import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';
import { useReduxMutator } from 'utils/redux-query';
import JobStep from 'views/RegisterFunnel/components/JobStep';

function Occupation() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [updateProfile] = useReduxMutator('UPDATE_EXPEDIENT_PROFILE');

  const handleFinishProcess = () => {
    dispatch(fetchPersonalArchives());
  };

  const saveProfile = ({ profile }) => updateProfile({ profile });

  return (
    <Switch>
      <Route path={`${match.url}/create`}>
        <JobStep
          noReferral
          goNextStep={saveProfile}
          onConfirm={handleFinishProcess}
        />
      </Route>
    </Switch>
  );
}

export default Occupation;
