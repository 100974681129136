import { useSelector } from 'react-redux';
import { t, l, tscope } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import { csvMaker, downloadCSV } from 'utils/CSVDownloader/CSVDownloader';
import { useMemo } from 'react';

const ts = tscope('Movements.default');

const buildCollectionTable = ({ collection }) => collection.map(({
  tipo,
  fecha,
  total,
  saldo,
  referencia,
  company,
  autorizacion,
  account,
}) => ({
  [ts('CSVHeaders.authorization')]: autorizacion,
  [ts('CSVHeaders.company')]: company,
  [ts('CSVHeaders.date')]: fecha,
  [ts('CSVHeaders.type')]: tipo,
  [ts('CSVHeaders.amount')]: total,
  [ts('CSVHeaders.reference')]: referencia,
  [ts('CSVHeaders.total')]: saldo,
  [ts('CSVHeaders.account')]: account,
}));

export const useController = () => {
  const [movements, { status: movementsStatus }] = useReduxQuery('MOVEMENTS');
  const { collection } = movements || {};
  const isNotLaptop = useSelector(({ browser }) => browser.lessThan.laptop);
  const displayDownloadCSVFile = !!collection?.length;
  const isEnableDownloadCSVFile = !movementsStatus.completed;

  const onDownloadMovements = () => {
    if (!Array.isArray(collection)) return;
    const collectionTable = buildCollectionTable({ collection });
    const movementsTable = csvMaker({ data: collectionTable });
    downloadCSV({
      data: movementsTable,
      fileName: ts('movementsFileName', { date: l('date.formats.short', movements.updated_at) }),
    });
  };

  const updatedAtText = useMemo(() => {
    if (movementsStatus.pending) return t('common.loadingText');
    if (movementsStatus.completed && movements?.updated_at) return l('date.formats.dateAndTimeLong', movements?.updated_at);
    return t('common.na-');
  }, [
    movements?.updated_at,
    movementsStatus.pending,
    movementsStatus.completed,
  ]);

  return {
    collection,
    isNotLaptop,
    updatedAtText,
    onDownloadMovements,
    displayDownloadCSVFile,
    isEnableDownloadCSVFile,
  };
};
