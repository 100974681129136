import { useForm } from 'react-hook-form';
import { t } from 'i18n';

const resolver = (values, context) => {
  const {
    name,
  } = context;
  const password = values[name];
  const errors = {};
  if (!password) errors.password = { message: t('Forms.fields.errors.required') };

  return {
    values,
    errors,
  };
};

const createOnError = ({ name, setValue, setError }) => (error) => {
  if (error.response?.status === 403) {
    setValue(name, '');
    setError(name, { message: t('Forms.fields.errors.invalidPassword') });
  }
};

export const useAuthorizeForm = ({
  name = 'password',
} = {}) => {
  const {
    control,
    setError,
    setValue,
    handleSubmit,
  } = useForm({
    resolver,
    context: {
      name,
    },
  });

  const onError = createOnError({
    name,
    setValue,
    setError,
  });

  const wrapHandleSubmit = (onSubmit) => handleSubmit(async (values) => {
    await onSubmit(values);
    setValue(name, '');
  });

  return {
    control,
    onError,
    handleSubmit: wrapHandleSubmit,
  };
};
