import { useContext, useMemo, useEffect } from 'react';
import * as cookiesStrategy from 'utils/cookies';
import { webStorageContext } from '../context';

const STRATEGIES = {
  COOKIES: {
    setValue: (key, value, options) => cookiesStrategy.setCookie(key, value, options),
    getValue: (key, options) => cookiesStrategy.getCookie(key, options),
    delete: (key, options) => cookiesStrategy.removeCookie(key, options),
  },
  LOCAL_STORAGE: {
    setValue: (key, value) => localStorage.setItem(key, value),
    getValue: (key) => localStorage.getItem(key),
    delete: (key) => localStorage.removeItem(key),
  },
};

const useStorage = (strategy, key, options) => {
  const storage = useMemo(() => STRATEGIES[strategy], []);
  const [getValue, setValueContext, context] = useContext(webStorageContext);
  const KEY = `${context.prefixKey}${key}`;
  const currentValue = getValue(KEY, { strategy });

  const setValue = (value) => {
    if (!value) storage.delete(KEY, options);
    else {
      storage.setValue(KEY, value, options);
      setValueContext(KEY, value, { strategy });
    }
  };

  useEffect(() => {
    const value = storage.getValue(KEY);
    setValueContext(KEY, value, { strategy });
  }, [KEY, strategy]);

  return [currentValue, setValue];
};

export const useCookie = (...args) => useStorage('COOKIES', ...args);

export const useLocalStorage = (...args) => useStorage('LOCAL_STORAGE', ...args);
