import { gql } from '@apollo/client';
import { useMutation } from 'hooks/apollo';

export const UPDATE_USER_FISCAL = gql`
mutation UPDATE_USER_FISCAL ($input: UpdateFiscalInformationMutationInput!)  {
  updateFiscalInformation (input: $input) {
    user {
      id
      fiscalInformation {
        name
        regime
        zipCode
      }
    }
  }
}
`;

export const useUpdateUserFiscal = (options) => useMutation(UPDATE_USER_FISCAL, options);
