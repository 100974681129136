import { t } from 'i18n';
import { saveRisk, signRisks } from 'apis/electronicRiskCertificate';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { dashboardRoute } from 'routes/';
import { showToastError, showToastInfo } from 'utils/toastHandler';
import { getRisksERC } from 'actions/electronicRiskCertificate';
import { getPendingRequirementIndex } from '../utils/getPendingRequirementIndex';
import { CERContext } from '../../../CER.context';

const tsAlerts = (key) => t(key, { scope: 'components.CER.alerts' });

export const useController = () => {
  const history = useHistory();
  const { onConfirmSuccess } = useContext(CERContext);
  const [step, setStep] = useState(0);
  const [isLoadingRiskList, setIsLoadingRiskList] = useState(false);
  const [isSavingRisk, setIsSavingRisk] = useState(false);
  const [riskList, setRisksState] = useState({
    advisory: false,
    information: false,
    yield_investment: false,
    losses: false,
    guarantee_of_resources: false,
    liquidity: false,
  });

  const fetchAcceptedConditions = async () => {
    const { risk } = await getRisksERC();
    const pendingStep = getPendingRequirementIndex(risk);

    setStep(pendingStep);
    setRisksState(risk);
    setIsLoadingRiskList(false);
  };

  useEffect(() => {
    setIsLoadingRiskList(true);
    fetchAcceptedConditions();
  }, []);

  const acceptTermHandler = async (data, hasFinished) => {
    const sendData = {};
    setIsSavingRisk(true);
    if (hasFinished) {
      sendData.password = data;

      try {
        const isSignedSuccessfully = await signRisks(sendData);
        if (isSignedSuccessfully) {
          showToastInfo(tsAlerts('success.riskSaved'));
          await onConfirmSuccess();
        }
      } catch {
        showToastError(tsAlerts('error.riskSaved'));
      }
    } else {
      sendData[data] = true;
      const response = await saveRisk(sendData);
      const pendingStep = getPendingRequirementIndex(response.risk);

      setStep(pendingStep);
    }

    setIsSavingRisk(false);
  };

  const goToDashboardHandler = () => {
    history.push(dashboardRoute);
  };

  return {
    step,
    riskList,
    isSavingRisk,
    isLoadingRiskList,
    acceptTermHandler,
    goToDashboardHandler,
  };
};
