import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const USER_QUERY = gql`
query USER_QUERY {
  user {
    id
    fiscalInformation {
      regime
      name
      zipCode
    }
  }
}
`;

export const useUser = (options) => useQuery(USER_QUERY, options);
