import { uploadAddress } from 'apis/uploadAddress';
import { useCallback, useState } from 'react';

export const useUploadAddress = ({
  onCompleted,
} = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [progress, setProgress] = useState(null);

  const uploadDocument = useCallback(async ({
    file,
  }) => {
    try {
      setIsLoading(true);
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      };

      const response = await uploadAddress({ file, onUploadProgress });

      if (onCompleted) onCompleted(response);
      return response;
    } finally {
      setIsFinished(true);
      setIsLoading(false);
    }
  }, [onCompleted]);

  return {
    progress,
    isLoading,
    isFinished,
    uploadDocument,
  };
};
