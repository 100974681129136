import React from 'react';
import autoinvestImageURL from 'assets/autoInvest.svg';
import {
  Text,
  Grid,
  Card,
  Image,
} from 'ui';
import { EditAutoinvestForm } from '../components';

export const EditAutoinvest = () => (
  <Grid margin="5" justifyContent="center">
    <Card flexDirection="row" gap="5" padding={{ base: '5', md: '8' }} maxWidth="4xl">
      <Image src={autoinvestImageURL} boxSize="10" />
      <Text>
        Bienvenido a Autoinvest, aquí podrás seleccionar tus criterios de préstamo y
        nosotros prestaremos tu dinero automáticamente a las solicitudes que cumplan
        con estos requisitos.
      </Text>
    </Card>
    <EditAutoinvestForm paddingX={{ base: '0', md: '10' }} paddingY="10" maxWidth="4xl" />
  </Grid>
);
