export const styles = {
  container: {
    px: '2rem',
    mt: { base: '4rem' },
    mb: { base: '8rem' },
    mx: { base: 'auto', sm: '8rem' },
    maxWidth: { base: 'auto', xs: '30rem' },
  },
  form: {
    gap: '2rem',
  },
  field: {
    color: 'schema.gray.700',
  },
  neighborhoodField: {
    inputProps: { autocomplete: false },
  },
  numberFields: {
    gap: '1rem',
    width: '100%',
    templateColumns: {
      base: '8rem 8rem 10rem',
      xs: '9rem 9rem 10rem',
    },
  },
  divider: {
    my: '1rem',
    color: 'schema.gray.300',
  },
  buttonGroup: {
    justifyContent: 'space-between',
  },
};
