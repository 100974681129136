export const ADD_SINGLE_TO_CART = 'ADD_SINGLE_TO_CART';
export const FETCHING_CART_LIST = 'FETCHING_CART_LIST';
export const FAILED_FETCH_CART_LIST = 'FAILED_FETCH_CART_LIST';
export const FAILED_TO_ADD = 'FAILED_TO_ADD';
export const UPDATE_SINGLE_IN_CART = 'UPDATE_SINGLE_TO_CART';
export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const UPDATE_CART_TOTAL = 'UPDATE_CART_TOTAL';
export const TOGGLE_LOADING_LENDING = 'TOGGLE_LOADING_LENDING';
export const UPDATE_CART_ITEMS_TOTAL = 'UPDATE_CART_ITEMS_TOTAL';
export const ADD_TICKET = 'ADD_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
