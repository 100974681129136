import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const WITHDRAWAL_BANK_ACCOUNTS = gql`
query WITHDRAWAL_BANK_ACCOUNTS {
  user {
    id
    withdrawalAccounts {
      id
      clabe
      status
      clabeAlias
      bank
    }
  }
}
`;

export const useWithdrawalBankAccounts = (options) => useQuery(WITHDRAWAL_BANK_ACCOUNTS, options);
