import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDevelopment } from 'config/';
import { getQueryRecord, invalidateQueryRecords } from '../queryBase';
import parseMeta from './parseMeta';
import { validateActionFn } from './alerts';
import { createActions } from '../actionCreator';

const useReduxSubscription = (keystore) => {
  const { payload, meta } = useSelector((state) => state[keystore]);
  const dispatch = useDispatch();
  const queryRecord = getQueryRecord(keystore);
  const actionStored = queryRecord?.query;

  const tryCallActionStored = useCallback(
    async (...params) => {
      try {
        await dispatch(actionStored({}, ...params));
      } catch (error) {
        if (isDevelopment) {
          // eslint-disable-next-line no-console
          console.warn(
            'Warning(redux-query): the error displayed below was throw by (%s) query, IGNORE this warn if you already catch this error on your component',
            keystore,
          );
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    },
    [actionStored],
  );

  const { reinitialize } = createActions(keystore);
  const dispatchReinitialize = () => {
    invalidateQueryRecords([keystore]);
    dispatch(reinitialize());
  };

  const refetch = useCallback((...params) => {
    validateActionFn(keystore, actionStored);

    return tryCallActionStored(...params);
  }, [tryCallActionStored]);

  return [
    payload,
    {
      ...parseMeta(meta),
      refetch,
      reinitialize: dispatchReinitialize,
    },
  ];
};

export default useReduxSubscription;
