export const style = {
  outerContainer: {
    maxWidth: '100%',
    padding: 0,
  },
  container: {
    gap: 3,
    padding: 4,
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: { base: 'column', sm: 'row' },
  },
  hasNoAccounts: {
    flexGrow: '1',
    maxWidth: '100%',
  },
  secondFactor: {
    padding: 4,
    width: '100%',
    maxWidth: '100%',
  },
  button: {
    width: { base: '100%', sm: 44 },
    minWidth: { base: '100%', sm: 44 },
  },
};
