import React from 'react';
import DotsLoader from 'components/DotsLoader';
import Button from 'components/Button';

const SaveProfileButton = ({ title, submitting, disabled }) => (
  <Button form="profile-form" type="submit" disabled={disabled || submitting}>
    {submitting ? <DotsLoader black /> : title}
  </Button>
);

export default SaveProfileButton;
