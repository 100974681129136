import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/requisitions/icon-filter.svg';
import ButtonContainer from './styles';

const FilterButton = (props) => {
  const {
    children, handleClick, type, color, disabled,
  } = props;
  return (
    <ButtonContainer
      onClick={handleClick}
      type={type || 'button'}
      color={color || 'primary'}
      disabled={disabled}
      {...props}
    >
      <FilterIcon title="" />
      {children}
    </ButtonContainer>
  );
};

export default FilterButton;
