import { userStatus } from 'config/constants';

function Settings(session) {
  if (!session) return null;

  const { settings = {}, user = {} } = session;

  function shouldSignCER() {
    return [userStatus.PROFILE_COMPLETED].includes(user.status);
  }

  function shouldSignContract() {
    return [userStatus.CONTRACT_UNSIGNED].includes(user.status);
  }

  function isBlockedAccount() {
    return [[userStatus.BLOCKED].includes(user.status)].some((val) => val === true);
  }

  function hasProfileValidated() {
    return settings.profileValidated === true;
  }

  function hasAddressValidated() {
    return settings.addressValidated === true;
  }

  return {
    shouldSignCER,
    shouldSignContract,
    isBlockedAccount,
    hasProfileValidated,
    hasAddressValidated,
    attrs: settings,
  };
}

export default Settings;
