import snakeify from 'snakecase-keys';
import camelify from 'camelcase-keys-recursive';
import { request } from 'apis/fetchers/yotepresto';

const update = async ({ email, otpCode }) => {
  const body = snakeify({
    email,
    otpCode,
  });
  const config = { method: 'put', path: '/v2/investor/users/change_email', body };

  const { data } = await request(config);

  return camelify(data);
};

export default update;
