import React from 'react';
import orderDefault from 'assets/requisitions/order-default.svg';
import orderDesc from 'assets/requisitions/order-descend.svg';
import orderAsc from 'assets/requisitions/order-ascend.svg';

export const getSortArrow = (category, type, sortOrder) => {
  if (category === type) {
    if (sortOrder === 'asc') return <img src={orderAsc} alt="order ascend" />;
    if (sortOrder === 'desc') return <img src={orderDesc} alt="order descend" />;
    return <img src={orderDefault} alt="order" />;
  }
  return <img src={orderDefault} alt="order" />;
};
