import React from 'react';
import { Panel } from 'components';
import { Button } from 'ui';
import { ContractModal } from './ContractModal';
import { useController } from './hooks';

export const ContractPanel = ({
  contracts,
  ...props
}) => {
  const {
    closeModal,
    isOpenModal,
    userContracts,
    onOpenContract,
    currentContract,
  } = useController({ contracts });

  return (
    <Panel
      title="Mis contratos"
      innerContentProps={{ direction: 'row', justifyContent: 'space-around' }}
      {...props}
    >
      {userContracts.map((contract) => (
        <Button
          key={contract.type}
          variant="outline"
          colorScheme="gray"
          onClick={() => onOpenContract(contract)}
        >
          {contract.name}
        </Button>
      ))}
      {isOpenModal && (
        <ContractModal
          isOpen={isOpenModal}
          handleClose={closeModal}
          selectedContract={currentContract}
        />
      )}
    </Panel>
  );
};
