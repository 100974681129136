import styled, { css } from 'styled-components';
import {
  textHelper, Grid, boxHelpers, shouldForwardProp,
} from 'styles';
import colors from 'styles/colors';

export const Header = styled.section`
  display: grid;
  place-items: center;
  gap: 1rem;
  color: #6ea0b0;
  font-size: 1.4rem;
  ${boxHelpers}
`;

export const Head = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  ${boxHelpers}
`;

export const Body = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 1.4rem;
  color: #04475c;
  box-shadow: rgba(37, 103, 141, 0.03);
  width: 100%;

  ${boxHelpers}
`;

export const Row = styled(Grid).withConfig({ shouldForwardProp })`
  display: grid;
  gap: 1rem;
  width: 100%;
  border-color: ${colors.schema.gray[200]};
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;

  &:last-child {
    ${({ hasLastBorder }) => (hasLastBorder
    ? ''
    : css`
            border-bottom-width: 0;
          `)}
  }

  ${boxHelpers}
`;

Row.defaultProps = {
  $placeItems: 'center',
  $columnsTemplate: 'none',
  $columnGap: '0',
};

export const Cell = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  ${textHelper}
`;
