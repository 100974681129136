import React from 'react';
import { tscope } from 'i18n';
import {
  AuthorizeWithOTPField,
} from 'components/AuthorizeWithOTPField';
import {
  Flex,
  Grid,
  Button,
  Divider,
} from 'ui';
import { PhoneField } from 'components/FormFields';
import { useController } from './hooks';

const ts = tscope('Dashboard.Settings.Account.ContactsPanel.phoneNumberForm');

export const PhoneForm = ({
  onCancel,
  onSuccess,
  shouldReset,
  ...props
}) => {
  const {
    onSubmit,
    otpProps,
    phoneCodes,
    submitProps,
    newPhoneProps,
    currentPhoneProps,
  } = useController({
    t: ts,
    onSuccess,
    shouldReset,
  });

  return (
    <Grid
      gap="5"
      as="form"
      padding="5"
      width="320px"
      onSubmit={onSubmit}
      placeContent="center"
      data-testid="PhoneNumberForm"
      {...props}
    >

      <PhoneField
        isSelectCodeDisabled
        name="currentPhone"
        data-testid="currentPhone"
        options={phoneCodes.options}
        labelText={ts('fields.currentPhoneNumber.label')}
        {...currentPhoneProps}
      />

      <PhoneField
        name="newPhone"
        data-testid="newPhone"
        options={phoneCodes.options}
        labelText={ts('fields.newPhoneNumber.label')}
        {...newPhoneProps}
      />
      <Divider borderColor="gray.300" />
      <AuthorizeWithOTPField
        t={ts}
        size="sm"
        name="otp"
        data-testid="PhoneForm.otp"
        placeSelf="center"
        {...otpProps}
      />
      <Flex
        gap="5"
        justifyContent="space-evenly"
      >
        <Button
          area="1 / 3"
          justifySelf="end"
          variant="ghost"
          onClick={onCancel}
        >
          {ts('actions.cancel')}
        </Button>
        <Button
          type="submit"
          area="1 / 3"
          justifySelf="end"
          {...submitProps}
        >
          {ts('actions.submit')}
        </Button>
      </Flex>
    </Grid>
  );
};
