import styled from 'styled-components';
import React from 'react';

const container = ({ toggle, ...otherProps }) => <div {...otherProps} />;

const MobilBarRequisitionDetailContainer = styled(container)`
  position: absolute;
  bottom: 0;
  width: calc(100% - 20rem);
  background-color: transparent;
  @media (max-width: 1400px) {
    width: 100%;
  }
  .mobileBar {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    flex: 1;
    border-radius: ${(props) => (props.toggle ? '20px 20px 0 0' : 'none')};
    box-shadow: ${(props) => (props.toggle ? '0 0 30px 0 rgba(37,103,141,0.34)' : 'none')};
    &.bg_trasnparent {
      background-color: transparent;
    }
    &.noNavButtons {
      background-color: transparent;
    }
    &__backgroundBar {
      flex: 1;
      height: 100%;
      box-shadow: ${(props) => (props.toggle ? '0 0 30px 0 rgba(37,103,141,0.34)' : 'none')};
      border-radius: ${(props) => (props.toggle ? '20px 20px 0 0' : 'none')};
    }
    &__navigationContent {
      flex: 1;
      display: flex;
      flex-direction: row;
      height: 100%;
      color: #a8c9d6;
    }
    &__lendingContent {
      flex: 1;
      height: 100%;
      padding: 0 2rem 1rem;
      border-radius: 20px 20px 0 0;
    }
    &__navigationButton {
      flex: 1;
      box-shadow: 0 0 14px 0 rgba(4, 71, 92, 0.17);
      border-top: 1px solid #cfe6ef;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 5rem;
      text-decoration: none;
      color: inherit;
      &:hover {
        cursor: pointer;
      }
      &.right {
        justify-content: flex-end;
      }
      &__sign {
        font-size: 3rem;
        font-weight: 300;
        &.right {
          margin-left: 1rem;
          margin-right: 0.8rem;
        }
        &.left {
          margin-left: 0.8rem;
          margin-right: 1rem;
        }
      }
    }
    &__lendButton {
      position: absolute;
      right: 40%;
      bottom: 0;
      width: 147px;
      height: 74px;
      background-color: #ffffff;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border: 1px solid #cfe6ef;
      border-bottom: 0;
      box-shadow: 0 0 22px 0 rgba(4, 71, 92, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.4rem;
      color: #0076ff;
      @media (max-width: 700px) {
        right: 32%;
      }
      @media (max-width: 380px) {
        right: 30%;
      }
      img {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
`;

export default MobilBarRequisitionDetailContainer;
