import React from 'react';
import isNil from 'utils/isNil';
import { Grid } from 'styles';
import { CheckListItem } from 'components';
import { ERRORS_CONFIG } from './constants';

const getErrorStatus = (error) => {
  if (isNil(error)) return 'default';
  if (error) return 'error';
  return 'success';
};

const shouldRenderItem = (error, errors) => (error.alwaysDisplay || errors?.includes(error.key));

export const PasswordValidations = ({ errors }) => (
  <Grid as="ul" rowGap="0.8rem">
    {ERRORS_CONFIG
      .filter((error) => shouldRenderItem(error, errors))
      .map((error) => (
        <CheckListItem
          key={error.key}
          status={getErrorStatus(errors?.includes(error.key))}
        >
          {error.text}
        </CheckListItem>
      ))}
  </Grid>
);
