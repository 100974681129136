import styled from 'styled-components';
import { ResponsiveHelpers } from 'styles';
import { breakPoints } from 'config/constants';
import { ReactComponent as ReviewingInfoImage } from 'assets/signup/reviewing-info.svg';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/phone-call.svg';

export const Container = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  min-height: 8rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 2rem;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    min-height: 4rem;
    padding-right: 1rem;
  }
`;

export const CloseSessionLink = styled.button`
  background-color: transparent;
  border-width: 0;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: '500';
  color: #0f7eff;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - 8rem);
  padding: 5% 2rem;
`;

export const Box = styled.div`
  display: flex;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(38, 57, 69, 0.09);
  padding: 6rem 6rem 6rem 4rem;
  align-items: center;
  max-width: 536px;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
  }
`;

export const Image = styled(ReviewingInfoImage)`
  min-width: 137px;
  min-height: 101px;
  margin-right: 3rem;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const PhoneIcon = styled(PhoneCallIcon)`
  min-height: 44px;
  min-width: 44px;
  margin-right: 2rem;
`;

export const Br = styled.br`
  ${ResponsiveHelpers}
`;
