import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { extraLargeBreakPoint, midBreakPoint } = breakPoints;

const FundedAndCartAmountsContent = styled.div`
  color: white;
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  @media (max-width: ${midBreakPoint}px) {
    font-size: 1.2rem;
    display: block;
  }
  .funded,
  .amountCart {
    flex: 1;
    text-align: center;
    padding: 1.7rem 0;
    @media (max-width: ${extraLargeBreakPoint}px) {
      padding: 1.4rem 0;
    }
  }
  .funded {
    background-color: #4ed79f;
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 600;
  }
  .amountCart {
    background-color: #ecf5fe;
    border: 1px solid rgba(59, 144, 229, 0.3);
    border-right: 0;
    font-size: 600;
    color: #346071;
  }
  .loadingLendAmount {
    width: 2rem;
    display: inline-block;
  }
`;

export default FundedAndCartAmountsContent;
