import React from 'react';
import { t } from 'i18n';
import InstructionsContainer from './styles';

const Instructions = () => (
  <InstructionsContainer>
    <p className="top-heading">
      Ahora tienes más oportunidades de hacer crecer tu dinero, ¡aprovecha nuestro programa de
      referidos para inversionistas! ¿Qué tienes que hacer? Es muy sencillo, aquí te lo explicamos
      en tres pasos:
    </p>
    <div className="listSteps">
      <dt>
        Comparte tu código de referencia, es tu nombre de usuario y lo puedes encontrar al iniciar
        sesión en tu cuenta de yotepresto.com, en la parte superior derecha.
      </dt>
      <dt>
        Tu referido deberá agregar el código al registrarse como nuevo inversionista en el campo
        que dice “Tengo un código de referencia”.
      </dt>
      <dt>
        Por cada persona que se registre gracias a ti, y que preste dinero durante el primer mes a
        partir de su fecha de registro, tú recibirás los siguientes bonos:
      </dt>
    </div>
    <div className="table-rages-and-amounts-table-container">
      <table className="amounts-table">
        <tbody>
          <tr>
            <td className="top-cell">{t('settings.referralProgram.instructions.titleRange')}</td>
            <td className="top-cell">{t('settings.referralProgram.instructions.titleBenefit')}</td>
          </tr>
          <tr>
            <td className="range">{t('settings.referralProgram.instructions.firstRange')}</td>
            <td className="benefit">{t('settings.referralProgram.instructions.firstBenefit')}</td>
          </tr>
          <tr>
            <td className="range">{t('settings.referralProgram.instructions.secondRange')}</td>
            <td className="benefit">{t('settings.referralProgram.instructions.secondBenefit')}</td>
          </tr>
          <tr>
            <td className="range">{t('settings.referralProgram.instructions.thirdRange')}</td>
            <td className="benefit">{t('settings.referralProgram.instructions.thirdBenefit')}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="conditions-container">
      <p className="top-heading">Consideraciones</p>
      <ul className="conditions-list">
        <li>
          Será requisito indispensable que el inversionista referido capture el código al momento
          de su registro. No será válido referir por otro medio ni en otro momento.
        </li>
        <li>El código sólo es válido para referir a nuevos inversionistas.</li>
        <li>
          En caso de que un usuario referenciado tenga más de una cuenta abierta en
          yotepresto.com, se considerarán la fecha y el código de referencia de su primer
          registro.
        </li>
        <li>
          Para la bonificación solo se considerarán los préstamos entregados durante el primer mes
          a partir de la fecha de registro de tu referido.
        </li>
        <li>
          La bonificación se realizará en un plazo aproximado de 10 días posteriores a la fecha en
          la que tu referido haya cumplido un mes de haberse registrado con nosotros. Los recursos
          de la bonificación se depositarán en tu cuenta de yotepresto.com y podrás reinvertirlos
          o retirarlos.
        </li>
        <li>
          Los beneficios de este programa no son acumulables con otras promociones, cupones o
          programas.
        </li>
      </ul>
    </div>
  </InstructionsContainer>
);

export default Instructions;
