import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import withSizes from 'react-sizes';
import PATHS from 'routes';
import { Flex } from 'styles';
import { getPaymentSchedule } from 'actions/calendar/calendarActions';
import { breakPoints } from 'config/constants';
import CalendarContainer from './styles';
import AsideCalendar from './AsideCalendar';
import DailyIncomes from './DailyIncomes';
import PaymentsSummary from './PaymentsSummary';
import { DailyIncomesLoader, AsideCalendarLoader } from './Loaders';
import Footer from '../FooterDashboard';
import { ALERTS } from '../Alerts/Alerts';
import DisplayDetails from '../DisplayDetails';

const { investorCalendarRequisitionPath, investorCalendarPath } = PATHS;

function Calendar({ getCurrentSchedule, width, isLoading }) {
  useEffect(() => {
    const dateNow = moment();
    getCurrentSchedule({
      year: dateNow.format('YYYY'),
      month: dateNow.format('MM'),
    });
  }, [getCurrentSchedule]);

  const mobile = width < breakPoints.smallBreakPoint;

  return (
    <>
      <CalendarContainer>
        {mobile && !isLoading && <PaymentsSummary />}
        {isLoading ? <AsideCalendarLoader /> : <AsideCalendar />}
        <Flex direction="column" w="100%" ml={{ base: '0', md: '30rem' }}>
          {ALERTS.map(({ id, Component }) => <Component key={id} m="2rem" />)}
          {isLoading ? <DailyIncomesLoader /> : <DailyIncomes />}
        </Flex>
        <Route path={investorCalendarRequisitionPath()} scrollToTopOnEnter={false}>
          {({ match }) => <DisplayDetails isShowing={match} backTo={investorCalendarPath()} />}
        </Route>
      </CalendarContainer>
      <Footer />
    </>
  );
}

const mapSizesToProps = ({ width }) => ({ width });

const mapStateToProps = ({ calendar }) => ({
  isLoading: calendar.loadingSchedule,
});

export default withSizes(mapSizesToProps)(
  connect(mapStateToProps, { getCurrentSchedule: getPaymentSchedule })(Calendar),
);
