import React from 'react';
import { Field } from 'react-final-form';
import ChoiceSelectorContainer from './styles';
import { Text } from '../Typography';

const ChoiceSelector = ({
  name,
  options,
  className = '',
  disabled,
  'data-testid': dataTestId,
}) => (
  <ChoiceSelectorContainer className={`radios ${className}`} disabled={disabled}>
    {options.map((item, index) => {
      const id = `${name}_${item.value}`;
      const key = `${index}_${id}`;
      return (
        <Field key={key} name={name} type="radio" value={item.value}>
          {({ input }) => (
            <div className={`option__button ${item.className || ''}`}>
              <input
                id={id}
                {...input}
                disabled={disabled}
                data-testid={dataTestId}
              />
              <label htmlFor={id}>{item.label}</label>
            </div>
          )}
        </Field>
      );
    })}
    <Field name={`${name}_errors`}>
      {({ meta }) => meta.error && meta.touched && (
        <Text color="schema.red.500" fontSize="1.2rem">{meta.error}</Text>
      )}
    </Field>
  </ChoiceSelectorContainer>
);
export default ChoiceSelector;
