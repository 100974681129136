import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'utils/webStorage';

export const EXPIRE_AFTER = 60000;
export const STORAGE_KEY = 'account_statement_download_expiry';

export const useDelayGenerate = ({
  isAccountStatementAvailable,
}) => {
  const [downloadExpiry, setDownloadExpiry] = useLocalStorage(STORAGE_KEY);
  const isAvailableToRequest = isAccountStatementAvailable && !downloadExpiry;

  const saveRequestTime = () => {
    const currentTime = new Date().getTime();
    setDownloadExpiry(currentTime);
  };

  const verifyExpiryTime = useCallback(() => {
    const currentTime = new Date().getTime();
    const lastTimeFromWasDownloaded = currentTime - downloadExpiry;
    if (downloadExpiry && lastTimeFromWasDownloaded > EXPIRE_AFTER) setDownloadExpiry(null);
  }, [downloadExpiry]);

  useEffect(() => {
    if (isAccountStatementAvailable === false) setDownloadExpiry(null);
    else verifyExpiryTime();
  }, [verifyExpiryTime]);

  return {
    saveRequestTime,
    setDownloadExpiry,
    isAvailableToRequest,
  };
};
