import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { t } from 'i18n';

const resolver = (values) => {
  const { accept } = values;
  const errors = {};
  if (!accept) errors.accept = { message: t('Forms.fields.errors.acceptTerms') };

  return {
    values,
    errors,
  };
};

export const useController = () => {
  const history = useHistory();
  const { control, handleSubmit } = useForm({
    resolver,
  });
  const onSubmit = handleSubmit(() => {
    history.push('edit');
  });

  return {
    control,
    onSubmit,
  };
};
