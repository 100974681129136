import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n-js';
import BulletThread from 'components/BulletThread/BulletThread';
import { Box, Flex, Grid } from 'styles';
import { Text } from 'components/Typography';
import { useReduxMutator } from 'utils/redux-query';
import Link from 'components/Link';
import { userSettingsSecurityChangePhone } from 'routes';
import ConfirmChangeOTPForm from 'views/DashboardContent/Settings/MainArea/Security/SecondFactorSelection/SecondFactorStrategies/components/ConfirmChangeOTPForm';
import EmailOTPForm from 'views/DashboardContent/Settings/MainArea/Security/SecondFactorSelection/SecondFactorStrategies/components/EmailOTPForm';
import { FIRST_STEP } from '../config/Constants';
import SecondStepHeader from './SecondStepHeader';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.2FAInstructions.SMS', ...options });

const type = 'sms';

const SelectSMS = (props) => {
  const {
    setCurrentModal, email, phone, onRequestClose, unobscuredPhone,
  } = props;
  const [, { data: requestChangeData, status: requestChangeStatus }] = useReduxMutator(
    'REQUEST_CHANGE_SECOND_FACTOR',
  );
  const [bulletStage, setBulletStage] = useState(['active', 'inactive']);

  return (
    <Flex direction="column" bgColor="white" h="auto">
      <SecondStepHeader
        onClick={() => setCurrentModal(FIRST_STEP)}
        imageName="SMS_FLAG"
        title={ts('header')}
        p="3rem"
      />
      <Grid columnGap="2rem" p="3rem" alignItems="start" rowGap="0.3rem">
        <BulletThread
          gridArea="1/1"
          gridColumn="1"
          last={false}
          status={bulletStage[0]}
          number="1"
        />
        <Box gridArea="1/2">
          <Text data-testid="emailOTPInstructions" mb="1.5rem">
            {ts('instructions.0.0')}
            <Text as="b">{ts('instructions.0.1', { email })}</Text>
          </Text>
          <EmailOTPForm type={type} phone={unobscuredPhone} setBulletStage={setBulletStage} />
        </Box>
        <BulletThread gridArea="2/1" last={false} status={bulletStage[1]} number="2" />
        <Box gridArea="2/2">
          <Text mb="0.5rem">
            <Text data-testid="SMSOTPInstructions" as="b">{ts('instructions.1.0', { phone })}</Text>
          </Text>
          <Text size="small" color="schema.gray.500">
            {ts('instructions.1.1')}
            {ts('instructions.1.2')}
            <Link
              color="blue"
              to={userSettingsSecurityChangePhone}
              onClick={() => onRequestClose(false)}
            >
              {ts('instructions.1.3')}
            </Link>
          </Text>
        </Box>
        <BulletThread gridArea="3/1" status={bulletStage[1]} number="3" />
        <Box gridArea="3/2">
          <Text>
            {' '}
            {ts('instructions.2', { phone })}
          </Text>
          {requestChangeStatus.completed && requestChangeData && (
            <ConfirmChangeOTPForm enableResendSMS />
          )}
        </Box>
      </Grid>
    </Flex>
  );
};

SelectSMS.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  unobscuredPhone: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default SelectSMS;
