import React from 'react';
import { ReactComponent as NoPerformanceSvg } from 'assets/noPerformance.svg';
import { useReduxQuery } from 'utils/redux-query';
import PATHS from 'routes';
import Card from 'views/Card';
import { t } from 'i18n';
import { Box } from 'styles';
import isNil from 'utils/isNil';
import Link from 'components/Link';
import { Text } from 'components/Typography';
import ProjectionChartContainer from './styles';
import ReturnChart from './ReturnChart';
import ProfitInfo from './ProfitInfo';
import Loader from './loader';

const ProjectionChart = (props) => {
  const { isMobile, handleModal, ...wrapperProps } = props;
  const [totalLoansMade] = useReduxQuery('TOTAL_LOANS_MADE');
  const [statements, { status: statementsStatus }] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [investments, { status: investmentsStatus }] = useReduxQuery('FETCH_ACCOUNT_INVESTMENTS');

  if (statementsStatus.initial || investmentsStatus.initial) return null;

  const isPendingOrCaching = statementsStatus.pending
    || statements?.caching
    || investmentsStatus.pending
    || investments?.caching;

  const isCompleted = statementsStatus.completed
    && !statements?.caching
    && investmentsStatus.completed
    && !investments?.caching;

  const averageRate = investments?.averageRate;
  const accountValue = statements?.valorCuenta;

  return (
    <ProjectionChartContainer isMobile={isMobile} {...wrapperProps}>
      <ProfitInfo handleModal={handleModal} />
      {isPendingOrCaching && (
        <Box
          bgColor="white"
          borderRadius="0.4rem"
          boxShadow="0 5px 12px 0 rgba(38, 57, 69, 0.09)"
          className="returnChart"
        >
          <Loader />
        </Box>
      )}
      {isCompleted && averageRate && (
        <div className="projectionChart">
          {!isMobile && (
            <div className="returnChartCard">
              <>
                <div className="HeaderCard">Rendimiento Proyectado</div>
                <div className="returnChart">
                  <ReturnChart averageRate={averageRate} accountValue={accountValue} />
                  <div className="chartFooter">
                    <p>
                      Este es el rendimiento que tendría tu cuenta en un futuro si siempre
                      re-inviertes los pagos que vas recibiendo, por eso es importante que no tengas
                      dinero en tu cuenta sin prestarlo. Está calculada con la Tasa Promedio
                      Ponderada de tus préstamos actuales.
                    </p>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      )}

      {isCompleted && !averageRate && !isNil(totalLoansMade) && (
        <Card className="investment__infoBanner">
          <div className="investment__infoBanner__container">
            <div className="header">
              <Text
                color="schema.gray.400"
                fontSize="1.2rem"
                lineHeight="1.4rem"
                textAlign="center"
              >
                {t('Dashboard.Summary.EmptyState.projectionChart.totalApprovedTickets', {
                  count: totalLoansMade,
                })}
                <Link fontSize="1.2rem" lineHeight="1.4rem" to={PATHS.investorPortfolioPath()}>
                  {t('Dashboard.Summary.EmptyState.projectionChart.totalApprovedTicketsLinkText', {
                    count: totalLoansMade,
                  })}
                </Link>
              </Text>
            </div>
            <div className="banner__body">
              <NoPerformanceSvg title="" />
              <p>
                Recuerda que cada mes recibirás el capital, los intereses y el IVA correspondientes
                al monto que hayas invertido en cada préstamo.
              </p>
            </div>
          </div>
        </Card>
      )}
    </ProjectionChartContainer>
  );
};

export default ProjectionChart;
