import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { fetchProfileCatalogs } from 'actions/catalogs';
import ChoiceSelector from 'components/ChoiceSelector';
import SelectField from 'components/SelectField';
import { messages } from 'config/constants';
import { t } from 'i18n';
import { Box, Flex } from 'styles';
import { useReduxQuery } from 'utils/redux-query';
import ReferenceCode from '../ReferenceCode';
import StepContainer from '../../RegisterFunnel.styles';
import SaveProfileButton from '../SaveProfileButton';

const LayoutDisabled = () => (
  <Flex
    position="absolute"
    top="0"
    bottom="0"
    left="0"
    right="0"
    bgColor="schema.gray.300"
    opacity="0.3"
    borderRadius="0.5rem"
  />
);

const JobStep = ({
  goNextStep,
  ocupations,
  sectors,
  activities,
  onSubmitText = t('common.continue'),
  className,
  noReferral = false,
  onConfirm = () => {},
  hasCatalogs,
}) => {
  const dispatch = useDispatch();
  const [profile] = useReduxQuery('PROFILE');
  const isDisabledForm = false;
  const ocupationsList = ocupations.map((item) => ({
    value: `${item.id}-ocupations`,
    label: item.name,
  }));
  const sectorsList = sectors.map((item) => ({
    value: `${item.const_id}-sector`,
    label: item.name,
  }));

  const handleFormSubmit = async (data) => {
    const profileData = {
      ocupationId: data.ocupation.replace('-ocupations', ''),
      sectorId: data.sector.replace('-sector', ''),
      activityId: data.activity,
    };
    await goNextStep({ profile: profileData });
    onConfirm();
  };

  const getActivities = (id) => activities
    .filter((item) => item.sector_const_id === parseInt(id, 10))
    .map((item) => ({ value: item.const_id, label: item.name }))
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

  const initialValues = useMemo(
    () => (profile
      ? {
        ocupation: profile?.ocupationId ? `${profile.ocupationId}-ocupations` : null,
        sector: profile.sectorId ? `${profile.sectorId}-sector` : null,
        activity: profile.activityId,
      }
      : null),
    [profile],
  );

  useEffect(() => {
    if (!hasCatalogs) {
      dispatch(fetchProfileCatalogs());
    }
  }, [hasCatalogs]);

  return (
    <StepContainer className={className}>
      <div className="instructions">
        <h3>¿A qué te dedicas?</h3>
        <p>Selecciona la opción que más te identifique.</p>
      </div>
      <Form
        onSubmit={handleFormSubmit}
        subscription={{ value: true }}
        initialValues={initialValues}
        validate={(values) => {
          const { required } = messages;
          const errors = {};
          if (!values.ocupation) errors.ocupation_errors = required;
          if (!values.sector) errors.sector_errors = required;
          if (!values.activity) errors.activity = required;
          return errors;
        }}
        render={({ handleSubmit, submitting }) => (
          <Box w={{ base: '100%', sm: '35rem' }}>
            <form id="profile-form" onSubmit={handleSubmit}>
              <Flex className="form-group" direction="column" position="relative">
                <label>Ocupación</label>
                <ChoiceSelector
                  name="ocupation"
                  options={ocupationsList}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>Sector</label>
                <ChoiceSelector
                  name="sector"
                  options={sectorsList}
                  disabled={isDisabledForm}
                  data-testid="sector-field"
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>Actividad principal</label>
                <Field name="sector" subscription={{ value: true }}>
                  {({ input: { value } }) => (
                    <SelectField
                      name="activity"
                      options={getActivities(value.replace('-sector', ''))}
                      disabled={value === '' || isDisabledForm}
                    />
                  )}
                </Field>
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
            </form>
            {!noReferral && (
              <div className="form-group">
                <ReferenceCode w={{ md: '35rem' }} />
              </div>
            )}
            <div className="buttons">
              <SaveProfileButton title={onSubmitText} submitting={submitting} />
            </div>
          </Box>
        )}
      />
    </StepContainer>
  );
};

const mapStateToProps = ({ catalogs }) => ({
  ocupations: catalogs.ocupations,
  sectors: catalogs.sectors,
  activities: catalogs.activities,
  hasCatalogs: Boolean(catalogs.depositAmounts.length),
});

export default connect(mapStateToProps)(JobStep);
