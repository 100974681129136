import React from 'react';
import styled, { css } from 'styled-components';

const mobileListDiv = ({ ...otherProps }) => <div {...otherProps} />;
const coloredSpan = ({ grade, ...otherProps }) => <span {...otherProps} />;
const progressBarDiv = ({ percentage, ...otherProps }) => <div {...otherProps} />;
const gradeDiv = ({ grade, ...otherProps }) => <div className="li grade" {...otherProps} />;
const listItemDiv = ({
  funded, inCart, currentHighlighted, highlighted, ...otherProps
}) => (
  <div {...otherProps} />
);
const button = ({ highlighted, ...otherProps }) => <button type="button" {...otherProps} />;

const getGradeColor = (grade) => {
  if (grade[0] === 'A') return '#00C7C1';
  if (grade[0] === 'B') return '#079C8F';
  if (grade[0] === 'C') return '#6845D6';
  return 'red';
};

const MobileListContainer = styled(mobileListDiv)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & .list {
    &__header {
      width: 95%;
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
      & .header__item {
        width: 16%;
        display: flex;
        justify-content: center;
        color: #6ea0b0;
        font-size: 1rem;

        &:last-of-type {
          width: 20%;
        }
        & button {
          display: flex;
          column-gap: 1rem;
          width: 100%;
          height: 100%;
          font-size: inherit;
          color: inherit;
          border: none;
          outline: none;
          background-color: transparent;
          &:first-of-type {
            span {
              margin-left: 2px;
            }
          }
        }
      }
    }
    &__body {
      & .loader {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      width: 95%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
      flex-direction: column;
      & .list__item {
        height: 6rem;
        display: flex;
        box-shadow: 0 4px 3px 0 rgba(37, 103, 141, 0.16);
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border-radius: 0.6rem;
        margin-bottom: 1.5rem;
        transition: all 0.4s ease;
        &__content {
          width: 100%;
          height: 100%;
          display: flex;
          position: relative;
          & .content__data {
            text-decoration: none;
            display: flex;
            width: 80%;
            border-right: 2px solid #f2f5f7;
            & .li {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20%;
              font-size: 1.2rem;
              color: #04475c;
              &.id {
                & span {
                  padding: 0.5rem 0.5rem;
                  border-radius: 1rem;
                  background-color: #f2f5f7;
                  font-size: 1rem;
                }
              }
              &.score {
                & span {
                  font-size: 1.2rem;
                  &:last-of-type {
                    margin-left: 0.4rem;
                  }
                }
              }

              &.badge {
                margin-right: 0.5rem;
                font-size: 1rem;
                font-weight: bold;
                line-height: 1.2rem;
                height: 1.8rem;
                width: 2.6rem;
                border-radius: 0.7rem;
                background-color: #f3f3bb;
                z-index: 5;
                box-shadow: 0 2px 4px 0 rgba(55, 77, 91, 0.17);
              }
            }
          }
          & .content__options {
            width: 20%;
            border: none;
            outline: none;
            display: flex;
            border-radius: 0 0.6rem 0 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
            &:hover {
              cursor: pointer;
            }
            & .closeIcon {
              font-size: 3rem;
              font-weight: normal;
              color: white;
              &:after {
                content: '\\d7';
              }
              & + p {
                color: white;
              }
            }
            & svg {
              width: 2.4rem;
              height: 2.4rem;
            }
            & p {
              color: #a2bcc5;
              font-size: 1rem;
            }
          }
        }

        & .progress-bar {
          height: 4px;
          width: 100%;
          background-color: #e7ebef;
          &__bar {
            height: 100%;
            background-color: #05c574;
            border-radius: 6px;
          }
        }
      }
    }
  }
`;

export const GradeSpan = styled(coloredSpan)`
  color: ${(props) => getGradeColor(props.grade)};
`;

export const Grade = styled(gradeDiv)`
  color: ${(props) => getGradeColor(props.grade)};
  .qualification {
    font-weight: bold;
  }
`;

export const ProgressBar = styled(progressBarDiv)`
  width: ${(props) => props.percentage}%;
`;

const getListBackground = (funded, inCart) => {
  if (inCart && funded) return '#F3F9FF';
  if (inCart) return '#F3F9FF';
  if (funded) return '#F9FFFC';
  return 'white';
};

export const ListItem = styled(listItemDiv)`
  background-color: ${(props) => getListBackground(props.funded, props.inCart)};
  opacity: ${(props) => (props.highlighted ? '1' : '.5')};
  ${(props) => props.currentHighlighted === 0
    && css`
      opacity: 1;
    `};
`;

export const Button = styled(button)`
  background-color: ${(props) => (props.highlighted ? '#0076FF' : 'white')};
`;

export const ContainerBadge = styled.div`
  position: absolute;
  left: -0.5rem;
  top: -1rem;
  z-index: 1;
  display: flex;
`;

export default MobileListContainer;
