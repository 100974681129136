import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrency } from 'utils/numberFormatters';
import { ReactComponent as AutoinvestIcon } from 'assets/AutoInvestIcon.svg';
import DotsLoader from 'components/DotsLoader';
import { Text } from 'components/Typography';
import FundedAndCartAmountsContent from './styles';

const FundedAndCartAmounts = ({ lentAmount, amountCart, autoinvestTickets }) => {
  const lendAmountUpdated = useSelector((state) => state.requisitions.updateRequisitionLendAmount);

  if (lentAmount > 0 || amountCart > 0) {
    return (
      <FundedAndCartAmountsContent>
        {lentAmount > 0 && (
          <div className="funded">
            {autoinvestTickets && <AutoinvestIcon title="" />}
            {' '}
            <Text $type="light">
              Ya le prestaste
              {' '}
              {lendAmountUpdated ? (
                <span className="loadingLendAmount">
                  <DotsLoader />
                </span>
              ) : (
                toCurrency(lentAmount)
              )}
            </Text>
          </div>
        )}
        {amountCart > 0 && (
          <div className="amountCart">
            <p>
              Agregado a carrito
              {toCurrency(amountCart)}
            </p>
          </div>
        )}
      </FundedAndCartAmountsContent>
    );
  }
  return null;
};

export default FundedAndCartAmounts;
