import React from 'react';
import get from 'lodash.get';
import { DoubleLineLoader } from 'components/Loaders';
import { STATUS_REQUISITION_NAME_MAP } from 'config/constants';
import BottomLeftBarContainer from './styles';
import FundedAndCartAmounts from '../../../Elements/FundedAndCartAmounts';
import DelayPaymentAndDispersionDates from '../../../Elements/DelayPaymentAndDispersionDates';
import LendingFormRequisitionDetail from '../../../LendingFormRequisitionDetail';

const BottomLeftBarContent = ({
  isLoading,
  lentAmount,
  amountCart,
  isFundedRequisition,
  dataReq,
  onUpdateAmountCart,
  fetchRequisition,
  autoinvestTickets,
}) => {
  const approveAmount = get(dataReq, 'general_details.monto_aprobado');
  const fundedAmount = get(dataReq, 'general_details.monto_fondeado');
  const statusRequisition = get(dataReq, 'status');
  const remainingAmount = approveAmount - fundedAmount;

  const renderContent = () => {
    if (STATUS_REQUISITION_NAME_MAP[statusRequisition]) return null;
    if (isLoading) return <DoubleLineLoader />;
    if (isFundedRequisition) return <DelayPaymentAndDispersionDates dataReq={dataReq} />;
    if (remainingAmount <= 0) return null;
    return (
      <LendingFormRequisitionDetail
        requisitionData={dataReq}
        remaining={remainingAmount}
        onUpdateAmountCart={onUpdateAmountCart}
        lentAmount={parseInt(lentAmount, 10)}
        onUpdateRequistion={fetchRequisition}
      />
    );
  };

  return (
    <>
      <BottomLeftBarContainer className={isFundedRequisition ? 'funded' : ''}>
        {!isLoading && (
          <FundedAndCartAmounts
            lentAmount={lentAmount}
            amountCart={amountCart}
            autoinvestTickets={autoinvestTickets}
          />
        )}
        <div className="content">{renderContent()}</div>
      </BottomLeftBarContainer>
    </>
  );
};

export default BottomLeftBarContent;
