import { OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import isNil from 'utils/isNil';
import { t } from 'i18n';

// Validate otp field
const validateOTPField = (otp) => {
  if (isNil(otp)) return t('common.errors.requiredField');
  if (otp.length < OTP_CODE_LENGHT_DEFAULT) return t('common.errors.OTPCodeInvalid', { length: OTP_CODE_LENGHT_DEFAULT });

  return null;
};

// default validator function for OTPForm
const defaultValidator = (values) => {
  const { otp } = values;
  const errors = {
    otp: validateOTPField(otp),
  };

  return errors;
};

export default defaultValidator;
