import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { Box } from 'ui';

const ContainerDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;
const OptionDiv = ({ active, isMobile, ...otherProps }) => <Box {...otherProps} />;

const sharedOptionStyles = css`
  background-color: white;
  border-radius: 0.4rem;
  color: ${colors.blue};
`;
export const Option = styled(OptionDiv)`
  color: #677d85;
  ${(props) => props.active
    && !props.isMobile
    && css`
      ${sharedOptionStyles}
    `}
  ${(props) => props.isMobile
    && css`
      border-bottom: 1px solid ${colors.gray.g3};
    `}
`;

const SidebarContainer = styled(ContainerDiv)`
  width: ${(props) => (props.isMobile ? '100%' : '25rem')};
  @media (max-width: 700px) {
    width: 100%;
    /* position: absolute; */
    background-color: ${colors.gray.g0};
    top: 58px;
  }
  background-color: ${colors.gray.g1};
  display: flex;
  flex-direction: column;
  align-items: center;

  .sidebar {
    &__options__container {
      display: flex;
      width: ${(props) => (props.isMobile ? '100%' : '80%')};
      flex-direction: column;
      align-items: flex-start;
      font-size: ${(props) => (props.isMobile ? '2rem' : '1.4rem')};
      margin: ${(props) => (props.isMobile ? '0' : '4.5rem 0')};
      & .option {
        margin-bottom: ${(props) => (props.isMobile ? '0' : '1rem')};
        width: 100%;
        height: ${(props) => (props.isMobile ? '7.2rem' : 'auto')};
        transition: all ease 0.4s;
        & a {
          height: 100%;
          width: 100%;
          padding: 0.5rem;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: inherit;
          & svg {
            margin-right: 1rem;
          }
          & .right__arrow {
            margin-left: auto;
            margin-right: 2rem;
            font-size: 4.2rem;
            font-weight: 200;
          }
        }
        &:visited {
          color: inherit;
        }
        &:hover {
          ${(props) => !props.isMobile
            && css`
              ${sharedOptionStyles}
              & svg {
                color: ${colors.schema.blue[500]};

                & g {
                  & g {
                    fill: #0076ff;
                  }
                }
              }
            `}
        }
      }
    }
  }
`;

export default SidebarContainer;
