import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxQuery } from 'utils/redux-query';
import WithSizes from 'react-sizes';
import { selectTab } from 'actions/dashboard';
import Button from 'components/Button';
import { ReactComponent as EmptyStateSvg } from 'assets/EmptyPortfolio.svg';
import PATHS, { investorAddFunds } from 'routes';
import { t } from 'i18n';
import EmptyStateContainer from './styles';
import { FUNDING_ACCOUNT_ITEM, REQUISITION_ITEM } from '../../../SideBar/utils';

const { investorRequisitionsPath } = PATHS;

const EmptyState = (props) => {
  const { width, noResults } = props;
  const dispatch = useDispatch();
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const availableFunds = statements?.saldoDisponible;
  const hasFunds = availableFunds > 0;
  const handleTabSelectRequisitions = () => dispatch(selectTab(REQUISITION_ITEM));
  const handleTabSelectAddFunds = () => dispatch(selectTab(FUNDING_ACCOUNT_ITEM));
  const mobile = width < 1300;

  return (
    <EmptyStateContainer className="empty__portfolio" mobile={mobile}>
      <EmptyStateSvg title="" />
      {noResults ? (
        <div className="empty__portfolio__title">
          Lo sentimos, no se encontraron resultados. Intenta de nuevo.
        </div>
      ) : (
        <>
          <div className="empty__portfolio__title">
            Aquí verás las solicitudes en las que invertiste y su progreso.
          </div>
          <div className="empty__portfolio__description">
            {hasFunds
              ? t('Portfolio.default.emptyState.title2')
              : t('Portfolio.default.emptyState.title')}
          </div>
          <Link to={hasFunds ? investorRequisitionsPath() : investorAddFunds}>
            <Button
              type="button"
              onClick={hasFunds ? handleTabSelectRequisitions : handleTabSelectAddFunds}
            >
              {hasFunds ? 'Ir a prestar' : 'Agregar Fondos'}
            </Button>
          </Link>
        </>
      )}
    </EmptyStateContainer>
  );
};

const mapSizesToProps = ({ width }) => ({
  width,
});

export default WithSizes(mapSizesToProps)(EmptyState);
