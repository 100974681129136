import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { selectTab } from 'actions/dashboard';
import { useReduxQuery } from 'utils/redux-query';
import { SETTINGS_ITEM } from 'views/SideBar/utils';
import { AutoinvestLoader, AutoinvestLoaderMobile } from './Loaders';
import Footer from '../../../FooterDashboard';
import { ShowAutoinvest } from './ShowAutoinvest';
import { EditAutoinvest } from './EditAutoinvest';

const AutoInvest = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [, { isLoading }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const isMobile = useSelector((state) => state.browser.is.mobile);

  useEffect(() => {
    dispatch(selectTab(SETTINGS_ITEM));
  }, []);

  if (isLoading) return isMobile ? <AutoinvestLoaderMobile /> : <AutoinvestLoader />;

  return (
    <>
      <Switch>
        <Route path={`${match.path}/edit`}>
          <EditAutoinvest />
        </Route>
        <Route path={`${match.path}/show`}>
          <ShowAutoinvest />
        </Route>
        <Redirect to={`${match.path}/show`} />
      </Switch>
      <Footer />
    </>
  );
};

export default AutoInvest;
