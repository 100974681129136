import styled from 'styled-components';
import { colors } from 'styles';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${colors.gray.g0};
  & .login__body {
    font-size: 1.5rem;
    width: 30rem;
    padding: 2rem;
    box-shadow: rgba(37, 103, 141, 0.07) 0px 4px 9px 0px;
    background: white;
    border-radius: 0.6rem;
    & h4 {
      margin-bottom: 3rem;
      margin-top: 1rem;
      font-size: 1.6rem;
      text-align: center;
      color: ${colors.gray.g5};
      font-weight: 500;
    }
    & .logo {
      text-align: center;
      img {
        width: 70%;
      }
    }
    & form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & label {
        text-align: left;
        display: block;
        width: 100%;
        color: ${colors.gray.g5};
        margin-bottom: 0.6rem;
        font-weight: 500;
      }
      & input {
        margin-bottom: 1.5rem;
        border: 1px solid ${colors.gray.g3};
        padding: 1.5rem;
        font-size: 1.5rem;
        border-radius: 0.4rem;
        display: block;
        width: 100%;
        &:focus {
          border-color: ${colors.blue};
          outline: none;
        }
      }
      & button {
        display: block;
        width: 100%;
        padding: 1.5rem;
        font-size: 1.5rem;
      }
    }
  }
`;
