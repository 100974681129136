import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

const containerDiv = (props) => <div {...props} />;

const AsideCalendarContainer = styled(containerDiv)`
  .DayPicker {
    &:focus,
    * {
      outline: none;
    }
    &-NavBar {
      .DayPicker-NavButton--prev {
        position: absolute;
        display: inline-block;
        left: 22px;
      }
    }
    &-Caption {
      text-transform: capitalize;
      text-align: center;
      color: ${colors.gray.g6};
      margin-bottom: 1.5em;
      > div {
        font-size: 1.51em;
      }
    }
    &-WeekdaysRow {
      text-transform: uppercase;
      color: rgba(52, 72, 94, 0.54);
    }
    &-Weekday {
      color: ${colors.gray.g4};
      font-weight: 600;
    }
    &-Day {
      padding: 0.8em;
      color: ${colors.gray.g3};
      font-size: 1.3em;
      outline: none;
      font-weight: 600;
      &--today {
        background-color: ${colors.gray.g2};
      }
      &--selected {
        background-color: ${colors.gray.g3} !important;
        font-weight: 700;
        color: white !important;
      }
      &--hasPayments {
        color: ${colors.gray.g5};
        &.DayPicker-Day--selected {
          background-color: ${colors.blue} !important;
        }
        &:hover {
          color: ${colors.blue};
        }
      }
      &--outside {
        color: #e1e4e7;
      }
    }
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #e7ebef;
  }
`;

export default AsideCalendarContainer;
