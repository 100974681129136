import camelify from 'camelcase-keys-recursive';
import { request } from '../../fetchers/yotepresto';

export const SECOND_FACTOR_PATH = '/v2/second_factors';

const fetch = async () => {
  const config = {
    path: SECOND_FACTOR_PATH,
    method: 'get',
  };

  const { data } = await request(config);

  return camelify(data);
};

export default fetch;
