import React from 'react';
import { animated } from 'react-spring';

const AnimatedStep = ({ styles, children, className }) => (
  <animated.div style={styles} className={className}>
    {children}
  </animated.div>
);

export default AnimatedStep;
