import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Heading, Text, Button } from 'components';
import { Flex } from 'styles';
import styles from './SignedCER.style';

const ts = (key) => t(key, { scope: 'components.CER.Confirm' });

export const SignedCER = (props) => {
  const { onGoToDashboard } = props;

  return (
    <Flex {...styles.container}>
      <Heading {...styles.title} data-testid="signedCER-title">
        {ts('sections.AlreadyConfirm.title')}
      </Heading>
      <Text {...styles.description}>{ts('sections.AlreadyConfirm.CTADescription')}</Text>
      <Button onClick={onGoToDashboard} data-testid="goToDashboardButton">
        {ts('sections.AlreadyConfirm.CTAButton')}
      </Button>
    </Flex>
  );
};

SignedCER.propTypes = {
  onGoToDashboard: PropTypes.func.isRequired,
};
