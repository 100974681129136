import React from 'react';
import { Text } from 'components/Typography';
import Link from 'components/Link';
import PATHS from 'routes';
import { Flex } from 'styles';
import { buildStyles } from 'react-circular-progressbar';
import { t } from 'i18n';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { firstLevelWarnsKeys } from 'config/constants';
import {
  LimitsBannerContainer,
  PieChartContainer,
  AnnounceContainer,
  PieChart,
  LevelUpButton,
} from './Limits.style';

const { investorSettingsLimitsPath } = PATHS;

const ranges = [
  {
    color: '#0071FF',
    level: firstLevelWarnsKeys.TRANSACTIONS_ON_THIRTY_PERCENT,
    percentage: 30,
  },
  {
    color: '#FF8E0A',
    level: firstLevelWarnsKeys.TRANSACTIONS_ON_SIXTY_PERCENT,
    percentage: 60,
  },
  {
    color: '#F10638',
    level: firstLevelWarnsKeys.TRANSACTIONS_ON_NINETY_PERCENT,
    percentage: 90,
  },
  {
    color: '#F10638',
    level: firstLevelWarnsKeys.EXCEEDED_TRANSACTIONAL_LIMIT,
    percentage: 100,
  },
];

function TransactionAndAccountValueWarnBody(props) {
  const { warnLevel } = props;

  return (
    <>
      <Text mb="0.6rem" weight="bold">
        {t('common.atention2')}
      </Text>
      <Text mb="0.6rem" align={{ base: 'center', sm: 'left' }}>
        {t('common.yourLimit')}
        {' '}
        <Text as="strong" color="#FF8E0A" weight="bold">
          {t('common.monthlyOperation', {
            transactionPercentage: warnLevel.transactionsPercentage,
          })}
        </Text>
        {t('common.andLimitOf')}
        {' '}
        <Text as="strong" color="#FF8E0A" weight="bold">
          {t('common.accountValueReach', {
            accountValuePercentage: warnLevel.accountPercentage,
          })}
        </Text>
        {' '}
        {t('common.levelUpYourLimit')}
        {' '}
      </Text>
      {warnLevel.transactionsLeftAmount > 0 && (
        <Text color="#FF8E0A" weight="bold">
          {t('settings.limits.leftAmount', {
            amount: toCurrencyWithDecimals(warnLevel.transactionsLeftAmount),
          })}
        </Text>
      )}
    </>
  );
}

function TransactionWarnBody(props) {
  const { warnLevel, range } = props;

  return (
    <>
      <Text mb="0.6rem" weight="bold">
        {t('common.atention2')}
      </Text>
      <Text mb="0.6rem" align={{ base: 'center', sm: 'left' }}>
        {t('settings.limits.overcome1')}
        {' '}
        <Text as="strong" color={range.color}>
          {t('settings.limits.overcomeBy', { percentage: range.percentage })}
        </Text>
        {' '}
        {t('settings.limits.overcome2')}
      </Text>
      {warnLevel.transactionsLeftAmount > 0 ? (
        <Text color={range.color} weight="bold">
          {t('settings.limits.leftAmount', {
            amount: toCurrencyWithDecimals(warnLevel.transactionsLeftAmount),
          })}
        </Text>
      ) : (
        <Text as="strong" color="#FF2135">
          {t('settings.limits.announce.onlyWithdrawal')}
        </Text>
      )}
    </>
  );
}

function LimitsBanner(props) {
  const { warnLevel, displayAccountValue, ...wrapperProps } = props;
  const range = displayAccountValue
    ? ranges[1]
    : ranges.find(({ level }) => level === warnLevel.level) || ranges[0];

  return (
    <LimitsBannerContainer {...wrapperProps}>
      <PieChartContainer>
        <PieChart
          strokeWidth={5}
          value={warnLevel.transactionsPercentage}
          styles={buildStyles({
            pathColor: range.color,
            pathTransitionDuration: 0.5,
            trailColor: '#E6EBF0',
          })}
        >
          <Text color={range.color} size="2.5rem" weight="bold" lineHeight="2.5rem">
            {warnLevel.transactionsPercentage}
            <Text
              as="strong"
              color={range.color}
              size="1.5rem"
              lineHeight="100%"
              verticalAlign="top"
            >
              %
            </Text>
          </Text>
        </PieChart>
        <Text size="1.2rem" whiteSpace="nowrap">
          {t('settings.limits.monthlyOperation2')}
        </Text>
      </PieChartContainer>
      <AnnounceContainer>
        {displayAccountValue ? (
          <TransactionAndAccountValueWarnBody warnLevel={warnLevel} />
        ) : (
          <TransactionWarnBody warnLevel={warnLevel} range={range} />
        )}
      </AnnounceContainer>
      <Flex centered grow="1">
        <LevelUpButton as={Link} to={investorSettingsLimitsPath()}>
          {t('settings.limits.upgrade')}
        </LevelUpButton>
      </Flex>
    </LimitsBannerContainer>
  );
}

export default LimitsBanner;
