import React from 'react';
import { Text } from 'components';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { t } from 'i18n';
import CashFlowContainer from './styles';

const ts = (id, options) => t(id, { scope: 'Dashboard.Cashflow', ...options });

const CashFlowModal = (props) => {
  const { dataModal } = props;

  const {
    valorCuenta: accountValue,
    saldoPrestado: balanceLoaned,
    recursosFondeadosCuenta: fundedResources,
    pagosRecibidos: paymentsReceived,
    recupCarteraVencida: overduePortfolio,
    totalIngresos: totalIncome,
    prestamosRealizados: loansMade,
    comPagadas: commissionsPaid,
    perdidasCarteraVencida: portfolioLosses,
    recursosRetiradosCuenta: withdrawnResources,
    totalEgresos: totalExpenditures,
    ingresosEgresos: incomeExpenditure,
  } = dataModal;

  const options = [
    { key: 0, title: <td><span>{ts('fundedResources')}</span></td>, value: fundedResources },
    { key: 1, title: <td><span>{ts('paymentsReceived')}</span></td>, value: paymentsReceived },
    {
      key: 4,
      title: (
        <td>
          <span>{ts('totalIncome.0')}</span>
          {' '}
          <span>
            <b>{ts('totalIncome.1')}</b>
          </span>
        </td>
      ),
      value: totalIncome,
    },
    { key: 5, title: <td><span>{ts('loansMade')}</span></td>, value: loansMade },
    { key: 6, title: <td><span>{ts('commissionsPaid')}</span></td>, value: commissionsPaid },
    {
      key: 7,
      title: <td><span>{ts('withdrawnResources')}</span></td>,
      value: withdrawnResources,
    },
    {
      key: 9,
      title: (
        <td>
          <span>{ts('totalExpenditures.0')}</span>
          {' '}
          <span>
            <b>{ts('totalExpenditures.1')}</b>
          </span>
        </td>
      ),
      value: totalExpenditures,
    },
    { key: 10, title: <td><span>{ts('incomeExpenditure')}</span></td>, value: incomeExpenditure },
    {
      key: 11,
      title: <td><span>{ts('balanceLoaned')}</span></td>,
      value: balanceLoaned,
    },
    {
      key: 12,
      title: (
        <td>
          <span>{ts('accountValue.0')}</span>
          {' '}
          <span>
            <b>{ts('accountValue.1')}</b>
          </span>
        </td>
      ),
      value: accountValue,
    },
    {
      key: 8,
      title: (
        <td>
          <span>{ts('portfolioLosses')}</span>
          <Text
            fontSize="1.2rem"
            lineHeight="1.6rem"
            color="schema.gray.400"
          >
            {ts('lossesDetails')}
          </Text>
        </td>
      ),
      value: portfolioLosses,
    },
    {
      key: 3,
      title: (
        <td>
          <span>{ts('overduePortfolio')}</span>
          <Text
            fontSize="1.2rem"
            lineHeight="1.6rem"
            color="schema.gray.400"
          >
            {ts('overdueDetails')}
          </Text>
        </td>
      ),
      value: overduePortfolio,
    },
  ];

  const renderTableRows = options.map(({ title, value, keyArray }) => (
    <tr key={keyArray}>
      {title}
      <td>{toCurrencyWithDecimals(value)}</td>
    </tr>
  ));

  return (
    <CashFlowContainer>
      <table>{renderTableRows}</table>
    </CashFlowContainer>
  );
};

export default CashFlowModal;
