export default {
  container: {
    mb: '7rem',
    rowGap: '3rem',
    placeItems: 'center',
  },
  content: {
    gap: '2rem',
  },
  numberFields: {
    gap: '1rem',
    templateColumns: '9rem 9rem 10rem',
  },
  field: {
    color: 'schema.gray.700',
  },
  neighborhoodField: {
    inputProps: { autocomplete: false },
  },
  submit: {
    w: '20.2rem',
  },
};
