import { useForm } from 'react-hook-form';
import { t, tscope } from 'i18n';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { showToastError, showToastInfo } from 'utils/toastHandler';

const ts = tscope('components.AutoInvestWidget.removeModal');

const resolver = (values) => {
  const {
    password,
  } = values;
  const errors = {};
  if (!password) errors.password = { message: ts('form.fields.password.errors.required') };

  return {
    values,
    errors,
  };
};

const createOnRemoveError = ({ setValue, setError }) => async (error) => {
  if (error.response.status === 500) {
    showToastError(ts('alerts.error.remove'));
    return;
  }
  if (error.response.status !== 403) return;
  setValue('password', '');
  await new Promise((resolve) => setTimeout(resolve, 1));
  setError('password', {
    message: t('Forms.fields.errors.invalidPassword'),
  }, { shouldFocus: true });
};

export const useController = () => {
  const {
    control,
    setError,
    setValue,
    handleSubmit,
  } = useForm({
    resolver,
    defaultValues: {
      password: '',
    },
  });
  const [autoInvestConfigs, { isLoading: isAILoading }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [autoInvestConfig] = autoInvestConfigs || [];
  const [
    destroyAIConfig,
    { isLoading: isREmovingAILoading },
  ] = useReduxMutator('DESTROY_AUTO_INV', {
    onError: createOnRemoveError({ setValue, setError }),
    onSuccess: () => {
      showToastInfo(ts('alerts.success.autoInvestRemoved'));
    },
  });
  const isLoading = isAILoading || isREmovingAILoading;

  const onSubmit = handleSubmit(async ({ password }) => {
    try {
      await destroyAIConfig({ password, autoinvestId: autoInvestConfig.id });
      return null;
    } catch (error) {
      if (error?.response?.status === 403) {
        return {
          password: ts('form.fields.password.errors.wrong'),
        };
      }
      showToastError(ts('alerts.error.remove'));
      return null;
    }
  });

  return {
    control,
    onSubmit,
    isLoading,
  };
};
