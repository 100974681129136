import React from 'react';
import { Heading, Text } from 'components';
import { Box, Flex } from 'styles';
import { MyAwesomeComponent } from './MyAwesomeComponent';

export const Playground = () => (
  <Box
    p={{ base: '2rem', sm: '4rem' }}
    textAlign="center"
    bgColor="schema.gray.100"
    minHeight="100vh"
  >
    <Heading>Playground to test visually components which are not implemented yet</Heading>

    <Text>
      Put your component below When your component is ready to go, remember remove it from here
      {' '}
      <br />
      Happy coding!
    </Text>

    <Flex centered mt="5rem">
      {/**
         * Put your code below this comment
         */}
      <MyAwesomeComponent />
      {/* End Screen */}
    </Flex>
  </Box>
);
