import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { Grid } from 'ui';
import LoadingDots from '../../Portfolio/ActiveState/Loading';
import EmptyState from '../EmptyState';
import { TransactionList } from '../components';

const getViewState = (movements) => {
  const { collection } = movements;
  if (!collection) return 'LOADING';
  if (collection.length > 0) return 'COMPLETED';
  return 'NO_RESULTS';
};

const MovementsList = (props) => {
  const {
    isMobile,
    showReferenceMobile,
    lastUpdate,
    onToggleFilter,
    onToggleF,
    onRefresh,
    ...baseProps
  } = props;
  const [movements] = useReduxQuery('MOVEMENTS');
  const state = getViewState(movements);
  const componentByState = {
    LOADING: <LoadingDots />,
    NO_RESULTS: <EmptyState noResults />,
    COMPLETED: <TransactionList marginBottom="10" />,
  };
  const component = componentByState[state];

  return (
    <Grid {...baseProps}>
      {component}
    </Grid>
  );
};

export default MovementsList;
