import React from 'react';
import { Link } from 'react-router-dom';
import PATHS from 'routes';
import { t } from 'i18n';
import statusOptions from 'views/DashboardContent/Portfolio/ActiveState/Filter/utils';
import getStatusColors from 'views/DashboardContent/Portfolio/ActiveState/utils';
import { useReduxQuery } from 'utils/redux-query';
import { Flex } from 'styles';
import { REQUISITION_STATUS } from 'config/constants';
import PortfolioListContainer from './styles';
import Item from './item';
import EmptyState from '../../../EmptyState';

const { investorPortfolioRequisitionPath } = PATHS;

const PortfolioList = () => {
  const [portfolio, { status: portfolioStatus }] = useReduxQuery('PORTFOLIO');

  if (portfolioStatus.pending) return null;

  const detailsList = portfolio.collection.map((item) => ({
    ...item,
    estado: statusOptions.find((itm) => itm.value === item.estado).text,
    color: getStatusColors(item.estado),
  }));

  const IdColumnWidth = portfolio?.collection.reduce(
    (acc, requisition) => (requisition.tags?.length > acc ? requisition.tags.length : acc),
    0,
  );

  const IdColumnBasis = IdColumnWidth > 0
    ? {
      base: 0,
      xs: `${IdColumnWidth * 3.5}rem`,
      sm: `${IdColumnWidth * 3.5}rem`,
      md: 'auto',
    }
    : null;

  if (portfolio.collection.length > 0) {
    return (
      <PortfolioListContainer>
        <div className="portfolio__list">
          <div className="portfolio__list__header">
            <Flex grow="1" flexBasis="7rem" centered className="header__item">
              {t('common.id')}
            </Flex>
            {IdColumnBasis && <Flex grow="1" flexBasis="5rem" centered className="header__item" />}
            <Flex grow="1" flexBasis="10rem" centered className="header__item">
              {t('common.status')}
            </Flex>
            <Flex grow="1" flexBasis="10rem" centered className="header__item" id="lent">
              {t('common.lent')}
            </Flex>
            <Flex grow="1" flexBasis="10rem" centered className="header__item" id="payed">
              {t('common.paid')}
            </Flex>
            <Flex grow="1" flexBasis="10rem" centered className="header__item" id="owes">
              {t('common.owesYou')}
            </Flex>
            <Flex grow="1" flexBasis="10rem" centered className="header__item">
              {t('common.user')}
            </Flex>
            <Flex grow="1" flexBasis="10rem" centered className="header__item">
              {t('common.calif_rate')}
            </Flex>
            <Flex grow="1" flexBasis="3rem" centered className="header__item">
              {t('common.term')}
            </Flex>
          </div>
          <div className="portfolio__list__body">
            {detailsList.map((requisition) => (
              <Item
                as={requisition.estado === REQUISITION_STATUS.CANCEL ? 'div' : Link}
                to={investorPortfolioRequisitionPath(
                  {
                    requisitionId: requisition.solicitud,
                  },
                  {
                    dispositionId: requisition.iDispositionId,
                  },
                )}
                key={requisition['@diffgr:id']}
                requisition={requisition}
                IdColumnBasis={IdColumnBasis}
              />
            ))}
          </div>
        </div>
      </PortfolioListContainer>
    );
  }
  return <EmptyState noResults />;
};

export default PortfolioList;
