import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const ContainerDiv = ({ mobile, ...otherProps }) => <div {...otherProps} />;
const { extraSmallBreakPoint } = breakPoints;

const EmptyStateContainer = styled(ContainerDiv)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #073f51;
  & .empty__state__description__title {
    font-size: 2.8rem;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 3.4rem;
    margin-bottom: 6rem;
    text-align: center;
    @media (max-width: ${extraSmallBreakPoint}px) {
      font-size: 2.3rem;
    }
  }
  & .empty__state__description__description {
    text-align: center;
    width: ${(props) => (props.mobile ? '80%' : '100%')};
    margin-top: 6rem;
    margin-bottom: 6rem;
    font-size: 2.2rem;
    line-height: 33px;
    letter-spacing: 0.63px;
    & span {
      font-weight: 500;
    }
    @media (max-width: ${extraSmallBreakPoint}px) {
      font-size: 1.2rem;
    }
  }
  svg {
    @media (max-width: ${extraSmallBreakPoint}px) {
      width: 250px;
    }
  }
`;

export default EmptyStateContainer;
