import React from 'react';
import {
  Text,
  Alert,
  VStack,
  Box,
  AlertTitle,
} from 'ui';
import { DocumentFileIcon } from 'assets/icons/DocumentFileIcon';
import { FileField } from '../FormFields';
import { useController } from './hooks';
import { styles as s } from './FileUploader.style';

export const FileUploader = ({
  name,
  accept,
  control,
  progress,
  labelText,
  isUploaded,
  isDisabled,
  isFinished,
  description,
  placeholder,
  isUploading,
  onSubmitFile,
  ...props
}) => {
  const {
    onChange,
    fileName,
  } = useController({
    name,
    control,
    onSubmitFile,
  });

  return (
    <VStack {...props}>
      {isFinished && (
        <Alert {...s.alert}>
          <Box>
            <DocumentFileIcon {...s.alertIcon} />
          </Box>
          <AlertTitle {...s.alertTitle}>
            {fileName}
          </AlertTitle>
        </Alert>
      )}
      <FileField
        {...s.fileField}
        progress={progress}
        accept={accept}
        labelText={labelText}
        iconElement={<DocumentFileIcon {...s.fileIcon} />}
        data-testid={name}
        name={name}
        control={control}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={isDisabled || isUploading}
        display={isFinished ? 'none' : 'block'}
      />
      {description && (
        <Text fontSize="md">
          {description}
        </Text>
      )}
    </VStack>
  );
};
