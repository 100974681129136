import React from 'react';
import PropTypes from 'prop-types';
import { t as i18nt, jsx } from 'i18n';

export const I18nText = (props) => {
  const {
    t,
    i18nKey,
    children,
    i18nOptions,
    components,
    TextComponent,
    ...textProps
  } = props;
  if (children || !i18nKey) {
    return <TextComponent {...textProps}>{children}</TextComponent>;
  }
  const textContent = components
    ? jsx(i18nKey, { t, ...i18nOptions, ...components })
    : t(i18nKey, i18nOptions);

  return <TextComponent {...textProps}>{textContent}</TextComponent>;
};

I18nText.defaultProps = {
  t: i18nt,
  i18nKey: '',
  children: null,
  i18nOptions: {},
  components: null,
  TextComponent: 'p',
};

I18nText.propTypes = {
  t: PropTypes.func,
  children: PropTypes.node,
  i18nKey: PropTypes.string,
  components: PropTypes.shape({}),
  i18nOptions: PropTypes.shape({}),
  TextComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
};
