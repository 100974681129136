import { useCallback, useEffect } from 'react';
import { t } from 'i18n';
import { useDispatch } from 'react-redux';
import { WS_EVENT_NAMES } from 'config/constants';
import { showToastError } from 'utils/toastHandler';
import { parseEventMessage } from 'utils/parseWSEventMessages';
import { useReduxSubscription } from 'utils/redux-query';
import { getRequisitionListSilent } from 'actions/requisitions';

export const useBindCreateOrderFailed = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [, { refetch: refetchOrders }] = useReduxSubscription('ORDERS');

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const { ticketId, ticketAmount } = data;

    showToastError(
      t('orders.declinedOrder', {
        ticketID: ticketId,
        amount: ticketAmount,
      }),
      {
        iconName: 'error',
      },
    );
    dispatch(getRequisitionListSilent());
    refetchOrders();
  }, [
    dispatch,
    refetchOrders,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.ORDER_FAILED,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
