import removeNilValues from 'utils/Object/removeNilValues';

const composeValidators = (...validators) => (values) => {
  const errors = validators.reduce(
    (allErrors, validator) => ({
      ...allErrors,
      ...validator(values),
    }),
    {},
  );

  return removeNilValues(errors);
};

export default composeValidators;
