export default {
  container: {
    margin: '6rem 2rem 0 0',
    top: '0px',
    right: '0px',
    position: 'fixed',
    width: 'auto',
    borderRadius: '1rem',
    backgroundColor: 'yellow.100',
    justifyContent: 'space-between',
  },
  spaceBox: {
    flexGrow: 1,
  },
  font: {
    fontSize: '1.4rem',
    minWidth: '16rem',
    maxWidth: '80%',
    color: 'yellow.700',
  },
  icon: {
    color: 'yellow.600',
  },
  button: {
    paddingX: '2rem',
    marginLeft: '1rem',
    alignSelf: 'flex-end',
    color: 'white',
  },
};
