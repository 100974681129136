import {
  fetchTotalApprovedTickets as APIfetchTotalApprovedTickets,
  fetchTotalActiveOrCloseTickets as APIfetchTotalActiveOrCloseTickets,
  addRequisitionToCart,
  getCartItems,
  getCartDetailsRequest,
  updateCartTicket,
  deleteTicket,
  authorizeCart,
  authorizingLendRequisition,
  fetchOrders as fetchOrdersRequest,
} from 'apis/cart';
import { t } from 'i18n';
import {
  showToast,
  showToastInfo,
  showToastWarn,
} from 'utils/toastHandler';
import { createAsyncAction } from 'utils/redux-query';
import { updateLimitAmountByRequisitionId } from 'actions/requisitions/requisitons';
import { updateAccountBalances } from '../dashboard';
import { getRequisitionList, getRequisitionListSilent } from '../requisitions';
import * as types from './types';
import { setSessionSetting } from '../auth/session';

export const getCartDetails = () => async (dispatch) => {
  const totalItemsInCart = await getCartDetailsRequest();
  dispatch({ type: types.UPDATE_CART_ITEMS_TOTAL, payload: totalItemsInCart });
};

export const getCartList = () => async (dispatch) => {
  const response = await getCartItems();
  dispatch({
    type: types.GET_CART_ITEMS,
    payload: response.length > 0 ? response : [],
  });
  dispatch({
    type: types.UPDATE_CART_ITEMS_TOTAL,
    payload: response.length,
  });
};

export const addSingleToCart = (requisition, loading) => async (dispatch, getState) => {
  dispatch(
    updateLimitAmountByRequisitionId(
      requisition.id,
      (currentLimit) => (currentLimit ? currentLimit - requisition.lendValue : 0),
    ),
  );
  try {
    await addRequisitionToCart(requisition);
    const totalItemsInCart = getState().cart.totalItemsInCart || 0;
    /**
     * CALLBACKS to sync other states
     */
    const firstTicketCreated = getState()?.SESSION?.payload?.settings?.firstTicketCreated;
    if (!firstTicketCreated) {
      dispatch(setSessionSetting('firstTicketCreated', false));
    }
    /**
     * CALLBACKS END
     */

    dispatch({ type: types.TOGGLE_LOADING_LENDING, payload: loading });
    dispatch(getRequisitionListSilent());
    dispatch({ type: types.UPDATE_CART_ITEMS_TOTAL, payload: totalItemsInCart + 1 });
  } catch (error) {
    const message = error.response?.data?.error;
    if (message) showToastWarn(message);
    dispatch(
      updateLimitAmountByRequisitionId(
        requisition.id,
        (currentLimit) => (currentLimit ? currentLimit + requisition.lendValue : 0),
      ),
    );
  }
};

export const updateSingleToCart = (updatedTicket) => async (dispatch, getState) => {
  const currentTicket = getState().cart?.cartItems?.find(
    (ticket) => ticket.id === updatedTicket.id,
  );
  dispatch(
    updateLimitAmountByRequisitionId(
      currentTicket.requisition_id,
      (currentLimit) => currentLimit + (currentTicket.amount - updatedTicket.amount),
    ),
  );
  try {
    const updateResponse = await updateCartTicket(updatedTicket);
    if (!updateResponse) return updateResponse;
    const [ticket] = updateResponse.data;

    dispatch({
      type: types.UPDATE_TICKET,
      payload: ticket,
    });
    dispatch(getRequisitionListSilent());

    return updateResponse;
  } catch (error) {
    dispatch(
      updateLimitAmountByRequisitionId(
        currentTicket.requisition_id,
        (currentLimit) => (
          currentLimit ? currentLimit - (currentTicket.amount - updatedTicket.amount) : 0
        ),
      ),
    );
    throw error;
  }
};

export const deleteCartTicket = (ticket) => async (dispatch, getState) => {
  const currentTicket = getState().cart?.cartItems?.find(
    (nextTicket) => nextTicket.id === ticket.id,
  );
  dispatch(
    updateLimitAmountByRequisitionId(
      currentTicket.requisition_id,
      (currentLimit) => currentLimit + (currentTicket.amount - ticket.amount),
    ),
  );

  try {
    const response = await deleteTicket(ticket.id);
    if (response.status !== 200) return response;
    const totalItemsInCart = getState().cart.totalItemsInCart || 0;

    dispatch({
      type: types.DELETE_TICKET,
      payload: ticket,
    });
    dispatch({
      type: types.UPDATE_CART_ITEMS_TOTAL,
      payload: totalItemsInCart > 0 ? totalItemsInCart - 1 : 0,
    });
    dispatch(getRequisitionListSilent());

    return response;
  } catch (error) {
    dispatch(
      updateLimitAmountByRequisitionId(
        currentTicket.requisition_id,
        (currentLimit) => currentLimit - (currentTicket.amount - ticket.amount),
      ),
    );

    throw error;
  }
};

export const fetchTotalApprovedTickets = createAsyncAction(
  'TOTAL_APPROVED_TICKETS',
  () => async () => {
    const totalApprovedTickets = await APIfetchTotalApprovedTickets();

    return totalApprovedTickets;
  },
);

export const fetchTotalActivedOrClosedTickets = createAsyncAction(
  'TOTAL_ACTIVED_OR_CLOSED_TICKETS',
  () => APIfetchTotalActiveOrCloseTickets,
);

export const updateTotalCart = (total) => ({
  type: types.UPDATE_CART_TOTAL,
  payload: total,
});

export const toggleLendingStatus = (loading) => ({
  type: types.TOGGLE_LOADING_LENDING,
  payload: loading,
});

export const fetchOrders = createAsyncAction('ORDERS', () => () => fetchOrdersRequest());

export const authorizingRequisitionLend = (requisition) => async (dispatch) => {
  dispatch(
    updateLimitAmountByRequisitionId(
      requisition.id,
      (currentLimit) => currentLimit - requisition.amount,
    ),
  );
  try {
    await authorizingLendRequisition(requisition);
    dispatch(getRequisitionListSilent());
    dispatch(fetchOrders());
    showToastInfo(t('common.toastMessages.addedOrders', { count: 1 }), {
      iconName: 'orders',
    });
  } catch (error) {
    const { response } = error;
    if (response.status === 422) throw error;
    showToast('error', 'Ocurrió un error al autorizar el préstamo');

    dispatch(
      updateLimitAmountByRequisitionId(
        requisition.id,
        (currentLimit) => currentLimit + requisition.amount,
      ),
    );
  }
};

export const updateStatesAfterAuthorize = () => async (dispatch) => {
  dispatch(getCartList());
  dispatch(getRequisitionList());
  dispatch(updateAccountBalances());
};

export const authorizeTickets = (otp) => async (dispatch, getState) => {
  const authorizeResponse = await authorizeCart(otp);
  const totalTickets = getState().cart.cartItems.length;

  showToastInfo(t('common.toastMessages.addedOrders', { count: totalTickets }), {
    iconName: 'orders',
  });
  dispatch({
    type: types.GET_CART_ITEMS,
    payload: [],
  });
  dispatch(fetchOrders());

  return authorizeResponse;
};
