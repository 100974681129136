import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

async function fetchAll() {
  const path = '/v1/investor/portfolio_auto_investments';
  const method = 'get';
  const { data } = await request({ method, path });

  return data ? camelify(data) : [];
}

export default fetchAll;
