import React, { useCallback, useState } from 'react';
import Route from 'components/Route';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { setFilter } from 'actions/investor/portfolio/portfolio';
import { Flex } from 'styles';
import EmptyState from './EmptyState';
import ActiveState from './ActiveState';
import MobileActiveState from './Mobile/MobileActiveState';
import DisplayDetails from '../DisplayDetails';
import Loading from './Loading';
import PortfolioFilter from './ActiveState/Filter';
import { ALERTS } from '../Alerts/Alerts';

const { investorPortfolioPath, investorPortfolioRequisitionPath } = PATHS;

const PortFolio = () => {
  const dispatch = useDispatch();
  const filtersApplied = useSelector((state) => state.PORTFOLIO_FILTER.payload);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [portfolio, { refetch: fetchPortfolio, status: portfolioStatus }] = useReduxQuery('PORTFOLIO');
  const filterByZellAppId = get(filtersApplied, 'zell_app_id');

  const handleApplyFilters = useCallback(async (filters) => {
    const mergeFilters = { ...filtersApplied, ...filters };
    dispatch(setFilter(mergeFilters));
    await fetchPortfolio(mergeFilters);
  }, [filtersApplied, dispatch]);

  const handleResetFilter = async () => {
    dispatch(setFilter({ page: 1 }));
    await dispatch(fetchPortfolio({ page: 1 }));
  };

  const handleSearchById = async ({ zell_app_id: zellAppId }) => {
    dispatch(setFilter({ page: 1, zell_app_id: zellAppId }));
    await dispatch(fetchPortfolio({ page: 1, zell_app_id: zellAppId }));
  };

  if (portfolioStatus.initial) return null;

  if (
    (portfolioStatus.pending && portfolio === null)
    || portfolio?.operation === 'caching'
    || portfolio?.ready
  ) return <Loading />;

  if (portfolio === null && portfolioStatus.completed) {
    return (
      <Flex direction="column" w="100%">
        {ALERTS.map(({ Component, id }) => <Component key={id} m="2rem" />)}
        <EmptyState />
        ;
      </Flex>
    );
  }

  return (
    <Flex className="portfolio__container" w="100%" overflowY="scroll" h="100%">
      <PortfolioFilter
        isOpen={isOpenFilter}
        onCloseFilter={() => setIsOpenFilter(false)}
        onApplyFilter={async (filter) => {
          await handleApplyFilters({
            ...filter,
            page: 1,
          });
          setIsOpenFilter(false);
        }}
        onResetFilter={async () => {
          await handleResetFilter();
          setIsOpenFilter(false);
        }}
        filters={filtersApplied}
        flexGrow="0"
      />
      <ActiveState
        filters={filtersApplied}
        onApplyFilter={handleApplyFilters}
        onResetFilter={handleResetFilter}
        onSearchByZellId={handleSearchById}
        showSearcher={filterByZellAppId}
        onOpenFilterClick={() => setIsOpenFilter(true)}
        display={{ base: 'none', md: 'flex' }}
        flexGrow="1"
        overflowY={{ lg: 'scroll' }}
      />
      <MobileActiveState
        filters={filtersApplied}
        onApplyFilter={handleApplyFilters}
        onResetFilter={handleResetFilter}
        onSearchByZellId={handleSearchById}
        showSearcher={filterByZellAppId}
        onOpenFilterClick={() => setIsOpenFilter(true)}
        display={{ md: 'none' }}
        flexGrow="1"
      />
      <Route path={investorPortfolioRequisitionPath()} scrollToTopOnEnter={false}>
        {({ match }) => (
          <DisplayDetails
            isShowing={match}
            showVisibilityButton={false}
            backTo={investorPortfolioPath()}
          />
        )}
      </Route>
    </Flex>
  );
};

export default PortFolio;
