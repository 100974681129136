import React, { useState, useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import get from 'lodash.get';
import { t } from 'i18n';
import ytpLogo from 'assets/ytplogo2.svg';
import { showToast } from 'utils/toastHandler';
import { rootPath } from 'routes';
import { UNAUTHORIZED_HTTP_STATUS, SERVER_ERROR_HTTP_STATUS } from 'config/constants';
import { useReduxMutator } from 'utils/redux-query';
import Button from 'components/Button/Button';
import DotsLoader from 'components/DotsLoader';
import { LoginContainer } from './styles';

const LAST_ATTEMPT_ERROR = 'auth.last_attempt';
const LOCKED_ACCOUNT_ERROR = 'auth.not_confirmed';

const isLastAttemptError = (errors) => errors.includes(LAST_ATTEMPT_ERROR);
const isLockedAccountError = (errors) => errors.includes(LOCKED_ACCOUNT_ERROR);

const Login = () => {
  const [createSession, { status: createSessionStatus, error }] = useReduxMutator('CREATE_SESSION');
  const [formValues, setFormValues] = useState({ email: '', password: '' });

  const handleInputChange = (event) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = formValues;
    createSession({ email, password });
  };

  useEffect(() => {
    if (!error) return;
    const status = get(error, 'response.status');
    const errors = get(error, 'response.errors') || [];
    if (status === UNAUTHORIZED_HTTP_STATUS) {
      if (isLockedAccountError(errors)) {
        showToast('error', t('login.errors.lockedAccount'));
        return;
      }
      if (isLastAttemptError(errors)) {
        showToast('error', t('login.errors.lastAttempt'));
        return;
      }
      showToast('error', t('login.errors.invalidPassword'));
      return;
    }
    if (status >= SERVER_ERROR_HTTP_STATUS) {
      showToast('error', t('login.errors.server'));
      return;
    }
    showToast('error', t('login.errors.unknown'));
  }, [error]);

  if (createSessionStatus.completed) return <Redirect to={rootPath} />;

  return (
    <LoginContainer>
      <div className="login__body">
        <div className="logo">
          <img data-testid="yoteprestologo" src={ytpLogo} alt="yotepresto.com" />
        </div>
        <h4>{t('login.title')}</h4>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email-input">{t('login.emailLabel')}</label>
          <input
            id="email-input"
            type="email"
            value={formValues.email}
            name="email"
            onChange={handleInputChange}
          />
          <label htmlFor="password-input">{t('login.passwordLabel')}</label>
          <input
            id="password-input"
            type="password"
            value={formValues.password}
            name="password"
            onChange={handleInputChange}
          />
          <Button type="submit" disabled={createSessionStatus.pending}>
            {createSessionStatus.pending ? <DotsLoader /> : t('login.button')}
          </Button>
        </form>
      </div>
    </LoginContainer>
  );
};

export default withRouter(Login);
