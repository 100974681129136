import React, {
  createRef, useEffect, useState, useContext, useRef,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { isNil } from 'utils';
import { addSingleToCart } from 'actions/cart/cartActions';
import { toggleDetails } from 'actions/dashboard';
import { hideRequisition } from 'actions/requisitions';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { ReactComponent as CartIconSmall } from 'assets/cartIconSmall.svg';
import { ReactComponent as HideIcon } from 'assets/hideIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { showToast } from 'utils/toastHandler';
import { calculateRemainingPercentage, toCurrency } from 'utils/numberFormatters';
import {
  restrictDecimalNumberTextInput,
  validPositiveNegativeDecimalNumbers,
} from 'utils/inputRestrictions';
import Button from 'components/Button';
import Badge from 'components/Badge';
import DotsLoader from 'components/DotsLoader';
import DiversificationDescriptions from 'components/DiversificationDescriptions';
// TODO remove after diversification beta has finish
import { Feature, FeaturesContext } from 'utils/featureFlags';
import Footer from 'views/DashboardContent/FooterDashboard';
import { contentAreaContext } from 'views/DashboardContent/DashboardContent.context';
import { MAX_LENDING_AMOUNT } from 'config/constants';
import PATHS from 'routes';
import debounce from 'utils/Monads/debounce';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { Grid } from 'styles';
import { getListIcon } from '../../icons';
import ListContainer, {
  ProgressPercentage,
  Grade,
  StyledButton,
  ListItem,
  AmountDisplay,
  ContainerBadge,
} from './styles';
import { getSortArrow } from './utils';
import RequisitionSorter from './Utils/RequisitionSorter';

const { investorRequisitionPath } = PATHS;

const EmptyState = () => (
  <div className="empty__message">
    <p>No se encontraron resultados</p>
  </div>
);

const Item = (props) => {
  const {
    setHiddenState,
    hiddenState,
    updateFilter,
    appliedFilter,
    selectedRequisitions,
    extraLarge,
    addTicketToCart,
    isLoadingAmount,
    option,
  } = props;
  const { diversification } = useContext(FeaturesContext);
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [diversificationLimitAmounts] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');
  const limitAmount = diversificationLimitAmounts?.[option?.id?.toString()] || 0;

  const availableFunds = statements?.saldoDisponible;
  const maxLimitAmountMessage = 'Alcanzaste la cantidad máxima a prestar en esta solicitud';
  const dispatch = useDispatch();
  const contentArea = useContext(contentAreaContext);
  const inputRef = useRef(null);

  const [requisitionsWithAmount, setRequisitionsWithAmount] = useState({});
  const [focusedInput, setFocusedInput] = useState(0);
  const [disableButton, setDisableButton] = useState(false);

  const {
    ticket_counts: ticketsCountsRequisition,
    to_be_approved_amount: toBeApprovedAmountRequisition,
  } = option;
  const selected = selectedRequisitions.find((req) => req === option.id);
  const inCart = parseInt(ticketsCountsRequisition, 10) > 0;

  // This way, UI is updated optimistically.
  const isHidden = hiddenState[option.id] ?? option.hidden;

  const quantityInCart = () => {
    if (parseInt(ticketsCountsRequisition, 10) > 0) {
      return parseInt(toBeApprovedAmountRequisition, 10);
    }
    return 0;
  };
  const funded = option.funded_amount > 0;

  const handleHideRequisitions = async () => {
    const { hidden } = option;
    setHiddenState(option.id, !hidden);

    const response = dispatch(hideRequisition({ requisitionId: option.id, hidden: !hidden }));
    if (response) await updateFilter(appliedFilter);

    setHiddenState(option.id, null);

    return response;
  };

  // TODO: this is used for batch selection and may be renabled in the near future
  // const handleRequisitionSelect = () =>
  //   toggleRequisitionSelection(option.id);

  useEffect(() => {
    if (!option) return () => { };

    if (focusedInput !== option.id) return () => { };

    const hideTooltipsAfterScrolling = debounce(() => {
      inputRef.current.blur();
    }, 50);

    if (contentArea.contentAreaRef && inputRef.current) {
      contentArea.contentAreaRef.addEventListener('scroll', hideTooltipsAfterScrolling);

      return () => contentArea.contentAreaRef.removeEventListener('scroll', hideTooltipsAfterScrolling);
    }

    return () => { };
  }, [option, focusedInput]);

  const validateAmountInput = (value) => {
    let errors = {};

    const parsedValue = !value.includes('-') && !value.includes('.') && value !== '' ? parseInt(value, 10) : null;

    const removeValue = (key) => {
      const { [key]: alias, ...rest } = errors;
      return rest;
    };

    const validateDiversificationError = (amount) => {
      const exceededAmount = amount > limitAmount;
      if (!diversification || !exceededAmount) return removeValue('diversification');
      return {
        ...errors,
        diversification:
          availableFunds === 0 || limitAmount !== 0
            ? `¡Mejora la diversificación de tus préstamos! Invierte,
               como máximo ${toCurrency(limitAmount)} en esta solicitud.`
            : maxLimitAmountMessage,
      };
    };

    if (value || value === '') {
      const minAmount = funded ? '100' : '200';

      errors = value.includes('-')
        ? { ...errors, wrongValue: 'Este valor es incorrecto.' }
        : removeValue('wrongValue');

      errors = value.includes('.')
        ? {
          ...errors,
          invalidDecimalValue: 'Este valor parece ser inválido.',
        }
        : removeValue('invalidDecimalValue');

      errors = value === ''
        ? { ...errors, requiredValue: 'Este valor es requerido.' }
        : removeValue('requiredValue');

      if (parsedValue || value.charAt(0) === '0') {
        const amount = parseInt(value, 10);
        const missingAmount = parseInt(option.loan_detail?.missing_amount, 10);

        errors = parsedValue > missingAmount
          ? {
            ...errors,
            remaining: `Este valor debe estar entre ${toCurrency(minAmount)} y ${toCurrency(
              option.loan_detail?.missing_amount,
            )}.`,
          }
          : removeValue('remaining');

        errors = amount > MAX_LENDING_AMOUNT
          ? {
            ...errors,
            maxValue: `Este valor no debe ser mayor que ${toCurrency(MAX_LENDING_AMOUNT)}`,
          }
          : removeValue('maxValue');

        errors = amount < Number(minAmount)
          ? {
            ...errors,
            minValue: `Este valor no debe ser menor que $${minAmount}.`,
          }
          : removeValue('minValue');

        errors = amount % 100 === 0
          ? removeValue('multiple')
          : {
            ...errors,
            multiple: 'Este valor parece ser inválido.',
          };
        errors = validateDiversificationError(amount);
      }

      const errorKeys = Object.keys(errors);
      const firstErrorKey = errorKeys[0] ?? false;

      if (firstErrorKey) {
        const firstError = errors[firstErrorKey];
        const firstErrorObject = { [firstErrorKey]: firstError };

        return firstErrorObject;
      }
      return errors;
    }
    errors = false;
    return errors;
  };

  const handleInputChange = (event, value) => {
    if (event.type !== 'click' && !event.target.value) {
      const { [option.id]: id, ...reqs } = requisitionsWithAmount;
      setRequisitionsWithAmount({ ...reqs });
    } else {
      if (!validPositiveNegativeDecimalNumbers(event.target.value) && value !== '') return false;
      const valueToBeSet = value || event.target.value;
      const errors = valueToBeSet.length >= 3 ? validateAmountInput(valueToBeSet) : {};
      setRequisitionsWithAmount({
        ...requisitionsWithAmount,
        [option.id]: {
          id: option.id,
          value: valueToBeSet.includes('-')
            ? validPositiveNegativeDecimalNumbers(valueToBeSet)
            : restrictDecimalNumberTextInput(valueToBeSet),
          errors,
        },
      });
    }
    return true;
  };

  const closeErrorBox = () => {
    const { [option.id]: id, ...reqs } = requisitionsWithAmount;
    setRequisitionsWithAmount({ ...reqs });
  };

  const getCurrentRequisitionWithAmount = () => Object.keys(requisitionsWithAmount).find(
    (req) => parseInt(req, 10) === parseInt(option.id, 10),
  );

  const handleAddToCart = async () => {
    setDisableButton(true);

    if (Object.keys(requisitionsWithAmount).length > 0) {
      if (Object.keys(requisitionsWithAmount[option.id].errors).length === 0) {
        const currentRequisition = requisitionsWithAmount[getCurrentRequisitionWithAmount()];

        const errors = validateAmountInput(currentRequisition.value);

        setRequisitionsWithAmount({
          ...requisitionsWithAmount,
          [currentRequisition.id]: {
            errors,
          },
        });

        if (!Object.entries(errors).length) {
          const { id, value } = currentRequisition;
          await addTicketToCart({ id, lendValue: value }, false);
          await updateFilter(appliedFilter);
          const { [option.id]: idToRemove, ...reqs } = requisitionsWithAmount;
          setRequisitionsWithAmount({ ...reqs });
        }
      }
    } else {
      const errors = validateAmountInput('');

      setRequisitionsWithAmount({
        ...requisitionsWithAmount,
        [option.id]: {
          id: option.id,
          value: '',
          errors,
        },
      });
    }

    setDisableButton(false);
  };

  const handleQuickOptionAction = async (requisition) => {
    setDisableButton(true);
    const { id, value } = requisition;
    const errors = Object.entries(validateAmountInput(value));
    if (errors.length) {
      setDisableButton(false);
      errors.map(([error, message]) => showToast('warning', message, { autoClose: error.substr(0, 11) !== 'Diversifica' }));
      return null;
    }
    await addTicketToCart({ id, lendValue: value }, false);
    await updateFilter(appliedFilter);
    setDisableButton(false);
    return null;
  };

  const tooltip = createRef();

  const hideTooltip = () => {
    const { current } = tooltip;
    if (current) current.tooltipRef = null;
    return ReactTooltip.hide();
  };

  const handleFocus = () => {
    setFocusedInput(option.id);
  };

  const triggerValidations = () => {
    if (!Object.keys(requisitionsWithAmount).length) return true;

    const reqInfo = requisitionsWithAmount[option.id];

    if (Object.keys(reqInfo.errors).length) return true;

    const currentRequisition = requisitionsWithAmount[getCurrentRequisitionWithAmount()];

    const errors = validateAmountInput(currentRequisition.value);

    if (currentRequisition.value.length >= 3) return true;

    setRequisitionsWithAmount({
      ...requisitionsWithAmount,
      [currentRequisition.id]: {
        errors,
      },
    });

    return true;
  };

  const handleBlur = () => {
    hideTooltip();
    let wasTriggerValidationsExecuted = false;
    wasTriggerValidationsExecuted = triggerValidations();
    setTimeout(() => {
      if (wasTriggerValidationsExecuted) setFocusedInput(0);
    }, 100);
  };

  const errorsInputs = requisitionsWithAmount[getCurrentRequisitionWithAmount()]?.errors;

  const errorsLength = Object.keys(errorsInputs || {}).length;

  const renderErrors = () => {
    if (errorsInputs) {
      const errorsArray = Object.entries(errorsInputs);
      const validErrors = errorsArray.map((error) => error[1]);
      if (validErrors.length === 0) return null;
      return (
        <div className="error__box">
          {validErrors.map((message) => (
            <div className="error__box__content" key={message}>
              <WarningIcon title="" />
              <span>{message}</span>
              <button type="button" onClick={closeErrorBox}>
                X
              </button>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const getDiversificationTooltipMsg = (limit) => (availableFunds === 0 || limit !== 0
    ? `Como monto máximo le puedes prestar ${toCurrency(limit)} pesos`
    : maxLimitAmountMessage);
  return (
    <ListItem
      className="list__item"
      funded={funded}
      inCart={inCart}
      selected={selected}
      key={option.id}
      data-tip
      data-for={option.id}
    >
      {funded && (
        <ReactTooltip effect="solid" id={`${option.id}`}>
          Ya le prestaste:
          {' '}
          {toCurrency(option.funded_amount)}
        </ReactTooltip>
      )}
      <Link
        className="list__item__content link"
        to={investorRequisitionPath({ requisitionId: option.zell_app_id })}
      >
        <div className="li id">
          <span>{option.zell_app_id}</span>
        </div>
        <div className="li grade">
          <Grade grade={option.qualification}>
            <span className="qualification">{option.qualification}</span>
            <span>
              {option.rate}
              %
            </span>
          </Grade>
        </div>
        <div className="li score">{option.credit_report?.score}</div>
        <div className="li destiny">
          {getListIcon(option.destination)}
          <span>{option.destination}</span>
        </div>
        <div className="li time-range">{option.term}</div>
        <div className="li ammount">{toCurrency(option.approved_amount)}</div>
        <div className="li remaining-ammount">{toCurrency(option.loan_detail?.missing_amount)}</div>
        {!extraLarge ? (
          <div className="li remaining-days">
            {option.loan_detail?.days_to_end}
            {' '}
            d
          </div>
        ) : null}
      </Link>
      <div className="li ammount-input">
        <div className={`input-container thirdStep ${errorsLength ? 'error-input' : ''}`}>
          <span>$</span>
          <input
            ref={inputRef}
            type="text"
            data-tip={option.id}
            data-for={`input${option.id}`}
            data-event="focus"
            onChange={(event) => {
              handleInputChange(event);
              hideTooltip();
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={requisitionsWithAmount[option.id] ? requisitionsWithAmount[option.id].value : ''}
          />
          <ReactTooltip
            id={`input${option.id}`}
            ref={tooltip}
            place="bottom"
            effect="solid"
            type="dark"
            className="listing__tooltip"
            clickable
          >
            <p>
              {disableButton || isLoadingAmount
                ? 'Cargando montos sugeridos...'
                : 'Montos sugeridos'}
            </p>
            {!isLoadingAmount && !disableButton && (
              <div className="quick__option__group">
                <Button
                  color="info"
                  type="button"
                  disabled={disableButton}
                  onClick={() => {
                    hideTooltip();
                    handleQuickOptionAction({ id: option.id, value: '200' });
                  }}
                >
                  <CartIconSmall title="" />
                  {' '}
                  $200
                </Button>
                <Button
                  color="info"
                  type="button"
                  disabled={disableButton}
                  onClick={() => {
                    hideTooltip();
                    handleQuickOptionAction({ id: option.id, value: '300' });
                  }}
                >
                  <CartIconSmall title="" />
                  {' '}
                  $300
                </Button>
                <Button
                  color="info"
                  type="button"
                  disabled={disableButton}
                  onClick={() => {
                    hideTooltip();
                    handleQuickOptionAction({ id: option.id, value: '500' });
                  }}
                >
                  <CartIconSmall title="" />
                  {' '}
                  $500
                </Button>
              </div>
            )}
            {!isLoadingAmount && (
              <Feature name="diversification">
                <span className="diversification_tooltip_message">
                  {getDiversificationTooltipMsg(limitAmount)}
                </span>
              </Feature>
            )}
          </ReactTooltip>
        </div>

        <StyledButton hidden={isHidden}>
          {(getCurrentRequisitionWithAmount() || focusedInput === option.id) && (
            <Button
              type="button"
              className="cart__button"
              onClick={handleAddToCart}
              disabled={false}
            >
              {disableButton || isLoadingAmount ? <DotsLoader /> : <CartIcon title="" />}
            </Button>
          )}
          {(funded || inCart) && (
            <AmountDisplay funded={funded} inCart={inCart}>
              {inCart && <CartIconSmall title="" />}
              {inCart && toCurrency(quantityInCart())}
              {!inCart && toCurrency(parseInt(option.funded_amount, 10).toFixed() || '0')}
            </AmountDisplay>
          )}
          <button
            className="eye__button"
            type="button"
            onClick={handleHideRequisitions}
            title={isHidden ? 'Mostrar' : 'Ocultar'}
          >
            <HideIcon title="" />
          </button>
        </StyledButton>
      </div>
      <div className="progressBar">
        <ProgressPercentage
          percentage={calculateRemainingPercentage(
            option.loan_detail?.missing_amount,
            option.approved_amount,
          )}
        />
      </div>
      {renderErrors()}
      <ContainerBadge>
        {option.number_of_credits > 1 && (
          <Badge value={option.number_of_credits} id={option.zell_app_id} hasTooltip />
        )}
        {option.zapopan && <span className="li badge">AZ!</span>}
      </ContainerBadge>
    </ListItem>
  );
};

const Items = (props) => {
  const { requisitions, sortOptions } = props;

  if (requisitions.length === 0) return <EmptyState />;

  const sortedRequisitions = RequisitionSorter(requisitions, sortOptions);

  return sortedRequisitions.map((option) => <Item key={option.id} {...props} option={option} />);
};

const List = (props) => {
  const {
    handleSortChange, sortOptions, loadingRequisitions, extraLarge,
  } = props;
  const [isLoadingAmount, setIsLoadingAmount] = useState(true);
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [, {
    status: requisitionsDiversificationStatus,
    refetch: refetchDiversification,
  }] = useReduxSubscription(
    'REQUISITIONS_DIVERSIFICATION',
  );
  const availableFunds = statements?.saldoDisponible;

  useEffect(() => {
    setIsLoadingAmount(isNil(availableFunds) || requisitionsDiversificationStatus.pending);
  }, [availableFunds, requisitionsDiversificationStatus.pending]);

  useEffect(() => {
    if (!statements) return;

    refetchDiversification();
  }, [statements]);

  return (
    <Grid>
      <ListContainer className="firstStep secondStep">
        <div className="list__header">
          <div className="h checkbox" />
          <div className="h id">ID</div>
          <div className="h grade">
            <button
              type="button"
              onClick={() => {
                handleSortChange('qualification', sortOptions.type, sortOptions.sortOrder);
              }}
            >
              Calif./Tasa
              {' '}
              {getSortArrow('qualification', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          <div className="h score">
            <button type="button" onClick={() => handleSortChange('bc_score')}>
              Score
              {' '}
              {getSortArrow('bc_score', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          <div className="h destiny">
            <button type="button" onClick={() => handleSortChange('destination')}>
              Destino
              {' '}
              {getSortArrow('destination', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          <div className="h time-range">
            <button type="button" onClick={() => handleSortChange('term')}>
              Plazo
              {' '}
              {getSortArrow('term', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          <div className="h ammount">
            <button type="button" onClick={() => handleSortChange('approved_amount')}>
              Monto
              {' '}
              {getSortArrow('approved_amount', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          <div className="h remaining-ammount">
            <button type="button" onClick={() => handleSortChange('missing_amount')}>
              Faltan
              {' '}
              {getSortArrow('missing_amount', sortOptions.type, sortOptions.sortOrder)}
            </button>
          </div>
          {!extraLarge ? (
            <div className="h remaining-days">
              <button type="button" onClick={() => handleSortChange('days_to_end')}>
                Restan
                {' '}
                {getSortArrow('days_to_end', sortOptions.type, sortOptions.sortOrder)}
              </button>
            </div>
          ) : null}

          <div />
        </div>
        <div className="list__body">
          {!loadingRequisitions ? (
            <Items {...props} isLoadingAmount={isLoadingAmount} />
          ) : (
            <div className="loader">
              <DotsLoader black />
            </div>
          )}
        </div>
        {!loadingRequisitions && (
        <Feature name="diversification">
          <div className="list__diversification-text">
            <DiversificationDescriptions />
          </div>
        </Feature>
        )}
      </ListContainer>
      <Footer alignSelf="end" />
    </Grid>
  );
};

export default connect(null, {
  onToggleDetails: toggleDetails,
  addTicketToCart: addSingleToCart,
})(List);
