const { BLOCKED_BY_LIMITS_STATUSES, userStatus } = require('config/constants');

export const DEFAULT_PAGES_FLAGS = {
  Dashboard: true,
  Requisitions: false,
  Portfolio: true,
  Transactions: true,
  ScheduledPayments: false,
  FundingAccount: false,
  Withdraw: false,
  Profile: true,
  Security: true,
  AccountStatementDownloader: true,
  Referrals: true,
  AutoInvest: false,
  Limits: false,
};

export const getPageFlags = ({ session }) => {
  if (!session) return DEFAULT_PAGES_FLAGS;
  const {
    user,
    limits,
    settings,
    levelWarn,
  } = session;
  const isBlockedByLevel = BLOCKED_BY_LIMITS_STATUSES.includes(levelWarn?.level);
  const isPartiallyBlocked = user.status === userStatus.PARTIALLY_BLOCKED;

  return {
    Dashboard: true,
    Requisitions: !(isBlockedByLevel || isPartiallyBlocked),
    Portfolio: true,
    Transactions: true,
    ScheduledPayments: true,
    FundingAccount: !(isBlockedByLevel || isPartiallyBlocked),
    Withdraw: true,
    Profile: true,
    Security: true,
    AccountStatementDownloader: true,
    Referrals: true,
    AutoInvest: isBlockedByLevel || isPartiallyBlocked ? false : settings.availableForAutoinvest,
    Limits: !!limits,
  };
};
