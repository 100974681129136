import { tscope } from 'i18n';
import { toggleInvoice } from 'apis/settings/accountProfile';
import { showToastError, showToastSuccess } from 'utils/toastHandler';
import { useReduxQuery } from 'utils/redux-query';

const t = tscope('Forms.fields.errors');
const invoicingT = tscope('settings.account.activateInvoicingModal');

const validator = () => (values) => {
  const { password } = values;
  const errors = {};

  if (!password) errors.password = t('required');

  return errors;
};

export const useController = ({
  onClose,
}) => {
  const validate = validator();
  const [profile, { status: profileStatus, refetch: refetchProfile }] = useReduxQuery('PROFILE');
  const isInvoicingActive = profile?.invoice;
  const isLoading = profileStatus.pending;
  const titlePreview = isInvoicingActive ? invoicingT('title.enabled') : invoicingT('title.disabled');
  const contentPreview = isInvoicingActive ? invoicingT('content.enabled') : invoicingT('content.disabled');

  const onSubmit = async (values) => {
    const { password } = values;
    try {
      await toggleInvoice({ password });
      onClose();
      refetchProfile();
      if (isInvoicingActive) showToastSuccess(invoicingT('alerts.success.deactivateInvoicing'));
      else showToastSuccess(invoicingT('alerts.success.activateInvoicing'));
      return null;
    } catch (error) {
      showToastError(invoicingT('alerts.error.activateInvoicing'));
      return {
        password: invoicingT('fields.password.error.invalid'),
      };
    }
  };

  const initialValues = {
    password: '',
  };

  return {
    validate,
    onSubmit,
    isLoading,
    titlePreview,
    initialValues,
    contentPreview,
    isInvoicingActive,
  };
};
