import 'core-js/stable';
import 'config/polifills';
import 'regenerator-runtime/runtime';
import 'config/loadConfig';
import React from 'react';
import { UIProvider, Fonts } from 'ui';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { GlobalTimerProvider } from 'utils/useTimer';
import { WebStorageProvider } from 'utils/webStorage';
import GlobalStyles from 'styles/global';
import store from 'store';
import client from 'config/apollo/client';
import { Root } from 'navigators/Root';
import * as UIConfig from 'config/ui';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <WebStorageProvider prefixKey="ytp_">
          <Router>
            <GlobalTimerProvider>
              <GlobalStyles />
              <ToastContainer />
              <UIProvider
                globalStyle={UIConfig.globalStyle}
                themeConfig={UIConfig.themeConfig}
              >
                <Fonts />
                <Root />
              </UIProvider>
            </GlobalTimerProvider>
          </Router>
        </WebStorageProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
);
