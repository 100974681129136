import React, { useState } from 'react';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { ReactComponent as ArrowUp } from 'assets/arrowUpPayHistory.svg';
import { ReactComponent as ArrowDown } from 'assets/arrowDownPayHistory.svg';
import { Button } from 'components';

const HiddenData = (props) => {
  const { showMore, item } = props;

  if (showMore) {
    return (
      <>
        <tr key="pay-history-row-1">
          <td>Capital</td>
          <td colSpan={2} className="dataColSpan">
            {toCurrencyWithDecimals(item.capital)}
          </td>
        </tr>
        ,
        <tr key="pay-history-row-2">
          <td>Int. Ordinario + IVA</td>
          <td colSpan={2} className="dataColSpan">
            {toCurrencyWithDecimals(item.interes_con_iva)}
          </td>
        </tr>
        ,
        <tr key="pay-history-row-3">
          <td>Int. Moratorio + IVA</td>
          <td colSpan={2} className="dataColSpan">
            {toCurrencyWithDecimals(item.interes_moratorio_con_iva)}
          </td>
        </tr>
      </>
    );
  }

  return null;
};

const PayHistoryRow = (props) => {
  const [showMore, setShowMore] = useState(false);
  const { item } = props;
  return (
    <>
      <tr key="pay-history-row-disp-0">
        <td>{item.fecha_aplicacion}</td>
        <td>
          <Button $type="link" onClick={() => setShowMore((prev) => !prev)}>
            <b>{toCurrencyWithDecimals(item.monto_total_pagado)}</b>
            {' '}
            {showMore ? <ArrowUp title="" /> : <ArrowDown title="" />}
          </Button>
        </td>
        <td>
          <b>{toCurrencyWithDecimals(item.saldo_insoluto)}</b>
        </td>
      </tr>
      <HiddenData showMore={showMore} item={item} key="pay-history-row-hide-0" />
    </>
  );
};

export default PayHistoryRow;
