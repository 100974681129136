import React from 'react';
import INEZoom from 'assets/identityVerification/ine-zoom.svg';
import ManualVerificationContainer from './styles';

const ManualVerification = () => (
  <ManualVerificationContainer>
    <div className="info">
      <h2>Necesitamos validar tu información manualmente</h2>
    </div>
    <div className="mati-container white">
      <img src={INEZoom} alt="" />
      <p>
        A más tardar en
        {' '}
        <b>un día hábil</b>
        {' '}
        nos pondremos en contacto contigo.
      </p>
    </div>
    <div className="info-footer">
      <p>¿Tienes dudas? Llama al 33 2332 6306</p>
    </div>
  </ManualVerificationContainer>
);

export default ManualVerification;
