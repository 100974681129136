import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import BulletThread from 'components/BulletThread/BulletThread';
import { Box, Flex, Grid } from 'styles';
import { Text } from 'components/Typography';
import Image from 'components/Image/Image';
import { useReduxQuery } from 'utils/redux-query';
import { maskEmailAddress } from 'utils/maskHandler';
import { FIRST_STEP } from '../config/Constants';
import SecondStepHeader from './SecondStepHeader';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.2FAInstructions.miTokenYtp', ...options });

const MiTokenYtp = (props) => {
  const { setCurrentModal } = props;
  const [session] = useReduxQuery('SESSION');
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');
  const unmaskedEmail = session?.user?.email ?? '';
  const email = maskEmailAddress(unmaskedEmail);

  return (
    <Flex direction="column" bgColor="white" h="auto">
      <SecondStepHeader
        onClick={() => setCurrentModal(FIRST_STEP)}
        imageName="YTP_SQUARE_LOGO"
        title={ts('header')}
        p="3rem"
      />
      <Grid columnGap="2rem" p="3rem" alignItems="start" rowGap="0.3rem">
        <BulletThread gridArea="1/1" gridColumn="1" last={false} status="success" number="1" />
        <Box gridArea="1/2" pb="1rem">
          <Text mb="1.5rem">
            {ts('instructions.0.0')}
            <Text as="b">{ts('instructions.0.1')}</Text>
            {ts('instructions.0.2')}
          </Text>
        </Box>
        <BulletThread gridArea="2/1" last={false} status="success" number="2" />
        <Box gridArea="2/2" pb="1rem">
          <Text mb="1.5rem">
            {ts('instructions.1.0')}
            <Text as="b">{ts('instructions.1.1')}</Text>
            {ts('instructions.1.2')}
          </Text>
          <Flex justifyContent="center">
            <Image objectFit="cover" imageName="YTP_APP" boxSize="227px" />
          </Flex>
        </Box>
        <BulletThread gridArea="3/1" last={false} status="success" number="3" />
        <Box gridArea="3/2" pb="1rem">
          <Text
            t={ts}
            i18nKey={secondFactor ? 'confirmOnFactor.withFactor' : 'confirmOnFactor.noFactor'}
            components={{
              email: (
                <Text as="strong" fontWeight="600">
                  {email}
                </Text>
              ),
              factor: (
                <Text
                  as="strong"
                  fontWeight="600"
                  i18nKey={`common.secondFactor.types.${secondFactor?.otpType}`}
                />
              ),
            }}
          />
        </Box>
        <BulletThread gridArea="4/1" status="success" number="4" />
        <Box gridArea="4/2">
          <Text>{ts('instructions.2')}</Text>
        </Box>
      </Grid>
    </Flex>
  );
};

MiTokenYtp.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
};

export default MiTokenYtp;
