import {
  colors,
  styled,
  textHelper,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

export const CheckboxBox = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${boxHelpers}
`;

export const CheckboxBorder = styled.div`
  display: grid;
  place-items: center;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) => (props.checked ? colors.schema.blue[500] : colors.schema.gray[400])};
  margin-right: 0.8rem;
`;

export const CheckboxInput = styled.input`
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${colors.schema.gray[400]};
  border-radius: 50%;
  display: flex;
  appearance: none;
  outline: none;
  cursor: pointer;

  :checked {
    background-color: ${colors.schema.blue[500]};
  }
`;

export const CheckboxText = styled.label`
  color: ${colors.schema.gray[500]};
  font-size: 1.2rem;
  cursor: pointer;
  ${textHelper}
`;
