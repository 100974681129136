import get from 'lodash.get';
import store from 'store';
import { getApiUrl } from 'config';
import { parseSessionFromHeaders, storeSession } from 'utils/session';
import { UNAUTHORIZED_HTTP_STATUS, MAINTENANCE_HTTP_STATUS } from 'config/constants';
import build from './build';

const apiUrl = getApiUrl();

const updateSession = (session) => {
  storeSession(session);
  store.dispatch({ type: 'SET_CREDENTIALS', payload: session });
};

const handleSuccessfulResponse = (response) => {
  const session = parseSessionFromHeaders(response.headers || {});
  if (session) updateSession(session);

  store.dispatch({ type: 'DELETE_SESSION_STATUS' });

  return response;
};

const handleFailedResponse = (error) => {
  const status = get(error, 'response.status');

  if (status === MAINTENANCE_HTTP_STATUS) {
    store.dispatch({ type: 'SET_IN_MAINTENANCE', payload: true });
  }

  if (status === UNAUTHORIZED_HTTP_STATUS) {
    store.dispatch({
      type: 'SET_SESSION_STATUS',
      payload: { reason: 'invalid_credentials_for_api' },
      meta: { status: 'COMPLETED' },
    });
  }

  throw error;
};

export const { request } = build({
  store,
  apiUrl,
  handleSuccessfulResponse,
  handleFailedResponse,
});
