import styled from 'styled-components';

export const BalanceElementGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
`;

export const BalanceElement = styled.div`
  width: 100%;
  min-height: 6.8rem;
  padding: 1rem 1.5rem;
  background-color: #012e47;
  border-top: 1px solid #134052;

  & .balance__description {
    font-size: 1.44rem;
    color: #93afb9;
    font-weight: 500;
    margin-bottom: 0.2rem;
  }

  & .balance__quantity {
    font-size: 1.8rem;
    font-weight: 400;
    color: white;
  }
`;
