import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { calculatePercentage } from 'utils/numberFormatters';
import { colors } from 'styles';
import ChartItem from './chart';
import StatisticsChartContainer from './styles';

const emptyChartData = {
  totalRows: 0,
  amount: 0,
  percentage: 0,
};

const StatisticsChart = () => {
  const [portfolio, { status: portfolioStatus }] = useReduxQuery('PORTFOLIO');

  if (!portfolioStatus.completed) return null;

  const {
    paymentDetails: { details, paid, totalPayable },
  } = portfolio;

  const AlCorriente = details['Al Corriente'] ?? emptyChartData;
  const Liquidado = details?.Liquidado ?? emptyChartData;
  const EnProcesoDePago = details['En Proceso de Pago'] ?? emptyChartData;
  const Atrasado = details?.Atrasado ?? emptyChartData;
  const Vencido = details?.Vencido ?? emptyChartData;
  const SolicitudCancelada = details['Solicitud Cancelada'] ?? emptyChartData;

  return (
    <StatisticsChartContainer className="statistics">
      <div className="statistics__chart">
        <div className="statistics__chart__body">
          {AlCorriente && (
            <ChartItem
              title="Al Corriente"
              color={colors.requisition.status.uptoDate}
              element={{
                totalRows: AlCorriente.totalRows,
                amount: AlCorriente.amount,
                percentage: calculatePercentage(AlCorriente.amount, totalPayable),
                key: AlCorriente.originalTitle,
              }}
            />
          )}
          {EnProcesoDePago && (
            <ChartItem
              title="En Tránsito"
              color={colors.requisition.status.paidInProcess}
              element={{
                totalRows: EnProcesoDePago.totalRows,
                amount: EnProcesoDePago.amount,
                percentage: calculatePercentage(EnProcesoDePago.amount, totalPayable),
                key: EnProcesoDePago.originalTitle,
              }}
            />
          )}
          {Atrasado && (
            <ChartItem
              title="Atrasado"
              color={colors.requisition.status.duePayment}
              element={{
                totalRows: Atrasado.totalRows,
                amount: Atrasado.amount,
                percentage: calculatePercentage(Atrasado.amount, totalPayable),
                key: Atrasado.originalTitle,
              }}
            />
          )}
          {Vencido && (
            <ChartItem
              title="Vencido"
              color={colors.requisition.status.overduePayment}
              element={{
                totalRows: Vencido.totalRows,
                amount: Vencido.amount,
                percentage: calculatePercentage(Vencido.amount, totalPayable),
                key: Vencido.originalTitle,
              }}
            />
          )}
          {Liquidado && (
            <ChartItem
              title="Pagado"
              color={colors.requisition.status.settledBalance}
              element={{
                totalRows: '--',
                amount: paid,
                percentage: calculatePercentage(paid, totalPayable),
                key: Liquidado.originalTitle,
              }}
            />
          )}
        </div>
      </div>
      <div className="statistics__chart__description">
        <p>
          El monto que observas en este apartado como “Pagado” corresponde al capital que has
          recibido tanto por los préstamos liquidados como por los préstamos activos, los cuales
          pueden estar al corriente, atrasados o vencidos.
        </p>
      </div>
      <div className="statistics__chart__information">
        <div className="information__body">
          <div className="information__body__content">
            <p>{Liquidado?.totalRows || 0}</p>
            <p>Liquidados</p>
          </div>
          <div className="information__body__content">
            <p>{SolicitudCancelada?.totalRows || 0}</p>
            <p>Cancelados</p>
          </div>
        </div>
      </div>
    </StatisticsChartContainer>
  );
};

export default StatisticsChart;
