import camelify from 'camelcase-keys-recursive';
import { request } from './fetchers/yotepresto';

const BANK_ACCOUNTS_PATH = '/v1/investor/bank_accounts';

export const fetchDepositInformation = async () => {
  const path = '/v1/investor/deposit_information';
  const method = 'get';
  const config = { method, path };

  const { data: depositInformation } = await request(config);

  if (depositInformation) {
    return camelify(depositInformation);
  }

  return null;
};

export const getBankAccounts = async () => {
  const path = BANK_ACCOUNTS_PATH;
  const method = 'get';
  const config = { method, path };
  let response;
  try {
    response = await request(config);
    return response.data;
  } catch (err) {
    response = false;
  }
  return response;
};

export const sendWithdrawRequest = (data, otp) => {
  const path = '/v1/investor/withdraws';
  const body = { withdraw: data, otp_code: otp };
  const method = 'post';
  const config = { method, path, body };
  return request(config);
};

export const getBanksWithdraws = async () => {
  const path = '/v1/catalogs/withdraws';
  const method = 'get';
  const config = { method, path };
  const response = await request(config);
  return response.data.catalogs;
};

export const validateCLABE = async (clabe) => {
  const path = '/v1/validate_clabe';
  const method = 'get';
  const params = { clabe };
  const config = { method, path, params };
  const response = await request(config);
  return response;
};

export const addBankAccount = async (accountData, otp) => {
  const { nameAccounts: name, clabeAccounts: clabe, banksAccounts: bankId } = accountData;
  const method = 'post';
  const path = BANK_ACCOUNTS_PATH;
  const body = {
    bank_account: {
      name,
      clabe,
      bank_id: Number(bankId),
    },
    otp_code: otp,
  };
  const config = { method, path, body };

  const response = await request(config);
  return response;
};

export const editBankAccount = async (accountData, otp) => {
  const {
    id, nameAccounts: name, clabeAccounts: clabe, banksAccounts,
  } = accountData;
  const method = 'put';
  const path = BANK_ACCOUNTS_PATH;
  const body = {
    id,
    bank_account: {
      name,
      clabe,
      bank_id: Number(banksAccounts),
    },
    otp_code: otp,
  };
  const config = { method, path, body };
  const response = await request(config);
  return response;
};

export const deleteBankAccount = async (id, otp) => {
  const method = 'delete';
  const path = BANK_ACCOUNTS_PATH;
  const body = { id, otp_code: otp };
  const config = { method, path, body };
  const response = await request(config);
  return response;
};
