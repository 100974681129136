import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { smallBreakPoint, midBreakPoint } = breakPoints;

const DelayPaymentAndDispersionDatesContent = styled.div`
  .title {
    margin-top: 2.2rem;
    margin-bottom: 1rem;
  }
  .delayDays {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 2rem;
      @media (max-width: ${smallBreakPoint}px) {
        margin-bottom: 1rem;
      }
    }
    &--text {
      margin-bottom: 0.7rem;
      @media (max-width: ${midBreakPoint}px) {
        text-align: center;
      }
      span {
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 28px;
      }
      .delayed {
        color: #f6b603;
      }
      .expired {
        color: #ff2135;
      }
    }
  }
  .dispersionDays {
    flex: 1;
    display: flex;
    margin-bottom: 1.7rem;
    &--item {
      flex: 1;
      border-right: 1px solid #e7edf3;
      padding: 2.3rem 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        &:first-child {
          color: #346071;
          font-size: 1.3rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
        }
        color: #668d9d;
      }
      &:nth-child(2) {
        p {
          &:first-child {
            color: #00c66f;
          }
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
`;

export default DelayPaymentAndDispersionDatesContent;
