export const formatAddress = (address) => {
  if (!address) return null;

  return ({
    ...address,
    street: address.street?.toLowerCase(),
    municipality: address.municipality?.toLowerCase(),
    city: address.city?.toLowerCase(),
    neighborhood: address.neighborhood?.toLowerCase(),
    state: {
      name: address.state?.name?.toLowerCase(),
    },
  });
};
