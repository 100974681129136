import sendToast from 'utils/toastHandler';
import { request } from './fetchers/yotepresto';

export const fetchRisk = async () => {
  try {
    const path = '/v2/investor/risks';
    const method = 'get';
    const config = { method, path };
    const response = await request(config);

    return response;
  } catch (e) {
    sendToast('error', 'Ocurrió un error al cargar los riesgos');
    return null;
  }
};

export const saveRisk = async (data) => {
  const path = '/v2/investor/risks';
  const method = 'put';
  const body = {
    risk: data,
  };
  const config = { method, path, body };
  try {
    const response = await request(config);

    return response.data;
  } catch (e) {
    sendToast('error', 'Ocurrió un error al guardar la información');
    return false;
  }
};

export const signRisks = async (data) => {
  const { password } = data;
  const path = '/v2/investor/risks/sign';
  const method = 'post';
  const skipStatusHandle = [403];
  const config = {
    path,
    method,
    skipStatusHandle,
    extraHeaders: { password },
  };
  const response = await request(config);

  return response.status >= 200 || response.status <= 299;
};
