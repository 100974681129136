import React, { useState } from 'react';
import { t } from 'i18n';
import Button from 'components/Button/Button';
import { Flex } from 'styles';
import { Heading, Text } from 'components/Typography';
import { useReduxQuery } from 'utils/redux-query';
import Remove2FAModal from '../../Remove2FAModal/Remove2FAModal';

function SecondFactorModalFooter() {
  const [isOpen, setIsOpen] = useState(false);
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');

  return (
    <Flex
      direction="column"
      p="3rem"
      justifyContent="center"
      alignContent="center"
      rowGap="1rem"
      borderTop="1px solid"
      borderColor="schema.gray.200"
    >
      <Heading as="h4">{t('settings.security.needsSupport')}</Heading>
      <Text>{t('settings.security.howToGet2FA')}</Text>
      <Flex columnGap="3rem">
        <Text
          as="a"
          href={t('common.helpWith2faLink')}
          target="blank"
          size="small"
          color="blue"
          fontSize="1.3rem"
          lineHeight="1.6rem"
          textDecoration="none"
          textAlign="center"
        >
          {t('common.goToHelpCenter')}
        </Text>
        {secondFactorStatus.completed && secondFactor && (
          <Button
            fontSize="1.3rem"
            lineHeight="1.6rem"
            $type="link"
            onClick={() => setIsOpen(true)}
          >
            {t('settings.security.reset2FA')}
          </Button>
        )}
      </Flex>

      <Remove2FAModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Flex>
  );
}

export default SecondFactorModalFooter;
