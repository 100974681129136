import { t, tscope } from 'i18n';
import { useForm } from 'react-hook-form';
import {
  showErrorAlert,
  showSuccessAlert,
} from 'utils/toastHandler';
import { resolver } from '../resolver';
import { useDeleteBankAccountMutation } from './graphql/useDeleteBankAccountMutation';

const ts = tscope('components.RemoveBankAccountForm');

const defaultCallback = () => {};

export const useDeleteBankAccountForm = ({
  clabe,
  onSuccess = defaultCallback,
}) => {
  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
  } = useForm({
    resolver,
  });

  const [deleteBankAccountMutation, { loading: isSubmitting }] = useDeleteBankAccountMutation({
    onErrorCode: {
      INVALID_OTP_ERROR: () => {
        showErrorAlert({ t: ts, key: 'deleteBankAccount' });
        setError('token', { message: t('Forms.fields.errors.invalidOTP_Token') });
      },
    },
    onCompleted: () => {
      showSuccessAlert({ t: ts, key: 'deleteBankAccount' });
      onSuccess();
    },
  });

  const submitHandler = (values) => deleteBankAccountMutation({
    variables: {
      input: {
        clabe,
        otpCode: values.token,
      },
    },
  });

  const onSubmit = handleSubmit(submitHandler);

  return {
    reset,
    control,
    onSubmit,
    setValue,
    isSubmitting,
  };
};
