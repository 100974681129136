import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormLayout from 'views/FormLayout';
import { useReduxQuery } from 'utils/redux-query';
import { USER_STATUSES } from 'config/constants';
import { dashboardRoute } from 'routes/index';
import { AddressForm } from '../../DashboardContent/Settings/MainArea/Limits/UpgradeLimit/AddressForm';

function AddressUpdate() {
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const [, { refetch: fetchPersonalArchive }] = useReduxQuery('PERSONAL_ARCHIVES');
  const userStatus = session?.user?.status;

  const onSubmit = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await fetchPersonalArchive();
  };

  useEffect(() => {
    if (userStatus !== USER_STATUSES.ADDRESS_VALIDATING) {
      history.push(dashboardRoute);
    }
  }, [userStatus, history]);

  return (
    <FormLayout hideImageSection pt="5rem" innerContentProps={{ alignItems: 'flex-start' }}>
      <AddressForm pt="0" mt="0" onSubmit={onSubmit} />
    </FormLayout>
  );
}

export default AddressUpdate;
