import React from 'react';
import { Link } from 'react-router-dom';
import { userSettingsReferral } from 'routes';
import { ReactComponent as ReferralDashboardIcon } from 'assets/referralDashboardIcon.svg';
import Button from 'components/Button';
import ReferralContainer from './styles';

const Referral = ({ referenceCode }) => (
  <ReferralContainer referenceCode={referenceCode}>
    <div className="referral">
      <div className="description">
        <ReferralDashboardIcon title="" />
        <div>
          <p>Haz crecer tu dinero con nuestro programa de referidos</p>

          <Button className="referralButton">
            <Link to={userSettingsReferral}> Ver más </Link>
          </Button>
        </div>
      </div>
      <div className="code">
        <p>Código</p>
        <div className="codeContainer">{referenceCode}</div>
      </div>
    </div>
  </ReferralContainer>
);

export default Referral;
