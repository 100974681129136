import React from 'react';
import { tscope, resolveT } from 'i18n';
import {
  Input,
  ViewIcon,
  FormLabel,
  IconButton,
  InputGroup,
  ViewOffIcon,
  FormControl,
  FormErrorMessage,
  InputRightElement,
} from 'ui';
import { useController } from './hooks';

export const PasswordAuthorizeField = ({
  t,
  name,
  size,
  control,
  isDisabled,
  'data-testid': dataTestId,
  ...props
}) => {
  const ts = resolveT(t, tscope('components.PasswordAuthorizeField'));
  const {
    field,
    fieldState,
    showPassword,
    displayError,
    isPasswordVisible,
  } = useController({ name, control });

  return (
    <FormControl
      size={size}
      isInvalid={fieldState.invalid}
      isDisabled={isDisabled}
      {...props}
    >
      <FormLabel fontSize={size}>{ts('label')}</FormLabel>
      <InputGroup size={size}>
        <Input
          {...field}
          placeholder={ts('placeholder')}
          type={isPasswordVisible ? 'text' : 'password'}
          data-testid={`${dataTestId}.input`}
        />
        <InputRightElement>
          <IconButton
            size="sm"
            variant="ghost"
            onClick={() => showPassword()}
            icon={isPasswordVisible ? <ViewIcon /> : <ViewOffIcon />}
          />
        </InputRightElement>
      </InputGroup>
      {displayError && (
        <FormErrorMessage data-testid={`${dataTestId}.textHelper`}>
          {fieldState.error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
