import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import 'typeface-roboto';
import PATHS from 'routes';
import { FeatureFlagsProvider } from 'providers/FeatureFlagsProvider';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Guest, Dashboard } from './components';
import { useController } from './hooks/useController';

const {
  publicPath,
} = PATHS;

const DashboardComponent = () => (
  <FeatureFlagsProvider>
    <Dashboard />
  </FeatureFlagsProvider>
);

export const Root = () => {
  useController();

  return (
    <Switch>
      <Route path={publicPath()} component={Guest} />
      <ProtectedRoute component={DashboardComponent} />
    </Switch>
  );
};
