import firmaFondo from 'assets/signup/firma_fondo.svg';

export const styles = {
  layout: {
    hideImageSection: true,
    innerContentProps: {
      p: '0',
      bgSize: 'contain',
      bgPosition: 'center',
      bgRepeat: 'no-repeat',
      backgroundImage: `url(${firmaFondo})`,
    },
  },
  content: {
    p: { base: '1rem', sm: '2rem' },
    w: { base: '100%', xl: '130rem' },
  },
  innerContent: {
    rowGap: '2rem',
    columnGap: '2rem',
    direction: { base: 'column', md: 'row' },
  },
  innerContentError: {
    mx: 'auto',
    rowGap: '2rem',
    centered: true,
    maxWidth: '60rem',
    direction: 'column',
  },
  title: {
    pt: '3rem',
    flexGrow: '1',
    flexBasis: '0',
    textAlign: { base: 'center', md: 'end' },
    alignSelf: { base: 'center', md: 'start' },
  },
  titleError: {
    py: '3rem',
    textAlign: 'center',
  },
  generateButtonError: {
    justifySelf: 'end',
  },
  cardContent: {
    flexGrow: '2',
    flexBasis: '0',
    bgColor: 'white',
    direction: 'column',
    borderRadius: '1rem',
    h: {
      base: 'calc(100vh - 24rem)',
      sm: 'calc(100vh - 42.1rem)',
      md: 'calc(100vh - 34rem)',
    },
  },
  form: {
    gap: '2rem',
    borderTop: '1px solid',
    borderColor: 'schema.gray.200',
    justifyContent: 'space-between',
    p: { base: '1rem', md: '3rem' },
    direction: { base: 'column', sm: 'row' },
  },
  printButton: {
    w: { base: '100%', xs: 'auto' },
    mt: { base: '0', xs: '2rem' },
  },
  groupField: {
    gap: '2rem',
    w: { base: '100%', sm: 'auto' },
    direction: { base: 'column', xs: 'row' },
    justifyContent: { base: 'space-between', xs: 'flex-end' },
  },
  passwordField: {
    color: 'schema.gray.500',
    w: { base: '100%', sm: 'auto' },
  },
  submit: {
    $type: 'success',
    mt: { base: '0', xs: '2rem' },
  },
};
