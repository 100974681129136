import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconSuccess } from 'assets/firstLoan.svg';
import { ReactComponent as IconClose } from 'assets/close.svg';
import PATHS from 'routes';
import Button from 'components/Button';
import FirstAuthorizedLoanContent from './styles';

const { investorPortfolioPath } = PATHS;

const FirstAuthorizedLoanModal = ({ onCloseModal }) => {
  const history = useHistory();
  const openPortfolioAction = () => {
    history.push(investorPortfolioPath());
    onCloseModal();
  };
  return (
    <FirstAuthorizedLoanContent>
      <IconClose className="icon--close" onClick={() => onCloseModal()} title="" />
      <IconSuccess className="icon--success" title="" />
      <p className="greenText">¡Felicidades, hiciste tu primer préstamo!</p>
      <p>Sigue invirtiendo y haz crecer tu dinero.</p>
      <div className="greyContainer">
        <p>
          Terminando el período de fondeo, al acreditado se le hace el cobro domiciliado y tu
          recibes al
          {' '}
          <b>siguiente mes</b>
          {' '}
          el capital, los intereses y el IVA correspondiente a tu
          inversión.
        </p>
      </div>
      <p>
        Para revisar el estatus de tus inversionistas, haz clic en el apartado de Mis préstamos.
      </p>
      <div className="buttons">
        <Button handleClick={() => openPortfolioAction()}>Mis préstamos</Button>
        <Button color="success" handleClick={() => onCloseModal()}>
          Continuar
        </Button>
      </div>
    </FirstAuthorizedLoanContent>
  );
};

export default FirstAuthorizedLoanModal;
