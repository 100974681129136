import { setCookie, getCookie, removeCookie } from './cookies';

export const parseSessionFromHeaders = (headers) => {
  if (!headers['access-token']) return null;
  return {
    'access-token': headers['access-token'],
    client: headers.client,
    expiry: headers.expiry,
    'token-type': headers['token-type'],
    uid: headers.uid,
  };
};

export const storeSession = (credentials) => {
  if (!credentials) return;

  const expires = new Date(parseInt(credentials.expiry || 0, 10) * 1000);
  const options = { expires };

  setCookie('ytp_uid', credentials.uid); // API requires this cookie not to be expired.
  setCookie('ytp_expiry', credentials.expiry, options);
  setCookie('ytp_client', credentials.client, options);
  setCookie('ytp_token_type', credentials['token-type'], options);
  setCookie('ytp_access_token', credentials['access-token'], options);
};

export const hasCredentials = () => {
  const accessToken = getCookie('ytp_access_token');
  if (!accessToken) return null;
  return true;
};

export const hasPopulatedCredentials = (credentials) => {
  if (!credentials || Object.values(credentials).length === 0) return false;

  return Object.values(credentials).every(
    (credential) => !!credential,
  );
};

export const loadSession = () => {
  const accessToken = getCookie('ytp_access_token');
  if (!accessToken) return null;

  return {
    uid: getCookie('ytp_uid'),
    expiry: getCookie('ytp_expiry'),
    client: getCookie('ytp_client'),
    'token-type': getCookie('ytp_token_type'),
    'access-token': accessToken,
  };
};

export const removeSession = () => {
  // API requires "ytp_uid" cookie not to be removed.
  removeCookie('ytp_expiry');
  removeCookie('ytp_client');
  removeCookie('ytp_token_type');
  removeCookie('ytp_access_token');
  removeCookie('_YoTePrestoClient_session');
};
