import React from 'react';
import { tscope } from 'i18n';
import {
  Text,
  Grid,
  Flex,
  Button,
} from 'components';
import { styles, List } from './RequireUpdateFiscalData.styles';

const t = tscope('settings.account.invoicingPanel');

export const RequireUpdateFiscalData = ({
  onUploadClick,
}) => (
  <>
    <Text
      t={t}
      i18nKey="updateFiscalData"
      mb="1rem"
      components={{
        startAt: <strong>{t('invoicingV4StartsAt')}</strong>,
      }}
      {...styles.text}
    />
    <Grid as={List} {...styles.list}>
      <li>
        <Text
          t={t}
          i18nKey="fiscalDataList.username"
          {...styles.text}
        />
      </li>
      <li>
        <Text
          t={t}
          i18nKey="fiscalDataList.regimen"
          {...styles.text}
        />
      </li>
      <li>
        <Text
          t={t}
          i18nKey="fiscalDataList.zipCode"
          {...styles.text}
        />
      </li>
    </Grid>
    <Flex {...styles.actionsArea}>
      <Button onClick={onUploadClick}>{t('actions.add')}</Button>
    </Flex>
  </>
);
