import React from 'react';
import {
  css,
  colors,
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';

const {
  largeBreakPoint, smallBreakPoint,
} = breakPoints;

const ContainerDiv = ({
  statusSelected, mobile, isOpen, ...otherProps
}) => <div {...otherProps} />;

const sharedButton = css`
  & .option__button {
    display: flex;
    & input:checked + label {
      background-color: white;
      color: ${colors.blue};
      box-shadow: 0px 6px 10px 0px rgba(1, 46, 71, 0.08);
    }
    & input {
      appearance: none;
      display: none;
    }
    & label {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4rem;
      background-color: ${colors.gray.g2};
      font-size: 1.3rem;
      color: ${colors.gray.g5};
      width: 8rem;
      height: 3rem;
      transition: all 0.4s ease;
      font-weight: 600;
      &:hover {
        transform: translateY(-2px);
        background-color: ${colors.gray.g2};
        color: ${colors.gray.g5};
        cursor: pointer;
        box-shadow: 0 12px 9px -6px rgba(1, 46, 71, 0.15) !important;
      }
      &:active {
        transform: translateY(1px);
      }
    }
  }
`;

const sharedTitle = css`
  margin: 1.5rem 1.5rem;
  font-size: 1.3rem;
  color: #04475c;
`;

const FilterContainer = styled(ContainerDiv).withConfig({ shouldForwardProp })`
  width: ${(props) => (props.mobile ? '100%' : '30rem')};
  background-color: #f2f5f7;

  @media (max-width: ${largeBreakPoint}px) {
    transition: all 0.5s ease;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transform-origin: left;
    bottom: 0;
    width: 27rem;
    z-index: 1000;
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .filter {
    &__header {
      width: 100%;
      margin-top: 1.2rem;
      padding-bottom: 1rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-align: center;
      font-weight: 500;
      color: ${colors.gray.g5};
    }
    &__close {
      padding: 8px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.8rem;
      display: none;
      cursor: pointer;
      & :hover {
        cursor: pointer;
        transform: translateY(-2px);
      }
      svg {
        transition: all 0.3s;
        width: 1.2rem;
      }
      @media (max-width: ${largeBreakPoint}px) {
        display: block;
      }
    }

    &__status {
      font-size: 1.3rem;
      color: #04475c;
      & p {
        margin: 1.5rem 1.5rem;
      }
      & label {
        padding: 0 1rem;
        & select {
          background-color: white;
          border-radius: 0.8rem;
          padding-left: 2rem;
          padding-top: 1rem;
          height: 4.5rem;
        }
        & span {
          margin-left: 1rem;
        }
      }
    }

    &__qualification {
      &__title {
        ${sharedTitle}
      }
      &__group {
        display: flex;
        justify-content: space-evenly;
        ${sharedButton};
        @supports (-ms-ime-align: auto) {
          justify-content: space-around;
        }
      }
    }

    &__origin {
      &__title {
        ${sharedTitle}
      }
      &__group {
        display: flex;
        justify-content: space-evenly;
        ${sharedButton};
        @supports (-ms-ime-align: auto) {
          justify-content: space-around;
          padding: 0 1.5rem;
        }
        .option__button label {
          border-radius: 1.2rem;
          min-width: 110px;
          height: 3.4rem;
        }
      }
    }
    &__action__buttons {
      margin-top: 2rem;
      display: flex;
      justify-content: space-evenly;
      padding: 3rem;
      @media (max-height: ${smallBreakPoint}px) {
        margin-top: 0;
        padding-bottom: 1rem;
      }
      & button {
        flex: 1;
        &:first-of-type {
          border-color: ${colors.blue};
          color: ${colors.blue};
          margin-right: 1rem;
        }
      }
    }
    &__description {
      padding: 2rem;
      @media (max-height: ${smallBreakPoint}px) {
        padding-top: 0;
      }
      &__container {
        padding: 2rem;
        background-color: white;
        &__title {
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
        }
        &__text {
          font-size: 1.4rem;
          color: #677d85;
        }
      }
    }
    &__search__group {
      padding-top: 3rem;
      border-top: 1px solid #ccdce2;
      &--description {
        font-size: 1.5rem;
        color: #04475c;
        font-weight: 600;
        text-align: center;
        margin-bottom: 1rem;
      }
      & .search__group {
        background-color: transparent;
      }
    }
    &__statistics {
      flex: 1;
      display: flex;
      align-items: flex-end;
      margin-bottom: 1rem;
      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #ccdce2;
        padding: 1rem;
        width: 100%;
        & a {
          color: ${colors.blue};
          text-align: center;
          text-decoration: none;
          font-size: 1.2rem;
        }
      }
    }
  }
  hr {
    width: 100%;
    margin-bottom: 1rem;
    border-top: 1px solid ${colors.gray.g2};
  }

  ${boxHelpers}
`;

export default FilterContainer;
