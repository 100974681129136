import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  animated, useTransition, useSpring, useChain, config,
} from 'react-spring';
import SecondFactor from 'modules/SecondFactor';
import { getRequisitionList, updateRequisitionLendAmount } from 'actions/requisitions';
import { cancelAuthorizedTicket } from 'apis/cart';
import { toCurrency } from 'utils/numberFormatters';
import { breakPoints } from 'config/constants';
import { updateAccountBalances } from 'actions/dashboard';

const TicketsRow = ({
  ticket,
  openedOtpState,
  updateCreatedTickets,
  widthLayout,
}) => {
  const [showOTP, setShowOTP] = useState(false);
  const dispatch = useDispatch();
  const transitionRef = useRef();
  const otpCodeContentProps = useTransition(showOTP, null, {
    ref: transitionRef,
    config: config.gentle,
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const springRef = useRef();
  const heightOTPContainer = () => (widthLayout > breakPoints.extraLargeBreakPoint ? 100 : 150);
  const propsSpring = useSpring({
    ref: springRef,
    from: { height: 0 },
    to: { height: showOTP ? heightOTPContainer() : 0 },
    delay: showOTP ? 350 : 0,
  });
  const cancelTicket = async (id, otp) => {
    const response = await cancelAuthorizedTicket(id, otp);
    if (response) {
      dispatch(updateAccountBalances());
      dispatch(getRequisitionList());
      dispatch(updateRequisitionLendAmount(true));
    }
    setShowOTP(!response);
    updateCreatedTickets((state) => (response ? state.filter((itm) => itm[0] !== id) : state));
  };

  useEffect(() => {
    setShowOTP(openedOtpState === ticket.index);
  }, [openedOtpState]);

  useChain(showOTP ? [springRef, transitionRef] : [transitionRef, springRef], [
    0,
    showOTP ? 0.75 : 0,
  ]);

  return (
    <>
      <div className="ticketsTable__row">
        <div className="ticketsTable__cell">{ticket.index + 1}</div>
        <div className="ticketsTable__cell">
          {toCurrency(ticket.item[1])}
        </div>
      </div>
      <animated.div style={propsSpring} className="ticketsTable__row otp_code">
        {otpCodeContentProps.map(
          ({ item, key, props }) => item && (
          <animated.div key={key} style={props} className="ticketsTable otp_code">
            <SecondFactor
              errors
              applyMobileStyles
              shrinkInputs
              buttonText="Eliminar"
              actionToAuthorize={(otp) => cancelTicket(ticket.item[0], otp)}
            />
          </animated.div>
          ),
        )}
      </animated.div>
    </>
  );
};

export default TicketsRow;
