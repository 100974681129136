import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const ListDiv = ({
  extraLarge, isMobile, selected, ...otherProps
}) => <div {...otherProps} />;
const GradeDiv = ({ grade, ...otherProps }) => <div className="li grade" {...otherProps} />;
const ListItemDiv = ({
  extraLarge, selected, funded, inCart, ...otherProps
}) => (
  <div {...otherProps} />
);
const ProgressDiv = ({ percentage, ...otherProps }) => <div {...otherProps} />;
const ButtonDiv = ({ hidden, ...otherProps }) => <div {...otherProps} />;
const AmountDiv = ({ funded, inCart, ...otherProps }) => <div {...otherProps} />;

const getGradeColor = (grade) => {
  if (!grade) return 'red';
  if (grade[0] === 'A') return colors.rates.a;
  if (grade[0] === 'B') return colors.rates.b;
  if (grade[0] === 'C') return colors.rates.c;
  return 'red';
};

const sharedListStyles = css`
  &.checkbox {
    grid-column: 1 / span 1;
  }
  &.id {
    grid-column: 2 / span 1;
  }
  &.grade {
    grid-column: 3 / span 1;
  }
  &.score {
    grid-column: 4 / span 1;
  }
  &.destiny {
    grid-column: 5 / span 1;
  }
  &.time-range {
    grid-column: 6 / span 1;
  }
  &.ammount {
    grid-column: 7 / span 1;
  }
  &.remaining-ammount {
    grid-column: 8 / span 1;
  }
  &.remaining-days {
    grid-column: 9 / span 1;
  }
`;

const sharedGridTemplate = css`
  display: grid;
  grid-template-columns: 4rem repeat(8, 9%) 1fr;
  grid-column: 1 / -1;
`;

const ListContainer = styled(ListDiv)`
  .list {
    &__header {
      ${sharedGridTemplate}
      @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
        grid-template-columns: 4rem repeat(8, 10%) 1fr;
      }

      font-size: 1.1rem;
      color: #6ea0b0;
      margin-bottom: 1rem;
      & .h {
        text-align: center;
        ${sharedListStyles}
        & button {
          display: flex;
          column-gap: 1rem;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: transparent;
          color: inherit;
          font-size: inherit;
          border: none;
          outline: none;
          vertical-align: middle;
          & span {
            font-size: 1.4rem;
          }
          & img {
            vertical-align: middle;
          }
          &:hover {
            color: #4f6f7b;
            cursor: pointer;
          }
        }
      }
    }
    &__body {
      margin-right: 1.3rem;
      margin-left: 4rem;

      &:hover {
        & .li {
          opacity: 0.6;
        }
        & .progressBar {
          opacity: 0.6;
        }
      }
      & .empty__message {
        display: flex;
        justify-content: center;
        font-size: 3rem;
        padding: 5rem 0;
        color: #6ea0b0;
      }
      & .loader {
        display: flex;
        justify-content: center;
        padding: 2rem;
        width: 100%;
      }
      .link {
        text-decoration: none;
      }
      & .list__item {
        &__content {
          display: flex;
          grid-column: 1 / 10;
          justify-content: space-around;
          transition: all 0.3s ease;
          position: relative;
          text-decoration: none;
          color: inherit;
          &:hover {
            cursor: pointer;
          }
        }
        ${sharedGridTemplate}
        grid-template-rows: auto auto;
        margin-bottom: 1.5rem;
        color: #346071;
        transition: all 0.3s ease;
        &:hover {
          & .list__item__content {
            box-shadow: 0px 0px 39px -15px rgba(37, 103, 141, 0.03);
          }
          & .li {
            opacity: 1;
            &:first-of-type {
              box-shadow: none;
            }
            &.destiny {
              & svg {
                transform: translateX(0%);
              }
              & span {
                opacity: 1;
              }
            }
            &.ammount-input {
              box-shadow: 10px 0px 39px -15px rgba(37, 103, 141, 0.03);
              & .eye__button {
                display: flex;
              }
            }
          }
        }
        & .progressBar {
          opacity: 1;
        }
      }
      & .error__box {
        grid-column: 1 / -1;
        box-shadow: 1px 3px 4px 0 rgba(38, 103, 141, 0.16);
        &__content {
          background-color: #fcf9ea;
          color: #c3933d;
          display: flex;
          align-items: center;
          padding: 0.8rem 1.7rem;
          border-bottom: 1px solid #e4ceaa;
          font-size: 1.3rem;
          font-weight: 600;
          &:last-of-type {
            border-bottom: none;
          }
          & svg {
            margin-right: 1rem;
          }
          & button {
            color: #b2c1be;
            font-weight: 600;
            font-size: 1.4rem;
            margin-left: auto;
            background-color: transparent;
            border: none;
            outline: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      & .li {
        height: 5rem;
        font-weight: 500;
        transition: all 0.3s ease;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        &:first-of-type {
          box-shadow: none;
        }
        &.checkBox {
          background-color: #f8fafc;
          & input {
            -webkit-appearance: none;
            outline: none;
            border: 1px solid #ccdce2;
            width: 1.85rem;
            height: 1.85rem;
            border-radius: 5rem;
            transition: all ease 0.4s;
            &:hover {
              cursor: pointer;
              background-color: #0076ff;
            }
            &:checked {
              background-color: #0076ff;
            }
          }
        }
        &.id {
          border-radius: 0.6rem 0 0 0;
          & span {
            background-color: transparent;
            padding: 0.6rem;
            border-radius: 1rem;
            background-color: #f2f5f7;
            font-size: 1rem;
            @media (max-width: ${breakPoints.largeBreakPoint}px) {
              padding: 0.5rem 0.5rem;
              border-radius: 1rem;
              background-color: #f2f5f7;
              font-size: 1rem;
            }
          }
        }
        &.grade {
          font-size: 1.4rem;
          & span:first-of-type {
            margin-right: 1rem;
          }
        }
        &.destiny {
          position: relative;
          & svg {
            transition: all 0.4s ease;
            transform: translateX(200%);
            width: 17px;
          }
          & span {
            transition: all 0.4s ease;
            flex: 1;
            opacity: 0;
            margin-left: 0.5rem;
          }
        }
        &.ammount-input {
          border-radius: 0 0.6rem 0 0;
          justify-content: flex-start;
          & .input-container {
            width: 8rem;
            border: 1px solid ${colors.gray.g3};
            &:focus-within {
              border: 1px solid ${colors.blue};
              span {
                color: #0076ff;
              }
            }
            &.error-input {
              border-color: ${colors.red};
              span, input {
                color: ${colors.red};
              }
            }
          }
          & > div {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            height: 70%;
            background-color: #f8fafc;
            font-size: 1.7rem;
            border-radius: 0.4rem;
            & span {
              margin: 0 0.5rem;
              font-size: 1.3rem;
              color: #93afb9;
            }
            & input {
              background-color: #f8fafc;
              height: 100%;
              width: 90%;
              font-size: 1.5rem;
              border: none;
              outline: none;
              border-radius: 0.4rem;
            }
            & .tooltip__hidden {
              display: none !important;
            }
            & .listing__tooltip {
              font-size: 1rem;
              background-color: ${colors.gray.g0} !important;
              pointer-events: auto;
              display: flex;
              flex-direction: column;
              border-radius: 0.8rem;
              padding-bottom: 1.2rem;
              box-shadow: 0 0px 8px 2px rgba(37, 103, 141, 0.14);
              &::after {
                border-bottom-color: white;
              }
              & p {
                color: ${colors.gray.g6};
                text-align: center;
                margin-bottom: 1rem;
                font-size: 1.2rem;
              }
              & .quick__option__group {
                display: flex;
              }
              & .diversification_tooltip_message {
                margin-top: .7rem;
                color: ${colors.gray.g5};
                font-size: 1rem;
              }
              & div {
                background-color: ${colors.gray.g1};
                & button {
                  display: flex;
                  align-items: center;
                  padding: 0.5rem;
                  margin-right: 1rem;
                  font-weight: 500;
                  color: ${colors.blue};
                  border-color: ${colors.blue};
                  padding: 0.8rem 1rem;
                  border-radius: 0.6rem;
                  background-color: white;
                  & svg {
                    width: 1.8rem;
                    margin-right: 0.4rem;
                  }
                  &:hover {
                    cursor: pointer;
                    background-color: ${colors.blue};
                    color: white;
                    svg * {
                      fill: white;
                    }
                  }
                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
            }
          }
          & div:last-of-type {
            background-color: transparent;
          }
        }
        &.badge {
          margin-right: 0.5rem;
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.2rem;
          height: 1.8rem;
          width: 2.6rem;
          border-radius: 0.7rem;
          background-color: #F3F3BB;
          z-index: 5;
          box-shadow: 0 2px 4px 0 rgba(55,77,91,0.17);
        }
        ${sharedListStyles}
      }
      & .progressBar {
        grid-column: 1 / -1;
        height: 0.3rem;
        background-color: ${colors.gray.g2};
        box-shadow: 1px 3px 4px 0 rgba(38, 103, 141, 0.16);
        border-radius: 0 0 1rem 1rem;
      }
    &__diversification-text{
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
   }
`;

export const StyledButton = styled(ButtonDiv)`
  .eye__button {
    display: ${(props) => (props.hidden ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;

    &:hover {
      background-color: rgba(194, 212, 219, 0.2);
    }

    & g {
      & g {
        ${(props) => props.hidden
          && css`
            fill: ${colors.blue};
          `}
      }
    }
  }
  .cart__button {
    padding: 0.5rem 1.2rem;
    margin-right: 1rem;
    & svg {
      height: 2rem;
      width: 2rem;
    }
    & g {
      & g {
        fill: white;
      }
    }
  }
`;
export const Grade = styled(GradeDiv)`
  color: ${(props) => getGradeColor(props.grade)};
  .qualification {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const ProgressPercentage = styled(ProgressDiv)`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: #05c574;
  border-radius: 6px;
`;

export const ListItem = styled(ListItemDiv)`
  position: relative;
  ${(props) => {
    const { selected, inCart, funded } = props;

    const getColor = () => {
      if (funded && inCart) return '#BFDBF7';
      if (funded) return '#B4EFD6';
      if (selected || inCart) return '#BFDBF7';
      return 'none';
    };

    const getBackgroundColor = () => {
      if (funded && inCart) return '#F3F9FF';
      if (funded) return '#F9FFFC';
      if (inCart) return '#F3F9FF';
      return 'white';
    };

    return css`
      & .li {
        font-size: 1.3rem;
        border-top: 1px solid ${getColor()};
        background-color: ${getBackgroundColor()};
        @media (max-width: ${breakPoints.largeBreakPoint}px) {
          font-size: 1.2rem;
        }
        &:first-of-type {
          border-top: transparent;
        }
        &.checkBox {
          border-top: none;
        }
        &.id {
          border-left: 1px solid ${getColor()};
          border-top: 1px solid ${getColor()};
        }
        &. {
          border-right: 1px solid ${getColor()};
        }
      }
      & .progressBar {
        border-bottom: 1px solid ${getColor()};
      }
    `;
  }};
`;

export const AmountDisplay = styled(AmountDiv)`
  ${(props) => {
    const getColors = () => {
      const { funded, inCart } = props;
      if (funded && inCart) return '#7FB9FA';
      if (funded) return '#3FD395';
      return '#7FB9FA';
    };
    return css`
      background-color: transparent;
      border-radius: 0.4rem;
      padding: 0 1rem;
      font-size: 1.2rem !important;
      color: ${getColors()};
      border: 1px solid ${getColors()};
      height: 100%;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      & svg {
        margin-right: 0.5rem;
        & g {
          & g {
            fill: #7fb9fa;
          }
        }
      }
    `;
  }}
`;

export const ContainerBadge = styled.div`
  position: absolute;
  left: -1.5rem;
  top: -1rem;
  display: flex;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    left: -0.5rem !important;
  }
`;

export default ListContainer;
