import React from 'react';
import withSizes from 'react-sizes';
import { useReduxQuery } from 'utils/redux-query';
import EmptyState from './EmptyState';
import ActiveState from './ActiveState';
import NoLoanState from './NoLoanState';
import { Container } from './style';
import { ALERTS } from '../Alerts/Alerts';

const StateSwitcher = ({ width }) => {
  const [session] = useReduxQuery('SESSION');
  const { user } = session || {};
  const { hadFunds } = user || {};
  const [totalApprovedTickets, { status: approvedTicketsStatus }] = useReduxQuery('TOTAL_APPROVED_TICKETS');

  const [totalActivedOrClosedTickets, { status: activedOrCompletedTicketsStatus }] = useReduxQuery('TOTAL_ACTIVED_OR_CLOSED_TICKETS');

  if (!approvedTicketsStatus.completed
    || !activedOrCompletedTicketsStatus.completed) return null;

  const currentState = () => {
    if (!hadFunds) return <EmptyState />;
    if (totalApprovedTickets === 0 && totalActivedOrClosedTickets === 0) {
      return <NoLoanState />;
    }
    return <ActiveState width={width} />;
  };

  return (
    <Container>
      {ALERTS.map(({ Component, id }) => <Component key={id} m="2rem" />)}
      {currentState()}
    </Container>
  );
};

const mapSizesToProps = ({ width }) => ({ width });

export default withSizes(mapSizesToProps)(StateSwitcher);
