import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { selectTab } from 'actions/dashboard';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { REQUISITION_ITEM } from '../../../../SideBar/utils';
import LinesLoader from '../LinesLoader';
import FundingContainer from './styles';

const { investorRequisitionsPath } = PATHS;

const Funding = (props) => {
  const { isMobile } = props;
  const dispatch = useDispatch();
  const [totalOnFunding, { status: totalOnFundingStatus }] = useReduxQuery(
    'REQUISITIONS_TOTAL_ON_FUNDING',
  );
  const handleTabSelection = () => dispatch(selectTab(REQUISITION_ITEM));

  return (
    <FundingContainer isMobile={isMobile}>
      {totalOnFundingStatus.completed ? (
        <div className="inFunding">
          <div>
            <div className="quantity" data-testid="fundingQuantity">
              {totalOnFunding}
            </div>
            <p>Solicitudes en fondeo</p>
          </div>
          <Link to={investorRequisitionsPath()}>
            <Button color="error" onClick={handleTabSelection}>
              Ir a prestar
            </Button>
          </Link>
        </div>
      ) : (
        <div className="inFunding">
          <div className="fundingLoader" data-testid="fundingLoader">
            <LinesLoader />
          </div>
        </div>
      )}
    </FundingContainer>
  );
};

export default Funding;
