import React, { useState } from 'react';
import { t } from 'i18n';
import Button from 'components/Button';
import { Flex, Box } from 'styles';
import { Text } from 'components/Typography';
import DotsLoader from 'components/DotsLoader';
import SecondFactorModal from 'views/DashboardContent/Activate2FAWizard/SecondFactorModal';
import { useReduxSubscription } from 'utils/redux-query';
import { Center } from 'ui';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.activateCTA', ...options });

const GenericSecondFactorCTA = (props) => {
  const { buttonText, cancelButtonText, ...contentProps } = props;
  const [openModal, setOpenModal] = useState(false);
  const [secondFactor, { status: secondFactorStatus }] = useReduxSubscription('SECOND_FACTOR');

  const InactiveSecondFactor = (
    <Flex p="2rem" direction="column" rowGap="1rem" {...contentProps}>
      <Text size="small">
        {ts('title.0')}
        <Text size="small" as="b">
          {ts('title.1')}
        </Text>
      </Text>
      <Text size="small">{ts('body')}</Text>
      <Button type="button" width="100%" style={{ maxWidth: '320px' }} onClick={() => setOpenModal(true)}>
        {ts('button')}
      </Button>
      <SecondFactorModal isOpen={openModal} onRequestClose={setOpenModal} />
      <Flex justifyContent="flex-end" columnGap="4rem" alignContent="center">
        {cancelButtonText && (
          <Box alignSelf="center">
            <Text fontSize="1.4rem" as="b" color="schema.gray.400">
              {cancelButtonText}
            </Text>
          </Box>
        )}
        {buttonText && (
          <Button fontWeight="700" disabled>
            {buttonText}
          </Button>
        )}
      </Flex>
    </Flex>
  );

  if (secondFactorStatus.pending) {
    return (
      <Center padding={2}>
        <DotsLoader color="schema.gray.200" />
      </Center>
    );
  }

  if (!secondFactor) {
    return <>{InactiveSecondFactor}</>;
  }

  return null;
};

export default GenericSecondFactorCTA;
