/**
 * useRedirect redirects the Investor to certain Views
 * depending on their status
 */

import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { IDENTITY_VERIFICATION_STATUSES, PROOF_OF_ADDRESS_STATUS, USER_STATUSES } from 'config/constants';
import PATHS, {
  profileRoute,
  dashboardRoute,
} from 'routes';
import { getWebAppUrl } from 'config/index';
import { useFeatureFlag } from 'utils/featureFlags';

const findAnyMissingExpedientFlag = (archive, { isProofOfAddressOnReview } = {}) => {
  if (!archive) return null;
  const archives = {
    ...archive,
    address: isProofOfAddressOnReview ? null : archive.address,
    nextRequirement: undefined,
  };
  return Object
    .values(archives)
    .some((arch) => arch === false);
};

export const getIsContractSkipped = ({
  userStatus,
  personalArchives,
  isProofOfAddressNull,
  isProofOfAddressPending,
  isProofOfAddressOnReview,
  isProofOfAddressApproved,
}) => {
  const isExpedientsAndContractRequired = [
    findAnyMissingExpedientFlag(personalArchives, { isProofOfAddressOnReview }),
    userStatus === USER_STATUSES.CONTRACT_UNSIGNED,
  ].every(Boolean);
  return (isExpedientsAndContractRequired
    || (!(isProofOfAddressNull || isProofOfAddressPending) && !isProofOfAddressApproved));
};

const redirectTo = ({ status, isContractSkipped }) => ({
  [USER_STATUSES.PROFILE_STARTED]: profileRoute,
  [USER_STATUSES.PROFILE_COMPLETED]: null,
  [USER_STATUSES.PLD_VALIDATING]: null,
  [USER_STATUSES.ID_VALIDATING]: PATHS.officialIdentificationsPath(),
  [USER_STATUSES.ADDRESS_VALIDATING]: PATHS.regulationProfileUpdatePath({ section: 'address' }),
  [USER_STATUSES.PLD_REVIEWING]: PATHS.investorWaitingForCommitteePath(),
  [USER_STATUSES.CONTRACT_UNSIGNED]: isContractSkipped ? null : PATHS.investorSignContractPath(),
  [USER_STATUSES.AUTHORIZED]: null,
  [USER_STATUSES.BLOCKED]: PATHS.investorBlockedAccountPath(),
  [USER_STATUSES.PARTIALLY_BLOCKED]: null,
  [USER_STATUSES.INACTIVE]: null,
  [USER_STATUSES.CANCELLED]: null,
}[status]);

function useRegulationRedirect() {
  const history = useHistory();
  const credentials = useSelector(({ auth }) => auth.credentials);
  const [session] = useReduxQuery('SESSION');
  const [personalArchives, { refetch: fetchPersonalArchive }] = useReduxQuery('PERSONAL_ARCHIVES');
  const [KYCStatus] = useReduxSubscription('KYC_STATUS');
  const { needsUpdateInformation } = useFeatureFlag();
  const isPersonConfirmed = session?.user?.flags?.personConfirmed === true;
  const userStatus = session?.user?.status;
  const proofOfAddressStatus = session?.user?.proofOfAddress?.status;
  const shouldGoToWebApp = userStatus === USER_STATUSES.PROFILE_STARTED
    && isPersonConfirmed === false;
  const isProofOfAddressOnReview = proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.ON_REVIEW;
  const isProofOfAddressApproved = proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.APPROVED;
  const isProofOfAddressPending = proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.PENDING;
  const isProofOfAddressNull = proofOfAddressStatus === null;
  const isContractSkipped = getIsContractSkipped({
    userStatus,
    personalArchives,
    isProofOfAddressNull,
    isProofOfAddressPending,
    isProofOfAddressOnReview,
    isProofOfAddressApproved,
  });
  const matchOfficialIdentificationRoute = useRouteMatch(PATHS.officialIdentificationsPath());
  const matchAddressRoute = useRouteMatch(
    PATHS.regulationProfileUpdatePath({ section: 'address' }),
  );
  const matchWaitingPLDRoute = useRouteMatch(PATHS.investorWaitingForCommitteePath());
  const matchContractRoute = useRouteMatch(PATHS.investorSignContractPath());
  const matchAnyPLDRoute = [
    matchOfficialIdentificationRoute,
    matchAddressRoute,
    matchWaitingPLDRoute,
    matchContractRoute,
  ].some(Boolean);

  useEffect(() => {
    if (isProofOfAddressApproved) fetchPersonalArchive();
  }, [isProofOfAddressApproved]);

  useEffect(() => {
    if (!credentials?.uid || !userStatus) return;

    if (shouldGoToWebApp) {
      window.location.assign(getWebAppUrl());
      return;
    }

    const allowUserEditExpedients = needsUpdateInformation
      && personalArchives?.personalInformation === false
      && [USER_STATUSES.ID_VALIDATING, USER_STATUSES.ADDRESS_VALIDATING]
        .includes(userStatus);

    if (allowUserEditExpedients) {
      history.push(PATHS.cnbvPath());
      return;
    }

    const isBlockedByMati = [
      userStatus === USER_STATUSES.ID_VALIDATING,
      KYCStatus?.matiStatus === IDENTITY_VERIFICATION_STATUSES.BLOCKED,
    ].every(Boolean);

    if (isBlockedByMati) return;

    const path = redirectTo({ status: userStatus, isContractSkipped });

    if (path) {
      history.push(path);
      return;
    }

    // prevent users stay on PLD views if path is null
    if (matchAnyPLDRoute) {
      history.push(dashboardRoute);
    }
  }, [
    userStatus,
    credentials?.uid,
    shouldGoToWebApp,
    matchAnyPLDRoute,
    isContractSkipped,
    needsUpdateInformation,
    personalArchives?.personalInformation,
  ]);
}

export default useRegulationRedirect;
