import React, { useContext } from 'react';
import { t, tscope } from 'i18n';
import { concatFullName2 } from 'utils/stringFormatters';
import { Grid } from 'styles';
import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button/Button';
import * as Table from 'components/Table/Basic';
import { Heading, Text } from 'components/Typography';
import { useModalDisclosure } from 'hooks';
import { Flex, IconButton, Tooltip } from 'ui';
import { RefreshIcon } from 'components/Icons';
import { Link } from 'react-router-dom';
import { Content } from './Show.styles';
import ProfileConfirmModal from '../Confirm/Confirm';
import { controllerContext } from '../controller/context';

const tOptions = { scope: 'expedientsProfileShow' };
const ts = tscope(tOptions.scope);

const ProfileRecord = (props) => {
  const {
    editPath,
    icon = null,
    headerTitle,
    value = '...',
    templateColumns = '8fr 4fr',
    editText = t('profileTable.editAction', tOptions),
  } = props;

  return (
    <Table.Row
      columnGap="1rem"
      templateColumns={templateColumns}
    >
      <Table.Cell py="1rem" alignSelf="end">
        <Text $size="small" color="gray.g5">
          {headerTitle}
        </Text>
        <Flex columnGap={1} alignItems="center">
          <Text size="1.5rem">{value}</Text>
          {icon}
        </Flex>
      </Table.Cell>
      <Table.Cell py="1rem" alignSelf="end" justifySelf="end">
        <Button
          as={Link}
          to={editPath}
          $type="link"
          size="1.2rem"
          lineHeight="2.4rem"
        >
          {editText}
        </Button>
      </Table.Cell>
    </Table.Row>
  );
};

function Show() {
  const {
    profile,
    generateIdentities,
    isUpdatingIdentities,
  } = useContext(controllerContext);

  const { isOpen, onOpen, onClose } = useModalDisclosure();

  if (!profile) return null;

  const { country, originCountry } = profile;

  // country is the newly selected value, if not present it means the user is
  // not coming from the birth/update page, so we use the originCountry
  const countryName = country || originCountry;

  return (
    <Content>
      <div>
        <Heading mb="1rem">{t('title', tOptions)}</Heading>
        <Heading as="p">{t('subtitle', tOptions)}</Heading>
      </div>
      <Box>
        <Table.Body>
          <ProfileRecord
            headerTitle={t('profileTable.headers.0', tOptions)}
            value={concatFullName2(profile)}
            editPath="username/update"
            editText={t('profileTable.editNameAction', tOptions)}
          />
          {profile?.isNotMexican && (
            <ProfileRecord
              headerTitle={t('profileTable.headers.1', tOptions)}
              value={`${profile.birthDate}, ${countryName}`}
              editPath="birth/update"
            />
          )}
          {!profile?.isNotMexican && (
            <ProfileRecord
              headerTitle={t('profileTable.headers.1', tOptions)}
              value={`${profile.birthDate}, ${profile?.state?.name}`}
              editPath="birth/update"
            />
          )}
          <ProfileRecord
            icon={(
              <Tooltip label={ts('refreshCURPTooltip')} hasArrow placement="top">
                <IconButton
                  size="sm"
                  variant="ghost"
                  colorScheme="green"
                  isLoading={isUpdatingIdentities}
                  onClick={generateIdentities}
                  icon={<RefreshIcon viewBox="0 2 24 24" boxSize={5} fill="green.500" />}
                />
              </Tooltip>
            )}
            headerTitle={t('profileTable.headers.2', tOptions)}
            value={profile.curp}
            editPath="curp/update"
          />
          <ProfileRecord
            headerTitle={t('profileTable.headers.3', tOptions)}
            value={profile.rfc}
            editPath="rfc/update"
          />
        </Table.Body>
      </Box>
      <Alert
        title={t('confirmAlert.title', tOptions)}
        message={(
          <>
            <Grid as="ul" ml="1.4rem" rowGap="0.8rem">
              <li>
                <Text $size="body">{t('confirmAlert.list.0', tOptions)}</Text>
              </li>
              <li>
                <Text $size="body">{t('confirmAlert.list.1', tOptions)}</Text>
              </li>
            </Grid>
            <Text $size="body" mt="1rem">
              {t('confirmAlert.body', tOptions)}
            </Text>
          </>
        )}
        mb="1rem"
      />
      <Button justifySelf="right" onClick={onOpen}>
        {t('confirmAction', tOptions)}
      </Button>
      <Text $size="small">
        {t('footnote', tOptions)}
        {' '}
        <Link size="1.2rem" as="a" href={`mailto:${t('common.pldEmail')}`}>
          {t('common.pldEmail')}
        </Link>
      </Text>
      <ProfileConfirmModal isOpen={isOpen} onClose={onClose} />
    </Content>
  );
}

export default Show;
