import React from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { investorSettings } from 'routes';
import { logOutUser } from 'actions/auth/fetcher';
import { getRequisitionList as getRequisitionListAction } from 'actions/requisitions';
import { publicUrls } from 'config/constants';
import { ReactComponent as CloseIcon } from 'assets/closeSessionIcon.svg';
import ytpLogo from 'assets/yoteprestologo.svg';
import ytpSymbol from 'assets/ytpSymbol.svg';
import { useFeatureFlag } from 'utils/featureFlags';
import BarItem from './BarItem/BarItem';
import {
  BarContainer, BarBrand, BarElement, StyledLink, BrandLink,
} from './styles';
import { getItems, settingsElement } from './utils';
import BalanceWidget from './BalanceWidget';

const SideBar = (props) => {
  const {
    isMid,
    logOut,
    toggleSidebar,
    handleSelect,
    activeTab,
    isMobile,
  } = props;
  const history = useHistory();

  const featureFlags = useFeatureFlag();
  const items = getItems({ featureFlags });

  const handleSettingsClick = () => {
    history.push(investorSettings);
    toggleSidebar();
    handleSelect(settingsElement);
  };

  const renderMobileOptions = () => (
    <>
      <StyledLink as="button" onClick={handleSettingsClick}>
        <BarElement
          data-testid="settings"
          active={activeTab.name === settingsElement.name}
        >
          <span>
            <div className="icon-wrapper">{settingsElement.icon}</div>
            <div className="name-wrapper">{settingsElement.title}</div>
          </span>
        </BarElement>
      </StyledLink>
      <StyledLink
        as={Link}
        to="#"
      >
        <BarElement
          data-testid="salir"
          onClick={logOut}
        >
          <span>
            <div className="icon-wrapper">
              <CloseIcon title="" />
            </div>
            <div className="name-wrapper">Cerrar sesión</div>
          </span>
        </BarElement>
      </StyledLink>
    </>
  );

  const renderBarBrand = () => (
    <BrandLink href={publicUrls.home}>
      <BarBrand>
        {isMid ? (
          <img data-testid="yoteprestologo" src={ytpSymbol} alt="yotepresto.com" width="50" />
        ) : (
          <img data-testid="yoteprestologo" src={ytpLogo} alt="yotepresto.com" />
        )}
      </BarBrand>
    </BrandLink>
  );

  return (
    <BarContainer id="sideBar">
      {!isMobile && renderBarBrand()}
      <div className="elements">
        {items.map((barItem) => (
          <BarItem
            key={barItem.name}
            barItem={barItem}
            onClick={barItem.isDisabled ? null : handleSelect}
            disabled={barItem.isDisabled}
          />
        ))}
        {isMobile && renderMobileOptions()}
      </div>
      {!isMid && <BalanceWidget />}
    </BarContainer>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  activeTab: dashboard.activeTab,
});

export default connect(mapStateToProps, {
  logOut: logOutUser,
  getRequisitionList: getRequisitionListAction,
})(SideBar);
