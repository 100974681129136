import get from 'lodash.get';
import sendToast from 'utils/toastHandler';

import {
  getRequisitionsOnFunding,
  toggleHiddenRequisitions,
  getRequisitionsListAmount,
} from 'apis/requisitions';
import * as types from './types';

export const loadingRequisitonList = (isLoading) => ({
  type: types.LOADING_REQUISITION_LIST,
  payload: {
    isLoading,
  },
});

export const updateRequisitionsList = (data) => {
  const requisitions = get(data, 'requisitions') || [];
  const payload = { ...data, requisitions };
  return { payload, type: types.FUNDING_REQUISITION_LIST };
};

export const getRequisitionList = () => async (dispatch, getStore) => {
  if (getStore().requisitions.loadingRequisitonList) return;
  try {
    dispatch(loadingRequisitonList(true));
    const requisitions = await getRequisitionsOnFunding();
    dispatch(loadingRequisitonList(false));
    dispatch(updateRequisitionsList(requisitions.data));
  } catch (e) {
    dispatch(loadingRequisitonList(false));
    sendToast('error', 'Error al cargar las solicitudes');
  }
};

export const getRequisitionListSilent = () => async (dispatch, getStore) => {
  if (getStore().requisitions.loadingRequisitonList) return;

  try {
    const requisitions = await getRequisitionsOnFunding();
    dispatch(updateRequisitionsList(requisitions.data));
  } catch (e) {
    sendToast('error', 'Error al actualizar las solicitudes');
  }
};

export const getOrFetchRequisitionList = () => async (dispatch, getStore) => {
  if (
    getStore().requisitions.loadingRequisitonList
    || getStore().requisitions.fundingRequisitionList.requisitions?.length > 0
  ) return;

  try {
    dispatch(loadingRequisitonList(true));
    const requisitions = await getRequisitionsOnFunding();
    dispatch(updateRequisitionsList(requisitions.data));
  } catch (e) {
    sendToast('error', 'Error al cargar las solicitudes');
  } finally {
    dispatch(loadingRequisitonList(false));
  }
};

export const hidingRequisition = (requisitionIDs, requisitionHiddenState) => async (dispatch) => {
  try {
    const hideReqResponse = await toggleHiddenRequisitions(requisitionIDs, requisitionHiddenState);
    if (hideReqResponse) {
      const requisitionsResponse = await getRequisitionsOnFunding();
      dispatch(updateRequisitionsList(requisitionsResponse.data));
      return true;
    }
    sendToast('error', 'Error al ocultar la solicitud');
    return false;
  } catch (e) {
    sendToast('error', 'Error al ocultar la solicitud');
    return false;
  }
};

export const updateTicketsInfo = (tickets) => ({
  type: types.MODIFY_TICKET_INFO,
  payload: { tickets },
});

export const updateRequisitionLendAmount = (payload) => (dispatch) => dispatch({
  type: types.UPDATE_REQUISITION_LEND_AMOUNT,
  payload,
});

export const updateRequisitionsCount = () => async (dispatch) => {
  const response = await getRequisitionsListAmount();
  dispatch({ type: types.UPDATE_REQUISITION_COUNT, payload: response });
};

export const hideRequisition = ({ requisitionId, hidden }) => async (dispatch) => {
  try {
    await toggleHiddenRequisitions([requisitionId], hidden);
    dispatch({
      type: types.HIDE_REQUISITION,
      payload: {
        requisitionId,
        hidden,
      },
    });
  } catch (error) {
    sendToast('error', 'Error al ocultar la solicitud');
  }
};
