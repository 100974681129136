export const styles = {
  container: {
    height: '100%',
    direction: 'column',
  },
  content: {
    rowGap: 6,
    paddingX: 5,
    paddingY: 10,
    justifyContent: 'center',
    templateColumns: { base: '100%', md: '35rem' },
  },
  cardBody: {
    padding: 5,
  },
  cardContent: {
    gap: 3,
  },
  title: {
    textAlign: 'center',
  },
  submit: {
    alignSelf: 'flex-end',
  },
  footer: {
    mt: 'auto',
    paddingX: 3,
    flexGrow: '0',
    justifySelf: 'flex-end',
  },
  actions: {
    rowGap: '1rem',
    direction: 'column',
  },
};
