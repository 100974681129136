import debounce from 'lodash.debounce';
import { useMemo, useEffect } from 'react';

const DEBOUNCE_WAIT = 15000;
const DEBOUNCE_OPTIONS = { leading: true, trailing: false, maxWait: DEBOUNCE_WAIT };

export const useActivityListener = ({ onExtendSession }) => {
  const onActivity = useMemo(
    () => debounce(onExtendSession, DEBOUNCE_WAIT, DEBOUNCE_OPTIONS),
    [onExtendSession],
  );

  useEffect(() => {
    document.addEventListener('keypress', onActivity);
    return () => document.removeEventListener('keypress', onActivity);
  }, [onActivity]);

  useEffect(() => {
    document.addEventListener('click', onActivity);
    return () => document.removeEventListener('click', onActivity);
  }, [onActivity]);

  useEffect(() => {
    document.addEventListener('scroll', onActivity);
    return () => document.removeEventListener('scroll', onActivity);
  }, [onActivity]);
};
