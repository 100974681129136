/**
 * Since <StrictMode /> is enable on top of component three
 * react does a double render, for impure function it could be
 * a problem, as a workaround, this helper returns true when
 * <App /> renders components on development.
 *
 */

import { isDevelopment } from 'config';

export const isFirstRender = () => {
  let hasRendered = false;
  return () => {
    if (!isDevelopment() || hasRendered) return true;
    hasRendered = true;
    return false;
  };
};
