import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import getStatusColors from '../../utils';

const Chart = ({ element }) => {
  const {
    title, totalRows, amount, percentage, originalTitle,
  } = element;
  return (
    <div className="chart">
      <p className="chart__title">{title}</p>
      <div className="chart__body">
        <div className="chart__body__content">
          <CircularProgressbarWithChildren
            value={percentage}
            strokeWidth={8}
            styles={buildStyles({
              pathColor: getStatusColors(originalTitle),
              pathTransitionDuration: 0.5,
              trailColor: '#E6EBF0',
            })}
          >
            {title !== 'Pagado' && (
              <p className="chart__body__content__quantity">{totalRows || 0}</p>
            )}
            <p className="chart__body__content__percentage">
              {percentage}
              %
            </p>
          </CircularProgressbarWithChildren>
        </div>
        <p className="chart__body__amount">{toCurrencyWithDecimals(amount || 0)}</p>
      </div>
    </div>
  );
};

export default Chart;
