import React from 'react';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';
import { CER } from 'components/CER/CER';
import { Flex } from 'styles';
import { useDispatch } from 'react-redux';

const ElectronicRiskCertificate = () => {
  const dispatch = useDispatch();

  const confirmSuccessHandler = () => {
    dispatch(fetchPersonalArchives());
  };

  return (
    <Flex justifyContent="center" px={{ base: '1rem', sm: '2rem' }}>
      <CER onConfirmSuccess={confirmSuccessHandler} />
    </Flex>
  );
};

export default ElectronicRiskCertificate;
