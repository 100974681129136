import React, { createContext, useMemo } from 'react';

export const CERContext = createContext({});

export const Provider = (props) => {
  const { onConfirmSuccess, children } = props;
  const contextValue = useMemo(() => ({ onConfirmSuccess }),
    [onConfirmSuccess]);

  return (
    <CERContext.Provider value={contextValue}>
      {children}
    </CERContext.Provider>
  );
};
