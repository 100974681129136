import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { setStatus } from 'actions/identityVerification';
import { restartVerification, fetchVerificationStatus } from 'apis/identityVerification';
import IdErrorSvg from 'assets/identityVerification/id-error.svg';
import ErrorContainer from './styles';

const Error = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClickOnRestartVerification = async () => {
    try {
      setLoading(true);
      await restartVerification();
      const matiStatusResponse = await fetchVerificationStatus();
      dispatch(setStatus(matiStatusResponse.data.mati_status));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ErrorContainer>
      <div className="info">
        <h2>No pudimos validar tu identificación.</h2>
      </div>
      <div className="mati-container">
        <img src={IdErrorSvg} alt="" />
        <Button onClick={handleClickOnRestartVerification} disabled={loading}>
          {loading ? <DotsLoader /> : 'Intentar de nuevo'}
        </Button>
      </div>
    </ErrorContainer>
  );
};

export default Error;
