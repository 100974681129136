import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { IDENTITY_VERIFICATION_STATUSES } from 'config/constants';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { DotsLoaderBox } from 'components/Loaders';
import IdentityVerificationContainer from './styles';
import WaitingForResponse from './WaitingForResponse';
import StartProcess from './StartProcess';
import UpdateInformation from './UpdateInformation';
import Success from './Success';
import ManualVerification from './ManualVerification';
import Error from './Error';
import TechnicalProblems from './TechnicalProblems/TechnicalProblems';
import { UserValidation } from './UserValidation';

const { investorBlockedAccountPath } = PATHS;

const {
  ERROR,
  STARTED,
  SUCCESS,
  BLOCKED,
  USER_VERIFICATION,
  UPDATE_INFORMATION,
  MANUAL_VERIFICATION,
  WAITING_FOR_RESPONSE,
} = IDENTITY_VERIFICATION_STATUSES;

const IdentityVerification = (props) => {
  const { onFinishProcess = () => {}, onUploadIDFinished = () => {} } = props;
  const [session] = useReduxQuery('SESSION');
  const [, { silentFetch: fetchPersonalArchive }] = useReduxQuery('PERSONAL_ARCHIVES');
  const [isMatiProcessing, setIsMatiProcessing] = useState(false);
  const userEmail = session.user.email;
  const [KYCStatus, { status: KYCStatusStatus, isLoading, refetch: fetchKYCStatus }] = useReduxQuery('KYC_STATUS');
  const onFinishedHandler = () => {
    onUploadIDFinished();
    setIsMatiProcessing(true);
  };

  useEffect(() => {
    if (KYCStatus?.matiStatus === USER_VERIFICATION) {
      fetchPersonalArchive();
    }
  }, [KYCStatus?.matiStatus]);

  if (KYCStatusStatus.failed) return <TechnicalProblems />;

  if (isLoading) return null;

  if (!KYCStatusStatus.completed) return null;

  const viewKey = KYCStatus.matiStatus;

  switch (viewKey) {
    case STARTED: {
      return (
        <IdentityVerificationContainer>
          <DotsLoaderBox isLoading={isMatiProcessing}>
            <StartProcess
              userEmail={userEmail}
              fetchStatus={fetchKYCStatus}
              onFinish={onFinishedHandler}
            />
          </DotsLoaderBox>
        </IdentityVerificationContainer>
      );
    }
    case WAITING_FOR_RESPONSE: {
      return (
        <IdentityVerificationContainer>
          <WaitingForResponse userEmail={userEmail} />
        </IdentityVerificationContainer>
      );
    }
    case UPDATE_INFORMATION: {
      return (
        <IdentityVerificationContainer>
          <UpdateInformation />
        </IdentityVerificationContainer>
      );
    }
    case MANUAL_VERIFICATION: {
      return (
        <IdentityVerificationContainer>
          <ManualVerification />
        </IdentityVerificationContainer>
      );
    }
    case USER_VERIFICATION: {
      return (
        <IdentityVerificationContainer>
          <UserValidation />
        </IdentityVerificationContainer>
      );
    }
    case ERROR: {
      return (
        <IdentityVerificationContainer>
          <Error />
        </IdentityVerificationContainer>
      );
    }
    case BLOCKED: {
      return <Redirect to={investorBlockedAccountPath()} />;
    }
    case SUCCESS: {
      return (
        <IdentityVerificationContainer>
          <Success onClickFinish={onFinishProcess} />
        </IdentityVerificationContainer>
      );
    }
    default:
      return null;
  }
};

export default IdentityVerification;
