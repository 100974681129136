import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import {
  Grid,
  Flex,
  Text,
  Link,
  Alert,
  Button,
  Heading,
  AlertIcon,
} from 'ui';
import { UpdateAddressField } from 'components/UpdateAddressField';
import { tscope, jsx } from 'i18n';
import { DotsLoaderBox } from 'components/Loaders';
import { styles } from './ShowAddress.style';
import { useController } from './hooks';

const ts = tscope('Expedients.Address.show');

export const ShowAddress = () => {
  const {
    address,
    control,
    onSubmit,
    idAddress,
    isLoading,
    displayForm,
    displayAlert,
    displayConfirm,
    rejectingReason,
    addressValidated,
    addressFieldProps,
    isAddressCompleted,
    isWaitingForChanges,
  } = useController();

  return (
    <Flex as="form" onSubmit={onSubmit} {...styles.container}>
      <Heading {...styles.title}>
        {ts('title')}
      </Heading>
      <Heading as="h4" {...styles.subtitle}>
        {ts('subtitle')}
      </Heading>
      {displayAlert && (
      <Alert {...styles.alert}>
        <AlertIcon />
        <Text color="gray.700">
          {rejectingReason || ts('alerts.warning.proofOfAddress')}
          <br />
          {jsx('updateHere', {
            t: ts,
            link: (
              <Link
                as={RRLink}
                to="update"
                textTransform="uppercase"
              >
                {ts('here')}
              </Link>
            ),
          })}
        </Text>
      </Alert>
      )}
      <DotsLoaderBox isLoading={isWaitingForChanges}>
        <Grid {...styles.content}>
          {displayForm && (
          <>
            <Text {...styles.cardTitle}>
              {ts('cardTitle')}
              :
            </Text>
            {address && [null, true].includes(addressValidated) && (
            <Text {...styles.addressText}>
              {ts('addressLine1')}
              {' '}
              <Text as="b" fontWeight="medium" textTransform="capitalize">
                {ts('addressLine1_5', {
                  street: address.street,
                  number: address.extNumber,
                  intNumber: address.intNumber
                    ? ts('addressInt', { intNumber: address.intNumber })
                    : '',
                  city: address.city,
                  state: address?.state?.name || '',
                  municipality: address.municipality,
                  zip: address.zip,
                  neighborhood: address.neighborhood,
                })}
              </Text>
              {' '}
              {ts('addressLine2')}
              <Text as="b" fontWeight="medium" textTransform="capitalize">
                {idAddress}
              </Text>
              <br />
              <br />
              {jsx('addressLine3', {
                t: ts,
                link: (
                  <Link
                    {...styles.editLink}
                    to="update"
                    as={RRLink}
                    disabled={isLoading}
                    textTransform="lowercase"
                  >
                    {ts('actions.edit')}
                  </Link>
                ),
              })}
            </Text>
            )}
            {(address && addressValidated === false) && (
            <>
              <Text {...styles.addressText} textTransform="capitalize" color="gray.700">
                {ts('address2.line1', {
                  street: address.street,
                  extNumber: address.extNumber,
                  intNumber: address.intNumber
                    ? ts('addressInt', { intNumber: address.intNumber })
                    : '',
                })}
                <br />
                {ts('address2.line2', {
                  neighborhood: address.neighborhood,
                  zip: address.zip,
                })}
                <br />
                {ts('address2.line3', {
                  city: address.city,
                  state: address?.state?.name || '',
                  municipality: address.municipality,
                })}
              </Text>
              <Link
                {...styles.editLink}
                to="update"
                as={RRLink}
                disabled={isLoading}
                textTransform="capitalize"
              >
                {ts('actions.change')}
              </Link>
            </>
            )}
            {!address && (
            <Text {...styles.addressText}>
              {ts('noAddressRecord')}
              {'. '}
              <Link
                {...styles.editLink}
                to="update"
                as={RRLink}
                disabled={isLoading}
                textTransform="capitalize"
              >
                ¡
                {ts('actions.edit')}
                !
              </Link>
            </Text>
            )}
            {displayConfirm && (
            <Flex {...styles.actions}>
              <Button
                type="submit"
                isDisabled={isLoading || !address || !isAddressCompleted}
              >
                {ts('actions.submit')}
              </Button>
            </Flex>
            )}
          </>
          )}

          {displayAlert && (
          <>
            <UpdateAddressField
              {...addressFieldProps}
              name="file"
              control={control}
              gridColumn="1 / -1"
            />
            <Text {...styles.proofOfAddressMandatory}>
              {ts('proofOfAddressMandatory')}
            </Text>
          </>
          )}
        </Grid>
      </DotsLoaderBox>
    </Flex>
  );
};
