import React from 'react';
import PayHistoryRow from './PayHistoryRow';

const PayHistoryComponent = (props) => {
  const { stateDetailsPayHistory } = props;
  return (
    <div
      style={{
        overflowX: 'auto',
        maxHeight: '60rem',
      }}
    >
      <table className="payHistory mobil">
        <thead>
          <tr>
            <td>Fecha de Aplicación</td>
            <td>Monto Pagado</td>
            <td>Saldo</td>
          </tr>
        </thead>
        <tbody>
          {stateDetailsPayHistory?.map((itm) => (
            <PayHistoryRow item={itm} key={`${itm['@diffgr:id']}`} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayHistoryComponent;
