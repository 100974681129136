import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchSessions } from 'actions/auth';
import { t } from 'i18n';
import { selectTab } from 'actions/dashboard';
import { Box, Flex } from 'styles';
import { Heading, Text } from 'components/Typography';
import { SETTINGS_ITEM } from 'views/SideBar/utils';
import SecurityContainer, { ContentCard } from './Security.styles';
import Footer from '../../../FooterDashboard';
import HelpCard from './HelpCard';
import DeviceInfoSection from './DeviceInfoSection';
import SecondFactorSelection from './SecondFactorSelection/SecondFactorSelection';
import MissingSecondFactor from './MissingSecondFactor/MissingSecondFactor';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.initialState', ...options });

const Security = (props) => {
  const { getSessionsInfo, sessionsInfo } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectTab(SETTINGS_ITEM));
  }, []);

  useEffect(() => {
    if (!sessionsInfo) getSessionsInfo();
  }, []);

  return (
    <>
      <SecurityContainer className="security">
        <div className="security__content">
          <DeviceInfoSection />
          <MissingSecondFactor />
          <ContentCard>
            <Box p="3rem">
              <Heading as="h3">{ts('header.title')}</Heading>
              <Flex direction="column">
                <Text mb="2rem">
                  {ts('body.instructions.0')}
                  <Text as="b">{ts('body.instructions.1')}</Text>
                  {ts('body.instructions.2')}
                </Text>
                <SecondFactorSelection />
              </Flex>
            </Box>
          </ContentCard>
          <HelpCard />
        </div>
      </SecurityContainer>
      <Footer />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  sessionsInfo: auth.sessionsInfo,
});

const mapDispatchToProps = {
  getSessionsInfo: fetchSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Security);
