import * as types from './types';

export const togglePortfolioFilters = (value) => ({
  type: types.TOGGLE_PORTFOLIO_FILTERS,
  payload: value,
});

export const togglePortfolioSearch = (value) => ({
  type: types.TOGGLE_PORTFOLIO_SEARCH,
  payload: value,
});

export const setPageNumber = async (value) => () => ({
  type: types.SET_PORTFOLIO_PAGE_NUMBER,
  payload: value,
});
