export const SET_SESSION = 'SET_CREDENTIALS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const SAVE_LAST_PATH = 'SAVE_LAST_PATH';
export const SET_USER_PROFILE_INFO = 'SET_USER_PROFILE_INFO';
export const IS_FETCHING_USER_PROFILE_INFO = 'IS_FETCHING_USER_PROFILE_INFO';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const RESTART_STATE = 'RESTART_STATE';
export const SET_IN_MAINTENANCE = 'SET_IN_MAINTENANCE';
export const SET_OTP_COUNTDOWN = 'SET_OTP_COUNTDOWN';
export const SET_EXPIRED_SESSION_MODAL = 'SET_EXPIRED_SESSION_MODAL';
export const SET_SESSIONS_INFO = 'SET_SESSIONS_INFO';
export const SET_PHONE_NUMBER_UPDATE = 'SET_PHONE_NUMBER_UPDATE';
export const SET_USER_LIMITS_LEVEL_TWO = 'SET_USER_LIMITS_LEVEL_TWO';
export const SET_CREDENTIAL_EMAIL = 'SET_CREDENTIAL_EMAIL';
