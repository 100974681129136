import styled from 'styled-components';
import bgLoading from 'assets/loading.svg';
import { colors } from 'styles';

const LoginContainer = styled.div`
  background-color: ${colors.gray.g0};
  background-image: url(${bgLoading});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  flex-direction: column;
  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: ${colors.gray.g6};
  }
  .lds-ellipsis div {
    background: #3a99f9;
  }
`;

export default LoginContainer;
