import React from 'react';
import { Icon } from 'ui';

export const UnlockIcon = (props) => (
  <Icon viewBox="0 0 29 29" {...props}>
    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="settings/icon/autoinvest-Icons/Menu/Config-Copy-8" fill="currentColor">
        <path d="M19.7792089,22.3976663 C19.7792089,22.6127497 19.6040005,22.787958 19.3901255,22.787958 L10.0569584,22.787958 C9.84187501,22.787958 9.66666667,22.6127497 9.66666667,22.3976663 L9.66666667,16.8272497 C9.66666667,15.9536247 10.3771667,15.2431247 11.2507917,15.2431247 L18.1950838,15.2431247 C19.0687088,15.2431247 19.7792089,15.9536247 19.7792089,16.8272497 L19.7792089,22.3976663 Z M18.1950837,14.0347918 L11.9915001,14.0347918 L11.9915001,9.95666672 C11.9915001,8.46437503 13.2058752,7.25000002 14.7477086,7.25000002 C16.2412087,7.25000002 17.4555837,8.46437503 17.4555837,9.95666672 L17.4555837,11.6809584 C17.4555837,12.0144584 17.7250421,12.2851251 18.0597504,12.2851251 C18.3932504,12.2851251 18.6639171,12.0144584 18.6639171,11.6809584 L18.6639171,9.95666672 C18.6639171,7.79737502 16.9070004,6.04166667 14.6981669,6.04166667 C12.5388752,6.04166667 10.7831668,7.79737502 10.7831668,9.95666672 L10.7831668,14.0819168 C9.46608338,14.3066668 8.45833333,15.4485418 8.45833333,16.8272501 L8.45833333,22.3976669 C8.45833333,23.2785419 9.17608336,23.9962919 10.0569584,23.9962919 L19.3901255,23.9962919 C20.2710005,23.9962919 20.9875422,23.2785419 20.9875422,22.3976669 L20.9875422,16.8272501 C20.9875422,15.2878335 19.7345005,14.0347918 18.1950837,14.0347918 L18.1950837,14.0347918 Z" id="Fill-1" />
      </g>
    </g>
  </Icon>
);
