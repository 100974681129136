import { useEffect, useMemo } from 'react';
import { tscope } from 'i18n';
import isNil from 'utils/isNil';
import { decodeJWT } from 'utils/JWT';
import { showToastWarn } from 'utils/toastHandler';
import { useReduxMutator } from 'utils/redux-query';
import { generateUniqueKey } from 'utils/react-utils/createUniqueKey';

const alertsT = tscope('Dashboard.Transactions.alerts');

const getIsArrayOfFees = (fees) => {
  if (isNil(fees)) return null;
  if (!Array.isArray(fees)) return fees === '' ? null : false;
  if (fees.length === 0) return null;
  return true;
};

export const useController = ({
  onClose,
  transaction,
}) => {
  const [fetchVoucher, { data, status }] = useReduxMutator(
    'TRANSACTION_VOUCHER',
    {
      onError: () => {
        showToastWarn(alertsT('warn.fetchVoucher'));
        onClose();
      },
    },
  );
  const token = data?.token;
  const isLoading = status.pending || status.initial;
  const voucher = useMemo(() => {
    if (!token) return null;
    const { payload } = decodeJWT(token);
    const fees = Array.isArray(payload.y)
      ? payload.y.map(({ c, m }) => ({
        id: generateUniqueKey('transaction'),
        label: c,
        value: m,
      }))
      : payload.y;

    const voucherParsed = {
      fees,
      token,
      type: payload.o,
      date: payload.t,
      amount: payload.m,
      company: payload.i,
      account: payload.m,
      referenceId: payload.f,
      accountNumber: payload.c,
    };
    return voucherParsed;
  }, [token]);
  const isArrayOfFees = getIsArrayOfFees(voucher?.fees);

  useEffect(() => {
    if (!transaction?.uid) return;
    fetchVoucher({
      uid: transaction.uid,
    });
  }, [transaction]);

  return {
    voucher,
    isLoading,
    isArrayOfFees,
  };
};
