import React, { useState, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';
import LevelUpModal from 'components/AnnounceLimits/LevelUpModal';
import { firstLevelWarnsKeys } from 'config/constants';
import Settings from 'utils/sdkYTP/investorSettings';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';

const ignorePaths = [
  PATHS.investorSettingsLimitsPath(),
  PATHS.investorSignContractPath(),
];

function LimitsWarningModal() {
  const [session] = useReduxQuery('SESSION');
  const [statement] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [showLevelUpLimitModal, toggleLevelUpLimitModal] = useState(false);
  const levelWarn = session?.levelWarn;
  const warnLevelStatus = levelWarn?.level;
  const settings = Settings(session);
  const matchWithIgnorePaths = useRouteMatch({ path: ignorePaths });
  const location = useLocation();

  useEffect(() => {
    if (!warnLevelStatus) return;

    const showLimitModal = [
      firstLevelWarnsKeys.OVERFUNDED,
      firstLevelWarnsKeys.ACCOUNT_VALUE_ON_FIFTY_PERCENT,
      firstLevelWarnsKeys.TRANSACTIONS_ON_SIXTY_PERCENT,
      firstLevelWarnsKeys.TRANSACTIONS_ON_NINETY_PERCENT,
      firstLevelWarnsKeys.EXCEEDED_TRANSACTIONAL_LIMIT,
    ].includes(warnLevelStatus);

    const showWarningModal = [
      showLimitModal,
      !matchWithIgnorePaths,
      !settings.isBlockedAccount(),
    ].every(Boolean);
    toggleLevelUpLimitModal(showWarningModal);
  }, [
    warnLevelStatus,
    location?.pathname,
    matchWithIgnorePaths,
  ]);

  useEffect(() => {
    if (!levelWarn) {
      toggleLevelUpLimitModal(false);
    }
  }, [levelWarn]);

  if (!showLevelUpLimitModal) return null;

  return (
    <LevelUpModal
      isOpen={showLevelUpLimitModal}
      warnLevel={levelWarn}
      accountValue={statement?.valorCuenta}
      onClose={() => toggleLevelUpLimitModal(false)}
    />
  );
}

export default LimitsWarningModal;
