import { gql } from '@apollo/client';
import { useMutation, useQuery } from 'hooks/apollo';

export const BANK_ACCOUNTS_GQL = gql`
query BANK_ACCOUNTS_GQL {
  user {
    id
    withdrawalAccounts {
      id
      bank
      clabe
      status
      clabeAlias
    }
  }
}
`;

export const useBankAccountsQuery = (options) => useQuery(BANK_ACCOUNTS_GQL, options);

export const CREATE_BANK_ACCOUNT = gql`
mutation CREATE_BANK_ACCOUNT($input: CreateWithdrawalAccountMutationInput!) {
  createWithdrawalAccount(input: $input) {
    user {
      id
      withdrawalAccounts {
        bank
        clabe
        clabeAlias
        id
        status
      }
    }
  }
}
`;

export const useCreateBankAccountMutation = (options) => useMutation(CREATE_BANK_ACCOUNT, options);

export const UPDATE_BANK_ACCOUNT = gql`
mutation UPDATE_BANK_ACCOUNT($input: UpdateWithdrawalAccountMutationInput!) {
  updateWithdrawalAccount(input: $input) {
    user {
      id
      withdrawalAccounts {
        bank
        clabe
        clabeAlias
        id
        status
      }
    }
  }
}
`;

export const useUpdateBankAccountMutation = (options) => useMutation(UPDATE_BANK_ACCOUNT, options);
