const statusOptions = [
  { id: 1, value: 'Solicitud Aperturada', text: 'Por Depositar' },
  { id: 2, value: 'Al Corriente', text: 'Al Corriente' },
  { id: 3, value: 'Solicitud Por Formalizar', text: 'Por Formalizar' },
  { id: 4, value: 'En Proceso de Pago', text: 'En tránsito' },
  { id: 5, value: 'Solicitud En Fondeo', text: 'En Fondeo' },
  { id: 6, value: 'Atrasado', text: 'Atrasado' },
  { id: 7, value: 'Vencido', text: 'Vencido' },
  { id: 8, value: 'Solicitud Cancelada', text: 'Cancelada' },
  { id: 9, value: 'Liquidado', text: 'Liquidado' },
];

export const statusDescriptions = {
  'Solicitud Aperturada': {
    title: 'Por Depositar',
    description:
      'Préstamos pendientes de ser entregados y activados por nuestra área de tesorería.',
  },
  'Al Corriente': {
    title: 'Al Corriente',
    description: 'Préstamos con pago puntual.',
  },
  'Solicitud Por Formalizar': {
    title: 'Por Formalizar',
    description: 'Préstamos pendientes de ser aceptados por el acreditado.',
  },
  'En Proceso de Pago': {
    title: 'En tránsito',
    description: 'Cobros pendientes de ser confirmados por el banco del acreditado',
  },
  'Solicitud En Fondeo': {
    title: 'En Fondeo',
    description: 'Préstamos en proceso de fondeo.',
  },
  Atrasado: {
    title: 'Atrasado',
    description: 'Préstamos con atraso menor a 90 días.',
  },
  Vencido: {
    title: 'Vencido',
    description: 'Préstamos con atraso mayor a 90 días.',
  },
  'Solicitud Cancelada': {
    title: 'Cancelada',
    description: 'Préstamos cancelados por los acreditados.',
  },
  Liquidado: {
    title: 'Liquidado',
    description: 'Préstamos liquidado por el acreditado.',
  },
};

export default statusOptions;
