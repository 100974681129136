import React from 'react';
import PropTypes from 'prop-types';
import iconSet from 'assets/icons/iconSet';
import Button from './Button';

const sizes = {
  xs: { button: { w: '1.5rem', h: '1.5rem' }, icon: { width: '1rem', height: '1rem' } },
  sm: { button: { w: '2rem', h: '2rem' }, icon: { width: '1.5rem', height: '1.5rem' } },
  md: { button: { w: '3rem', h: '3rem' }, icon: { width: '2rem', height: '2rem' } },
};

const ButtonIcon = (props) => {
  const {
    iconName,
    size,
    type,
    variant = 'transparent',
    isRounded,
    title = '',
    ...buttonProps
  } = props;
  const Icon = iconSet[iconName].component || iconSet.QUESTION.component;
  const sizesStyleProps = sizes[size];
  const roundedStyleProps = isRounded ? { borderRadius: '50%' } : {};

  return (
    <Button
      type={type}
      $type={variant}
      p="0"
      title={title}
      {...sizesStyleProps.button}
      {...roundedStyleProps}
      {...buttonProps}
    >
      <Icon title={title} {...sizesStyleProps.icon} />
    </Button>
  );
};

ButtonIcon.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(iconSet)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  type: PropTypes.string,
};

ButtonIcon.defaultProps = {
  iconName: 'QUESTION',
  size: 'sm',
  type: 'button',
};

export default ButtonIcon;
