import { useReduxSubscription } from 'utils/redux-query';
import { useMemo } from 'react';
import { WITHDRAWAL_BANK_ACCOUNT_STATUS } from 'config/constants';
import { useWithdrawalBankAccounts } from './graphql/useQueryWithdrawalAccount';

export const useController = (params) => {
  const {
    setCurrentBank,
    setOpenDeleteModal,
  } = params;

  const { data, loading } = useWithdrawalBankAccounts();
  const userBankAccounts = data?.user?.withdrawalAccounts || null;
  const [secondFactor] = useReduxSubscription('SECOND_FACTOR');

  const bankAccounts = useMemo(() => (
    userBankAccounts || []
  ).reduce((rest, account) => ([
    ...rest,
    {
      isDisabled: !secondFactor
        || account.status === WITHDRAWAL_BANK_ACCOUNT_STATUS.VALIDATING,
      ...account,
    },
  ]), []), [userBankAccounts, secondFactor]);

  const handleOpenDeleteModal = (bankAccount) => {
    setCurrentBank(bankAccount);
    setOpenDeleteModal(true);
  };

  return {
    loading,
    bankAccounts,
    handleOpenDeleteModal,
  };
};
