import { tscope } from 'i18n';

const t = tscope('catalog.relationshipTypes');

export const relationshipTypes = [
  { label: t('mother'), value: 'mother' },
  { label: t('father'), value: 'father' },
  { label: t('spouse'), value: 'spouse' },
  { label: t('children'), value: 'children' },
  { label: t('brotherly'), value: 'brotherly' },
  { label: t('other'), value: 'other' },
];
