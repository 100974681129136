export const styles = {
  container: {
    direction: { base: 'column', xs: 'row' },
    position: 'relative',
    backgroundColor: 'schema.zenfi.blue.300',
    borderRadius: '4px',
    padding: '2rem',
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    direction: 'column',
    gap: '1rem',
  },
  zenfiImage: {
    width: '100%',
    height: '100%',
  },
  contentText: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    width: { base: '80%', xs: '70%' },
  },
  link: {
    fontWeight: '500',
  },
  bgContainer: {
    position: 'absolute',
    right: { base: '-10rem', xs: '0' },
    top: { base: '0', xs: '0' },
    width: { base: '80%', xs: '45%' },
  },
  zenfiLogo: {
    width: '12rem',
    height: '2.2rem',
  },
};
