import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { useReduxSubscription } from 'utils/redux-query';
import { getModalStyles } from './styles';

const MODAL_ID = 'react-modal-container';

export const scrollToBottom = () => {
  const element = document.getElementById(MODAL_ID);
  element.scrollTop = element.scrollHeight;
};

const Modal = ({
  isOpen,
  onClose,
  onRequestClose,
  overlayStyle,
  contentStyle,
  closeOnSessionEnds,
  children,
  ...otherReactModalProps
}) => {
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const hasSessionEnded = ['idle', 'invalid_credentials_for_api', 'another_session'].includes(
    sessionStatus?.reason,
  );

  useEffect(() => {
    if (closeOnSessionEnds && hasSessionEnded) {
      onRequestClose();
      onClose();
    }
  }, [closeOnSessionEnds, hasSessionEnded]);

  useEffect(() => {
    ReactModal.setAppElement('body');
  }, []);

  return (
    <ReactModal
      id={MODAL_ID}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={() => { onRequestClose(); onClose(); }}
      style={getModalStyles({ overlayStyle, contentStyle })}
      {...otherReactModalProps}
    >
      {children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  onClose: () => {},
  onRequestClose: () => {},
  closeOnSessionEnds: true,
};

Modal.propTypes = {
  onClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  closeOnSessionEnds: PropTypes.bool,
};

export { CloseButton } from './CloseButton';
export default Modal;
