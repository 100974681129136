import styled from 'styled-components';
import { colors } from 'styles';

export const YtpLogo = styled.img`
  position: absolute;
  width: 2.4rem;
  left: 2rem;
  top: 1.3rem;
`;

export const BorrowersContainer = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 50rem;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: gray;
  }
  @media (max-width: 1300px) {
    padding-top: 2rem;
  }
  .tickets_created {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 1.3rem;
    color: #04475c;
  }
  .borrowers {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 420px;
    &__table {
      &__header {
        width: 80%;
        border-collapse: collapse;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        th {
          font-weight: normal;
          :first-child {
            padding-left: 5rem;
            text-align: left;
          }
          :last-child {
            padding-right: 6rem;
            text-align: right;
          }
        }
      }
      &__body {
        width: 80%;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #e3edf0;
        border-radius: 8px;
        box-shadow: 0 2px 5px 0 rgba(4, 71, 92, 0.07);
        font-size: 1.3rem;
        line-height: 40px;
        color: #04475c;
        td {
          border-bottom: 1px solid #e3edf0;
          :first-child {
            padding-left: 5rem;
            text-align: left;
            position: relative;
            img {
              position: absolute;
              width: 10px;
              left: 21px;
              top: 10px;
            }
          }
          :last-child {
            font-weight: 500;
            padding-right: 6rem;
            text-align: right;
          }
        }
        tr:last-child td {
          border-bottom: none;
        }
        & .tickets_created {
          padding-bottom: 2rem;
        }
      }
    }
  }
  .borrowers_table {
    width: 80%;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #e3edf0;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(4, 71, 92, 0.07);
    font-size: 1.3rem;
    line-height: 40px;
    color: #04475c;
    td {
      border-bottom: 1px solid #e3edf0;
      :first-child {
        padding-left: 5rem;
        text-align: left;
        position: relative;
      }
      :last-child {
        font-weight: 500;
        padding-right: 6rem;
        text-align: right;
      }
    }
    tr:last-child td {
      border-bottom: none;
    }
    &.ticketsTable {
      margin-bottom: 5rem;
    }
  }
  .ytp-note {
    border: 1px solid ${colors.green};
    width: 80%;
    padding: 1.5rem 2rem 1.5rem 2rem;
    border-radius: 6px;
    margin-bottom: 2rem;
    background-color: ${colors.gray.g0};
    span {
      font-weight: bold;
      color: ${colors.green};
    }
  }
`;
