import { t } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import { showToastError, showToastSuccess } from 'utils/toastHandler';
import { updatePassword } from 'apis/settings/accountProfile';
import { useFormValidator } from './useFormValidator';

const ts = (key, options) => t(key, { scope: 'components.UpdatePasswordForm', ...options });

export const useController = (options) => {
  const {
    onUpdatePasswordSuccess,
  } = options;
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');
  const { validate } = useFormValidator();

  const handleSubmit = async (values) => {
    const {
      otp,
      newPassword,
      currentPassword,
      newPasswordConfirmation,
    } = values;
    try {
      const { status } = await updatePassword(
        {
          currentPassword,
          newPassword,
          newPasswordConfirmation,
        },
        otp,
      );

      if (status === 200) {
        onUpdatePasswordSuccess();
        showToastSuccess(ts('alerts.passwordUpdatedSuccessfully'));
        return null;
      }
      if (status === 204) {
        showToastError(ts('alerts.currentPasswordWrong'));
        return {
          currentPassword: ts('fields.currentPassword.errors.invalid'),
          conditionPasswordTypes: null,
        };
      }
      return null;
    } catch {
      showToastError(ts('alerts.passwordUpdatedFailed'));
      return null;
    }
  };

  return {
    validate,
    secondFactor,
    handleSubmit,
    secondFactorStatus,
  };
};
