import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const MainAreaContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 56px);
  overflow-y: scroll;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-left: 18rem;
    width: calc(100% - 18rem);
  }
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-left: 0;
    width: 100%;
  }
`;

export default MainAreaContainer;
