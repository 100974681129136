import * as React from 'react';
import { Box, Center } from 'ui';
import DotsLoader from 'components/DotsLoader';

const bgProps = {
  filter: 'auto',
  blur: '3px',
  boxShadow: 'md',
  pointerEvents: 'none',
  brightness: '98%',
};

export const DotsLoaderBox = ({ isLoading, children, ...props }) => (
  <Center {...props}>
    <Box {...(isLoading ? bgProps : {})} position="relative">
      {children}
    </Box>
    <DotsLoader
      zIndex="10"
      position="absolute"
      color="schema.blue.500"
      visibility={isLoading ? 'visible' : 'hidden'}
    />
  </Center>
);
