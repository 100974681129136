import React from 'react';
import { t } from 'i18n';
import DotsLoader from 'components/DotsLoader';
import SecondFactorModal from 'views/DashboardContent/Activate2FAWizard/SecondFactorModal';
import { Button, Container, Text } from 'ui';
import { style } from './PanelSecondFactorCTA.style';
import { useController } from './hooks/useController';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.activateCTA', ...options });

export const PanelSecondFactorCTA = () => {
  const {
    isOpen,
    onOpen,
    onClose,
    status,
    secondFactor,
  } = useController();

  const InactiveSecondFactor = (
    <Container
      {...style.container}
    >
      <Container
        {...style.innerContainer}
      >
        <Text>
          {ts('title.0')}
          <Text as="b">
            {ts('title.1')}
          </Text>
        </Text>
        <Text>{ts('body')}</Text>
      </Container>
      <Button
        {...style.button}
        onClick={onOpen}
      >
        {ts('configure')}
      </Button>
      <SecondFactorModal isOpen={isOpen} onClose={onClose} />
    </Container>
  );

  if (status.pending || status.initial) return <DotsLoader {...style.loader} />;

  if (!secondFactor) {
    return <>{InactiveSecondFactor}</>;
  }

  return null;
};
