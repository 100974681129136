import { useState } from 'react';

function useSteps() {
  const [steps, setSteps] = useState([]);

  const trackStep = ({ name, hasUserTakenForm }) => {
    setSteps((currentSteps) => [
      ...currentSteps,
      {
        name,
        hasUserTakenForm,
      },
    ]);
  };

  const findStepByName = (name) => steps.find((step) => step.name === name) || null;

  return {
    steps,
    trackStep,
    findStepByName,
  };
}

export default useSteps;
