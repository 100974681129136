import {
  SET_ACTIVE_TAB,
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_ACCOUNT_STATEMENT,
  FETCHING_ACCOUNT_PORTFOLIO,
  SET_PORTFOLIO_CACHING,
  TOGGLE_DETAILS,
  SET_SOCKET_VALUES,
  SET_FUNDED_REQUISITIONS_IDS,
  SET_ACCOUNT_BALANCES,
  SET_ACCOUNT_BALANCES_SIDEBAR,
  SET_ACCOUNT_STATEMENT_CACHING,
  SET_ACCOUNT_STATEMENT_DATA_READY,
  SET_PORTFOLIO_DATA_READY,
} from 'actions/dashboard/types';
import { dashboardRoute } from 'routes';

const INITIAL_STATE = {
  accountStatement: null,
  activeTab: {
    name: 'Resumen',
    path: dashboardRoute,
    title: 'Inicio',
  },
  sidebarOpen: false,
  displayDetail: false,
  idRequisitionDetail: '',
  fundedRequisitionsIds: null,
  dispositionId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarOpen: action.payload };
    case CLOSE_SIDEBAR:
      return { ...state, sidebarOpen: action.payload };
    case SET_ACCOUNT_STATEMENT:
      return {
        ...state,
        accountStatement: action.payload || null,
      };
    case FETCHING_ACCOUNT_PORTFOLIO:
      return { ...state, accountPortfolio: action.payload || null };
    case SET_PORTFOLIO_CACHING:
      return { ...state, cachingPortfolio: action.payload };
    case TOGGLE_DETAILS:
      return {
        ...state,
        displayDetail: action.payload,
        idRequisitionDetail: action.idRequisitionDetail,
        dispositionId: action.dispositionId,
      };
    case SET_SOCKET_VALUES:
      return {
        ...state,
        socketValues: {
          ...state.socketValues,
          ...action.payload,
        },
      };
    case SET_FUNDED_REQUISITIONS_IDS:
      return { ...state, fundedRequisitionsIds: action.payload };
    case SET_ACCOUNT_BALANCES_SIDEBAR:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          saldo_disponible: action.availableBalance,
          valor_cuenta: action.accountValue,
        },
      };
    case SET_ACCOUNT_BALANCES:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          saldo_disponible: action.availableBalance,
          valor_cuenta: action.accountValue,
          saldo_en_fondeo: action.fundingBalance,
        },
      };
    case SET_ACCOUNT_STATEMENT_CACHING:
      return {
        ...state,
        cachingAccountStatement: action.payload,
      };
    case SET_ACCOUNT_STATEMENT_DATA_READY:
      return {
        ...state,
        accountStatementDataReady: action.payload,
      };
    case SET_PORTFOLIO_DATA_READY:
      return {
        ...state,
        accountPortfolioDataReady: action.payload,
      };
    default:
      return state;
  }
};
