import React from 'react';
import { tscope, toCurrency } from 'i18n';
import imageSet from 'assets/images/imageSet';
import {
  Card,
  CardBody,
  Heading,
  Image,
  Text,
} from 'ui';
import { styles } from './ProofOfAddressOnReview.style';

const ts = tscope('settings.limits.onReview');
export const ProofOfAddressOnReview = ({
  levelConfig,
  ...props
}) => (
  <Card {...props}>
    <CardBody {...styles.card}>
      <Heading {...styles.heading}>
        {ts('title')}
      </Heading>
      <Image
        {...styles.image}
        src={imageSet.INE_ZOOM.url}
      />
      <Text {...styles.desc}>
        {ts('desc1')}
        {' '}
        <br />
        <b>{ts('currentLevel')}</b>
      </Text>
      <Text {...styles.desc}>
        {ts('desc2')}
        <br />
        {' '}
        <strong>
          {toCurrency(levelConfig?.maxAccountLimit)}
        </strong>
      </Text>
      <Text {...styles.desc}>
        {ts('desc3')}
        <br />
        {' '}
        <strong>
          {toCurrency(levelConfig?.maxOperationLimit)}
        </strong>
      </Text>
    </CardBody>
  </Card>
);
