import React from 'react';
import Alert from 'components/Alert';
import { tscope } from 'i18n';
import { UploadFiscalForm } from '../InvoicingSection/components';
import { useController } from './hooks/useController';

const t = tscope('settings.banners.fiscalDataError');

export const ErrorInFiscalInfoAlert = (props) => {
  const {
    isModalOpen,
    onOpenModal,
    onCloseModal,
  } = useController();

  return (
    <>
      <Alert
        status="warn"
        title={t('title')}
        message={t('body')}
        actionButtonProps={{
          alignSelf: 'center',
          children: t('action'),
          onClick: onOpenModal,
        }}
        {...props}
      />
      {isModalOpen
        && (
          <UploadFiscalForm
            isOpen={isModalOpen}
            onClose={onCloseModal}
          />
        )}
    </>
  );
};
