import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { smallBreakPoint, extraSmallBreakPoint } = breakPoints;

const ConditionsDescriptionContainer = styled.div`
  min-width: 10rem;
  display: flex;
  flex-direction: column;

  .container {
    position: relative;
    min-width: 10rem;

    &.grow {
      @media (max-width: ${smallBreakPoint}px) and (min-width: ${extraSmallBreakPoint}px) {
        .text {
          line-height: 22px;
        }
      }
    }
  }
  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .counter {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.4rem;
  }
  .text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: ${smallBreakPoint}px) {
      font-size: 1.4rem;
    }
    @media (max-width: ${extraSmallBreakPoint}px) {
      line-height: 20px;
    }
  }
  .confirmationButtons {
    padding: 2rem;
    position: relative;
    &--container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &--ok-button {
      padding: 1.5rem 3rem;
    }
    &--moreInfo {
      /* position: absolute; */
      right: 0;
      top: 1.5rem;
      text-decoration: underline;
      color: ${colors.gray.g4};
      a {
        color: ${colors.gray.g4};
        :visited {
          color: ${colors.gray.g4};
        }
        :focus {
          outline: none;
        }
      }
      :hover {
        cursor: pointer;
      }
    }
  }
  .password_confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text_info {
      font-size: 1.1rem;
      align-self: flex-start;
      color: #677d85;
      text-align: left;
      max-width: 33.7rem;
      font-weight: 500;
      margin-top: 1rem;
    }
  }
`;

export default ConditionsDescriptionContainer;
