import React from 'react';
import { toCurrency } from 'utils/numberFormatters';
import ytpSymbolSrc from 'assets/ytp-black.png';
import { t } from 'i18n';
import { YtpLogo, BorrowersContainer } from './styles';
import TicketsTable from './TicketsTable';

const BorrowersRequisitionDetail = ({
  borrowers,
  lendedTicketsByYtp,
  percentajeYtpLended,
  createdTickets,
  updateCreatedTickets,
  widthLayout,
}) => {
  const items = borrowers.map((item) => (
    <tr key={`${item.username}--${item.amount}`}>
      <td>
        {item['lended_by_ytp?'] && (
          <YtpLogo src={ytpSymbolSrc} alt={t('detailRequisition.ytpUser')} />
        )}
        {item.username}
      </td>
      <td>{toCurrency(item.amount)}</td>
    </tr>
  ));

  return (
    <BorrowersContainer>
      <div className="borrowers">
        {lendedTicketsByYtp && (
          <div className="ytp-note">
            <span>Yotepresto.com</span>
            {' '}
            comparte el
            <b>
              {percentajeYtpLended.toFixed(2)}
              %
            </b>
            {' '}
            de
            riesgo en ésta solicitud
          </div>
        )}
        {!!createdTickets.length && (
          <TicketsTable
            tickets={createdTickets}
            updateCreatedTickets={updateCreatedTickets}
            widthLayout={widthLayout}
          />
        )}
        <table className="borrowers__table__header">
          <tbody>
            <tr>
              <th>Usuario</th>
              <th>Monto</th>
            </tr>
          </tbody>
        </table>
        <table className="borrowers_table">
          <tbody>{items}</tbody>
        </table>
      </div>
    </BorrowersContainer>
  );
};

export default BorrowersRequisitionDetail;
