import React from 'react';
import { Center } from 'ui';
import AddressView from 'views/DashboardContent/Expedients2/Address/Address';
import { useController } from './hooks';

export const Address = () => {
  useController();

  return (
    <Center paddingY="10">
      <AddressView />
    </Center>
  );
};
