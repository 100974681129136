import { fetchZipCodeInfoCatalog } from 'apis/catalogs';
import { ZIPCODE_LENGTH } from 'config/constants';
import { useEffect, useState } from 'react';

export const useZipCode = (zip, { onError, onCompleted } = {}) => {
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    if (!zip || zip.length < ZIPCODE_LENGTH) return;
    setIsLoading(true);
    fetchZipCodeInfoCatalog(zip)
      .then((data) => {
        setLocation(data);
        onCompleted();
      })
      .finally(() => setIsLoading(false));
  }, [zip]);

  useEffect(() => {
    if (!zip || zip.length < ZIPCODE_LENGTH) return;
    const noLocationFound = !location || location.status === 'error';
    if (noLocationFound) onError();
  }, [location]);

  return { location, isLoading };
};
