import {
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';

const { smallBreakPoint } = breakPoints;

const PasswordConfirmationContainer = styled.div.withConfig({ shouldForwardProp })`
  width: 100%;
  display: flex;
  input {
    height: 4.3rem;
    border-radius: 0.4rem;
    border: 1px solid #a2bcc5;
    padding: 1.2rem 1.3rem;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    @media (max-width: ${smallBreakPoint}px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
    :focus {
      outline: none;
    }
    ::placeholder {
      font-size: 1.5rem;
      color: #c2d4db;
      font-weight: 500;
    }
  }
  form {
    display: flex;
    gap: 1rem;
    width: 100%;

    @media (max-width: ${smallBreakPoint}px) {
      flex-direction: column;
    }
    ${boxHelpers}
  }
  .button {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 1.2rem 1rem;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    @media (max-width: ${smallBreakPoint}px) {
      width: 100%;
    }
  }
`;

export default PasswordConfirmationContainer;
