import React from 'react';
import { tscope } from 'i18n';
import PATHS from 'routes';
import { Text } from 'components/Typography';
import { Link } from 'react-router-dom';
import AlertModal from 'components/AlertModal/AlertModal';

const t = tscope('Dashboard.alerts.partiallyBlocked');

export const BlockedAlert = (props) => {
  const { alertIsOpen, closeAlert, name } = props;

  return (
    <AlertModal
      title={t('title', { name })}
      isOpen={alertIsOpen}
      onClose={closeAlert}
      status="warn"
      body={(
        <Text
          textAlign="center"
        >
          {t('body')}
        </Text>
      )}
      acceptText={t('action')}
      buttonProps={{
        as: Link,
        to: PATHS.cnbvPath(),
        onClick: closeAlert,
      }}
    />
  );
};
