import React from 'react';
import { t } from 'i18n';
import Button from 'components/Button/Button';
import { Grid } from 'styles';
import { styles } from './Help.style';

const ts = (i18nKey) => t(i18nKey, { scope: 'Dashboard.Summary.activeState.widgets.help' });

export const Help = () => (
  <Grid {...styles.container}>
    <Button {...styles.button} href={ts('CTA.financialReport.link')}>
      {ts('CTA.financialReport.label')}
    </Button>
    <Button {...styles.button} href={ts('CTA.guide.link')}>
      {ts('CTA.guide.label')}
    </Button>
    <Button {...styles.button} href={ts('CTA.statistics.link')}>
      {ts('CTA.statistics.label')}
    </Button>
    <Button {...styles.button} href={ts('CTA.helpCenter.link')}>
      {ts('CTA.helpCenter.label')}
    </Button>
  </Grid>
);
