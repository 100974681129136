import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { t } from 'i18n';
import PATHS from 'routes';
import { useReduxSubscription } from 'utils/redux-query';
import { showToastInfo } from 'utils/toastHandler';

const ts = (key) => t(key, { scope: 'Movements.default' });

export const useCachingTransactionHandler = () => {
  const [movements, { status: movementsStatus, refetch }] = useReduxSubscription('MOVEMENTS');
  const [movementsFilter] = useReduxSubscription('MOVEMENTS_FILTER');
  const matchTransactionsRoute = useRouteMatch(PATHS.investorTransactionsPath());
  const [hasAlertDisplayed, setHasAlertDisplayed] = useState(false);
  const isValidToRefetch = Boolean(
    movements?.ready && movementsStatus.completed && matchTransactionsRoute,
  );

  useEffect(() => {
    if (!isValidToRefetch) return;
    refetch(movementsFilter);
  }, [
    movementsFilter,
    isValidToRefetch,
  ]);

  useEffect(() => {
    if (hasAlertDisplayed || !movements?.ready) return;

    showToastInfo(ts('alerts.info.fetchMovementsCompleted'));
    setHasAlertDisplayed(true);
  }, [movements?.ready, hasAlertDisplayed]);
};
