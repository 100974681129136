import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import withSizes from 'react-sizes';
import { connect, useDispatch } from 'react-redux';
import {
  getOrFetchRequisitionList,
  hidingRequisition,
  updateRequisitionLendAmount,
  hideRequisition,
} from 'actions/requisitions';
import ReactTooltip from 'react-tooltip';
import get from 'lodash.get';
import moment from 'moment';
import useQueryParams from 'hooks/useQueryParams';
import 'react-tabs/style/react-tabs.css';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { getRequisitionDetail } from 'apis/requisitions';
import Footer from 'views/DashboardContent/FooterDashboard';
import {
  calculateRemainingPercentage,
  toCurrency,
  toCurrencyWithDecimals,
} from 'utils/numberFormatters';
import { breakPoints } from 'config/constants';
import { ReactComponent as LeftNavigation } from 'assets/leftNavigation.svg';
import { ReactComponent as RightNavigation } from 'assets/rightNavigation.svg';
import IncomeSVG from 'assets/requisitions/income.svg';
import OutcomeSVG from 'assets/requisitions/outcome.svg';
import InfoSVG from 'assets/icons/info.svg';
import DotsLoader from 'components/DotsLoader';
import { FinancialLoader } from 'components/Loaders';
import PATHS from 'routes';
import {
  isSourceActivityAnEmail,
  isSourceActivityWhatsapp,
  isSourceActivityAnSMS,
  isSourceActivityAnMobile,
  isSourceActivityAnHomeManagement,
} from 'actions/requisitions/selectors';
import { tscope } from 'i18n';
import { Box, Flex, Text } from 'components';
import CommentsRequisitionDetail from './CommentsRequisitionDetail';
import BorrowersRequisitionDetail from './BorrowersRequisitionDetail';
import MobilBarRequisitionDetail from './MobilBarRequisitionDetail';
import PayHistoryComponent from './PayHistoryComponent';
import AvatarQualificationInterestStatus from './Sections/AvatarQualificationInterestStatus';
import RequisitionInfoGroup from './Elements/RequisitionInfoGroup';
import FundedAndCartAmounts from './Elements/FundedAndCartAmounts';
import ProductTypeDescription from './Elements/ProductTypeDescription';
import DelayPaymentAndDispersionDates from './Elements/DelayPaymentAndDispersionDates';
import LeftBarContent from './Sections/LeftBarContent';
import HeaderElements from './Elements/HeaderElements';
import PaymentsGraphCard from './Sections/MainContent/PaymentsGraphCard';
import CreditHistory from './Sections/MainContent/CreditHistory';
import PreviewsLoansTable from './Sections/MainContent/PreviewsLoansTable';
import TopItems from './Sections/MainContent/TopItems';
import DescriptionContent from './Sections/MainContent/DescriptionContent';
import {
  DetailContentContainer,
  WhatsappIcon,
  SmsIcon,
  EnvelopIcon,
  CallIcon,
  HomeManagementIcon,
  P,
} from './styles';
import ElementCardContainer from './Elements/ElementCardContainer';
import ZapopanMessage from './Elements/ZapopanMessage';
import AmortizationSchedule from './AmortizationSchedule';

const { investorRequisitionPath } = PATHS;

const t = tscope('detailRequisition');

const getPreviousRequisiton = ({ requisitions, currentRequisitionId }) => {
  if (!requisitions) return null;

  const currentRequisitionIndex = requisitions.findIndex(
    (requisition) => requisition.zell_app_id === currentRequisitionId,
  );

  return requisitions.find((_, index) => index === currentRequisitionIndex - 1);
};

const getNextRequisiton = ({ requisitions, currentRequisitionId }) => {
  if (!requisitions) return null;

  const currentRequisitionIndex = requisitions.findIndex(
    (requisition) => requisition.zell_app_id === currentRequisitionId,
  );

  return requisitions.find((_, index) => index === currentRequisitionIndex + 1);
};

const DetailContent = (propsContainer) => {
  const {
    width,
    onUpdateList,
    cartItems,
    requisitions,
    requisitionsArray: FilteredRequisitons,
    onUpdatingLendAmount,
    lendAmountUpdated,
    isEnablePagination = true,
    showVisibilityButton = true,
    backTo,
  } = propsContainer;
  const { requisitionId } = useParams();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const [loadingData, setLoadingData] = useState(true);
  const [statusCollapse, setStatusCollapse] = useState(false);
  const [requisitionData, setRequisitionData] = useState({});
  const requisitionPreview = requisitionId
    ? requisitions.find((itm) => itm.zell_app_id.toString() === requisitionId.toString()) || {}
    : {};
  const mobile = width < breakPoints.midBreakPoint;
  const [fundedRequisition, setFundedRequisition] = useState(false);
  const [stateDetailsPayHistory, setStateDetailsPayHistory] = useState([]);
  const [lentAmount, setLentAmount] = useState(0);
  const [fundedPercentage, setFundedPercentage] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [amountCart, setAmountCart] = useState(0);
  const [autoinvestTickets, setAutoinvestTickets] = useState(false);
  const [createdTickets, setCreatedTickets] = useState([]);
  const [pastCredits, setPastCredits] = useState([]);
  const [numberOfCredits, setNumberOfCredits] = useState(0);

  const nextRequisition = useMemo(
    () => getNextRequisiton({
      requisitions: FilteredRequisitons,
      currentRequisitionId: requisitionId,
    }),
    [FilteredRequisitons, requisitionId],
  );
  const previousRequisition = useMemo(
    () => getPreviousRequisiton({
      requisitions: FilteredRequisitons,
      currentRequisitionId: requisitionId,
    }),
    [FilteredRequisitons, requisitionId],
  );

  const fetchRequisition = async (id, dispositionId) => {
    setLoadingData(true);
    const response = await getRequisitionDetail(id, dispositionId);

    if (response.requisition) {
      const isFundedRequisition = get(response.requisition, 'status') !== 'funding';
      setRequisitionData(response.requisition);
      setLentAmount(response.requisition.lent_amount);
      setFundedPercentage(response.requisition.general_details.porc_fondeado);
      setRemainingAmount(
        response.requisition.general_details.monto_aprobado
        - response.requisition.general_details.monto_fondeado,
      );
      setAmountCart(response.requisition.amount_on_shopping_cart || 0);
      setFundedRequisition(isFundedRequisition);
      if (isFundedRequisition) {
        setStateDetailsPayHistory(response.requisition.credit_detail.payment_log);
      }
      setAutoinvestTickets(!!response.requisition['has_tickets_by_autoinvest?']);
      setCreatedTickets((state) => response.requisition.my_tickets ?? state);

      const credits = response.requisition.past_credits;
      const numberCredits = response.requisition.number_of_credits;
      if (credits) setPastCredits(credits || []);
      if (numberCredits) setNumberOfCredits(numberCredits);
      setLoadingData(false);
      setStatusCollapse(false);
    }
  };
  useEffect(() => {
    if (requisitionId) {
      const dispositionId = queryParams.get('dispositionId');

      fetchRequisition(requisitionId, dispositionId);
    }
  }, [requisitionId]);
  useEffect(() => {
    const ticketsOnCart = cartItems.filter((item) => item.requisition_id === requisitionData.id);
    const totalTicketsAmount = ticketsOnCart.reduce(
      (a, b) => ({
        amount: parseInt(a.amount, 10) + parseInt(b.amount, 10),
      }),
      { amount: 0 },
    );
    setAmountCart(totalTicketsAmount.amount);
  }, [cartItems]);
  useEffect(() => {
    if (requisitions) {
      const amountRequisition = parseInt(requisitionData.lent_amount, 10);
      const newRequisitionData = requisitions.find((itm) => itm.zell_app_id === requisitionId);
      if (newRequisitionData) {
        const newAmount = parseInt(newRequisitionData.funded_amount, 10);
        if (amountRequisition !== newAmount) {
          const approveAmount = parseFloat(newRequisitionData.approved_amount);
          const percentageAmount = calculateRemainingPercentage(
            newRequisitionData.loan_detail.missing_amount,
            approveAmount,
          );
          setLentAmount(newAmount);
          setFundedPercentage(percentageAmount);
          setRemainingAmount(newRequisitionData.loan_detail.missing_amount);
        }
      }
    }
  }, [requisitions]);
  useEffect(() => {
    if (lendAmountUpdated) onUpdatingLendAmount(false);
  }, [lentAmount]);

  const onHideRequisition = async () => {
    dispatch(
      hideRequisition({ requisitionId: requisitionPreview.id, hidden: !requisitionPreview.hidden }),
    );
    onUpdateList();
  };
  const getExpense = (expenseType) => {
    const zeroText = '$0';
    const { expenses } = requisitionData.general_details;
    if (Array.isArray(expenses)) {
      const mount = expenses.find((e) => e.tipo === expenseType);
      if (mount) return mount.monto !== '0.00' ? toCurrencyWithDecimals(mount.monto) : zeroText;
    }
    if (expenses.tipo === expenseType) return expenses.monto !== '0.00' ? toCurrencyWithDecimals(expenses.monto) : zeroText;
    return zeroText;
  };
  const FinancialStatusContent = () => {
    const TableSpends = () => (
      <Box
        className="financialStatusContent--item expenses"
        mt={{ base: '1rem', lg: '0 !important' }}
        pl={{ base: '0 !important', lg: '1rem !important' }}
        maxWidth="35rem !important"
        minWidth="25rem !important"
        flexGrow="1"
      >
        <Text
          t={t}
          i18nKey="expenses.title"
          color="schema.gray.500"
          fontSize="1.4rem"
          lineHeight="2.2rem"
          transform="uppercase"
        />
        <Box as="table" className="expenses__table">
          <tbody>
            <tr>
              <td>
                <Text
                  t={t}
                  i18nKey="expenses.list.cards"
                  color="schema.gray.500"
                  fontSize="1.4rem"
                  lineHeight="1.8rem"
                />
              </td>
              <td>{getExpense('1')}</td>
            </tr>
            <tr>
              <td>
                <Text
                  t={t}
                  i18nKey="expenses.list.essentialExpenses"
                  color="schema.gray.500"
                  fontSize="1.4rem"
                  lineHeight="1.8rem"
                />
              </td>
              <td>{getExpense('8')}</td>
            </tr>
            <tr>
              <td>
                <Text
                  t={t}
                  i18nKey="expenses.list.transportation"
                  color="schema.gray.500"
                  fontSize="1.4rem"
                  lineHeight="1.8rem"
                />
              </td>
              <td>{getExpense('7')}</td>
            </tr>
            <tr>
              <td>
                <Text
                  t={t}
                  i18nKey="expenses.list.entertainment"
                  color="schema.gray.500"
                  fontSize="1.4rem"
                  lineHeight="1.8rem"
                />
              </td>
              <td>{getExpense('2')}</td>
            </tr>
            <tr>
              <td>
                <Text
                  t={t}
                  i18nKey="expenses.list.insurance"
                  color="schema.gray.500"
                  fontSize="1.4rem"
                  lineHeight="1.8rem"
                />
              </td>
              <td>{getExpense('5')}</td>
            </tr>
            {
              getExpense('6') !== '$0'
              && (
                <tr>
                  <td>
                    <Text
                      t={t}
                      i18nKey="expenses.list.rent"
                      color="schema.gray.500"
                      fontSize="1.4rem"
                      lineHeight="1.8rem"
                    />
                  </td>
                  <td>{getExpense('6')}</td>
                </tr>
              )
            }

            <tr>
              <Box as="td" textAlign="left !important" colSpan="2">
                <Text
                  as="i"
                  t={t}
                  fontWeight="600"
                  i18nKey="expenses.notice"
                  color="schema.gray.500"
                  fontSize="1.2rem"
                  lineHeight="1.8rem"
                />
              </Box>
            </tr>
          </tbody>
        </Box>
      </Box>
    );
    if (fundedRequisition && !loadingData) {
      return (
        <ElementCardContainer titleFoldCard="ESTADO FINANCIERO" classNames="foldingCard" isFoldCard>
          <Flex className="financialStatusContent" flexWrap="wrap" gap="1rem">
            <div className="financialStatusContent--incomesExpenses">
              <Flex className="financialStatus card" direction="row !important" flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                <div className="financialStatus__item">
                  <div className="financialStatus__incomes">
                    <img src={IncomeSVG} alt="income icon" />
                    <h3>{toCurrency(requisitionData.general_details.ingresos)}</h3>
                    <p>Ingresos</p>
                  </div>
                </div>
                <div className="financialStatus__item">
                  <div className="financialStatus__outcome">
                    <img src={OutcomeSVG} alt="outcome icon" />
                    <h3>{toCurrency(requisitionData.general_details.total_egresos)}</h3>
                    <p>Egresos</p>
                  </div>
                </div>
              </Flex>
            </div>
            <Flex
              className="financialStatusContent--tableExpenses"
              gap="1rem"
              width="100%"
              alignItems="start !important"
            >
              {!loadingData && <TableSpends />}
            </Flex>
          </Flex>
        </ElementCardContainer>
      );
    }
    return (
      <>
        <div className="splitContent__left">
          <p className="mainContainer__content__title">{!loadingData && 'ESTADO FINANCIERO'}</p>
          <Flex className="financialStatus card" direction="row !important" flexWrap={{ base: 'wrap', sm: 'nowrap' }}>
            <div className="financialStatus__item">
              <div className="financialStatus__incomes">
                {loadingData ? (
                  <FinancialLoader />
                ) : (
                  <div>
                    <img src={IncomeSVG} alt="income icon" />
                    <h3>{toCurrency(requisitionData.general_details.ingresos)}</h3>
                    <p>Ingresos</p>
                  </div>
                )}
              </div>
            </div>
            <div className="financialStatus__item">
              <div className="financialStatus__outcome">
                {loadingData ? (
                  <FinancialLoader />
                ) : (
                  <div>
                    <img src={OutcomeSVG} alt="outcome icon" />
                    <h3>{toCurrency(requisitionData.general_details.total_egresos)}</h3>
                    <p>Egresos</p>
                  </div>
                )}
              </div>
            </div>
          </Flex>
        </div>
        <Flex gap="1rem">
          {loadingData ? (
            <Box className="expenses__loader" alignSelf="center">
              <DotsLoader black />
            </Box>
          ) : (
            <TableSpends />
          )}
        </Flex>
      </>
    );
  };
  const PersonalInfoContent = () => {
    const sgmm = get(requisitionData, 'general_details.sgmm');
    const autoPropio = get(requisitionData, 'general_details.auto_propio');
    const TableElement = () => (
      <div className="personalInfo__table">
        <div>
          <p>Edad</p>
          <p>{requisitionData.age}</p>
        </div>
        <div>
          <p>Dependientes</p>
          <p>{requisitionData.dependents}</p>
        </div>
        <div>
          <p>
            SGMM
            <img
              src={InfoSVG}
              alt="info tooltip"
              data-for="tooltipSGMM"
              data-tip="Cuenta con Seguro Privado de Gastos Médicos Mayores"
              className="tooltip sgmm"
            />
          </p>
          <ReactTooltip id="tooltipSGMM" getContent={(dataTip) => dataTip} />
          <p>{sgmm === 0 || !sgmm ? 'No' : 'Si'}</p>
        </div>
        <div>
          <p>Auto propio</p>
          <p>{autoPropio > 0 ? 'Si' : 'No'}</p>
        </div>
        <div>
          <p>Estudios</p>
          <p>{requisitionData.degree}</p>
        </div>
        <div>
          <p>Residencia</p>
          <p>
            {`${requisitionData.city.slice(0, 1)}${requisitionData.city
              .slice(1)
              .toLocaleLowerCase()}`}
          </p>
        </div>
        <div>
          <p>Vivienda</p>
          <p>{requisitionData.property_type}</p>
        </div>
        <div>
          <p>Ocupación</p>
          <p>{requisitionData.general_details.ocupacion}</p>
        </div>
        <div>
          <p>Antiguedad Laboral</p>
          <p>{`${requisitionData.general_details.antiguedad_laboral} años`}</p>
        </div>
        <div>
          <p>Tipo de empleo</p>
          <p>{requisitionData.employee_type}</p>
        </div>
      </div>
    );

    if (fundedRequisition && !loadingData) {
      return (
        <ElementCardContainer titleFoldCard="DATOS PERSONALES" classNames="foldingCard" isFoldCard>
          <TableElement />
        </ElementCardContainer>
      );
    }
    return (
      <>
        <p className="mainContainer__content__title">{!loadingData && 'DATOS PERSONALES'}</p>
        {loadingData ? (
          <div className="personalInfo__table loader">
            <DotsLoader black />
          </div>
        ) : (
          <TableElement />
        )}
      </>
    );
  };
  const PayHistoryContent = () => {
    if (mobile) return <PayHistoryComponent stateDetailsPayHistory={stateDetailsPayHistory} />;
    return (
      <div
        style={{
          overflowX: 'auto',
          maxHeight: '60rem',
        }}
      >
        <table className="payHistory">
          <thead>
            <tr>
              <td>Fecha de Aplicación</td>
              <td>Monto Pagado</td>
              <td>Capital</td>
              <td>Int. Ordinario + IVA</td>
              <td>Int. Moratorio + IVA</td>
              <td>Saldo</td>
            </tr>
          </thead>
          <tbody>
            {(requisitionData.credit_detail.payment_log || []).map((itm) => (
              <tr key={`${itm.fecha_aplicacion}--${itm['@diffgr:id']}`}>
                <td>{itm.fecha_aplicacion}</td>
                <td>
                  <b>{toCurrencyWithDecimals(itm.monto_total_pagado)}</b>
                </td>
                <td>{toCurrencyWithDecimals(itm.capital)}</td>
                <td>{toCurrencyWithDecimals(itm.interes_con_iva)}</td>
                <td>{toCurrencyWithDecimals(itm.interes_moratorio_con_iva)}</td>
                <td>
                  <b>{toCurrencyWithDecimals(itm.saldo_insoluto)}</b>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const LogCollectionContent = () => {
    const { collection } = requisitionData;
    const { actions, promises } = collection;
    const renderActivity = (activity) => {
      if (isSourceActivityWhatsapp(activity)) {
        return (
          <P>
            <WhatsappIcon />
            {' '}
            {activity}
          </P>
        );
      }
      if (isSourceActivityAnSMS(activity)) {
        return (
          <P>
            <SmsIcon />
            {' '}
            {activity}
          </P>
        );
      }
      if (isSourceActivityAnMobile(activity)) {
        return (
          <P>
            <CallIcon />
            {' '}
            {activity}
          </P>
        );
      }
      if (isSourceActivityAnEmail(activity)) {
        return (
          <P>
            <EnvelopIcon />
            {' '}
            {activity}
          </P>
        );
      }
      if (isSourceActivityAnHomeManagement(activity)) {
        return (
          <P>
            <HomeManagementIcon />
            {' '}
            {activity}
          </P>
        );
      }
      return <P>{activity}</P>;
    };
    const renderPayPromises = () => {
      if (promises?.length) {
        const yesterdayDate = moment().subtract(2, 'days');
        const availablePromises = promises.filter(
          (item) => moment(item.payment_promise_date).diff(yesterdayDate) >= 0,
        );
        if (availablePromises.length) {
          return (
            <div className="promisesContainer">
              {availablePromises.map((promise) => (
                <div key={promise.id} className="promisesContainer__item">
                  <span>
                    Promesa de pago:
                    {' '}
                    <b>{moment(promise.payment_promise_date).format('DD/MM/YYYY')}</b>
                  </span>
                  <span>
                    Cantidad
                    {' '}
                    <b>{toCurrencyWithDecimals(promise.amount)}</b>
                  </span>
                </div>
              ))}
            </div>
          );
        }
      }
      return null;
    };
    return (
      <div className={`logCollectionContainer ${actions && actions.length > 0 ? '' : 'empty'}`}>
        {renderPayPromises()}
        <table className="logCollectionTable">
          <thead>
            <tr>
              <td>Actividad</td>
              <td>Resultado</td>
              <td>Fecha y hora</td>
            </tr>
          </thead>
          <tbody>
            {(actions || []).map((itm) => (
              <tr key={itm.id}>
                <td>{renderActivity(itm.activity)}</td>
                <td>{itm.result}</td>
                <td>{moment(itm.created_at).format('DD/MM/YYYY, h:mm a')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const fundingEndDays = get(requisitionData, 'general_details.dias_fin');
  const loanInfo = get(requisitionData, 'credit_detail.loan_info');
  const creditInfo = get(requisitionData, 'credit_information');
  const zellID = get(requisitionData, 'zell_app_id');
  const destinationRequisition = get(requisitionData, 'destination');
  const descriptionRequisition = get(requisitionData, 'description');
  const collectionProduct = get(requisitionData, 'collection_product');
  const zapopanFlag = get(requisitionData, 'zapopan');
  const statusRequisition = get(requisitionData, 'status');
  const isFormalizeStatus = () => statusRequisition === 'formalize';

  useEffect(() => {
    dispatch(getOrFetchRequisitionList());
  }, []);

  const showPagination = Boolean(
    isEnablePagination && !loadingData && !mobile && FilteredRequisitons?.length,
  );

  return (
    <DetailContentContainer
      isMobile={mobile}
    >
      {showPagination && previousRequisition && (
        <Link
          className="circleNavigation left"
          to={investorRequisitionPath({
            requisitionId: previousRequisition?.zell_app_id || requisitionId,
          })}
        >
          <LeftNavigation title="" />
        </Link>
      )}
      {!mobile && (
        <LeftBarContent
          isFundedRequisition={fundedRequisition}
          isLoading={loadingData}
          dataReq={requisitionData}
          fundedPercentage={fundedPercentage}
          remainingAmount={remainingAmount}
          fundingEndDays={fundingEndDays}
          lentAmount={lentAmount}
          amountCart={amountCart}
          onUpdateAmountCart={setAmountCart}
          fetchRequisition={fetchRequisition}
          autoinvestTickets={autoinvestTickets}
          backTo={backTo}
        />
      )}
      <div className="mainContainer">
        {mobile && !loadingData && (
          <FundedAndCartAmounts
            lentAmount={lentAmount}
            amountCart={amountCart}
            autoinvestTickets={autoinvestTickets}
          />
        )}
        {!loadingData && mobile && fundedRequisition && (
          <div className="fundedHeader card">
            <HeaderElements
              zellID={requisitionData.zell_app_id}
              numberOfCredits={numberOfCredits}
              setStatusCollapse={setStatusCollapse}
              statusCollapse={statusCollapse}
              isHidden={requisitionPreview.hidden}
              onHideRequisition={onHideRequisition}
              showHideIcon={showVisibilityButton && !fundedRequisition}
              isLoading={loadingData}
              isMobile={mobile}
              backTo={backTo}
            />
            <AvatarQualificationInterestStatus
              dataReq={requisitionData}
              isFundedRequisition={fundedRequisition}
              isLoading={loadingData}
            />
            <RequisitionInfoGroup
              dataReq={requisitionData}
              isFundedRequisition={fundedRequisition}
              isLoading={loadingData}
            />
            {(collectionProduct || fundedRequisition) && (
              <ProductTypeDescription dataReq={requisitionData} />
            )}
          </div>
        )}
        <div className="mainContainer__content">
          {!loadingData && zapopanFlag && !fundedRequisition && <ZapopanMessage />}
          <TopItems
            isMobile={mobile}
            pastCredits={pastCredits}
            numberOfCredits={numberOfCredits}
            isLoading={loadingData}
            dataReq={requisitionData}
            fundingEndDays={fundingEndDays}
            zellID={zellID}
            statusCollapse={statusCollapse}
            setStatusCollapse={setStatusCollapse}
            isHidden={requisitionPreview.hidden}
            onHideRequisition={onHideRequisition}
            isFundedRequisition={fundedRequisition}
            remainingAmount={remainingAmount}
            fundedPercentage={fundedPercentage}
            showVisibilityButton={showVisibilityButton}
            backTo={backTo}
          />

          {!loadingData && statusCollapse && (
            <PreviewsLoansTable
              requisitionId={requisitionId}
              isFundedRequisition={fundedRequisition}
              pastCredits={pastCredits}
            />
          )}
          {!loadingData && fundedRequisition && mobile && !isFormalizeStatus() && (
            <DelayPaymentAndDispersionDates dataReq={requisitionData} />
          )}

          <PaymentsGraphCard
            isLoading={loadingData}
            isFundedRequisition={fundedRequisition}
            loanInfo={loanInfo}
            isMobile={mobile}
          />
          <CreditHistory
            isLoading={loadingData}
            creditInfo={creditInfo}
            isFundedRequisition={fundedRequisition}
          />
          {!loadingData && (
            <DescriptionContent
              isFundedRequisition={fundedRequisition}
              isLoading={loadingData}
              descriptionTitle={destinationRequisition}
              descriptionBody={descriptionRequisition}
            />
          )}
          <Flex className="splitContent" gap="1rem">
            <FinancialStatusContent />
          </Flex>
          <div className="personalInfo">
            <PersonalInfoContent />
          </div>
          {!mobile && !loadingData && (
            <>
              <div className="tabs">
                <Tabs>
                  <TabList>
                    <Tab>
                      {fundedRequisition ? 'Histórico de pagos' : 'Preguntas al solicitante'}
                    </Tab>
                    <Tab
                      className={
                        fundedRequisition ? 'react-tabs__tab logCollection' : 'react-tabs__tab'
                      }
                    >
                      {fundedRequisition ? (
                        'Bitácora de cobranza'
                      ) : (
                        <>
                          <p className="borrowers__text">¿Quiénes ya prestaron?</p>
                          <div className="borrowers__counter">{requisitionData.lenders.length}</div>
                        </>
                      )}
                    </Tab>
                    <Tab>Tabla de amortización</Tab>
                  </TabList>
                  <TabPanel>
                    {fundedRequisition ? (
                      <PayHistoryContent />
                    ) : (
                      <CommentsRequisitionDetail
                        commentsArray={requisitionData.questions}
                        idRequisition={requisitionData.id}
                        zellID={requisitionData.zell_app_id}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {fundedRequisition ? (
                      <LogCollectionContent />
                    ) : (
                      <BorrowersRequisitionDetail
                        borrowers={requisitionData.lenders}
                        lendedTicketsByYtp={requisitionData['lended_tickets_by_ytp?']}
                        percentajeYtpLended={requisitionData.percentaje_ytp_lended}
                        createdTickets={createdTickets}
                        updateCreatedTickets={setCreatedTickets}
                        widthLayout={width}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {requisitionData.amortizations && (
                      <AmortizationSchedule amortizations={requisitionData.amortizations} />
                    )}
                  </TabPanel>
                </Tabs>
              </div>
              {!fundedRequisition && (
                <div className="footer">
                  <div className="splitContent">
                    <div className="splitContent__left">
                      <img src={InfoSVG} alt="info tooltip" />
                      <p>
                        El anonimato es muy importante por razones de seguridad y tranquilidad para
                        toda nuestra comunidad. Te pedimos seguir estas reglas:
                      </p>
                    </div>
                    <div className="splitContent__right">
                      <ol className="footer__list">
                        <li>No hacer preguntas que puedan revelar la identidad del acreditado.</li>
                        <li>
                          No hacer preguntas sobre domicilio, lugar de empleo y datos de contacto.
                        </li>
                        <li>
                          No hacer preguntas que no sirvan para tomar una decisión de inversión.
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div className="footer__contactInfo">
                    <p>
                      Si identificas un usuario que rompe estas reglas, envíanos un correo a
                      {' '}
                      <a
                        href="mailto:ayuda@yotepresto.com"
                        className="footer__contactInfo__helpMail"
                      >
                        ayuda@yotepresto.com
                      </a>
                      {' '}
                      o comunícate con nosotros vía
                      {' '}
                      <span className="footer__contactInfo__whatsapp">
                        Whatsapp al 33-2332-6306
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {mobile && !loadingData && (
          <div className="width100">
            <div className="tabs">
              <Tabs>
                <TabList>
                  <Tab>
                    <p className="questions__text funded">
                      {fundedRequisition ? 'Histórico de pagos' : 'Preguntas al solicitante'}
                    </p>
                  </Tab>
                  <Tab>
                    {fundedRequisition ? (
                      <p className="borrowers__text">Bitácora de cobranza</p>
                    ) : (
                      <>
                        <p className="borrowers__text">¿Quiénes ya prestaron?</p>
                        <div className="borrowers__counter">{requisitionData.lenders.length}</div>
                      </>
                    )}
                  </Tab>
                  <Tab>Tabla de amortización</Tab>
                </TabList>
                <TabPanel>
                  {fundedRequisition ? (
                    <PayHistoryContent />
                  ) : (
                    <CommentsRequisitionDetail
                      commentsArray={requisitionData.questions}
                      idRequisition={requisitionData.id}
                      zellID={requisitionData.zell_app_id}
                      mobile
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {fundedRequisition ? (
                    <LogCollectionContent />
                  ) : (
                    <BorrowersRequisitionDetail
                      borrowers={requisitionData.lenders}
                      lendedTicketsByYtp={requisitionData['lended_tickets_by_ytp?']}
                      percentajeYtpLended={requisitionData.percentaje_ytp_lended}
                      createdTickets={createdTickets}
                      updateCreatedTickets={setCreatedTickets}
                      widthLayout={width}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {requisitionData.amortizations && (
                    <AmortizationSchedule amortizations={requisitionData.amortizations} />
                  )}
                </TabPanel>
              </Tabs>
            </div>
            {!fundedRequisition && (
              <div className="footer">
                <div className="footer__paragraph">
                  <img src={InfoSVG} alt="info tooltip" />
                  <p>
                    El anonimato es muy importante por razones de seguridad y tranquilidad para toda
                    nuestra comunidad. Te pedimos seguir estas reglas:
                  </p>
                </div>
                <ol type="1" className="footer__list">
                  <li>No hacer preguntas que puedan revelar la identidad del acreditado.</li>
                  <li>No hacer preguntas sobre domicilio, lugar de empleo y datos de contacto.</li>
                  <li>No hacer preguntas que no sirvan para tomar una decisión de inversión.</li>
                </ol>
                <div className="footer__contactInfo">
                  <p>
                    Si identificas un usuario que rompe estas reglas, envíanos un correo a
                    {' '}
                    <a href="mailto:ayuda@yotepresto.com" className="footer__contactInfo__helpMail">
                      ayuda@yotepresto.com
                    </a>
                    {' '}
                    o comunícate con nosotros vía
                    {' '}
                    <span className="footer__contactInfo__whatsapp">Whatsapp al 33-2332-6306</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        <Footer />
      </div>
      {mobile && !loadingData && !fundedRequisition && (
        <MobilBarRequisitionDetail
          remainingAmount={remainingAmount}
          isEnablePagination={isEnablePagination}
          nextRequisitionPath={investorRequisitionPath({
            requisitionId: nextRequisition?.zell_app_id || requisitionId,
          })}
          previousRequisitionPath={investorRequisitionPath({
            requisitionId: previousRequisition?.zell_app_id || requisitionId,
          })}
          idRequisition={requisitionData.id}
          remaining={
            requisitionData.general_details.monto_aprobado
            - requisitionData.general_details.monto_fondeado
          }
          onUpdateCart={setAmountCart}
          lentAmount={parseInt(requisitionData.lent_amount, 10)}
          requisitionData={requisitionData}
          onUpdateRequisition={fetchRequisition}
        />
      )}
      {showPagination && nextRequisition && (
        <Link
          className="circleNavigation right"
          to={investorRequisitionPath({
            requisitionId: nextRequisition?.zell_app_id || requisitionId,
          })}
        >
          <RightNavigation title="" />
        </Link>
      )}
    </DetailContentContainer>
  );
};

const mapSizesToProps = ({ width }) => ({ width });

const mapStateToProps = ({ dashboard, cart, requisitions }) => {
  const { cartItems } = cart;
  const { displayDetail, dispositionId } = dashboard;
  const { updateRequisitionLendAmount: lendAmountUpdated, fundingRequisitionList } = requisitions;
  return {
    displayDetailState: displayDetail,
    cartItems,
    dispositionID: dispositionId,
    lendAmountUpdated,
    requisitions: fundingRequisitionList.requisitions || [],
  };
};

export default withSizes(mapSizesToProps)(
  connect(mapStateToProps, {
    hideRequisition: hidingRequisition,
    onUpdatingLendAmount: updateRequisitionLendAmount,
  })(DetailContent),
);
