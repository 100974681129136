import React from 'react';
import LeftBarContainer from './styles';
import TopLeftBarContent from './TopLeftBarContent';
import BottomLeftBarContent from './BottomLeftBarContent';

const LeftBarContent = ({
  isFundedRequisition,
  isLoading,
  dataReq,
  fundedPercentage,
  remainingAmount,
  fundingEndDays,
  lentAmount,
  amountCart,
  onUpdateAmountCart,
  fetchRequisition,
  autoinvestTickets,
  backTo,
}) => (
  <LeftBarContainer className={isFundedRequisition ? 'funded' : ''}>
    <TopLeftBarContent
      isFundedRequisition={isFundedRequisition}
      isLoading={isLoading}
      dataReq={dataReq}
      fundedPercentage={fundedPercentage}
      remainingAmount={remainingAmount}
      fundingEndDays={fundingEndDays}
      backTo={backTo}
    />
    <BottomLeftBarContent
      isFundedRequisition={isFundedRequisition}
      isLoading={isLoading}
      dataReq={dataReq}
      lentAmount={lentAmount}
      amountCart={amountCart}
      onUpdateAmountCart={onUpdateAmountCart}
      fetchRequisition={fetchRequisition}
      autoinvestTickets={autoinvestTickets}
    />
  </LeftBarContainer>
);

export default LeftBarContent;
