function getSnowplowTracker() {
  const init = (config) => {
    const { appId, cookieDomain, endpoint } = config;
    /* eslint-disable */
    // STARTS code provided by SNOWPLOW
    (function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
    p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
    };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
    n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//d1fc8wv8zag5ca.cloudfront.net/2.5.1/sp.js","snowplow"));
    // ENDS code provided by SNOWPLOW
    /* eslint-enable */

    // Start tracker
    const { snowplow } = window;
    if (!snowplow) throw new Error('Error initializing Snowplow tracker');

    snowplow('newTracker', 'cf', endpoint, {
      appId,
      cookieDomain,
    });
  };

  const addContext = (context) => {
    const { snowplow } = window;
    if (!snowplow) return;

    snowplow('setUserId', context.current_user);
    snowplow('trackPageView');
  };

  return {
    init,
    addContext,
  };
}

export default getSnowplowTracker();
