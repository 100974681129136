import { gql, useMutation } from '@apollo/client';

export const UPDATE_BANK = gql`
  mutation UPDATE_BANK (
    $input: UpdateWithdrawalAccountMutationInput!
  ) {
    updateWithdrawalAccount(input: $input) {
      user {
        id
        withdrawalAccounts {
          id
          clabe
          status
          clabeAlias
          bank
        }
      }
    }
  }
`;

export const useUpdateBankAccountMutation = (options) => useMutation(UPDATE_BANK, options);
