import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
} from 'react';

export const webStorageContext = createContext();

export const WebStorageProvider = ({ children, prefixKey = '' }) => {
  const [storage, setStorage] = useState({});

  const setValue = useCallback((key, value, { strategy }) => {
    setStorage((currentStorage) => ({
      ...currentStorage,
      [strategy]: {
        ...(currentStorage[strategy] || {}),
        [key]: value,
      },
    }));
  }, []);

  const getValue = useCallback(
    (key, { strategy }) => {
      const value = storage[strategy]?.[key];
      if (value === undefined) return undefined;
      return storage[strategy][key];
    },
    [storage],
  );

  const contextValue = useMemo(
    () => [getValue, setValue, { prefixKey }],
    [getValue, setValue, prefixKey],
  );

  return (
    <webStorageContext.Provider value={contextValue}>
      {children}
    </webStorageContext.Provider>
  );
};
