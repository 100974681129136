import { useModalDisclosure } from 'hooks/ui';
import { useController as useFormController } from 'react-hook-form';
import { ts } from '../config';

export const useController = ({
  name,
  control,
  parentT = ts,
}) => {
  const { fieldState } = useFormController({ name, control });
  const { error } = fieldState;
  const t = (key, options) => parentT(
    key,
    { ...options, defaultValue: ts(key, options) },
  );
  const enable2FADisclosure = useModalDisclosure();

  return {
    t,
    error,
    enable2FADisclosure,
  };
};
