import React from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as OrderIcon } from 'assets/orders/menu_icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/roundedCheck.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/redWarning.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styled from 'styled-components';

const MessageText = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 1rem;
    color: inherit;
  }
`;

const TYPES_MAP = {
  error: toast.TYPE.ERROR,
  warning: toast.TYPE.WARNING,
  success: toast.TYPE.SUCCESS,
  info: toast.TYPE.INFO,
  default: toast.TYPE.SUCCESS,
};
const activeToasts = new Set();

const getToastHash = (type, message) => `${type}::${message}`;

function Message({ message, iconName }) {
  const iconSet = {
    orders: OrderIcon,
    success: CheckIcon,
    error: DangerIcon,
    warning: WarningIcon,
  };

  const Icon = iconSet[iconName] || null;

  return Icon ? (
    <MessageText>
      {Icon && <Icon width="30px" height="30px" title="" />}
      <p>{message}</p>
    </MessageText>
  ) : (
    message
  );
}

export const showToast = (type, message, { autoClose = true, iconName } = {}) => {
  const toastHash = getToastHash(type, message);

  activeToasts.add(toastHash);

  toast(<Message message={message} iconName={iconName} />, {
    type: TYPES_MAP[type] || TYPES_MAP.default,
    autoClose,
    onClose: () => {
      activeToasts.delete(toastHash);
    },
  });
};

export const showToastInfo = (message, options) => showToast('info', message, options);

export const showToastSuccess = (message, options) => showToast('success', message, options);

export const showToastError = (message, options) => showToast('error', message, options);

export const showToastWarn = (message, options) => showToast('warning', message, options);

export const showSuccessAlert = ({ t, key, ...i18nOptions }, options) => showToastSuccess(t(`alerts.success.${key}`, i18nOptions), options);
export const showErrorAlert = ({ t, key, ...i18nOptions }, options) => showToastError(t(`alerts.error.${key}`, i18nOptions), options);
export const showInfoAlert = ({ t, key, ...i18nOptions }, options) => showToastInfo(t(`alerts.info.${key}`, i18nOptions), options);
export const showWarningAlert = ({ t, key, ...i18nOptions }, options) => showToastWarn(t(`alerts.warning.${key}`, i18nOptions), options);

export default showToast;
