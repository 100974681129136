import * as API from 'apis/investor/personalArchives';
import { createAsyncAction } from 'utils/redux-query';

export const fetchPersonalArchives = createAsyncAction(
  'PERSONAL_ARCHIVES',
  () => async () => {
    const personalArchives = await API.fetch();
    return personalArchives;
  },
);
