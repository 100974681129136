import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const InfoPillDiv = ({ mobile, large, ...otherProps }) => <div {...otherProps} />;

const InfoPillContainer = styled(InfoPillDiv)`
  display: flex;
  justify-content: ${(props) => (props.large ? 'space-between' : 'flex-end')};
  .infoPill {
    &__body {
      margin: 1.5rem;
      border-radius: 2.1rem;
      display: flex;
      font-size: 1.4rem;
      background-color: #f2f5f7;
      padding: 2px 14px;
      font-weight: 500;
      & div {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        margin: 0.6rem 0;
        color: #0076ff;
        & svg {
          margin-right: 0.6rem;
          max-width: 18px;
        }
        &:first-of-type {
          color: #346071;
          padding-right: 1rem;
          border-right: 2px solid ${(props) => (props.mobile ? 'none' : '#ccdce2')};
          margin-right: 0.5rem;
        }
      }
    }
  }
  .filterButton {
    margin-left: 2rem;
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      margin-left: 1rem;
    }
    & button {
      margin-top: 2rem;
    }
  }
`;

export default InfoPillContainer;
