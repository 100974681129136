import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withSizes from 'react-sizes';

import { investorAddFunds } from 'routes';
import { selectTab } from 'actions/dashboard';
import addFundsImg from 'assets/AddFunds.svg';
import { ReactComponent as MoneyIcon } from 'assets/moneyIcon.svg';
import { ReactComponent as TransparentUserIcon } from 'assets/userIconTransparent.svg';
import { ReactComponent as UserIcon } from 'assets/userIcon.svg';
import Button from 'components/Button';
import { breakPoints } from 'config/constants';
import Footer from 'views/DashboardContent/FooterDashboard';
import { t } from 'i18n';
import { FUNDING_ACCOUNT_ITEM } from '../../../SideBar/utils';
import {
  DashboardEmptyState,
  LeftContent,
  VideoCard,
  RightContent,
  GridCardsContent,
  Card,
  BorderCard,
  HalfCard,
  StackCard,
  CardHeader,
  CardBody,
  TextCard,
} from './styles';

const HeaderRightCard = () => (
  <Card>
    <h1 className="welcomeTitle">Bienvenido a yotepresto</h1>
  </Card>
);

const VideoRightCard = () => (
  <VideoCard className="how_to_invest__container">
    <iframe
      src="https://www.youtube.com/embed/JQJL4XpjSsI?enablejsapi=1&rel=0&origin=https://testingytp.com"
      frameBorder="0"
      title="como-invertir-vid"
    />
  </VideoCard>
);

const EmptyState = ({ isMobile, selectActiveTab }) => {
  const handleTabSelection = () => selectActiveTab(FUNDING_ACCOUNT_ITEM);
  return (
    <DashboardEmptyState id="emptyState">
      <div className="content">
        <LeftContent>
          <GridCardsContent>
            {isMobile && <HeaderRightCard />}
            {isMobile && <VideoRightCard />}
            <Card>
              <img className="addFunds" alt="Add funds" src={addFundsImg} />
            </Card>
            <Card>
              <h3 className="fundsTitle">Aún no has fondeado tu cuenta</h3>
              <p className="fundsParagraph">
                Para poder empezar a prestar y hacer crecer tu dinero necesitas agregar fondos a tu
                cuenta
              </p>
              <div className="addFundsButton">
                <Link to={investorAddFunds}>
                  <Button onClick={handleTabSelection}>Agregar fondos</Button>
                </Link>
              </div>
            </Card>
            <VideoCard>
              <iframe
                width="560"
                height="315"
                src={t('summary.addFundsVideoURL')}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoCard>
          </GridCardsContent>
        </LeftContent>
        <RightContent>
          <GridCardsContent>
            {!isMobile && <HeaderRightCard />}
            {!isMobile && <VideoRightCard />}
            <BorderCard>
              <HalfCard>
                <MoneyIcon title="" />
                <p className="paragraphCard">Más de 600 millones en préstamos entregados</p>
              </HalfCard>
              <HalfCard>
                <TransparentUserIcon title="" />
                <p className="paragraphCard">Más de 1 millón usuarios registrados </p>
              </HalfCard>
            </BorderCard>
            <StackCard>
              <CardHeader>Testimonios</CardHeader>
              <CardBody>
                <TextCard>
                  <UserIcon title="" />
                  <p className="paragraphCard">
                    Llevo invirtiendo desde Abril de 2017, la verdad una excelente forma de
                    diversificar el dinero y la plataforma es muy transparente. Mi sugerencia es
                    realizar muchos préstamos pequeños, de alrededor de $500-$700 pesos, para poder
                    diversificar el riesgo.
                  </p>
                </TextCard>
                <TextCard>
                  <UserIcon title="" />
                  <p className="paragraphCard">
                    Muy buena opción para invertir, al momento todo sencillo y transparente, no he
                    tenido ningún problema, sólo trabajen más en la parte de garantizar por medio de
                    alguna autoridad que no serán un fraude en un futuro y uno así poder tener más
                    confianza de invertirles más con más confianza teniendo en cuenta los riesgos
                    normales del préstamo.
                  </p>
                </TextCard>
                <TextCard>
                  <UserIcon title="" />
                  <p className="paragraphCard">
                    Buena alternativa de inversión, es flexible, puedes invertir en línea y sin
                    tantos requisitos.
                  </p>
                </TextCard>
              </CardBody>
            </StackCard>
          </GridCardsContent>
        </RightContent>
      </div>
      <Footer />
    </DashboardEmptyState>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakPoints.midBreakPoint,
});

export default withSizes(mapSizesToProps)(
  connect(null, { selectActiveTab: selectTab })(EmptyState),
);
