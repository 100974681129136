import { useModalDisclosure } from 'hooks/ui';
import { useState } from 'react';

export const useController = () => {
  const { isOpen: openModal, onClose, onOpen: setOpenModal } = useModalDisclosure();
  const {
    isOpen: openDeleteModal,
    onClose: onCloseDeleteModal,
    onOpen: setOpenDeleteModal,
  } = useModalDisclosure();

  const [currentBank, setCurrentBank] = useState(null);

  const handleCloseEditModal = () => {
    setCurrentBank(null);
    onClose();
  };

  const handleCloseDeleteModal = () => {
    setCurrentBank(null);
    onCloseDeleteModal();
  };

  return {
    openModal,
    setOpenModal,
    currentBank,
    setCurrentBank,
    openDeleteModal,
    setOpenDeleteModal,
    handleCloseEditModal,
    handleCloseDeleteModal,
  };
};
