import React, { useState, useMemo } from 'react';
import { restartVerification } from 'apis/identityVerification';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { concatFullName } from 'utils/stringFormatters';
import moment from 'moment';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import UpdateInformationContainer from './styles';

const UpdateInformation = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [profile] = useReduxQuery('PROFILE');
  const [catalog] = useReduxQuery('CATALOG');
  const [, { refetch: refetchKYCStatus }] = useReduxSubscription('KYC_STATUS');
  const { curp = '', birthDate = '', birthStateId = '' } = profile ?? {};
  const fullName = concatFullName(profile);

  const handleChangeIdentification = async () => {
    setLoadingStatus(true);
    await restartVerification();
    await refetchKYCStatus();
    setLoadingStatus(false);
  };

  const stateName = useMemo(() => {
    if (!catalog || !birthStateId) return '';

    return catalog.states.find((state) => String(state.id) === String(birthStateId)).name;
  }, [catalog, birthStateId]);

  return (
    <UpdateInformationContainer>
      <div className="info">
        <h2>Los datos de tu identificación no coinciden con los que tenemos registrados:</h2>
      </div>
      <div className="mati-container white">
        <div className="fieldset">
          <label htmlFor="">Nombre</label>
          <span>{fullName}</span>
        </div>
        <div className="fieldset">
          <label htmlFor="">CURP</label>
          <span>{curp}</span>
        </div>
        <div className="fieldset-double">
          <div className="fieldset">
            <label htmlFor="">Fecha de nacimiento</label>
            <span>{moment(birthDate).format('DD/MM/YYYY')}</span>
          </div>
          <div className="fieldset">
            <label htmlFor="">Lugar de nacimiento</label>
            <span>{stateName}</span>
          </div>
        </div>
        <div className="button-container">
          <Button onClick={handleChangeIdentification}>
            {!loadingStatus ? 'Subir otra identificación' : <DotsLoader />}
          </Button>
        </div>
      </div>
      <div className="info-footer">
        <p>
          Si los datos con los que te registraste son incorrectos, contáctanos al:
          {' '}
          <b>33 2332 6306</b>
        </p>
        <div className="divider" />
        <p>
          <span className="highlight">Importante:</span>
          {' '}
          recuerda que para agregar y retirar fondos
          validaremos que tanto la cuenta origen como la cuenta destino estén a tu nombre.
        </p>
      </div>
    </UpdateInformationContainer>
  );
};

export default UpdateInformation;
