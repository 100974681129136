import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';
import snakeify from 'snakecase-keys';
import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { t } from 'i18n';

const ts = (key) => t(key, { scope: 'api.registers.confirmProfile' });

const cleanList = (list) => {
  const compactList = list.filter(Boolean);

  return compactList.length > 0 ? compactList : null;
};

async function confirm({ newProfile, password }) {
  const {
    curp,
    rfc,
    mexican,
    birthDate,
    birthStateId,
  } = newProfile.personalInformationRequestChange || newProfile;

  const curpErrors = [
    !curp && ts('serverErrors.cantBeBlank'),
    curp?.length < 18 && ts('serverErrors.curpLength'),
  ];
  const rfcErrors = [
    !rfc && ts('serverErrors.cantBeBlank'),
    rfc?.length < 13 && ts('serverErrors.rfcLength'),
  ];
  const birthDateErrors = [!birthDate && ts('serverErrors.cantBeBlank')];
  const birthStateIdErrors = [mexican && !birthStateId && ts('serverErrors.cantBeBlank')];

  const errors = clearErrorsObject({
    curp: cleanList(curpErrors),
    rfc: cleanList(rfcErrors),
    birthDate: cleanList(birthDateErrors),
    birthStateId: cleanList(birthStateIdErrors),
  });

  if (Object.keys(errors).length > 0) {
    const serverError = new Error('Bad request');

    serverError.response = { data: { error: errors } };
    throw serverError;
  }

  const method = 'post';
  const path = '/v2/investor/registers/confirm';
  const config = {
    path,
    method,
    extraHeaders: { password },
    body: snakeify({ profile: newProfile }),
  };
  const { data: profile } = await request(config);

  return profile ? camelify(profile) : null;
}

export default confirm;
