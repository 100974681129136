import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { extraLargeBreakPoint, midBreakPoint } = breakPoints;

const TopLeftBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
  border-bottom: 1px solid #e7edf3;
  color: #668d9d;
  &.funded {
    flex: 0;
  }
  .content {
    width: 31rem;
  }
  .fundedLineChart {
    margin-top: 4rem;
    p {
      color: #04475c;
      font-size: 1.2rem;
      margin-top: 1.5rem;
      b {
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: #346071;
      }
    }
    @media (max-width: ${extraLargeBreakPoint}px) {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    @media (max-width: ${midBreakPoint}px) {
      margin-top: 1rem;
      p {
        margin-top: 1rem;
        text-align: right;
        b:first-child {
          margin-left: 0.3rem;
        }
      }
    }
  }
`;

export default TopLeftBarContainer;
