import React from 'react';
import { Form, Field } from 'react-final-form';
import { Flex, Box } from 'styles';
import {
  Input,
  Button,
  InputGroup,
  FormControl,
  ArrowBackIcon,
  InputRightElement,

} from 'ui';
import SearchGroupContainer from './styles';

const SearchGroup = (props) => {
  const {
    onSearchByIdSubmit, onBackClick, className, ...rest
  } = props;

  const handleSearchSubmit = ({ zell_app_id: zellAppId }) => {
    onSearchByIdSubmit({ zell_app_id: zellAppId, page: 1 });
  };

  return (
    <SearchGroupContainer className={`search__group ${className || ''}`} {...rest}>
      <Form
        onSubmit={handleSearchSubmit}
        render={({ handleSubmit }) => (
          <Flex
            as="form"
            onSubmit={handleSubmit}
            w="100%"
            px="1rem"
            py="2rem"
            columnGap="1rem"
            justifyContent="space-between"
          >
            <Button
              variant="link"
              fontSize="lg"
              onClick={onBackClick}
              leftIcon={<ArrowBackIcon />}
              animationType="none"
              display={{ lg: 'none' }}
            >
              Regresar
            </Button>
            <Box justifySelf="flex-end">
              <Field name="zell_app_id" parse={(v) => v.replaceAll(/\D/g, '')}>
                {({ input, meta }) => (
                  <FormControl
                    maxWidth={80}
                    isDisabled={meta.submitting}
                    isInvalid={meta.invalid}
                  >
                    <InputGroup>
                      <Input
                        pr="24"
                        placeholder="Buscar por ID"
                        {...input}
                      />
                      <InputRightElement width="22">
                        <Button
                          size="md"
                          animationType="none"
                          borderTopLeftRadius={0}
                          borderBottomLeftRadius={0}
                          isDisabled={!input.value}
                          type="submit"
                        >
                          Buscar
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
              </Field>
            </Box>
          </Flex>
        )}
      />
    </SearchGroupContainer>
  );
};

export default SearchGroup;
