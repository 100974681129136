import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { Box } from 'styles';
import { useInputAnimation } from './animation';
import { FieldContent, Label, TextHelper } from './FieldBase.style';

export const getErrorMessage = (meta, { validateOn = 'blur' } = {}) => {
  const {
    error,
    touched,
    modified,
    submitError,
    dirtySinceLastSubmit,
  } = meta;
  const displayOn = { blur: touched, change: modified };
  const shouldDisplay = !dirtySinceLastSubmit && displayOn[validateOn] && (error || submitError);
  return shouldDisplay
    ? error || submitError
    : '';
};

const BaseField = forwardRef((props, ref) => {
  const {
    name,
    color,
    parse,
    format,
    validate,
    disabled,
    children,
    textLabel,
    validateOn,
    textHelper,
    labelProps,
    validateFields,
    breakTextHelper,
    textHelperProps,
    isTextHelperFloating,
    shouldDisplayTextHelper = true,
    ...rest
  } = props;

  const dataTestId = props['data-testid'] || name;

  const { input, meta } = useField(name, {
    parse,
    format,
    validate,
    validateFields,
    defaultValue: '',
  });
  const errorMessage = getErrorMessage(meta, { validateOn });

  const style = useInputAnimation({
    isActive: meta.active || !meta.pristine,
    hasError: !!errorMessage,
    isFocused: meta.active,
  });

  const textErrorProps = {};
  if (textHelper) textErrorProps.mb = '0';
  if (isTextHelperFloating) textErrorProps.position = 'absolute';
  if (isTextHelperFloating) textErrorProps.top = '100%';

  return (
    <FieldContent position="relative" disabled={disabled} ref={ref} {...rest}>
      <Label color={color} data-testid={`${dataTestId}-label`} {...labelProps}>
        {textLabel && <Box as="span" mb="0.5rem">{textLabel}</Box>}
        {children({
          meta, hasError: !!errorMessage, input, animationStyle: style,
        })}
      </Label>
      {shouldDisplayTextHelper && errorMessage && (
        <TextHelper
          whiteSpace={breakTextHelper ? 'break-spaces' : 'nowrap'}
          data-testid={`${dataTestId}-textHelper`}
          {...textHelperProps}
          {...textErrorProps}
        >
          {errorMessage}
        </TextHelper>
      )}
      {textHelper}
    </FieldContent>
  );
});

BaseField.defaultProps = {
  textLabel: '',
  format: (v) => v,
  parse: (v) => v,
  colorLabel: null,
  breakTextHelper: false,
  textHelper: null,
};

BaseField.propTypes = {
  name: PropTypes.string.isRequired,
  textLabel: PropTypes.string,
  colorLabel: PropTypes.string,
  format: PropTypes.func,
  parse: PropTypes.func,
  breakTextHelper: PropTypes.bool,
  textHelper: PropTypes.element,
};

export default BaseField;
