import { useInterval } from './useInterval';

export const useTimer = ({
  time,
  onFinish,
} = {}) => {
  const {
    time: timeLeft,
    reinitialize,
    finished,
    setTime,
  } = useInterval({
    onFinish,
    interval: time,
    initialTime: time,
  });

  return {
    time: timeLeft,
    reinitialize,
    finished,
    setTime,
  };
};
