import { FETCH_USER_SIGNED_CONTRACTS } from 'actions/settings/accountProfile/types';
import * as API from 'apis/investor/contract';
import { createAsyncAction } from 'utils/redux-query';

export const fetchContract = createAsyncAction('CONTRACT', () => () => API.fetch());

export const updateContract = createAsyncAction(
  'UPDATE_CONTRACT',
  (...params) => async (dispatch) => {
    const result = await API.update(...params);

    dispatch({
      type: FETCH_USER_SIGNED_CONTRACTS,
      payload: null,
    });

    return result;
  },
);
