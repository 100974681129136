import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { largeBreakPoint } = breakPoints;

const greyColor = '#677D85';
const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const InfoBarContainer = styled(ContainerDiv)`
  .controls_area {
    display: flex;
    background-color: white;
    .options {
      margin-top: 6px;
      margin-bottom: 8px;
      margin-left: 10px;
      display: none;
      @media (max-width: ${largeBreakPoint}px) {
        display: block;
      }
    }
  }
  .contentArea {
    border-bottom: 1px solid #e7ebef;
    &__lastUpdate {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: ${greyColor};
      background-color: white;
      font-size: 1.1rem;
      & p {
        padding-top: 5px;
        margin: 1.5rem 2rem;
      }
    }
    &__information__chart__container {
      display: flex;
      background-color: white;
      &__charts {
        display: flex;
        width: 75%;
        justify-content: space-evenly;
        @supports (-ms-ime-align: auto) {
          justify-content: space-around;
        }
        & .chart {
          width: 8rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          &__title {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
          }
          &__body {
            color: #04475c;
            &__content {
              width: 80%;
              margin: 0 auto;
              &__quantity {
                font-size: 1.5rem;
                font-weight: bold;
              }
              &__percentage {
                font-size: 0.9rem;
              }
            }
            &__amount {
              margin-top: 1rem;
              font-size: 1.4rem;
              text-align: center;
            }
          }
        }
      }
      &__infoWidget {
        display: flex;
        width: 25%;
        min-width: 178px;
        padding: 1rem 2rem;
        & .infoWidget {
          &__body {
            color: #04475c;
            display: flex;
            background-color: #f2f5f7;
            height: 100%;
            width: 100%;
            border-radius: 6px;
            &__section {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-left: 5px;
              padding-right: 5px;
              &__quantity {
                font-size: 1.5rem;
              }
              &__description {
                font-size: 1.1rem;
              }
              &:first-of-type {
                border-right: 1px solid #c2d4db;
              }
            }
          }
        }
      }
    }
    &__description {
      background-color: white;
      padding: 3rem;
      margin-bottom: 3rem;
      & p {
        font-size: 11px;
        color: #93afb9;
      }
    }
  }
`;

export default InfoBarContainer;
