import React, { useMemo, useRef } from 'react';
import { tscope, toCurrency, toPercentage } from 'i18n';
import ContentLoader from 'react-content-loader';
import { useReduxQuery } from 'utils/redux-query';
import { ReactComponent as WonIcon } from 'assets/wonIconModal.svg';
import { useSelector } from 'react-redux';
import { Flex } from 'styles';
import { Text } from 'components/Typography';
import Button from 'components/Button/Button';
import isNil from 'utils/isNil';
import Tooltip from 'react-tooltip';
import ProfitInfoContainer from './styles';
import Card from '../../../../../Card';

const t = tscope('Dashboard.Summary.activeState.widgets.profit');

const parseProfit = (statement) => {
  if (!statement?.accumulated) return null;

  const { acumulado, acumuladoIva } = statement.accumulated.find(
    (element) => element.concepto === 'ResNetTot',
  );

  return parseFloat(acumulado) + parseFloat(acumuladoIva);
};

const Loader = () => (
  <ContentLoader height={70} width={200} speed={2} primaryColor="#F2F5F7" secondaryColor="#E7EBEF">
    <rect x="40" y="50" rx="5" ry="5" width="120" height="10" />
    <rect x="40" y="0" rx="5" ry="5" width="120" height="10" />
  </ContentLoader>
);

const InfoCard = ({
  tooltipText = '',
  title,
  value,
  loading,
  className,
  titleClass,
  ...contentProps
}) => {
  const tooltipRef = useRef();

  return (
    <div
      ref={tooltipRef}
      className={className}
      {...contentProps}
    >
      <Card data-tip={tooltipText}>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <h5>{value}</h5>
            <p className={titleClass}>{title}</p>
          </div>
        )}
        {tooltipText && <Tooltip effect="solid" />}
      </Card>
    </div>
  );
};

const ProfitDetail = (props) => {
  const { handleModal } = props;
  const isMobile = useSelector(({ browser }) => browser.lessThan.mobile);

  if (isMobile) return <span>{t('profited.title')}</span>;

  return (
    <Button
      type="button"
      $type="link"
      $isNotAnimated
      fontSize="1.4rem"
      colorSchema="green"
      display="flex"
      columnGap="0.5rem"
      onKeyPress={() => handleModal('detailWon')}
      onClick={() => handleModal('detailWon')}
    >
      <span>{t('profited.title')}</span>
      <Text as={WonIcon} color="inherit" w="14px" h="14px" />
    </Button>
  );
};

const ProfitInfo = (props) => {
  const { handleModal } = props;
  const [accountInvestments, { status: investmentsStatus }] = useReduxQuery(
    'FETCH_ACCOUNT_INVESTMENTS',
  );
  const [accountStatements, { status: statementsStatus }] = useReduxQuery(
    'FETCH_ACCOUNT_STATEMENTS',
  );

  const totalProfit = useMemo(() => parseProfit(accountStatements), [accountStatements]);
  const averageRate = accountInvestments?.averageRate;
  const {
    recursosFondeadosCuenta: fundedResources,
    prestamosRealizados: loansMade,
  } = accountStatements || { recursosFondeadosCuenta: 0, prestamosRealizados: 0 };

  return (
    <Flex
      bgColor="white"
      borderRadius="0.4rem"
      boxShadow="0 5px 12px 0 rgba(38, 57, 69, 0.09)"
      as={ProfitInfoContainer}
      gap="1rem"
    >
      <InfoCard
        className="info cardGrey"
        title={t('funding.title')}
        value={fundedResources && toCurrency(fundedResources)}
        loading={statementsStatus.pending || accountStatements?.caching}
      />
      <InfoCard
        className="info cardGrey"
        title={t('lent.title')}
        tooltipText={t('lent.tooltip')}
        value={loansMade && toCurrency(loansMade)}
        loading={statementsStatus.pending || accountStatements?.caching}
      />
      {!isNil(totalProfit) && totalProfit > 0 && (
        <InfoCard
          titleClass="wonModal"
          className="info cardGreen"
          value={toCurrency(totalProfit)}
          title={<ProfitDetail handleModal={handleModal} />}
        />
      )}
      <InfoCard
        className="info cardBlue"
        title={t('rate.title')}
        value={toPercentage(averageRate || 0, { precision: 2 })}
        loading={investmentsStatus.pending || accountInvestments?.caching}
      />
    </Flex>
  );
};

export default ProfitInfo;
