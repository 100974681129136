export const getAutoinvestTerms = ({
  riskImgUrl,
}) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Términos y condiciones de Autoinvest</title>
  <style>
    html {
      font-size: 16px;
      line-height: 1.5;
      font-family: 'Arial', sans-serif;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    table, th, td {
      border-top: 1px solid gray;
      padding: 10px;
      text-align: center;
    }
  </style>
</head>
<body>
  <div className="terms-and-conditions-container">
    <div className="text-container">
      <section>
        <div className="content">
          <p>
            Estimado Inversionista a partir de este momento eres candidato a participar en el
            esquema de Inversiones Automáticas (en lo sucesivo “<b>Autoinvest</b>”) que tienen
            como objeto el otorgar a <b>Comunidad de Préstamos S.A.P.I de C.V., Institución de Financiamiento Colectivo</b>
            (Yotepresto.com) la comisión o mandato para que en tu nombre y representación
            realicemos inversiones de forma automática, lo anterior derivado de los recursos que
            provengan de los pagos efectuados por los financiamientos u operaciones no
            formalizadas anteriormente realizadas en nuestra plataforma podrán ser invertidos de
            forma automática en aquellos solicitantes que cuenten con las características, forma
            y términos que el Inversionista haya seleccionado de forma previa a la aceptación de
            estos términos y condiciones, es decir al momento de activar la modalidad del
            Autoinvest, incluidos los criterios a utilizar en la selección de Solicitantes,
            nivel de riesgo, monto o porcentaje.
          </p>
          <p>
            Las actividades y uso de los Servicios de Yotepresto.com se encuentran reguladas de
            conformidad con la Ley para Regular las Instituciones de Tecnología Financiera y las
            Disposiciones de Carácter General Aplicables a las Instituciones de Tecnología
            Financiera por lo que acuerdas leer los presentes términos y condiciones obligándote
            a su cumplimiento al ingresar a la Plataforma, manifestando tu consentimiento y
            aceptación del presente acuerdo, con independencia a la formalización de las
            operaciones de financiamiento colectivo con Yotepresto.com que requerirán el
            consentimiento y manifestación expresa de la voluntad de forma independiente al
            presente acuerdo, sujetos en todo momento al cumplimiento de las Disposiciones de
            carácter general de la Comisión Nacional para la Protección y Defensa de los
            Usuarios de Servicios Financieros en materia de transparencia y sanas prácticas
            aplicables a las instituciones de tecnología financiera.
          </p>
          <p>
            Hacemos del conocimiento del Inversionista que ni el Gobierno Federal ni las
            entidades de la administración pública paraestatal podrán responsabilizarse o
            garantizar los recursos de los Clientes que sean utilizados en las Operaciones que
            celebren con Yotepresto.com o frente a otros, así como tampoco asumirán alguna
            responsabilidad por las obligaciones contraídas por Yotepresto.com o por algún
            Cliente frente al Inversionista, en virtud de las Operaciones que celebren contigo
            como Inversionista.
          </p>
          <p>Al activar el Autoinvest automáticamente obtienes los siguientes beneficios:</p>
          <p>
            <b>1. </b>
            Automatización del proceso de “fondeo” el cual consiste en el proceso
            mediante el cual varios inversionistas como tú deciden destinar cierta cantidad de
            recursos a un solicitante de un préstamo para que, entre los Inversionistas, se
            complete dicha solicitud de préstamo. La ventaja de utilizar la modalidad de
            Autoinvest es que no necesitarás acceder a la plataforma de Yotepresto.com para
            hacer tus préstamos, ya que estás de acuerdo en que estos se realicen de manera
            automática únicamente cuando cumplan con los términos y condiciones que se indican
            más adelante, los cuales se te informarán a través de la plataforma en tu sesión de
            usuario cuando hayan quedado firmes y con las especificaciones de cada uno, como los
            montos, plazo y cualquier información relacionada y podrás consultar o revocar esta
            funcionalidad en cualquier momento en la función de “Activación del Autoinvest”, a
            través de la plataforma de Yotepresto.com, así mismo para el caso de que se llevara
            cobranza extrajudicial se informara el detalle de la misma;
          </p>
          <p>
            <b>2. </b>
            No perderás la oportunidad de invertir en las solicitudes de préstamos que
            se ajusten a tus preferencias de inversión;
          </p>
          <p>
            <b>3. </b>
            La posibilidad de hacer inversiones automáticas o AutoInvest a solicitudes
            que por estadística se tardan más en fondear, pero que al activar el Autoinvest, se
            volverán atractivas ya que su porcentaje de fondeo abarcará un porcentaje fondeado
            en las primeras horas del día en un rango entre el 50% o 60%.
          </p>
        </div>
        <div>
          <h3>Funcionamiento:</h3>
          <p>
            Para poder acceder o activar la función de Autoinvest es necesario que previamente
            cuentes con el contrato y autorización respectiva de participación como
            inversionista para posteriormente poder aceptar los términos y condiciones del
            funcionamiento del Autoinvest. Realizado lo anterior, el proceso de Autoinvest
            entrará en funcionamiento automáticamente cada vez que una solicitud de préstamo
            entre al status de &quot;Fondeo&quot;
            <i>
              (cuando un usuario solicitante de un préstamo ha aceptado la propuesta que le
              envió Yotepresto.com y su solicitud ha sido puesta a disposición de los
              inversionistas para que estos a su vez inviertan).
            </i>
          </p>
          <p>
            <i>
              * Para los casos en los que sean varios los inversionistas con el autoinvest
              activado aplicará un esquema de orden de conformidad con al fecha de activación.
            </i>
          </p>

          <h3>Montos mínimos de solicitudes de préstamo o fondeo:</h3>
          <p>
            <b>a)</b>
            El Autoinvest podrá fondear en su totalidad aquellas solicitudes de
            préstamo por medio de las cuales se solicite la cantidad de $55,000 (cincuenta y
            cinco mil pesos 00/100 moneda nacional) o más.
          </p>
          <p>
            <b>b)</b>
            Para aquellas solicitudes de préstamo en las cuales se solicite menos de
            $55,000 (cincuenta y cinco mil pesos 00/100 moneda nacional) solamente podrán ser
            fondeadas en un 50% (cincuenta por ciento) (
            <b>e.g.</b>
            <i>
              una solicitud de préstamo de $10,000.00 diez mil pesos 00/100 moneda nacional
              unicamente podría ser fondeada por medio del Autoinvest hasta por un monto máximo
              de $5,000.00 cinco mil pesos 00/100 moneda nacional.)
            </i>
          </p>

          <h3>Criterios de Selección de Inversionistas</h3>
          <p>
            El Autoinvest únicamente podrá ser activado por aquellos inversionistas en los que
            el valor de su cuenta en su perfil de usuario sea de al menos la cantidad de
            $20,000.00 (veinte mil pesos 00/100 moneda nacional) considerando;
            <i>
              <b>I)</b>
              la cantidad de dinero disponible;
              <b>II)</b>
              la que se encuentra en
              proceso de Fondeo; y
              <b>III)</b>
              lo que ya haya sido invertido o prestado (en lo
              sucesivo el &quot;
              <b>Valor de la Cuenta</b>
            </i>
            &quot;. Si en cualquier momento el Valor de la Cuenta disminuye, el Autoinvest se
            suspenderá o quedará en stand-by automáticamente y dejará de invertir de forma
            automática. El Autoinvest podrá reactivarse cuando se vuelva a contar con recursos
            suficientes en la Cuenta.
          </p>
          <p>
            El Inversionista podrá desactivar en cualquier momento el Autoinvest en el dashboard
            de su perfil de usuario el cual estará siempre de forma visible.
          </p>

          <h3>Calificación y porcentaje de riesgo:</h3>
          <p>
            A1 – A7 (8.9% - 14.9%); B1 – B7 (15.9% - 21.9%); C1 – C7 (22.9% - 38.9%); o Todos
            los anteriores.
          </p>
          <p>
            El porcentaje asignado a cada letra corresponde a la tasa de interés a cobrarse
            conforme la calificación de riesgo obtenido.
          </p>

          <h3>Plazo:</h3>
          <p>6, 12, 18, 24, 30, 36 meses o todos los anteriores. </p>

          <h3>Destino:</h3>
          <p>
            Automóvil, Educación, Gastos Familiares, Gastos Personales, Negocio, Otros, Pagar
            deudas, Vivienda o todas las anteriores.
          </p>

          <h3>
            Montos a invertir o
            <i>
              “tickets” (cantidad de dinero que un inversionista seleccionó para ser invertida):
            </i>
          </h3>
          <p>
            Los tickets podrán ser de $200.00 (doscientos pesos 00/100 moneda nacional); $500.00
            (quinientos pesos 00/100 moneda nacional); $700.00 (setecientos pesos 00/100 moneda
            nacional); $1,000.00 (mil pesos 00/100 moneda nacional) o de la cantidad que tu como
            inversionista elijas siempre y cuando sea un múltiplo de 100 (cien) y que sea mayor
            a $200.00 (doscientos pesos 00/100 moneda nacional).
            <i>
              <b>e.g.</b>
              si tu como inversionista escoges fondear por Autoinvest la cantidad de
              $10,000 (diez mil pesos 00/100 moneda nacional) y a una solicitud de préstamo le
              faltan $9,900.00 (nueve mil novecientos pesos 00/100 moneda nacional) el
              autoinvest no se aplicará a dicha solicitud ya que el ticket debe de ser
              exactamente de los diez mil pesos.
            </i>
          </p>
          <p>
            El autoinvest no podrá invertir más de un ticket en una misma persona que este
            solicitando un préstamo.
            <i>
              (
              <span>e.g.</span>
              si se selecciona el ticket de $200.00 (doscientos pesos 00/100
              m.n.), el autoinvest invertirá automaticamente en diversos deudores que cumplan
              con los requerimientos de selección pero nunca mas de un ticket a un mismo deudor
              ni por una cantidad distinta a los doscientos pesos).
            </i>
          </p>

          <h3>Funcionamiento del Algoritmo.</h3>
          <p>
            Cada que una solicitud de préstamo de un potencial cliente de Yotepresto.com entre
            al estatus de fondeo y cumpla con las reglas de solicitud, se realizará el proceso
            de Autoinvest, donde los inversionistas podrán fondear estas solicitudes,
            considerando lo siguiente:
          </p>
          <p>
            Se utilizará el método de First Input - First Output (“
            <b>
              <span style="text-decoration: underline;">FIFO</span>
            </b>
            ”), basado en la fecha en que haya sido activado el Autoinvest o en su caso cuando
            se haya reactivado.
          </p>
          <p>
            <i>
              <b>Ejemplo:</b>
              Se tienen 5 inversionistas, cada uno de ellos seleccionó
              previamente en el Autoinvest: I) la calificación y porcentaje de riesgo; II) el
              plazo; III) el destino; y IV) el monto de los tickets a invertir:
            </i>
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <i>Juan</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa A</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Pablo</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa C</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Pedro</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>18 meses</i>
                </td>
                <td>
                  <i>Tasa A y C</i>
                </td>
                <td>
                  <i>Pagar deudas</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>José</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa C</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Jesús</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>18 meses</i>
                </td>
                <td>
                  <i>Tasa B</i>
                </td>
                <td>
                  <i>Educación</i>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Se tiene en proceso una solicitud presentada por un usuario que esta solicitando un
            préstamo con las siguientes características:
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <i>Plazo 12 meses</i>
                </td>
                <td>
                  <i>Tasa C</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            El orden de inversión quedaría en primer lugar Pablo y en segundo lugar José. Al
            finalizar se tendría un nuevo orden de inversionistas de la siguiente forma:
          </p>

          <table>
            <tbody>
              <tr>
                <td>
                  <i>Juan</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa A</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Pedro</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>18 meses</i>
                </td>
                <td>
                  <i>Tasa A y C</i>
                </td>
                <td>
                  <i>Pagar deudas</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Jesús</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>18 meses</i>
                </td>
                <td>
                  <i>Tasa B</i>
                </td>
                <td>
                  <i>Educación</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>Pablo</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa C</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
              <tr>
                <td>
                  <i>José</i>
                </td>
                <td>
                  <i>Plazo</i>
                </td>
                <td>
                  <i>12 meses</i>
                </td>
                <td>
                  <i>Tasa C</i>
                </td>
                <td>
                  <i>Negocio</i>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Metodología de riesgos:</h3>
          <p>
            El nivel de riesgo que tienen las inversiones efectuadas conforme al esquema del
            Autoinvest dependerá del segmento que sea seleccionado entre las letras “A”, “B”, y
            “C” (calificación de riesgo asignada por Yotepresto.com que podrá estar segmentada a
            su vez por números del 1 al 7) de conformidad con la siguiente tabla:
          </p>

          <div>
            <img src=${riskImgUrl} alt="Niveles de riesgo" width="100%" />
          </div>
          <h3>Revocación:</h3>
          <p>
            Yotepresto.com mantendrá disponible para ti en todo momento la revocación del
            presente acuerdo, para lo cual bastará la solicitud realizada, para dejar sin
            efectos el presente documento.
          </p>
        </div>
      </section>
    </div>
  </div>
</body>
</html>
`;
