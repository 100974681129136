import React from 'react';
import { t } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import { Flex } from 'styles';
import MobilePortfolioListContainer from './styles';
import { Item } from './components';
import EmptyState from '../../../EmptyState';

export const List = () => {
  const [portfolio, { status: portfolioStatus }] = useReduxQuery('PORTFOLIO');

  if (!portfolioStatus.completed) return null;

  const { collection } = portfolio;

  const IdColumnWidth = collection.reduce(
    (acc, requisition) => (requisition.tags?.length > acc ? requisition.tags.length : acc),
    0,
  );

  const IdColumnBasis = IdColumnWidth > 0
    ? {
      base: 0,
      xs: `${IdColumnWidth * 4}rem`,
      sm: `${IdColumnWidth * 4}rem`,
      md: 'auto',
    }
    : null;

  if (collection.length > 0) {
    return (
      <MobilePortfolioListContainer className="mobile__list">
        <div className="mobile__list__body">
          <div className="list__body__header">
            <Flex className="header__item id" grow="0 !important" flexBasis={IdColumnBasis}>
              {t('common.id')}
            </Flex>
            <div className="header__item status">{t('common.status')}</div>
            <div className="header__item lent">{t('common.lent')}</div>
            <div className="header__item owes">{t('common.owesYou')}</div>
            <div className="header__item user">{t('common.user')}</div>
          </div>
          <div className="list__body__content">
            {collection.map((requisition) => (
              <Item
                detail={requisition}
                key={requisition['@diffgr:id']}
                IdColumnBasis={IdColumnBasis}
              />
            ))}
          </div>
        </div>
      </MobilePortfolioListContainer>
    );
  }
  return <EmptyState noResults />;
};
