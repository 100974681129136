import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  USER_STATUSES,
  WS_EVENT_NAMES,
} from 'config/constants';
import {
  createActions,
  useReduxSubscription,
} from 'utils/redux-query';
import { parseEventMessage } from 'utils/parseWSEventMessages';

const {
  update: updatePortfolio,
} = createActions('PORTFOLIO');

export const useBindUpdatingPortfolio = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [session] = useReduxSubscription('SESSION');
  const isUserBlocked = session?.user?.status === USER_STATUSES.BLOCKED;

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const { ready } = data;
    if (!ready) return;
    const payload = { ready: true };
    const meta = { status: 'COMPLETED', error: null };
    dispatch(updatePortfolio(payload, meta));
  }, [
    dispatch,
  ]);

  useEffect(() => {
    if (isUserBlocked) return () => {};
    return bindEvent(
      WS_EVENT_NAMES.PORTFOLIO_UPDATING,
      callback,
    );
  }, [
    callback,
    bindEvent,
    isUserBlocked,
  ]);
};
