import { useCatalog } from './graphql';

export const useGetCountryById = () => {
  const { data, loading } = useCatalog();
  const countries = data?.catalogs?.countries || [];

  const getCountryById = (countryId) => {
    const country = countries?.find((c) => c.id === countryId);
    return country;
  };

  const getCountryNameByName = (countryName) => {
    const country = countries?.find((c) => c.name === countryName);
    return country;
  };

  return {
    getCountryById,
    getCountryNameByName,
    isLoading: loading,
  };
};
