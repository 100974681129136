import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { isRequired } from 'utils/Validators/common';
import { t } from 'i18n';

const tOptions = { scope: 'expedientsProfileUpdate.curp' };

function validator(values) {
  const { curp, homoClave } = values;
  let errorCurp = null;
  let errorHomo = null;

  if (homoClave?.length < 2) {
    errorHomo = t('form.curpField.errors.length', { ...tOptions, length: 2 });
  }

  if (!homoClave) {
    errorHomo = t('form.curpField.errors.requiredHomoClave', tOptions);
  }

  if (curp?.length < 16) {
    errorCurp = t('form.curpField.errors.lengthCurp', tOptions);
  }

  if (!curp) {
    errorCurp = isRequired(curp);
  }

  return clearErrorsObject({
    curp: errorCurp,
    homoClave: errorHomo,
  });
}

export default validator;
