import { useCallback, useEffect, useMemo } from 'react';
import { t } from 'i18n';
import { useReduxMutator } from 'utils/redux-query';
import { showToastError, showToastSuccess } from 'utils/toastHandler';
import useTimer from 'utils/useTimer';
import { OTP_TYPES, RESEND_SMS_REMAINING_TIME, STORAGE_KEYS } from 'config/constants';
import { useLocalStorage } from 'utils/webStorage';

const toasts = (id, options) => t(
  id,
  { scope: 'Dashboard.activate2FAWizard.steps.toasts', ...options },
);

const onGenerateOTPSuccess = () => {
  showToastSuccess(toasts('codeGeneratedSuccessfuly'));
};

const onGenerateOTPError = () => {
  showToastError(toasts('generateCodeFailed'));
};

export const useOTPTimer = () => {
  const [
    otpExpiryLeftTime,
    setOtpExpiryLeftTime,
  ] = useLocalStorage(STORAGE_KEYS.OTP_EXPIRY_LEFT_TIME);

  const { humanize, reinitialize, time } = useTimer(0, {
    onFinish: () => { setOtpExpiryLeftTime(null); },
  });
  const remainingTime = useMemo(() => {
    if (otpExpiryLeftTime === undefined) return undefined;
    if (!otpExpiryLeftTime) return 0;
    const currentTime = new Date().getTime();
    const lifeSpanOTP = currentTime - otpExpiryLeftTime;
    const remainingTimeLeft = RESEND_SMS_REMAINING_TIME - lifeSpanOTP;
    const isValidRange = remainingTimeLeft > 0 && remainingTimeLeft <= RESEND_SMS_REMAINING_TIME;
    if (isValidRange) return remainingTimeLeft;
    return 0;
  }, [otpExpiryLeftTime]);

  useEffect(() => {
    if (remainingTime === undefined) return;
    if (remainingTime <= 0) setOtpExpiryLeftTime(null);
    else reinitialize(remainingTime);
  }, [
    reinitialize,
    remainingTime,
  ]);

  return {
    time,
    humanize,
    setOtpExpiryLeftTime,
  };
};

export const useGenerateOTP = () => {
  const [
    generateOTP,
    {
      status,
    },
  ] = useReduxMutator(
    'GENERATE_OTP',
    {
      onSuccess: onGenerateOTPSuccess,
      onError: onGenerateOTPError,
    },
  );
  const {
    time,
    humanize,
    setOtpExpiryLeftTime,
  } = useOTPTimer();

  const loading = status.pending;
  const remainingSeconds = humanize.seconds;

  const sendToSMS = useCallback(
    async () => {
      setOtpExpiryLeftTime(new Date().getTime());
      await generateOTP(OTP_TYPES.SMS);
    },
    [OTP_TYPES.SMS, generateOTP],
  );

  const sendToEmail = useCallback(
    () => generateOTP(OTP_TYPES.EMAIL),
    [OTP_TYPES.EMAIL, generateOTP],
  );

  return {
    time,
    loading,
    sendToSMS,
    sendToEmail,
    remainingSeconds,
  };
};
