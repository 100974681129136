const config = {
  defer: true,
  zendeskKey: process.env.REACT_APP_ZENDESK_KEY,
  settings: {
    color: { theme: '#0076FF', launcherText: '#FFFFFF' },
  },
  enable: true,
};

export default config;
