import React from 'react';
import { Feature } from 'utils/featureFlags';
import CompleteExpedientsBanner from 'views/DashboardContent/Expedients2/Alerts/CompleteExpedientsBanner';
import { Flex } from 'styles';
import SignCERNotice from 'components/Banners/SignCERNotice';

export const ALERTS = [
  {
    id: 'expedients',
    Component: (props) => (
      <Feature name="needsUpdateInformation">
        <Flex {...props}>
          <CompleteExpedientsBanner />
        </Flex>
      </Feature>
    ),
  },
  {
    id: 'cer',
    Component: (props) => (
      <Feature name="cer">
        <Flex {...props}>
          <SignCERNotice />
        </Flex>
      </Feature>
    ),
  },
];
