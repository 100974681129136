import React from 'react';
import { AnimoZapopanContainer } from './styles';

const AnimoZapopan = () => (
  <AnimoZapopanContainer>
    <div className="container">
      <h1>¡Ánimo Zapopan!</h1>
      <p>
        Debido a la pandemia de COVID-19 y sus afectaciones en la economía,
        {' '}
        el Gobierno de Zapopan lanzó el programa de reactivación económica
        {' '}
        <b>¡Ánimo, Zapopan!</b>
        , que busca apoyar a personas y pequeños negocios del municipio.
      </p>
      <p>
        Uno de los pilares de este programa es el financiamiento para impulsar pequeños negocios,
        {' '}
        y fortalecer el consumo de bienes y servicios. Para ello, en yotepresto.com firmamos un
        {' '}
        Contrato de Colaboración en Financiamiento con el Gobierno de Zapopan con el objetivo de
        {' '}
        ofrecer financiamiento en las mejores condiciones de costo, tiempos de respuesta,
        {' '}
        seguridad y transparencia.
      </p>
      <h3>
        ¿En qué consiste esta colaboración?
      </h3>
      <p>
        A través de yotepresto.com se otorgarán préstamos personales a los habitantes y dueños
        {' '}
        de pequeños negocios de Zapopan; esto, por medio de nuestra plataforma y esquema de
        {' '}
        financiamiento colectivo con el increíble beneficio para nuestros inversionistas de contar
        {' '}
        con el respaldo del Gobierno de Zapopan para cubrir potenciales pérdidas por cartera
        {' '}
        vencida.
      </p>
      <p>
        El Gobierno de Zapopan constituirá un Fondo de Contingencia Líquida a Primeras Pérdidas
        {' '}
        por un monto de 3 millones de pesos destinado a garantizar las cuotas de capital de
        {' '}
        aquellos créditos otorgados en Zapopan que caigan en cartera vencida (más de 90 días)
        {' '}
        para ser pagados a los inversionistas.
      </p>
      <p>
        El objetivo principal de esta colaboración es potenciar el alcance de los recursos con
        {' '}
        los que cuenta el municipio. Es decir, en lugar de sólo prestar directamente 3 millones
        {' '}
        de pesos, el municipio aportará esos recursos como garantías a primeras pérdidas a un
        {' '}
        fideicomiso público para que, a través de yotepresto.com, coloquemos una cantidad
        {' '}
        objetivo de 30 millones de pesos en préstamos.
      </p>
      <h3>
        ¿Cómo funcionará el esquema de garantías?
      </h3>
      <ol>
        <li>
          Recibiremos las solicitudes de crédito, que serán analizadas con el mismo proceso, modelo
          {' '}
          de riesgos y tasas con el que analizamos las solicitudes que recibimos normalmente.
          {' '}
          Los solicitantes deberán contar con buen perfil crediticio para ser autorizados,
          {' '}
          incluyendo tener buen historial crediticio, ingresos comprobables, etcétera.
        </li>
        <li>
          Las solicitudes autorizadas serán publicadas de forma normal para ser fondeadas por
          {' '}
          los inversionistas. En la sección de
          {' '}
          <b>Ir a prestar</b>
          {' '}
          se identificarán con el símbolo
          {' '}
          <span className="example">AZ!</span>
          , distintivo del programa ¡Ánimo, Zapopan!, lo que significa que formarán parte de
          {' '}
          los créditos que entran dentro del programa de garantías a primeras pérdidas.
        </li>
        <li>
          En caso de que uno de los acreditados que forman parte del programa se atrase en sus pagos
          {' '}
          por más de 90 días, yotepresto.com solicitará al Fideicomiso del Municipio de Zapopan
          {' '}
          el depósito de las garantías para respaldar el saldo de capital de las cuotas vencidas.
        </li>
        <li>
          Cada mes, el fideicomiso depositará a yotepresto.com los recursos de los préstamos
          {' '}
          vencidos en un plazo comprometido de 15 días hábiles después de haber sido solicitados
          {' '}
          para cubrir el saldo de capital de las cuotas vencidas. El monto máximo para cubrir
          {' '}
          pérdidas será de 3 millones de pesos.
        </li>
        <li>
          Las garantías no serán aplicadas como un pago directo al préstamo, sino como una
          {' '}
          transacción de abono a tu cuenta en yotepresto.com (como si hubieras agregado
          {' '}
          fondos a tu cuenta). Detallaremos cada transacción de pago de garantías en la sección
          {' '}
          <b>Mis movimientos</b>
          , identificando cada una con el número del préstamo al que corresponde la garantía
          {' '}
          abonada en la columna
          {' '}
          <b>Referencia</b>
          .
        </li>
      </ol>
      <p>
        En yotepresto.com nos sentimos honrados y orgullosos por la oportunidad de colaborar
        {' '}
        con la reactivación económica del Municipio de Zapopan aportando nuestra tecnología y
        {' '}
        modelo de operación y al mismo tiempo ofrecer a nuestros usuarios nuevas oportunidades
        {' '}
        para hacer crecer su dinero.
      </p>
    </div>
  </AnimoZapopanContainer>
);

export default AnimoZapopan;
