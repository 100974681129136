import { request } from '../fetchers/yotepresto';

export const logout = () => {
  const method = 'delete';
  const path = '/auth/sign_out';
  const config = { method, path };
  return request(config).catch(() => false);
};

export const fetchUserSessions = async () => {
  const path = '/v1/user_sessions';
  const method = 'get';
  const config = { method, path };
  const response = await request(config);
  return response;
};
