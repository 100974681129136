import {
  css,
  styled,
  colors,
  textHelper,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

export const FieldContent = styled.div.withConfig({ shouldForwardProp })`
  display: flex;
  flex-direction: column;

  ${(props) => (props.disabled
    ? css`
          opacity: 0.5;
        `
    : '')}

  ${boxHelpers}
`;

export const TextHelper = styled.p`
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${colors.danger};
  margin-bottom: 1rem;
  white-space: nowrap;
  ${textHelper}
`;

export const Label = styled.label`
  color: ${colors.schema.gray[700]};
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  ${textHelper}
`;
