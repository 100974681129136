import * as Sentry from '@sentry/browser';
import {
  getCrashReporterConfig,
} from 'config';

function CrashReporter() {
  return Sentry;
}

export const init = () => {
  const config = getCrashReporterConfig();
  CrashReporter().init(config);
};

export const setUser = (user) => {
  CrashReporter().setUser(user);
};

export const setContext = (context) => {
  CrashReporter().setContext('context', context);
};

export const captureInfoMessage = (message, context = {}) => {
  CrashReporter().setContext('context', context);
  CrashReporter().captureMessage(message, context);
};

export const captureErrorMessage = (message, context = {}) => {
  CrashReporter().setContext('context', context);
  CrashReporter().captureMessage(new Error(message));
};
