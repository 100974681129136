import {
  TextField as TextFieldUI,
  SelectField as SelectFieldUI,
  DateField as DateFieldUI,
  OTPField as OTPFieldUI,
  CheckboxField as CheckboxFieldUI,
  RadioButtonGroupField as RadioButtonGroupFieldUI,
  PhoneField as PhoneFieldUI,
  FileField as FileFieldUI,
  Editable,
  ButtonCheckboxGroup as ButtonCheckboxGroupUI,
} from 'ui';
import {
  connectControlField,
  connectPhoneField,
} from './connectFieldHOC';

export const OTPField = connectControlField(OTPFieldUI);
export const TextField = connectControlField(TextFieldUI);
export const DateField = connectControlField(DateFieldUI);
export const PhoneField = connectPhoneField(PhoneFieldUI);
export const SelectField = connectControlField(SelectFieldUI);
export const RadioButtonGroupField = connectControlField(RadioButtonGroupFieldUI);
export const ButtonCheckboxGroup = connectControlField(ButtonCheckboxGroupUI,
  { isCheckboxGroup: true });
export const EditableField = connectControlField(Editable);
export const CheckboxField = connectControlField(CheckboxFieldUI,
  { defaultValue: false, isCheckboxGroup: true });
export const FileField = connectControlField(FileFieldUI,
  { defaultValue: '', isFile: true });
