import React, {
  forwardRef,
} from 'react';
import { isTesting } from 'config/index';
import { useFieldController } from './hooks/useFieldController';

export const connectControlField = (
  FieldComponent,
  {
    defaultValue = '',
    isFile = false,
    isCheckboxGroup = false,
  } = {},
) => forwardRef(({
  name,
  control,
  parseType = '',
  onChange,
  ...props
}, ref) => {
  const {
    ref: formRef,
    ...fieldProps
  } = useFieldController({
    name,
    isFile,
    control,
    onChange,
    parseType,
    defaultValue,
    isCheckboxGroup,
  });
  const handleRef = (node) => {
    formRef(node);
    // eslint-disable-next-line no-param-reassign
    if (ref) ref.current = node;
  };

  return (
    <FieldComponent
      {...props}
      {...fieldProps}
      ref={handleRef}
    />
  );
});

// On test env, is not necessary to use the input type 'telephone'
// since mask value make tests going wrong
const phoneInputType = isTesting() ? 'text' : 'telephone';

export const connectPhoneField = (
  FieldComponent,
  {
    defaultValue = '',
  } = {},
) => ({
  name,
  control,
  ...props
}) => {
  const codeProps = useFieldController({
    name: `${name}Code`,
    control,
  });
  const numberProps = useFieldController({
    name: `${name}Number`,
    control,
    defaultValue,
  });
  return (
    <FieldComponent
      name={name}
      type={phoneInputType}
      codeValue={codeProps.value}
      onCodeChange={codeProps.onChange}
      onCodeBlur={codeProps.onBlur}
      codeError={codeProps.error}
      numberValue={numberProps.value}
      onNumberChange={numberProps.onChange}
      onNumberBlur={numberProps.onBlur}
      numberError={numberProps.error}
      {...props}
    />
  );
};
