import { gql } from '@apollo/client';
import { useLazyQuery } from 'hooks/apollo';

export const ACCOUNT_STATEMENT_GQL = gql`
query AccountStatement($accountStatementId: ID!) {
  accountStatement(id: $accountStatementId) {
    id
    url
  }
}
`;

export const useAccountStatement = (options) => useLazyQuery(ACCOUNT_STATEMENT_GQL, options);
