import React from 'react';
import ContentLoader from 'react-content-loader';
import ReactToolTip from 'react-tooltip';
import { isNil } from 'utils';
import { calculatePercentage, toCurrencyWithDecimals } from 'utils/numberFormatters';
import { useReduxQuery } from 'utils/redux-query';
import AccountStatementContainer from './styles';

const renderLoader = () => (
  <ContentLoader
    height={100}
    width={200}
    speed={2}
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    data-testid="account-statement-loader"
  >
    <rect x="50" y="45" rx="5" ry="5" width="100" height="8" />
    <rect x="60" y="70" rx="5" ry="5" width="80" height="5" />
    <rect x="60" y="24" rx="5" ry="5" width="80" height="5" />
  </ContentLoader>
);

const AccountStatement = (props) => {
  const { isMobile, handleModal } = props;
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');

  const calculateActiveCreditPercentage = (lentQty, accountValue) => {
    if (lentQty * accountValue) return calculatePercentage(lentQty, accountValue);
    return '0.00';
  };

  const accountValue = statements?.valorCuenta;
  const availableBalance = statements?.saldoDisponible;
  const fundingBalance = statements?.saldoEnFondeo;
  const lentBalance = statements?.saldoPrestado;

  return (
    <AccountStatementContainer isMobile={isMobile}>
      <div className="accountStatement">
        <div className="accountStatement__info">
          {isNil(accountValue) ? (
            renderLoader()
          ) : (
            <div>
              <p>Valor de tu cuenta:</p>
              <p className="main-value total" data-testid="account-statement-account-value">
                {toCurrencyWithDecimals(accountValue)}
              </p>
              {!isMobile && (
                <span
                  role="button"
                  tabIndex="0"
                  className="cashFlowModal"
                  onClick={() => handleModal('cashFlow')}
                  onKeyPress={() => handleModal('cashFlow')}
                >
                  Ver flujo de efectivo
                </span>
              )}
            </div>
          )}
        </div>
        <div className="accountStatement__info">
          {isNil(availableBalance) ? (
            renderLoader()
          ) : (
            <div data-tip="available" data-for="available">
              <p>Disponible para prestar o retirar:</p>
              <p className="main-value available">{toCurrencyWithDecimals(availableBalance)}</p>
              {!isMobile && (
                <p className="percentage" data-testid="account-statement-balance-percentage">
                  {calculatePercentage(availableBalance, accountValue)}
                  %
                </p>
              )}
              <ReactToolTip id="available">Incluye capital, intereses e IVA pagados</ReactToolTip>
            </div>
          )}
        </div>
        <div className="accountStatement__info">
          {isNil(fundingBalance) ? (
            renderLoader()
          ) : (
            <div>
              <p>En proceso de fondeo:</p>
              <p className="main-value">{toCurrencyWithDecimals(fundingBalance)}</p>
              {!isMobile && (
                <p className="percentage" data-testid="account-statement-funding-percentage">
                  {calculatePercentage(fundingBalance, accountValue)}
                  %
                </p>
              )}
            </div>
          )}
        </div>
        <div className="accountStatement__info">
          {isNil(lentBalance) ? (
            renderLoader()
          ) : (
            <div>
              <p>Préstamos en créditos activos:</p>
              <p className="main-value">{toCurrencyWithDecimals(lentBalance)}</p>
              {!isMobile && (
                <p className="percentage" data-testid="account-statement-lend-percentage">
                  {calculateActiveCreditPercentage(lentBalance, accountValue)}
                  %
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </AccountStatementContainer>
  );
};

export default AccountStatement;
