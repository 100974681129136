import React from 'react';
import { Field } from 'react-final-form';
import TextFieldContainer from './styles';

export function TextFieldStyled(props) {
  const { error, touched, ...inputProps } = props;

  return (
    <TextFieldContainer>
      <input type="text" {...inputProps} />
      {error && touched && (
        <div className="error">
          <span style={{ color: 'red' }}>{error}</span>
        </div>
      )}
    </TextFieldContainer>
  );
}

const TextField = (props) => {
  const {
    name,
    placeholder,
    value = '',
    format,
    parse,
    length,
    formatOnBlur,
    textArea,
    colTextArea,
    rowTextArea,
    onBlur,
    disabled,
    onChange = () => {},
  } = props;
  return (
    <Field
      name={name}
      component="input"
      defaultValue={value}
      format={format}
      formatOnBlur={formatOnBlur}
      parse={parse}
    >
      {({ input, meta }) => {
        const renderInput = () => {
          if (textArea) {
            return (
              <textarea
                id={input.name}
                cols={colTextArea}
                rows={rowTextArea}
                maxLength={length}
                disabled={disabled}
                {...input}
              />
            );
          }
          return (
            <input
              type="text"
              id={input.name}
              placeholder={placeholder}
              maxLength={length}
              disabled={disabled}
              {...input}
              onBlur={(ev) => {
                if (onBlur) {
                  onBlur(input.value);
                  input.onBlur(ev);
                }
              }}
              onChange={(ev) => {
                onChange(ev);
                input.onChange(ev);
              }}
            />
          );
        };
        return (
          <TextFieldContainer>
            {renderInput()}
            {meta.error && meta.touched && (
              <div className="error">
                <span style={{ color: 'red' }}>{meta.error}</span>
              </div>
            )}
          </TextFieldContainer>
        );
      }}
    </Field>
  );
};

export default TextField;
