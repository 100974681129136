import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'styles';
import { Text } from 'components/Typography';
import SimpleProgressBar from 'components/ProgressBar/Simple';
import { ReactComponent as CheckIcon } from 'assets/icons/roundedCheck.svg';

const buildItemsState = (status) => ({
  hidden: {
    link: {
      display: 'none',
    },
  },
  actived: {
    link: {
      display: 'block',
    },
    container: {
      bgColor: { base: 'transparent', sm: 'white' },
      borderRadius: '0.4rem',
      boxShadow: ' 0 5px 9px 0 rgba(37,103,141,0.04)',
      display: 'flex',
    },
  },
  checked: {
    text: {
      color: 'schema.green.500',
      weight: '600',
    },
  },
  disabled: {
    icon: {
      color: 'schema.gray.300',
    },
    text: {
      color: 'schema.gray.300',
    },
    secondaryText: {
      color: 'schema.gray.300',
    },
  },
}[status]);

export const Item = (props) => {
  const {
    children,
    actived,
    checked,
    disabled,
    hidden,
    Icon = null,
    to,
    stepNumber,
    totalSteps,
    onClick,
    showProgressBar = true,
    ...contentProps
  } = props;
  const status = [
    [hidden, 'hidden'],
    [checked, 'checked'],
    [disabled, 'disabled'],
    [actived, 'actived'],
    [true, 'normal'],
  ].find(([value, firstStatus]) => (value ? firstStatus : null))[1];

  const styleProps = buildItemsState(status);

  return (
    <Text
      as={['checked', 'disabled'].includes(status) ? 'p' : Link}
      to={to}
      textDecoration="none"
      {...(styleProps?.link || {})}
      {...contentProps}
    >
      <Flex
        as="li"
        listStyleType="none"
        p="0.8rem 1rem"
        alignItems="flex-start"
        columnGap="0.8rem"
        rowGap="1rem"
        flexWrap={{ base: 'wrap', sm: 'nowrap' }}
        onClick={onClick}
        {...(styleProps?.container || {})}
      >
        <Text
          as={['checked'].includes(status) ? CheckIcon : Icon}
          minWidth="18px"
          minHeight="18px"
          width="18px"
          height="18px"
          {...(styleProps?.icon || {})}
        />
        <Text
          as="span"
          textDecoration="none"
          color="schema.gray.600"
          lineHeight="2rem"
          {...(styleProps?.text || {})}
        >
          {children}
        </Text>
        <Text
          as="span"
          color="schema.gray.600"
          fontSize="1.2rem"
          lineHeight="2rem"
          flexGrow="1"
          textAlign="end"
          textDecoration="none"
          display={{ base: 'block', sm: 'none' }}
          {...(styleProps?.secondaryText || {})}
        >
          paso
          {' '}
          {stepNumber}
          {' '}
          de
          {' '}
          {totalSteps}
        </Text>
        {showProgressBar && (
          <SimpleProgressBar
            progress={`${(stepNumber * 100) / 8}`}
            w="100%"
            size="small"
            display={{ base: 'block', sm: 'none' }}
          />
        )}
      </Flex>
    </Text>
  );
};

export const Section = (props) => {
  const { children, ...sectionProps } = props;

  return (
    <Flex as="ul" m="0" p="0 2rem" direction="column" rowGap="1rem" {...sectionProps}>
      {children}
    </Flex>
  );
};
