import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReduxQuery } from 'utils/redux-query';
import { maskEmailAddress, maskPhoneNumber } from 'utils/maskHandler';
import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,
} from 'ui';
import { ModalSwitch } from './ModalSwitch';
import { FIRST_STEP } from './config/Constants';

const SecondFactorModal = (props) => {
  const {
    isOpen,
    onClose,
    closeButtonTestId,
    'data-testid': datatestId,
  } = props;
  const [currentModal, setCurrentModal] = useState(FIRST_STEP);
  const [session] = useReduxQuery('SESSION');
  const [profile] = useReduxQuery('PROFILE');

  useEffect(() => {
    if (!isOpen) setCurrentModal(FIRST_STEP);
  }, [isOpen]);

  const phoneNumber = profile?.cellPhoneNumber ?? '';
  const displayPhoneNumberText = maskPhoneNumber(phoneNumber);

  const email = session?.user?.email ?? '';
  const displayEmailText = maskEmailAddress(email);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton data-testid={closeButtonTestId} />
        <ModalBody>
          <ModalSwitch
            isOpen={isOpen}
            setCurrentModal={setCurrentModal}
            currentModal={currentModal}
            onClose={onClose}
            phone={displayPhoneNumberText}
            email={displayEmailText}
            unobscuredPhone={phoneNumber}
            data-testid={datatestId}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

SecondFactorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SecondFactorModal;
