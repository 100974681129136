import React from 'react';
import { Link } from 'react-router-dom';
import { tscope } from 'i18n';
import PATHS from 'routes';
import {
  Text,
  Panel,
  Heading,
  AlertDialog,
} from 'components';
import { Button, Flex } from 'ui';
import { useController } from './hooks';
import { styles } from './BeneficiarySection.styles';
import { BeneficiaryTable, IncompleteBeneficiaryNotice } from './components';

const t = tscope('settings.account.beneficiarySection');
const { investorSettingsPath } = PATHS;

export const BeneficiarySection = (props) => {
  const {
    beneficiary,
    openDeleteDialog,
    closeDeleteDialog,
    isDeleteDialogOpen,
    onRemoveBeneficiary,
    isDeletingBeneficiary,
    isBeneficiaryIncomplete,
  } = useController();

  return (
    <Panel
      title={t('title')}
      innerContentProps={beneficiary ? {} : styles.panelInnerContent}
      {...props}
    >
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        body={(
          <Heading
            t={t}
            as="h4"
            i18nKey="deleteDialog.title"
            textAlign="center"
          />
        )}
        actions={[
          {
            id: 101,
            variant: 'btn-link',
            onClick: closeDeleteDialog,
            disabled: isDeletingBeneficiary,
            text: t('deleteDialog.actions.cancel'),
          },
          {
            id: 102,
            colorSchema: 'red',
            onClick: onRemoveBeneficiary,
            disabled: isDeletingBeneficiary,
            text: t('deleteDialog.actions.confirm'),
          },
        ]}
      />
      {beneficiary
        ? (
          <>
            <BeneficiaryTable
              beneficiary={beneficiary}
              onDelete={openDeleteDialog}
              isDisabledFullName={isBeneficiaryIncomplete}
            />
            {isBeneficiaryIncomplete && (
              <IncompleteBeneficiaryNotice />
            )}
          </>
        )
        : (
          <Flex {...styles.noBeneficiaryContainer}>
            <Text t={t} i18nKey="content.noBeneficiaries" {...styles.textContent} />
            <Button
              {...styles.completeBeneficiaryButton}
              as={Link}
              to={investorSettingsPath({ section: 'beneficiary/update' })}
            >
              {t('content.actions.add')}
            </Button>
          </Flex>
        )}
    </Panel>
  );
};
