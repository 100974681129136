import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { t } from 'i18n';
import PATHS from 'routes';
import { useReduxSubscription } from 'utils/redux-query';
import { showToastInfo } from 'utils/toastHandler';

const ts = (key) => t(key, { scope: 'Portfolio.default' });

export const useCachingPortfolioHandler = () => {
  const [portfolio, { status: portfolioStatus, refetch }] = useReduxSubscription('PORTFOLIO');
  const matchPortfolioRoute = useRouteMatch(PATHS.investorPortfolioPath());
  const [hasAlertDisplayed, setHasAlertDisplayed] = useState(false);

  useEffect(() => {
    if (portfolio?.ready && portfolioStatus.completed && matchPortfolioRoute) {
      refetch();
    }
  }, [portfolio?.ready, portfolioStatus.completed, matchPortfolioRoute]);

  useEffect(() => {
    if (hasAlertDisplayed || !portfolio?.ready) return;

    showToastInfo(ts('alerts.info.fetchPortfolioCompleted'));
    setHasAlertDisplayed(true);
  }, [portfolio?.ready, hasAlertDisplayed]);
};
