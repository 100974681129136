import { tscope } from 'i18n';
import { showToastSuccess } from 'utils/toastHandler';
import { useCallback, useMemo } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { useValidate } from './useValidate';
import { useRegimenCatalog } from './graphql/regimenCatalog';
import { useUpdateUserFiscal } from './graphql/userMutation';
import { useUser } from './graphql/user';

const t = tscope('settings.account.uploadFiscalForm');

const buildRegimenOptions = (catalog) => {
  if (!catalog) return [];
  return catalog.map(({ id, name }) => ({
    id,
    name,
  }));
};

export const useController = ({
  onClose,
}) => {
  const validate = useValidate();
  const { data: userData, loading: isLoadingUser } = useUser();
  const [, { refetch: refetchProfile }] = useReduxQuery('PROFILE');
  const { data, loading } = useRegimenCatalog();
  const handleOnUpdateComplete = useCallback(() => {
    onClose();
    showToastSuccess(t('alerts.success.updating'));
    refetchProfile();
  }, [onClose]);
  const [updateUserFiscal, { loading: isSubmitting }] = useUpdateUserFiscal({
    onCompleted: handleOnUpdateComplete,
  });
  const fiscalInformation = userData?.user?.fiscalInformation;
  const disabledFields = isLoadingUser || isSubmitting;
  const isRegimeDisabled = loading || disabledFields;
  const regimenOptions = buildRegimenOptions(data?.catalogs?.fiscalRegimes);

  const onSubmit = (values) => {
    const { username, regimen, zipCode } = values;
    return updateUserFiscal({
      variables: {
        input: {
          fiscalInformation: {
            zipCode,
            name: username,
            regime: regimen,
          },
        },
      },
    });
  };

  const initialValues = useMemo(() => ({
    username: fiscalInformation?.name || '',
    regimen: fiscalInformation?.regime || '',
    zipCode: fiscalInformation?.zipCode || '',
  }), [fiscalInformation]);

  const closeModal = () => (isSubmitting ? () => { } : onClose());

  return {
    validate,
    onSubmit,
    closeModal,
    isSubmitting,
    initialValues,
    disabledFields,
    regimenOptions,
    isRegimeDisabled,
  };
};
