import { useDisclosure } from 'ui';
import { useController as useFormController } from 'react-hook-form';
import { useEffect } from 'react';

export const useController = ({ control, name }) => {
  const { field, fieldState } = useFormController({ control, name });
  const {
    onClose: hidePassword,
    onToggle: showPassword,
    isOpen: isPasswordVisible,
  } = useDisclosure();
  const displayError = fieldState.invalid && fieldState.error;

  useEffect(() => {
    if (!field.value) hidePassword();
  }, [field, hidePassword]);

  return {
    field,
    fieldState,
    displayError,
    showPassword,
    isPasswordVisible,
  };
};
