/**
 * This allow parse any
 */
import camelify from 'camelcase-keys-recursive';

const toSchema = (message) => {
  const result = typeof message === 'object'
    ? camelify(message)
    : message;

  return {
    data: result,
  };
};

export const parseEventMessage = (message) => {
  try {
    const payload = JSON.parse(message);
    return toSchema(payload);
  } catch {
    return toSchema(message);
  }
};
