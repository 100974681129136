import { useMemo, useEffect } from 'react';
import { l } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';

export const useController = (options) => {
  const { goNextStep } = options;
  const [session] = useReduxQuery('SESSION');
  const [profile, { status: profileStatus }] = useReduxQuery('PROFILE');

  const updateProfile = async (values) => {
    const {
      user, gender, curp, rfc, birthStateId, birthDate: birthDateValue,
    } = values;
    const [day, month, year] = birthDateValue.split('/').map(Number);
    const ISOBirthDate = new Date(year, month - 1, day, 0, 0, 0).toISOString();

    const profileData = {
      birthStateId,
      birthDate: ISOBirthDate,
      firstName: user.firstName,
      secondName: user.middleName?.trim(),
      firstLastName: user.firstLastName,
      secondLastName: user.secondLastName,
      gender,
      rfc,
      curp,
    };

    await goNextStep({ profile: profileData, profileCompleted: 'true' }, true);
  };

  const initialValues = useMemo(() => {
    if (profile) {
      return {
        user: {
          firstName: profile.firstName,
          middleName: profile.secondName,
          firstLastName: profile.firstLastName,
          secondLastName: profile.secondLastName,
        },
        gender: profile.gender,
        birthDate: l('date.formats.medium', profile.birthDate),
        birthStateId: profile.birthStateId,
        rfc: profile.rfc,
        curp: profile.curp,
      };
    }

    return null;
  }, [profile]);

  useEffect(() => {
    if (!session || !profile) return;
    if (!session.user.flags.personConfirmed) return;
    const unmodifiedProfile = {
      birthStateId: profile.birthStateId,
      birthDate: profile.birthDate,
      firstName: profile.firstName,
      secondName: profile.secondName,
      firstLastName: profile.firstLastName,
      secondLastName: profile.secondLastName,
      gender: profile.gender,
      rfc: profile.rfc,
      curp: profile.curp,
    };
    goNextStep({ profile: unmodifiedProfile, profileCompleted: 'true' }, true);
  }, [profile, session?.user?.flags?.personConfirmed]);

  return {
    initialValues,
    profileStatus,
    updateProfile,
  };
};
