import React from 'react';
import { Icon } from 'ui';

export const DocumentFileIcon = (props) => (
  <Icon viewBox="0 0 16 19" fill="none" {...props}>
    <path d="M0.6 2.5C0.6 1.7268 1.2268 1.1 2 1.1H10.1716C10.5429 1.1 10.899 1.2475 11.1615 1.51005L14.99 5.33848C15.2525 5.60103 15.4 5.95712 15.4 6.32843V16.5C15.4 17.2732 14.7732 17.9 14 17.9H2C1.2268 17.9 0.6 17.2732 0.6 16.5V2.5Z" stroke="currentColor" strokeWidth="1.2" />
    <path d="M10.5 1.5L10.5 6H15" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="4" y="7.5" width="8" height="1" rx="0.5" fill="currentColor" />
    <rect x="4" y="10.5" width="8" height="1" rx="0.5" fill="currentColor" />
    <rect x="4" y="13.5" width="4" height="1" rx="0.5" fill="currentColor" />
  </Icon>
);
