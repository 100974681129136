import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { ReactComponent as CloseIconSVG } from 'assets/closeCardBottom.svg';
import { ReactComponent as PlusIconSVG } from 'assets/plusIcon.svg';
import ElementCardContainerContent from './styles';

const ElementCardContainer = ({
  children,
  color = 'rgb(255, 255, 255)',
  classNames,
  isFoldCard,
  titleFoldCard,
}) => {
  const [cardFoldState, setCardFoldState] = useState(false);
  const transitionIcon = useTransition(cardFoldState, null, {
    from: {
      position: 'absolute',
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const transitionCard = useTransition(cardFoldState, null, {
    from: {
      opacity: 0,
      maxHeight: 0,
      paddingTop: 0,
    },
    enter: {
      opacity: 1,
      maxHeight: 600,
      paddingTop: 10,
    },
    leave: {
      opacity: 0,
      maxHeight: 0,
      paddingTop: 0,
    },
  });

  const foldingIconAnimation = () => transitionIcon.map(({ item, key, props }) => (item ? (
    <animated.div style={props} className="header__icons__close" key={key}>
      <CloseIconSVG title="" />
    </animated.div>
  ) : (
    <animated.div style={props} key={key}>
      <PlusIconSVG title="" />
    </animated.div>
  )));
  return (
    <ElementCardContainerContent className={classNames || ''} style={{ backgroundColor: color }}>
      {isFoldCard ? (
        <>
          <div
            className="header"
            role="button"
            tabIndex="0"
            onClick={() => setCardFoldState((prevS) => !prevS)}
            onKeyPress={() => setCardFoldState((prevS) => !prevS)}
          >
            {titleFoldCard}
            <span className="header__icons">{foldingIconAnimation()}</span>
          </div>
          {transitionCard.map(
            ({ item, key, props }) => item && (
            <animated.div style={props} key={key}>
              {children}
            </animated.div>
            ),
          )}
        </>
      ) : (
        children
      )}
    </ElementCardContainerContent>
  );
};

export default ElementCardContainer;
