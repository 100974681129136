import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import PaymentSummaryContainer from './styles';

function PaymentSummary({ monthAmount, dayAmount, date }) {
  const { year, month, day } = date;
  const currentDate = moment(`${year}-${month}-${day}`).locale('es');
  return (
    <PaymentSummaryContainer>
      <div className="payments-today">
        <span>Pagos programados</span>
        <span className="capitalize">
          {currentDate.format('MMMM')}
          :
        </span>
        <h3>{toCurrencyWithDecimals(monthAmount)}</h3>
      </div>
      <div className="payments-month">
        <span>Pagos programados</span>
        <span className="capitalize">
          {currentDate.format('D MMMM')}
          :
        </span>
        <h3>{toCurrencyWithDecimals(dayAmount)}</h3>
      </div>
      <div className="note">
        <span>Los pagos sólo se realizan en días hábiles.</span>
      </div>
    </PaymentSummaryContainer>
  );
}

const mapStateToProps = ({ calendar }) => ({
  monthAmount: calendar.summary.monto,
  dayAmount: calendar.dayAmount,
  date: calendar.date,
  summary: calendar.summary,
});

export default connect(mapStateToProps)(PaymentSummary);
