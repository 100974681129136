import React, { forwardRef, useMemo } from 'react';
import { useField } from 'react-final-form';
import CheckboxContainer from 'components/CheckboxContainer';
import { Box } from 'styles';
import { Text } from 'components/Typography';
import { CheckboxInput } from './CheckboxInput';

const getErrorMessage = (meta) => {
  const {
    touched, error, submitError, dirtySinceLastSubmit,
  } = meta;
  if (!dirtySinceLastSubmit && touched && (error || submitError)) {
    return error || submitError;
  }

  return '';
};

const components = {
  radio: CheckboxContainer,
  checkbox: CheckboxInput,
};

const CheckboxField = (props, ref) => {
  const {
    id,
    name,
    color,
    variant,
    textLabel,
    validateFields,
    onChange = () => {},
    checkboxType = 'radio',
    ...rest
  } = props;
  const { input, meta } = useField(name, {
    type: 'checkbox',
    validateFields,
  });
  const errorMessage = getErrorMessage(meta);
  const CheckboxComponent = useMemo(() => components[checkboxType], [checkboxType]);

  const onChangeHandler = (ev) => {
    onChange(ev.target.checked);
    input.onChange(ev);
  };

  return (
    <Box {...rest}>
      <CheckboxComponent
        id={id}
        color={color}
        text={textLabel}
        label={textLabel}
        {...input}
        onChange={onChangeHandler}
        value={input.checked}
        ref={ref}
      />
      {errorMessage && (
        <Text color="schema.red.500" fontSize="1.2rem" lineHeight="2rem" data-testid={`${name}.textHelper`}>
          {errorMessage}
        </Text>
      )}
    </Box>
  );
};

export default forwardRef(CheckboxField);
