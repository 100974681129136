import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import colors from 'styles/colors';
import { textHelper, shouldForwardProp } from 'styles';
import { I18nText } from '../Typography';

function LinkTo(props) {
  const {
    className, children, disabled, to, ...rest
  } = props;

  return (
    <RouterLink className={className} to={disabled ? '#' : to} disabled={disabled} {...rest}>
      {children}
    </RouterLink>
  );
}

export const Link = styled(LinkTo)
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({ textDecoration: 'none', ...props }))`
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${colors.schema.blue[500]};
  font-size: 1.4rem;
  ${textHelper}
`;

export const ExternalLinkStyled = styled
  .a
  .withConfig({
    shouldForwardProp,
  })
  .attrs((props) => ({
    target: '_blank',
    rel: 'noopener noreferrer',
    ...props,
  }))`
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${colors.schema.blue[500]};
  font-size: 1.4rem;
  ${textHelper}
`;

export const ExternalLink = (props) => (
  <I18nText TextComponent={ExternalLinkStyled} {...props} />
);
