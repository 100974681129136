import styled from 'styled-components';

const TableContainer = styled.div`
  .referrals-info-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tbody {
      tr {
        td {
          text-align: center;
          font-size: 1.4rem;
          line-height: 1.4rem;
          padding: 1.5rem 0;
          border-top: 0.1rem solid #ddd;
          border-bottom: 0.1rem solid #ddd;

          &.top-cell {
            color: #83a8b7;
            border-top: none;
            padding: 1.5rem 0.5rem;
          }
        }
      }
    }
  }

  .bottom-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2.5rem 0;
    font-size: 1.4rem;

    .text {
      color: #5b727b;
      line-height: 1.6rem;
    }

    .quantity-and-amount {
      font-size: 1.8rem;
      font-weight: 500;
      padding-left: 1rem;

      &.quantity {
        color: #0076ff;
      }

      &.amount {
        color: #00c574;
      }
    }

    .earned {
      padding-left: 2rem;
    }
  }

  @media (max-width: 75rem) {
    width: 100%;
  }
`;

export default TableContainer;
