import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { isRequired } from 'utils/Validators/common';

function validator(values) {
  const { isNotMexican, state, country } = values;

  const stateError = !isNotMexican ? isRequired(state) : null;
  const countryError = isNotMexican ? isRequired(country) : null;

  return clearErrorsObject({
    state: stateError,
    country: countryError,
  });
}

export default validator;
