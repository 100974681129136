import React from 'react';
import { Flex } from 'styles';
import { CER } from 'components/CER/CER';
import FormLayout from 'views/FormLayout';
import { useController } from './hooks/useController';
import styles from './ElectronicRiskCertificate.styles';

export const ElectronicRiskCertificate = () => {
  const {
    confirmSuccessHandler,
  } = useController();

  return (
    <FormLayout {...styles.container}>
      <Flex {...styles.innerContent}>
        <CER onConfirmSuccess={confirmSuccessHandler} />
      </Flex>
    </FormLayout>
  );
};
