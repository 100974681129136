import React from 'react';
import { Form } from 'react-final-form';
import * as Table from 'components/Table/Basic';

const ListForm = (props) => {
  const {
    children, initialValues, onSubmit, validate, ...contentProps
  } = props;

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, ...formProps }) => (
        <form onSubmit={handleSubmit}>
          <Table.Body {...contentProps}>{children({ handleSubmit, ...formProps })}</Table.Body>
        </form>
      )}
    </Form>
  );
};

export default ListForm;
