import { useForm } from 'react-hook-form';
import { resolver } from './resolver';

export const useProofOfAddressForm = ({
  address,
  statesCatalog,
  proofOfAddressStatus,
  updateProfileAddress,
  onSubmit: onSubmitProp = () => {},
  onSuccess = () => {},
}) => {
  const {
    control,
    handleSubmit,
  } = useForm({
    resolver,
    context: {
      proofOfAddressStatus,
    },
  });

  const onSubmit = handleSubmit(async () => {
    onSubmitProp();
    if (typeof address.stateId === 'number') {
      await updateProfileAddress(address);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      onSuccess();
      return;
    }
    const stateId = statesCatalog
      .find((state) => state.code === address.stateId.toUpperCase())
      ?.id || address.stateId;
    await updateProfileAddress({ ...address, stateId });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    onSuccess();
  });

  return {
    control,
    onSubmit,
  };
};
