import React from 'react';
import DotsLoader from 'components/DotsLoader';
import { Box } from 'styles';

const Loading = (props) => (
  <Box
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      height: '200px',
    }}
    {...props}
  >
    <DotsLoader black />
  </Box>
);

export default Loading;
