import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

export const useController = ({
  name,
  control,
  onSubmitFile,
}) => {
  const file = useWatch({ name, control });
  const fileName = useMemo(() => file?.files?.[0]?.name, [file]) || '';

  const clippedName = fileName.length > 35 ? `...${fileName.slice(-35)}` : fileName;

  const onChange = (e) => {
    onSubmitFile(e?.target?.files[0]);
  };

  return {
    onChange,
    fileName: clippedName,
  };
};
