import React, { useState, useEffect } from 'react';
import withSizes from 'react-sizes';
import { useDispatch, useSelector } from 'react-redux';
import { setUserSettingsFlags } from 'actions/auth';
import Modal from 'components/Modal';
import UpdateAddressNotifyModal from 'components/UpdateAddressNotifyModal';
import * as Zendesk from 'utils/Zendesk';
import { useRouteMatch } from 'react-router-dom';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { setIsOpenCart } from 'actions/dashboard';
import { setSessionSetting } from 'actions/auth/session';
import { Box } from 'styles';
import { SlideFade } from 'ui';
import InvestorStatusNullAlert from './Alerts/InvestorStatusNullAlert';
import SideBar from '../SideBar';
import Header from '../Header';
import DashboardContent from '../DashboardContent';
import CartComponent from '../Cart';
import FirstAuthorizedLoanModal from '../ModalContent/FirstAuthorizedLoan';
import { DashboardContainer } from './styles';

const { investorSettingsUpdateAddressPath } = PATHS;

const Dashboard = (props) => {
  const {
    selectTab, activeTab, isMobile, isMid,
  } = props;
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(false);
  const showCart = useSelector((state) => state.CART_IS_OPEN.payload);
  const totalItemsInCart = useSelector((state) => state.cart.totalItemsInCart);
  const updateAddressRouteMatch = useRouteMatch(investorSettingsUpdateAddressPath());
  const needUpdateAddress = true;
  const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(
    !updateAddressRouteMatch && needUpdateAddress,
  );
  const [totalApprovedTickets] = useReduxQuery('TOTAL_APPROVED_TICKETS');
  const [session] = useReduxQuery('SESSION');
  const { settings, id: userId } = session || {};
  const showFirstLoanModal = totalApprovedTickets === 1 && !settings.firstTicketApproved;

  const handleToggle = () => {
    setShowSidebar(!showSidebar);
  };

  const dispatchSetCart = (isOpen) => dispatch(setIsOpenCart(Boolean(isOpen)));

  const openCart = () => dispatchSetCart(true);

  const handleToggleCart = () => {
    dispatchSetCart(!showCart);
  };

  const onCloseCart = () => {
    dispatchSetCart(false);
  };

  const onCloseModal = async () => {
    if (showFirstLoanModal) {
      dispatch(setUserSettingsFlags('firstTicketApproved', true));
      dispatch(setSessionSetting('firstTicketApproved', true));
    }
  };

  useEffect(() => {
    setShowUpdateAddressModal(!updateAddressRouteMatch && settings?.needsAddress);
  }, [updateAddressRouteMatch, settings?.needsAddress]);

  useEffect(() => {
    if (showCart) {
      Zendesk.hideLauncher();
    } else {
      Zendesk.displayLauncher();
    }
  }, [showCart]);

  useEffect(() => {
    if (!totalItemsInCart) {
      dispatch(setIsOpenCart(false));
    }
  }, [totalItemsInCart]);

  return (
    <DashboardContainer isMobile={isMobile} id="appContainer">
      <SlideFade
        in={showSidebar}
        offsetY="0"
        style={{ zIndex: isMobile ? '999' : '1', position: 'fixed' }}
        unmountOnExit
      >
        <Box
          top="0"
          left="0"
          right="0"
          bottom="0"
          h="100vh"
          role="button"
          onClick={() => setShowSidebar(false)}
        >
          <SideBar
            handleSelect={selectTab}
            activeTab={activeTab}
            isMobile={isMobile}
            toggleSidebar={handleToggle}
          />
        </Box>
      </SlideFade>
      {
        !isMobile && (
          <SideBar
            handleSelect={selectTab}
            activeTab={activeTab}
            isMobile={isMobile}
            isMid={isMid}
          />
        )
      }
      <Header
        title={activeTab.title}
        isMobile={isMobile}
        toggleSidebar={handleToggle}
        sidebarOpen={showSidebar}
        toggleCart={handleToggleCart}
      />
      <SlideFade
        in={showCart}
        offsetY="0"
        unmountOnExit
        style={{
          zIndex: '1000',
        }}
      >
        <CartComponent onCloseCart={onCloseCart} />
      </SlideFade>
      <DashboardContent
        isMobile={isMobile}
        openCart={openCart}
        channelName={userId}
        activeTab={activeTab}
      />
      <UpdateAddressNotifyModal
        isOpen={showUpdateAddressModal}
        onClose={() => setShowUpdateAddressModal(false)}
      />
      <InvestorStatusNullAlert />
      <Modal isOpen={showFirstLoanModal} onRequestClose={onCloseModal}>
        <FirstAuthorizedLoanModal onCloseModal={onCloseModal} />
      </Modal>
    </DashboardContainer>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 700,
  isMid: width < 920,
  width,
});

export default withSizes(mapSizesToProps)(Dashboard);
