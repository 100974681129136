import React from 'react';
import styled from 'styled-components';

const ContainerDiv = ({ mobile, ...otherProps }) => <div {...otherProps} />;

const EmptyStateContainer = styled(ContainerDiv)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #073f51;
  svg {
    max-width: 28rem;
  }
  & .empty__state__description__title {
    font-size: 2.8rem;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 3.4rem;
    text-align: center;
    width: ${(props) => (props.mobile ? '80%' : '90%')};
  }
  & .empty__state__description__description {
    text-align: center;
    width: ${(props) => (props.mobile ? '80%' : '90%')};
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    line-height: 33px;
    letter-spacing: 0.63px;
    & span {
      font-weight: 500;
    }
  }
`;

export default EmptyStateContainer;
