import React from 'react';
import DotsLoader from 'components/DotsLoader';
import LoadingContainer from './styles';

const Loading = () => (
  <LoadingContainer data-testid="fs-loader">
    <h1>Cargando</h1>
    <DotsLoader black />
  </LoadingContainer>
);

export default Loading;
