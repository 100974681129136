import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { blue } = colors;
const { smallBreakPoint } = breakPoints;

const ProjectionChartContainer = styled.div`
  grid-column: 1 / 6;
  max-width: 100%;
  grid-row: ${(props) => props.columStartAt} / -1;
  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
  }
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-column: 1 / -1;
    grid-row: 4 / 5;
  }
  & .projectionChart {
    box-shadow: ${colors.shadow.card};
    border-radius: 0.4rem;
    display: flex;
    background-color: white;
    flex-direction: column;
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      .card h5 {
        font-size: 1.5rem;
      }
    }

    & .profitInfo {
      display: flex;
      padding: 1.5rem;
      width: 100%;

      & .card {
        height: 100%;
        margin: 0;
        margin-right: 1rem !important;
        justify-content: center;
        padding: 1.6rem;
        box-shadow: 0;
        & h5 {
          font-size: 2rem;
          color: #012e47;
          font-weight: 500;
        }
        & p {
          font-size: 1.4rem;
          color: #677d85;
        }
      }
      & .cardGrey {
        flex: 1;
        margin-right: 1.5rem;
        & .card {
          background-color: #f8fafc;
          box-shadow: 0;
        }
      }
      & .cardGreen {
        flex: 1;
        margin-right: 1.5rem;
        & .card {
          background-color: #f0fcf6;
          h5,
          p {
            color: #00c574;
          }
        }
      }
      & .cardBlue {
        flex: 1;
        & .card {
          background-color: #eff7ff;
          h5,
          p {
            color: ${blue};
          }
        }
      }
    }
    & .HeaderCard {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      width: 100%;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.6rem;
      background-color: #f8fafc;
      color: #93afb9;
    }
    & .chartContainer {
      font-size: 1.2rem;
    }
    .chartFooter {
      font-size: 1.1rem;
      color: #aaa;
      padding: 5px 20px 20px 20px;
    }
  }
  & .investment__infoBanner {
    margin-top: 2rem;
    font-size: 1.4rem;
    padding: 0;
    &__container {
      & .header {
        font-size: 1.6rem;
        text-align: center;
        padding: 2rem;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #eee;
        & span {
          color: ${blue};
        }
      }
      & .banner__body {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        & p {
          width: 50%;
          margin-bottom: 1rem;
          color: #677d85;
          font-size: 1.4rem;
          line-height: 180%;
        }
        @media (max-width: ${smallBreakPoint}px) {
          flex-direction: column;
          & p {
            margin-left: 1.5rem;
            margin-top: 1rem;
            width: 100%;
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .recharts-tooltip-item {
    font-weight: 600 !important;
  }
  .recharts-default-tooltip {
    border-radius: 6px;
    padding: 6px 10px !important;
    border: none !important;
  }
`;

export default ProjectionChartContainer;
