import React from 'react';
import { Link } from 'react-router-dom';
import { tscope } from 'i18n';
import PATHS from 'routes';
import {
  Flex,
  Tag,
  Button,
} from 'components';
import { styles } from './IncompleteBeneficiaryNotice.styles';

const t = tscope('settings.account.beneficiarySection');
const { investorSettingsPath } = PATHS;

export const IncompleteBeneficiaryNotice = () => (
  <Flex {...styles.container}>
    <Tag {...styles.tag}>
      {t('content.pendingInfo')}
    </Tag>
    <Button as={Link} to={investorSettingsPath({ section: 'beneficiary/update' })}>
      {t('content.actions.update')}
    </Button>
  </Flex>
);
