export const styles = {
  row: {
    columnGap: '3',
    templateColumns: '6fr 6fr',
  },
  rowLabel: {
    color: 'gray.700',
  },
  rowValue: {
    color: 'gray.700',
    textAlign: 'end',
    fontWeight: 'semibold',
  },
};
