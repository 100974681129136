import React from 'react';
import { Form, Field } from 'react-final-form';
import { publicUrls } from 'config/constants';
import Button from 'components/Button/Button';
import { t } from 'i18n';
import { Text } from 'components/Typography';
import ButtonIcon from 'components/Button/ButtonIcon';
import getStatusColors from '../utils';
import statusOptions, { statusDescriptions } from './utils';
import FilterContainer from './styles';
import InputField, { Select } from '../../../../InputField';

const StatusDescription = ({ status }) => (status ? (
  <div className="filter__description">
    <div className="filter__description__container">
      <Text
        className="filter__description__container__title"
        color={getStatusColors(status)}
        weight="500"
      >
        {(statusDescriptions[status] || {}).title}
      </Text>
      <Text
        className="filter__description__container__text"
        color={getStatusColors(status)}
        weight="500"
      >
        {(statusDescriptions[status] || {}).description}
      </Text>
    </div>
  </div>
) : null);

const PortfolioFilter = (props) => {
  const {
    onApplyFilter, onResetFilter, isOpen, onCloseFilter, filters, ...boxProps
  } = props;

  const handleFilterSubmit = async (values) => onApplyFilter(values);

  const handleReset = (form) => {
    form.reset();
    onResetFilter({});
  };

  const renderStatusOptions = statusOptions.map((option) => (
    <option value={option.value} key={option.id}>
      {option.text}
    </option>
  ));

  return (
    <FilterContainer
      isOpen={isOpen}
      position={{ base: 'fixed', lg: 'static' }}
      top="5.6rem"
      left={{
        base: isOpen ? '0' : '-27rem',
        sm: isOpen ? '7rem' : '-27rem',
        md: isOpen ? '20rem' : '-27rem',
      }}
      maxHeight="100%"
      {...boxProps}
    >
      <Form
        onSubmit={handleFilterSubmit}
        initialValues={filters}
        render={({
          handleSubmit,
          submitSucceeded,
          submitFailed,
          form,
          pristine,
          submitting,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="filter__header">Filtrar préstamos</div>
            <ButtonIcon
              display={{ lg: 'none' }}
              iconName="CLOSE"
              onClick={onCloseFilter}
              position="absolute"
              size="xs"
              right="1rem"
              top="1rem"
            />
            <hr />
            <Field name="type" parse={(v) => v}>
              {({ input, meta }) => (
                <div className="filter__status">
                  <p>{t('common.status')}</p>
                  <div className="filter__status__dropdown">
                    <Select
                      isActive={input.value}
                      isFocused={meta.active}
                      name={input.name}
                      labelText={input.value === '' ? t('common.status') : t('common.all')}
                      input={{ ...input }}
                    >
                      <option value="" key="0">
                        Todos
                      </option>
                      {renderStatusOptions}
                    </Select>
                  </div>
                </div>
              )}
            </Field>
            <div className="filter__qualification">
              <p className="filter__qualification__title">Calificación/Tasa</p>
              <div className="filter__qualification__group">
                <div className="option__button">
                  <Field
                    name="rate"
                    component="input"
                    type="radio"
                    value="A"
                    id="qualificationA"
                  />
                  <label htmlFor="qualificationA">A</label>
                </div>
                <div className="option__button">
                  <Field
                    name="rate"
                    component="input"
                    type="radio"
                    value="B"
                    id="qualificationB"
                  />
                  <label htmlFor="qualificationB">B</label>
                </div>
                <div className="option__button">
                  <Field
                    name="rate"
                    component="input"
                    type="radio"
                    value="C"
                    id="qualificationC"
                  />
                  <label htmlFor="qualificationC">C</label>
                </div>
              </div>
            </div>
            <div className="filter__origin">
              <p className="filter__origin__title">Origen</p>
              <div className="filter__origin__group">
                <div className="option__button">
                  <Field
                    name="origin"
                    component="input"
                    type="radio"
                    value="front_end"
                    id="originManual"
                  />
                  <label htmlFor="originManual">Manual</label>
                </div>
                <div className="option__button">
                  <Field
                    name="origin"
                    component="input"
                    type="radio"
                    value="autoinvest"
                    id="originAutoInvest"
                  />
                  <label htmlFor="originAutoInvest">Autoinvest</label>
                </div>
              </div>
            </div>
            <Field name="collection_product_type" parse={(v) => v}>
              {({ input, meta }) => (
                <div className="filter__status">
                  <p>Condición</p>
                  <div className="filter__status__dropdown">
                    <InputField
                      isActive={input.value}
                      isFocused={meta.active}
                      name={input.name}
                      labelText={input.value ? 'Condición' : 'Todas'}
                      inputType="select"
                      input={{ ...input }}
                    >
                      <option value="">Todas</option>
                      <option value="re_calendarization">Recalendarización</option>
                      <option value="grace_period">Período de gracia</option>
                      <option value="restructuring">Reestructurado</option>
                      <option value="lquita">{t('detailRequisition.lquita.title')}</option>
                    </InputField>
                  </div>
                </div>
              )}
            </Field>
            <div className="filter__action__buttons">
              <Button
                type="button"
                $type="primary-outline"
                onClick={() => handleReset(form)}
                disabled={!(submitSucceeded || submitFailed) && submitting}
              >
                Borrar
              </Button>
              <Button type="submit" disabled={pristine || submitting}>
                Aplicar
              </Button>
            </div>
            <StatusDescription status={values.type} />
            <div className="filter__statistics">
              <div className="filter__statistics__content">
                <a href={publicUrls.statistics} rel="noopener noreferrer" target="_blank">
                  Estadísticas generales de yotepresto.com
                </a>
              </div>
            </div>
          </form>
        )}
      />
    </FilterContainer>
  );
};

export default PortfolioFilter;
