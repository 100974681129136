import {
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  useHistory,
} from 'react-router-dom';
import { tscope } from 'i18n';
import { showToastError } from 'utils/toastHandler';
import { fetchPersonalIdentifications } from 'apis/registerForm';
import { profileContext } from '../../../controller/context';

const ts = tscope('expedientsProfileShow');

export const useController = () => {
  const history = useHistory();
  const submitRef = useRef(null);
  const {
    profile,
    trackStep,
    setProfile,
    STEP_NAMES,
  } = profileContext();
  const [isUpdatingIdentities, setIsUpdatingIdentities] = useState(false);
  const [curp, setCURP] = useState('');
  const curpUserData = curp?.slice(0, 16) || '';
  const curpHomoClave = curp?.slice(16, 18) || '';

  const handleOnSubmit = (values) => {
    const { curp: _curp, homoClave } = values;

    setProfile((currentProfile) => ({
      ...currentProfile,
      curp: [_curp, homoClave].join(''),
    }));
    trackStep({ name: STEP_NAMES.CURP, hasUserTakenForm: true });
    history.push('../show');
  };

  const regenerateCURP = async () => {
    try {
      setIsUpdatingIdentities(true);
      const { curp: _curp, errors } = await fetchPersonalIdentifications(
        {
          firstName: profile.firstName,
          secondName: profile.secondName,
          firstLastName: profile.firstLastName,
          secondLastName: profile.secondLastName,
          birthDate: profile.birthDate,
          birthState: profile.isNotMexican
            ? profile.country
            : profile.state?.name,
          gender: profile.gender,
        },
      );
      setCURP(_curp);
      if (submitRef.current) submitRef.current.focus();
      if (errors) showToastError(ts('alerts.error.generateCurpRfc'));
    } catch (e) {
      showToastError(ts('alerts.error.generateCurpRfc'));
    } finally {
      setIsUpdatingIdentities(false);
    }
  };

  useEffect(() => {
    setCURP(profile?.curp);
  }, [profile]);

  return {
    submitRef,
    curpUserData,
    curpHomoClave,
    handleOnSubmit,
    regenerateCURP,
    isUpdatingIdentities,
  };
};
