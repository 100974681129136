import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';
import Card from '../../../../Card';

const { smallBreakPoint, largeBreakPoint } = breakPoints;
const SecurityContainer = styled.div`
  @media (max-width: ${smallBreakPoint}px) {
    display: flex;
    justify-content: center;
  }
  .loader {
    padding: 2rem;
    text-align: center;
  }
  .device_info {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    span {
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }
  .link {
    color: ${colors.blue};
    background-color: transparent;
    border: none;
    outline: none;
    &:focus {
      outline: none;
    }
  }
  .security__content {
    width: 80%;
    height: 100%;
    margin: auto;
    padding-left: 3.2rem;
    color: #04475c;
    @media (max-width: ${largeBreakPoint}px) {
      width: 90%;
      padding-left: 0;
    }
  }

  .factor_type_header {
    padding: 2rem;
    line-height: 2rem;
    font-size: 1.333rem;
    & p {
      &:first-of-type {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }

  .factor_type_section,
  .factor_type_header {
    border-bottom: 1px solid ${colors.gray.g3};
    &:last-of-type {
      border-bottom: none;
    }
  }

  .factor_type_option {
    display: flex;
    padding: 2rem;
    line-height: 2rem;
    font-size: 1.333rem;
    border-bottom: none;
    &.active {
      background-color: #f4fbff;
    }
    & .option_toggle {
      min-width: 20px;
      display: flex;
      align-items: center;
    }
  }
`;

export const ContentCard = styled(Card)`
  padding: 0;
  margin-bottom: 2rem;
`;

export const SecondFactorContainer = styled.div`
  border-bottom: 1px solid ${colors.gray.g3};
  &:last-of-type {
    border-bottom: none;
  }
`;

export const SecondFactorTopSection = styled.div`
  flex: 1;
  margin-right: 2rem;
`;

export const SecondFactorTitle = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;

  ${({ $isActualFactor }) => ($isActualFactor
    ? css`
          color: ${colors.blue};
          font-weight: bold;
        `
    : '')}
`;

export const SecondFactorSubtitle = styled.p`
  font-size: 1.3rem;
  font-weight: normal;
`;

export const Title = styled.h2`
  color: ${colors.textDefault};
  font-size: 2rem;
  line-height: 3rem;
`;

export const Subtitle = styled.h3`
  color: ${colors.textDefault};
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 2rem;
`;

export const AnimatedDiv = styled.div`
  transition: ease-in-out max-height 0.3s, ease-in-out opacity 0.3s;
  border-color: transparent;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  display: none;

  ${({ $show }) => ($show
    ? css`
          display: block;
          border-bottom-color: #c2d4db;
          max-height: 600px;
          overflow: hidden;
          opacity: 1;
          @media (max-width: ${smallBreakPoint}px) {
            max-height: 1000px;
          }
        `
    : null)}
`;

export default SecurityContainer;
