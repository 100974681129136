import { t } from 'i18n';

export const resolver = (values) => {
  const {
    password,
  } = values;
  const errors = {};
  if (!password) errors.password = { message: t('Forms.fields.errors.required') };

  return {
    values,
    errors,
  };
};
