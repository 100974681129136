import { gql } from '@apollo/client';
import { useLazyQuery } from 'hooks/apollo';

export const USER_GQL = gql`
query {
  user {
    id
    accountStatements {
      id
      periodStart
    }
    withdrawalAccounts {
      id
      bank
      clabe
      status
      clabeAlias
    }
  }
}
`;

export const useUser = (options) => useLazyQuery(USER_GQL, options);

export const USER_BANK_ACCOUNTS_GQL = gql`
query {
  user {
    id
    withdrawalAccounts {
      bank
      clabe
      clabeAlias
      id
      status
    }
  }
}
`;

export const useBankAccountsLazyQuery = (opts) => useLazyQuery(
  USER_BANK_ACCOUNTS_GQL,
  opts,
);
