import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getQuery } from 'utils/redux-query/queryBase';
import { validateActionFn } from './alerts';
import createActions from '../actionCreator/createActions';
import parseMeta from './parseMeta';
import { STATUS } from '../config';
import { useErrorEffect } from './useErrorEffect';

const useReduxMutatorSelector = (keystore, options = {}) => {
  const { onSuccess = () => {}, onError = null } = options;
  const {
    payload,
    meta,
  } = useSelector((state) => state[keystore]);

  const dispatch = useDispatch();
  const actionStored = getQuery(keystore);

  const action = useCallback(async (...args) => {
    validateActionFn(keystore, actionStored);

    try {
      const result = await dispatch(actionStored({}, ...args));
      onSuccess(result);
      return result;
    } catch (error) {
      if (onError) {
        onError(error);
        return undefined;
      }
      throw error;
    }
  }, [
    keystore,
  ]);

  useEffect(() => () => {
    if ([STATUS.COMPLETED, STATUS.FAILED].includes(meta.status)) {
      const { reinitialize } = createActions(keystore);

      dispatch(reinitialize());
    }
  }, [keystore, meta.status]);

  useErrorEffect({ meta });

  return [
    action,
    {
      ...parseMeta(meta),
      data: payload,
    },
  ];
};

export default useReduxMutatorSelector;
