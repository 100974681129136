export const styles = {
  modal: {
    size: 'sm',
  },
  headerModal: {
    borderBottom: '1px solid',
    borderColor: 'gray.200',
  },
  headerSkeleton: {
    maxWidth: '60',
    endColor: 'gray.200',
    startColor: 'gray.50',
  },
  bodyModal: {
    paddingY: 6,
    width: 'full',
  },
  form: {
    rowGap: 4,
    columnGap: 2,
    templateColumns: '1fr 1fr',
  },
  field: {
    gridColumn: '1/-1',
  },
  divider: {
    gridColumn: '1/-1',
    borderColor: 'gray.200',
  },
  tokenField: {
    padding: 5,
    borderRadius: 'md',
    gridColumn: '1 / -1',
    placeSelf: 'stretch',
    justifySelf: 'center',
    backgroundColor: 'gray.100',
  },
  tokenOTPTypeLabel: {
    color: 'gray.500',
    fontWeight: 'normal',
  },
  cancelButton: {
    width: '36',
    variant: 'ghost',
    colorScheme: 'gray',
    placeSelf: 'center',
  },
  submitButton: {
    width: '36',
    type: 'submit',
    placeSelf: 'center',
  },
  footerModal: {
    borderTop: '1px solid',
    borderColor: 'gray.200',
    justifyContent: 'center',
  },
  addAccountDisclaimer: {
    width: 60,
    fontSize: 'sm',
    color: 'gray.400',
    textAlign: 'center',
  },
  resendOTPButton: {
    variant: 'link',
    gridColumn: '1/-1',
    fontWeight: 'normal',
    justifySelf: 'end',
    animationType: 'none',
  },
};
