import { useCallback } from 'react';
import { tscope } from 'i18n';
import { ZIPCODE_LENGTH } from 'config/constants';
import { compareDesc } from 'date-fns';
import { defaultDateStringToISO } from 'utils/Date/Calendar';
import { hasTrailingWhitespace } from 'utils/strings';
import { relationOtherType } from '../constants';

const t = tscope('Forms.fields.errors');

const isBDayGraterThanNow = (date) => {
  const dateObj = new Date(defaultDateStringToISO(date));
  return compareDesc(new Date(), dateObj) === 1;
};

export const useValidator = () => {
  const validateCommon = useCallback((values) => {
    const {
      name,
      birthDate,
      zip,
      street,
      extNumber,
      relationshipType,
      relationshipTypeOther,
    } = values;
    const errors = {};

    if (!name) errors.name = t('required');
    if (hasTrailingWhitespace(name)) errors.name = t('trailingWhitespace');
    if (!birthDate) errors.birthDate = t('required');
    if (birthDate && isBDayGraterThanNow(birthDate)) errors.birthDate = t('invalidDate');
    if (!relationshipType) errors.relationshipType_errors = t('required');
    if (relationshipType === relationOtherType.value && !relationshipTypeOther) errors.relationshipTypeOther = t('required');
    if (!street) errors.street = t('required');
    if (!extNumber) errors.extNumber = t('shortRequired');
    if (!zip) errors.zip = t('shortRequired');
    if (zip && zip.length < ZIPCODE_LENGTH) errors.zip = t('length', { count: ZIPCODE_LENGTH });

    return errors;
  }, []);

  const validateForeignBeneficiary = useCallback((values) => {
    const {
      city,
      country,
    } = values;
    const errors = {};
    if (!city) errors.city = t('required');
    if (!country) errors.country = t('required');

    return errors;
  }, []);

  const validateNationalBeneficiary = useCallback((values) => {
    const {
      neighborhood,
    } = values;
    const errors = {};
    if (!neighborhood) errors.neighborhood = t('required');
    return errors;
  }, []);

  const validator = useCallback((values) => {
    const {
      isForeignPerson,
    } = values;
    const errors = validateCommon(values);
    const errorsByType = (isForeignPerson)
      ? validateForeignBeneficiary(values)
      : validateNationalBeneficiary(values);
    const allError = {
      ...errors,
      ...errorsByType,
    };

    return allError;
  }, [
    validateForeignBeneficiary,
    validateNationalBeneficiary,
  ]);
  return validator;
};
