import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Tag, Text, Flex } from 'components';
import MediaDisplay from 'components/MediaDisplay/MediaDisplay';
import { MI_TOKEN_YTP, EXTERNAL_APP, SMS } from '../config/Constants';

const ts = (id, options) => t(id, {
  scope: 'Dashboard.activate2FAWizard.steps.initialState.body.authenticationTypes',
  ...options,
});

const MiTokenYtpTitle = () => (
  <Flex
    columnGap="1rem"
    alignItems={{ base: 'flex-start', sm: 'center' }}
    direction={{ base: 'column', sm: 'row' }}
  >
    <Text>
      {ts('tokenYtp.title')}
      {' '}
    </Text>
    <Tag size="small" schemaColor="schema.green" variant="solid" iconName="STAR">
      {ts('tokenYtp.tag')}
    </Tag>
  </Flex>
);

const OptionsList = (props) => {
  const { setCurrentModal, phone } = props;

  return (
    <Flex direction="column">
      <MediaDisplay
        cursor="pointer"
        minSize="4rem"
        minHeight="10rem"
        onClick={() => setCurrentModal(MI_TOKEN_YTP)}
        TitleComponent={MiTokenYtpTitle}
        imageName="YTP_SQUARE_LOGO"
        textContent={ts('tokenYtp.description')}
      />
      <MediaDisplay
        cursor="pointer"
        minSize="4rem"
        minHeight="10rem"
        onClick={() => setCurrentModal(EXTERNAL_APP)}
        title={ts('externalApp.title')}
        imageName="EXTERNAL_SECOND_FACTOR"
        textContent={ts('externalApp.description')}
      />
      <MediaDisplay
        cursor="pointer"
        minSize="4rem"
        minHeight="10rem"
        onClick={() => setCurrentModal(SMS)}
        title={ts('SMS.title')}
        imageName="SMS_FLAG"
        textContent={ts('SMS.description', { phone })}
      />
    </Flex>
  );
};

OptionsList.propTypes = {
  setCurrentModal: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
};

export default OptionsList;
