import get from 'lodash.get';
import {
  css,
  colors,
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

export const Divider = styled.div.withConfig({ shouldForwardProp }).attrs((props) => ({
  color: get(colors, props.color, colors.schema.gray[200]),
  position: props.position || 'horizontal',
  stroke: '1px',
}))`
  border-style: ${(props) => props.style || 'solid'};
  border-color: ${(props) => props.color};
  border-width: 0;

  ${(props) => (props.position === 'horizontal'
    ? css`
          border-top-width: ${props.stroke};
          height: 0;
          width: 100%;
        `
    : '')}

  ${(props) => (props.position === 'vertical'
    ? css`
          border-left-width: ${props.stroke};
          height: 100%;
          width: 0;
        `
    : '')}

  ${boxHelpers}
`;

export default Divider;
