import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Terms, Confirm } from './components';
import { useRouter } from './hooks';
import { Provider } from './CER.context';

export const CER = (props) => {
  const { onConfirmSuccess } = props;
  const { ROUTES } = useRouter();

  return (
    <Provider onConfirmSuccess={onConfirmSuccess}>
      <Switch>
        <Route path={ROUTES.ROOT} component={Terms} exact />
        <Route path={ROUTES.CONFIRM} component={Confirm} />
      </Switch>
    </Provider>
  );
};

CER.defaultProps = {
  onConfirmSuccess: () => {},
};

CER.propTypes = {
  onConfirmSuccess: PropTypes.func,
};
