import React from 'react';
import { t, l } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import Modal, { CloseButton } from 'components/Modal';
import { Panel } from 'components';
import { Text } from 'components/Typography';
import ButtonIcon from 'components/Button/ButtonIcon';
import { handlePrint, PrintFrame } from 'utils/window/print';
import { Box, Flex } from 'ui';
import { getAutoinvestContract } from 'views/DashboardContent/Settings/MainArea/AutoInvest/components/EditAutoinvestForm/components/CreateAutoinvestForm/getAutoinvestContract';
import { RemoveAutoInvestForm } from './components';

const ts = (key, opts) => t(
  key,
  { scope: 'components.AutoInvestWidget.removeModal', ...opts },
);

export const RemoveAutoInvestModal = (props) => {
  const { isOpen, onClose } = props;
  const [session] = useReduxQuery('SESSION');
  const [autoInvestmentConfigs] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [autoInvestConfig] = autoInvestmentConfigs || [{}];
  const { user } = session || {};
  const autoinvestContract = getAutoinvestContract({
    fullname: user?.fullName,
    signedAt: l('date.formats.contractDate', autoInvestConfig?.activatedAt),
  });
  const printContractHandler = () => handlePrint({ htmlContent: autoinvestContract });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} closeOnSessionEnds>
      <CloseButton onClick={onClose} />
      <Panel
        title={ts('title')}
        innerContentProps={{ rowGap: '1rem' }}
        textHeaderProps={{ textAlign: 'center' }}
        footerComponent={<RemoveAutoInvestForm onCancel={onClose} />}
      >
        <PrintFrame id="printableFrame" data-testid="RemoveAutoInvestModal-printableFrame" />
        <Box
          as="iframe"
          frameBorder="0"
          srcDoc={autoinvestContract}
          height="md"
          padding="5"
          width="100%"
          bgColor="gray.50"
          title="Contrato de activación de Autoinvest"
          data-testid="RemoveAutoInvestModal.contractFrame"
        />
        <Flex
          gap="1rem"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Box
            order={{ base: '2', xs: '1' }}
          >
            <Text
              t={ts}
              i18nKey="signedAt"
              components={{
                date: (
                  <Text
                    as="strong"
                    color="schema.blue.500"
                    fontSize="1.4rem"
                  >
                    {l('date.formats.dateAndTime', autoInvestConfig.createdAt)}
                  </Text>),
              }}
              fontSize="1.4rem"
              data-testid="RemoveAutoInvestModal-signedAtText"
            />
            <Text
              t={ts}
              i18nKey="ipAddress"
              components={{
                ipAddress: (
                  <Text
                    as="strong"
                    color="schema.blue.500"
                    fontSize="1.4rem"
                  >
                    {autoInvestConfig.ipAddress}
                  </Text>
                ),
              }}
              fontSize="1.4rem"
              data-testid="RemoveAutoInvestModal-ipAddressText"
            />
          </Box>
          <ButtonIcon
            iconName="COPY"
            title={ts('actions.print')}
            onClick={printContractHandler}
            order={{ base: '1', xs: '2' }}
            ml={{ base: 'auto', xs: '0' }}
            data-testid="RemoveAutoInvestModal-printButton"
          />
        </Flex>
      </Panel>
    </Modal>
  );
};
