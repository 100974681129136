import React, { useState } from 'react';
import { t } from 'i18n';
import Button from 'components/Button/Button';
import { useReduxQuery } from 'utils/redux-query';
import {
  BoxContent,
  Title,
  Body,
  SecondFactorImage,
  HelpCenterLink,
  HelpCenterLinksContainer,
} from './HelpCard.style';
import Remove2FAModal from '../Remove2FAModal/Remove2FAModal';

function HelpCard() {
  const [isOpen, setIsOpen] = useState(false);
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');

  return (
    <BoxContent>
      <Title>{t('settings.security.needsSupport')}</Title>
      <Body>{t('settings.security.howToGet2FA')}</Body>
      <HelpCenterLinksContainer>
        <HelpCenterLink href={t('common.helpWith2faLink')} target="blank">
          {t('common.goToHelpCenter')}
        </HelpCenterLink>
        {secondFactorStatus.completed && secondFactor && (
          <Button
            fontSize="1.3rem"
            lineHeight="1.6rem"
            $type="link"
            onClick={() => setIsOpen(true)}
          >
            {t('settings.security.reset2FA')}
          </Button>
        )}
      </HelpCenterLinksContainer>
      <SecondFactorImage />
      <Remove2FAModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </BoxContent>
  );
}

export default HelpCard;
