import React from 'react';
import { connect } from 'react-redux';
import { togglePortfolioFilters } from 'actions/portfolio/portfolioActions';
import { useReduxQuery } from 'utils/redux-query';
import { calculatePercentage } from 'utils/numberFormatters';
import InfoBarContainer from './styles';
import Chart from './chart';

const statsMap = {
  'Al Corriente': 'Al Corriente',
  'En Proceso de Pago': 'En Tránsito',
  Atrasado: 'Atrasado',
  Vencido: 'Vencido',
  Liquidado: 'Pagado',
};

const createSummary = ({ statusDetail, totalPayable, paid }) => {
  const title = statsMap[statusDetail.originalTitle] || null;

  if ((title && statusDetail) || title === 'Pagado') {
    const amount = title === 'Pagado' ? paid : statusDetail.amount;

    return {
      title,
      ...statusDetail,
      amount,
      percentage: calculatePercentage(amount, totalPayable),
    };
  }
  return {
    title,
    total_rows: 0,
    amount: 0,
    percentage: calculatePercentage(0, totalPayable),
  };
};

const InfoBar = () => {
  const [portfolio] = useReduxQuery('PORTFOLIO');

  if (portfolio === null) return null;

  const { paymentDetails } = portfolio || {};

  return (
    <InfoBarContainer>
      <div className="controls_area">
        <div className="contentArea__lastUpdate">
          <p>
            Última actualización
            {portfolio?.updatedAt}
          </p>
        </div>
      </div>
      <div className="contentArea__information__chart__container">
        <div className="contentArea__information__chart__container__charts">
          <Chart
            element={createSummary({
              totalPayable: portfolio.paymentDetails.totalPayable,
              statusDetail: paymentDetails.details['Al Corriente'] || {
                originalTitle: 'Al Corriente',
              },
              paid: portfolio.paymentDetails.paid,
            })}
          />
          <Chart
            element={createSummary({
              totalPayable: portfolio.paymentDetails.totalPayable,
              statusDetail: paymentDetails.details['En Proceso de Pago'] || {
                originalTitle: 'En Proceso de Pago',
              },
              paid: portfolio.paymentDetails.paid,
            })}
          />
          <Chart
            element={createSummary({
              totalPayable: portfolio.paymentDetails.totalPayable,
              statusDetail: paymentDetails.details.Atrasado || { originalTitle: 'Atrasado' },
              paid: portfolio.paymentDetails.paid,
            })}
          />
          <Chart
            element={createSummary({
              totalPayable: portfolio.paymentDetails.totalPayable,
              statusDetail: paymentDetails.details.Vencido || { originalTitle: 'Vencido' },
              paid: portfolio.paymentDetails.paid,
            })}
          />
          <Chart
            element={createSummary({
              totalPayable: portfolio.paymentDetails.totalPayable,
              statusDetail: paymentDetails.details.Liquidado || { originalTitle: 'Liquidado' },
              paid: portfolio.paymentDetails.paid,
            })}
          />
        </div>
        <div className="contentArea__information__chart__container__infoWidget">
          <div className="infoWidget__body">
            <div className="infoWidget__body__section">
              <p className="infoWidget__body__section__quantity">
                {paymentDetails.details.Liquidado?.totalRows || 0}
              </p>
              <p className="infoWidget__body__section__description">Liquidados</p>
            </div>
            <div className="infoWidget__body__section">
              <p className="infoWidget__body__section__quantity">
                {paymentDetails.details['Solicitud Cancelada']?.totalRows || 0}
              </p>
              <p className="infoWidget__body__section__description">Cancelados</p>
            </div>
          </div>
        </div>
      </div>
      <div className="contentArea__description">
        <p>
          El monto que observas en este apartado como “Pagado” corresponde al capital que has
          recibido tanto por los préstamos liquidados como por los préstamos activos, los cuales
          pueden estar al corriente, atrasados o vencidos.
        </p>
      </div>
    </InfoBarContainer>
  );
};

export default connect(null, {
  handleFilterToggle: togglePortfolioFilters,
})(InfoBar);
