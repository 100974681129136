import {
  colors,
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';
import FlashNoticeComponent from 'components/FlashNotice';
import MonthlyTransactionsComponent from './MonthlyTransactions';
import AddFundsBannerComponent from './AddFundsBanner';
import BankAccountCardComponent from './BankAccountCard';
import BlockedBankAccountCardComponent from './BankAccountCard/Blocked';
import TransactionsLimitCardComponent from './TransactionsLimitCard';
import FooterComponent from '../FooterDashboard';

const AddFundsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 53.6rem 28rem;
  grid-template-rows: auto 11rem auto;
  grid-gap: 2rem;
  max-width: ${breakPoints.extraLargeBreakPoint}px;
  margin: 4rem auto 2rem;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-template-columns: auto auto;
    grid-column-gap: 2rem;
    padding: 0 5rem;
  }

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-column-gap: 1.5rem;
    padding: 0 2rem;
    justify-items: center;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-column-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
  }

  @media print {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 1rem;
  }

  .loadingDots {
    display: flex;
    justify-content: center;
    margin: 20% auto;
    grid-column: 1 / -1;
  }
`;

export const Title = styled.h1`
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: 2rem;
`;

export const AddFundsBanner = styled(AddFundsBannerComponent)`
  grid-area: 2 / 1 / 2 / 2;
  max-width: 60rem;
  justify-self: center;
  width: 100%;
  max-width: 53.6rem;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-area: 2 / 1 / 2 / 1;
  }
`;

export const BankAccountCard = styled(BankAccountCardComponent)`
  grid-area: 3 / 1 / 5 / 1;
  justify-self: center;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-area: 3 / 1 / 3 / 1;
  }

  @media print {
    margin: 0 auto;
  }
`;

export const BlockedBankAccountCard = styled(BlockedBankAccountCardComponent)`
  grid-area: 3 / 1 / 5 / 1;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-area: 3 / 1 / 3 / 1;
  }
`;

export const RightTile = styled.div.withConfig({ shouldForwardProp })`
  grid-area: 2 / 2 / 5 / 2;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-area: 2 / 2 / 2 / -1;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-area: 4 / 1 / 4 / 1;
  }

  ${boxHelpers}
`;

export const TransactionsLimitCard = styled(TransactionsLimitCardComponent)`
  margin-bottom: 2rem;

  @media print {
    display: none;
  }
`;

export const MonthlyTransactions = styled(MonthlyTransactionsComponent)`
  @media print {
    display: none;
  }
`;

export const NoticeSection = styled.section`
  grid-area: 5 / 1 / 5 / span 2;
  color: ${colors.gray.g10};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-area: 5 / 1;
  }
`;

export const Footer = styled(FooterComponent)``;

export const FlashNotice = styled(FlashNoticeComponent)``;

export default AddFundsContainer;
