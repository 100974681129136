import styled from 'styled-components';

export const UpgradeLimitContainer = styled.div`
  width: 100%;

  .loader {
    grid-column: 4 / -1;
    margin: auto;
  }
`;
