import { tscope } from 'i18n';
import { ZIPCODE_LENGTH } from 'config/constants';

const errorsT = tscope('Forms.fields.errors');

const buildError = ({ message }) => ({
  message,
  type: 'danger',
});

export const resolver = (values) => {
  const {
    zip,
    street,
    extNumber,
    neighborhood,
  } = values;
  const errors = {};

  if (!street) errors.street = buildError({ message: errorsT('required') });
  if (!extNumber) errors.extNumber = buildError({ message: errorsT('shortRequired') });
  if (!zip) errors.zip = buildError({ message: errorsT('shortRequired') });
  if (!neighborhood) errors.neighborhood = buildError({ message: errorsT('required') });
  if (zip && zip.length !== ZIPCODE_LENGTH) errors.zip = buildError({ message: errorsT('length', { count: ZIPCODE_LENGTH }) });

  return {
    values,
    errors,
  };
};
