import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { calcHeightContentOnBrowserType } from 'utils/responsiveHandler';
import { breakPoints, isSafari, isChromeMobile } from 'config/constants';

export const CheckoutContent = styled.div`
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? '0.2' : '1')};
`;

const container = ({ mobile, ...otherProps }) => <div {...otherProps} />;
const CartContainer = styled(container)`
  position: fixed;
  right: 0;
  width: 52.3rem;
  height: calc(100vh - 56px);
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: ${colors.shadow.cart};
  @media (min-width: ${breakPoints.extraSmallBreakPoint}px) and (max-width: ${breakPoints.smallBreakPoint}px) {
    max-width: 100%;
  }
  @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
    ${calcHeightContentOnBrowserType(isSafari() ? '92.8' : '93.5')};
    width: 100%;
  }
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    height: calc(${isSafari() ? '91.8' : '93.5'}vh - 75px);
  }
  .cart {
    &__header {
      display: flex;
      flex-direction: row;
      padding-left: 2rem;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      background-color: ${colors.gray.g0};
      span {
        color: ${colors.gray.g6};
        font-size: 1.6rem;
        font-weight: 500;
        &:first-of-type {
          flex: 1;
          padding-left: 2rem;
        }
      }
      &--close {
        padding-right: 2rem;
        :hover {
          cursor: pointer;
        }
      }
    }
    &__tableData {
      flex: 1;
      width: 100%;
      margin-top: 3.2rem;
      overflow-y: auto;
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        height: ${() => (isChromeMobile ? 'calc(93.3vh - 60px)' : '93.5vh')};
        width: 100%;
      }
      @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
        flex: 2;
        ${isSafari()
        && css`
          flex: 3;
        `}
      }
      &.refreshing {
        opacity: 0.2;
      }
      &--table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        th {
          font-size: 1.1rem;
          color: ${colors.gray.g4};
          line-height: 13px;
          border-bottom: 1px solid ${colors.gray.g2};
          padding-bottom: 1rem;
          font-weight: 100;
          position: sticky;
          top: 0;
          z-index: 2;
          background-color: white;
          @media (max-width: ${breakPoints.smallBreakPoint}px) {
            :nth-child(2) {
              width: 30%;
            }
          }
        }
        &--group {
          display: table-row-group;
        }
        &--rowData {
          padding: 1.5rem 2rem;
          font-size: 1.2rem;
          height: 5.5rem;
          td {
            border-bottom: 1px solid ${colors.gray.g2};
            color: ${colors.gray.g5};
            font-weight: 600;
            &:last-child {
              padding-right: 1rem;
            }
          }
          &--id {
            span {
              font-size: 1rem;
              background-color: ${colors.gray.g1};
              color: ${colors.gray.g5};
              padding: 0.6rem;
              border-radius: 1rem;
            }
            &.inputInvalid {
              span {
                color: ${colors.red};
              }
            }
          }
          &--qualification {
            b {
              font-size: 1.5rem;
              @media (max-width: ${breakPoints.smallBreakPoint}px) {
                font-size: 1.4rem;
                padding-right: 0.2rem;
              }
            }
            font-size: 1.4rem;
            @media (max-width: ${breakPoints.smallBreakPoint}px) {
              font-size: 1.3rem;
            }
          }
          &--won {
            span {
              color: ${colors.green};
            }
            line-height: 14px;
          }
          &--destination {
            svg {
              vertical-align: middle;
            }
            svg * {
              fill: ${colors.gray.g5};
            }
          }
          &--input {
            width: 8.5rem;
            div {
              color: ${colors.gray.g6};
              display: flex;
              flex-direction: row;
              align-content: center;
              justify-content: center;
              line-height: 15px;
              font-size: 1.3rem;
              font-weight: 500;
              height: 2.9rem;
              input {
                width: 6.2rem;
                padding-left: 1.5rem;
                border: 1px solid ${colors.gray.g3};
                border-radius: 4px;
                background-color: ${colors.gray.g0};
                color: ${colors.gray.g6};
                font-size: 1.3rem;
                font-weight: 500;
                &:focus {
                  outline: 0;
                  border-color: ${colors.blue};
                  font-weight: 600;
                  background: white;
                }
              }
              i {
                display: block;
                font-style: normal;
                margin-top: 2px;
                transform: translate(185%, 22.5%);
                pointer-events: none;
                @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
                  ${isSafari()
                  && css`
                    margin-top: 1px;
                  `}
                }
              }
            }
            &.inputInvalid {
              div {
                input {
                  border-color: ${colors.red};
                  color: ${colors.red};
                }
                i {
                  color: ${colors.red};
                }
              }
            }
          }
          &--delete {
            svg {
              :hover {
                cursor: pointer;
                * {
                  fill: #ff214e;
                }
              }
            }
          }
        }
        &--rowError {
          font-size: 1.2rem;
          background-color: #fcf9e6;
          display: flex;
          justify-content: space-between;
          align-items: center;
          svg {
            padding-left: 1rem;
            width: 3.5rem;
          }
          p {
            text-align: right;
            color: #c59458;
            padding: 1rem 1.5rem 0.5rem 0;
          }
        }
      }
      &--coverUp {
        width: 100%;
        height: 69vh;
        position: absolute;
        top: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 200;
      }
      .ticketFunded {
        td {
          background-color: ${colors.gray.g0};
          :nth-child(3) {
            svg {
              * {
                fill: ${colors.gray.g3};
              }
            }
          }
          :nth-child(4),
          :nth-child(5) {
            color: ${colors.gray.g3};
          }
          :nth-child(6) {
            span {
              color: ${colors.gray.g3};
            }
          }
          input {
            color: ${colors.red};
          }
          i {
            color: ${colors.red};
          }
        }
      }
    }
    &__diversification {
      align-self: flex-end;
      font-size: 1.2rem;
      padding: 0 2rem 1rem;
      > div {
        color: #677d85;
      }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #ccdce2;
      @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
        flex: 1;
        ${isSafari()
        && css`
          flex: 2;
        `}
      }
      &__numbers {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #346071;
        font-size: 1.3rem;
        font-weight: 500;
        padding: 2rem;
        @media (max-width: ${breakPoints.smallBreakPoint}px) {
          padding: 1rem 2rem;
          flex: unset;
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &--total {
          span {
            padding-left: 1rem;
            font-size: 2.1rem;
          }
        }
      }
      &__auth {
        flex: 1;
        display: flex;
        .secondFactor {
          margin: 0 auto;
          width: 100%;
          padding: 0;
          @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
            width: 100%;
          }
          .otp__form {
            @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
              padding-top: 0;
            }
            @media (max-width: ${breakPoints.smallBreakPoint}px) {
              align-items: flex-start;
              label {
                width: 100%;
              }
            }
            button {
              flex-grow: 1;
              @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
                margin-left: 0;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
`;

export default CartContainer;
