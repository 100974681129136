import styled from 'styled-components';
import { animated } from 'react-spring';

const DropdownContainer = styled(animated.div)`
  position: absolute;
  top: 5.5rem;
  right: 0.5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.15);
  background-color: white;
  min-width: 16rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.6rem 1.2rem rgba(0, 0, 0, 0.175);
  transition: all ease 0.2s;
  z-index: 999;
`;

export default DropdownContainer;
