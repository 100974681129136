import React from 'react';
import { t } from 'i18n';
import { Text } from 'components';
import { Box, Flex } from 'styles';
import styles from './Item.styles';

const ts = (key) => t(key, { scope: 'components.CER.Terms' });

export const Item = (props) => {
  const { number, i18nKey } = props;

  return (
    <Box>
      <Flex {...styles.header}>
        <Text {...styles.numberText} data-testid="TermsItemNumber">
          {number}
        </Text>
        <Text {...styles.title} data-testid="TermsItemTitle">
          {ts(`sections.termsListing.${i18nKey}.title`)}
        </Text>
      </Flex>

      <Text data-testid="TermsItemDescription">
        {ts(`sections.termsListing.${i18nKey}.description`)}
      </Text>
    </Box>
  );
};
