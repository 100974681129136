import React from 'react';

import Button from 'components/Button';
import { TourStep, StepStyle } from './styles';

const getTourSteps = (handleTourClose) => [
  {
    position: 'top',
    selector: '.firstStep',
    style: StepStyle,
    content: () => (
      <TourStep>
        <p>Aquí te explicaremos cómo empezar a invertir. ¡Es muy fácil!</p>
      </TourStep>
    ),
  },
  {
    position: 'top',
    selector: '.secondStep',
    style: StepStyle,
    content: () => (
      <TourStep>
        <p>Aquí verás las solicitudes de préstamos en las que puedes invertir.</p>
      </TourStep>
    ),
  },
  {
    position: 'left',
    selector: '.thirdStep:first-of-type',
    style: StepStyle,
    content: () => (
      <TourStep>
        <p>Aquí indicarás la cantidad que deseas invertir, recuerda que puede ser desde $200.</p>
      </TourStep>
    ),
  },
  {
    position: 'left',
    selector: '.lastStep',
    style: StepStyle,
    content: () => (
      <TourStep>
        <p>Para terminar, deberás hacer clic aquí y autorizar con tu contraseña.</p>
        <Button className="margin-top" type="button" onClick={handleTourClose}>
          Entendido
        </Button>
      </TourStep>
    ),
  },
];

export default getTourSteps;
