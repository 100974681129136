import React, { useEffect } from 'react';
import { ReactComponent as MaintenanceImage } from 'assets/maintenance.svg';
import { checkApiStatus } from 'actions/auth';
import { useDispatch } from 'react-redux';
import MaintenanceContainer from './styles';

const ONE_MINUTE = 60 * 1000;

const Maintenance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const poll = setInterval(() => dispatch(checkApiStatus()), ONE_MINUTE);
    return () => clearInterval(poll);
  }, []);

  return (
    <MaintenanceContainer>
      <MaintenanceImage title="" />
      <h1>Sitio en mantenimiento.</h1>
      <h2>Inténtalo de nuevo más tarde.</h2>
    </MaintenanceContainer>
  );
};

export default Maintenance;
