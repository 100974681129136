import React, { useState } from 'react';
import { t, toCurrency } from 'i18n';
import {
  TransactionsCardContent,
  Title,
  TransactionsTable,
  TableHeader,
  Head,
  TableBody,
  TableRow,
  Cell,
  SeeMoreButton,
} from './TransactionsCard.style';

function TransactionsCard(props) {
  const { transactions } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    setIsExpanded((currentIsExpanded) => !currentIsExpanded);
  };

  return (
    <TransactionsCardContent {...props}>
      <Title as="h4">{t('addFunds.transactions.montlyTransactionsTitle')}</Title>
      <TransactionsTable>
        <TableHeader>
          <Head>{t('addFunds.transactions.date')}</Head>
          <Head>{t('addFunds.transactions.amount')}</Head>
        </TableHeader>
        <TableBody $isExpanded={isExpanded}>
          {transactions
            .map((transaction, index) => ({ ...transaction, id: index }))
            .map((transaction) => (
              <TableRow key={transaction.id}>
                <Cell $type={transaction.type}>{transaction.createdAt}</Cell>
                <Cell $type={transaction.type}>
                  {toCurrency(transaction.amount, { precision: 2 })}
                </Cell>
              </TableRow>
            ))}
        </TableBody>
      </TransactionsTable>
      {transactions.length > 5 && (
        <SeeMoreButton $size="small" $type="primary-outline" onClick={handleSeeMoreClick}>
          {isExpanded
            ? t('addFunds.transactions.seeLess')
            : t('addFunds.transactions.seeMore')}
        </SeeMoreButton>
      )}
    </TransactionsCardContent>
  );
}

export default TransactionsCard;
