import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/Typography';
import ReactTooltip from 'react-tooltip';
import { Flex } from 'styles';
import iconSet from 'assets/icons/iconSet';

const VARIANTS = ['subtle', 'solid', 'semi-solid'];
const [SUBTLE, SOLID, SEMI_SOLID] = VARIANTS;

const createVariant = ({ schemaColor }) => ({
  [SUBTLE]: {
    content: {
      border: '2px solid',
      borderColor: `${schemaColor}.100`,
      bgColor: `${schemaColor}.100`,
      borderRadius: '0.8rem',
    },
    text: {
      color: 'schema.gray.500',
      fontWeight: '500',
    },
  },
  [SEMI_SOLID]: {
    content: {
      border: '2px solid',
      borderColor: `${schemaColor}.400`,
      bgColor: `${schemaColor}.400`,
      borderRadius: '0.8rem',
    },
    text: {
      color: 'schema.gray.100',
      fontWeight: '500',
    },
  },
  [SOLID]: {
    content: {
      border: '2px solid',
      borderColor: `${schemaColor}.500`,
      bgColor: `${schemaColor}.500`,
      borderRadius: '1.9rem',
    },
    text: {
      color: 'schema.white',
      fontWeight: '600',
    },
  },
});

const sizes = () => ({
  small: {
    container: { h: '22px', px: '0.75rem' },
    icon: { width: '12px', height: '12px', viewBox: '0 0 12 12' },
    text: {
      fontSize: '0.8rem',
      lineHeight: '1.2rem',
    },
  },
  md: {
    container: { h: '30px', px: '1rem', p: '0.8rem' },
    icon: { width: '20px', height: '20px', viewBox: '0 0 20 20' },
    text: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
    },
  },
  lg: {
    container: { h: '26px', px: '1rem' },
    text: {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
    },
  },
});

export const Tag = (props) => {
  const {
    as,
    size,
    tooltip,
    onClick,
    variant,
    fontSize,
    iconName,
    children,
    fontWeight,
    lineHeight,
    schemaColor,
    iconViewBox,
    letterSpacing,
    ...contentProps
  } = props;

  const tooltipRef = useRef();
  const style = VARIANTS.includes(variant)
    ? createVariant({ schemaColor })[variant]
    : createVariant({ schemaColor }).SOLID;
  const sizesStyle = sizes()?.[size];
  const IconComponent = iconSet[iconName]?.component ?? (() => <img alt="not found" />);

  const styleText = {};
  if (fontSize) styleText.fontSize = fontSize;
  if (lineHeight) styleText.lineHeight = lineHeight;
  if (fontWeight) styleText.fontWeight = fontWeight;
  if (letterSpacing) styleText.letterSpacing = letterSpacing;

  useEffect(() => () => ReactTooltip.hide(tooltipRef.current));

  return (
    <Flex
      ref={tooltipRef}
      p="0.5rem"
      alignItems="center"
      columnGap="0.5rem"
      onClick={onClick}
      {...style.content}
      {...sizesStyle.container}
      {...contentProps}
    >
      {iconName && <IconComponent {...sizesStyle.icon} viewBox={iconViewBox} />}
      <Text
        {...style.text}
        {...sizesStyle.text}
        {...styleText}
        data-tip={tooltip}
      >
        {children}
      </Text>
      {tooltip && <ReactTooltip backgroundColor="white" textColor="gray" borderColor="gray" />}
    </Flex>
  );
};

Tag.defaultProps = {
  tooltip: null,
  variant: SUBTLE,
  schemaColor: 'schema.gray',
  size: 'small',
  styleText: {},
  letterSpacing: '1px',
};

Tag.propTypes = {
  tooltip: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
  schemaColor: PropTypes.string,
  size: PropTypes.string,
  styleText: PropTypes.shape({}),
  letterSpacing: PropTypes.string,
};

export default Tag;
