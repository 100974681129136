import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { useFileUploaderOptions } from 'components/UpdateAddressField';
import { PROOF_OF_ADDRESS_STATUS, WS_EVENT_NAMES } from 'config/constants';
import { useEvent } from 'hooks/usePrivateInvestorChannel';
import { useProofOfAddressForm } from './useProofOfAddressForm';
import { getIsAddressCompleted } from '../../../utils/isAddressCompleted';
import { formatAddress } from '../../../utils/formatAddress';

const createViewFlags = ({ session, address }) => {
  if (!session) return ({});
  const {
    status: proofOfAddressStatus,
  } = session?.user?.proofOfAddress || {};
  const { user } = session;
  const { personalId, proofOfAddress } = user;
  const noDocumentoToMatch = personalId?.type ? personalId?.type !== 'national-id' : null;
  const proofOfAddressApproved = proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.APPROVED;
  if (proofOfAddress.addressValidated === false) {
    return {
      displayAlert: false,
      displayForm: true,
      displayConfirm: true,
    };
  }
  const displayAlert = !address ? false : proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.REJECTED
  || noDocumentoToMatch === true;
  const displayForm = !address ? true : proofOfAddressApproved || !noDocumentoToMatch;
  const displayConfirm = proofOfAddressApproved;

  return {
    displayAlert,
    displayForm,
    displayConfirm,
  };
};

export const useController = () => {
  const history = useHistory();
  const [isWaitingForChanges, setIsWaitingForChanges] = useState(false);
  const [session, { silentFetch: fetchSession }] = useReduxQuery('SESSION');
  const [, { silentFetch: fetchPersonalArchive }] = useReduxQuery('PERSONAL_ARCHIVES');
  const [address, { isLoading: isAddressLoading }] = useReduxQuery('PROFILE_ADDRESS');
  const [updateProfileAddress, { isLoading: isUpdateAddressLoading }] = useReduxMutator('UPDATE_PROFILE_ADDRESS');
  const [catalogData] = useReduxQuery('CATALOG');
  const statesCatalog = catalogData?.catalogs?.states || [];
  const idAddress = session?.user?.personalId?.address || '';
  const addressValidated = session?.user?.proofOfAddress?.addressValidated;
  const {
    status: proofOfAddressStatus,
    rejectReason: rejectingReason,
  } = session?.user?.proofOfAddress || {};
  const isAddressCompleted = getIsAddressCompleted(address);
  const onSubmitCallback = useCallback(() => {
    setIsWaitingForChanges(true);
  }, []);
  const onSuccess = useCallback(() => {
    setIsWaitingForChanges(false);
    fetchPersonalArchive();
    fetchSession();
  }, [fetchPersonalArchive, fetchSession]);
  const { control, onSubmit } = useProofOfAddressForm({
    address,
    onSuccess,
    statesCatalog,
    updateProfileAddress,
    proofOfAddressStatus,
    onSubmit: onSubmitCallback,
  });
  const onUploadSuccess = useCallback(async () => {
    setIsWaitingForChanges(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await fetchPersonalArchive();
    await fetchSession();
  }, [fetchPersonalArchive, fetchSession]);
  const onUpload = useCallback(() => {
    setIsWaitingForChanges(true);
  }, []);
  const addressFieldProps = useFileUploaderOptions({
    onUpload,
    onUploadSuccess,
  });
  const isLoading = isAddressLoading || isUpdateAddressLoading || addressFieldProps.isUploading;
  const {
    displayAlert,
    displayForm,
    displayConfirm,
  } = createViewFlags({ session, address });

  useEvent(WS_EVENT_NAMES.SESSION_UPDATING, () => {
    fetchSession();
    setIsWaitingForChanges(false);
  }, [fetchSession]);

  useEffect(() => {
    if (address) return;
    history.push('./update');
  }, [address]);

  return {
    control,
    onSubmit,
    idAddress,
    isLoading,
    displayForm,
    displayAlert,
    displayConfirm,
    rejectingReason,
    addressValidated,
    addressFieldProps,
    isAddressCompleted,
    isWaitingForChanges,
    address: formatAddress(address),
  };
};
