import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { WS_EVENT_NAMES } from 'config/constants';
import { createActions, useReduxQuery } from 'utils/redux-query';
import { parseEventMessage } from 'utils/parseWSEventMessages';
import { parseSession } from 'apis/auth/session/parser';

const {
  update: setSessionPayload,
} = createActions('SESSION');

export const useBindUpdatingSession = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [, { refetch: fetchUserExpedient }] = useReduxQuery('PERSONAL_ARCHIVES');

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const sessionPayload = parseSession(data);
    const meta = { status: 'COMPLETED', error: null };
    dispatch(setSessionPayload(
      sessionPayload,
      meta,
    ));
    if (sessionPayload.user.personalArchiveChanged === true) fetchUserExpedient();
  }, [
    dispatch,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.SESSION_UPDATING,
    callback,
  ), [
    callback,
    bindEvent,
  ]);
};
