import {
  css,
  styled,
  colors,
  textHelper,
  boxHelpers,
  shouldForwardProp,
} from 'styles';
import { SelectFieldInput } from 'components/SelectField/SelectField.style';

export const SelectInput = styled(SelectFieldInput)`
  border-color: ${colors.schema.gray[400]};

  ${({ hasError }) => (hasError ? css`
      border-color: ${colors.schema.red[500]};
    `
    : '')
}
`;

export const FieldContent = styled.div.withConfig({ shouldForwardProp })`
  display: grid;
  gap: 1rem;
  ${({ disabled }) => (disabled
    ? css`
    opacity: 0.5;
  `
    : '')}
  ${boxHelpers}
`;

export const TextHelper = styled.p`
  font-size: 1.2rem;
  color: ${colors.danger};
  margin-bottom: 1rem;
  ${boxHelpers}
`;

export const Label = styled.label`
  color: #93afb9;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  ${textHelper}
`;
