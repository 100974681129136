import camelify from 'camelcase-keys-recursive';
import snakecaseKeys from 'snakecase-keys';
import { request } from '../../fetchers/yotepresto';

export const BANK_ACCOUNTS_PATH = '/v1/investor/bank_accounts';

const parseRequestBody = ({ bankAccount, otp }) => {
  const result = {
    bankAccount,
    id: bankAccount.id ? Number(bankAccount.id) : undefined,
    otpCode: otp,
  };
  return snakecaseKeys(result);
};

const parseBankAccount = (data) => {
  const { bankAccount } = camelify(data);
  const {
    id,
    bank,
    name,
    clabe,
    status,
  } = bankAccount;
  const result = {
    id: String(id),
    name,
    clabe,
    status,
    bank: {
      ...bank,
      id: String(bank.id),
    },
  };
  return { bankAccount: result };
};

const parseBankAccounts = (data) => {
  if (!data) return null;
  const { bankAccounts } = camelify(data);
  const result = bankAccounts
    .map((bankAccount) => parseBankAccount({ bankAccount }).bankAccount);
  return {
    bankAccounts: result,
  };
};

export const fetchBankAccounts = async () => {
  const path = BANK_ACCOUNTS_PATH;
  const method = 'get';
  const config = { method, path };
  const { data } = await request(config);
  return parseBankAccounts(data);
};

export const createBankAccount = async ({ bankAccount, otp }) => {
  const path = BANK_ACCOUNTS_PATH;
  const method = 'post';
  const body = parseRequestBody({
    otp,
    bankAccount,
  });
  const config = { method, path, body };
  const { data } = await request(config);
  return parseBankAccount(data);
};

export const updateBankAccount = async ({ bankAccount, otp }) => {
  const path = BANK_ACCOUNTS_PATH;
  const method = 'put';
  const body = parseRequestBody({
    otp,
    bankAccount,
  });
  const config = { method, path, body };
  const { data } = await request(config);
  return parseBankAccount(data);
};

export const deleteBankAccount = async ({ bankAccount, otp }) => {
  const path = BANK_ACCOUNTS_PATH;
  const method = 'delete';
  const body = snakecaseKeys({
    id: bankAccount.id,
    otpCode: otp,
  });
  const config = { method, path, body };
  await request(config);
};
