import React, { useEffect, useRef } from 'react';
import { useSpring } from 'react-spring';
import { colors } from 'styles';

import {
  FieldLabel, LabelSpan, FieldInput, FieldSelect,
} from './styles';

export const Select = (props) => {
  const {
    children,
    isPristine,
    isActive,
    error,
    isFocused,
    input,
    otherProps,
    readOnly,
    placeText,
    labelText,
  } = props;
  const inputEl = useRef(null);

  const getBorderColor = () => {
    if (error) return colors.red;
    if (isFocused) return colors.blue;
    return colors.gray.g3;
  };

  const inputAnimation = useSpring({
    borderColor: getBorderColor(),
    color: isActive ? colors.gray.g6 : 'white',
    from: { borderColor: colors.gray.g3 },
  });

  const getLabelColor = () => {
    if (error) return colors.red;
    if (isFocused) return colors.blue;
    if (isPristine) return colors.gray.g4;
    return colors.gray.g4;
  };

  const labelAnimation = useSpring({
    top: isActive ? 1 : 13,
    color: getLabelColor(),
    fontSize: isActive ? '1.3rem' : '1.6rem',
    from: {
      top: 0,
    },
  });

  return (
    <FieldLabel>
      <FieldSelect
        style={inputAnimation}
        placeholder={placeText || ''}
        ref={inputEl}
        disabled={readOnly || false}
        {...input}
        {...otherProps}
      >
        {children}
      </FieldSelect>
      <LabelSpan style={labelAnimation}>{labelText}</LabelSpan>
    </FieldLabel>
  );
};

const InputField = ({
  isActive,
  isFocused,
  isPristine,
  error,
  isRadio,
  labelText,
  placeText,
  input,
  onChange,
  onBlur,
  onFocus,
  inputType,
  children,
  isChecked,
  name,
  readOnly,
  maxLength,
  focused,
  ...otherProps
}) => {
  const inputEl = useRef(null);

  const getLabelColor = () => {
    if (error) return colors.red;
    if (isFocused) return colors.blue;
    if (isPristine) return colors.gray.g4;
    return colors.gray.g4;
  };

  const getBorderColor = () => {
    if (error) return colors.red;
    if (isFocused) return colors.blue;
    return colors.gray.g3;
  };

  const labelAnimation = useSpring({
    top: isActive ? 1 : 13,
    color: getLabelColor(),
    fontSize: isActive ? '1.3rem' : '1.6rem',
    from: {
      top: 0,
    },
  });

  const inputAnimation = useSpring({
    borderColor: getBorderColor(),
    color: isActive ? colors.gray.g6 : 'white',
    from: { borderColor: colors.gray.g3 },
  });

  useEffect(() => {
    if (focused) {
      setTimeout(() => {
        inputEl.current.focus();
      }, 700);
    }
  }, []);

  const renderInput = () => {
    switch (inputType) {
      case 'radio':
        return (
          <FieldInput
            type="radio"
            checked={isChecked}
            onChange={onChange}
            {...otherProps}
            {...input}
          />
        );
      case 'select':
        return (
          <FieldSelect
            style={inputAnimation}
            placeholder={placeText || ''}
            ref={inputEl}
            {...otherProps}
            {...input}
            onChange={(ev) => {
              if (input) input.onChange(ev);
              if (onChange) onChange(ev);
            }}
            disabled={readOnly || false}
          >
            {children}
          </FieldSelect>
        );
      default:
        return (
          <FieldInput
            name={name}
            style={inputAnimation}
            placeholder={placeText || ''}
            onChange={onChange}
            {...otherProps}
            {...input}
            type={inputType || 'text'}
            onFocus={onFocus || input?.onFocus}
            onBlur={onBlur || input?.onBlur}
            disabled={readOnly || false}
            maxLength={maxLength || 'none'}
          />
        );
    }
  };
  return (
    <FieldLabel isRadio={isRadio}>
      {renderInput()}
      <LabelSpan isRadio={isRadio} style={!isRadio ? labelAnimation : {}}>
        {labelText}
      </LabelSpan>
    </FieldLabel>
  );
};

export default InputField;
