import React, { useEffect, useCallback } from 'react';
import { getMatiWidgetURL } from 'config';
import INEsvg from 'assets/identityVerification/ine.svg';
import Button from 'components/Button';
import { t } from 'i18n';

const { REACT_APP_MATI_CLIENT_ID, REACT_APP_MATI_FLOW_ID } = process.env;

const StartProcess = ({ userEmail, onFinish }) => {
  const matiButtonRef = React.createRef(null);

  const handleFinished = useCallback(() => {
    onFinish();
  }, []);

  useEffect(() => {
    const ref = matiButtonRef.current;
    ref.addEventListener('metamap:userFinishedSdk', handleFinished);
  }, [matiButtonRef, handleFinished]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = getMatiWidgetURL();
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const triggerMati = () => {
    const matiBitton = document.getElementsByTagName('metamap-button');
    if (matiBitton[0]) matiBitton[0].click();
  };

  return (
    <>
      <div className="info">
        <h2>{t('Mati.IdentityValidation.title')}</h2>
      </div>
      <div className="mati-container">
        <img src={INEsvg} alt="" className="image" />
        <Button onClick={triggerMati} className="mati-button">
          Subir identificación
        </Button>
        <metamap-button
          ref={matiButtonRef}
          clientid={REACT_APP_MATI_CLIENT_ID}
          flowId={REACT_APP_MATI_FLOW_ID}
          metadata={`{"email": "${userEmail}"}`}
        />
      </div>
    </>
  );
};

export default StartProcess;
