import React from 'react';
import { Icon } from 'ui';

export const UserIcon = (props) => (
  <Icon viewBox="0 0 29 29" {...props}>
    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="settings/icon/autoinvest-Icons/Menu/Config-Copy-2" fill="currentColor">
        <path d="M14.5892957,6.04166667 C12.6704624,6.04166667 11.1092958,7.60283329 11.1092958,9.52166657 C11.1092958,10.7589999 11.7569625,11.8767082 12.8432541,12.5122915 C13.0559208,12.6367498 13.1719208,12.8796248 13.1344624,13.1249165 C13.0982124,13.3689998 12.9157541,13.5659581 12.6752958,13.6215415 C10.5075458,14.1266248 8.69867083,15.4340414 7.58337918,17.3045414 C7.14596252,18.0379997 7.14112919,18.927333 7.57008752,19.6825413 C8.01717084,20.4679579 8.81829583,20.9367913 9.71367082,20.9367913 L19.4649207,20.9367913 C20.3602956,20.9367913 21.1614206,20.4679579 21.608504,19.6837496 C22.0374623,18.927333 22.032629,18.0379997 21.594004,17.303333 C20.4799206,15.4340414 18.6710457,14.1266248 16.5032957,13.6215415 C16.2628374,13.5659581 16.080379,13.3689998 16.0441291,13.1237081 C16.0066707,12.8796248 16.1226707,12.6367498 16.3353374,12.5122915 C17.421629,11.8767082 18.0692957,10.7589999 18.0692957,9.52166657 C18.0692957,7.60283329 16.508129,6.04166667 14.5892957,6.04166667 M19.4649204,22.1451246 L9.71367069,22.1451246 C8.37725407,22.1451246 7.18342077,21.4479163 6.52004579,20.279458 C5.87358748,19.141208 5.88325415,17.7975414 6.54542079,16.6858747 C7.62808743,14.8721664 9.27262904,13.5176248 11.2494623,12.7889998 C10.3987957,11.9238332 9.90096235,10.7505415 9.90096235,9.52166656 C9.90096235,6.93704162 12.0046706,4.83333333 14.5892955,4.83333333 C17.1739205,4.83333333 19.2776287,6.93704162 19.2776287,9.52166656 C19.2776287,10.7710832 18.7906704,11.9298748 17.9339621,12.7914165 C19.909587,13.5200415 21.551712,14.8733748 22.631962,16.6846664 C22.631962,16.6846664 22.631962,16.6858747 22.6331703,16.6858747 C23.2965453,17.7975414 23.3050036,19.141208 22.6585453,20.279458 C21.9951703,21.4479163 20.801337,22.1451246 19.4649204,22.1451246" id="Fill-1" />
      </g>
    </g>
  </Icon>
);
