export const styles = {
  panelInnerContent: {
    pt: 0,
    pb: 0,
    px: 0,
  },
  textContent: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: 'schema.gray.500',
  },
  noBeneficiaryContainer: {
    padding: 4,
    gap: 3,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: { base: 'column', sm: 'row' },
  },
  completeBeneficiaryButton: {
    width: { base: '100%', sm: 44 },
    minWidth: { base: '100%', sm: 44 },
  },
};
