import * as API from 'apis/account/autoinvestment';
import { createActions, createAsyncAction } from 'utils/redux-query';
import { fetchUserSignedContracts } from 'actions/settings/accountProfile';

export const { update: updateAIConfigs } = createActions('FETCH_ALL_AUTO_INV');

export const fetchAll = createAsyncAction('FETCH_ALL_AUTO_INV', () => () => API.fetchAll());

export const toggleActivation = createAsyncAction(
  'TOGGLE_ACTIVATION_AUTO_INV',
  ({ autoinvestId, password }) => async (dispatch) => {
    const autoInvestConfigs = await API.toggleActivation({ autoinvestId, password });

    dispatch(updateAIConfigs([autoInvestConfigs], { status: 'COMPLETED', error: null }));
    return autoInvestConfigs;
  },
);

export const create = createAsyncAction(
  'CREATE_AUTO_INV',
  ({ autoinvestConfig, otp }) => async (dispatch) => {
    const autoInvestConfigs = await API.create({ autoinvestConfig, otp });

    dispatch(updateAIConfigs([autoInvestConfigs], { status: 'COMPLETED', error: null }));
    dispatch(fetchUserSignedContracts());
  },
);

export const update = createAsyncAction(
  'UPDATE_AUTO_INV',
  ({ autoinvestConfig, password }) => async (dispatch, getState) => {
    const autoInvestConfigs = await API.update({ autoinvestConfig, password });

    if (autoInvestConfigs) {
      dispatch(updateAIConfigs([autoInvestConfigs], { status: 'COMPLETED', error: null }));

      return autoInvestConfigs;
    }

    const {
      FETCH_ALL_AUTO_INV: {
        payload: [currentAutoinvest],
      },
    } = getState();

    dispatch(
      updateAIConfigs([{ ...currentAutoinvest, ...autoinvestConfig }], {
        status: 'COMPLETED',
        error: null,
      }),
    );

    return autoInvestConfigs;
  },
);

export const destroy = createAsyncAction(
  'DESTROY_AUTO_INV',
  ({ autoinvestId, password }) => async (dispatch) => {
    const autoInvestConfigs = await API.destroy({ autoinvestId, password });

    dispatch(
      updateAIConfigs(autoInvestConfigs ? [autoInvestConfigs] : [], {
        status: 'COMPLETED',
        error: null,
      }),
    );
    dispatch(fetchUserSignedContracts());
  },
);
