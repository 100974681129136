import isNil from 'utils/isNil';
import { STEPS } from '../constants';

export const getRegisterStep = ({ profile }) => {
  const shouldFillJob = [
    isNil(profile.ocupationId),
    isNil(profile.sectorId),
    isNil(profile.activityId),
  ].some(Boolean);

  if (shouldFillJob) return STEPS.FILL_JOB;

  const shouldFillInvestmentPlan = [
    isNil(profile.monthlyFundingId),
    isNil(profile.depositAmountId),
    isNil(profile.fundingSourceId),
    isNil(profile.moneyDestinationId),
    // since some users was registered with empty investmentReason,
    // we need to check if it's empty string
    isNil(profile.investmentReason),
    profile.investmentReason === '',
  ].some(Boolean);

  if (shouldFillInvestmentPlan) return STEPS.FILL_INVESTMENT_PLAN;

  return STEPS.CONFIRM_PROFILE;
};
