import { useCallback, useEffect } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { useDeleteBankAccountForm } from './useDeleteBankAccountForm';

const defaultValues = {
  token: '',
};

export const useController = ({
  isOpen,
  onClose,
  bankAccount,
}) => {
  const [secondFactor, { status: { completed: is2FAComplete } }] = useReduxQuery('SECOND_FACTOR');
  const [profile] = useReduxQuery('PROFILE');
  const telephone = profile?.cellPhoneNumber;
  const clabe = bankAccount?.clabe;

  const {
    reset,
    control,
    setValue,
    onSubmit,
    isSubmitting,
  } = useDeleteBankAccountForm({
    clabe,
    onSuccess: onClose,
  });

  const onResendOTP = useCallback(() => {
    setValue('otpCode', '');
  }, [setValue]);

  const {
    otpFieldProps,
    showSMSContent,
  } = useAuthorizeWithOTP({
    telephone,
    onResendOTP,
    is2FAComplete,
    currentSecondFactor: secondFactor,
  });

  useEffect(() => {
    if (!isOpen) showSMSContent();
  }, [isOpen, showSMSContent]);

  const isLoading = [
    isSubmitting,
  ].includes(true);

  useEffect(() => {
    if (!isOpen && !bankAccount) reset(defaultValues);
    if (!bankAccount) return;
    const values = {
      token: '',
    };
    reset(values);
  }, [isOpen]);

  return {
    control,
    onSubmit,
    isLoading,
    otpFieldProps,
  };
};
