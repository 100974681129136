import { useCallback } from 'react';
import { useCatalogQuery } from './graphql/useCatalog';
import { validateIsBankNotFound, validateIsCreditCard } from '../resolver';

export const useBankCatalog = () => {
  const { data: catalogData, loading: isLoadingData } = useCatalogQuery();
  const banks = catalogData?.catalogs?.investorBanks || null;
  const isLoadingCatalog = isLoadingData;

  const getBankOptions = useCallback(({ clabe }) => {
    if (!clabe || !banks) return [];
    const ABMClabe = clabe.slice(0, 3);
    return banks.filter(({ abmNumber }) => abmNumber === ABMClabe);
  }, [
    banks,
  ]);

  const getIsBankNotFound = useCallback(
    ({ clabe }) => validateIsBankNotFound({
      clabe,
      bankCatalog: banks,
    }), [
      banks,
    ],
  );

  const getIsCreditCard = useCallback(
    ({ clabe }) => validateIsCreditCard({ clabe }), [
    ],
  );

  return {
    getBankOptions,
    getIsCreditCard,
    isLoadingCatalog,
    getIsBankNotFound,
    bankCatalog: banks,
  };
};
