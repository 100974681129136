const getStatusColors = (value) => {
  if (value === 'Solicitud Aperturada') return '#78DFCE';
  if (value === 'Al Corriente') return '#3FBF94';
  if (value === 'Solicitud Por Formalizar') return '#9A05CC';
  if (value === 'En Proceso de Pago') return '#0085D6';
  if (value === 'Solicitud En Fondeo') return '#79E3FB';
  if (value === 'Atrasado') return '#FFCC64';
  if (value === 'Vencido') return '#FC674A';
  if (value === 'Liquidado') return '#5ADF70';
  if (value === 'Liquidado con quita') return '#FA6400';
  return '#4F6F7B';
};

export default getStatusColors;
