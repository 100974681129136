import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import colors from 'styles/colors';
import { ResponsiveHelpers, shouldForwardProp, textHelper } from 'styles';
import { I18nText } from './I18nText/I18nText';

export const P = styled.p`
  ${textHelper}
  ${ResponsiveHelpers}
`;

const sizes = {
  small: css`
    color: ${colors.gray.g5};
    font-size: 1.2rem;
    line-height: 1.5rem;
  `,
  body: css`
    color: ${colors.gray.g5};
    font-size: 1.4rem;
    text-align: left;
    line-height: 2rem;
  `,
  normal: css`
    color: ${colors.gray.g5};
    font-size: 1.6rem;
    text-align: left;
    line-height: 2.4rem;
  `,
};

const types = {
  primary: css`
    color: ${colors.gray.g7};
  `,
  secondary: css`
    color: ${colors.gray.g5};
  `,
  third: css`
    color: ${colors.gray.g6};
  `,
  light: css`
    color: ${colors.white};
  `,
};

export const TextStyle = css`
  color: {colors.schema.gray[500]};
  font-size: 1.6rem;
  text-align: left;
  line-height: 2.4rem;
  ${textHelper}
  ${ResponsiveHelpers}
`;

const TextStyled = styled.p.withConfig({
  shouldForwardProp,
})`
  ${({ $size }) => sizes[$size]}
  ${({ $type }) => types[$type]}
  ${textHelper}
  ${ResponsiveHelpers}
`;

const HeadingStyled = styled.h2.withConfig({
  shouldForwardProp,
})`
  ${({ $size }) => sizes[$size]}
  ${({ $type }) => types[$type]}
  ${textHelper}
  ${ResponsiveHelpers}
`;

TextStyled.propTypes = {
  $type: PropTypes.oneOf(Object.keys(types)),
  $size: PropTypes.oneOf(Object.keys(sizes)),
};

TextStyled.defaultProps = {
  $type: 'primary',
  $size: 'normal',
};

export const Text = (props) => <I18nText TextComponent={TextStyled} {...props} />;
export const Heading = (props) => <I18nText TextComponent={HeadingStyled} {...props} />;
