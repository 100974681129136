import { FileUploader } from 'components/FileUploader/FileUploader';
import React from 'react';
import { Box } from 'ui';
import { tscope } from 'i18n';

const ts = tscope('Expedients.Address.show.updateAddressField');

export const UpdateAddressField = ({
  name,
  control,
  progress,
  isFinished,
  isUploading,
  onSubmitFile,
  ...props
}) => (
  <Box as="form" width="300px" {...props}>
    <FileUploader
      labelText={ts('label')}
      control={control}
      name={name}
      placeholder={ts('placeholder')}
      onSubmitFile={onSubmitFile}
      progress={progress}
      isFinished={isFinished}
      isUploading={isUploading}
      accept="application/pdf, image/jpeg, image/png"
      description={ts('description')}
    />
  </Box>
);
