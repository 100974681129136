import React, { useState } from 'react';
import { Text, Heading } from 'components/Typography';
import { Box, Flex, Grid } from 'styles';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { Form } from 'react-final-form';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import Modal, { CloseButton } from 'components/Modal';
import TokenField from 'components/Form/CustomFields/TokenField/TokenField';
import { t, jsx } from 'i18n';
import SecondFactorModal from 'views/DashboardContent/Activate2FAWizard/SecondFactorModal';
import { isEmail } from 'utils/email';
import { showToastError, showToastSuccess } from 'utils/toastHandler';
import { useGenerateOTP } from 'hooks';
import { OTP_TYPES } from 'config/constants';
import { DeactivateSecondFactorModal } from './components';

const ts = (id) => t(id, { scope: 'Dashboard.Settings.Account.ContactsPanel' });

const validator = ({ currentEmail }) => ({ email, otp }) => {
  const errors = {};

  if (!email) errors.email = ts('emailForm.fields.email.errors.required');
  if (email && !isEmail(email)) {
    errors.email = jsx('emailForm.fields.email.errors.invalidEmail', {
      t: ts,
      chars: (
        <span style={{ fontWeight: 900, fontSize: '1.5rem', fontStyle: 'italic' }}>
          {ts('emailForm.fields.email.errors.validEmailChars')}
        </span>
      ),
    });
  }
  if (!otp || otp?.length < 6) errors.otp = ts('emailForm.fields.otp.errors.required');
  if (email === currentEmail) errors.email = ts('emailForm.fields.email.errors.differentEmail');
  return errors;
};

export const EmailForm = (props) => {
  const { onSubmit = () => {}, onCancel = () => {} } = props;
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');
  const [session] = useReduxQuery('SESSION');
  const { sendToSMS } = useGenerateOTP();
  const [updateProfileEmail] = useReduxMutator('UPDATE_PROFILE_EMAIL');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeactivate2FAModal, setShowDeactivate2FAModal] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const hasSecondFactorActivated = secondFactorStatus.completed && secondFactor;
  const otpType = secondFactor?.otpType;
  const currentEmail = session?.user?.email ?? '';

  const handleClose = (form) => {
    setShowConfirmModal(false);
    form.change('otp', '');
  };

  const handleSubmitForm = async ({ otp, email }) => {
    try {
      await updateProfileEmail({ otpCode: otp, email });
      setShowConfirmModal(false);
      showToastSuccess(ts('alerts.success.emailUpdated'));
      onSubmit();
    } catch (error) {
      if (error.response.status === 422) {
        showToastError(t('Dashboard.activate2FAWizard.steps.toasts.invalidCode'));
      } else {
        showToastError(
          t('Dashboard.Settings.Account.ContactsPanel.alerts.error.failedUpdateEmail'),
        );
      }
    }
  };

  const handleOpenConfirmModal = () => {
    if (otpType === OTP_TYPES.SMS) {
      sendToSMS();
    }
    setShowConfirmModal(true);
  };

  const resendHandler = () => {
    sendToSMS();
  };

  return (
    <Form
      initialValues={{ email: '', otp: '' }}
      onSubmit={handleSubmitForm}
      validate={validator({ currentEmail })}
    >
      {({
        handleSubmit, touched, errors, valid, submitting, validating, form,
      }) => (
        <Grid
          as="form"
          flexBasis="100%"
          onSubmit={handleSubmit}
          p="2rem"
          justifyItems="stretch"
          gap="2rem"
          template="auto / 1fr 1fr;"
        >
          <TextField
            color="schema.gray.700"
            textLabel={ts('emailForm.fields.email.textLabel')}
            name="email"
            type="email"
            gridArea="1 / 1 / 1 / 3"
            inputProps={{ fontWeight: '500' }}
          />
          {!hasSecondFactorActivated && (
          <Box gridArea="2 / 1 / 2 / 3">
            <Heading as="h3" mb="1rem">
              {ts('enable2FA_CTANotice.title')}
            </Heading>
            <Text mb="2rem">{ts('enable2FA_CTANotice.description')}</Text>
            <Button type="button" $type="primary" onClick={() => setShowWizard(true)}>
              {ts('enable2FA_CTANotice.okButton')}
            </Button>
          </Box>
          )}
          <Flex
            gridArea={hasSecondFactorActivated ? '2 / 2' : '3 / 2'}
            justifyContent="flex-end"
            gap="2rem"
          >
            <Button $type="btn-link" type="button" onClick={onCancel}>
              {ts('emailForm.actions.cancel')}
            </Button>
            <Button
              $type="primary"
              type="button"
              onClick={() => handleOpenConfirmModal()}
              disabled={!touched.email || errors.email || !hasSecondFactorActivated}
            >
              {ts('emailForm.actions.change')}
            </Button>
          </Flex>
          <Modal
            isOpen={showConfirmModal}
            onRequestClose={() => handleClose(form)}
            contentStyle={{ maxWidth: '40rem' }}
          >
            <CloseButton onClick={() => handleClose(form)} />
            <Flex direction="column" p="4rem" rowGap="3rem">
              <Heading>{ts('confirmModal.title')}</Heading>
              <Text>{ts('confirmModal.description')}</Text>
              <TokenField
                name="otp"
                color="schema.gray.700"
                w="100%"
                fieldSize="40"
                fluid
                textLabel={(
                  <Text as="span">
                    {ts('emailForm.fields.otp.textLabel')}
                    {' '}
                    <Text as="span" color="schema.gray.500">
                      (
                      {t(`common.secondFactor.types.${secondFactor?.otpType}`)}
                      )
                    </Text>
                  </Text>
                )}
              />
              {otpType === OTP_TYPES.SMS && (
                <Text size="small" color="schema.gray.500">
                  {ts('alerts.reSend.0')}
                  <Button
                    fontSize="1.2rem"
                    lineHeight="1.5rem"
                    $type="link"
                    fontWeight="normal"
                    ml="0.5rem"
                    onClick={resendHandler}
                  >
                    {ts('alerts.reSend.1')}
                  </Button>
                </Text>
              )}
              <Flex justifyContent="flex-end" gap="2rem" mt="1rem">
                <Button
                  flexGrow="1"
                  flexBasis="0"
                  $type="btn-link"
                  type="button"
                  schema="gray"
                  onClick={() => handleClose(form)}
                >
                  {ts('emailForm.actions.cancel')}
                </Button>
                <Button
                  disabled={[!valid, submitting, validating].some(Boolean)}
                  flexGrow="1"
                  flexBasis="0"
                  $type="primary"
                  schema="green"
                  type="button"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {ts('emailForm.actions.submit')}
                </Button>
              </Flex>
            </Flex>
            <Grid
              borderColor="schema.gray.200"
              borderTop="1px solid"
              template="auto auto / 1fr 1fr"
              p="2rem"
              gap="0.5rem"
            >
              <Text fontSize="1.4rem" gridArea="1 / 1 / 1 / 3">
                {ts('confirmModal.footer.title')}
              </Text>
              <Text
                color="schema.blue.500"
                fontSize="1.4rem"
                gridArea="2 / 1"
                textDecoration="none"
                justifySelf="center"
                alignSelf="start"
                lineHeight="2rem"
                as="a"
                href={t('common.helpWith2faLink')}
                target="blank"
              >
                {ts('confirmModal.footer.helpCenterLinkText')}
              </Text>
              <Button
                type="button"
                $type="link"
                fontSize="1.4rem"
                gridArea="2 / 2"
                fontWeight="normal"
                onClick={() => {
                  setShowConfirmModal(false);
                  setShowDeactivate2FAModal(true);
                }}
              >
                {ts('confirmModal.footer.disable2FAButton')}
              </Button>
            </Grid>
          </Modal>
          <SecondFactorModal
            isOpen={showWizard}
            onRequestClose={() => {
              setShowWizard(false);
            }}
          />
          <DeactivateSecondFactorModal
            isOpen={showDeactivate2FAModal}
            onClose={() => {
              setShowConfirmModal(false);
              setShowDeactivate2FAModal(false);
            }}
          />
        </Grid>
      )}
    </Form>
  );
};
