const cleanCollection = (collection = []) => collection
  .filter((item) => !!item.estado || Object.keys(item).length > 1);

const parser = ({ data, params }) => {
  if (!data) return { collection: [] };
  const { operation, collection = [] } = data;

  const parsedCollection = cleanCollection(collection);

  if (operation === 'caching') return data;

  if (operation === 'empty_collection' || (parsedCollection.length === 0 && !params)) return null;

  return { ...data, collection: parsedCollection };
};

export default parser;
