import React from 'react';
import { ReactComponent as MagnifierIcon } from 'assets/magnifier.svg';
import ButtonContainer from './styles';

const SearchButton = (props) => {
  const {
    children, handleClick, type, color, disabled,
  } = props;
  return (
    <ButtonContainer
      onClick={handleClick}
      type={type || 'button'}
      color={color || 'primary'}
      disabled={disabled}
      {...props}
    >
      <MagnifierIcon title="" />
      {children}
    </ButtonContainer>
  );
};

export default SearchButton;
