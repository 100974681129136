import { useCallback, useEffect } from 'react';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { debounceLeading } from 'utils/Monads';
import { WS_EVENT_NAMES } from 'config/constants';

export const useBindAccountStatementBrief = ({
  bindEvent,
}) => {
  const [session] = useReduxQuery('SESSION');
  const needsDiversification = session?.settings?.needsDiversification;
  const [, { refetch: fetchAccountStatement }] = useReduxSubscription('FETCH_ACCOUNT_STATEMENTS');
  const [, { refetch: fetchDiversifications }] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');

  const callback = useCallback(debounceLeading(() => {
    fetchAccountStatement();
    if (needsDiversification) fetchDiversifications();
  }, 300), [
    needsDiversification,
    fetchAccountStatement,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.ACCOUNT_BALANCE_BRIEF,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
