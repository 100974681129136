import React from 'react';
import { Link } from 'react-router-dom';
import BackArrowSVG from 'assets/backArrow.svg';
import { ReactComponent as HideIcon } from 'assets/hideIcon.svg';
import { t } from 'i18n';
import Collapse from 'components/Collapse';
import HeaderElementsContainer, { TitleRequisition, ContainerIconHide } from './styles';

const HeaderElements = (props) => {
  const {
    isMobile,
    zellID,
    numberOfCredits,
    isHidden,
    onHideRequisition,
    showHideIcon,
    isLoading,
    backTo,
  } = props;
  return (
    <HeaderElementsContainer className={!showHideIcon ? 'funded' : ''}>
      {isMobile && backTo && (
        <Link className="closeButton" to={backTo}>
          <img src={BackArrowSVG} alt="back" />
          {' '}
          {t('common.backButton')}
        </Link>
      )}
      {!isLoading && (
        <div className="idRequisitionHideButton">
          <TitleRequisition>{t('common.requisition')}</TitleRequisition>
          <span className="number">
            No.
            {zellID}
          </span>
          {numberOfCredits > 1 && <Collapse value={numberOfCredits} hasSuffix={false} {...props} />}

          {showHideIcon && (
            <ContainerIconHide isHiddenMobile>
              <span
                role="button"
                tabIndex="0"
                className="hideIcon"
                onClick={onHideRequisition}
                onKeyPress={onHideRequisition}
              >
                <HideIcon className={`hideIcon--icon ${isHidden ? 'active' : ''}`} title="" />
              </span>
            </ContainerIconHide>
          )}
        </div>
      )}
    </HeaderElementsContainer>
  );
};

export default HeaderElements;
