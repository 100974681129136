import styled from 'styled-components';

import { breakPoints } from 'config/constants';
import colors from 'styles/colors';

const { midBreakPoint } = breakPoints;

const IdentityVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: ${midBreakPoint}px) {
    grid-column: 5 / -1;
  }
  .info {
    text-align: center;
    max-width: 320px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-size: 2.4rem;
      color: ${colors.gray.g7};
      margin-bottom: 0.8rem;
    }
    p {
      color: ${colors.gray.g5};
    }
  }

  .mati-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #04475c;
    font-size: 1.4rem;
    padding: 2.4rem 3.3rem 3rem 3.3rem;
    max-width: 330px;
    &.white {
      background-color: white;
      margin-top: 1rem;
      color: #677d85;
      b {
        color: ${colors.gray.g7};
      }
    }
    .image {
      margin-bottom: 3.5rem;
    }
  }

  .benefits {
    text-align: center;
    max-width: 240px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .list {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ul {
    display: inline-flex;
    flex-direction: column;
    margin-top: 2.1rem;
    margin-bottom: 2.1rem;
  }

  .li {
    display: inline-block;
  }

  .ul .li:before {
    content: '✓';
    font-family: monospace;
    font-size: 2rem;
    color: green;
    margin-right: 1rem;
  }

  metamap-button {
    display: none;
  }

  .mati-button {
    padding: 1.4rem 5rem 1.4rem 5rem;
  }

  .info-footer {
    padding-top: 3.4rem;
    padding-bottom: 3rem;
    color: #677d85;
    max-width: 350px;
    text-align: center;
    p {
      margin-left: -2rem;
      margin-right: -2rem;
      font-size: 1.5rem;
    }
    .divider {
      border: 1px solid transparent;
      border-bottom: 1px solid #e7ebef;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      height: 1px;
    }
    .highlight {
      color: ${colors.gray.g7};
    }
  }
`;

export default IdentityVerificationContainer;
