import React from 'react';
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import { colors } from 'styles';
import chevronDownSvg from './chevronDown.svg';

const { gray } = colors;

const fieldStyles = css`
  border: 1px solid;
  border-radius: 0.4rem;
  border-color: ${colors.gray.g3};
  background-color: white;
  outline: none;
  width: 100%;
  height: 5rem;
  padding: 1.2rem 0 0 1rem;
  font-size: inherit;
  font-weight: 500;
  color: transparent;
  appearance: none;
`;

const styledLabel = ({ isRadio, ...otherProps }) => <label {...otherProps} />;

export const FieldLabel = styled(styledLabel)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isRadio ? 'center' : '')};
  justify-content: ${(props) => (props.isRadio ? 'center' : '')};
  position: relative;
  width: ${(props) => (props.isRadio ? '12rem' : '100%')};
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  text-rendering: optimizelegibility;
  transition: all 0.2s ease;
  padding: 0 0.3rem;
  ${(props) => props.isRadio
    && css`
      &:hover input,
      &:hover span {
        border-color: ${colors.gray.g5};
        color: ${colors.gray.g5};
        cursor: pointer;
      }
      & input:checked,
      & input:checked + span {
        color: ${colors.blue};
        font-weight: 500;
        border-color: ${colors.blue};
      }
      & input:checked {
        box-shadow: rgba(1, 46, 71, 0.08) 0px 6px 10px 0px;
      }
    `}
`;

const AnimatedSpan = animated.span;
const styledSpan = ({ isMobile, isRadio, ...otherProps }) => <AnimatedSpan {...otherProps} />;
export const LabelSpan = styled(styledSpan)`
  line-height: 2.4rem;
  position: absolute;
  top: 1.3rem;
  padding-left: ${(props) => (props.isRadio ? '0' : '1rem')};
  color: #adbcbf;
  cursor: ${(props) => (props.isRadio ? 'pointer' : 'text')};
`;

export const FieldInput = styled(animated.input)`
  ${fieldStyles}
  transition: all 0.2s ease;
  &::placeholder {
    opacity: 0;
  }
  &:focus::placeholder {
    opacity: 1;
    color: ${colors.gray.g4};
  }
  &:disabled {
    background-color: ${gray.g1};
  }
`;

export const FieldSelect = styled(animated.select)`
  ${fieldStyles}
  background-color: white;
  background-image: url(${chevronDownSvg});
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.5rem;

  option {
    color: ${colors.schema.gray[500]};
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  & .date-group__field {
    &__day {
      width: 25%;
    }
    &__month {
      width: 50%;
      margin: 0 1rem;
    }
    &__year {
      width: 25%;
    }
  }
  :focus {
    outline: none;
  }
`;

export const ErrorSpan = styled.div`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: ${colors.red};
  min-width: 100%;
`;
