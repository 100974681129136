import styled from 'styled-components';
import colors from 'styles/colors';
import ReactTooltip from 'react-tooltip';

export const types = { success: { bgColor: colors.successLight, textColor: colors.success } };

export const typesKeys = Object.keys(types).reduce((acc, key) => ({ ...acc, [key]: key }), {});

export const BadgeContainer = styled.div`
  margin-top: 0.7rem;
  padding-top: 0.2rem;
  margin-right: 1rem;
  text-align: center;
  height: 2.5rem;
  width: 3.6rem;
  box-sizing: inherit;
  border-radius: 1.45rem;
  background-color: ${({ $type }) => types[$type].bgColor};
  box-shadow: 0 2px 6px 0 rgba(157, 187, 192, 0.5);
`;

export const Text = styled.p`
  font-size: 1.5rem;
  display: inline;
  color: ${({ $type }) => types[$type].textColor};
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2rem;
  margin-right: 0.1rem;
`;

export const Suffix = styled.abbr`
  color: ${({ $type }) => types[$type].textColor};
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 9px;
  vertical-align: 0.8rem;
`;

export const Tooltip = styled(ReactTooltip)`
  box-sizing: border-box !important;
  border-radius: 0.5rem !important;
  border: 0.05rem solid #a8c9d6 !important;
  border-bottom-color: 0.5rem solid #a8c9d6 !important;
`;

export const TooltipBody = styled.p`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: #7caaba;
  font-weight: normal;
`;
