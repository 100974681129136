import React, { useState } from 'react';
import { t } from 'i18n';
import SecondFactorThumbnail from 'assets/security/second-factor-thumbnail.svg';
import {
  AddFundsBannerContent,
  Body,
  VideoButton,
  AddFundsVideoModal,
} from './AddFundsBanner.styles';

function AddFundsBanner(props) {
  const { ...contentProps } = props;
  const [isOpenModal, toggleModal] = useState(false);

  const handlePlayVideoClick = () => {
    toggleModal(true);
  };

  const handleOnRequestCloseModal = () => {
    toggleModal(false);
  };

  return (
    <AddFundsBannerContent {...contentProps}>
      <Body>
        {t('addFunds.bannerTop.body.part1')}
        {' '}
        <b>{t('addFunds.bannerTop.body.part2')}</b>
        {' '}
        {t('addFunds.bannerTop.body.part3')}
      </Body>
      <VideoButton $type="transparent" $isNotAnimated onClick={handlePlayVideoClick} h="10.4rem">
        <img
          src={SecondFactorThumbnail}
          className="player"
          style={{ maxWidth: '18.4rem' }}
          alt={t('addFunds.bannerTop.playVideoAlt')}
        />
      </VideoButton>
      <AddFundsVideoModal
        isOpen={isOpenModal}
        onRequestClose={handleOnRequestCloseModal}
        shouldCloseOnOverlayClick
        closeOnSessionEnds
      >
        <iframe
          title="agregar-fondo-vid"
          src={t('addFunds.bannerTop.videoURL')}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          width="1200"
          height="675"
        />
      </AddFundsVideoModal>
    </AddFundsBannerContent>
  );
}

export default AddFundsBanner;
