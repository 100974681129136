import { useState } from 'react';

export const useController = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  return {
    onOpenModal,
    isModalOpen,
    onCloseModal,
  };
};
