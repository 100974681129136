import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as Calendar } from 'assets/sidebar/calendarIcon.svg';
import { ReactComponent as EditIcon } from './editIcon.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as QuestionMark } from './questionMark.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as AutoInvest } from './autoInvest.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as WhatsApp } from './whatsapp.svg';
import { ReactComponent as Location } from './location.svg';
import { ReactComponent as Cellphone } from './cellphone.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Danger } from './danger.svg';
import { ReactComponent as FileDownload } from './fileDownload.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Time } from './time.svg';

const iconSet = {
  COPY: { component: Copy },
  STAR: { component: Star },
  BACK: { component: Back },
  MAIL: { component: Mail },
  TIME: { component: Time },
  TRASH: { component: Trash },
  CHECK: { component: Check },
  EDIT: { component: EditIcon },
  DANGER: { component: Danger },
  CLOSE: { component: CloseIcon },
  REFRESH: { component: Refresh },
  CALENDAR: { component: Calendar },
  LOCATION: { component: Location },
  WHATS_APP: { component: WhatsApp },
  CELLPHONE: { component: Cellphone },
  QUESTION: { component: QuestionMark },
  AUTO_INVEST: { component: AutoInvest },
  FILE_DOWNLOAD: { component: FileDownload },
};

export default iconSet;
