import { tscope } from 'i18n';

const errorsT = tscope('Forms.fields.errors');

const buildError = ({ message }) => ({
  message,
  type: 'danger',
});

export const resolver = (values) => {
  const {
    token,
  } = values;
  const errors = {};
  if (!token) errors.token = buildError({ message: errorsT('required') });

  return {
    values,
    errors,
  };
};
