import { css, keyframes } from 'styled-components';

export const slideDown = css`
  transition: max-height 355ms, opacity 355ms;
  transition-timing-function: ease-in-out;

  ${(props) => (props.show
    ? css`
          opacity: 1;
          max-height: 50rem;
        `
    : css`
          opacity: 0;
          max-height: 0rem;
        `)}
`;

export const wipeEnterKeyframe = keyframes`
0% {
  transform: scale(0, 0.05);
}
50% {
  transform: scale(1, 0.05);
}
`;

export const wipeEnter = css`
  animation: ${wipeEnterKeyframe} 750ms linear 1;
`;
