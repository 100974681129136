import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import get from 'lodash.get';
import { useReduxQuery } from 'utils/redux-query';
import { Box, Flex } from 'styles';
import ButtonIcon from 'components/Button/ButtonIcon';
import FilterButton from 'views/FilterButton';
import { t } from 'i18n';
import InfoBar from './InfoBar';
import PortfolioList from './list';
import Pagination from '../../../MyMovements/Pagination';
import Loading from '../Loading';
import ReturnSearchHeader from './ReturnSearchHeader';
import SearchGroup from '../../SearchGroup';
import Footer from '../../../FooterDashboard';

const ContentArea = (props) => {
  const {
    onApplyFilter, onResetFilter, filters, onSearchByZellId, onOpenFilterClick,
  } = props;
  const [portfolio, { status: portfolioStatus }] = useReduxQuery('PORTFOLIO');

  const { totalRows } = portfolio || {};

  const isEmptyCollection = portfolio?.collection?.length === 0 || portfolio?.operation === null;

  const isFilteredById = get(filters, 'zell_app_id');

  return (
    <Flex direction="column" justifyContent="space-between" h="100%">
      <Flex grow="1" direction="column">
        {isFilteredById && <ReturnSearchHeader onBack={onResetFilter} />}
        {!isEmptyCollection && !isFilteredById && <InfoBar onOpenFilterClick={onOpenFilterClick} />}
        {portfolioStatus.pending && <Loading my="auto" />}
        {portfolioStatus.completed && (
          <Flex alignItems="center" pl="4rem" columnGap="2rem" pr="1rem">
            <FilterButton
              display={{ base: 'none', md: 'flex', lg: 'none' }}
              onClick={onOpenFilterClick}
            >
              Filtrar
            </FilterButton>
            <ButtonIcon
              variant="subtle"
              title={t('Portfolio.default.updateList')}
              schema="gray"
              iconName="REFRESH"
              isRounded
              onClick={() => onApplyFilter(filters)}
            />
            {!isFilteredById && (
              <SearchGroup
                ml="auto"
                className="right"
                justifyContent="flex-end"
                onSearchByIdSubmit={onSearchByZellId}
              />
            )}
          </Flex>
        )}
        {portfolioStatus.completed && portfolio.collection && <PortfolioList />}
      </Flex>
      <Box w="100%" grow="0" flexShrink="0">
        {portfolioStatus.completed && (
          <Pagination
            totalRows={totalRows}
            currentPage={filters?.page}
            handlePageChange={(page) => onApplyFilter({ page })}
            ml="0"
          />
        )}
        <Footer flexGrow="0" />
      </Box>
    </Flex>
  );
};

export default ContentArea;
