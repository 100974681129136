import { createContext, useContext } from 'react';

export const FeaturesContext = createContext();

export const FeaturesProvider = FeaturesContext.Provider;

export const Feature = ({ name, children, fallbackComponent = null }) => {
  const flags = useContext(FeaturesContext);
  if (!children) return null;

  return flags[name] ? children : fallbackComponent;
};

export const useFeatureFlag = () => useContext(FeaturesContext);
