import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useReduxMutator } from 'utils/redux-query';
import Button from 'components/Button/Button';
import { Box, Flex } from 'styles';
import { t } from 'i18n-js';
import { Text } from 'components/Typography';
import TokenField from 'components/Form/CustomFields/TokenField/TokenField';
import defaultValidator from 'components/OTPForm/config/validator';
import composeValidators from 'components/OTPForm/config/composeValidators';
import { showToastError } from 'utils/toastHandler';
import DotsLoader from 'components/DotsLoader';
import { useGenerateOTP } from 'hooks';

const STAGES = ['initial', 'active', 'done'];
const [INITIAL, ACTIVE, DONE] = STAGES;

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.2FAInstructions', ...options });

const EmailOTPForm = (props) => {
  const {
    type, phone, setBulletStage, customValidator = () => ({}),
  } = props;
  const [requestChange, { status: requestChangeStatus }] = useReduxMutator(
    'REQUEST_CHANGE_SECOND_FACTOR',
  );
  const { sendToEmail } = useGenerateOTP();
  const [stage, setStage] = useState(INITIAL);

  const handleFirstStage = () => {
    sendToEmail();
    setStage(ACTIVE);
  };

  const handleSecondStage = async (values) => {
    const { otp } = values;
    const requestChangeParams = {
      type,
      phone,
      otpCode: otp,
      activate: true,
    };
    requestChange(requestChangeParams);
  };

  const resendHandler = () => {
    sendToEmail();
  };

  useEffect(() => {
    if (!requestChangeStatus.completed) return;
    setStage(DONE);
    setBulletStage(['success', 'active']);
  }, [requestChangeStatus.completed]);

  useEffect(() => {
    if (!requestChangeStatus.failed) return;
    showToastError(t('settings.security.errors.invalidCode'));
  }, [requestChangeStatus.failed]);

  const validator = useCallback(composeValidators(defaultValidator, customValidator), [
    customValidator,
  ]);

  switch (stage) {
    case INITIAL: {
      return (
        <Box>
          <Button mb="1rem" onClick={handleFirstStage}>
            {ts('buttons.sendButton')}
          </Button>
        </Box>
      );
    }
    case ACTIVE: {
      return (
        <Flex direction="column" rowGap="1rem" py="0.5rem">
          <Form onSubmit={handleSecondStage} validate={validator}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Flex
                  columnGap="2rem"
                  rowGap="2rem"
                  width="100%"
                  my="1rem"
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <TokenField data-testid="otp-email" />
                  <Button size="medium" type="submit" disabled={requestChangeStatus.pending}>
                    {requestChangeStatus.pending ? (
                      <DotsLoader color="schema.gray.300" />
                    ) : (
                      ts('buttons.continueButton')
                    )}
                  </Button>
                </Flex>
              </form>
            )}
          </Form>
          <Text size="small" color="schema.gray.500">
            {ts('troubleshooting.reSend.0')}
            <Button
              fontSize="1.2rem"
              lineHeight="1.5rem"
              $type="link"
              fontWeight="normal"
              ml="0.5rem"
              onClick={resendHandler}
            >
              {ts('troubleshooting.reSend.1')}
            </Button>
          </Text>
        </Flex>
      );
    }
    default: {
      return null;
    }
  }
};

EmailOTPForm.propTypes = {
  phone: PropTypes.string,
  type: PropTypes.string.isRequired,
};

EmailOTPForm.defaultProps = {
  phone: '',
};

export default EmailOTPForm;
