export const transformErrors = ({ errors, tickets }) => {
  const ticketsErrors = Object.entries(errors).map(([message, ticketIds]) => {
    const ticketsWithError = ticketIds
      .map((ticketId) => tickets.find((ticket) => ticket.id === ticketId))
      .filter(Boolean);

    return { error: message, ticketsData: ticketsWithError };
  });

  return ticketsErrors;
};

export const countTotalOrdersForEachCategory = (ordersByCategory) => ordersByCategory
  .map((orders) => Object.values(orders)[0])
  .reduce((count, orders) => count + orders.length, 0);
