import styled from 'styled-components';
import { colors } from 'styles';

const ZapopanMessageContainer = styled.div`
  display: flex;
  position: relative;
  padding: 1.8rem 2.5rem;
  border: 1px solid #f3f3bb;
  background-color: #ffffff;
  margin-top: 3.5rem;
  color: black;
  justify-content: space-between;
  border-radius: 7px;
  p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    color: #677d85;
  }
  .content {
    display: flex;
  }
  .badge {
    position: absolute;
    left: -1rem;
    top: -1rem;
    width: 2.3rem;
    height: 2.6rem;
    border-radius: 7px;
    background-color: #f3f3bb;
    box-shadow: 0 2px 4px 0 rgba(55, 77, 91, 0.17);
    font-size: 1rem;
    font-weight: bold;
    color: #374d5b;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .moreInfo {
    text-decoration: underline;
    color: ${colors.blue};
    font-size: 1rem;
    :hover {
      cursor: pointer;
    }
    :focus {
      outline: none;
    }
    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
    }
  }
`;

export default ZapopanMessageContainer;
