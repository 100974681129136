import React, {
  useState, useEffect, createContext, useContext,
} from 'react';

const UPDATE_EVERY_TIME = 1000;

function useTimer(
  initialTime = 0,
  { onFinish = () => {}, checkEveryTime = UPDATE_EVERY_TIME } = {},
) {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time === 0) return () => {};

    function tick() {
      setTime((_time) => _time - checkEveryTime);
    }

    const intervalPID = setInterval(tick, checkEveryTime);

    if (time <= 0) {
      onFinish();
      clearInterval(intervalPID);
    }

    return () => clearInterval(intervalPID);
  }, [time, checkEveryTime]);

  return {
    time,
    humanize: { seconds: (time / 1000).toFixed(0) },
    reinitialize: setTime,
    setTime,
  };
}

export default useTimer;

const timerContext = createContext({ time: 0, setTime: () => {} });

export const GlobalTimerProvider = ({ initialTime = 0, children }) => {
  const [time, setTime] = useState(initialTime);

  return <timerContext.Provider value={{ time, setTime }}>{children}</timerContext.Provider>;
};

export function useGlobalTimer({ onFinish }) {
  const context = useContext(timerContext);
  const { time, setTime } = context || {};
  useEffect(() => {
    function tick() {
      setTime((_time) => _time - 1000);
    }

    const intervalPID = setInterval(tick, 1000);

    if (time <= 0) {
      if (onFinish) onFinish();
      clearInterval(intervalPID);
    }

    return () => clearInterval(intervalPID);
  }, [time, onFinish]);

  return {
    time,
    humanize: { seconds: (time / 1000).toFixed(0) },
    reinitialize: setTime,
  };
}
