import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import PATHS, { investorTransactions } from 'routes';
import { useReduxSubscription } from 'utils/redux-query';
import { tabKeys } from '../utils';
import { BarElement, StyledLink } from '../styles';

const BarItem = (props) => {
  const {
    barItem, onClick, showSpark, isActive, disabled,
  } = props;
  const [displaySpark, toggleSpark] = useState(showSpark);

  useEffect(() => {
    if (showSpark) {
      toggleSpark(showSpark);
    }
  }, [showSpark]);

  const handleOnClickBarItem = () => {
    onClick(barItem);
    toggleSpark(false);
  };

  return (
    <StyledLink as={disabled ? 'p' : Link} to={barItem.path} key={barItem.name} disabled={disabled}>
      <BarElement
        data-testid={barItem.name}
        onClick={handleOnClickBarItem}
        active={isActive}
        isReady={displaySpark}
        disabled={disabled}
        justifyContent={{ base: 'flex-start', sm: 'center', md: 'flex-start' }}
      >
        <span>
          <div className="icon-wrapper">
            {barItem.icon}
            {displaySpark && <div className="ready-mark" />}
          </div>
          <div className="name-wrapper">{barItem.name}</div>
        </span>
      </BarElement>
    </StyledLink>
  );
};

const SmartBarItem = (props) => {
  const {
    barItem, toggleSidebar, onClick, disabled,
  } = props;
  const match = useRouteMatch();
  const isActive = match.url === barItem.path;
  const matchSettingsRoutes = useRouteMatch(PATHS.investorSettingsPath());
  const matchMovementsPath = useRouteMatch(investorTransactions);
  const matchPortfolioPath = useRouteMatch(PATHS.investorPortfolioPath());
  const [movements, { status: movementsStatus, countMovementsCalls }] = useReduxSubscription('MOVEMENTS');
  const [portfolio, { status: portfolioStatus, countCalls: countPortfolioCalls }] = useReduxSubscription('PORTFOLIO');

  const [showSparkMovements, setShowSparkMovements] = useState(false);
  const [showSparkPortfolio, setShowSparkPortfolio] = useState(false);

  useEffect(() => {
    const showSpark = barItem.id === tabKeys.MOVEMENTS_TAB
      && movementsStatus.completed
      && (movements?.ready || countMovementsCalls === 1);

    setShowSparkMovements(showSpark);
  }, [movementsStatus.completed, movements?.ready, countMovementsCalls]);

  useEffect(() => {
    if (matchMovementsPath) {
      setShowSparkMovements(false);
    }
  }, [matchMovementsPath]);

  useEffect(() => {
    const showSpark = barItem.id === tabKeys.LOANS_TAB
      && portfolioStatus.completed
      && (portfolio?.ready || countPortfolioCalls === 1);

    setShowSparkPortfolio(showSpark);
  }, [portfolioStatus.completed, portfolio?.ready, countPortfolioCalls]);

  useEffect(() => {
    if (matchPortfolioPath) {
      setShowSparkPortfolio(false);
    }
  }, [matchPortfolioPath]);

  const handleOnClickBarItem = () => {
    if (toggleSidebar) toggleSidebar();
    if (onClick) onClick(barItem);
  };

  return (
    <BarItem
      barItem={barItem}
      onClick={handleOnClickBarItem}
      showSpark={showSparkMovements || showSparkPortfolio}
      isActive={!matchSettingsRoutes && isActive}
      disabled={disabled}
    />
  );
};

const mapStateToProps = ({ dashboard }) => ({
  activeTab: dashboard.activeTab,
});

export default connect(mapStateToProps)(SmartBarItem);
