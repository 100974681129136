import { useEffect } from 'react';
import { useReduxSubscription } from 'utils/redux-query';
import { REFETCH_PORTFOLIO_INVESTMENTS_WAIT_FOR } from 'config/constants';

export const usePortfolioInvestments = () => {
  const [portFolioInvestments, { refetch: refetchPortFolioInvestments }] = useReduxSubscription(
    'FETCH_ACCOUNT_INVESTMENTS',
  );

  useEffect(() => {
    if (!portFolioInvestments?.caching) return () => {};
    const pid = setTimeout(
      () => refetchPortFolioInvestments(),
      REFETCH_PORTFOLIO_INVESTMENTS_WAIT_FOR,
    );
    return () => clearTimeout(pid);
  }, [portFolioInvestments?.caching]);
};
