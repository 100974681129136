import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useReduxLazyQuery, useReduxQuery } from 'utils/redux-query';
import Link from 'components/Link';
import { Text } from 'components/Typography';
import { countTotalOrdersForEachCategory } from 'actions/cart/selectors';
import { publicUrls } from 'config/constants';
import PATHS, { investorSettings } from 'routes';
import { logOutUser } from 'actions/auth/fetcher';
import { getCartDetails } from 'actions/cart/cartActions';
import { ReactComponent as DropIcon } from 'assets/headerDrop.svg';
import ytpLogo2 from 'assets/ytplogo2.svg';
import Settings from 'utils/sdkYTP/investorSettings';
import { SlideFade } from 'ui';
import {
  HeaderContainer,
  HeaderButton,
  ElementGroup,
  HeaderTitle,
  DropdownElement,
  OrderItemIcon,
  SettingsIcon,
} from './styles';
import Dropdown from './Dropdown';
import CartIconQuantity from '../Cart/CartQuantityIcon';

const {
  investorOrdersPath,
} = PATHS;

const Header = (props) => {
  const dispatch = useDispatch();
  const { toggleCart, logOut, totalItemsInCart } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [session] = useReduxQuery('SESSION');
  const settings = Settings(session);
  const currentRouteOptions = useSelector((state) => state.dashboard.activeTab);
  const isBlockedAccount = settings?.isBlockedAccount();
  const needsUpdateExpedients = session?.settings?.needsUpdateExpedients;
  const blockedWrongInfo = session?.settings?.blockedWrongInfo;
  const disableNavigation = [needsUpdateExpedients].some(Boolean);
  const disableCartMenuItem = [disableNavigation, totalItemsInCart === 0, blockedWrongInfo].some(
    Boolean,
  );
  const disableSettingsMenuItem = [disableNavigation].some(Boolean);

  const shouldFetchOrders = !isBlockedAccount && !needsUpdateExpedients && !blockedWrongInfo;
  const [{ processingOrders, orders: ordersByCategory }, , { lazyQuery: lazyQueryOrders }] = useReduxLazyQuery('ORDERS');
  const countOrders = ordersByCategory ? countTotalOrdersForEachCategory(ordersByCategory) : null;
  const disableOrdersMenuItem = [disableNavigation, countOrders === 0, blockedWrongInfo].some(
    Boolean,
  );

  useEffect(() => {
    if (shouldFetchOrders) {
      lazyQueryOrders();
    }
  }, [shouldFetchOrders, lazyQueryOrders]);

  useEffect(() => {
    if (shouldFetchOrders) {
      dispatch(getCartDetails());
    }
  }, [shouldFetchOrders]);

  const handleLogOut = () => {
    logOut();
  };

  const handleCartClick = () => {
    if (!disableCartMenuItem || totalItemsInCart > 0) toggleCart();
  };

  const dropdownElements = [
    { name: 'yotepresto.com', path: publicUrls.home },
    { name: 'Preguntas frecuentes', path: publicUrls.faq },
    { name: 'Guía', path: publicUrls.guide },
    { name: 'Centro de ayuda', path: publicUrls.helpCenter },
  ];

  const hideDropdown = () => {
    setDropdownOpen(false);
    window.removeEventListener('click', hideDropdown);
  };

  const showDropdown = (event) => {
    setDropdownOpen(!dropdownOpen);
    window.addEventListener('click', hideDropdown);
    event.stopPropagation();
  };

  const renderOptions = dropdownElements.map((element) => (
    <DropdownElement key={element.name}>
      <a href={element.path} target="_blank" rel="noopener noreferrer">
        {element.name}
      </a>
    </DropdownElement>
  ));

  const renderDropdown = (
    <SlideFade
      in={dropdownOpen}
      offsetY="0"
      unmountOnExit
    >
      <Dropdown>
        {renderOptions}
        <DropdownElement>
          <button className="end__session" type="button" onClick={handleLogOut}>
            Cerrar sesión
          </button>
        </DropdownElement>
      </Dropdown>
    </SlideFade>
  );

  const renderElementGroup = (
    <ElementGroup className="element__group">
      <HeaderButton
        as={disableOrdersMenuItem ? 'div' : Link}
        to={countOrders > 0 ? investorOrdersPath() : '#'}
        data-testid="header-button"
        $totalNotificationsBadge={processingOrders}
        disabled={disableOrdersMenuItem}
      >
        <OrderItemIcon disabled={disableOrdersMenuItem} />
      </HeaderButton>
      <HeaderButton
        className="lastStep"
        onClick={handleCartClick}
        data-testid="header-button"
        $totalNotificationsBadge={totalItemsInCart}
        invertColorSvgOnHover
        disabled={disableCartMenuItem}
      >
        <CartIconQuantity disabled={disableCartMenuItem} onToggleCart={toggleCart} />
      </HeaderButton>
      <HeaderButton
        className="settings"
        as={disableSettingsMenuItem ? 'div' : Link}
        data-testid="header-button"
        disabled={disableSettingsMenuItem}
        to={investorSettings}
      >
        <SettingsIcon />
        <Text
          as="span"
          size="small"
          color={disableSettingsMenuItem ? 'schema.gray.300' : 'schema.blue.500'}
        >
          {'  '}
          Ajustes
        </Text>
      </HeaderButton>
      <HeaderButton data-testid="header-button" dropdownOpen={dropdownOpen} onClick={showDropdown}>
        <DropIcon width="8px" title="" />
        {renderDropdown}
      </HeaderButton>
    </ElementGroup>
  );

  const renderHeaderTitle = () => {
    const { isMobile } = props;
    if (isMobile) {
      return <img className="mobile__logo" src={ytpLogo2} alt="yotepresto" />;
    }

    return <HeaderTitle>{currentRouteOptions.title}</HeaderTitle>;
  };
  const { isMobile, toggleSidebar, sidebarOpen } = props;
  return (
    <HeaderContainer isMobile={isMobile}>
      {renderHeaderTitle()}
      {!isMobile ? (
        renderElementGroup
      ) : (
        <div className="header__button__group">
          <HeaderButton
            as={disableOrdersMenuItem ? 'div' : Link}
            to={investorOrdersPath()}
            className="lastStep"
            data-testid="header-button"
            $totalNotificationsBadge={processingOrders}
            disabled={disableOrdersMenuItem}
          >
            <OrderItemIcon disabled={disableOrdersMenuItem} />
          </HeaderButton>
          <HeaderButton
            className="lastStep"
            onClick={handleCartClick}
            data-testid="header-button"
            $totalNotificationsBadge={totalItemsInCart}
            isMobile
            invertColorSvgOnHover
            disabled={disableCartMenuItem}
          >
            <CartIconQuantity disabled={disableCartMenuItem} onToggleCart={toggleCart} />
          </HeaderButton>
          <HeaderButton onClick={toggleSidebar} data-testid="header-button" menu isMobile>
            {!sidebarOpen ? <div>&#9776;</div> : <div>X</div>}
          </HeaderButton>
        </div>
      )}
    </HeaderContainer>
  );
};
const mapStateToProps = ({ cart }) => {
  const { totalItemsInCart } = cart;
  return {
    totalItemsInCart: totalItemsInCart || 0,
  };
};

const mapDispatchToProps = {
  logOut: logOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
