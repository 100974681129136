import React from 'react';
import { ReactComponent as ZenfiLogo } from 'assets/icons/zenfi-logo.svg';
import { ReactComponent as ZenfiApp } from 'assets/images/zenfiApp.svg';
import {
  Box,
  Text,
  Flex,
  ExternalLink,
} from 'components';
import { tscope } from 'i18n';
import { styles as s } from './ZenfiCard.styles';

const t = tscope('common');

export const ZenfiCard = () => (
  <Flex {...s.container}>
    <Box {...s.bgContainer}>
      <ZenfiApp {...s.zenfiImage} />
    </Box>
    <Flex {...s.content}>
      <ZenfiLogo />
      <Text
        {...s.contentText}
        t={t}
        i18nKey="getProofOfTaxStatusZenfiCard.body"
      />
      <ExternalLink
        {...s.link}
        t={t}
        i18nKey="getProofOfTaxStatusZenfiCard.download"
        href={t('zenfiDownloadApp')}
      />
    </Flex>
  </Flex>
);
