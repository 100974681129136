import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'actions/auth/fetcher';
import { useReduxSubscription } from 'utils/redux-query';
import { useLogin } from 'hooks/auth';
import { t } from 'i18n';

const tl = (key, options) => t(key, { scope: 'components.ExpiredSessionModal', ...options });

const messages = {
  idle: tl('body.idle'),
  another_session: tl('body.anotherSession'),
  invalid_credentials_for_api: tl('body.idle'),
};

export const useController = () => {
  const dispatch = useDispatch();
  const { login, isLoading, error } = useLogin();
  const isError = !!error;
  const [session] = useReduxSubscription('SESSION');
  const email = session?.user?.email;
  const [password, setPassword] = useState('');
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isThereAReason = [
      'idle',
      'another_session',
      'invalid_credentials_for_api',
    ].includes(sessionStatus?.reason);
    setIsOpen(isThereAReason);
  }, [sessionStatus?.reason]);

  const onEndSession = async () => {
    await dispatch(logOutUser());
    setIsOpen(false);
  };

  const onStartSession = async (event) => {
    event.preventDefault();
    await login({
      email,
      password,
    });
    setIsOpen(false);
  };

  const onChangePasswordInput = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const content = messages[sessionStatus?.reason] || '';

  return {
    isOpen,
    isError,
    content,
    isLoading,
    onEndSession,
    onStartSession,
    onChangePasswordInput,
  };
};
