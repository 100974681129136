import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isDevelopment } from 'config/index';
import PATHS, { profileRoute, investorCerRoute } from 'routes';
import Loading from 'views/Loading';
import LimitsWarningModal from 'views/LimitsWarningModal';
import SubmitOccupation from 'views/Regulation/SubmitOccupation';
import OfficialIdentifications from 'views/Regulation/OfficialIdentifications';
import AddressUpdate from 'views/Regulation/Profile/AddressUpdate';
import WaitingForCommittee from 'views/WaitingForCommittee';
import BlockedAccount from 'views/BlockedAccount';
import DashboardContainer from 'views/DashboardContainer';
import { CorruptAccount } from 'views/CorruptAccount/CorruptAccount';
import { Playground } from 'views/Playground/Playground';
import { ElectronicRiskCertificate } from 'views/ElectronicRiskCertificate';
import { RegisterFunnel } from 'views/RegisterFunnel';
import { Contract } from 'views/Contract';
import { NeedsUpdateInfo } from 'views/AlertDialogs';
import { ExpiredSessionModal } from 'components/ExpiredSessionModal';
import { useController } from './hooks/useController';
import { ActivityMonitor } from './components/ActivityMonitor';

const {
  investorWaitingForCommitteePath,
  investorSignContractPath,
  investorRegulationOccupationPath,
  investorBlockedAccountPath,
  officialIdentificationsPath,
  regulationProfileUpdatePath,
} = PATHS;

export const Dashboard = () => {
  const {
    isAppReady,
    isUserBlocked,
    isMissingStatus,
  } = useController();

  if (!isAppReady) return <Loading />;

  if (isMissingStatus) return <CorruptAccount />;

  return (
    <>
      {!isUserBlocked && (
        <LimitsWarningModal />
      )}
      <NeedsUpdateInfo />
      <ExpiredSessionModal />
      <ActivityMonitor />
      <Switch>
        {isDevelopment() && <Route path="/playground" component={Playground} />}
        <Route path={officialIdentificationsPath()} component={OfficialIdentifications} />
        <Route path={investorRegulationOccupationPath()} component={SubmitOccupation} />
        <Route path={investorSignContractPath()} component={Contract} />
        <Route
          path={regulationProfileUpdatePath({ section: 'address' })}
          component={AddressUpdate}
        />
        <Route exact path={profileRoute} component={RegisterFunnel} />
        <Route path={investorCerRoute} component={ElectronicRiskCertificate} />
        <Route path={investorWaitingForCommitteePath()} component={WaitingForCommittee} />
        <Route path={investorBlockedAccountPath()} component={BlockedAccount} />
        {
          isUserBlocked
            ? <Redirect to={investorBlockedAccountPath()} />
            : <Route exact path="*" component={DashboardContainer} />
        }
      </Switch>
    </>
  );
};
