import React from 'react';
import styled from 'styled-components';

import { breakPoints } from 'config/constants';

const { smallBreakPoint, midBreakPoint } = breakPoints;
const profitInfoDiv = ({ isMobile, ...props }) => <div {...props} />;

const ProfitInfoContainer = styled(profitInfoDiv)`
  display: flex;
  padding: 1.5rem;
  width: 100%;

  @media (max-width: ${midBreakPoint}px) {
    flex-direction: column;

    & .performanceCardGroup {
      flex-direction: column;
    }
  }

  & .card {
    height: 100%;
    margin: 0;
    margin-right: 1rem;
    justify-content: center;
    padding: 1.6rem;
    box-shadow: 0;
    & h5 {
      font-size: 2rem;
      color: #4f6f7b;
      font-weight: 500;
    }
    & p {
      font-size: 1.4rem;
      color: #677d85;
    }
  }

  & .performanceCardGroup {
    display: flex;
    flex: 1;
  }

  & .info {
    flex: 1;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: ${smallBreakPoint}px) {
    .info {
      margin-right: 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .cardGrey {
    & .card {
      background-color: #f8fafc;
      box-shadow: none;
    }
  }
  & .cardGreen {
    & .card {
      background-color: #f0fcf6;
      box-shadow: none;
      h5,
      p {
        color: #00c574;
      }
    }
    .wonModal {
      text-decoration: underline;
      @media (max-width: ${smallBreakPoint}px) {
        text-decoration: none;
      }
      svg {
        vertical-align: middle;
      }
      .modal-link:hover {
        cursor: pointer;
      }
      .modal-icon {
        padding-left: 0.3rem;
      }
    }
  }
  & .cardBlue {
    & .card {
      background-color: #eff7ff;
      box-shadow: none;
      h5,
      p {
        color: #0076ff;
      }
    }
  }
`;

export default ProfitInfoContainer;
