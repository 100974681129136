import { LIMIT_LEVELS } from 'config/constants';
import { t } from 'i18n';
import PATHS, { investorAddFunds } from 'routes';

const {
  investorSettingsLimitsLevelOneUpdateAddressPath,
  investorSettingsLimitsLevelOneUpdateIdentificationPath,
} = PATHS;

export const navigateToUpgradeLevelTwo = ({
  requirements,
  isAddressPending,
  isAddressOnReview,
}) => {
  if (!requirements?.userIdValidation) {
    return investorSettingsLimitsLevelOneUpdateIdentificationPath();
  }

  if (!requirements?.address && !isAddressOnReview && !isAddressPending) {
    return investorSettingsLimitsLevelOneUpdateAddressPath();
  }

  return null;
};

const createSchemaMap = {
  step1: ({
    currentLevel,
    requirements,
    maxTransactionLimit,
    maxAccountValueLimit,
  }) => {
    const isCompleted = ['level_one', 'level_two', 'level_three'].includes(currentLevel);
    const disabledUpgradeButton = currentLevel === 'unassigned';
    const displayUpgradeButton = false;

    const requirementsList = [
      {
        id: 'personal_information',
        title: t('common.personalInformation'),
        completed: isCompleted || requirements?.personalInformation,
      },
      {
        id: 'email',
        title: t('common.email'),
        completed: isCompleted || requirements?.email,
      },
      {
        id: 'ocupation',
        title: t('common.ocupation'),
        completed: isCompleted || requirements?.ocupation,
      },
    ];

    return {
      step: 1,
      isCompleted,
      disabledUpgradeButton,
      displayUpgradeButton,
      requirements: requirementsList,
      accountAmount: maxAccountValueLimit,
      fundingAmount: maxTransactionLimit,
    };
  },
  step2: ({
    currentLevel,
    requirements,
    isAddressPending,
    isAddressOnReview,
    maxTransactionLimit,
    displayProofOfAddressOnReview,
  }) => {
    const isRequirementsAccomplished = requirements?.address && requirements?.userIdValidation;
    const isCompleted = ['level_two', 'level_three'].includes(currentLevel);
    const disabledUpgradeButton = currentLevel !== 'level_one' || displayProofOfAddressOnReview || isRequirementsAccomplished;
    const displayUpgradeButton = ['level_one'].includes(currentLevel);
    const requirementsList = [
      {
        id: 'user_id_validation',
        title: t('common.identification'),
        completed: isCompleted || requirements?.userIdValidation,
      },
      {
        id: 'address',
        title: isAddressOnReview || isAddressPending
          ? t('common.addressOnReview')
          : t('common.address'),
        completed: isCompleted || requirements?.address,
      },
    ];
    const upgradeLevelLink = navigateToUpgradeLevelTwo({
      requirements,
      isAddressPending,
      isAddressOnReview,
    });

    return {
      step: 2,
      isCompleted,
      disabledUpgradeButton,
      displayUpgradeButton,
      requirements: requirementsList,
      updgradeLevelLink: upgradeLevelLink,
      fundingAmount: maxTransactionLimit,
    };
  },
  step3: ({ currentLevel, requirements }) => {
    const isCompleted = ['level_three'].includes(currentLevel);
    const isCLABECompleted = requirements?.clabe;
    const disabledUpgradeButton = currentLevel === LIMIT_LEVELS.LEVEL_THREE;
    const displayUpgradeButton = ['level_one', 'level_two'].includes(currentLevel) && !isCLABECompleted;
    const upgradeButtonText = 'Agregar fondos';
    const notice = !isCLABECompleted ? 'Sólo agrega fondos a tu cuenta (desde 1 centavo)' : null;
    const updgradeLevelLink = investorAddFunds;

    const requirementsList = [
      {
        id: 'clabe',
        title: t('settings.limits.confirmedCLABE'),
        completed: isCompleted || requirements?.clabe,
      },
    ];

    return {
      step: 3,
      requirements: requirementsList,
      isCompleted,
      disabledUpgradeButton,
      displayUpgradeButton,
      upgradeButtonText,
      updgradeLevelLink,
      notice,
    };
  },
};

export default createSchemaMap;
