import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';
import { useReduxMutator } from 'utils/redux-query';
import InvestPlansStep from 'views/RegisterFunnel/components/InvestPlansStep';

function Deposits() {
  const [updateProfile] = useReduxMutator('UPDATE_PROFILE');
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const handleFinishProcess = () => {
    dispatch(fetchPersonalArchives());
  };

  const saveProfile = (profile) => updateProfile(profile);

  return (
    <Switch>
      <Route path={`${match.url}/create`}>
        <InvestPlansStep
          hasReferenceCode={false}
          goNextStep={saveProfile}
          onConfirm={handleFinishProcess}
          doubleConfirm
        />
      </Route>
    </Switch>
  );
}

export default Deposits;
