import React, { useState } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookShareButton } from 'react-share';
import { ReactComponent as CopyCodeIcon } from 'assets/group17.svg';
import { ReactComponent as FacebookIcon } from 'assets/shape.svg';
import { t } from 'i18n';
import CodeAndSocialMediaContainer from './styles';

const CodeAndSocialMedia = (props) => {
  const { isMobile } = props;
  const [session] = useReduxQuery('SESSION');

  const userCode = session?.user.referenceCode;

  const [localState, setLocalState] = useState({
    copyState: {
      text: '',
      copied: false,
    },
  });

  const clipboardMessage = (
    <span className="copy-to-clipboard-msg">Copiado en el portapapeles</span>
  );

  return (
    <CodeAndSocialMediaContainer>
      <div className="code-and-social-media code">
        <div className="text">Código</div>
        <div className="code-shown">{userCode}</div>
        <div className="copy-icon-container">
          <CopyToClipboard
            text={userCode}
            onCopy={() => setLocalState({
              ...localState,
              copyState: { ...localState.copyState, copied: true },
            })}
          >
            <CopyCodeIcon title="" />
          </CopyToClipboard>
        </div>
        {!isMobile && localState.copyState.copied && clipboardMessage}
      </div>
      {isMobile && localState.copyState.copied && (
        <div className="copy-to-clipboard-msg-mobile-container">{clipboardMessage}</div>
      )}
      <div className="code-and-social-media social-media">
        <div className="text">Compartir</div>
        <FacebookShareButton
          quote={t('settings.referralProgram.shareOnFacebook.quote', { code: userCode })}
          url={t('settings.referralProgram.shareOnFacebook.link')}
          className="facebook-icon-container"
        >
          <FacebookIcon title="" />
        </FacebookShareButton>
      </div>
    </CodeAndSocialMediaContainer>
  );
};

export default CodeAndSocialMedia;
