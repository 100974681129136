import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import { ReactComponent as BlockedImageComponent } from 'assets/funds/blocked-account.svg';
import Button from 'components/Button/Button';
import Box from 'components/Box';

export const BlockedContent = styled(Box)`
  flex-direction: column;
  padding: 12rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 4rem;
    width: 100%;
  }
`;

export const BlockedImage = styled(BlockedImageComponent)`
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  color: ${colors.gray.g7};
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 2rem;
  text-align: center;
`;

export const DescriptionText = styled.p`
  text-align: center;
  margin-bottom: 2rem;
`;

export const LimitsLink = styled(Button)``;
