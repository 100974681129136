import styled, { css } from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import { Text } from 'components/Typography';
import Box from 'components/Box';
import Button from 'components/Button/Button';

const minItemsToDisplay = 5;
const colorsByType = { withdrawal: colors.red, deposit: colors.green };

export const TransactionsCardContent = styled(Box)`
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
  padding: 3rem;
  border: 1px solid ${colors.gray.g12};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 30rem;
  }
`;

export const Title = styled(Text)`
  text-align: center;
`;

export const TransactionsTable = styled.section`
  width: 100%;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

export const Head = styled.p`
  text-align: left;
  font-size: 1.2rem;
  color: ${colors.gray.g4};
  width: 50%;

  &:last-child {
    text-align: right;
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${minItemsToDisplay * 3}rem;
  overflow-y: hidden;

  ${({ $isExpanded }) => ($isExpanded
    ? css`
          max-height: max-content;
        `
    : '')}
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray.g12};
  height: 3rem;
  min-height: 3rem;

  &:last-child {
    border-bottom: 0;
  }
`;

export const Cell = styled.p`
  text-align: left;
  width: 50%;
  color: ${({ $type }) => colorsByType[$type]};

  &:last-child {
    text-align: right;
  }
`;

Cell.defaultProps = {
  $type: colors.gray.g5,
};

export const SeeMoreButton = styled(Button)`
  margin-top: 2rem;
  text-align: center;
`;
