import React from 'react';
import { messages } from 'config/constants';
import { Form } from 'react-final-form';
import DotsLoader from 'components/DotsLoader';
import Footer from 'views/DashboardContent/FooterDashboard';
import FormLayout from 'views/FormLayout';
import { Text, Heading } from 'components/Typography';
import Button from 'components/Button/Button';
import { Box, Flex } from 'styles';
import TextField from 'components/TextField/TextField';
import { PrintFrame } from 'utils/window/print';
import { ContractWrapper } from 'components/ContractWrapper';
import { styles as s } from './Contrat.styles';
import { useController } from './hooks';
import { LoadingCard } from './components';
import { ts } from './constants';

const initialValues = { password: '' };
const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = messages.required;
  return errors;
};

export const Contract = () => {
  const {
    contract,
    onSubmit,
    displayCard,
    printDocument,
    refetchContract,
    fetchContractStatus,
  } = useController();

  return (
    <FormLayout {...s.layout} renderFooter={() => <Footer />}>
      <Box {...s.content}>
        <PrintFrame />
        {fetchContractStatus.failed && (
          <Flex {...s.innerContentError}>
            <Heading {...s.titleError}>
              {ts('ErrorFetching.title')}
            </Heading>
            <Text textAlign="center">
              {ts('ErrorFetching.description')}
            </Text>
            <Button
              onClick={refetchContract}
              {...s.generateButtonError}
            >
              {ts('ErrorFetching.actions.generate')}
            </Button>
          </Flex>
        )}
        {displayCard && (
          <Flex {...s.innerContent}>
            <Heading {...s.title}>
              {ts('title')}
            </Heading>
            {fetchContractStatus.pending && <LoadingCard />}
            {fetchContractStatus.completed && (
              <Flex {...s.cardContent}>
                <ContractWrapper
                  {...s.contractDocument}
                  srcdoc={contract.document}
                  height="3xl"
                  maxHeight="50rem"
                  width="100%"
                />
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  initialValues={initialValues}
                  render={({
                    pristine,
                    submitting,
                    handleSubmit,
                  }) => (
                    <Flex
                      as="form"
                      onSubmit={handleSubmit}
                      {...s.form}
                    >
                      <Button
                        type="button"
                        onClick={printDocument}
                        {...s.printButton}
                      >
                        {ts('updateContactForm.actions.print')}
                      </Button>
                      <Flex {...s.groupField} alignItems="flex-end">
                        <TextField
                          type="password"
                          name="password"
                          isTextHelperFloating
                          textLabel={ts('updateContactForm.fields.password.textLabel')}
                          {...s.passwordField}
                        />
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          disabled={submitting || pristine}
                          {...s.submit}
                        >
                          {submitting
                            ? <DotsLoader />
                            : ts('updateContactForm.actions.submit')}
                        </Button>
                      </Flex>
                    </Flex>
                  )}
                />
              </Flex>
            )}
          </Flex>
        )}
      </Box>
    </FormLayout>
  );
};
