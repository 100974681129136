import React from 'react';
import { t } from 'i18n';
import { Link } from 'react-router-dom';
import { animoZapopanRoute } from 'routes';
import ZapopanMessageContainer from './styles';

const ZapopanMessage = () => (
  <ZapopanMessageContainer>
    <span className="badge">{t('detailRequisition.zapopanWarranty.badgeText')}</span>
    <p>{t('detailRequisition.zapopanWarranty.description')}</p>
    <div className="moreInfo">
      <Link to={animoZapopanRoute} target="new">
        {t('detailRequisition.zapopanWarranty.moreInfo')}
      </Link>
    </div>
  </ZapopanMessageContainer>
);

export default ZapopanMessage;
