import sendToast from 'utils/toastHandler';
import { request } from './fetchers/yotepresto';

export const getRequisitionsOnFunding = async () => {
  const method = 'get';
  const path = '/v2/investor/requisition_listings';
  try {
    return await request({
      method,
      path,
      log: true,
    });
  } catch (error) {
    return false;
  }
};

export const toggleHiddenRequisitions = async (ids, hidden = false) => {
  const method = 'put';
  const path = '/v2/investor/requisition_listings/toggle_hidden';
  const body = {
    hidden,
    requisition_ids: ids,
  };
  try {
    const response = await request({ method, path, body });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export const getRequisitionDetail = async (id, dispositionId) => {
  const responseToast = () => sendToast('error', 'Ocurrió un error al momento de cargar la información');
  const path = dispositionId
    ? `/v1/investor/loans/${id}?disposition_id=${dispositionId}`
    : `/v1/investor/loans/${id}`;
  const method = 'get';
  const config = { method, path };
  let response = false;
  try {
    response = await request(config);
    if (response.status === 200) return response.data;
    responseToast();
  } catch (e) {
    responseToast();
  }
  return response;
};

export const sendCommentMessage = async (id, content) => {
  const method = 'post';
  const path = '/v1/messages';
  const body = { message: { requisition_id: id, content } };
  const response = await request({ method, path, body });
  return response.data;
};

export const getRequisitionComments = async (reqID) => {
  const method = 'get';
  const path = `/v2/investor/requisition/messages?zell_app_id=${reqID}`;
  const response = await request({ method, path });
  return response.data;
};

export const getRequisitionsListAmount = async () => {
  const method = 'get';
  const path = '/v2/investor/requisition_listings/total_on_funding';
  const { data } = await request({ method, path });

  return data;
};

export const fetchDiversification = async () => {
  const method = 'GET';
  const path = '/v2/investor/diversifications';
  const { data } = await request({ method, path });

  return data;
};
