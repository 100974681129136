import React, { useEffect, useRef } from 'react';
import {
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import PATHS, {
  userSettingsAccount,
  userSettingsReferral,
  userSettingsSecurity,
  userSettingsAutoinvest,
  investorSettings,
} from 'routes';
import { ALERTS } from 'views/DashboardContent/Alerts/Alerts';
import { Box, useDisclosure, useOutsideClick } from 'ui';
import { useFeatureFlag } from 'utils/featureFlags';
import MainAreaContainer from './styles';
import AccountProfile from './AccountProfile';
import Referrals from './Referral';
import Security from './Security';
import Autoinvest from './AutoInvest';
import Sidebar from '../Sidebar';
import MobileHeader from './MobileHeader';
import Limits from './Limits';
import UpdateAddress from './UpdateAddress';
import AccountStatement from './AccountStatement/AccountStatement';
import { FiscalInformationAlert, ErrorInFiscalInfoAlert } from './AccountProfile/components';
import { UpsertBeneficiary } from './Beneficiary';
import { useController } from './hooks/useController';
import { NavigationResolver } from './components';

const { investorSettingsLimitsPath, investorSettingsUpdateAddressPath } = PATHS;

const SidebarMobile = ({
  sidebarOptions,
}) => {
  const ref = useRef();
  const location = useLocation();
  const currentOption = sidebarOptions
    .find((element) => location.pathname?.match(new RegExp(element.url)));
  const menuDisclosure = useDisclosure();
  useOutsideClick({
    ref,
    handler: menuDisclosure.onClose,
  });

  if (!currentOption) return null;

  return (
    <Box
      ref={ref}
      position="sticky"
      top="0"
      zIndex={10}
      width="full"
      height="56px"
      overflow="visible"
      backgroundColor="white"
    >
      <Box
        boxShadow="md"
      >
        <MobileHeader
          option={currentOption}
          onClose={menuDisclosure.onClose}
          onToggle={menuDisclosure.onToggle}
        />
        {menuDisclosure.isOpen && (
          <Sidebar
            isMobile
            options={sidebarOptions}
          />
        )}
      </Box>
    </Box>
  );
};
const MainArea = ({ isMobile, sidebarOptions }) => {
  const mainAreaRef = useRef();
  const {
    displayUpdateFiscalAlert,
    displayInvoicingErrorAlert,
  } = useController();

  const featureFlags = useFeatureFlag();

  const renderMobileHeader = () => (isMobile
    ? (<SidebarMobile sidebarOptions={sidebarOptions} />)
    : null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname && mainAreaRef.current) mainAreaRef.current.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <MainAreaContainer
      className="main__area"
      ref={mainAreaRef}
      isMobile={isMobile}
    >
      {renderMobileHeader()}
      {ALERTS.map(({ Component, id }) => <Component key={id} m="2rem" />)}
      {displayUpdateFiscalAlert && <FiscalInformationAlert m="2rem" />}
      {displayInvoicingErrorAlert && <ErrorInFiscalInfoAlert m="2rem" />}
      <Switch>
        {featureFlags.Profile && (
          <Route exact path={userSettingsAccount} component={AccountProfile} />
        )}
        {featureFlags.Security && (
          <Route exact path={userSettingsSecurity} component={Security} />
        )}
        {featureFlags.AccountStatementDownloader && (
          <Route path={`${investorSettings}/account-statement`} component={AccountStatement} />
        )}
        {featureFlags.Referrals && (
          <Route exact path={userSettingsReferral} component={Referrals} />
        )}
        {featureFlags.Limits && (
          <Route path={investorSettingsLimitsPath()} component={Limits} />
        )}
        {featureFlags.AutoInvest && (
          <Route path={userSettingsAutoinvest} component={Autoinvest} />
        )}
        <Route
          exact
          path={`${investorSettings}/beneficiary/update`}
          component={UpsertBeneficiary}
        />
        <Route path={investorSettingsUpdateAddressPath()} component={UpdateAddress} />
        <Route component={NavigationResolver} />
      </Switch>
    </MainAreaContainer>
  );
};

export default MainArea;
