import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const DIV = ({ isMobile, ...props }) => <div {...props} />;

const AccountStatementContainer = styled(DIV)`
  grid-column: 1 / 7;
  height: 100%;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-column: 1 / -1;
  }

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-row: 2 / 3;
  }

  & .accountStatement {
    display: flex;
    background-color: white;
    box-shadow: ${colors.shadow.card};
    border-radius: 0.4rem;
    height: 100%;
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      flex-wrap: wrap;
    }
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      flex-direction: column;
      &__info {
        border-bottom: 1px solid #e7ebef;
        padding: 1rem 0;
      }
    }
    &__info {
      flex: 1;
      height: 100%;
      display: flex;
      padding: 2rem 0;
      justify-content: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
      align-items: center;
      border-right: 1px solid #e7ebef;
      @media (max-width: ${breakPoints.midBreakPoint}px) {
        flex: 0 50%;
        height: 165px;
        border-bottom: 1px solid #e7ebef;
      }
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        flex: 1;
        height: 100%;
        border-right: 1px solid transparent;
      }
      & svg {
        width: ${(props) => (props.isMobile ? '20' : '100')}%;
        height: ${(props) => (props.isMobile ? '20px' : '100%')};
        margin: 0 auto;
      }

      & > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-left: ${(props) => props.isMobile && '1rem'};
        align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
        justify-content: space-around;
        & .main-value {
          font-size: 2.6rem;
          @media (max-width: ${breakPoints.smallBreakPoint}px) {
            font-size: 2rem;
          }
        }
        & .available {
          font-weight: 600;
          color: #0076ff;
        }
        & .total {
          color: #134052;
        }
        & p {
          font-size: 2.3rem;
          color: #4f6f7b;
          font-weight: 500;
          @media (max-width: ${breakPoints.largeBreakPoint}px) {
            font-size: 2.1rem;
          }
        }
        & p:first-of-type {
          font-size: 1.3rem;
          color: #93afb9;
          @media (max-width: ${breakPoints.largeBreakPoint}px) {
            text-align: center;
            min-height: 35px;
          }
        }
        & p.percentage {
          font-size: 14px;
        }
        .cashFlowModal {
          color: #0076ff;
          text-decoration: none;
          font-size: 1.2rem !important;
          transition: all 0.3s ease;
          &:hover {
            color: #00c574;
            cursor: pointer;
          }
          :focus {
            outline: none;
          }
        }
      }
      &:last-of-type {
        border-right: none;
      }
    }
  }
`;

export default AccountStatementContainer;
