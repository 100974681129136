import React, { useMemo } from 'react';
import Button from 'components/Button';
import { concatFullName } from 'utils/stringFormatters';
import moment from 'moment';
import { useReduxQuery } from 'utils/redux-query';
import UpdateInformationContainer from './styles';

const UpdateInformation = ({ onClickFinish }) => {
  const [profile] = useReduxQuery('PROFILE');
  const [catalog] = useReduxQuery('CATALOG');
  const { curp = '', birthDate = '', birthStateId = '' } = profile ?? {};
  const fullName = concatFullName(profile);

  const stateName = useMemo(() => {
    if (!catalog || !birthStateId) return '';

    return catalog.states.find((state) => String(state.id) === String(birthStateId)).name;
  }, [catalog, birthStateId]);

  const handleChangeIdentification = async () => {
    onClickFinish();
  };

  return (
    <UpdateInformationContainer>
      <div className="info">
        <h2>¡Tu información está correcta!</h2>
      </div>
      <div className="mati-container white">
        <div className="fieldset">
          <label htmlFor="">Nombre</label>
          <span>{fullName}</span>
        </div>
        <div className="fieldset">
          <label htmlFor="">CURP</label>
          <span>{curp}</span>
        </div>
        <div className="fieldset-double">
          <div className="fieldset">
            <label htmlFor="">Fecha de nacimiento</label>
            <span>{moment(birthDate).format('DD/MM/YYYY')}</span>
          </div>
          <div className="fieldset">
            <label htmlFor="">Lugar de nacimiento</label>
            <span>{stateName}</span>
          </div>
        </div>
        <div className="button-container" />
      </div>
      <div className="info-footer">
        <p>Puedes seguir operando con normalidad.</p>
        <Button onClick={handleChangeIdentification}>Terminar</Button>
      </div>
    </UpdateInformationContainer>
  );
};

export default UpdateInformation;
