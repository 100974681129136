import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const CartIconQuantityContainer = styled(ContainerDiv)`
  position: relative;
  .zeroTicketsSpan {
    display: none;
  }
  .zeroTicketsIcon * {
    fill: ${colors.gray.g2} !important;
  }
  & span {
    padding: 0.3rem;
    min-width: 1.8rem;
    position: absolute;
    font-weight: 700;
    top: -7px;
    left: 16px;
    border-radius: 5rem;
    background-color: ${colors.blue};
    color: white;
    animation-name: notification;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  @keyframes notification {
    0% {
      transform: scale(1);
      background-color: ${colors.blue};
    }
    100% {
      transform: scale(1.3);
      background-color: ${colors.green};
    }
  }
  svg {
    vertical-align: middle;
  }
`;

export default CartIconQuantityContainer;
