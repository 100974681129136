import {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { t } from 'i18n';
import { fetchPersonalIdentifications } from 'apis/registerForm';
import { useReduxQuery, useReduxMutator } from 'utils/redux-query';
import { showToastError, showToastSuccess, showToastInfo } from 'utils/toastHandler';
import { createStatesCatalog } from './reducers';
import { parseFinalProfile, parseInitialProfile } from './parsers';
import useSteps from './useSteps';

const STEP_NAMES = {
  USERNAME: 0,
  BIRTH: 1,
  RFC: 2,
  CURP: 3,
};

function useController(options) {
  const { onFinishProcess = () => {} } = options;
  const [catalog] = useReduxQuery('CATALOG');
  const [initialProfile] = useReduxQuery('PROFILE');
  const [confirmProfile, { status: confirmProfileStatus }] = useReduxMutator(
    'EXPEDIENTS_PROFILE_CONFIRM',
  );
  const [isUpdatingIdentities, setIsUpdatingIdentities] = useState(false);
  const [profile, setProfile] = useState(null);
  const { findStepByName, trackStep } = useSteps();
  const statesCatalog = useMemo(() => createStatesCatalog(catalog?.states), [catalog?.states]);
  const partialProfileToGenerateIdentities = useMemo(
    () => ({
      firstName: profile?.firstName,
      secondName: profile?.secondName,
      firstLastName: profile?.firstLastName,
      secondLastName: profile?.secondLastName,
      birthDate: profile?.birthDate,
      birthState: profile?.isNotMexican
        ? profile?.country
        : profile?.state?.name,
      gender: profile?.gender,
    }),
    [
      profile?.firstName,
      profile?.secondName,
      profile?.firstLastName,
      profile?.secondLastName,
      profile?.birthDate,
      profile?.state?.name,
      profile?.gender,
      profile?.isNotMexican,
      profile?.country,
    ],
  );

  const hasProfileCompleted = useMemo(
    () => Object.values(partialProfileToGenerateIdentities).some(Boolean),
    [partialProfileToGenerateIdentities],
  );

  const hasIdentities = [profile?.curp && profile?.rfc].every(Boolean);

  const hasTouchedUsernameForm = [
    findStepByName(STEP_NAMES.USERNAME),
    findStepByName(STEP_NAMES.BIRTH),
  ].some(Boolean);

  const onUpdateProfile = async ({ password }) => {
    const newProfile = parseFinalProfile(profile, {
      context: {
        shouldBeProcessed: findStepByName(STEP_NAMES.USERNAME),
      },
    });
    const response = await confirmProfile({
      newProfile,
      password,
    });
    return response;
  };

  const generateIdentities = useCallback(async () => {
    try {
      setIsUpdatingIdentities(true);
      const { rfc, curp, errors } = await fetchPersonalIdentifications(
        partialProfileToGenerateIdentities,
      );
      if (errors) showToastError(t('common.errors.generateCurpRfc'));
      else {
        setProfile((currentProfile) => ({
          ...currentProfile,
          rfc,
          curp,
        }));
      }
    } catch (e) {
      showToastError(t('common.errors.generateCurpRfc'));
    } finally {
      setIsUpdatingIdentities(false);
    }
  }, [partialProfileToGenerateIdentities]);

  useEffect(() => {
    if (!initialProfile || !statesCatalog) return;
    const newProfile = parseInitialProfile(initialProfile);
    const birthState = statesCatalog.find(
      ({ id }) => String(id) === String(newProfile.birthStateId),
    );

    setProfile({
      ...newProfile,
      state: birthState,
    });
  }, [initialProfile, statesCatalog]);

  useEffect(() => {
    if (!hasProfileCompleted) return;
    if (hasTouchedUsernameForm === false && hasIdentities) return;
    generateIdentities();
  }, [
    hasIdentities,
    generateIdentities,
    hasProfileCompleted,
    hasTouchedUsernameForm,
  ]);

  useEffect(() => {
    if (confirmProfileStatus.completed) {
      if (findStepByName(STEP_NAMES.USERNAME)) {
        showToastInfo(t('expedientsProfileUpdate.notifications.success.profileInProcess'));
      } else {
        showToastSuccess(t('expedientsProfileUpdate.notifications.success.profileUpdated'));
      }
      onFinishProcess();
    }
  }, [confirmProfileStatus.completed]);

  useEffect(() => {
    if (confirmProfileStatus.failed) {
      showToastError('Falló al guardar tu perfil, revisa tu información de perfil');
    }
  }, [confirmProfileStatus?.failed]);

  return {
    profile,
    setProfile,
    statesCatalog,
    onUpdateProfile,
    confirmProfileStatus,
    trackStep,
    isUpdatingIdentities,
    STEP_NAMES,
    generateIdentities,
  };
}

export default useController;
