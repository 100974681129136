import createActions from './createActions';
import { registerQuery } from '../queryBase';

const createAsyncAction = (keystore, asyncAction) => {
  const { initQuery, setPayload, setError } = createActions(keystore);

  const enhancedAction = (
    { silent = false } = {},
    ...paramsForAsyncAction
  ) => async (dispatch, getState) => {
    try {
      if (!silent) dispatch(initQuery());

      const result = await asyncAction(...paramsForAsyncAction)(dispatch, getState);

      dispatch(setPayload(result));

      return result;
    } catch (error) {
      dispatch(setError(error));
      throw error;
    }
  };

  registerQuery(keystore, enhancedAction);

  return enhancedAction;
};

export default createAsyncAction;
