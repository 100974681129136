import { STATUS } from './config';

const defaultOptions = {
  initialState: null,
};

const createReducer = (keystore, { initialState = {} } = defaultOptions) => (
  state = {
    meta: { status: STATUS.INITIAL, countCalls: 0, error: null },
    payload: initialState,
  },
  action = {},
) => {
  switch (action.type) {
    case `SET_${keystore}`:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.meta,
          status: action.meta.status || null,
          error: action.meta.error || null,
        },
        payload: action.payload === undefined ? state.payload : action.payload,
      };
    case `SET_${keystore}_META`:
      return {
        ...state,
        meta: action.meta,
      };
      /**
       * INIT_QUERY_[KEYSTORE]
       * before query is called It sets status to PENDING and increments calls.
       */
    case `INIT_QUERY_${keystore}`:
      return {
        payload: state.payload,
        meta: {
          status: STATUS.PENDING,
          countCalls: state.meta.countCalls + 1,
        },
      };
    case `UPDATE_${keystore}`:
      return {
        payload: action.payload,
        meta: {
          ...action.meta,
          countCalls: state.meta.countCalls + 1,
        },
      };
    case `PATCH_${keystore}`:
      return {
        payload: {
          ...state.payload,
          ...action.payload,
        },
        meta: {
          ...state.meta,
          ...action.meta,
        },
      };
    case `DELETE_${keystore}`:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: STATUS.INITIAL,
          error: null,
        },
        payload: null,
      };
    default:
      return state;
  }
};

export default createReducer;
