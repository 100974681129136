export const styles = {
  container: {
    width: 'sm',
    rowGap: 8,
  },
  title: {
    textAlign: 'center',
  },
  desc: {
    textAlign: 'center',
  },
  image: {
    width: '20rem',
  },
  cardBody: {
    display: 'grid',
    placeItems: 'center',
    p: 10,
  },
  noteText: {
    textAlign: 'center',
    fontSize: 'sm',
  },
};
