import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PATHS from 'routes';
import { updateUserSettings } from 'apis/user';
import { setSessionLimits } from 'actions/auth/session';
import { AddressContentForm } from './components';
import { useController } from './hooks/useController';

const initialValues = {
  zip: '',
  city: '',
  street: '',
  stateName: '',
  intNumber: '',
  extNumber: '',
  neighborhood: '',
  municipality: '',
};

export const AddressForm = (props) => {
  const {
    onSubmit = () => {},
    onBeforeUpdateAddress = () => {},
    ...contentProps
  } = props;
  const {
    handleFormSubmit,
    validateAddress,
  } = useController({
    onSubmit,
    onBeforeUpdateAddress,
  });

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validateAddress}
      initialValues={initialValues}
    >
      {({
        form,
        values,
        submitting,
        handleSubmit,
      }) => (
        <AddressContentForm
          form={form}
          values={values}
          submitting={submitting}
          onSubmit={handleSubmit}
          {...contentProps}
        />
      )}
    </Form>
  );
};

export const LimitsAddressForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBeforeUpdateAddress = () => {
    dispatch(
      setSessionLimits((limits) => ({
        ...limits,
        requirementsNextLevel: {
          ...limits.requirementsNextLevel,
          address: true,
        },
      })),
    );
  };

  const handleSubmit = async () => {
    await updateUserSettings('address_validated', true);
    history.push(PATHS.investorSettingsLimitsLevelOneUpdateIdentificationPath());
  };

  return <AddressForm onSubmit={handleSubmit} onBeforeUpdateAddress={handleBeforeUpdateAddress} />;
};
