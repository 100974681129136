import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';

export const styles = {
  formLayout: {
    pt: '0',
    backgroundColor: colors.schema.gray[50],
    innerContentProps: { direction: 'column', px: '0' },
  },
  innerContent: {
    direction: 'column',
    mt: '5rem',
    justifyContent: 'center',
  },
};

const StepContainer = styled.div`
  color: ${colors.gray.g5};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  background-color: ${colors.schema.gray[50]};

  .error {
    font-size: 1.2rem;
    margin-top: 0.4rem;
  }
  .radios .error {
    margin-top: -12px;
  }
  & > form,
  .instructions,
  .form {
    width: 35rem;
    @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
      width: 100%;
    }
  }
  .instructions {
    margin-bottom: 3rem;
    h3 {
      color: ${colors.gray.g6};
      font-size: 2.3rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.4rem;
    }
    a {
      color: ${colors.blue};
      text-decoration: none;
    }
    &--form {
      padding-bottom: 2rem;
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        padding-bottom: 1rem;
      }
      @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
        padding-bottom: 0;
      }
    }
  }
  .form-group {
    margin-top: 2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-bottom: 1.7rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    label {
      font-weight: 600;
      display: block;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
      &.disabled {
        color: ${colors.gray.g4};
      }
    }

    a.link {
      color: #1793ff;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-decoration: none;
      font-size: 1.3rem;
      display: block;
      text-align: right;
    }

    .date {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 2rem;
      .error-wrapper {
        grid-column: 1 / -1;
      }
    }

    .gender label {
      min-width: 8.5rem;
    }

    &.checkboxes {
      display: flex;
      .label {
        margin-left: 1rem;
        font-size: 1.2rem;
        font-weight: normal;
        margin-bottom: 0;
        margin-top: 0.2rem;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
    padding-bottom: 2rem;
    @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
      padding-bottom: 1rem;
    }
    button {
      padding: 1.6rem 2.5rem;
    }
    button:last-child {
      margin-left: 1rem;
    }
  }

  .btn-outline-primary {
    padding: 1.2rem;
    border-radius: 0.4rem;
    border: 0.1rem solid ${colors.blue};
    background-color: transparent;
    color: ${colors.blue};
    font-weight: 600;
  }

  .input__group {
    width: 100%;
    input[name='phoneNumber'] {
      width: 100%;
      letter-spacing: 0.4rem;
    }
    .error__span {
      display: block;
      font-size: 1.2rem;
      padding-top: 1rem;
      color: red;
    }
  }
`;

export default StepContainer;
