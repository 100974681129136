import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const containerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const CalendarContainer = styled(containerDiv)`
  width: 100%;
  display: flex;
  & + #footer {
    margin-left: 28rem;
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      margin-left: 0;
    }
  }
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    flex-direction: column;
    display: block;
    height: auto;
  }
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-template-rows: min-content auto;
  }

  & .calendar-loader {
    width: 100%;

    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      display: none;
    }
  }

  & .daily-incomes-loader {
    width: 100%;
  }
`;

export default CalendarContainer;
