import React from 'react';
import { Form } from 'react-final-form';
import {
  Link as RouterLink,
} from 'react-router-dom';
import { t, tscope } from 'i18n';
import { Grid, Flex } from 'styles';
import Button from 'components/Button/Button';
import Link from 'components/Link';
import TextField from 'components/TextField/TextField';
import { Heading } from 'components/Typography';
import Divider from 'components/Divider';
import { RefreshIcon } from 'components/Icons';
import { IconButton, Tooltip } from 'ui';
import { Content } from './CURP.styles';
import validator from './validator';
import { useController } from './hooks';
import { parseCURPField, parseCURPHomoField } from './utils';

const tOptions = { scope: 'expedientsProfileUpdate.curp' };
const ts = tscope('expedientsProfileShow');

function CURP() {
  const {
    submitRef,
    curpUserData,
    curpHomoClave,
    handleOnSubmit,
    regenerateCURP,
    isUpdatingIdentities,
  } = useController();

  return (
    <Content>
      <Heading mb="1rem">{t('title', tOptions)}</Heading>
      <Heading as="p">{t('subtitle', tOptions)}</Heading>

      <Form
        onSubmit={handleOnSubmit}
        initialValues={{ curp: curpUserData, homoClave: curpHomoClave }}
        validate={validator}
      >
        {({ handleSubmit, submitting }) => (
          <Grid as="form" onSubmit={handleSubmit} rowGap="2rem" mt="2rem">
            <Grid templateColumns="22rem 5rem" columnGap="1rem" rowGap="0.5rem">
              <TextField
                gridColumn="1/2"
                color="schema.gray.700"
                textLabel={t('form.curpField.textLabel', tOptions)}
                name="curp"
                readOnly={Boolean(curpUserData)}
                parse={parseCURPField}
                iconPosition="right"
                Icon={() => (
                  <Tooltip label={ts('refreshCURPTooltip')} hasArrow placement="top">
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="green"
                      onClick={regenerateCURP}
                      data-testid="refreshCurpButton"
                      isLoading={isUpdatingIdentities}
                      icon={<RefreshIcon boxSize={5} fill="green.500" />}
                    />
                  </Tooltip>
                )}
              />
              <TextField
                textLabel="‎‎‎ ‎"
                gridColumn="2/2"
                color="schema.gray.700"
                name="homoClave"
                parse={parseCURPHomoField}
              />
              <Link as="a" href={t('getCURPLink', tOptions)} target="blank" gridColumn="1 / -1">
                {t('getCURP', tOptions)}
              </Link>
            </Grid>
            <Divider w="100%" color="schema.gray.300" gridArea="2 / 1 / 2 / 3" />
            <Flex rowGap="1rem" justifyContent="space-between" gridArea="3 / 1 / 3 / 3">
              <Button as={RouterLink} to="../show" $type="btn-link" type="button">
                {t('form.actions.cancel', tOptions)}
              </Button>
              <Button type="submit" disabled={submitting} ref={submitRef}>
                {t('form.actions.submit', tOptions)}
              </Button>
            </Flex>
          </Grid>
        )}
      </Form>
    </Content>
  );
}

export default CURP;
