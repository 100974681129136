import React from 'react';
import { t } from 'i18n';
import { Panel } from 'components';
import { AddBankAccountForm } from 'components/AddBankAccountForm';
import { useController } from './hooks/useController';
import { SecondFactorSection } from './components/SecondFactorSection/SecondFactor';
import { BanksTable } from './components/BanksTable/BanksTable';
import { DeleteBankAccountForm } from './components/DeleteBankAccountForm';
import { styles } from './BankAccountSection.style';

export const BankAccountSection = ({
  innerContentProps = {},
  ...props
}) => {
  const {
    openModal,
    setOpenModal,
    currentBank,
    setCurrentBank,
    openDeleteModal,
    setOpenDeleteModal,
    handleCloseEditModal,
    handleCloseDeleteModal,
  } = useController();

  return (
    <Panel
      title={t('settings.bankAccounts.title')}
      innerContentProps={{ ...styles.innerContentProps, ...innerContentProps }}
      {...props}
    >
      <BanksTable
        setOpenModal={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        setCurrentBank={setCurrentBank}
      />
      <SecondFactorSection
        setOpenModal={setOpenModal}
      />
      <DeleteBankAccountForm
        isOpen={openDeleteModal}
        bankAccount={currentBank}
        onClose={handleCloseDeleteModal}
      />
      <AddBankAccountForm
        onClose={handleCloseEditModal}
        isOpen={openModal}
        bankAccountId={currentBank?.id}
      />
    </Panel>
  );
};
