import React from 'react';
import { Link } from 'react-router-dom';
import { tscope } from 'i18n';
import PATHS from 'routes';
import {
  Box,
  Text,
  ButtonIcon,
  TableBasic,
} from 'components';
import { styles } from './BeneficiaryTable.styles';

const t = tscope('settings.account.beneficiarySection');
const { investorSettingsPath } = PATHS;

export const BeneficiaryTable = ({
  onDelete,
  beneficiary,
  isDisabledFullName,
}) => (
  <Box>
    <TableBasic.Header {...styles.tableHeader}>
      <TableBasic.Head>
        {t('tableHeaders.name')}
      </TableBasic.Head>
      <TableBasic.Head {...styles.actionCell}>
        {t('tableHeaders.edit')}
      </TableBasic.Head>
      <TableBasic.Head {...styles.actionCell}>
        {t('tableHeaders.delete')}
      </TableBasic.Head>
    </TableBasic.Header>

    <TableBasic.Body>
      <TableBasic.Row {...styles.tableRow}>
        <TableBasic.Cell>
          <Text
            {...styles.fullName}
            color={isDisabledFullName
              ? 'schema.gray.400'
              : 'schema.gray.700'}
          >
            {beneficiary.fullName}
          </Text>
        </TableBasic.Cell>
        <TableBasic.Cell {...styles.actionCell}>
          <ButtonIcon
            as={Link}
            to={investorSettingsPath({ section: 'beneficiary/update' })}
            iconName="EDIT"
            color="schema.blue.500"
            data-testid="edit-action"
          />
        </TableBasic.Cell>
        <TableBasic.Cell {...styles.actionCell}>
          <ButtonIcon
            iconName="TRASH"
            onClick={onDelete}
            color="schema.blue.500"
            data-testid="delete-action"
          />
        </TableBasic.Cell>
      </TableBasic.Row>
    </TableBasic.Body>
  </Box>
);
