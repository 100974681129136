import { breakPoints } from 'config/constants';
import { css } from 'styled-components';

/**
 * CSSAliases will includes all aliases used as props, i.e: [bgColor, borderRadius...]
 */
let CSSAliases = [];

/**
 * shouldForwardProp:
 * used to pass on every component which implement any css rule as prop
 * this prevent to pass this props through DOM
 */

export const shouldForwardProp = (prop, defaultValidatorFn) => (
  !CSSAliases.includes(prop) && defaultValidatorFn(prop));

/**
 *
 * @param {String} rule any valid css rule name
 * @param {String | Array[String]} propNames alias used as prop for the rule
 * @param {fn} convert optional, parse the value
 * @returns a CSS`` template
 */

export const propToRule = (rule, propNames, convert = (v) => v) => {
  CSSAliases = CSSAliases.concat(propNames);

  return (props) => {
    const propName = Array.isArray(propNames)
      ? propNames.find((currentPropName) => props[currentPropName])
      : propNames;

    if (!propName) return '';
    const value = props[propName];
    if (!value) return '';

    if (typeof value === 'string') {
      return css`
        ${rule}: ${convert(value)};
      `;
    }

    const styles = [];

    if (value.base) {
      styles.push(`
        ${rule}: ${convert(value.base)};
      `);
    }

    if (value.xs) {
      styles.push(`
        @media (min-width: ${breakPoints.extraSmallBreakPoint}px) {
          ${rule}: ${convert(value.xs)};
        }
      `);
    }

    if (value.sm) {
      styles.push(`
        @media (min-width: ${breakPoints.smallBreakPoint}px) {
          ${rule}: ${convert(value.sm)};
        }
      `);
    }

    if (value.md) {
      styles.push(`
        @media (min-width: ${breakPoints.midBreakPoint}px) {
          ${rule}: ${convert(value.md)};
        }
      `);
    }

    if (value.lg) {
      styles.push(`
        @media (min-width: ${breakPoints.largeBreakPoint}px) {
          ${rule}: ${convert(value.lg)};
        }
      `);
    }

    if (value.xl) {
      styles.push(`
        @media (min-width: ${breakPoints.extraLargeBreakPoint}px) {
          ${rule}: ${convert(value.xl)};
        }
      `);
    }

    return css`
      ${styles.join('')}
    `;
  };
};
