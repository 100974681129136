import { t, tscope } from 'i18n';
import { ZIPCODE_LENGTH, messages } from 'config/constants';
import { showToastError } from 'utils/toastHandler';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { fetchStatesCatalog } from 'apis/catalogs';

const ts = tscope('components.UpdateProfileAddress');

export const useController = (options) => {
  const {
    onSubmit,
    onBeforeUpdateAddress,
  } = options;
  const [, { silentFetch }] = useReduxQuery('SESSION');
  const [profileAddress] = useReduxQuery('PROFILE_ADDRESS');
  const [createProfileAddress] = useReduxMutator('CREATE_PROFILE_ADDRESS');
  const [updateProfileAddress] = useReduxMutator('UPDATE_PROFILE_ADDRESS');

  const handleFormSubmit = async (address) => {
    try {
      onBeforeUpdateAddress();
      const statesCatalog = await fetchStatesCatalog();
      const stateId = (statesCatalog || []).find(
        (state) => state.code === address.stateId.toUpperCase(),
      ).id;
      if (profileAddress) {
        await updateProfileAddress({ ...address, stateId });
      } else {
        await createProfileAddress({ ...address, stateId });
      }
      await onSubmit();
    } catch (error) {
      showToastError(t('settings.limits.errors.updateAddressFailed'));
    } finally {
      silentFetch();
    }
  };

  const validateAddress = (address) => {
    const errors = {};
    const isZipValid = address.zip && address.zip.length === ZIPCODE_LENGTH;
    if (!address.street) errors.street = messages.required;
    if (!address.extNumber) errors.extNumber = messages.required;
    if (!address.zip) errors.zip = ts('fields.zip.errors.required');
    if (address.zip && address.zip.length !== ZIPCODE_LENGTH) errors.zip = ts('fields.zip.errors.length', { length: ZIPCODE_LENGTH });
    if (isZipValid && !address.neighborhood) errors.neighborhood = messages.required;
    return errors;
  };

  return {
    validateAddress,
    handleFormSubmit,
  };
};
