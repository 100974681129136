import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

const containerDiv = (props) => <div {...props} />;

const PaymentsSummaryContainer = styled(containerDiv)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content;
  color: ${colors.gray.g6};
  background-color: ${colors.gray.g1};
  .payments {
    &-today,
    &-month {
      font-size: 12px;
      padding-top: 1em;
      padding-bottom: 1em;
      margin-top: 16px;
      text-align: center;
      .capitalize {
        text-transform: capitalize;
      }
      h3 {
        font-size: 18px;
      }
      span {
        display: block;
        margin-bottom: 5px;
      }
    }
  }

  .note {
    grid-column: 1 / -1;
    width: 100%;
    font-size: 1.2rem;
    padding: 14px 0 14px 0;
    text-align: center;
  }
`;

export default PaymentsSummaryContainer;
