import { useEffect } from 'react';
import { useDisclosure } from 'ui';
import { useReduxSubscription } from 'utils/redux-query';

export const REASONS_KEYS = {
  IDLE: 'idle',
  INVALID: 'invalid_credentials_for_api',
  TAKEN: 'another_session',
};

export const useModalDisclosure = (props) => {
  const {
    onToggle,
    onOpen,
    isOpen,
    onClose,
  } = useDisclosure(props);
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const sessionEndsReason = sessionStatus?.reason;
  const { IDLE, INVALID, TAKEN } = REASONS_KEYS;
  const hasSessionEnded = [
    IDLE,
    TAKEN,
    INVALID,
  ].includes(sessionEndsReason);

  useEffect(() => {
    if (!hasSessionEnded) return;
    onClose();
  }, [hasSessionEnded]);

  return {
    onToggle,
    onOpen,
    isOpen,
    onClose,
  };
};
