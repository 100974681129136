import config from 'config/zendesk';

const canUseDOM = () => !(typeof window === 'undefined' || !window.document || !window.document.createElement);

const createScriptTag = () => {
  const { defer, zendeskKey } = config;
  const script = document.createElement('script');
  if (defer) {
    script.defer = true;
  } else {
    script.async = true;
  }
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
  document.body.appendChild(script);
};

export const insertZendeskScript = () => {
  if (!config.enable) return;
  if (canUseDOM() && !window.zE) {
    window.zESettings = config.settings;
    createScriptTag();
  }
};

export const displayLauncher = () => {
  if (!config.enable || !window.zE) return;
  window.zE('messenger:set', 'cookies', true);
};

export const hideLauncher = () => {
  if (!config.enable || !window.zE) return;
  window.zE('messenger:set', 'cookies', false);
};
