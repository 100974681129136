import React, { useState } from 'react';
import SearchButton from 'components/SearchButton';
import { useReduxQuery } from 'utils/redux-query';
import { Flex, Box } from 'styles';
import { Text } from 'components/Typography';
import Pagination from '../../../MyMovements/Pagination';
import StatisticsChart from './StatisticsChart';
import MobilePortfolioList from './MobilePortfolioList';
import Loading from '../../ActiveState/Loading';
import FilterButton from '../../../../FilterButton';
import ReturnSearchHeader from '../../ActiveState/ContentArea/ReturnSearchHeader';
import SearchGroup from '../../SearchGroup';
import Footer from '../../../FooterDashboard';

const MobileActiveState = (props) => {
  const {
    currentPage,
    filters,
    isLoading,
    onApplyFilter,
    onResetFilter,
    onSearchByZellId,
    onOpenFilterClick,
    showSearcher,
    ...boxProps
  } = props;
  const [isOpenSearcher, setIsOpenSearcher] = useState(false);

  const [portfolio, { status: portfolioStatus }] = useReduxQuery('PORTFOLIO');
  if (portfolio === null) return null;

  const isEmptyCollection = portfolio?.collection?.length === 0 || portfolio?.operation === null;
  const { updatedAt, totalRows } = portfolio;

  const handleSearcherSubmit = (filter) => {
    onSearchByZellId(filter);
    setIsOpenSearcher(false);
  };

  return (
    <Flex w="100%" h="100%" direction="column" {...boxProps}>
      <Flex grow="1" direction="column" rowGap="2rem">
        {isOpenSearcher && (
          <SearchGroup
            className="panel"
            onBackClick={() => setIsOpenSearcher(false)}
            onSearchByIdSubmit={handleSearcherSubmit}
          />
        )}
        {!isOpenSearcher && (
          <>
            {!showSearcher && (
              <>
                <Flex px="1rem" mt="1.5rem" justifyContent="space-between">
                  <div>
                    <FilterButton onClick={onOpenFilterClick}>Filtrar</FilterButton>
                  </div>
                  <div>
                    <SearchButton onClick={() => setIsOpenSearcher(true)}>Buscar</SearchButton>
                  </div>
                </Flex>
                <Text
                  fontSize="1rem"
                  lineHeight="1.8rem"
                  color="schema.gray.500"
                  textAlign="center"
                >
                  Última actualización
                  {' '}
                  {updatedAt}
                </Text>
              </>
            )}
          </>
        )}

        {showSearcher && <ReturnSearchHeader onBack={onResetFilter} />}

        {!isEmptyCollection && !showSearcher && <StatisticsChart />}

        {portfolioStatus.pending ? <Loading /> : <MobilePortfolioList />}
      </Flex>
      <Box w="100%" grow="0" flexShrink="0" mt="2rem">
        {portfolioStatus.completed && (
          <Pagination
            isMobile
            totalRows={totalRows}
            currentPage={filters.page}
            handlePageChange={(page) => onApplyFilter({ page })}
          />
        )}
      </Box>
      <Footer />
    </Flex>
  );
};

export default MobileActiveState;
