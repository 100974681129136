import React from 'react';
import get from 'lodash.get';
import { t } from 'i18n-js';
import ReactTooltip from 'react-tooltip';
import { toCurrency, toCurrencyWithDecimals } from 'utils/numberFormatters';
import { getProductType } from 'utils/requisitions';
import { LinearLoader } from 'components/Loaders';
import RequisitionInfoGroupContainer from './styles';
import { getListIcon } from '../../../../Requisitions/icons';

const RequisitionInfoGroup = ({ isLoading, isFundedRequisition, dataReq }) => {
  const newPayment = get(dataReq, 'collection_product.new_payment') || get(dataReq, 'general_details.nuevo_pago');
  const increaseTerm = get(dataReq, 'collection_product.increase_term');
  const amount = get(dataReq, 'general_details.monto_aprobado');
  const destiny = get(dataReq, 'general_details.destino');
  const monthlyPayment = get(dataReq, 'general_details.pago_mensual');
  const term = get(dataReq, 'general_details.plazo');
  const newTerm = get(dataReq, 'general_details.plazo_nuevo');

  const showIcon = (destinyData) => {
    const destinyCode = destinyData.slice(0, 12);
    if (destinyCode === 'Pagar Deudas') return getListIcon(destinyCode);
    return getListIcon(destinyData);
  };

  const renderContent = () => {
    if (isLoading) return <LinearLoader height={10} widthLoader={100} />;
    const productData = getProductType(dataReq);
    const isRestructuringProduct = productData?.type === 'R';

    return (
      <div className="groupItems">
        <div className="groupItems__item">
          <p>{t('common.amount')}</p>
          <div className="info">
            <p>{toCurrency(amount)}</p>
          </div>
        </div>
        <div className="groupItems__item">
          <p>{t('common.destiny')}</p>
          <div className="info">
            <p>{showIcon(destiny)}</p>
          </div>
        </div>
        <div className="groupItems__item">
          <p>{t('common.term')}</p>
          <div className="info">
            <p>{isRestructuringProduct ? newTerm : term}</p>
            {productData && productData?.type !== 'Q' && (

              <div
                className="product_type"
                data-tip={
                  isRestructuringProduct
                    ? `Plazo original: ${term}`
                    : `Plazo original: ${term} + ${increaseTerm}`
                }
              >
                {productData.type}
              </div>
            )}
          </div>
        </div>
        <div className="groupItems__item">
          <p>{t('detailRequisition.monthlyPayment')}</p>
          <div className="info">
            <p>{toCurrencyWithDecimals(newPayment || monthlyPayment)}</p>
            {productData && productData?.type !== 'Q' && (
              <>
                <div
                  className="product_type"
                  data-tip={`Pago original: ${toCurrencyWithDecimals(monthlyPayment)}`}
                >
                  {productData.type}
                </div>
                <ReactTooltip backgroundColor="white" textColor="gray" borderColor="gray" border />
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <RequisitionInfoGroupContainer className={isFundedRequisition ? 'funded' : ''}>
      {renderContent()}
    </RequisitionInfoGroupContainer>
  );
};

export default RequisitionInfoGroup;
