import axios from 'axios';
import get from 'lodash.get';
import sendToast from 'utils/toastHandler';
import { request } from './fetchers/yotepresto';

const parseState = ([name, id, code]) => ({ name, id: String(id), code });

const parseZipCodeInfo = ({ message: input = {} }) => ({
  municipality: input.d_mnpio,
  stateId: input.codigo_estado,
  stateName: input.d_estado,
  city: input.d_ciudad,
  zipCode: input.codigoPostal,
  neighborhoods: input.asentas.map((n) => n.asenta).sort(),
});

const parseZipCodeError = ({ message: error, status }) => ({ error, status });

export const fetchStatesCatalog = async () => {
  try {
    const response = await request({
      method: 'get',
      path: '/v1/catalogs/profile',
    });

    const data = get(response, 'data.catalogs.states') || null;
    return data ? data.map(parseState) : data;
  } catch (e) {
    sendToast('error', 'Error al cargar la lista de estados. Por favor inténtalo nuevamente.');
    return null;
  }
};

export const fetchCatalog = async () => {
  const { data } = await request({
    method: 'get',
    path: '/v1/catalogs/profile',
  });
  if (!data?.catalogs) return null;

  const catalog = {
    ...data.catalogs,
    states: data.catalogs.states.map(parseState),
  };

  return catalog;
};

export const fetchZipCodeInfoCatalog = async (zipCode) => {
  const ytpZipEngineUrl = 'https://zip.yotepresto.com/api/zips/';
  const response = await axios.get(`${ytpZipEngineUrl}${zipCode}`, {});
  if (get(response, 'data.status') === 'success') {
    const { data: zipcodeInfo } = response;
    return parseZipCodeInfo(zipcodeInfo);
  }
  if (get(response, 'data.status') === 'error') return parseZipCodeError(response.data || {});
  return get(response, 'data');
};

export const fetchRegisterCatalogs = async () => {
  const response = await request({
    method: 'get',
    path: '/v2/investor/profiles/catalogs',
  });

  return response;
};
