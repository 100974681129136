import React from 'react';
import { Box } from 'ui';
import {
  ActivateInvoicingModal,
  InvoicingPanel, UploadFiscalForm,
} from './components';
import { useController } from './hooks';

export const InvoicingSection = (props) => {
  const {
    isOpen,
    openForm,
    closeForm,
    isActiveInvoicingOpen,
    openActiveInvoicingModal,
    closeActiveInvoicingModal,
  } = useController();

  return (
    <Box {...props}>
      <InvoicingPanel
        onEditFiscal={openForm}
        onUploadClick={openForm}
        onActiveInvoicing={openActiveInvoicingModal}
      />
      {isOpen && (
        <UploadFiscalForm
          isOpen={isOpen}
          onClose={closeForm}
        />
      )}
      {isActiveInvoicingOpen && (
        <ActivateInvoicingModal
          isOpen={isActiveInvoicingOpen}
          onClose={closeActiveInvoicingModal}
        />
      )}
    </Box>
  );
};
