import {
  fetchCatalog as fetchCatalogAPI,
  fetchStatesCatalog,
  fetchZipCodeInfoCatalog,
  fetchRegisterCatalogs,
} from 'apis/catalogs';
import { createAsyncAction } from 'utils/redux-query';
import * as types from './types';

export const setStates = (states) => (dispatch) => {
  dispatch({ type: types.SET_STATES, payload: states });
};

export const setZipCodeInfo = (zipCode, zipCodeInfo) => (dispatch) => {
  dispatch({ type: types.SET_ZIPCODES_INFO, payload: { [zipCode]: zipCodeInfo } });
};

export const fetchStates = () => async (dispatch) => {
  const states = await fetchStatesCatalog();
  dispatch(setStates(states));
};

export const fetchZipCodeInfo = (zipCode) => async (dispatch) => {
  const zipCodeInfo = await fetchZipCodeInfoCatalog(zipCode);
  dispatch(setZipCodeInfo(zipCode, zipCodeInfo));
};

export const fetchProfileCatalogs = () => async (dispatch) => {
  const response = await fetchRegisterCatalogs();
  if (response.data) {
    const data = { ...response.data };
    const payload = {
      activities: data.activities,
      depositAmounts: data.deposit_amounts,
      fundingSources: data.funding_sources,
      moneyDestination: data.money_destination,
      montlhyFundings: data.montlhy_fundings,
      ocupations: data.ocupations,
      sectors: data.sectors,
    };
    dispatch({ type: types.SET_CATALOGS, payload });
  }
};

export const fetchCatalog = createAsyncAction('CATALOG', () => () => fetchCatalogAPI());
