import styled from 'styled-components';

import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const { gray } = colors;

const { midBreakPoint, smallBreakPoint } = breakPoints;
export const DashboardEmptyState = styled.div`
  display: grid;
  width: 100%;
  .content {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    padding-bottom: 0;
    margin-bottom: 0;
    @media (max-width: ${midBreakPoint}px) {
      flex-direction: column;
    }
  }
`;

export const LeftContent = styled.div`
  background: #f8fafc;
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: ${smallBreakPoint}px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  background: #f2f5f7;
  flex: 1;
  padding: 0 2rem;
  & .how_to_invest__container {
    width: 100%;
  }
  @media (max-width: 910px) {
    padding-right: 10px;
    padding-left: 10px;
  }
`;

export const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 260px;
  width: 80%;
  margin-bottom: 1rem;
  iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
  }
`;

export const GridCardsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  .addFunds {
    margin-top: 3rem;
    width: 100%;
  }
  .welcomeTitle {
    font-size: 1.75rem;
    color: #677d85;
    margin: 1.414em 0 3rem 0;
    font-weight: inherit;
    line-height: 1.2;
  }
  .fundsTitle {
    font-size: 3.2rem;
    margin: 1.414em 0 0.5em;
    line-height: 1.2;
    color: #677d85;
    text-align: center;
  }
  .fundsParagraph {
    text-align: center;
    font-size: 2rem;
    margin: 1em auto 3rem;
    line-height: 1.2;
    font-weight: 500;
    color: ${colors.gray.g4};
  }
  .addFundsButton {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const BorderCard = styled.div`
  background: white;
  box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.04);
  border-radius: 6px;
  display: flex;
  padding: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  @media (max-width: ${midBreakPoint}px) {
    padding: 1.5rem;
    flex-direction: column;
  }
`;

export const HalfCard = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  @media (max-width: ${midBreakPoint}px) {
    &:first-of-type {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid ${gray.g3};
    }
  }
  & svg {
    width: 6rem;
  }
  .paragraphCard {
    font-weight: 600;
    color:${colors.gray.g5}
    line-height: 180%;
    padding: 0 1rem;
  }
`;

export const CardHeader = styled.div`
  padding: 1.75rem 1.25rem;
  font-size: 2rem;
  color: ${colors.gray.g6};
  font-weight: 600;
  width: 100%;
  text-align: center;
`;

export const StackCard = styled.div`
  background: white;
`;

export const CardBody = styled.div``;

export const TextCard = styled.div`
  display: grid;
  grid-column-gap: 1.5rem;
  grid-template-columns: 10% 90%;
  justify-items: left;
  border-bottom: 1px solid #e7ebef;
  padding: 10px 0;

  svg {
    margin: auto;
  }
  .iconCard {
    grid-column: 1 / 2;
    margin-top: 1rem;
    justify-self: center;
  }

  .paragraphCard {
    grid-column: 2 / 3;
    font-weight: 400;
    line-height: 180%;
    color: #677d85;
    margin-right: 1rem;
  }

  :nth-child(3) {
    border-bottom: none !important;
    .paragraphCard {
      align-self: center;
    }
  }
`;
export const Bordertard = styled.div`
  background: white;
  box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.04);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  margin-bottom: 1rem;
  width: 100%;
`;

export const Halftard = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  justify-items: center;
  align-items: center;

  .iconCard {
    grid-column: 1 / 2;
  }

  .paragraphCard {
    grid-column: 2 / 3;
    font-weight: 400;
    line-height: 180%;
    padding: 0 1rem;
  }
`;
