import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Link from 'components/Link';
import { selectTab } from 'actions/dashboard';
import PATHS, { mainRoute } from 'routes';
import { t } from 'i18n';
import InvestorSettings from 'utils/sdkYTP/investorSettings';
import { useReduxQuery } from 'utils/redux-query';
import {
  Container,
  Title,
  RegulationImage,
  ConfirmIdentityText,
  Box,
  StartButton,
  ValidateLaterLink,
} from './ConfirmIdentity.styles';

const {
  investorSettingsLimitsLevelOneUpdateAddressPath,
  investorSettingsLimitsLevelOneConfirmPersonalInformationPath,
  investorSettingsLimitsLevelOneUpdateIdentificationPath,
} = PATHS;

function ConfirmIdentity() {
  const dispatch = useDispatch();
  const [session] = useReduxQuery('SESSION');
  const userSettings = InvestorSettings(session);

  useEffect(() => {
    dispatch(selectTab({ title: t('common.regulation.validateInformation') }));
  }, []);

  const handleStartLinkPath = () => {
    if (!userSettings.hasProfileValidated()) {
      return investorSettingsLimitsLevelOneUpdateAddressPath();
    }
    if (userSettings.hasAddressValidated()) {
      return investorSettingsLimitsLevelOneConfirmPersonalInformationPath();
    }
    if (userSettings.profileCompleted) {
      return investorSettingsLimitsLevelOneUpdateIdentificationPath();
    }
    return '#';
  };

  return (
    <Container>
      <Title>{t('common.regulation.validateYourProfile')}</Title>
      <Box>
        <RegulationImage width="300px" />
        <ConfirmIdentityText>
          {t('common.regulation.validateYourIdentity_part1')}
          {' '}
          <ConfirmIdentityText as="strong">
            {t('common.regulation.validateYourIdentity_part2')}
            {' '}
          </ConfirmIdentityText>
          {t('common.regulation.validateYourIdentity_part3')}
        </ConfirmIdentityText>
      </Box>
      <StartButton as={Link} to={handleStartLinkPath()}>
        {t('common.start')}
      </StartButton>
      <ValidateLaterLink to={mainRoute}>{t('common.regulation.validateLater')}</ValidateLaterLink>
    </Container>
  );
}

export default ConfirmIdentity;
