import {
  SET_SESSION,
  LOG_OUT_USER,
  USER_AUTHENTICATED,
  SAVE_LAST_PATH,
  SET_SESSION_LOADING,
  SET_IN_MAINTENANCE,
  SET_OTP_COUNTDOWN,
  SET_EXPIRED_SESSION_MODAL,
  SET_SESSIONS_INFO,
  SET_PHONE_NUMBER_UPDATE,
  IS_FETCHING_USER_PROFILE_INFO,
  SET_CREDENTIAL_EMAIL,
} from 'actions/auth/types';
import { loadSession } from 'utils/session';

const credentials = loadSession();
const userAuthenticated = credentials ? null : false;
const { localStorage } = window;
const countdownTimeStored = localStorage.getItem('countdownTime');
const countdownTime = countdownTimeStored ? JSON.parse(countdownTimeStored) : {};
const initialOtpRemainingTime = countdownTime.otpRemainingTime || 0;
const initialOtpCountdownStart = countdownTime.otpCountdownStart || null;

const INITIAL_STATE = {
  credentials,
  userAuthenticated,
  isLoading: userAuthenticated === null,
  inMaintenance: false,
  lastBrowserPath: false,
  otpRemainingTime: initialOtpRemainingTime,
  otpCountdownStart: initialOtpCountdownStart,
  sessionExpiredModal: null,
  sessionsInfo: null,
  phoneNumberUpdate: false,
  isFetchingUserProfileInfo: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OTP_COUNTDOWN: {
      const { otpCountdownStart, otpRemainingTime } = action.payload;
      return { ...state, otpCountdownStart, otpRemainingTime };
    }
    case SET_SESSION:
      return { ...state, credentials: action.payload };
    case SAVE_LAST_PATH:
      return { ...state, lastBrowserPath: action.payload };
    case IS_FETCHING_USER_PROFILE_INFO:
      return { ...state, isFetchingUserProfileInfo: action.payload };
    case LOG_OUT_USER:
      return { ...state, userAuthenticated: false, credentials: null };
    case USER_AUTHENTICATED:
      return { ...state, userAuthenticated: true };
    case SET_SESSION_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_IN_MAINTENANCE:
      return { ...state, inMaintenance: action.payload };
    case SET_EXPIRED_SESSION_MODAL:
      return { ...state, sessionExpiredModal: action.payload };
    case SET_SESSIONS_INFO:
      return { ...state, sessionsInfo: action.payload };
    case SET_PHONE_NUMBER_UPDATE:
      return { ...state, phoneNumberUpdate: action.payload };
    case SET_CREDENTIAL_EMAIL:
      return { ...state, credentials: { ...state.credentials, uid: action.uid } };
    default:
      return state;
  }
};
