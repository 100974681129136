/* eslint-disable no-console */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback } from 'react';
import { isDevelopment } from 'config';
import { setLoadQueryTimestamp, getQueryRecord, invalidateQueryRecords } from '../queryBase';
import { STATUS } from '../config';
import parseMeta from './parseMeta';
import { validateActionFn } from './alerts';
import createActions from '../actionCreator/createActions';
import { useErrorEffect } from './useErrorEffect';

const useReduxQuerySelector = (keystore) => {
  const { payload, meta } = useSelector((state) => state[keystore]);
  const dispatch = useDispatch();
  const queryRecord = getQueryRecord(keystore);
  const actionStored = queryRecord.query;

  const tryCallActionStored = useCallback(
    async ({ silent = false } = {}, ...params) => {
      try {
        await dispatch(actionStored({ silent }, ...params));
      } catch (error) {
        if (isDevelopment) {
          console.warn(
            'Warning(redux-query): the error displayed below was throw by (%s) query, IGNORE this warn if you already catch this error on your component',
            keystore,
          );
          console.error(error);
        }
      }
    },
    [actionStored],
  );

  const refetch = (...params) => {
    validateActionFn(keystore, actionStored);

    return tryCallActionStored({ silent: false }, ...params);
  };

  const silentFetch = (...params) => {
    validateActionFn(keystore, actionStored);

    return tryCallActionStored({ silent: true }, ...params);
  };

  const { reinitialize, setPayload } = createActions(keystore);
  const dispatchReinitialize = () => {
    invalidateQueryRecords([keystore]);
    dispatch(reinitialize());
  };

  const updateOptimistically = useCallback((optimisticPayload) => {
    dispatch(setPayload(optimisticPayload));
  }, [setPayload]);

  useEffect(() => {
    if (getQueryRecord(keystore).initializedAt || meta.status !== STATUS.INITIAL) return;

    validateActionFn(keystore, actionStored);

    const timeStamp = Date.now();

    setLoadQueryTimestamp(keystore, timeStamp);
    tryCallActionStored();
  }, [meta.status]);

  useErrorEffect({ meta });

  return [
    payload,
    {
      ...parseMeta(meta),
      refetch,
      silentFetch,
      updateOptimistically,
      reinitialize: dispatchReinitialize,
    },
    // keeps as legacy third result
    {
      refetch,
      reinitialize: dispatchReinitialize,
    },
  ];
};

export default useReduxQuerySelector;
