import React from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18n';
import websiteImageURL from 'assets/website.png';
import { Grid, Box } from 'styles';
import { Heading, Text } from 'components/Typography';
import Button from 'components/Button/Button';
import { logOutUser } from 'actions/auth/fetcher';

const ts = (id, ...options) => t(id, { scope: 'Mati', ...options });

const TechnicalProblems = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOutUser());
  };

  return (
    <Grid maxWidth="90rem" p={{ base: '2rem', md: '2rem 4rem' }} rowGap="2rem" columnGap="1rem">
      <Heading alignSelf="center" gridArea={{ base: '2 / 1', md: '1 / 1' }}>
        {ts('Errors.technicalProblems.title')}
      </Heading>
      <Box
        as="img"
        src={websiteImageURL}
        alt=""
        maxWidth="40rem"
        gridArea={{ base: '1 / 1', md: '1 / 2' }}
      />
      <Text
        justifySelf="center"
        textAlign="center"
        gridArea={{ base: '3 / 1', md: '2 / 1 / 2 / 3' }}
        maxWidth="40rem"
      >
        {ts('Errors.technicalProblems.description')}
      </Text>
      <Button
        onClick={handleLogout}
        justifySelf="center"
        $type="success"
        type="button"
        gridArea={{ base: '4 / 1', md: '3 / 1 / 3 / 3' }}
      >
        Cerrar Sesión
      </Button>
    </Grid>
  );
};

export default TechnicalProblems;
