import React from 'react';
import { Form } from 'react-final-form';
import { tscope } from 'i18n';
import {
  Text,
  Flex,
  Grid,
  TextField,
  SelectField,
  ModalCloseButton,
  ExternalLink,
  Button,
} from 'components';
import { ZIPCODE_LENGTH } from 'config/constants';
import { onlyDigits } from 'utils/inputRestrictions';
import { useController } from './hooks';
import { ZenfiCard } from './components';
import { Modal, styles as s } from './UploadFiscalForm.styles';

const t = tscope('settings.account.uploadFiscalForm');
const commonT = tscope('common');

export const UploadFiscalForm = ({
  isOpen,
  onClose,
}) => {
  const {
    onSubmit,
    validate,
    closeModal,
    isSubmitting,
    initialValues,
    regimenOptions,
    disabledFields,
    isRegimeDisabled,
  } = useController({
    onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalCloseButton onClick={closeModal} />
      <Flex {...s.modalHeader}>
        <Text as="h2" t={t} i18nKey="title" {...s.title} />
      </Flex>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Grid
            as="form"
            onSubmit={handleSubmit}
            {...s.form}
          >
            <TextField
              name="username"
              disabled={disabledFields}
              textLabel={t('fields.username.label')}
              placeholder={t('fields.username.placeholder')}
              textHelper={<Text t={t} i18nKey="fields.username.textHelper" {...s.textHelper} />}
            />
            <SelectField
              name="regimen"
              textLabel={t('fields.regimen.label')}
              placeholder={t('fields.regimen.placeholder')}
              disabled={isRegimeDisabled || disabledFields}
            >
              {regimenOptions
                && regimenOptions.map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
            </SelectField>
            <TextField
              name="zipCode"
              parse={onlyDigits}
              maxLength={ZIPCODE_LENGTH}
              disabled={disabledFields}
              textLabel={t('fields.zipCode.label')}
              placeholder={t('fields.zipCode.placeholder')}
              textHelper={<Text t={t} i18nKey="fields.zipCode.textHelper" {...s.textHelper} />}
            />
            <Text
              t={t}
              i18nKey="getZenfi"
              {...s.textHelper}
              components={{
                zenfi: <ExternalLink
                  {...s.textHelper}
                  {...s.zenfi}
                  i18nKey="common.zenfi"
                  href={commonT('zenfiDownloadApp')}
                />,
              }}
            />
            <Flex {...s.actionsContent}>
              <Button
                {...s.actionButton}
                $type="btn-link"
                onClick={onClose}
                disabled={isSubmitting || disabledFields}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                {...s.actionButton}
              >
                {t('actions.submit')}
              </Button>
            </Flex>
          </Grid>
        )}
      </Form>
      <Flex {...s.modalFooter}>
        <ZenfiCard />
      </Flex>
    </Modal>
  );
};
