import camelify from 'camelcase-keys-recursive';
import { request } from '../../fetchers/yotepresto';

const deactivate = async () => {
  const config = {
    path: '/v2/second_factors/deactivate',
    method: 'put',
  };

  const { data } = await request(config);

  return camelify(data);
};

export default deactivate;
