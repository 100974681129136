import { useState, useEffect } from 'react';
import { t } from 'i18n';
import { ZIPCODE_LENGTH } from 'config/constants';
import { showToastError, showToastWarn } from 'utils/toastHandler';
import { useReduxQuery } from 'utils/redux-query';
import { fetchZipCodeInfoCatalog } from 'apis/catalogs';

export const useController = (options) => {
  const {
    values,
    addressForm,
  } = options;
  const [
    profileAddress,
    { error: fetchProfileAddressError },
  ] = useReduxQuery('PROFILE_ADDRESS');
  const [displayLocation, setDisplayLocation] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const { initialValues } = addressForm.getState();

  const fillLocationFields = (location) => {
    setNeighborhoods(location?.neighborhoods || []);
    addressForm.change('city', location?.city || location?.municipality || '');
    addressForm.change('municipality', location?.municipality || '');
    addressForm.change('stateName', location?.stateName || '');
    addressForm.change('stateId', location?.stateId || '');
  };

  const autoFillNeighborhood = async (zip, neighborhood) => {
    if (!values.zip || values.zip.length < ZIPCODE_LENGTH) {
      setDisplayLocation(false);
      fillLocationFields(null);
      return;
    }
    const location = await fetchZipCodeInfoCatalog(zip);
    const noLocationFound = !location || location.status === 'error';
    setDisplayLocation(!noLocationFound);
    if (noLocationFound) showToastWarn(t('settings.limits.errors.failedLoadZIP'));
    fillLocationFields(location);
    if (location?.neighborhoods) {
      const neighborhoodSelected = location.neighborhoods
        .find(
          (nh) => String(nh)
            .toLowerCase() === String(neighborhood).toLowerCase(),
        );
      addressForm.change('neighborhood', neighborhoodSelected || '');
    } else {
      addressForm.change('neighborhood', '');
    }
  };

  useEffect(() => {
    if (profileAddress) {
      addressForm.initialize({
        street: profileAddress.street,
        intNumber: profileAddress.intNumber,
        extNumber: profileAddress.extNumber,
        city: profileAddress.city,
        stateId: profileAddress.stateID,
        stateName: profileAddress.state?.name,
        neighborhood: profileAddress.neighborhood,
        municipality: profileAddress.municipality,
        zip: profileAddress.zip,
      });
    }
  }, [profileAddress]);

  useEffect(() => {
    if (fetchProfileAddressError) {
      showToastError(t('settings.limits.errors.failedLoadAddress'));
    }
  }, [fetchProfileAddressError]);

  useEffect(() => {
    autoFillNeighborhood(values.zip, initialValues.neighborhood);
  }, [values.zip, initialValues.neighborhood]);

  return {
    neighborhoods,
    displayLocation,
  };
};
