import styled, { css } from 'styled-components';
import { colors } from 'styles';

export const Switch = styled.button`
  display: flex;
  border-radius: 20rem;
  background-color: ${colors.schema.gray[300]};
  border: 1px solid;
  border-color: ${colors.schema.gray[300]};
  margin: 0;
  height: ${({ boxSize }) => boxSize + 0.2}rem;
  width: ${({ size }) => size}rem;
  transition: all 0.4s ease;

  &:focus {
    outline: ${colors.schema.blue[300]} solid 2px;
  }

  ${({ checked }) => (checked
    ? css`
      background-color: ${colors.schema.blue[500]};
      border-color: ${colors.schema.blue[500]};
    `
    : '')
}
`;

export const Dot = styled.span`
  border: 0;
  width: ${({ boxSize }) => boxSize}rem;
  height: ${({ boxSize }) => boxSize}rem;
  user-select: none;
  background: ${colors.schema.white};
  border-radius: 4em;
  transition: all 0.4s ease;

  ${({ checked }) => (checked
    ? css`
      transform: ${({ boxSize }) => css`translate(${(boxSize - 0.2)}rem, 0)`};
    `
    : '')
}
`;
