import React, {
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useField, useFormState } from 'react-final-form';
import { t } from 'i18n';
import { capitalize } from 'utils/strings';
import isNil from 'utils/isNil';
import debounce from 'utils/Monads/debounce';
import {
  getDayList,
  getYearList,
  getMonthList,
  countDaysInMonth,
} from 'utils/Date/Calendar';

export const getErrorMessage = (meta, { validateOn = 'blur' } = {}) => {
  const {
    error,
    touched,
    modified,
    submitError,
  } = meta;
  const displayOn = { blur: touched, change: modified };
  const shouldDisplay = displayOn[validateOn] && (error || submitError);
  return shouldDisplay
    ? error || submitError
    : '';
};

const validate = (date) => {
  if (isNil(date)) return null;
  const [day, month, year] = date.split('/');
  const isInvalid = !day || !month || !year;
  return isInvalid
    ? t('Forms.fields.errors.invalidDate')
    : null;
};

export const useController = ({
  name,
  disabled,
  spanYears,
  startingYearAt,
  onBlur = () => {},
  color = 'schema.gray.700',
}) => {
  const inputDayRef = useRef(null);
  const inputMonthRef = useRef(null);
  const inputYearRef = useRef(null);
  const { submitFailed } = useFormState();
  const { input, meta } = useField(name, { validate });
  const [day, month, year] = input.value.split('/');
  const hasError = getErrorMessage(meta);

  const changeDayValueHandler = ({ target: { value: newDay } }) => {
    input.onChange([newDay, month, year].join('/'));
  };

  const changeMonthValueHandler = ({ target: { value: newMonth } }) => {
    if (Number(day) > countDaysInMonth(newMonth, year)) {
      input.onChange(['01', newMonth, year].join('/'));
      return;
    }

    input.onChange([day, newMonth, year].join('/'));
  };

  const changeYearValueHandler = ({ target: { value: newYear } }) => {
    input.onChange([day, month, newYear].join('/'));
  };

  const daysOptions = useMemo(
    () => getDayList(month || 1, year || 2000).map((index) => (
      <option key={index} value={`${index}`.padStart(2, '0')}>
        {index}
      </option>
    )),
    [month, year],
  );

  const monthsOptions = useMemo(
    () => getMonthList().map(({ name: monthName, id }) => (
      <option key={id} value={id}>
        {capitalize(monthName)}
      </option>
    )),
    [],
  );

  const yearOptions = useMemo(
    () => getYearList({ from: startingYearAt, span: spanYears }).map((yearOption) => (
      <option key={yearOption} value={yearOption}>
        {yearOption}
      </option>
    )),
    [],
  );

  const triggerBlur = (ev) => {
    const cannotPerformAction = [
      inputDayRef.current,
      inputMonthRef.current,
      inputYearRef.current,
    ].includes(document.activeElement);
    if (cannotPerformAction) return;

    onBlur(ev);
  };

  const triggerBlurDebounced = useCallback(debounce(triggerBlur, 50), []);

  const onBlurHandler = useCallback((ev) => {
    const areValuesSelected = [day, month, year].every(Boolean);
    if (!submitFailed && !areValuesSelected) return;
    input.onBlur(ev);
    triggerBlurDebounced(ev);
  }, [triggerBlurDebounced, day, month, year]);

  const statusVariants = {
    normal: {
      color,
    },
    disabled: {
      label: {
        color: 'schema.gray.300',
      },
    },
  };

  const statusVariant = (disabled) ? statusVariants.disabled : statusVariants.normal;

  return {
    day,
    year,
    month,
    input,
    hasError,
    yearOptions,
    daysOptions,
    inputDayRef,
    inputYearRef,
    inputMonthRef,
    monthsOptions,
    statusVariant,
    onBlurHandler,
    changeDayValueHandler,
    changeYearValueHandler,
    changeMonthValueHandler,
  };
};
