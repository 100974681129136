export default {
  container: {
    maxWidth: '33rem',
    px: { base: '1rem', sm: '2rem' },
  },
  title: {
    as: 'h3',
    textAlign: 'center',
  },
  descriptionText: {
    textAlign: 'center',
  },
  card: {
    p: '3rem',
    mb: '2rem',
    centered: true,
    direction: 'column',
    borderRadius: '1rem',
    bgColor: 'schema.white',
    bosShadow: '0 5px 12px 0 rgba(38, 57, 69, 0.09)',
  },
  notice: {
    textAlign: 'center',
    color: 'schema.gray.400',
  },
  image: {
    mb: '3.5rem',
    boxSize: '12rem',
  },
  highlightedText: {
    as: 'strong',
    color: 'schema.gray.700',
  },
};
