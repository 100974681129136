export default {
  container: {
    m: { base: 0, md: '0 4rem' },
    rowGap: '2rem',
    maxWidth: '35rem',
    direction: 'column',
  },
  title: {
    mb: '2rem',
    textAlign: 'center',
  },
  instructions: {
    weight: 'bold',
  },
  termsCard: {
    bgColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 5px 12px 0 rgba(38, 57, 69, 0.09)',
  },
  loaderContainer: {
    mt: '6rem',
    textAlign: 'center',
  },
};
