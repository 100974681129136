import { createActions, createAsyncAction } from 'utils/redux-query';
import * as API from 'apis/investor/bankAccounts';

const bankAccountsAction = createActions('BANK_ACCOUNTS');

export const fetchBankAccounts = createAsyncAction(
  'BANK_ACCOUNTS',
  () => () => API.fetchBankAccounts(),
);

export const createBankAccounts = createAsyncAction(
  'CREATE_BANK_ACCOUNT',
  (...params) => async (dispatch, getState) => {
    const { bankAccount } = await API.createBankAccount(...params);
    const { BANK_ACCOUNTS: { payload } } = getState();
    const bankAccounts = payload?.bankAccounts || [];
    const bankAccountsPatch = {
      bankAccounts: [
        ...bankAccounts,
        bankAccount,
      ],
    };
    const meta = { status: 'COMPLETED', error: null };
    dispatch(bankAccountsAction.update(bankAccountsPatch, meta));
    return { bankAccount };
  },
);

export const updateBankAccounts = createAsyncAction(
  'UPDATE_BANK_ACCOUNT',
  (...params) => async (dispatch, getState) => {
    const { bankAccount } = await API.updateBankAccount(...params);
    const { BANK_ACCOUNTS: { payload } } = getState();
    const bankAccounts = payload?.bankAccounts || [];
    const bankAccountsPatch = {
      bankAccounts: bankAccounts
        .map((currentBankAccount) => (
          currentBankAccount.id === bankAccount.id
            ? bankAccount
            : currentBankAccount)),
    };
    const meta = { status: 'COMPLETED', error: null };
    dispatch(bankAccountsAction.update(bankAccountsPatch, meta));
    return { bankAccount };
  },
);

export const deleteBankAccounts = createAsyncAction(
  'DELETE_BANK_ACCOUNT',
  ({ bankAccount, otp }) => async (dispatch, getState) => {
    const result = await API.deleteBankAccount({ bankAccount, otp });
    const { BANK_ACCOUNTS: { payload } } = getState();
    const bankAccounts = payload?.bankAccounts || [];
    const bankAccountsPatch = {
      bankAccounts: bankAccounts
        .filter((currentBankAccount) => (
          currentBankAccount.id !== bankAccount.id)),
    };
    const meta = { status: 'COMPLETED', error: null };
    dispatch(bankAccountsAction.update(bankAccountsPatch, meta));
    return result;
  },
);
