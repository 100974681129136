import styled from 'styled-components';

import { breakPoints } from 'config/constants';
import colors from 'styles/colors';

const { midBreakPoint } = breakPoints;

// TODO(yosantiagogarcia): Refactor by html elements
const AddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-column: 4 / -1;
  padding-top: 7rem;
  padding-bottom: 7rem;

  @media (max-width: ${midBreakPoint}px) {
    grid-column: 5 / -1;
  }
  .title {
    width: 300px;
    margin-bottom: 3rem;
    text-align: center;
    h3 {
      font-size: 2.4rem;
      color: #04475c;
    }
  }
  & > form,
  .form {
    width: 300px;
  }
  .form-group {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    &.submit {
      text-align: center;
    }
    label {
      color: ${colors.gray.g8};
      font-weight: 600;
      display: block;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
      &.disabled {
        color: ${colors.gray.g4};
      }
    }
    &.num-cp {
      display: flex;
      gap: 12px;
      .num-ext,
      .num-int {
        flex: 2;
      }
      .zip {
        flex: 3;
      }
    }
  }
`;

export default AddressFormContainer;
