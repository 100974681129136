import React from 'react';
import styled from 'styled-components';

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const StatisticsChartContainer = styled(ContainerDiv)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: .6rem;
  .statistics {
    &__chart{
      width: 95%;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1) ;
      &__body {
        & .chart__item {
          display: flex;
          height: 5.2rem;
          background-color: white;
          font-size: 1.12rem;
          font-weight: normal;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 0.8rem;
          border-bottom: 1px solid #E7EBEF;
          color: #04475C;
          &__content {
            flex: 1;
            justify-content: center;
            display: flex;
            align-items: center;
            &.quantity {
              font-weight: bold;
            }
            &.percentage {
              display: flex;
              align-items: center;
              & .chart__container {
                width: 2.5rem;
                margin-left: .5rem;
              }
            }
          }
          .percentage__container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 75px;
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
      &__description {
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 0 .5rem;
        text-align: center;
        font-size: 1.1rem;
        color: #93AFB9;
      }
      &__information {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 3rem;
        border-bottom: 1px solid #DEE6E8;
        & .information__body {
          display: flex;
          align-items: center;
          width: 24rem;
          height: 7rem;
          background-color: white;
          border-radius: 0.6rem;
          font-size: 1.1rem;
          box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1) ;
          &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            height: 100%;
            color: #04475C;
            &:first-of-type {
              border-right: 1px solid #DEE6E8;
            }
            & p:first-of-type {
              font-size: 1.5rem;
              font-weight: 500;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
`;

export default StatisticsChartContainer;
