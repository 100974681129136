import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderGraph = () => (
  <ContentLoader speed={2} primaryColor="#F2F5F7" secondaryColor="#E7EBEF" height={230} width={480}>
    <rect x="370" y="25" rx="3" ry="3" width="85" height="9" />
    <rect x="310" y="50" rx="3" ry="3" width="85" height="9" />
    <rect x="250" y="80" rx="3" ry="3" width="85" height="9" />
    <rect x="180" y="105" rx="3" ry="3" width="85" height="9" />
    <rect x="110" y="135" rx="3" ry="3" width="85" height="9" />
    <rect x="40" y="165" rx="3" ry="3" width="85" height="9" />
  </ContentLoader>
);

export default LoaderGraph;
