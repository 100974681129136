import { tscope } from 'i18n';

const ts = tscope('Forms.fields.errors');

export const resolver = (values) => {
  const {
    password,
  } = values;
  const errors = {};
  if (!password) errors.password = { message: ts('required') };

  return {
    values,
    errors,
  };
};
