import React, { forwardRef } from 'react';
import { tscope } from 'i18n';
import {
  Grid,
  SelectField,
  TextField,
} from 'components';
import { ZIPCODE_LENGTH } from 'config/constants';
import { useController, useZipField } from './hooks/useController';
import { styles } from './LocationPartialForm.styles';

const t = tscope('settings.account.beneficiaryForm');

export const LocationPartialForm = ({
  'data-testid': dataTestId = 'address',
}) => {
  const {
    neighborhoods,
    displayLocation,
  } = useController();

  const testIds = {
    neighborhood: dataTestId.concat('.neighborhood'),
    municipality: dataTestId.concat('.municipality'),
    city: dataTestId.concat('.city'),
    stateName: dataTestId.concat('.stateName'),
  };

  return (
    <>
      <SelectField
        {...styles.neighborhoodField}
        name="neighborhood"
        textLabel={t('fields.neighborhood.label')}
        disabled={!displayLocation}
        data-testid={testIds.neighborhood}
      >
        <option key="empty" value="">Selecciona una colonia</option>
        {
            neighborhoods?.map(
              (neighborhood) => (
                <option
                  key={neighborhood}
                  value={neighborhood}
                >
                  {neighborhood}
                </option>
              ),
            )
          }
      </SelectField>

      <Grid display={displayLocation ? 'grid' : 'none'} rowGap="2rem">
        <TextField
          readOnly
          disabled
          name="municipality"
          textLabel={t('fields.municipality.label')}
          data-testid={testIds.municipality}
          {...styles.field}
        />
        <TextField
          readOnly
          disabled
          name="city"
          textLabel={t('fields.city.label')}
          data-testid={testIds.city}
          {...styles.field}
        />
        <TextField
          readOnly
          disabled
          name="stateName"
          textLabel={t('fields.stateName.label')}
          data-testid={testIds.stateName}
          {...styles.field}
        />
      </Grid>
    </>
  );
};

export const ZipField = forwardRef(({
  name,
  disabled,
  formControl,
  shouldValidate,
  ...props
}, ref) => {
  const {
    key,
    validate,
    validating,
  } = useZipField({
    name,
    formControl,
    shouldValidate,
  });

  return (
    <TextField
      {...props}
      ref={ref}
      disabled={validating || disabled}
      key={key}
      name={name}
      validate={validate}
      maxLength={ZIPCODE_LENGTH}
    />
  );
});
