import React from 'react';
import { Box } from 'styles';
import RequisitionsFilterContent from './RequisitionFilterContent';
import RequisitionsFilterContainer from './styles';

const RequisitionsFilterElement = (props) => {
  const {
    onCloseFilter, onToggleFilter, openFilter, large,
  } = props;

  const displayFilter = openFilter ? 'block' : 'none';

  return (
    <Box
      left="0"
      right="0"
      bottom="0"
      h="100vh"
      zIndex="9"
      role="button"
      maxWidth={{ base: 'auto', md: '27rem' }}
      display={{ base: displayFilter, md: 'block' }}
      position={{ base: 'fixed', md: 'absolute' }}
      top={{ base: '5.6rem', md: '0' }}
      onClick={() => onCloseFilter()}
    >
      <RequisitionsFilterContainer
        onClick={(ev) => ev.stopPropagation()}
        className="filterSidebar"
        onCloseFilter={onCloseFilter}
        onToggleFilter={onToggleFilter}
        openFilter={openFilter}
        large={large}
      >
        <RequisitionsFilterContent {...props} />
      </RequisitionsFilterContainer>
    </Box>
  );
};

export default RequisitionsFilterElement;
