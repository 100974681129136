import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import withSizes from 'react-sizes';
import { Link } from 'react-router-dom';
import { breakPoints, publicUrls } from 'config/constants';
import PATHS from 'routes';
import { updateRequisitionsCount } from 'actions/requisitions';
import { selectTab } from 'actions/dashboard';
import noLoanImage from 'assets/dashboard/no-loan-state.svg';
import Button from 'components/Button';
import Footer from 'views/DashboardContent/FooterDashboard';
import { useReduxQuery } from 'utils/redux-query';
import isNil from 'utils/isNil';
import { toCurrency } from 'i18n';
import { REQUISITION_ITEM } from '../../../SideBar/utils';
import LastSignAtBanner from '../../LastSignAtBanner';
import { VideoCard } from '../EmptyState/styles';
import NoLoanContainer, { LimitsBanner } from './styles';

const { investorRequisitionsPath } = PATHS;

const lendButton = (handleClick) => (
  <Link to={investorRequisitionsPath()}>
    <Button color="success" onClick={handleClick}>
      Empezar a prestar
    </Button>
  </Link>
);

const NoLoanState = ({ isMobile, isMidSize, selectActiveTab }) => {
  const dispatch = useDispatch();
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [totalRequisitions] = useReduxQuery('REQUISITIONS_TOTAL_ON_FUNDING');

  useEffect(() => {
    dispatch(updateRequisitionsCount());
  }, []);
  const handleTabSelection = () => selectActiveTab(REQUISITION_ITEM);
  const requisitionsQty = isNil(totalRequisitions) ? '-' : totalRequisitions;
  const balanceStr = isNil(statements?.saldoDisponible)
    ? '-'
    : toCurrency(statements.saldoDisponible, { precision: 2 });

  return (
    <NoLoanContainer isMobile={isMidSize} id="noLoan">
      {(isMobile || isMidSize) && (
        <div className="last__sign__at">
          <LastSignAtBanner />
        </div>
      )}
      <div className="content__container">
        <div className="leftContent">
          {!isMidSize && <img className="funds" alt="Funds" src={noLoanImage} />}
          <h2 className="greenText">¡Tu cuenta tiene fondos!</h2>
          {!isMidSize && <p>Para poder hacer crecer tu dinero empieza a prestar</p>}
          {!isMidSize && <div className="leftButton">{lendButton(handleTabSelection)}</div>}
        </div>
        <div className="rightContent">
          {!isMobile && !isMidSize && (
            <div className="last__sign__at">
              <LastSignAtBanner />
            </div>
          )}
          {!isMidSize && <h1>Bienvenido a yotepresto.com</h1>}
          <div className={!isMidSize ? 'card' : 'card mobile'}>
            <h3>Aún no has prestado</h3>
            <p>
              Actualmente no has prestado dinero a ninguna solicitud, tienes
              <b>{balanceStr}</b>
              disponibles para prestar y hay
              <b>
                {requisitionsQty}
                {' '}
                solicitud
                {totalRequisitions !== 1 ? 'es' : ''}
                {' '}
                en fondeo.
              </b>
            </p>
            <div className="rightButton">{lendButton(handleTabSelection)}</div>
            <p>Recuerda que el monto mínimo para prestar es $200.00.</p>
          </div>
          <LimitsBanner />
          <p className={!isMidSize ? 'videoHeader' : 'videoHeader_mobile'}>
            Aquí te decimos cómo invertir en nuestra plataforma
          </p>
          <VideoCard className="videoContainer">
            <iframe
              src="https://www.youtube.com/embed/JQJL4XpjSsI?enablejsapi=1&rel=0&origin=https://testingytp.com"
              frameBorder="0"
              title="agregar-fondo-vid"
            />
          </VideoCard>
          <div className="links">
            <a href={publicUrls.guide} target="_blank" rel="noopener noreferrer">
              Guía
            </a>
            <a href={publicUrls.statistics} target="_blank" rel="noopener noreferrer">
              Estadísticas
            </a>
            <a href={publicUrls.helpCenter} target="_blank" rel="noopener noreferrer">
              Centro de Ayuda
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </NoLoanContainer>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < breakPoints.smallBreakPoint,
  isMidSize: width < breakPoints.midBreakPoint,
});

const mapDispatchToProps = {
  selectActiveTab: selectTab,
};

export default connect(null, mapDispatchToProps)(withSizes(mapSizesToProps)(NoLoanState));
