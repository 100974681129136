import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const fundingDiv = ({ onToggleFilter, isMobile, ...props }) => <div {...props} />;
const FundingContainer = styled(fundingDiv)`
  grid-column: 7 / -1;
  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-column: 1 / -1;
    & a {
      display: flex;
      text-decoration: none;
    }
  }

  & .inFunding {
    box-shadow: ${colors.shadow.card};
    border-radius: 0.4rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 2rem 0;
    margin: 0;
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      flex-direction: row;
    }
    & .fundingLoader {
      height: 100%;
      width: 100%;
      max-width: 200px;
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        height: 20%;
        width: 20%;
      }
    }
    & .quantity {
      text-align: center;
      font-size: 2.5rem;
      color: #134052;
      font-weight: 600;
    }
    & p {
      font-size: 1.4rem;
    }
  }
`;

export default FundingContainer;
