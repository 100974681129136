import { useHistory } from 'react-router-dom';
import PATHS from 'routes';

const {
  investorSettingsPath,
} = PATHS;

export const useController = () => {
  const history = useHistory();
  const onBeneficiaryUpdated = () => {
    history.push(investorSettingsPath({ section: 'account' }));
  };

  return {
    onBeneficiaryUpdated,
  };
};
