import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import ReactTooltip from 'react-tooltip';
import Button from 'components/Button';
import ButtonIcon from 'components/Button/ButtonIcon';
import RequisitionsFilterContainer from './styles';
import {
  AutomovilSvg,
  deudaSvg,
  educacionSvg,
  familiarSvg,
  negocioSvg,
  otroSvg,
  personalSvg,
  viviendaSvg,
} from '../../icons';

const RequisitionsFilterContent = ({
  onUpdate, onToggleFilter, filterState, large,
}) => {
  const [filter, setFilter] = useState({ funded_amount: true, hidden: false });
  const qualificationFilters = ['A', 'B', 'C'];
  const periodFilter = ['6', '12', '18', '24', '30', '36'];
  const destinyFilter = [
    {
      IconDestiny: familiarSvg,
      id: 1,
      name: 'Familiar',
    },
    {
      IconDestiny: deudaSvg,
      id: 2,
      name: 'Pagar Deudas',
    },
    {
      IconDestiny: AutomovilSvg,
      id: 3,
      name: 'Automóvil',
    },
    {
      IconDestiny: negocioSvg,
      id: 4,
      name: 'Negocio',
    },
    {
      IconDestiny: educacionSvg,
      id: 5,
      name: 'Educación',
    },
    {
      IconDestiny: viviendaSvg,
      id: 6,
      name: 'Vivienda',
    },
    {
      IconDestiny: personalSvg,
      id: 7,
      name: 'Gastos Personales',
    },
    {
      IconDestiny: otroSvg,
      id: 8,
      name: 'Otro',
    },
  ];

  useEffect(() => {
    if (Object.getOwnPropertyNames(filterState).length > 0) {
      setFilter(filterState);
    }
  }, []);

  const onSubmitFilter = () => {
    onUpdate(filter);
  };

  const clearFilter = () => {
    const initialFilter = {
      qualification: [],
      term: [],
      destination: [],
      funded_amount: true,
      hidden: false,
    };
    setFilter(initialFilter);
    onUpdate(initialFilter);
  };

  const updateFilter = (filterField) => {
    const { name, valueItem } = filterField;
    let qualificationArr = filter.qualification || [];
    let periodArr = filter.term || [];
    let destinyArr = filter.destination || [];
    let flagBorrowed = filter.funded_amount || false;
    let flagHidden = filter.hidden || false;
    switch (name) {
      case 'qualification':
        if (qualificationArr.indexOf(valueItem) >= 0) {
          qualificationArr = qualificationArr.filter((f) => f !== valueItem);
        } else {
          qualificationArr.push(valueItem);
        }
        break;
      case 'term':
        if (periodArr.indexOf(valueItem) >= 0) {
          periodArr = periodArr.filter((f) => f !== valueItem);
        } else {
          periodArr.push(valueItem);
        }
        break;
      case 'destination':
        if (destinyArr.indexOf(valueItem.name) >= 0) {
          destinyArr = destinyArr.filter((f) => f !== valueItem.name);
        } else {
          destinyArr.push(valueItem.name);
        }
        break;
      case 'funded_amount':
        flagBorrowed = !flagBorrowed;
        break;
      case 'hidden':
        flagHidden = !flagHidden;
        break;
      default:
    }

    setFilter((pFilter) => ({
      ...pFilter,
      qualification: qualificationArr,
      term: periodArr,
      destination: destinyArr,
      funded_amount: flagBorrowed,
      hidden: flagHidden,
    }));
  };

  const renderCheckboxButtonFields = (items, fieldType) => {
    const filterCategory = filter[fieldType];
    const renderSelectClass = (itemValue) => {
      let classItem = '';
      if (fieldType === 'qualification') classItem += 'qualificationButton ';
      if (filterCategory) {
        if (fieldType === 'destination' && filter.destination.indexOf(itemValue.name) >= 0) classItem += 'selected';
        if (filterCategory.find((val) => val === itemValue)) classItem += 'selected';
      }
      return classItem;
    };

    return items.map((itm = '', index) => {
      const { IconDestiny } = itm;
      const key = `${index}`;
      return (
        <Field
          name={fieldType}
          key={key}
          value={fieldType !== 'destination' ? itm : itm.name}
          type="button"
          render={(props) => (
            <Button
              className={`checkboxButton ${renderSelectClass(itm)}`}
              handleClick={() => updateFilter({ name: fieldType, valueItem: itm })}
              category={fieldType}
              color="info"
              data-for={fieldType !== 'destination' ? null : 'destinyTooltip'}
              data-tip={fieldType !== 'destination' ? null : itm.name}
              {...props.input}
            >
              {fieldType !== 'destination' ? (
                itm
              ) : (
                <IconDestiny className="iconDestinyFilter" {...props.input} />
              )}
            </Button>
          )}
        />
      );
    });
  };

  return (
    <RequisitionsFilterContainer>
      <Form
        initialValues={{
          qualification: '',
          term: '',
          destination: '',
          funded_amount: false,
          hidden: false,
        }}
        onSubmit={onSubmitFilter}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="filterForm">
            <div className="contentFilter">
              <p className="titleFilter">
                Filtrar solicitudes
                {large && (
                  <ButtonIcon iconName="CLOSE" className="closeFilter" onClick={onToggleFilter} />
                )}
              </p>
              <hr />
              <div className="categoryFilter">
                <p className="titleCategoryFilter">Calificación</p>
                <div className="inputsFilter">
                  {renderCheckboxButtonFields(qualificationFilters, 'qualification')}
                </div>
              </div>
              <div className="categoryFilter">
                <p className="titleCategoryFilter">Plazo</p>
                <div className="inputsFilter">
                  {renderCheckboxButtonFields(periodFilter, 'term')}
                </div>
              </div>
              <div className="categoryFilter destinyFilter">
                <p className="titleCategoryFilter">Destino</p>
                <div className="inputsFilter destinyInputs">
                  {renderCheckboxButtonFields(destinyFilter, 'destination')}
                </div>
              </div>
              <hr />
              <div className="categoryFilter">
                <div className="checkboxFieldsFilter">
                  <Field name="funded_amount" type="checkbox">
                    {(props) => (
                      <div className="checkboxInput">
                        <label className="labelInput">
                          <input
                            className="checkboxInput"
                            onClick={() => updateFilter({ name: 'funded_amount' })}
                            {...props.input}
                            checked={filter.funded_amount}
                          />
                          <span className="checkmark" />
                          <span className="labelCheckboxInput">
                            Mostrar solicitudes a las que ya le presté
                          </span>
                        </label>
                      </div>
                    )}
                  </Field>
                  <Field name="hidden" type="checkbox">
                    {(props) => (
                      <div className="checkboxInput">
                        <label className="labelInput">
                          <input
                            className="checkboxInput"
                            onClick={() => updateFilter({ name: 'hidden' })}
                            {...props.input}
                            checked={filter.hidden}
                          />
                          <span className="checkmark" />
                          <span className="labelCheckboxInput">
                            Mostrar solicitudes que escondiste
                          </span>
                        </label>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
              <div className="buttonsFilter">
                <Button color="info" handleClick={clearFilter}>
                  Borrar
                </Button>
                <Button type="submit">Aplicar</Button>
              </div>
            </div>
            <ReactTooltip
              id="destinyTooltip"
              type="dark"
              effect="solid"
              getContent={(dataTip) => `${dataTip}`}
            />
          </form>
        )}
      />
    </RequisitionsFilterContainer>
  );
};

export default RequisitionsFilterContent;
