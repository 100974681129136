import React from 'react';
import { tscope } from 'i18n';
import { Form } from 'react-final-form';
import {
  Text,
  Flex,
  Panel,
  Button,
  TextField,
  ModalCloseButton,
} from 'components';
import { Modal, styles as s } from './ActivateInvoicingModal.styles';
import { useController } from './hooks';

const t = tscope('settings.account.activateInvoicingModal');

export const ActivateInvoicingModal = ({
  isOpen,
  onClose,
}) => {
  const {
    validate,
    onSubmit,
    isLoading,
    titlePreview,
    initialValues,
    contentPreview,
  } = useController({
    onClose,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalCloseButton onClick={onClose} />
      <Panel
        title={titlePreview}
        textHeaderProps={s.textHeaderProps}
        footerComponent={(
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({
              handleSubmit,
              submitting,
              invalid,
              submitFailed,
            }) => (
              <Flex
                as="form"
                onSubmit={handleSubmit}
                {...s.form}
              >
                <TextField
                  {...s.field}
                  name="password"
                  type="password"
                  textLabel={t('fields.password.label')}
                  placeholder={t('fields.password.placeholder')}
                  textHelperProps={s.errorTextHelper}
                />
                <Button
                  type="submit"
                  disabled={submitting || (invalid && !submitFailed) || isLoading}
                >
                  {t('actions.submit')}
                </Button>
              </Flex>
            )}
          </Form>
      )}
      >
        <Text {...s.textContent}>{contentPreview}</Text>
      </Panel>
    </Modal>
  );
};
