import camelify from 'camelcase-keys-recursive';

const cleanCollection = (collection = []) => collection
  .filter((item) => !!item.estado || !item.updatedAt);

const parsePortfolio = (data) => {
  if (!data) return null;

  const {
    operation, collection = [], paymentDetails = {}, totalRows, updatedAt,
  } = camelify(data);
  const parsedCollection = cleanCollection(collection);

  if (
    operation === 'empty_collection'
    || (parsedCollection.length === 0 && collection[0]?.collectionProduct.length === 0)
  ) return null;

  if (operation === 'caching') return data;

  if (operation === null || parsedCollection.length === 0) {
    return {
      collection: [],
      paymentDetails: {
        details: {},
        paid: 0,
        totalPayable: 0,
      },
    };
  }

  const { paid, totalPayable } = paymentDetails;

  const parsedDetails = Object.entries(data.payment_details.details).reduce(
    (acc, [key, detail]) => ({ ...acc, [key]: { ...camelify(detail), originalTitle: key } }),
    {},
  );

  return {
    collection: parsedCollection,
    paymentDetails: {
      details: parsedDetails,
      paid,
      totalPayable,
    },
    totalRows,
    updatedAt,
  };
};

export default parsePortfolio;
