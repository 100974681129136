import React from 'react';

import ButtonContainer from './styles';

const Button = (props) => {
  const {
    children, handleClick, type, color, disabled, className,
  } = props;
  return (
    <ButtonContainer
      onClick={handleClick}
      type={type || 'button'}
      color={color || 'primary'}
      className={className || ''}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;
