import React, { useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Route from 'components/Route';
import withSizes from 'react-sizes';
import { connect, useSelector, useDispatch } from 'react-redux';
import { getRequisitionList, getRequisitionListSilent } from 'actions/requisitions';
import { breakPoints } from 'config/constants';
import PATHS from 'routes';
import { applyFilterToRequisitions } from 'actions/requisitions/selectors';
import RequisitionsContainer from './styles';
import RequisitionsFilterElement from './RequisitionsFilter';
import RequisitionsList from './RequisitionsList';
import DisplayDetails from '../DisplayDetails';
import { ALERTS } from '../Alerts/Alerts';

const { investorRequisitionsPath, investorRequisitionPath } = PATHS;
const FILTER_INITIAL_STATE = {
  funded_amount: true,
  hidden: false,
};

export const Requisitions = (props) => {
  const contentRef = useRef(null);
  const { loadingRequisitonList, openCart, width } = props;
  const [state, setState] = useState({
    filter: FILTER_INITIAL_STATE,
    sortOptions: {},
    openFilter: false,
    selectedRequisitions: [],
    requisitionsToBeLent: [],
    hiddenState: {},
    showHiddenRequisitions: FILTER_INITIAL_STATE.hidden,
    showFundedRequisitions: FILTER_INITIAL_STATE.funded_amount,
    toggleDetailRequisition: false,
    requisitionId: 0,
    sortedRequisitions: false,
  });
  const match = useRouteMatch(investorRequisitionsPath());
  const dispatch = useDispatch();

  const cleanSelectedRequisitions = () => {
    setState((_state) => ({ ..._state, selectedRequisitions: [] }));
  };

  const toggleRequisitionSelection = (id) => {
    const { selectedRequisitions } = state;

    if (selectedRequisitions.find((element) => element === id)) {
      setState((prevState) => ({
        ...prevState,
        selectedRequisitions: prevState.selectedRequisitions.filter((element) => element !== id),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        selectedRequisitions: [...prevState.selectedRequisitions, id],
      }));
    }
  };

  const setHiddenState = (id, hide) => setState((prevState) => ({
    ...prevState,
    hiddenState: {
      ...prevState.hiddenState,
      [id]: hide,
    },
  }));

  const updateFilter = async (filter) => {
    const showFundedRequisitions = !!filter.funded_amount;
    const showHiddenRequisitions = !!filter.hidden;

    setState((pState) => ({
      ...pState,
      filter,
      showFundedRequisitions,
      showHiddenRequisitions,
      openFilter: false,
    }));
  };

  const toggleFilter = () => {
    setState((pState) => ({
      ...pState,
      openFilter: !pState.openFilter,
    }));
  };

  const closeFilter = () => {
    setState((_state) => ({
      ..._state,
      openFilter: false,
    }));
  };

  const handleSortChange = (type) => {
    setState((prevState) => {
      const isSameType = type === prevState.sortOptions.type;
      const isSortedDesc = prevState.sortOptions.sortOrder === 'desc';

      if (!isSameType) {
        return {
          ...prevState,
          sortOptions: { type, sortOrder: 'desc' },
        };
      }

      if (isSortedDesc) {
        return {
          ...prevState,
          sortOptions: { type, sortOrder: 'asc' },
        };
      }

      return { ...prevState, sortOptions: {} };
    });
  };

  const requisitions = useSelector(
    ({ requisitions: { fundingRequisitionList } }) => applyFilterToRequisitions(
      {
        ...state.filter,
        ...state.sortOptions,
        showFundedRequisitions: state.showFundedRequisitions,
        showHiddenRequisitions: state.showHiddenRequisitions,
        hiddenState: state.hiddenState,
      },
      fundingRequisitionList.requisitions,
    ),
  );

  useEffect(() => {
    if (match.isExact) {
      if (requisitions.length > 0) {
        dispatch(getRequisitionListSilent());
      } else {
        dispatch(getRequisitionList());
      }
    }
  }, [match.isExact, getRequisitionListSilent]);

  const large = width <= breakPoints.largeBreakPoint;

  const hasRequisitionsBeenFiltered = [
    state.filter?.funded_amount === true,
    state.filter?.hidden === false,
    Object.keys(state.sortOptions).length === 0,
    state.showFundedRequisitions === true,
    state.showHiddenRequisitions === false,
    Object.keys(state.hiddenState).length === 0,
  ].some((hasFilter) => hasFilter === false);

  const onScrollContent = () => {
    if (!contentRef.current) return;

    contentRef.current.focus();
  };

  return (
    <RequisitionsContainer ref={contentRef} onScroll={onScrollContent}>
      <RequisitionsFilterElement
        large={large}
        openFilter={state.openFilter}
        filterState={state.filter}
        onUpdate={updateFilter}
        onToggleFilter={toggleFilter}
        onCloseFilter={closeFilter}
      />
      {ALERTS.map(({ id, Component }) => <Component key={id} my="2rem" mr="2rem" ml={{ base: '2rem', lg: '28rem', xl: '30rem' }} />)}
      <RequisitionsList
        requisitions={requisitions}
        showEmptyState={!hasRequisitionsBeenFiltered && requisitions.length === 0}
        onToggleFilter={toggleFilter}
        toggleRequisitionSelection={toggleRequisitionSelection}
        selectedRequisitions={state.selectedRequisitions}
        setHiddenState={setHiddenState}
        hiddenState={state.hiddenState}
        showHiddenRequisitions={state.showHiddenRequisitions}
        showFundedRequisitions={state.showFundedRequisitions}
        updateFilter={updateFilter}
        appliedFilter={state.filter}
        cleanSelectedRequisitions={cleanSelectedRequisitions}
        handleSortChange={handleSortChange}
        sortOptions={state.sortOptions}
        loadingRequisitions={loadingRequisitonList}
        openCart={openCart}
      />
      <Route path={investorRequisitionPath()}>
        {({ match: detailsRouteMatch }) => (
          <DisplayDetails
            isShowing={detailsRouteMatch}
            requisitionsArray={requisitions}
            onUpdateList={() => updateFilter(state.filter)}
            backTo={investorRequisitionsPath()}
          />
        )}
      </Route>
    </RequisitionsContainer>
  );
};

const mapSizeToProps = ({ width }) => ({ width });

const mapStateToProps = ({ requisitions }) => ({
  loadingRequisitonList: requisitions.loadingRequisitionList,
});

export default connect(mapStateToProps)(withSizes(mapSizeToProps)(Requisitions));
