import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { useReduxQuery } from 'utils/redux-query';
import PATHS from 'routes';
import { t } from 'i18n';

function CompleteExpedientsBanner(props) {
  const [session, { isLoading }] = useReduxQuery('SESSION');
  const [, { isLoading: isPersonalArchiveLoading }] = useReduxQuery('PERSONAL_ARCHIVES');
  const name = session?.user?.fullName?.split(' ')[0];

  if (isLoading || isPersonalArchiveLoading) return null;

  return (
    <Alert
      title={name ? `${t('common.Banners.welcomeWithName', { name })}` : `${t('common.Banners.welcome')}`}
      message={t('common.Banners.updateInformation')}
      status="warn"
      actionButtonProps={{ children: t('common.update'), as: Link, to: PATHS.cnbvPath() }}
      iconProps={{ w: '3rem', h: '3rem' }}
      w="100%"
      {...props}
    />
  );
}

export default CompleteExpedientsBanner;
