import React from 'react';
import Link from 'components/Link';
import { t } from 'i18n';
import { Text } from 'components/Typography';
import { investorSettings } from 'routes/index';

export const AccountStatementDocText = () => (
  <Text
    color="schema.green.500"
    i18nKey="common.alerts.info.accountStatementFile"
    components={{
      link: (
        <Link
          fontSize="inherit"
          lineHeight="inherit"
          color="schema.blue.300"
          to={`${investorSettings}/account-statement?month=last`}
        >
          {t('common.see')}
        </Link>
      ),
    }}
  />
);
