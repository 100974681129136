import React from 'react';

import { CardContainer } from './styles';

const Card = (props) => {
  const { children, ...otherProps } = props;
  return (
    <CardContainer className="card" {...otherProps}>
      {children}
    </CardContainer>
  );
};

export default Card;
