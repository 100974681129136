import React, { useState, useEffect } from 'react';
import AlertModal from 'components/AlertModal';
import { useReduxQuery } from 'utils/redux-query';
import { Text } from 'components/Typography';
import { t } from 'i18n';

const ts = (id, ...options) => t(id, { scope: 'Dashboard.alerts.investorNull', ...options });

function InvestorNullAlert() {
  const [isOpen, setOpen] = useState(false);
  const [session] = useReduxQuery('SESSION');

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(session?.user.status === null);
  }, [session?.user.status]);

  return (
    <AlertModal
      title={ts('title')}
      body={(
        <Text textAlign="center">
          {ts('description')}
          {' '}
          <a href={`mailto:${t('common.inversionistaEmail')}?subject=${ts('emailSubject')}`}>
            {t('common.inversionistaEmail')}
          </a>
        </Text>
      )}
      acceptText="Ok"
      status="warn"
      isOpen={isOpen}
      onClose={handleCloseModal}
      buttonProps={{
        $type: 'success',
        onClick: handleCloseModal,
      }}
    />
  );
}

export default InvestorNullAlert;
