import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

export const uploadAddress = async ({ file, onUploadProgress }) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await request({
    extraHeaders: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    path: '/v1/investor/documents/proof_of_addresses',
    body: formData,
    onUploadProgress,
  });
  return camelify(response);
};
