import React, { useState } from 'react';
import { t } from 'i18n';
import MediaDisplay from 'components/MediaDisplay/MediaDisplay';
import { Tag, Flex, Text } from 'components';
import { maskPhoneNumber } from 'utils/maskHandler';
import { useReduxQuery } from 'utils/redux-query';
import { factorTypes } from 'config/constants';
import { MI_TOKEN_YTP, EXTERNAL_APP, SMS } from '../config/Constants';
import SecondFactorModal from './SecondFactorModal/SecondFactorModal';

const ts = (id, options) => t(id, {
  scope: 'Dashboard.activate2FAWizard.steps.initialState.body.authenticationTypes',
  ...options,
});

const MiTokenYtpTitle = (props) => {
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');

  const otpType = secondFactor?.otpType;
  const isCurrentFactor = otpType === factorTypes.YOTEPRESTO_APP;

  return (
    <Flex
      columnGap="1rem"
      alignItems={{ base: 'flex-start', sm: 'center' }}
      direction={{ base: 'column', sm: 'row' }}
    >
      <Text {...props}>
        {`${ts('tokenYtp.title')} ${isCurrentFactor ? ts('isCurrentFactor') : ''}`}
      </Text>
      <Tag size="small" schemaColor="schema.green" variant="solid" iconName="STAR">
        {ts('tokenYtp.tag')}
      </Tag>
    </Flex>
  );
};

const SecondFactorSelection = () => {
  const [currentModal, setCurrentModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [profile] = useReduxQuery('PROFILE');
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');
  const phoneNumber = profile?.cellPhoneNumber ?? '';
  const phone = maskPhoneNumber(phoneNumber);

  const handleOpenModal = (modalType) => {
    setCurrentModal(modalType);
    setIsOpen(true);
  };

  const closeModalHandler = () => {
    setIsOpen(false);
  };

  const otpType = secondFactor?.otpType;

  const isMiTokenCurrentFactor = otpType === factorTypes.YOTEPRESTO_APP;
  const isSMSCurrentFactor = otpType === factorTypes.SMS;
  const isAppCurrentFactor = otpType === factorTypes.AUTHENTICATOR_APP;

  const statusProps = {
    active: {
      variant: 'outlined',
      colorSchema: 'schema.blue',
    },
    normal: {
      variant: 'subtle',
      colorSchema: 'schema.gray',
    },
  };

  return (
    <Flex direction="column">
      <MediaDisplay
        cursor="pointer"
        minSize="4rem"
        minHeight="10rem"
        onClick={() => handleOpenModal(MI_TOKEN_YTP)}
        TitleComponent={MiTokenYtpTitle}
        imageName="YTP_SQUARE_LOGO"
        textContent={ts('tokenYtp.description')}
        {...(isMiTokenCurrentFactor ? statusProps.active : statusProps.normal)}
      />
      <MediaDisplay
        cursor="pointer"
        minSize="4rem"
        minHeight="10rem"
        onClick={() => handleOpenModal(EXTERNAL_APP)}
        title={`${ts('externalApp.title')} ${isAppCurrentFactor ? ts('isCurrentFactor') : ''}`}
        imageName="EXTERNAL_SECOND_FACTOR"
        textContent={ts('externalApp.description')}
        {...(isAppCurrentFactor ? statusProps.active : statusProps.normal)}
      />
      <MediaDisplay
        cursor={isSMSCurrentFactor ? null : 'pointer'}
        minSize="4rem"
        minHeight="10rem"
        onClick={isSMSCurrentFactor ? null : () => handleOpenModal(SMS)}
        title={`${ts('SMS.title')} ${isSMSCurrentFactor ? ts('isCurrentFactor') : ''}`}
        imageName="SMS_FLAG"
        textContent={ts('SMS.description', { phone })}
        {...(isSMSCurrentFactor ? statusProps.active : statusProps.normal)}
      />
      <SecondFactorModal
        isOpen={isOpen}
        onClose={closeModalHandler}
        currentModal={currentModal}
      />
    </Flex>
  );
};

export default SecondFactorSelection;
