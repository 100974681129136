import React from 'react';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';

import InfoWidgetContainer from './styles';

const InfoWidget = (props) => {
  const { paymentDetails, type } = props;
  const data = paymentDetails === undefined ? false : paymentDetails[type];
  const categories = {
    Pago: [
      { title: 'Total', key: 'total' },
      { title: 'Capital', key: 'capital' },
      { title: 'Intereses', key: 'intereses' },
      { title: 'IVA de interes', key: 'iva_intereses' },
      { title: 'Intereses Moratorios', key: 'moratorios' },
      { title: 'IVA de interes moratorio', key: 'iva_moratorios' },
    ],
    Comisión: [
      { title: 'Total', key: 'total' },
      { title: 'Comisión', key: 'intereses' },
      { title: 'IVA Comisión', key: 'iva_intereses' },
    ],
    Retiro: [{ title: 'Total', key: 'total' }],
    Préstamo: [{ title: 'Total', key: 'total' }],
    Abono: [{ title: 'Total', key: 'total' }],
  };

  const renderDetailsList = () => categories[type].map((category) => (
    <div className="info-widget__row" key={type + category.title}>
      <div className="info-widget__category">{category.title}</div>
      <div className="info-widget__data">
        {toCurrencyWithDecimals(Math.abs(data[category.key]))}
      </div>
    </div>
  ));

  return data ? <InfoWidgetContainer>{renderDetailsList()}</InfoWidgetContainer> : null;
};

export default InfoWidget;
