import React from 'react';
import { tscope } from 'i18n';
import {
  Box,
  Text,
  Flex,
} from 'components';

const t = tscope('settings.account.invoicingPanel');

export const InvoicingInstructions = ({ status }) => (
  <Flex gap="1rem">
    <Box>
      <Text t={t} as="h4" i18nKey="updateFiscalContent.title" color="schema.gray.500" fontWeight="500" lineHeight="3.2rem" />
      <Text t={t} i18nKey={`updateFiscalContent.desc.${status}`} color="schema.gray.500" fontSize="1.4rem" lineHeight="2rem" />
    </Box>
  </Flex>
);
