import { useEffect } from 'react';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';

export const useResetAIConfig = () => {
  const [session] = useReduxQuery('SESSION');
  const [AIconfigs, { reinitialize: resetAIConfigs }] = useReduxSubscription('FETCH_ALL_AUTO_INV');
  useEffect(() => {
    if (!AIconfigs || session?.settings?.availableForAutoinvest !== false) return;
    resetAIConfigs();
  }, [session]);
};
