import {
  useCallback,
  useEffect, useState,
} from 'react';
import { USER_STATUSES } from 'config/constants';
import { useHistory } from 'react-router-dom';
import { dashboardRoute } from 'routes/index';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { getRegisterStep } from '../utils/getRegisterStep';
import { STEPS } from '../constants';

export const useController = () => {
  const history = useHistory();
  const [session, { status: sessionStatus }] = useReduxQuery('SESSION');
  const [profile, { status: profileStatus }, { refetch: refetchProfile }] = useReduxQuery('PROFILE');
  const [updateProfile] = useReduxMutator('UPDATE_PROFILE');
  const [currentStep, setCurrentStep] = useState(STEPS.FILL_JOB);
  const shouldRedirectToDashboard = session?.user?.status !== USER_STATUSES.PROFILE_STARTED;
  const displayLoader = profileStatus.pending || sessionStatus.pending;

  const updateCurrentStep = useCallback(() => {
    if (!profile) return;
    const step = getRegisterStep({
      profile,
    });
    setCurrentStep(step);
  }, [profile]);

  const goNextStep = async (data, profileCompleted) => {
    try {
      await updateProfile(data);
    } catch (e) {
      await refetchProfile();
    } finally {
      if (profileCompleted) history.push(dashboardRoute);
    }
  };

  useEffect(() => {
    updateCurrentStep();
  }, [updateCurrentStep]);

  useEffect(() => {
    if (shouldRedirectToDashboard) {
      history.push(dashboardRoute);
    }
  }, [shouldRedirectToDashboard]);

  return {
    profile,
    goNextStep,
    currentStep,
    displayLoader,
  };
};
