import React from 'react';
import TableRow from './styles';

const Row = (props) => {
  const { item } = props;

  return (
    <TableRow>
      <td>{item.name}</td>
      <td>{item.created_at}</td>
      <td>{item.status}</td>
      <td className="reward">{item.reward}</td>
    </TableRow>
  );
};

export default Row;
