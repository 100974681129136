import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import debounce from 'utils/Monads/debounce';
import TextField from 'components/TextField/TextField';
import { t } from 'i18n';
import { Grid } from 'styles';
import { capitalizeText } from 'utils/strings';

const nameFields = ['firstName', 'middleName', 'firstLastName', 'secondLastName'];

const FullNameField = forwardRef((props, forwardedRef) => {
  const {
    name,
    onBlur,
    format,
    'data-testid': dataTestId = name,
    ...otherProps
  } = props;
  const [applyAnimation, setAnimation] = useState(false);
  const inputNames = nameFields.map((baseName) => `user.${baseName}`);
  const nameInput = useRef(null);
  const middleNameInput = useRef(null);
  const firstLastNameInput = useRef(null);
  const secondLastNameInput = useRef(null);

  const triggerBlur = useCallback(() => {
    const cannotPerformAction = [
      nameInput.current,
      middleNameInput.current,
      firstLastNameInput.current,
      secondLastNameInput.current,
    ].includes(document.activeElement);

    if (cannotPerformAction) return;

    onBlur();
  }, [onBlur]);

  const triggerBlurDebounced = useCallback(debounce(triggerBlur, 50), [triggerBlur]);

  useEffect(() => {
    setAnimation(true);
  }, []);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      nameInput.current.focus();
    },
  }));

  return (
    <Grid animateWith="slideDown" show={applyAnimation} {...otherProps}>
      <TextField
        color="schema.gray.700"
        textLabel={t('Forms.customFields.fullNameField.firstName')}
        name={inputNames[0]}
        ref={nameInput}
        onBlur={triggerBlurDebounced}
        variant="outline"
        data-testid={`${dataTestId}-firstName`}
        parse={capitalizeText}
      />
      <TextField
        color="schema.gray.700"
        textLabel={t('Forms.customFields.fullNameField.middleName')}
        name={inputNames[1]}
        ref={middleNameInput}
        onBlur={triggerBlurDebounced}
        variant="outline"
        data-testid={`${dataTestId}-middleName`}
        parse={capitalizeText}
      />
      <TextField
        color="schema.gray.700"
        textLabel={t('Forms.customFields.fullNameField.firstLastName')}
        name={inputNames[2]}
        ref={firstLastNameInput}
        onBlur={triggerBlurDebounced}
        variant="outline"
        data-testid={`${dataTestId}-firstLastName`}
        parse={capitalizeText}
      />
      <TextField
        color="schema.gray.700"
        textLabel={t('Forms.customFields.fullNameField.secondLastName')}
        name={inputNames[3]}
        ref={secondLastNameInput}
        onBlur={triggerBlurDebounced}
        variant="outline"
        data-testid={`${dataTestId}-secondLastName`}
        parse={capitalizeText}
      />
    </Grid>
  );
});

export default FullNameField;
