import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { t } from 'i18n';
import { concatFullName2 } from 'utils/stringFormatters';
import { genres } from 'config/constants';
import { Grid, Flex } from 'styles';
import TextField from 'components/TextField/TextField';
import DotsLoader from 'components/DotsLoader';
import Alert from 'components/Alert';
import Divider from 'components/Divider';
import { Heading, Text } from 'components/Typography';
import * as Table from 'components/Table/Basic';
import {
  Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,
} from 'ui';
import validator from './validator';
import { controllerContext } from '../controller/context';

const tOptions = { scope: 'expedientsProfileConfirm' };

const ProfileRecord = (props) => {
  const { headerTitle, value = '...', ...rest } = props;

  return (
    <Table.Row templateColumns="8fr 4fr" placeItems="left" columnGap="1rem" {...rest}>
      <Table.Cell py="1rem">
        <Text $size="small" color="gray.g5">
          {headerTitle}
        </Text>
        <Text size="1.5rem">{value}</Text>
      </Table.Cell>
    </Table.Row>
  );
};

const ConfirmForm = (props) => {
  const { handleSubmit } = props;
  const { confirmProfileStatus } = useContext(controllerContext);

  return (
    <Grid
      as="form"
      onSubmit={handleSubmit}
      templateColumns={{ base: '1fr', md: '1fr 1fr' }}
      columnGap="2rem"
      width="100%"
      padding={{ base: '0 2rem 2rem', md: '0 4.8rem 4.8rem' }}
    >
      <TextField
        name="password"
        type="password"
        placeholder=""
        color="schema.gray.700"
        isTextHelperFloating
        textLabel={t('form.passwordField.textLabel', tOptions)}
      />
      <Button
        type="submit"
        colorScheme="green"
        alignSelf="end"
        disabled={confirmProfileStatus.pending}
      >
        {confirmProfileStatus.pending ? <DotsLoader /> : t('form.actions.submit', tOptions)}
      </Button>
    </Grid>
  );
};

function Confirm(props) {
  const { isOpen, onClose } = props;
  const { profile, onUpdateProfile } = useContext(controllerContext);

  const handleOnSubmit = async (values) => {
    const { password } = values;
    try {
      await onUpdateProfile({ password });

      return null;
    } catch (error) {
      const { response } = error;

      const errorPassword = response.data.error;

      if (typeof errorPassword === 'string') {
        return {
          password: errorPassword,
        };
      }

      onClose();
      return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" rowGap="2rem" width="100%">
            <Grid mx="2rem" top="0" bgColor="white">
              <Heading align="center" size="2.4rem">
                {t('title', tOptions)}
              </Heading>
              <Heading align="center" as="h3" size="1.4rem" weight="normal">
                {t('subtitle', tOptions)}
              </Heading>
            </Grid>

            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              width="100%"
              padding={{ base: '0 2rem', md: '0 4.8rem' }}
            >
              <ProfileRecord
                headerTitle={t('profileTable.headers.0', tOptions)}
                value={concatFullName2(profile)}
                gridColumn="1/-1"
              />
              <ProfileRecord
                headerTitle={t('profileTable.headers.1', tOptions)}
                value={genres.find((gender) => gender.value === profile.gender)?.label}
                gridColumn="1/-1"
              />
              {profile.isNotMexican && (
                <ProfileRecord
                  headerTitle={t('profileTable.headers.2', tOptions)}
                  value={`${profile.birthDate}, ${profile?.country}`}
                  gridColumn="1/-1"
                />
              )}
              {!profile.isNotMexican && (
                <ProfileRecord
                  headerTitle={t('profileTable.headers.2', tOptions)}
                  value={`${profile.birthDate}, ${profile?.state?.name}`}
                  gridColumn="1/-1"
                />
              )}
              <ProfileRecord
                headerTitle={t('profileTable.headers.3', tOptions)}
                value={profile.rfc}
                borderBottomWidth={{ base: '1px', md: '0' }}
                gridColumn={{ base: '1/-1', md: '1/1' }}
              />
              <ProfileRecord
                headerTitle={t('profileTable.headers.4', tOptions)}
                value={profile.curp}
                borderLeftWidth={{ base: '0', md: '1px' }}
                pl={{ base: '0', md: '2rem' }}
                gridColumn={{ base: '1/-1', md: '2/2' }}
              />
            </Grid>

            <Alert
              variant="subtle"
              colorSchema="gray"
              title={t('alert.title', tOptions)}
              message={(
                <Grid as="ul" ml="1.4rem" rowGap="0.8rem">
                  <li>
                    <Text $size="body">{t('alert.list.0', tOptions)}</Text>
                  </li>
                  <li>
                    <Text $size="body">{t('alert.list.1', tOptions)}</Text>
                  </li>
                  <li>
                    <Text $size="body">{t('alert.list.2', tOptions)}</Text>
                  </li>
                  <li>
                    <Text $size="body">{t('alert.list.3', tOptions)}</Text>
                  </li>
                </Grid>
              )}
              mx={{ base: '2rem', md: '4.8rem' }}
            />
            <Divider />
            <Form onSubmit={handleOnSubmit} validate={validator}>
              {({ handleSubmit, form }) => (
                <ConfirmForm
                  handleSubmit={handleSubmit}
                  form={form}
                />
              )}
            </Form>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

Confirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Confirm;
