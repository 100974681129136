import React from 'react';
import { t } from 'i18n';
import PropTypes from 'prop-types';
import {
  CollapseContainer, Text, Icon, types, typesKeys,
} from './Collapse.style';
import Badge from '../Badge/Badge';

function Collapse(props) {
  const {
    id,
    setStatusCollapse,
    statusCollapse,
    value,
    isFundedRequisition,
    type,
    hasSuffix = true,
    ...contentProps
  } = props;
  return (
    <CollapseContainer
      $type={type}
      {...contentProps}
      onClick={() => setStatusCollapse(!statusCollapse)}
    >
      {!isFundedRequisition && <Badge value={value} hasSuffix={hasSuffix} {...props} />}
      <Text>
        {isFundedRequisition ? t('detailRequisition.othersLoans') : t('detailRequisition.loans')}
      </Text>
      <Icon open={statusCollapse} />
    </CollapseContainer>
  );
}

Collapse.defaultProps = {
  type: typesKeys.success,
  isFundedRequisition: false,
};

Collapse.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  setStatusCollapse: PropTypes.func.isRequired,
  statusCollapse: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  isFundedRequisition: PropTypes.bool,
};

export default Collapse;
