import React from 'react';
import { ReactComponent as AvatarLoadSVG } from 'assets/requisitions/avatarLoading.svg';
import AvatarWomenSVG from 'assets/requisitions/avatarWoman.svg';
import AvatarManSVG from 'assets/Avatar.svg';
import { Image, Stack, Text } from 'ui';

const AvatarImageElement = ({
  gender, username, loading,
}) => (
  <Stack alignItems="center">
    {loading ? (
      <AvatarLoadSVG title="" width="60" height="60" />
    ) : (
      <Image
        src={gender === 'F' ? AvatarWomenSVG : AvatarManSVG}
        alt="Avatar"
        boxSize="16"
      />
    )}
    <Text
      color="gray.500"
      fontSize="sm"
      whiteSpace="nowrap"
    >
      {loading ? '' : username}
    </Text>
  </Stack>
);

export default AvatarImageElement;
