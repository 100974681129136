import * as api from 'apis/identityVerification';
import { createAsyncAction } from 'utils/redux-query';
import * as types from './types';

export const getStatus = createAsyncAction('KYC_STATUS', () => async () => api.fetchVerificationStatus());

export const updatePersonalIdStatus = createAsyncAction('UPDATE_KYC_STATUS', () => async () => {
  await api.updatePersonalIdStatus();
});

export const setStatus = (status) => ({ type: types.SET_VERIFICATION_STATUS, payload: status });
