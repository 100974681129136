import React from 'react';
import { PasswordAuthorizeField } from 'components/PasswordAuthorizeField';
import {
  Grid,
  Flex,
  Button,
} from 'ui';
import { tscope } from 'i18n';
import { useController } from './hooks';

const ts = tscope('components.RemoveAIContractForm');

export const RemoveAIContractForm = ({
  onCancel = () => {},
  onSuccess,
  ...props
}) => {
  const {
    control,
    onSubmit,
    isLoading,
  } = useController({ onSuccess });

  return (
    <Grid as="form" onSubmit={onSubmit} gap="5" {...props}>
      <PasswordAuthorizeField
        t={tscope('components.RemoveAIContractForm.passwordField')}
        isDisabled={isLoading}
        name="password"
        control={control}
        data-testid="password"
      />
      <Flex gap="5" justifyContent="space-between">
        <Button
          isDisabled={isLoading}
          onClick={onCancel}
          colorScheme="gray"
        >
          {ts('actions.cancel')}
        </Button>
        <Button
          isDisabled={isLoading}
          type="submit"
          colorScheme="red"
        >
          {ts('actions.submit')}
        </Button>
      </Flex>
    </Grid>
  );
};
