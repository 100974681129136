import React from 'react';

import Button from 'components/Button';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { useDispatch } from 'react-redux';
import { setIsOpenCart } from 'actions/dashboard';
import FirstAddToCartContainer from './styles';

const FirstAddToCart = ({ handleModalClose }) => {
  const dispatch = useDispatch();

  const handleOpen = () => {
    handleModalClose();
    dispatch(setIsOpenCart(true));
  };

  return (
    <FirstAddToCartContainer>
      <div className="cart__icon__container">
        <CartIcon title="" />
        {' '}
        <span className="cart__icon__quanity">1</span>
      </div>
      <p className="ready">¡Listo!</p>
      <div className="description">
        <p>Agregaste tu primer préstamo, ahora sólo tienes que ir al carrito para autorizarlo.</p>
      </div>
      <div className="buttons__container">
        <Button color="info" className="button__primary" onClick={handleModalClose}>
          Seguir prestando
        </Button>
        <Button color="success" onClick={handleOpen}>
          Ver en carrito
        </Button>
      </div>
    </FirstAddToCartContainer>
  );
};

export default FirstAddToCart;
