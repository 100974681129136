import Bowser from 'bowser';

const UNSUPPORTED_BROWSERS = ['Internet Explorer'];

const SUPPORTED_VERSIONS = {
  Chrome: '>=57',
  Firefox: '>=52',
  Safari: '>=10.1',
  'Microsoft Edge': '>=16',
};

export const isBrowserSupported = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const current = browser.getBrowser();
  const isUnsupportedBrowser = UNSUPPORTED_BROWSERS.includes(current.name);
  const isIncludedInList = Object.keys(SUPPORTED_VERSIONS).includes(current.name);

  if (isUnsupportedBrowser) return false;
  if (!isIncludedInList) return true;

  return browser.satisfies(SUPPORTED_VERSIONS);
};
