import React from 'react';
import { t, toCurrency } from 'i18n';
import {
  Tag,
  Box,
  Text,
  Flex,
  Grid,
  Divider,
} from 'ui';
import { useController } from './hooks';

export const DisplayAutoinvestConfig = (props) => {
  const {
    amount,
    TermList,
    isLoading,
    gradeList,
    purposeList,
  } = useController();

  return (
    <Grid gap="5" {...props}>
      <Box opacity={isLoading ? '0.4' : '1'}>
        <Text fontWeight="500" marginBottom="1">Calificación/Tasa</Text>
        <Flex gap="2" flexWrap="wrap">
          {gradeList.map(({ id, text }) => (
            <Tag size="lg" colorScheme="blue" key={id}>{text}</Tag>
          ))}
        </Flex>
      </Box>
      <Divider />
      <Box opacity={isLoading ? '0.4' : '1'}>
        <Text fontWeight="500" marginBottom="1">Plazo</Text>
        <Flex gap="2" flexWrap="wrap">
          {TermList.map(({ id, text }) => (
            <Tag size="lg" colorScheme="blue" key={id}>
              {t('common.month', { count: text })}
            </Tag>
          ))}
        </Flex>
      </Box>
      <Divider />
      <Box opacity={isLoading ? '0.4' : '1'}>
        <Text fontWeight="500" marginBottom="1">Destino</Text>
        <Flex gap="2" flexWrap="wrap">
          {purposeList.map(({ id, text }) => (
            <Tag size="lg" colorScheme="blue" key={id}>{text}</Tag>
          ))}
        </Flex>
      </Box>
      <Divider />
      <Box opacity={isLoading ? '0.4' : '1'}>
        <Text fontWeight="500" marginBottom="1">Monto para prestar por solicitud</Text>
        <Flex>
          <Tag size="lg" colorScheme="blue">{toCurrency(amount, { precision: 0 })}</Tag>
        </Flex>
      </Box>
    </Grid>
  );
};
