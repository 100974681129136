import { userStatus } from 'config/constants';

const findAnyMissingExpedientFlag = (archive) => {
  if (!archive) return null;
  const archives = {
    ...archive,
    address: archive.address,
    nextRequirement: undefined,
  };
  return Object
    .values(archives)
    .some((arch) => arch === false);
};

export const useExpedientFlag = ({
  session,
  personalArchives: archive,
}) => {
  const { user } = session || {};

  const isKYCRequesting = [
    archive?.ocupation,
    archive?.kycActivities,
  ].some((value) => value === false);

  const isRisked = [
    isKYCRequesting,
    user?.status === userStatus.PLD_VALIDATING,
  ].every(Boolean);

  const allowedStatus = [
    userStatus.AUTHORIZED,
    userStatus.PLD_VALIDATING,
    userStatus.CONTRACT_UNSIGNED,
    userStatus.PARTIALLY_BLOCKED,
    userStatus.PERSONAL_ARCHIVE_INCOMPLETE,
  ];

  const isStatusAllowed = (allowedStatus.includes(user?.status) || isRisked);

  const needsUpdateInformation = isStatusAllowed
    && findAnyMissingExpedientFlag(archive);

  return {
    needsUpdateInformation,
  };
};
