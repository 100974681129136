import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import Box from 'components/Box';
import Button from 'components/Button/Button';
import { Text } from 'components/Typography';
import ProgressBarComponent from 'components/ProgressBar/Simple';

export const TransactionsLimitsCardContent = styled(Box)`
  flex-direction: column;
  padding: 0;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    max-width: 30rem;
  }
`;

export const TransactionsLimitsCardBody = styled.div`
  border-bottom: 1px solid ${colors.gray.g12};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3rem 2rem;
  width: 100%;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    padding: 2rem 2rem 2rem;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 3rem 2rem;
    margin: 0 auto;
  }
`;

export const TransactionsLimitsCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 3rem 3rem;
  width: 100%;
`;

export const ProgressBar = styled(ProgressBarComponent)`
  margin-top: 1rem;
  width: 100%;
`;

export const LevelLimitUpButton = styled(Button)`
  margin: 1rem auto 0;
  text-decoration: none;
`;

export const LimitsPageLink = styled(Button)`
  margin: 1.8rem auto;
`;

export const Title = styled(Text)`
  text-align: center;
`;

export const MontlyAmountText = styled.p`
  color: ${colors.green};
  font-size: 3.6rem;
  line-height: 5rem;
  font-weight: bold;
`;

export const DescriptionText = styled.p`
  text-align: center;
  line-height: 2rem;
  color: ${colors.gray.g5};
`;

export const AmountsRow = styled.p`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AmountText = styled.p`
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: ${({ $align }) => $align || 'left'};
`;

export const TotalText = styled.p`
  font-size: 1.6rem;
  color: ${({ $type }) => colors[$type] || colors.gray.g10};
`;
