import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/Image/Image';
import { Box, Flex } from 'styles';
import { Text } from 'components/Typography';

const createVariant = ({ colorSchema }) => ({
  outlined: {
    container: {
      borderColor: `${colorSchema}.500`,
      bgColor: `${colorSchema}.50`,
    },
    title: {
      color: `${colorSchema}.500`,
    },
  },
  subtle: {
    container: {
      borderColor: `${colorSchema}.200`,
      bgColor: `${colorSchema}.50`,
    },
    title: {
      color: `${colorSchema}.700`,
    },
  },
});

const MediaDisplay = (props) => {
  const {
    title,
    TitleComponent,
    BodyComponent,
    textContent,
    imageName,
    colorSchema,
    objectFit,
    alt,
    variant,
    boxSize,
    minSize,
    imageProps,
    ...contentProps
  } = props;

  const variantConfig = createVariant({ colorSchema })?.[variant]
    ?? createVariant({ colorSchema }).subtle;

  return (
    <Flex
      width="100%"
      border="1px solid"
      p="1.5rem"
      borderRadius="8px"
      justifyContent="flex-start"
      alignItems="flex-start"
      my="0.75rem"
      {...variantConfig.container}
      {...contentProps}
    >
      <Image
        imageName={imageName}
        objectFit={objectFit}
        alt={alt}
        boxSize={boxSize}
        minSize={minSize}
        bgColor="transparent"
        alignSelf="center"
        {...imageProps}
      />
      <Box ml="1.5rem">
        {!title && TitleComponent && (
          <Flex mb="1rem" alignItems="center">
            <TitleComponent fontWeight="600" {...variantConfig.title} />
          </Flex>
        )}
        {title && (
          <Text mb="1rem" fontWeight="600" {...variantConfig.title}>
            {title}
          </Text>
        )}
        {!textContent && BodyComponent && <BodyComponent />}
        {textContent && <Text lineHeight="1.8rem">{textContent}</Text>}
      </Box>
    </Flex>
  );
};

MediaDisplay.propTypes = {
  imageName: PropTypes.string.isRequired,
  imageProps: PropTypes.shape({}),
  colorSchema: PropTypes.string,
  variant: PropTypes.string,
};

MediaDisplay.defaultProps = {
  imageProps: {},
  colorSchema: 'schema.gray',
  variant: 'subtle',
};

export default MediaDisplay;
