import React from 'react';
import { useHistory } from 'react-router-dom';
import { mainRoute } from 'routes';
import { Flex, Grid, Box } from 'styles';
import { Text, Heading } from 'components/Typography';
import Button from 'components/Button/Button';
import Alert from 'components/Alert';
import { useController } from './hooks/useController';

const Item = (props) => {
  const { children, stepNumber = 0 } = props;

  return (
    <Flex as="li" listStyleType="none" p="0.8rem 1rem" alignItems="flex-start" columnGap="0.8rem">
      <Flex centered borderRadius="50%" bgColor="schema.blue.500" minWidth="25px" minHeight="25px">
        <Text color="white" fontSize="1.2rem" weight="bold">
          {stepNumber}
        </Text>
      </Flex>
      <Text textDecoration="none" color="schema.gray.600" lineHeight="2.8rem">
        {children}
      </Text>
    </Flex>
  );
};

const Show = () => {
  const history = useHistory();
  const handleFinish = () => {
    history.push(mainRoute);
  };

  const { depositInformation } = useController();

  const clabeInterna = depositInformation?.clabeInterna || '';

  return (
    <Grid rowGap="2rem" maxWidth="35rem">
      <Box>
        <Heading>Cuenta bancaria</Heading>
        <Heading as="h3" weight="normal" fontSize="1.6rem">
          Valida tu cuenta bancaria agregando fondos a tu cuenta de inversionista (desde $0.01).
        </Heading>
      </Box>
      <Grid
        bgColor="white"
        borderRadius="0.4rem"
        boxShadow="0 5px 12px 0 rgba(38, 57, 69, 0.09)"
        maxWidth="40rem"
        p="2.5rem"
      >
        <Box as="ul">
          <Item stepNumber={1}>
            Desde tu banca en línea, da de alta la cuenta CLABE
            <b>
              {' '}
              {clabeInterna}
            </b>
          </Item>
          <Item stepNumber={2}>
            Envía un
            {' '}
            <b>SPEI o transferencia electrónica</b>
            {' '}
            desde tu banca por internet.
          </Item>
          <Item stepNumber={3}>Tu cuenta será validada.</Item>
        </Box>
      </Grid>
      <Alert
        colorSchema="gray"
        variant="subtle"
        message={(
          <Text fontSize="1.4rem">
            La cuenta debe estar
            {' '}
            <b>a tu nombre</b>
            , para poder depositarte los retiros que
            realices.
          </Text>
        )}
      />
      <Button onClick={handleFinish} justifySelf="flex-end">
        Entendido
      </Button>
    </Grid>
  );
};

export default Show;
