import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { t, l } from 'i18n';
import {
  fetchUserSignedContracts,
  toggleInvoiceSwitch,
} from 'actions/settings/accountProfile';
import { concatFullName } from 'utils/stringFormatters';
import { ReactComponent as AvatarIcon } from 'assets/requisitions/avatarLoading.svg';
import { selectTab } from 'actions/dashboard';
import { SETTINGS_ITEM } from 'views/SideBar/utils';
import { useReduxQuery } from 'utils/redux-query';
import { SlideFade } from 'ui';
import ContactsPanel from './ContactsPanel/ContactsPanel';
import { AccountProfileContainer, UserNameText } from './styles';
import { ChangePassword } from './ChangePassword';
import Footer from '../../../FooterDashboard';
import { InvoicingSection, BeneficiarySection } from './components';
import { BankAccountSection } from './BankAccountSection/BankAccountSection';
import { ContractPanel } from './ContractPanel';

const AccountProfile = (props) => {
  const {
    signedContracts,
    getSignedContracts,
    responsiveIs,
    isFetchingSignedContracts,
  } = props;
  const dispatch = useDispatch();
  const [session] = useReduxQuery('SESSION');
  const { user } = session;
  const [profile, { status: profileStatus }] = useReduxQuery('PROFILE');
  const { createdAt } = profile || {};
  const [showUpdatePasswordForm, setShowUpdatePasswordForm] = useState(false);
  const [fullName, setFullName] = useState('');

  useEffect(() => {
    if (!signedContracts && !isFetchingSignedContracts) getSignedContracts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!profile) return;

    setFullName(concatFullName(profile));
  }, [profile]);

  useEffect(() => {
    dispatch(selectTab(SETTINGS_ITEM));
  }, []);

  const toggleShowPassword = () => setShowUpdatePasswordForm(!showUpdatePasswordForm);
  const closePasswordForm = () => setShowUpdatePasswordForm(false);

  const { lastSignInAt } = user;
  const parseDate = (date) => l('date.formats.medium', date);

  const renderUserHeader = () => (
    <div className="user-information">
      <div className="data-group user-data">
        <div className="avatar">
          <AvatarIcon title="" />
        </div>
        <div className="user-name">
          <UserNameText>{profileStatus.pending ? t('settings.loading') : fullName}</UserNameText>
          <button type="button" onClick={toggleShowPassword}>
            {t('settings.changePassword')}
          </button>
        </div>
      </div>
      <div className="data-group session-data">
        <p>
          Último inicio de sesión:
          {parseDate(lastSignInAt) || 'Cargando'}
        </p>
        <p>
          Registrado desde:
          {parseDate(createdAt) || 'Cargando'}
        </p>
      </div>
    </div>
  );

  const smallScreen = responsiveIs.mobile || responsiveIs.tablet;

  return (
    <>
      <AccountProfileContainer className="account--profile" isMobile={smallScreen}>
        <div className="account--profile__content">
          {renderUserHeader()}
          <SlideFade
            in={showUpdatePasswordForm}
            offsetY="0"
            unmountOnExit
          >
            <ChangePassword
              onUpdatePasswordSuccess={closePasswordForm}
            />
          </SlideFade>
          <ContactsPanel />
          <BankAccountSection mb="2rem" />
          <BeneficiarySection mb="2rem" />
          <ContractPanel mb="2rem" contracts={signedContracts} />
          <InvoicingSection mb="2rem" />
        </div>
      </AccountProfileContainer>
      <Footer />
    </>
  );
};

const mapStateToProps = ({ settings, funds, browser }) => {
  const { banksAddAccount, accountsList, isFetchingAccountsList } = funds;
  const { accountProfile } = settings;
  const {
    beneficiaryData,
    invoiceToggled,
    signedContracts,
    isFetchingSignedContracts,
    isFetchingBeneficiary,
  } = accountProfile;
  const { is: responsiveIs } = browser;
  return {
    banksList: banksAddAccount,
    userAccountsList: accountsList,
    beneficiaryData,
    signedContracts,
    invoiceToggled,
    responsiveIs,
    isFetchingSignedContracts,
    isFetchingAccountsList,
    isFetchingBeneficiary,
  };
};

const mapDispatchToProps = {
  toggleInvoiceButton: toggleInvoiceSwitch,
  getSignedContracts: fetchUserSignedContracts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountProfile);
