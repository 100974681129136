import { t } from 'i18n';
import { useForm } from 'react-hook-form';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { resolver } from '../resolver';

const createCreateAutoinvestError = ({ setError, setValue }) => (error) => {
  if (error?.response?.status === 403) {
    setError('password', { message: t('Forms.fields.errors.invalidPassword') });
    setValue('password', '');
  }
};

export const useController = ({
  onSuccess = () => {},
}) => {
  const {
    setValue,
    setError,
    control,
    handleSubmit,
  } = useForm({
    resolver,
    defaultValues: {
      accept: false,
      password: '',
    },
  });
  const [autoinvestConfigs] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const autoinvestConfig = autoinvestConfigs?.[0] || null;
  const [createAutoinvest, { isLoading }] = useReduxMutator('UPDATE_AUTO_INV', {
    onSuccess,
    onError: createCreateAutoinvestError({ setError, setValue }),
  });

  const onSubmit = handleSubmit(({ password }) => {
    createAutoinvest({
      password,
      autoinvestConfig,
    });
  });

  return {
    control,
    onSubmit,
    isLoading,
  };
};
