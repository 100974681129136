import React, { useState, useEffect } from 'react';
import { t } from 'i18n';
import { DescriptionLoader } from 'components/Loaders';
import ElementCardContainer from '../../../Elements/ElementCardContainer';
import DescriptionContainer from './styles';

const DescriptionContent = ({
  descriptionTitle,
  descriptionBody,
  isFundedRequisition,
  isLoading,
}) => {
  const [toggleDescription, setToggleDescription] = useState(false);
  const fundingRequisition = () => {
    const SlicedDescription = () => (
      <p>
        {`${descriptionBody.slice(0, 350)}... `}
        <span
          className="seeMore"
          onClick={() => setToggleDescription(true)}
          role="button"
          tabIndex="0"
          onKeyDown={() => setToggleDescription(true)}
        >
          {t('detailRequisition.seeMore')}
        </span>
      </p>
    );
    const renderContent = () => {
      const lengthDescription = descriptionBody.length;
      if (!toggleDescription && lengthDescription > 350) return <SlicedDescription />;
      return <p>{descriptionBody}</p>;
    };
    return (
      <>
        <p className="mainContainer__content__title">
          {!isLoading ? descriptionTitle : ''}
          :
        </p>
        <ElementCardContainer classNames="descriptionCard">
          {isLoading ? <DescriptionLoader /> : renderContent()}
        </ElementCardContainer>
      </>
    );
  };
  const fundedRequisition = () => (
    <ElementCardContainer
      titleFoldCard={t('detailRequisition.descriptionTitle')}
      classNames="foldingCard descriptionCard"
      isFoldCard
    >
      <p>
        Destino:
        {' '}
        <b>{descriptionTitle}</b>
      </p>
      <p>{descriptionBody}</p>
    </ElementCardContainer>
  );

  useEffect(() => {
    setToggleDescription(false);
  }, [descriptionBody]);

  return (
    <DescriptionContainer className={isFundedRequisition ? 'funded' : ''}>
      {!isFundedRequisition ? fundingRequisition() : fundedRequisition()}
    </DescriptionContainer>
  );
};

export default DescriptionContent;
