import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18n';
import { useSpring, useTransition, animated as a } from 'react-spring';
import carIcon from 'assets/carIcon.svg';
import { ReactComponent as RightNavigation } from 'assets/rightNavigationMobil.svg';
import { ReactComponent as LeftNavigation } from 'assets/leftNavigationMobile.svg';
import classnames from 'classnames';
import * as Zendesk from 'utils/Zendesk';
import MobilBarRequisitionDetailContainer from './styles';
import LendingFormRequisitionDetail from '../LendingFormRequisitionDetail';

const FirstState = (props) => {
  const {
    style,
    isEnablePagination,
    previousRequisitionPath,
    nextRequisitionPath,
    openBorrowBar,
    heightNavigation,
    remainingAmount,
  } = props;
  return (
    <a.div style={{ ...style }}>
      {!!isEnablePagination && (
        <a.div className="mobileBar__navigationContent">
          <Link className="mobileBar__navigationButton left" to={previousRequisitionPath}>
            <span className="mobileBar__navigationButton__sign left">
              <LeftNavigation title="" />
            </span>
            Anterior
          </Link>
          <Link className="mobileBar__navigationButton right" to={nextRequisitionPath}>
            Siguiente
            <span className="mobileBar__navigationButton__sign right">
              <RightNavigation title="" />
            </span>
          </Link>
        </a.div>
      )}
      {remainingAmount > 0 && (
        <a.div
          className="mobileBar__lendButton"
          onClick={openBorrowBar}
          style={{ heightNavigation }}
        >
          <img src={carIcon} alt="iconCar" />
          {t('detailRequisition.lend')}
        </a.div>
      )}
    </a.div>
  );
};

const SecondState = (props) => {
  const {
    style,
    openBorrowBar,
    requisitionData,
    remaining,
    lentAmount,
    onUpdateCart,
    onUpdateRequisition,
  } = props;

  return (
    <a.div style={{ ...style, borderRadius: '20px 20px 0 0' }}>
      <div className="mobileBar__lendingContent">
        <LendingFormRequisitionDetail
          onCloseButton={openBorrowBar}
          requisitionData={requisitionData}
          remaining={remaining}
          onUpdateAmountCart={onUpdateCart}
          lentAmount={lentAmount}
          mobile
          onUpdateRequistion={onUpdateRequisition}
        />
      </div>
    </a.div>
  );
};

const MobilBarRequisitionDetail = ({
  nextRequisitionPath,
  previousRequisitionPath,
  remaining,
  onUpdateCart,
  lentAmount,
  requisitionData,
  onUpdateRequisition,
  isEnablePagination,
  remainingAmount,
}) => {
  const [toggleBorrowBar, setToggleBorrowBar] = useState(false);
  const [index, setIndex] = useState(0);
  const { height, heightNavigation } = useSpring({
    height: toggleBorrowBar ? '40rem' : '5rem',
    heightNavigation: toggleBorrowBar ? '100%' : '0%',
    config: { mass: 5, tension: 500, friction: 100 },
  });
  const transitions = useTransition(index, null, {
    from: { maxHeight: 0 },
    enter: { maxHeight: 400 },
    leave: { maxHeight: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
  });
  const openBorrowBar = () => {
    setToggleBorrowBar((state) => !state);
    setIndex((state) => (state + 1) % 2);
  };

  useEffect(() => {
    if (toggleBorrowBar) {
      Zendesk.hideLauncher();
    } else {
      Zendesk.displayLauncher();
    }
  }, [toggleBorrowBar]);

  return (
    <MobilBarRequisitionDetailContainer toggle={toggleBorrowBar}>
      <a.div
        className={classnames(
          `mobileBar ${toggleBorrowBar || !!nextRequisitionPath ? '' : 'noNavButtons'}`,
          {
            bg_trasnparent: !isEnablePagination,
          },
        )}
        style={{ height }}
      >
        <div
          className={classnames('mobileBar__backgroundBar', {
            trasnparentBackground: isEnablePagination,
          })}
        >
          {transitions.map(({ item, props }) => (item ? (
            <SecondState
              remaining={remaining}
              onUpdateCart={onUpdateCart}
              lentAmount={lentAmount}
              requisitionData={requisitionData}
              onUpdateRequisition={onUpdateRequisition}
              style={props}
              openBorrowBar={openBorrowBar}
            />
          ) : (
            <FirstState
              remainingAmount={remainingAmount}
              isEnablePagination={isEnablePagination}
              previousRequisitionPath={previousRequisitionPath}
              nextRequisitionPath={nextRequisitionPath}
              openBorrowBar={openBorrowBar}
              heightNavigation={heightNavigation}
              style={props}
            />
          )))}
        </div>
      </a.div>
    </MobilBarRequisitionDetailContainer>
  );
};

export default MobilBarRequisitionDetail;
