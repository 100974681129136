import { request } from '../fetchers/yotepresto';

export const updatePassword = async (values, otp) => {
  const method = 'put';
  const path = '/v1/update_password';
  const body = {
    user: {
      current_password: values.currentPassword,
      password: values.newPassword,
      password_confirmation: values.newPasswordConfirmation,
    },
    otp_code: otp,
  };

  const config = { method, path, body };
  const response = await request(config);
  return {
    status: response.status,
  };
};

export const toggleInvoice = async ({
  password,
} = {}) => {
  const method = 'put';
  const path = '/v1/profile/invoice_toggle';
  return request({ method, path, extraHeaders: { password } });
};

export const getUserSignedContracts = async () => {
  const config = {
    method: 'get',
    path: '/v1/investor/contract/signed_contracts',
  };
  const response = await request(config);
  return response.data;
};

export const passwordEnhancements = async (email, password) => {
  const method = 'post';
  const path = '/v1/passwords/validate';
  const params = { email, password };
  const { data: { errors } } = await request({ method, path, params });
  return errors;
};

export const addressValid = async () => {
  const method = 'get';
  const path = '/v1/profile/address/valid';
  return request({ method, path });
};
