import styled from 'styled-components';
import { colors } from 'styles';

const TextField = styled.div`
  input[type='text'],
  textarea {
    background-color: white;
    border: 1px solid #a2bcc5;
    font-size: 1.5rem;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    color: ${colors.gray.g8};
  }
  input[type='text']::placeholder {
    color: ${colors.gray.g3};
  }
  input[disabled=''] {
    background-color: #f5f5f5;
    opacity: 0.8;
  }
`;

export default TextField;
