import React, { useState, useContext } from 'react';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { t } from 'i18n';
import { pipe } from 'utils/Monads';
import { capitalize } from 'utils/strings';
import { Heading, Text } from 'components/Typography';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import Divider from 'components/Divider';
import Link from 'components/Link';
import Alert from 'components/Alert';
import { Flex, Grid } from 'styles';
import { controllerContext } from 'views/DashboardContent/Expedients/Profile/controller/context';
import { Content } from './Username.styles';
import validator from './validator';

const tOptions = { scope: 'expedientsProfileUpdate.username' };
const steps = { FILL_REASON: 'fillReason', FILL_NAME: 'fillName' };

function Username() {
  const history = useHistory();
  const {
    profile, setProfile, trackStep, STEP_NAMES,
  } = useContext(controllerContext);
  const initialValues = {
    firstName: profile?.firstName,
    secondName: profile?.secondName,
    firstLastName: profile?.firstLastName,
    secondLastName: profile?.secondLastName,
  };
  const [step, setStep] = useState(steps.FILL_REASON);

  const handleClickOnReason = (reason, input) => () => {
    input.onChange(reason);
    input.onBlur();
    setStep(steps.FILL_NAME);
  };

  const handleOnSubmit = (values) => {
    const {
      firstName, secondName, firstLastName, secondLastName, reason,
    } = values;
    const nextProfile = (currentProfile) => ({
      ...currentProfile,
      firstName,
      secondName,
      firstLastName,
      secondLastName,
      reason,
    });

    setProfile(pipe(nextProfile));
    trackStep({ name: STEP_NAMES.USERNAME, hasUserTakenForm: true });

    history.push('../show');
  };

  return (
    <Form onSubmit={handleOnSubmit} validate={validator} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          {step === steps.FILL_REASON && (
            <Content>
              <div>
                <Heading mb="1rem">{t('title', tOptions)}</Heading>
                <Heading as="p">{t('subtitle', tOptions)}</Heading>
              </div>
              <Field name="reason">
                {({ input }) => (
                  <>
                    <Button
                      $type="primary-outline"
                      onClick={handleClickOnReason(
                        t('form.reasonField.options.0', tOptions),
                        input,
                      )}
                    >
                      {t('form.reasonField.options.0', tOptions)}
                    </Button>
                    <Button
                      $type="primary-outline"
                      onClick={handleClickOnReason(
                        t('form.reasonField.options.1', tOptions),
                        input,
                      )}
                    >
                      {t('form.reasonField.options.1', tOptions)}
                    </Button>
                    <Button
                      $type="primary-outline"
                      onClick={handleClickOnReason(
                        t('form.reasonField.options.2', tOptions),
                        input,
                      )}
                    >
                      {t('form.reasonField.options.2', tOptions)}
                    </Button>
                  </>
                )}
              </Field>
              <Link to="../show" weight="500">
                {t('actions.cancel', tOptions)}
              </Link>
              <Text>
                {t('footnote', tOptions)}
                {' '}
                <Link as="a" href={`mailto:${t('common.pldEmail')}`}>
                  {t('common.pldEmail')}
                </Link>
              </Text>
            </Content>
          )}
          {step === steps.FILL_NAME && (
            <Content>
              <div>
                <Heading mb="1rem">{t('title2', tOptions)}</Heading>
                <Heading as="p">{t('subtitle2', tOptions)}</Heading>
              </div>
              <TextField
                name="firstName"
                textLabel={t('form.firstNameField.textLabel', tOptions)}
                color="gray.g7"
                parse={capitalize}
              />
              <TextField
                name="secondName"
                textLabel={t('form.secondNameField.textLabel', tOptions)}
                color="gray.g7"
                parse={capitalize}
              />
              <TextField
                name="firstLastName"
                textLabel={t('form.firstLastNameField.textLabel', tOptions)}
                color="gray.g7"
                parse={capitalize}
              />
              <TextField
                name="secondLastName"
                textLabel={t('form.secondLastNameField.textLabel', tOptions)}
                color="gray.g7"
                parse={capitalize}
              />
              <Alert
                title={t('confirmAlert.title', tOptions)}
                variant="subtle"
                colorSchema="gray"
                message={(
                  <Grid as="ul" ml="1.4rem">
                    <li>
                      <Text $size="body">{t('confirmAlert.list.0', tOptions)}</Text>
                    </li>
                    <li>
                      <Text $size="body">{t('confirmAlert.list.1', tOptions)}</Text>
                    </li>
                    <li>
                      <Text $size="body">{t('confirmAlert.list.2', tOptions)}</Text>
                    </li>
                  </Grid>
                )}
              />
              <Divider w="100%" color="schema.gray.300" />
              <Flex justifyContent="space-between">
                <Button $type="btn-link" onClick={() => setStep(steps.FILL_REASON)}>
                  {t('form.actions.cancel', tOptions)}
                </Button>
                <Button type="submit" disabled={submitting}>
                  {t('form.actions.submit', tOptions)}
                </Button>
              </Flex>
            </Content>
          )}
        </form>
      )}
    </Form>
  );
}

export default Username;
