import React from 'react';
import ContentLoader from 'react-content-loader';

const ReferralsTableLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={100}
    viewBox="0 0 400 100"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="11" y="10" rx="5" ry="5" width="59" height="12" />
    <rect x="116" y="10" rx="5" ry="5" width="59" height="12" />
    <rect x="222" y="10" rx="5" ry="5" width="59" height="12" />
    <rect x="324" y="10" rx="5" ry="5" width="59" height="12" />
    <rect x="11" y="42" rx="5" ry="5" width="59" height="12" />
    <rect x="116" y="42" rx="5" ry="5" width="59" height="12" />
    <rect x="222" y="42" rx="5" ry="5" width="59" height="12" />
    <rect x="324" y="42" rx="5" ry="5" width="59" height="12" />
    <rect x="116" y="74" rx="5" ry="5" width="59" height="12" />
    <rect x="222" y="74" rx="5" ry="5" width="59" height="12" />
  </ContentLoader>
);

export { ReferralsTableLoader };
