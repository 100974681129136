import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { tscope } from 'i18n';
import { WS_EVENT_NAMES } from 'config/constants';
import {
  createActions,
  useReduxSubscription,
} from 'utils/redux-query';
import { parseEventMessage } from 'utils/parseWSEventMessages';
import { showToastError, showToastInfo, showToastSuccess } from 'utils/toastHandler';

const ts = tscope('settings.limits');

const { setPayload: setKYCStatusPayload } = createActions('KYC_STATUS');

export const useBindMatiVerification = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [, {
    refetch: refetchPersonalArchives,
    status: {
      completed: personalArchivesCompleted,
    },
  }] = useReduxSubscription('PERSONAL_ARCHIVES');
  const [KYCInfo] = useReduxSubscription('KYC_STATUS');

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const { status } = data;

    if (status === 'success') {
      showToastSuccess(ts('success.successValidateIdentification'));
      if (personalArchivesCompleted) {
        refetchPersonalArchives();
      }
    } else if (status === 'started') {
      showToastInfo(ts('success.startedValidateIdentification'));
    } else if (status === 'waiting_for_response') {
      showToastInfo(ts('success.waitingValidateIdentification'));
    } else {
      showToastError(ts('errors.failedValidateIdentification'));
    }
    const payload = {
      ...KYCInfo,
      matiStatus: status,
    };
    dispatch(setKYCStatusPayload(payload));
  }, [
    KYCInfo,
    dispatch,
    refetchPersonalArchives,
    personalArchivesCompleted,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.MATI_STATUS,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
