import * as api from 'apis/investor/movements/movements';
import { createAsyncAction, createActions } from 'utils/redux-query';

export const {
  setPayload: setPayloadMovements,
  setMeta: setMetaMovements,
  update: updateMovements,
  reinitialize: reinitializeMovements,
} = createActions('MOVEMENTS');

export const fetchMovements = createAsyncAction('MOVEMENTS', (filters) => () => api.getTransactions(filters));

export const { setPayload: setMovementsFilter } = createActions('MOVEMENTS_FILTER');
