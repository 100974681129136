import React from 'react';
import Route from 'components/Route';
import { useSelector } from 'react-redux';
import {
  getWebAppUrl,
} from 'config';

export const ProtectedRoute = (props) => {
  const {
    component: Component,
  } = props;
  const credentials = useSelector(({ auth }) => auth.credentials);
  const isAuthenticated = !!credentials;

  const renderContent = (routeProps) => {
    if (!isAuthenticated) {
      window.location.assign(getWebAppUrl());
      return null;
    }

    return <Component {...routeProps} />;
  };

  return <Route render={renderContent} />;
};
