import styled from 'styled-components';
import { colors } from 'styles';

export const List = styled.ul`
  margin-left: 4rem;
  li::marker {
    color: ${colors.schema.gray[500]}
  }
`;

export const styles = {
  innerContent: {
    padding: '2rem',
  },
  text: {
    fontSize: '1.4rem',
    color: 'schema.gray.500',
  },
  list: {
    gap: '0.5rem',
  },
  actionsArea: {
    justifyContent: 'flex-end',
  },
};
