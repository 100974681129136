import camelify from 'camelcase-keys-recursive';
import { request } from '../../fetchers/yotepresto';

const verifyPhone = async () => {
  const config = {
    path: '/v2/second_factors/verify_phone',
    method: 'post',
  };

  const { data } = await request(config);

  return camelify(data);
};

export default verifyPhone;
