import styled from 'styled-components';

const ErrorContainer = styled.div`
  text-align: center;
  img {
    margin-bottom: 3.5rem;
  }
`;

export default ErrorContainer;
