import React from 'react';
import { Checkbox, Checkmark, Label } from './CheckboxInput.styles';

export const CheckboxInput = ({
  label,
  value,
  onBlur,
  checked,
  onChange,
  ...contentProps
}) => (
  <Label {...contentProps}>
    {label}
    <Checkbox
      type="checkbox"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      checked={checked}
    />
    <Checkmark />
  </Label>
);
