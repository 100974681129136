import React from 'react';
import PATHS, {
  dashboardRoute,
  investorCalendar,
  investorAddFunds,
  investorRemoveFunds,
  userSettingsAccount,
  investorTransactions,
  investorSettings,
} from 'routes';
import { ReactComponent as SummaryIcon } from 'assets/sidebar/resumeIcon.svg';
import { ReactComponent as LendIcon } from 'assets/sidebar/moneyIcon.svg';
import { ReactComponent as PortfolioIcon } from 'assets/sidebar/portfolioIcon.svg';
import { ReactComponent as BalanceIcon } from 'assets/sidebar/movesIcon.svg';
import { ReactComponent as CalendarIcon } from 'assets/sidebar/calendarIcon.svg';
import { ReactComponent as AddFundsIcon } from 'assets/sidebar/addFundsIcon.svg';
import { ReactComponent as WithdrawFundsIcon } from 'assets/sidebar/withdrawIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/settingsSideBar.svg';
import { t } from 'i18n';

const {
  investorPortfolioPath,
  investorRequisitionsPath,
  investorSettingsLimitsLevelOnePath,
} = PATHS;

export const tabKeys = {
  RESUME_TAB: 'RESUME_TAB',
  REQUISITIONS_TAB: 'REQUISITIONS_TAB',
  LOANS_TAB: 'LOANS_TAB',
  MOVEMENTS_TAB: 'MOVEMENTS_TAB',
  CALENDAR_TAB: 'CALENDAR_TAB',
  ADD_FUNDS_TAB: 'ADD_FUNDS_TAB',
  WITHDRAW_TAB: 'WITHDRAW_TAB',
  SETTINGS_TAB: 'SETTINGS_TAB',
  SETTINGS_SECURITY_TAB: 'SETTINGS_SECURITY_TAB',
};

export const RESUME_ITEM = {
  id: tabKeys.RESUME_TAB,
  name: t('Dashboard.NavigationMenu.items.summary'),
  path: dashboardRoute,
  title: 'Inicio',
  icon: <SummaryIcon title="" />,
};

export const REQUISITION_ITEM = {
  id: tabKeys.REQUISITIONS_TAB,
  name: t('Dashboard.NavigationMenu.items.requisitions'),
  path: investorRequisitionsPath(),
  title: 'Solicitudes en fondeo',
  icon: <LendIcon title="" />,
};

export const PORTFOLIO_ITEM = {
  id: tabKeys.LOANS_TAB,
  name: t('Dashboard.NavigationMenu.items.portfolio'),
  path: investorPortfolioPath(),
  title: t('Dashboard.NavigationMenu.items.portfolio'),
  icon: <PortfolioIcon title="" />,
};

export const TRANSACTIONS_ITEM = {
  id: tabKeys.MOVEMENTS_TAB,
  name: t('Dashboard.NavigationMenu.items.transactions'),
  path: investorTransactions,
  title: t('Dashboard.NavigationMenu.items.transactions'),
  icon: <BalanceIcon title="" />,
};

export const SCHEDULED_PAYMENTS_ITEM = {
  id: tabKeys.CALENDAR_TAB,
  name: t('Dashboard.NavigationMenu.items.calendar'),
  path: investorCalendar,
  title: t('Dashboard.NavigationMenu.items.calendar'),
  icon: <CalendarIcon title="" />,
};

export const FUNDING_ACCOUNT_ITEM = {
  id: tabKeys.ADD_FUNDS_TAB,
  name: t('Dashboard.NavigationMenu.items.addFunds'),
  path: investorAddFunds,
  title: t('Dashboard.NavigationMenu.items.addFunds'),
  icon: <AddFundsIcon title="" />,
};

export const WITHDRAW_ITEM = {
  id: tabKeys.WITHDRAW_TAB,
  name: t('Dashboard.NavigationMenu.items.withdrawFunds'),
  path: investorRemoveFunds,
  title: t('Dashboard.NavigationMenu.items.withdrawFunds'),
  icon: <WithdrawFundsIcon title="" />,
};

export const SETTINGS_ITEM = {
  id: tabKeys.SETTINGS_TAB,
  name: t('common.routeNames.settings'),
  path: investorSettings,
  title: t('common.routeNames.settings'),
  icon: <SettingsIcon title="" />,
};

const getItems = ({ featureFlags }) => [
  { isDisabled: !featureFlags.Dashboard, ...RESUME_ITEM },
  { isDisabled: !featureFlags.Requisitions, ...REQUISITION_ITEM },
  { isDisabled: !featureFlags.Portfolio, ...PORTFOLIO_ITEM },
  { isDisabled: !featureFlags.Transactions, ...TRANSACTIONS_ITEM },
  { isDisabled: !featureFlags.ScheduledPayments, ...SCHEDULED_PAYMENTS_ITEM },
  { isDisabled: !featureFlags.FundingAccount, ...FUNDING_ACCOUNT_ITEM },
  { isDisabled: !featureFlags.Withdraw, ...WITHDRAW_ITEM },
];

const limitTitle = (level) => ({
  name: `Límites/Nivel ${level}`,
  path: investorSettingsLimitsLevelOnePath(),
  title: `Límites/Nivel ${level}`,
});

const settingsElement = {
  name: 'Ajustes',
  path: userSettingsAccount,
  title: 'Ajustes',
  icon: <SettingsIcon title="" />,
};

export { getItems, settingsElement, limitTitle };
