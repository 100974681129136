import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const ReferralContainerDiv = ({ isMobile, referenceCode, ...props }) => <div {...props} />;
const ReferralContainer = styled(ReferralContainerDiv)`
  grid-column: 6 / -1;
  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    grid-column: 1 / -1;
  }
  & .referral {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: ${colors.shadow.card};
    border-radius: 0.4rem;
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      height: auto;
      flex-direction: column;
    }
    & .description {
      cursor: pointer;
      flex: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      border-right: 1px solid ${colors.gray.g2};
      border-bottom: 0px solid ${colors.gray.g2};
      padding: 2rem;
      transition: all 0.4s ease;
      .referralButton a {
        text-decoration: none;
        color: white;
      }
      svg + div {
        margin-left: 2rem;
        p {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 1rem;
        }
      }
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        border-right: 0px solid ${colors.gray.g2};
        border-bottom: 1px solid ${colors.gray.g2};
      }
    }
    & .code {
      flex: 1;
      display: flex;
      padding: 0.8rem;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      p {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 1rem;
        color: ${colors.gray.g5};
      }
      .codeContainer {
        border: 1px dashed ${colors.gray.g3};
        font-size: 1.6rem;
        font-weight: 600;
        color: #6236ff;
        border-radius: 0.6rem;
        padding: 1rem;
        white-space: nowrap;
      }
    }
  }
`;

export default ReferralContainer;
