import {
  Flex,
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

const Box = styled(Flex).withConfig({ shouldForwardProp })`
  display: flex;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(38, 57, 69, 0.09);
  padding: 1rem 2rem;
  align-items: center;
  max-width: 536px;
  ${boxHelpers}
`;

export default Box;
