import React from 'react';

export const handlePrint = ({ htmlContent }) => {
  const pri = document.getElementById('printableFrame').contentWindow;
  pri.document.open();
  pri.document.write(htmlContent);
  pri.document.close();
  pri.focus();
  pri.print();
};

export const PrintFrame = (props) => (
  <iframe
    title="printableFrame"
    id="printableFrame"
    style={{
      height: '0px',
      width: '0px',
      position: 'absolute',
      visibility: 'hidden',
    }}
    {...props}
  />
);
