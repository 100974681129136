import React, { useState } from 'react';
import TicketsRow from './TicketsRow';
import TicketsTableContainer from './styles';

const TicketsTable = ({ tickets, updateCreatedTickets, widthLayout }) => {
  const [openedOtpIndex, setOpenedOtpIndex] = useState(null);

  return (
    <>
      <p className="tickets_created">
        Tus préstamos en está solicitud (
        {tickets.length}
        )
      </p>
      <div className="borrowers_table ticketsTable">
        <TicketsTableContainer className="ticketsTable">
          {tickets.map((item, index) => (
            <TicketsRow
              ticket={{ index, item }}
              setOnOpenOtpState={setOpenedOtpIndex}
              openedOtpState={openedOtpIndex}
              updateCreatedTickets={updateCreatedTickets}
              widthLayout={widthLayout}
            />
          ))}
        </TicketsTableContainer>
      </div>
    </>
  );
};

export default TicketsTable;
