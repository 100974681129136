import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const {
  largeBreakPoint, extraSmallBreakPoint, extraLargeBreakPoint, midBreakPoint,
} = breakPoints;

const CreditHistoryContainer = styled.div`
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  @media (max-width: ${midBreakPoint}px) {
    margin-top: 3.5rem;
  }
  &.funded {
    margin-top: 5rem;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .item {
    padding: 2.5rem;
    text-align: center;
    flex: 1;
    min-width: auto;
    margin-right: 1.5rem;
    :last-child {
      margin-right: 0;
    }
    @media (max-width: ${largeBreakPoint}px) {
      padding: 2.5rem 1rem;
    }
    @media (max-width: ${midBreakPoint}px) {
      margin-right: 0;
    }
    @media (max-width: ${extraSmallBreakPoint}px) {
      padding: 0;
    }
    &__score {
      color: #08abb7;
    }
    &__history {
      color: #00c574;
    }
    &__consult {
      color: #2c8aff;
    }
    &__account {
      color: #6c4ee4;
    }
    &__number {
      display: flex;
      flex-direction: row;
      justify-content: center;
      h4 {
        color: inherit;
        line-height: 4.2rem;
        font-size: 3.8rem;
        font-weight: 600;
      }
      img {
        margin-right: 0.5rem;
      }
      @media (max-width: ${extraLargeBreakPoint}px) {
        flex-direction: column;
        align-items: center;
        font-size: 3rem;
        img {
          width: 2.4rem;
          height: 2.3rem;
        }
      }
    }
    &__text {
      color: #668d9d;
    }
  }
`;

export default CreditHistoryContainer;
