import React from 'react';

import DropdownContainer from './styles';

const Dropdown = (props) => {
  const { children, styles } = props;
  return <DropdownContainer style={styles}>{children}</DropdownContainer>;
};

export default Dropdown;
