import { request } from 'apis/fetchers/yotepresto';
import parse from './parser';

const fetch = async () => {
  const method = 'get';
  const path = '/v2/investor/personal_archives';
  const config = { method, path };
  const { data } = await request(config);

  return parse(data);
};

export default fetch;
