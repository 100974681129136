import React from 'react';
import styled from 'styled-components';

const ContainerDiv = ({ mobile, ...otherProps }) => <div {...otherProps} />;

const EmptyStateContainer = styled(ContainerDiv)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #93afb9;
  width: 100%;
  padding-top: 4rem;

  & .empty__portfolio {
    & svg {
      width: ${(props) => (props.mobile ? '20rem' : '100%')};
    }
    &__title {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 3.5rem;
      margin-top: 4rem;
      padding: ${(props) => (props.mobile ? '0 1rem' : '0')};
      max-width: 440px;
    }
    &__description {
      font-size: 1.6rem;
      text-align: center;
      margin-bottom: 3rem;
      padding: ${(props) => (props.mobile ? '0 1rem' : '0')};
      max-width: 440px;
    }
  }
`;

export default EmptyStateContainer;
