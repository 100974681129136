import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';
import snakecaseKeys from 'snakecase-keys';

export const CATALOG_WITHDRAWAL_PATH = '/v1/catalogs/withdraws';

const parseWithdrawalBankCatalog = ({ catalog }) => {
  const catalogCamelified = camelify(catalog);
  const banks = catalogCamelified.banks
    .map(([id, name, abmCode]) => ({
      id: String(id),
      name,
      abmCode,
    }));
  return {
    catalog: { banks },
  };
};

export const fetchWithdrawalBanks = async () => {
  const path = CATALOG_WITHDRAWAL_PATH;
  const method = 'get';
  const config = { method, path };
  const { data: { catalogs } } = await request(config);
  return parseWithdrawalBankCatalog({ catalog: catalogs });
};

export const WITHDRAWAL_REQUEST_PATH = '/v1/investor/withdraws';

export const requestWithdrawal = ({ withdraw, otp }) => {
  const path = '/v1/investor/withdraws';
  const body = snakecaseKeys({
    withdraw,
    otpCode: otp,
  });
  const method = 'post';
  const config = { method, path, body };
  return request(config);
};
