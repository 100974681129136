import * as API from 'apis/investor/profile/cellphone';
import { createAsyncAction, createActions } from 'utils/redux-query';

const { patch: patchProfile } = createActions('PROFILE');

export const updateCellphone = createAsyncAction(
  'UPDATE_PROFILE_CELLPHONE',
  ({
    otp,
    cellPhoneCode,
    cellPhoneNumber,
  }) => async (dispatch, getStore) => {
    const { payload: profile } = getStore().PROFILE;
    const result = await API.update({
      otp,
      cellPhoneCode,
      cellPhoneNumber,
    });

    dispatch(
      patchProfile({
        ...profile,
        cellPhoneNumber,
      }),
    );

    return result;
  },
);
