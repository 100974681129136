import DotsLoader from 'components/DotsLoader';
import React from 'react';
import { Flex } from 'styles';
import { styles as s } from './LoadingCard.styles';

export const LoadingCard = () => (
  <Flex {...s.loadingContent}>
    <Flex {...s.loadingInnerContent}>
      <DotsLoader />
    </Flex>
  </Flex>
);
