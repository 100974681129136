import React from 'react';
import moment from 'moment';
import { PrintFrame } from 'utils/window/print';
import {
  Box,
  Text,
  Flex,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from 'ui';
import {
  ContractWrapper,
} from 'components/ContractWrapper';
import { RemoveAIContractForm } from '../components';
import { useController } from './hooks';

export const ContractModal = ({
  isOpen,
  handleClose,
  selectedContract,
}) => {
  const {
    data,
    html,
    name,
    onPrint,
    isAILoading,
    isAutoinvest,
    onRemoveContractSuccess,
    showPasswordConfirmation,
    setShowPasswordConfirmation,
  } = useController({ handleClose, selectedContract });

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {name}
        </ModalHeader>
        <ModalBody>
          <PrintFrame id="printableFrame" />
          {isAutoinvest && (
          <>
            <Box
              as="iframe"
              frameBorder="0"
              title="Contrato de activación de Autoinvest"
              srcDoc={html}
              padding="5"
              bgColor="gray.50"
              height="xl"
              width="100%"
            />
            <Box p="2rem" borderTopWidth="1px">
              <Text fontSize="md">
                El documento fue firmado el:
                {' '}
                <b>{moment(data.created_at).format('DD/MM/Y h:mm:ss a')}</b>
              </Text>
              {data.ip_address ? (
                <Text fontSize="md">
                  Desde la dirección IP:
                  {' '}
                  <b>{data.ip_address}</b>
                </Text>
              ) : null}
            </Box>
          </>
          )}
          {!isAutoinvest && (
          <ContractWrapper
            document={html}
            height="3xl"
            maxHeight="50rem"
            width="100%"
          />
          )}
        </ModalBody>
        <ModalFooter gap="5" justifyContent="center" marginBottom="5">
          {showPasswordConfirmation && (
            <RemoveAIContractForm
              onCancel={() => setShowPasswordConfirmation(false)}
              onSuccess={onRemoveContractSuccess}
              width="sm"
              padding="5"
              boxShadow="lg"
              borderRadius="md"
              bgColor="gray.50"
              justifySelf="center"
            />
          )}
          {!showPasswordConfirmation && (
            <Flex justifyContent="space-between" width="full">
                {isAutoinvest && (
                  <Button
                    colorScheme="red"
                    isDisabled={isAILoading}
                    onClick={() => setShowPasswordConfirmation(true)}
                  >
                    Revocar
                  </Button>
                )}
              <Button onClick={onPrint} className="print">
                Imprimir
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
