import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import createReducer from 'utils/redux-query/createReducer';
import { breakPoints } from 'config/constants';
import authReducer from './authReducer';
import dashboardReducer from './dashboardReducer';
import fundsReducer from './fundsReducer';
import cartReducer from './cartReducer';
import catalogsReducer from './catalogsReducer';
import accountProfileReducer from './settings/accountProfileReducer';
import securityReducer from './settings/securityReducer';
import calendarReducer from './calendarReducer';
import requisitionsReducer from './requisitionsReducer';
import identityVerificationReducer from './identityVerificationReducer';
import limitsReducer from './settings/limitsReducer';

const orderAlphabetically = (obj) => Object
  .entries(obj)
  .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const reducers = orderAlphabetically({
  // BEGIN APP REDUCERS
  APP_AUTO_INVEST: createReducer('APP_AUTO_INVEST'),
  // BEGIN QUERY REDUCERS
  SESSION: createReducer('SESSION'),
  CREATE_SESSION: createReducer('CREATE_SESSION'),
  FETCH_ACCOUNT_INVESTMENTS: createReducer('FETCH_ACCOUNT_INVESTMENTS'),
  FETCH_ACCOUNT_STATEMENTS: createReducer('FETCH_ACCOUNT_STATEMENTS'),
  SESSION_STATUS: createReducer('SESSION_STATUS'),
  PERSONAL_ARCHIVES: createReducer('PERSONAL_ARCHIVES'),
  PROFILE: createReducer('PROFILE'),
  UPDATE_PROFILE_CELLPHONE: createReducer('UPDATE_PROFILE_CELLPHONE'),
  UPDATE_PROFILE_EMAIL: createReducer('UPDATE_PROFILE_EMAIL'),
  REQUISITIONS_TOTAL_ON_FUNDING: createReducer('REQUISITIONS_TOTAL_ON_FUNDING'),
  REQUISITIONS_DIVERSIFICATION: createReducer('REQUISITIONS_DIVERSIFICATION'),
  UPDATE_PROFILE: createReducer('UPDATE_PROFILE'),
  UPDATE_EXPEDIENT_PROFILE: createReducer('UPDATE_EXPEDIENT_PROFILE'),
  PROFILE_ADDRESS: createReducer('PROFILE_ADDRESS'),
  CREATE_PROFILE_ADDRESS: createReducer('CREATE_PROFILE_ADDRESS'),
  UPDATE_PROFILE_ADDRESS: createReducer('UPDATE_PROFILE_ADDRESS'),
  KYC_STATUS: createReducer('KYC_STATUS'),
  AUTO_INVEST_CONTRACT: createReducer('AUTO_INVEST_CONTRACT'),
  FETCH_ALL_AUTO_INV: createReducer('FETCH_ALL_AUTO_INV'),
  TOGGLE_ACTIVATION_AUTO_INV: createReducer('TOGGLE_ACTIVATION_AUTO_INV'),
  CREATE_AUTO_INV: createReducer('CREATE_AUTO_INV'),
  UPDATE_AUTO_INV: createReducer('UPDATE_AUTO_INV'),
  DESTROY_AUTO_INV: createReducer('DESTROY_AUTO_INV'),
  UPDATE_KYC_STATUS: createReducer('UPDATE_KYC_STATUS'),
  CART_IS_OPEN: createReducer('CART_IS_OPEN', { initialState: false }),
  CONTRACT: createReducer('CONTRACT'),
  UPDATE_CONTRACT: createReducer('UPDATE_CONTRACT'),
  REFERRALS: createReducer('REFERRALS'),
  auth: authReducer,
  dashboard: dashboardReducer,
  funds: fundsReducer,
  cart: cartReducer,
  catalogs: catalogsReducer,
  calendar: calendarReducer,
  identityVerification: identityVerificationReducer,
  settings: combineReducers({
    accountProfile: accountProfileReducer,
    security: securityReducer,
    limits: limitsReducer,
  }),
  browser: createResponsiveStateReducer({
    mobile: breakPoints.smallBreakPoint,
    tablet: breakPoints.midBreakPoint,
    laptop: breakPoints.extraLargeBreakPoint,
    large: breakPoints.largeBreakPoint,
  }),
  requisitions: requisitionsReducer,
  ORDERS: createReducer('ORDERS', { initialState: [] }),
  TOTAL_APPROVED_TICKETS: createReducer('TOTAL_APPROVED_TICKETS'),
  TOTAL_ACTIVED_OR_CLOSED_TICKETS: createReducer('TOTAL_ACTIVED_OR_CLOSED_TICKETS'),
  TOTAL_LOANS_MADE: createReducer('TOTAL_LOANS_MADE'),
  MOVEMENTS: createReducer('MOVEMENTS'),
  MOVEMENTS_FILTER: createReducer('MOVEMENTS_FILTER', { initialState: { page: 1 } }),
  PORTFOLIO: createReducer('PORTFOLIO'),
  PORTFOLIO_FILTER: createReducer('PORTFOLIO_FILTER', { initialState: { page: 1 } }),
  TICKET_APPROVED: createReducer('TICKET_APPROVED'),
  LEVELS: createReducer('LEVELS'),
  TRANSACTIONS: createReducer('TRANSACTIONS'),
  TRANSACTION_VOUCHER: createReducer('TRANSACTION_VOUCHER'),
  DEPOSIT_INFORMATION: createReducer('DEPOSIT_INFORMATION'),
  CATALOG: createReducer('CATALOG'),
  WITHDRAWAL_BANKS_CATALOG: createReducer('WITHDRAWAL_BANKS_CATALOG'),
  WITHDRAW_FUNDS: createReducer('WITHDRAW_FUNDS'),
  BANK_ACCOUNTS: createReducer('BANK_ACCOUNTS'),
  CREATE_BANK_ACCOUNT: createReducer('CREATE_BANK_ACCOUNT'),
  UPDATE_BANK_ACCOUNT: createReducer('UPDATE_BANK_ACCOUNT'),
  DELETE_BANK_ACCOUNT: createReducer('DELETE_BANK_ACCOUNT'),
  EXPEDIENTS_PROFILE_CONFIRM: createReducer('EXPEDIENTS_PROFILE_CONFIRM'),
  REFERRAL: createReducer('REFERRAL'),
  CREATE_REFERRAL: createReducer('CREATE_REFERRAL'),
  UPDATE_REFERRAL: createReducer('UPDATE_REFERRAL'),
  SECOND_FACTOR: createReducer('SECOND_FACTOR'),
  DEACTIVATE_SECOND_FACTOR: createReducer('DEACTIVATE_SECOND_FACTOR'),
  VERIFY_PHONE: createReducer('VERIFY_PHONE'),
  CONFIRM_CHANGE_SECOND_FACTOR: createReducer('CONFIRM_CHANGE_SECOND_FACTOR'),
  REQUEST_CHANGE_SECOND_FACTOR: createReducer('REQUEST_CHANGE_SECOND_FACTOR'),
  VALIDATE_OTP: createReducer('VALIDATE_OTP'),
  GENERATE_OTP: createReducer('GENERATE_OTP'),
});

const appReducer = combineReducers(reducers);

export const rootReducer = (state, action) => {
  if (action.type === 'RESTART_STATE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
