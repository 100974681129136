export const styles = {
  alert: {
    width: 'md',
    variant: 'subtle',
    status: 'warning',
    borderWidth: '1px',
    borderRadius: 'lg',
    color: 'yellow.500',
    borderStyle: 'solid',
    bgColor: 'yellow.100',
    borderColor: 'yellow.300',
  },
};
