import React from 'react';
import { FeaturesProvider } from 'utils/featureFlags';
import { useFeatureFlags } from 'hooks';

export const FeatureFlagsProvider = (props) => {
  const { children } = props;
  const featureFlags = useFeatureFlags();

  return (
    <FeaturesProvider value={featureFlags}>
      {children}
    </FeaturesProvider>
  );
};
