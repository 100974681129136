import { useForm } from 'react-hook-form';
import { t, l } from 'i18n';
import {
  useReduxMutator,
  useReduxQuery,
} from 'utils/redux-query';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { useModalDisclosure } from 'hooks';
import { useEffect } from 'react';
import isNil from 'utils/isNil';
import { mapPurposesApi } from '../../../../../getLists';
import { resolver } from '../resolver';
import { getAutoinvestContract } from '../getAutoinvestContract';

const createCreateAutoinvestError = ({ setError, setValue }) => (error) => {
  if (error?.response?.status === 422) {
    setError('otp', { message: t('Forms.fields.errors.invalidOTP_Token') });
    setValue('otp', '');
  }
};

export const useController = ({
  onSuccess = () => {},
}) => {
  const {
    watch,
    trigger,
    control,
    setValue,
    setError,
    formState,
    handleSubmit,
  } = useForm({
    resolver,
    defaultValues: {
      otp: '',
      accept: null,
    },
  });
  const [session] = useReduxQuery('SESSION');
  const [profile] = useReduxQuery('PROFILE');
  const [autoinvestConfigs] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [currentSecondFactor, { isCompleted: is2FAComplete }] = useReduxQuery('SECOND_FACTOR');
  const [createAutoinvest, { isLoading }] = useReduxMutator('CREATE_AUTO_INV', {
    onSuccess,
    onError: createCreateAutoinvestError({ setError, setValue }),
  });
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useModalDisclosure();
  const { errors } = formState;
  const autoinvestConfig = autoinvestConfigs?.[0];
  const username = session?.user?.fullName;
  const telephone = profile?.cellPhoneNumber;
  const contractDocument = getAutoinvestContract({
    fullname: username,
    signedAt: l('date.formats.contractDate', new Date()),
  });
  const acceptValue = watch('accept');
  const { otpFieldProps } = useAuthorizeWithOTP({
    telephone,
    is2FAComplete,
    currentSecondFactor,
    isRequestOTPDisabled: !acceptValue,
  });

  const onConfirmLendingCriteria = async () => {
    onModalOpen();
    await new Promise((resolve) => setTimeout(resolve, 100));
    document
      .getElementById('contractForm')
      .scrollIntoView({ behavior: 'smooth' });
  };

  const onSubmit = handleSubmit(({ otp }) => {
    createAutoinvest({
      otp,
      autoinvestConfig: {
        active: true,
        rates: autoinvestConfig.rates,
        months: autoinvestConfig.months,
        purposes: mapPurposesApi(autoinvestConfig.purposes),
        amount: autoinvestConfig.amount,
        contract: contractDocument,
      },
    });
  });

  useEffect(() => {
    if (isNil(acceptValue)) return;
    if (!errors.otp) return;
    trigger('otp');
  }, [acceptValue, trigger]);

  return {
    control,
    username,
    onSubmit,
    isLoading,
    otpFieldProps,
    contractDocument,
    isModalOpen,
    onModalOpen,
    onModalClose,
    onConfirmLendingCriteria,
  };
};
