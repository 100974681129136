import React from 'react';
import { tscope } from 'i18n';
import {
  TextField,
  SelectField,
} from 'components/FormFields';
import {
  AuthorizeWithOTPField,
} from 'components/AuthorizeWithOTPField';
import {
  Text,
  Grid,
  Modal,
  Button,
  Divider,
  Skeleton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Box,
} from 'ui';
import { useController } from './hooks';
import { styles as s } from './AddBankAccount.style';

const ts = tscope('components.AddBankAccountForm');
const OTPFieldT = tscope('components.AddBankAccountForm.formFields.token');

export const AddBankAccountForm = ({
  isOpen,
  onClose,
  bankAccountId,
}) => {
  const {
    clabe,
    control,
    onSubmit,
    isEditing,
    isLoading,
    fieldProps,
    bankOptions,
    otpFieldProps,
    isSubmitDisabled,
  } = useController({
    isOpen,
    onClose,
    bankAccountId,
  });

  return (
    <Modal
      {...s.modal}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader {...s.headerModal}>
          <Skeleton {...s.headerSkeleton} isLoaded={!isLoading}>
            {isEditing ? ts('editingTitle') : ts('title')}
          </Skeleton>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody {...s.bodyModal}>
          <Grid
            {...s.form}
            as="form"
            onSubmit={onSubmit}
          >
            <TextField
              {...s.field}
              name="alias"
              labelText={ts('formFields.alias.label')}
              placeholder={ts('formFields.alias.placeholder')}
              control={control}
              isDisabled={isLoading}
              parseType="alphanumeric"
              maxLength={50}
            />
            <Box {...s.field}>
              <TextField
                {...s.field}
                name="clabe"
                type="number"
                maxLength={18}
                control={control}
                labelText={ts('formFields.clabe.label')}
                placeholder={ts('formFields.clabe.placeholder')}
                {...fieldProps.clabe}
              />
              {isEditing && (
                <Text {...s.field} fontSize="sm" color="yellow.600">
                  {ts('formFields.clabe.cannotEdit')}
                </Text>
              )}
            </Box>
            <SelectField
              {...s.field}
              name="bank"
              clabe={clabe}
              control={control}
              labelText={ts('formFields.bank.label')}
              placeholder={ts('formFields.bank.placeholder')}
              {...fieldProps.bank}
            >
              {bankOptions?.map(({ id, name }) => (
                <option key={id} value={name}>{name}</option>
              ))}
            </SelectField>
            <Divider {...s.divider} />
            <AuthorizeWithOTPField
              {...s.tokenField}
              {...otpFieldProps}
              size="sm"
              name="token"
              t={OTPFieldT}
              control={control}
              isDisabled={isLoading}
              data-testid="tokenField"
            />

            <Button
              {...s.cancelButton}
              onClick={onClose}
            >
              {ts('actions.cancel')}
            </Button>
            <Button
              {...s.submitButton}
              isDisabled={isSubmitDisabled}
            >
              {ts('actions.submit')}
            </Button>
          </Grid>
        </ModalBody>
        <ModalFooter {...s.footerModal}>
          <Text {...s.addAccountDisclaimer}>
            {ts('addAccountDisclaimer')}
          </Text>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
