import { createAsyncAction } from 'utils/redux-query';
import confirmProfileAPI from 'apis/investor/registers/confirm';
import { updateProfileAction } from '../profile';
import { fetchPersonalArchives } from '../personalArchives';

export const confirmProfile = createAsyncAction(
  'EXPEDIENTS_PROFILE_CONFIRM',
  ({ newProfile, password }) => async (dispatch) => {
    const result = await confirmProfileAPI({ newProfile, password });
    await fetchPersonalArchives()(dispatch);
    const { profile } = result;
    dispatch(updateProfileAction(profile, { status: 'COMPLETED', error: null }));
    return profile;
  },
);
