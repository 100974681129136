import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18n';
import PATHS from 'routes';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { LIMIT_LEVELS } from 'config/constants';
import { useReduxQuery } from 'utils/redux-query';
import { Button } from 'ui';
import {
  TransactionsLimitsCardContent,
  TransactionsLimitsCardBody,
  TransactionsLimitsCardFooter,
  Title,
  MontlyAmountText,
  DescriptionText,
  LimitsPageLink,
  AmountText,
  TotalText,
  AmountsRow,
  ProgressBar,
} from './TransactionsLimitsCard.style';

const {
  investorSettingsLimitsPath,
  investorSettingsLimitsLevelOneUpdateAddressPath,
  investorSettingsLimitsLevelOneUpdateIdentificationPath,
} = PATHS;

function TransactionsLimitsCard(props) {
  const [session] = useReduxQuery('SESSION');
  const { limits } = session;
  const {
    currentLevel,
    currentLimit,
    maxLimits,
    requirementsNextLevel,
    transactionsAmountOnMonth,
    transactionsLeftAmount,
    transactionsPercentage,
  } = limits;
  const isLevelOne = currentLevel === LIMIT_LEVELS.LEVEL_ONE;
  const isLevelTwo = currentLevel === LIMIT_LEVELS.LEVEL_TWO;
  const type = transactionsLeftAmount <= 0 ? 'danger' : 'success';
  const displayLeftAmount = toCurrencyWithDecimals(
    transactionsLeftAmount <= 0 ? 0 : transactionsLeftAmount,
  );

  const redirectToLimitsProcess = {
    [LIMIT_LEVELS.LEVEL_ONE]: requirementsNextLevel?.address
      ? investorSettingsLimitsLevelOneUpdateIdentificationPath()
      : investorSettingsLimitsLevelOneUpdateAddressPath(),
    [LIMIT_LEVELS.LEVEL_TWO]: investorSettingsLimitsPath(),
  };

  return (
    <TransactionsLimitsCardContent {...props}>
      <TransactionsLimitsCardBody>
        <Title as="h2">{t('addFunds.limits.operationLimit')}</Title>
        <MontlyAmountText>
          {toCurrencyWithDecimals(currentLimit.maxOperationLimit)}
        </MontlyAmountText>
        <DescriptionText>
          {isLevelOne && (
            <>
              {t('addFunds.limits.descriptionLevelOne.part1')}
              {' '}
              <b>{toCurrencyWithDecimals(maxLimits.levelTwo.maxOperationLimit)}</b>
              {' '}
              {t('addFunds.limits.descriptionLevelOne.part2')}
            </>
          )}
          {isLevelTwo && t('addFunds.limits.descriptionLevelTwo')}
        </DescriptionText>
        <Button colorScheme="green" as={Link} to={redirectToLimitsProcess[currentLevel]} mt="2">
          {t('addFunds.limits.levelup')}
        </Button>
        <LimitsPageLink as={Link} $type="link" $size="small" to={investorSettingsLimitsPath()}>
          {t('addFunds.limits.seeLimits')}
        </LimitsPageLink>
      </TransactionsLimitsCardBody>
      <TransactionsLimitsCardFooter>
        <AmountsRow>
          <AmountText>{t('addFunds.limits.currentAmount')}</AmountText>
          <AmountText $align="right">{t('addFunds.limits.leftAmount')}</AmountText>
        </AmountsRow>
        <AmountsRow>
          <TotalText>
            <b>{toCurrencyWithDecimals(transactionsAmountOnMonth)}</b>
          </TotalText>
          <TotalText $type={type}>
            <b>{displayLeftAmount}</b>
          </TotalText>
        </AmountsRow>
        <ProgressBar progress={transactionsPercentage} type={type} />
      </TransactionsLimitsCardFooter>
    </TransactionsLimitsCardContent>
  );
}

export default TransactionsLimitsCard;
