import isNil from './isNil';

export function stringToBoolean(str) {
  if ([true, false].includes(str)) return str;

  const booleans = { true: true, false: false };
  const foundValue = booleans[(str || '').toLowerCase()];

  return isNil(foundValue) ? str : foundValue;
}

export function capitalize(str) {
  if (!str) return '';

  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
}

export function capitalizeText(text) {
  if (!text) return '';
  return text.split(' ').map(capitalize).join(' ');
}

export const removeWhiteSpaces = (str) => str.replace(/\s/g, '');

export const filterOnlyNumbers = (value) => value.replace(/\D/g, '');

export const filterAlphabeticChars = (string) => string.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ´\s.]/g, '');

export const filterStrangeChars = (string) => string.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ´\s.0-9]/g, '');

export const singleWhiteSpace = (string) => string.replace(/\s{2,}/g, ' ');

export const hasTrailingWhitespace = (string) => !/^(?!^\s|.*\s$)\S.*\S$/.test(string);

export const toFloatNumber = (value) => {
  const result = String(value ?? '')
    .trim()
    .replace(/[^0-9.]/g, '');
  return Number(result) ?? null;
};
