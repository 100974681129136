import React from 'react';
import { useTransition, animated, useSpring } from 'react-spring';
import { ReactComponent as OkIcon } from 'assets/icons/roundedCheck.svg';
import { TERMS_LIST } from '../../constants/termsList';
import ConditionsAcceptedContainer from './ConditionsAccepted.style';

export const ConditionsAccepted = ({ stepCounter }) => {
  const getAnimationHeight = (step) => ({ opacity: 1, height: 55 * step });

  const heightAnimation = useSpring({
    to: async (next) => next(getAnimationHeight(stepCounter)),
    from: { opacity: 0, height: 0 },
  });

  const displayItemAnimation = useTransition(
    stepCounter > 0 ? TERMS_LIST.slice(0, stepCounter) : [],
    (item) => item.id,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
    },
  );

  return stepCounter === 0 ? null : (
    <ConditionsAcceptedContainer>
      <animated.div style={heightAnimation}>
        {displayItemAnimation.map(({ item, props, key }) => (
          <animated.div
            style={props}
            key={key}
            className="item"
            data-testid="conditions-accepted-item"
          >
            <OkIcon title="" />
            {item.title}
          </animated.div>
        ))}
      </animated.div>
    </ConditionsAcceptedContainer>
  );
};
