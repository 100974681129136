import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const ListDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;

export const RequisitionsListContainer = styled(ListDiv)`
  margin-left: 27rem;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const TourStep = styled.div`
  p {
    margin-right: 1rem;
    font-size: 1.6rem;
  }

  button {
    margin-top: 1rem;
  }
`;

export const LoadingRequistions = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    height: 300px;
  }
`;

export const StepStyle = { fontSize: '1.6rem' };
