import { t } from 'i18n';
import { useInterval } from '../../../utils';

export const tl = (key, options) => t(key, { scope: 'components.ExpiringSessionToast', ...options });

export const useController = ({ toastTime, onSessionExpire }) => {
  const { humanize } = useInterval({
    initialTime: toastTime,
    interval: 1000,
    onFinish: onSessionExpire,
  });

  const description = tl('expiresIn', { count: humanize.seconds });
  const btnText = tl('renew');

  return {
    description,
    btnText,
  };
};
