import { l } from 'i18n';
import { useState } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { handlePrint } from 'utils/window/print';
import { getAutoinvestContract } from 'views/DashboardContent/Settings/MainArea/AutoInvest/components/EditAutoinvestForm/components/CreateAutoinvestForm/getAutoinvestContract';

export const useController = ({
  handleClose,
  selectedContract,
}) => {
  const [session] = useReduxQuery('SESSION');
  const [, { isLoading: isAILoading }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const { user } = session || {};
  const { name = '', contractData } = selectedContract || {};
  const isAutoinvest = name === 'Autoinvest';
  const data = contractData || {};
  const autoinvestContract = getAutoinvestContract({
    fullname: user?.fullName,
    signedAt: l('date.formats.contractDate', data?.activated_at),
  });
  const html = isAutoinvest ? autoinvestContract : data.document;

  const onRemoveContractSuccess = () => {
    handleClose();
    setShowPasswordConfirmation(false);
  };

  const onPrint = () => {
    handlePrint({ htmlContent: html });
  };

  return {
    name,
    data,
    html,
    onPrint,
    isAILoading,
    isAutoinvest,
    onRemoveContractSuccess,
    showPasswordConfirmation,
    setShowPasswordConfirmation,
  };
};
