import styled, { css } from 'styled-components';
import {
  Body as TBody,
  Cell as TCell,
  Head as THead,
  Row as TRow,
  Header as THeader,
} from 'components/Table/Basic';
import { ResponsiveHelpers } from 'styles';
import { breakPoints } from 'config/constants';
import Button from 'components/Button/Button';
import { Text as DefaultText } from 'components/Typography';

export const LoandsHistoryContainer = styled.div`
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    margin-top: 3rem;
  }
`;

export const Text = styled(DefaultText)`
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;

export const GoToDetailLink = styled(Button)`
  text-align: center;
  display: inline-block;
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    padding: 0.7rem 0.5rem;
  }
  ${({ isRedirect }) => (isRedirect
    ? css`
          pointer-events: none;
          cursor: not-allowed;
        `
    : '')};
`;

export const LoansHistoryTable = styled.div`
  margin-top: 2.2rem;
  margin-bottom: 8.8rem;
`;

const Body = styled(TBody)`
  align-items: center;
`;

const Row = styled(TRow)`
  place-items: center;
  grid-template-columns: repeat(7, 1fr);
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const Cell = styled(TCell)`
  font-size: 1.4rem;
  ${ResponsiveHelpers}
`;

const Head = styled(THead)`
  ${ResponsiveHelpers}

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: repeat(5, 1fr);
    font-size: 1.2rem;
  }
`;

const Header = styled(THeader)`
  grid-template-columns: repeat(7, 1fr);
  ${ResponsiveHelpers}
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const Table = {
  Body, Header, Cell, Head, Row,
};
