import React from 'react';
import { ReactComponent as AlertLimitsIcon } from 'assets/icons/alertLimits.svg';
import Link from 'components/Link';
import { Text } from 'components/Typography';
import { Flex } from 'styles';
import { t } from 'i18n';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { useReduxQuery } from 'utils/redux-query';
import { ReactComponent as CloseModalIcon } from 'assets/icons/closeModal.svg';
import PATHS from 'routes';
import { firstLevelWarnsKeys } from 'config/constants';
import {
  LevelUpModalContainer,
  WelcomeText,
  BodyText,
  Box,
  LevelUpButton,
  ProgressBar,
  CloseButton,
} from './LevelUpModal.style';

const { investorSettingsLimitsPath } = PATHS;

function BodyForLimitAbove50(props) {
  const { accountValue, warnLevel } = props;

  return (
    <>
      <BodyText>
        {t('settings.limits.announce.accountValue')}
        {' '}
        <Text as="strong" color="#FF8E0A">
          {t('settings.limits.announce.overComeLimit', { limit: 50 })}
          {' '}
        </Text>
        {t('settings.limits.announce.levelUp1')}
      </BodyText>
      <Text mb="1rem">
        {t('settings.limits.limit')}
        :
        {' '}
        <Text as="strong">{toCurrencyWithDecimals(warnLevel.maxAccountValueLimit)}</Text>
      </Text>
      <Box>
        <ProgressBar progress={warnLevel.accountPercentage} type="warning" />
        <Text align="center" mt="1rem">
          {t('settings.limits.announce.accountValue2')}
          :
          {' '}
          <Text as="strong" color="#FF8E0A">
            {toCurrencyWithDecimals(accountValue)}
          </Text>
        </Text>
      </Box>
    </>
  );
}

function BodyForLimitAbove60(props) {
  const { warnLevel } = props;

  return (
    <>
      <BodyText>
        {t('settings.limits.announce.operationLimit')}
        {' '}
        <Text as="strong" color="#FF8E0A">
          {t('settings.limits.announce.overComeLimit', { limit: 60 })}
        </Text>
        {t('settings.limits.announce.levelUp2')}
      </BodyText>
      <Box>
        <Flex justifyContent="space-between">
          <Text as="strong">{toCurrencyWithDecimals(warnLevel.transactionsAmountOnMonth)}</Text>
          <Text as="strong" color="#FF8E0A">
            {toCurrencyWithDecimals(warnLevel.transactionsLeftAmount)}
          </Text>
        </Flex>
        <ProgressBar progress={warnLevel.transactionsPercentage} type="warning" />
        <Flex justifyContent="space-between">
          <Text size="1.2rem">{t('settings.limits.announce.fundedAmount')}</Text>
          <Text size="1.2rem">{t('settings.limits.announce.leftAmount')}</Text>
        </Flex>
      </Box>
    </>
  );
}

function BodyForLimitAbove90(props) {
  const { warnLevel } = props;
  const percentageRange = warnLevel.level === firstLevelWarnsKeys
    .EXCEEDED_TRANSACTIONAL_LIMIT ? 100 : 90;

  return (
    <>
      <BodyText>
        {t('settings.limits.announce.operationLimit')}
        {' '}
        <Text as="strong" color="#FF2135">
          {t('settings.limits.announce.overComeLimit', { limit: percentageRange })}
        </Text>
        {t('settings.limits.announce.levelUp2')}
      </BodyText>
      <Box>
        <Flex justifyContent="space-between" mb="1rem">
          <Text as="strong">{toCurrencyWithDecimals(warnLevel.transactionsAmountOnMonth)}</Text>
          {warnLevel.transactionsLeftAmount > 0 ? (
            <Text as="strong" color="#FF2135">
              {toCurrencyWithDecimals(warnLevel.transactionsLeftAmount)}
            </Text>
          ) : (
            <Text as="strong" color="#FF2135">
              {toCurrencyWithDecimals(0)}
            </Text>
          )}
        </Flex>
        <ProgressBar progress={warnLevel.transactionsPercentage} type="danger" />
        <Flex justifyContent="space-between">
          <Text size="1.2rem">{t('settings.limits.announce.fundedAmount')}</Text>
          <Text size="1.2rem">{t('settings.limits.announce.leftAmount')}</Text>
        </Flex>
      </Box>
    </>
  );
}

function BodyForLimitAbove100(props) {
  const { accountValue, warnLevel } = props;

  return (
    <>
      <BodyText>
        {t('settings.limits.announce.accountValue')}
        {' '}
        <Text as="strong" color="#FF2135">
          {t('settings.limits.announce.overComeLimit_100')}
          {' '}
        </Text>
        {t('settings.limits.announce.levelUp3')}
      </BodyText>
      <Text mb="2rem">
        {t('settings.limits.limit')}
        :
        {' '}
        <Text as="strong">{toCurrencyWithDecimals(warnLevel.maxAccountValueLimit)}</Text>
      </Text>
      <Box>
        <ProgressBar progress={warnLevel.accountPercentage} type="danger" />
        <Text align="center" mt="1rem">
          {t('settings.limits.announce.accountValue2')}
          :
          {' '}
          <Text as="strong" color="#FF2135">
            {toCurrencyWithDecimals(accountValue)}
          </Text>
        </Text>
      </Box>
      <Text as="strong" mb="2rem" color="#FF2135" textAlign="center">
        {t('settings.limits.announce.onlyWithdrawal')}
      </Text>
    </>
  );
}

function LevelUpModal(props) {
  const { accountValue, onClose, warnLevel } = props;
  const [session] = useReduxQuery('SESSION');
  const name = session?.user?.fullName?.split(' ')[0] ?? '';

  return (
    <LevelUpModalContainer onRequestClose={onClose} {...props}>
      <CloseButton onClick={onClose}>
        <CloseModalIcon />
      </CloseButton>
      <AlertLimitsIcon />
      <WelcomeText>{t('common.Banners.welcome', { name })}</WelcomeText>
      {warnLevel?.level === firstLevelWarnsKeys.ACCOUNT_VALUE_ON_FIFTY_PERCENT && (
        <BodyForLimitAbove50 warnLevel={warnLevel} accountValue={accountValue} />
      )}
      {warnLevel?.level === firstLevelWarnsKeys.TRANSACTIONS_ON_SIXTY_PERCENT && (
        <BodyForLimitAbove60 warnLevel={warnLevel} accountValue={accountValue} />
      )}
      {[
        firstLevelWarnsKeys.TRANSACTIONS_ON_NINETY_PERCENT,
        firstLevelWarnsKeys.EXCEEDED_TRANSACTIONAL_LIMIT,
      ].includes(warnLevel?.level) && (
        <BodyForLimitAbove90 warnLevel={warnLevel} accountValue={accountValue} />
      )}
      {warnLevel?.level === firstLevelWarnsKeys.OVERFUNDED && (
        <BodyForLimitAbove100 warnLevel={warnLevel} accountValue={accountValue} />
      )}
      <LevelUpButton to={investorSettingsLimitsPath()} as={Link} onClick={onClose}>
        {t('settings.limits.upgrade')}
      </LevelUpButton>
    </LevelUpModalContainer>
  );
}

export default LevelUpModal;
