import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import Box from 'components/Box';
import Button from 'components/Button/Button';
import { ReactComponent as CopyIcon } from 'assets/group17.svg';

export const BankAccountContent = styled.div``;

export const BankAccountCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 5rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 2rem;
  }
`;

export const PrintInstructionsButton = styled(Button)`
  padding-left: 3rem;
  padding-right: 3rem;

  @media print {
    display: none;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 100%;
  }
`;

export const CopyButton = styled(Button)`
  padding-left: 2rem;
  padding-right: 2rem;

  @media print {
    display: none;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 0;
    border: 1rem;
  }
`;

export const CopyButtonText = styled.span`
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    display: none;
  }
`;

export const CopyButtonIcon = styled(CopyIcon)`
  display: none;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    display: block;
  }
`;

export const Field = styled.div`
  color: ${colors.gray.g5};
  font-size: 1.4rem;
  line-height: 2rem;
  border-bottom: ${({ $noBorderBottom }) => ($noBorderBottom ? '' : `1px solid ${colors.gray.g12}`)};
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 3rem;
`;

export const FieldLabel = styled.label`
  color: ${colors.gray.g5};
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const FieldLabelNote = styled.span`
  color: ${colors.gray.g5};
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const FieldText = styled.p`
  color: ${({ $primary }) => ($primary ? colors.green : colors.gray.g7)};
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
`;

export const FieldHelperText = styled.p`
  color: ${colors.gray.g4};
  font-size: 1.1rem;
  line-height: 1.4rem;
`;

export const ContactSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-column-gap: 2rem;
  padding: 3rem 5rem;
  max-width: 536px;
  color: ${colors.gray.g7};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 0;
  }
`;
