import { useForm } from 'react-hook-form';
import { t } from 'i18n';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { resolver } from '../resolver';

const createOnRemoveError = ({ setValue, setError }) => async (error) => {
  if (error.response.status !== 403) return;
  setValue('password', '');
  await new Promise((resolve) => setTimeout(resolve, 1));
  setError('password', {
    message: t('Forms.fields.errors.invalidPassword'),
  }, { shouldFocus: true });
};

export const useController = ({
  onSuccess = () => {},
}) => {
  const {
    control,
    setError,
    setValue,
    handleSubmit,
  } = useForm({
    resolver,
    defaultValues: {
      password: '',
    },
  });
  const [
    autoinvest,
    { isLoading: isContractLoading },
  ] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [
    destroyAIConfig,
    { isLoading: isRemoveContractLoading },
  ] = useReduxMutator('DESTROY_AUTO_INV', {
    onError: createOnRemoveError({ setValue, setError }),
    onSuccess,
  });
  const isLoading = isContractLoading || isRemoveContractLoading;

  const onSubmit = handleSubmit(async ({ password }) => {
    setValue('password', '');
    const [{ id: autoinvestId }] = autoinvest;
    if (!autoinvestId) return;
    await destroyAIConfig({ autoinvestId, password });
  });

  return {
    control,
    onSubmit,
    isLoading,
  };
};
