import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from 'views/DashboardContent/FooterDashboard';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { getCartList } from 'actions/cart/cartActions';
import { calculateRemainingPercentage } from 'utils/numberFormatters';
import { toggleDetails } from 'actions/dashboard';
import DotsLoader from 'components/DotsLoader';
import Badge from 'components/Badge';
import PATHS from 'routes';
import { Feature } from 'utils/featureFlags';
import DiversificationDescriptions from 'components/DiversificationDescriptions';
import { getListIcon } from '../../../icons';
import { getSortArrow } from '../utils';
import MobileListContainer, {
  Grade,
  ProgressBar,
  ListItem,
  Button,
  ContainerBadge,
} from './styles';
import RequisitionSorter from '../Utils/RequisitionSorter';

const { investorRequisitionPath } = PATHS;

const MobileList = (props) => {
  const {
    requisitions,
    cartItems,
    getCartItems,
    highlightRequisition,
    highlighted,
    handleSortChange,
    sortOptions,
    loadingRequisitions,
  } = props;

  useEffect(() => {
    getCartItems();
  }, []);

  const renderList = () => {
    if (!requisitions) return false;
    const sortedRequisitions = RequisitionSorter(requisitions, sortOptions);

    return sortedRequisitions.map((option) => {
      const inCart = cartItems.find((item) => item.requisition_id === option.id);
      const funded = option.funded_amount > 0;
      const handleHighlight = () => highlightRequisition(option);

      return (
        <ListItem
          currentHighlighted={highlighted}
          highlighted={highlighted === parseInt(option.id, 10)}
          funded={funded}
          inCart={inCart}
          className="list__item"
          key={option.id}
        >
          <div className="list__item__content">
            <Link
              to={investorRequisitionPath({ requisitionId: option.zell_app_id })}
              className="content__data"
            >
              <ContainerBadge>
                {option.number_of_credits > 1 && (
                  <Badge value={option.number_of_credits} id={option.zell_app_id} hasTooltip />
                )}
                {option.zapopan && <span className="li badge">AZ!</span>}
              </ContainerBadge>
              <div className="li id">
                <span>{option.zell_app_id}</span>
              </div>
              <div className="li score">
                <Grade grade={option.qualification}>
                  <span className="qualification">{option.qualification}</span>
                  <span>
                    {option.rate}
                    %
                  </span>
                </Grade>
              </div>
              <div className="li destiny">{getListIcon(option.destination)}</div>
              <div className="li amount">
                $
                {option.approved_amount / 1000}
                k
              </div>
              <div className="li time-frame">{option.term}</div>
            </Link>
            <Button
              highlighted={highlighted === parseInt(option.id, 10)}
              className="content__options thirdStep"
              onClick={handleHighlight}
              type="button"
            >
              {highlighted === option.id ? (
                <div>
                  <span className="closeIcon" />
                  <p>Cerrar</p>
                </div>
              ) : (
                <div>
                  <CartIcon title="" />
                  <p>Prestar</p>
                </div>
              )}
            </Button>
          </div>
          <div className="progress-bar">
            <ProgressBar
              className="progress-bar__bar"
              percentage={calculateRemainingPercentage(
                option.loan_detail.missing_amount,
                option.approved_amount,
              )}
            />
          </div>
        </ListItem>
      );
    });
  };

  return (
    <MobileListContainer className="firstStep secondStep">
      <div className="list__header">
        <div className="header__item">ID</div>
        <div className="header__item">
          <button type="button" onClick={() => handleSortChange('qualification')}>
            Cal/Tasa
            {getSortArrow('qualification', sortOptions.type, sortOptions.sortOrder)}
          </button>
        </div>
        <div className="header__item">
          <button type="button" onClick={() => handleSortChange('destination')}>
            Destino
            {' '}
            {getSortArrow('destination', sortOptions.type, sortOptions.sortOrder)}
          </button>
        </div>
        <div className="header__item">
          <button type="button" onClick={() => handleSortChange('approved_amount')}>
            Monto
            {' '}
            {getSortArrow('approved_amount', sortOptions.type, sortOptions.sortOrder)}
          </button>
        </div>
        <div className="header__item">
          <button type="button" onClick={() => handleSortChange('term')}>
            Plazo
            {' '}
            {getSortArrow('term', sortOptions.type, sortOptions.sortOrder)}
          </button>
        </div>
        <div className="header__item" />
      </div>
      <div className="list__body firstStep secondStep">
        {!loadingRequisitions && requisitions ? (
          renderList()
        ) : (
          <div className="loader">
            <DotsLoader black />
          </div>
        )}
        {!loadingRequisitions && (
          <Feature name="diversification">
            <div className="list__diversification-text">
              <DiversificationDescriptions />
            </div>
          </Feature>
        )}
      </div>
      <Footer />
    </MobileListContainer>
  );
};

const mapStateToProps = ({ cart, dashboard }) => {
  const { cartItems } = cart;
  const { displayDetail } = dashboard;
  return {
    cartItems,
    displayDetailState: displayDetail,
  };
};

export default connect(mapStateToProps, {
  getCartItems: getCartList,
  onToggleDetails: toggleDetails,
})(MobileList);
