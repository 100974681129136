import {
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { l } from 'i18n';
import useQuery from 'hooks/useQuery';
import {
  useUser,
} from './graphql';
import { useRequestAccountStatementForm } from './useRequestAccountStatementForm';
import { useRequestLastAccountStatement } from './useRequestLastAccountStatement';

const createOptionsForSelectField = (statementAccounts = []) => statementAccounts
  .map(({ id, periodStart }) => ({
    key: id,
    value: id,
    label: l('date.formats.short', periodStart),
  }));

const useAutoSelectMonth = ({
  setValue,
  lastAccountStatement,
}) => {
  const inputRef = useRef(null);
  const query = useQuery();
  const monthParam = query.get('month');

  useEffect(() => {
    if (!monthParam || !lastAccountStatement) return;
    setValue('month', lastAccountStatement);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  }, [monthParam, lastAccountStatement]);
  return {
    inputRef,
  };
};

export const useController = () => {
  const { data, loading: isLoadingUser } = useUser();
  const { accountStatements, flags } = data?.user || {};
  const { isAccountStatementAvailable } = flags || {};
  const optionsForSelectField = useMemo(
    () => createOptionsForSelectField(accountStatements),
    [accountStatements],
  );
  const lastAccountStatement = optionsForSelectField?.[0]?.value;
  const {
    control,
    onSubmit,
    setValue,
    isLoading: isFormLoading,
  } = useRequestAccountStatementForm();
  const {
    inputRef,
  } = useAutoSelectMonth({
    setValue,
    lastAccountStatement,
  });
  const {
    isAvailableToRequest,
    requestLastAccountStatement,
    isLoading: isGenerateLoading,
  } = useRequestLastAccountStatement({
    isAccountStatementAvailable,
  });

  const isLoading = isLoadingUser || isGenerateLoading || isFormLoading;

  const onGenerateAccountStatement = () => {
    requestLastAccountStatement();
  };

  return {
    control,
    onSubmit,
    isLoading,
    isAvailableToRequest,
    optionsForSelectField,
    onGenerateAccountStatement,
    passwordFieldRef: inputRef,
  };
};
