import React, { useEffect, useMemo } from 'react';
import {
  Switch, Route, useRouteMatch, Redirect,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useReduxQuery } from 'utils/redux-query';
import { selectTab } from 'actions/dashboard';
import { Grid, Flex } from 'styles';
import DotsLoader from 'components/DotsLoader';
import { dashboardRoute } from 'routes';
import AsideMenu from './AsideMenu/AsideMenu';
import Profile from './Profile/Profile';
import Deposits from './Deposits/Deposits';
import Occupation from './Occupation/Occupation';
import RiskCerticate from './RiskCertificate/RiskCerticate';
import Address from './Address/Address';
import Identification from './Identification/Identification';
import Banking from './Banking/Banking';
import Footer from '../FooterDashboard';

const aliasPathMap = (url) => ({
  personalInformation: `${url}/profile/show`,
  kycActivities: `${url}/deposits/create`,
  ocupation: `${url}/occupation/create`,
  cer: `${url}/risk_cer`,
  id: `${url}/identification/update`,
  address: `${url}/address`,
  bankAccount: `${url}/banking/show`,
});

function Expedients2() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [personalArchives, { status: personalArchivesStatus }] = useReduxQuery('PERSONAL_ARCHIVES');

  const fallbackPath = useMemo(() => {
    if (!personalArchives || !match) return null;

    if (personalArchives.nextRequirement) {
      return aliasPathMap(match.url)[personalArchives.nextRequirement];
    }

    return dashboardRoute;
  }, [match, personalArchives]);

  useEffect(() => {
    dispatch(selectTab({ title: 'Actualizar' }));
  }, []);

  if (personalArchivesStatus.completed && personalArchives === null) {
    return <Redirect to={dashboardRoute} />;
  }

  return (
    <Grid
      template={{ base: '6.6rem 1fr / 1fr', sm: '1fr / 25rem auto' }}
      w="100%"
    >
      <AsideMenu gridRow={{ base: '1 / -1', sm: 'auto' }} />
      <Flex
        direction="column"
        pt="4rem"
        alignItems="center"
        justifySelf="center"
        gridRow={{ base: '2 / -1', sm: 'auto' }}
        overflow="scroll"
        maxHeight="calc(100vh - 5.6rem)"
        placeSelf="stretch"
      >
        {personalArchivesStatus.pending && personalArchives === null && (
          <Flex centered flexGrow="1">
            <DotsLoader color="schema.gray.300" />
          </Flex>
        )}
        {personalArchivesStatus.completed && (
          <Switch>
            {personalArchives?.personalInformation === false && (
              <Route path={`${match.url}/profile`} component={Profile} />
            )}
            {personalArchives?.kycActivities === false && (
              <Route path={`${match.url}/deposits`} component={Deposits} />
            )}
            {personalArchives?.ocupation === false && (
              <Route path={`${match.url}/occupation`} component={Occupation} />
            )}
            {personalArchives?.cer === false && (
              <Route path={`${match.url}/risk_cer`} component={RiskCerticate} />
            )}
            {personalArchives?.id === false && (
              <Route path={`${match.url}/identification`} component={Identification} />
            )}
            {personalArchives?.address === false && (
              <Route path={`${match.url}/address`} component={Address} />
            )}
            {personalArchives?.bankAccount === false && (
              <Route path={`${match.url}/banking`} component={Banking} />
            )}
            {fallbackPath && <Redirect to={fallbackPath} />}
          </Switch>
        )}
        <Footer flexGrow="0" alignSelf="flex-end" mt="auto" w="100%" />
      </Flex>
    </Grid>
  );
}

export default Expedients2;
