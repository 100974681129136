import React from 'react';
import { useController } from './hooks';
import { BlockedAlert, NeedsUpdateAlert } from './components';

export const NeedsUpdateInfo = () => {
  const {
    name,
    closeAlert,
    alertIsOpen,
    needsUpdateInformation,
    isUserPartiallyBlocked,
  } = useController();

  if (isUserPartiallyBlocked
    && needsUpdateInformation) {
    return (
      <BlockedAlert
        name={name}
        closeAlert={closeAlert}
        alertIsOpen={alertIsOpen}
      />
    );
  }

  if (needsUpdateInformation) {
    return (
      <NeedsUpdateAlert
        name={name}
        closeAlert={closeAlert}
        alertIsOpen={alertIsOpen}
      />
    );
  }

  return null;
};
