import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { ReactComponent as CartIcon } from 'assets/carIcon.svg';
import { toCurrency } from 'i18n';
import isNil from 'utils/isNil';
import FilterButton from '../../../../FilterButton';
import InfoPillContainer from './styles';

const InfoPil = (props) => {
  const {
    onToggleFilter, mobile, cartTotal = 0, large,
  } = props;
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const availableFunds = statements?.saldoDisponible;

  return (
    <InfoPillContainer mobile={mobile} large={large}>
      {large && (
        <div className="filterButton">
          <FilterButton onClick={onToggleFilter}>Filtrar</FilterButton>
        </div>
      )}
      <div className="infoPill__body">
        <div className="infoPill__body__available">
          Disponible:
          {' '}
          {availableFunds ? toCurrency(availableFunds) : '...'}
        </div>
        {!mobile && (
          <div className="infoPill__body__total">
            <CartIcon title="" />
            {' '}
            Total:
            {' '}
            {isNil(cartTotal) ? '...' : toCurrency(cartTotal, { precision: 0 })}
          </div>
        )}
      </div>
    </InfoPillContainer>
  );
};

export default InfoPil;
