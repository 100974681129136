import snakeify from 'snakecase-keys';
import camelify from 'camelcase-keys-recursive';
import { request } from './fetchers/yotepresto';

const parseProfileData = ({ data }) => {
  let profile = camelify(data.profile);
  profile = {
    ...profile,
    country: profile.originCountry,
  };

  return { profile };
};

export const fetchProfile = async () => {
  const path = '/v2/investor/registers';
  const method = 'get';

  const { data } = await request({
    method,
    path,
  });

  const profile = parseProfileData({ data });
  return profile;
};

export const updateExpedientProfile = async ({ profile }) => {
  const path = '/v2/profiles/kyc_activities';
  const method = 'put';
  const body = {
    profile: snakeify(profile),
  };
  const { data } = await request({
    method,
    path,
    body,
  });
  return data;
};

export const updateProfile = async ({ profile, profileCompleted }) => {
  const path = '/v2/investor/registers';
  const method = 'put';
  const body = {
    profile: snakeify(profile),
    profile_completed: profileCompleted,
  };

  const { data } = await request({
    method,
    path,
    body,
  });

  return parseProfileData({ data });
};

export const updateProfileAddress = async (address) => {
  const payload = {
    profile: {
      originCountry: address.originCountry,
      addressAttributes: address,
    },
  };
  const method = 'put';
  const path = '/v2/profiles/addresses';
  const body = snakeify(payload);

  const { data } = await request({ method, path, body });

  return camelify(data);
};

export const createProfileAddress = async (address) => {
  const method = 'post';
  const path = '/v1/profile/address';
  const body = snakeify({ address });

  const { data } = await request({ method, path, body });

  return camelify(data);
};

export const getProfileAddress = async () => {
  const method = 'get';
  const path = '/v1/profile/address';

  const { data } = await request({ method, path });

  return data ? camelify(data) : null;
};

export const updateUserSettings = (key, value) => {
  const method = 'put';
  const path = '/v1/user_settings';
  const body = snakeify({
    user: {
      [key]: value,
    },
  });

  return request({ method, path, body });
};

export default updateUserSettings;
