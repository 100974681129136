import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const ConfirmProfileInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  .loader {
    margin-top: 50%;
  }
  .content {
    max-width: 40rem;
  }
  .title {
    font-size: 2.3rem;
    color: ${colors.gray.g5};
    margin-bottom: 1rem;
    line-height: 28px;
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      text-align: center;
    }
  }
  .card {
    background-color: ${colors.gray.g0};
  }
  .info {
    &--message {
      background-color: ${colors.gray.g1};
      display: flex;
      flex-direction: row;
      padding: 2rem 1rem;
      margin-bottom: 3rem;
      p {
        color: ${colors.gray.g41};
        font-size: 1.4rem;
      }
    }
    &--svg {
      padding: 0 1.5rem;
    }
  }
  .confirmation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.8rem;
    &--input {
      margin-right: 1rem;
    }
    p {
      color: ${colors.gray.g41};
      font-size: 1.2rem;
    }
  }
`;

export default ConfirmProfileInformationContainer;
