import {
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { WS_EVENT_NAMES } from 'config/constants';
import { createActions, useReduxSubscription } from 'utils/redux-query';
import { parseEventMessage } from 'utils/parseWSEventMessages';
import { parseLimitAmounts } from 'actions/requisitions/requisitons';

const { update: updateRequisitionDiversification } = createActions('REQUISITIONS_DIVERSIFICATION');

export const useBindUpdatingDiversification = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [currentLimitAmountList] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');

  const callback = useCallback((message) => {
    const { data: limitAmountList } = parseEventMessage(message);
    const payload = parseLimitAmounts({
      ...(currentLimitAmountList || {}),
      ...limitAmountList,
    });
    const meta = { status: 'COMPLETED', error: null };
    dispatch(updateRequisitionDiversification(payload, meta));
  }, [
    dispatch,
    currentLimitAmountList,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.DIVERSIFICATION_UPDATING,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
