import React from 'react';
import { t } from 'i18n';
import {
  Box,
  Text,
  Flex,
  Button,
  Container,
} from 'ui';
import DotsLoader from 'components/DotsLoader';
import { PanelSecondFactorCTA } from './components/PanelSecondFactorCTA';
import { useController } from './hooks/useController';
import { style } from './SecondFactor.style';

export const SecondFactorSection = (props) => {
  const { setOpenModal } = props;
  const {
    loading,
    secondFactor,
    hasNoAccounts,
    handleOpenModal,
  } = useController({ setOpenModal });

  if (loading) return (<DotsLoader />);

  return (
    <Container {...style.outerContainer}>
      {
        secondFactor && (
          <Flex {...style.container}>
            {
              hasNoAccounts && (
                <Box
                  {...style.hasNoAccounts}
                >
                  <Text>
                    {t('settings.bankAccounts.emptyState')}
                  </Text>
                </Box>
              )
            }
            <Button
              onClick={handleOpenModal}
              {...style.button}
            >
              {t('settings.bankAccounts.add.button')}
            </Button>
          </Flex>
        )
      }
      {
        !secondFactor && (
          <Container
            {...style.secondFactor}
            title={t('settings.bankAccounts.title')}
          >
            <Text>
              {t('settings.bankAccounts.secondFactor.required')}
            </Text>
            <PanelSecondFactorCTA />
          </Container>
        )
      }
    </Container>
  );
};
