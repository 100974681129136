import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import FooterComponent from 'views/DashboardContent/FooterDashboard';

const { smallBreakPoint } = breakPoints;

export const Footer = styled(FooterComponent)`
  flex-grow: unset;
  margin-top: auto;
  width: 100%;
`;

const StepsCERContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  width: 100%;

  @media (max-width: ${smallBreakPoint}px) {
    margin-top: 4rem;
  }
  h1 {
    font-size: 3rem;
    font-weight: bold;
    padding-bottom: 3.5rem;
    color: black;
    @media (max-width: ${smallBreakPoint}px) {
      font-size: 2rem;
    }
  }
  .message_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
      margin-top: 2.7rem;
      font-size: 1.6rem;
    }
    .card {
      max-width: 56.7rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media (max-width: ${smallBreakPoint}px) {
        width: 90%;
        padding: 2rem 1rem;
      }
      svg {
        flex: 1;
      }
      p {
        flex: 2;
        font-size: 1.6rem;
        line-height: 2.4rem;
        @media (max-width: ${smallBreakPoint}px) {
          font-size: 1.4rem;
          line-height: 2.2rem;
        }
      }
    }
    button {
      margin-top: 1.5rem;
      padding: 1.8rem 3rem;
    }
  }
`;

export default StepsCERContainer;
