import { css } from 'styled-components';
import get from 'lodash.get';
import { breakPoints } from 'config/constants';
import colors from './colors';
import * as animations from './core/animations';
import { propToRule } from './core/propToRule';

export const extractProp = (name) => (props) => props[name];
const getColorFromSchema = (color) => get(colors.schema, color?.replace('schema.', ''), color);

export const backgroundHelpers = css`
  ${propToRule('background', ['bg', 'background'], getColorFromSchema)}
  ${propToRule('background-color', ['bgColor', 'backgroundColor'], getColorFromSchema)}
  ${propToRule('background-image', ['bgImage', 'backgroundImage'])}
  ${propToRule('background-position', ['bgPosition', 'backgroundPosition'])}
  ${propToRule('background-repeat', ['bgRepeat', 'backgroundRepeat'])}
  ${propToRule('background-size', ['bgSize', 'backgroundSize'])}
`;

export const marginHelpers = css`
  ${propToRule('margin-top', ['my', 'mt'])}
  ${propToRule('margin-bottom', ['my', 'mb'])}
  ${propToRule('margin-left', ['mx', 'ml'])}
  ${propToRule('margin-right', ['mx', 'mr'])}
  ${propToRule('margin', ['margin', 'm'])}
`;

export const paddingHelpers = css`
  ${propToRule('padding-top', ['py', 'pt'])}
  ${propToRule('padding-bottom', ['py', 'pb'])}
  ${propToRule('padding-left', ['px', 'pl'])}
  ${propToRule('padding-right', ['px', 'pr'])}
  ${propToRule('padding', ['padding', 'p'])}
`;

export const borderHelpers = css`
  ${propToRule('border', ['b', 'border'])}
  ${propToRule('border-top', ['bt', 'borderTop'])}
  ${propToRule('border-left', ['bl', 'borderLeft'])}
  ${propToRule('border-bottom', ['bb', 'borderBottom'])}
  ${propToRule('border-right', ['br', 'borderRight'])}
  ${propToRule('border-top-width', ['btw', 'borderTopWidth'])}
  ${propToRule('border-bottom-width', ['bbw', 'borderBottomWidth'])}
  ${propToRule('border-right-width', ['brw', 'borderRightWidth'])}
  ${propToRule('border-left-width', ['blw', 'borderLeftWidth'])}
  ${propToRule('border-width', 'borderWidth')}
  ${propToRule('box-shadow', 'boxShadow')}
  ${propToRule('border-color', 'borderColor', getColorFromSchema)}
`;

export const borderRadiix = css`
  ${propToRule('border-radius', 'borderRadius')}
  ${propToRule('border-top-left-radius', 'borderTopLeftRadius')}
  ${propToRule('border-top-right-radius', 'borderTopRightRadius')}
  ${propToRule('border-bottom-left-radius', 'borderBottomLeftRadius')}
  ${propToRule('border-bottom-right-radius', 'borderBottomRightRadius')}
`;

export const animateWithHelper = css`
  ${({ animateWith }) => animations[animateWith]}
`;

export const fluidHelpers = css`
  ${propToRule('display', 'display')}
  ${propToRule('width', ['w', 'width'])}
  ${propToRule('height', ['h', 'height'])}
  ${propToRule('max-width', ['maxw', 'maxWidth'])}
  ${propToRule('min-width', ['minw', 'minWidth'])}
  ${propToRule('max-height', ['maxh', 'maxHeight'])}
  ${propToRule('min-height', ['minh', 'minHeight'])}
  ${propToRule('overflow', 'overflow')}
  ${propToRule('overflow-x', 'overflowX')}
  ${propToRule('overflow-y', 'overflowY')}
  ${propToRule('hidden', 'hidden')}
  ${propToRule('opacity', 'opacity')}
`;

export const positionHelpers = css`
  ${propToRule('position', 'position')}
  ${propToRule('top', 'top')}
  ${propToRule('bottom', 'bottom')}
  ${propToRule('left', 'left')}
  ${propToRule('right', 'right')}
  ${propToRule('grid-template', 'template')};
  ${propToRule('grid-template-columns', 'templateColumns')}
  ${propToRule('grid-template-rows', 'templateRows')}
  ${propToRule('justify-content', 'justifyContent')}
  ${propToRule('justify-self', 'justifySelf')}
  ${propToRule('justify-items', 'justifyItems')}
  ${propToRule('align-items', 'alignItems')}
  ${propToRule('align-self', 'alignSelf')}
  ${propToRule('place-items', 'placeItems')}
  ${propToRule('place-self', 'placeSelf')}
  ${propToRule('column-gap', 'columnGap')}
  ${propToRule('row-gap', 'rowGap')}
  ${propToRule('gap', 'gap')}
  ${propToRule('grid-area', 'gridArea')}
  ${propToRule('grid-column', 'gridColumn')}
  ${propToRule('grid-row', 'gridRow')}
  ${propToRule('place-items', 'placerItems')}
  ${propToRule('flex-grow', ['flexGrow', 'grow'])}
  ${propToRule('flex-shrink', ['flexShrink', 'shrink'])}
  ${propToRule('flex-basis', ['flexBasis', 'basis'])}
  ${propToRule('flex-wrap', 'flexWrap')}
  ${propToRule('flex-direction', ['direction', 'flexDirection'])}
  ${propToRule('order', 'order')}
  ${propToRule('z-index', 'zIndex')}
`;

export const transitions = css`
  ${propToRule('transition', 'transition')}
`;

export const listHelpers = css`
  ${propToRule('list-style-type', 'listStyleType')}
`;

export const extraHelpers = css`
  ${propToRule('cursor', 'cursor')}
  ${propToRule('pointer-events', 'pointerEvents')}
  ${propToRule('visibility', 'visibility')}
`;

export const boxHelpers = css`
  ${backgroundHelpers}
  ${positionHelpers}
  ${borderHelpers}
  ${marginHelpers}
  ${paddingHelpers}
  ${fluidHelpers}
  ${borderRadiix}
  ${listHelpers}
  ${transitions}
  ${animateWithHelper}
  ${extraHelpers}
`;

export const textHelper = css`
  ${boxHelpers}
  ${propToRule('font-size', ['size', 'fontSize'])}
  ${propToRule('line-height', 'lineHeight')}
  ${propToRule('text-decoration', 'textDecoration')}
  ${propToRule('letter-spacing', 'letterSpacing')}
  ${propToRule('text-overflow', 'textOverflow')}
  ${propToRule('overflow-wrap', 'overflowWrap')}
  ${propToRule('font-weight', 'fontWeight')}
  ${propToRule('color', 'color', getColorFromSchema)}
  ${propToRule('text-align', ['align', 'textAlign'])}
  ${propToRule('vertical-align', 'verticalAlign')}
  ${propToRule('line-height', 'lineHeight')}
  ${propToRule('white-space', 'whiteSpace')}
  ${propToRule('text-transform', ['transform', 'textTransform'])}
`;

/**
 * DEPRECATED helpers
 * Don't use anymore!
 */

export const ResponsiveHelpers = css`
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    ${(props) => extractProp('isHiddenMobile')(props)
      && css`
        display: none;
      `}
  }

  @media (min-width: ${breakPoints.midBreakPoint}px) {
    ${(props) => extractProp('isOnlyMobile')(props)
      && css`
        display: none;
      `}
  }

  @media (max-width: ${breakPoints.smallBreakPoint - 1}px) {
    display: ${(props) => (extractProp('isHiddenOnSmall')(props) ? 'none' : '')};
  }
`;
