import { useReduxQuery } from 'utils/redux-query';
import { getDestinationsList, getMonthsList, getQualificationList } from '../../../getLists';

export const useController = () => {
  const [autoinvestConfigs, { isLoading }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const currentAutoinvestConfig = autoinvestConfigs?.[0] || null;

  const gradeList = getQualificationList(currentAutoinvestConfig?.rates || []);
  const TermList = getMonthsList(currentAutoinvestConfig?.months || []);
  const purposeList = getDestinationsList(currentAutoinvestConfig?.purposes || []);
  const amount = currentAutoinvestConfig?.amount || null;

  return {
    amount,
    isLoading,
    gradeList,
    TermList,
    purposeList,
  };
};
