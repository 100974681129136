import { useCallback, useMemo } from 'react';
import { tscope, l } from 'i18n';
import { defaultDateStringToISO } from 'utils/Date/Calendar';
import { showToastSuccess } from 'utils/toastHandler';
import { relationshipTypes } from 'catalog/relationship';
import { useBeneficiaryQuery, useBeneficiaryMutation, useCatalog } from './graphql';

const t = tscope('settings.account.beneficiaryForm');
const relationshipList = relationshipTypes
  .reduce((list, relationshipType) => [...list, relationshipType.value], []);
const mapRelationshipTypeKeys = (relationType) => ({
  sibiling: 'brotherly',
  couple: 'spouse',
  progenitor: '',
}[relationType] || relationType);
const parseRelationshipType = (relationshipType) => {
  const relationshipTypeKey = mapRelationshipTypeKeys(relationshipType);
  const isValidRelationshipType = relationshipList.includes(relationshipTypeKey);
  return isValidRelationshipType ? relationshipTypeKey : '';
};
const parseBirthDate = (date) => (date
  ? l('date.formats.medium', date)
  : '');

export const useBeneficiaryForm = ({
  onBeneficiaryUpdated,
}) => {
  const { data: catalogData } = useCatalog();
  const states = catalogData?.catalogs?.states;
  const onMutateBeneficiaryCompleted = useCallback(() => {
    showToastSuccess(t('alerts.success.upsertBeneficiary'));
    onBeneficiaryUpdated();
  }, []);
  const [mutateBeneficiary, { loading: isUpdatingBeneficiary }] = useBeneficiaryMutation({
    onCompleted: onMutateBeneficiaryCompleted,
  });
  const { data, loading: isLoadingBeneficiary } = useBeneficiaryQuery();
  const beneficiary = data?.user?.donee;
  const isLoading = isLoadingBeneficiary || isUpdatingBeneficiary;

  const resolveStateId = useCallback(
    ({ stateCode }) => states?.find(({ constId }) => constId === stateCode)?.id || '',
    [states],
  );

  const onSubmit = async (values) => {
    const {
      zip,
      birthDate,
      isForeignPerson,
      stateId: stateCode,
    } = values;
    const stateId = isForeignPerson ? '' : resolveStateId({ stateCode });
    const input = {
      donee: {
        fullName: values.name,
        birthDate: defaultDateStringToISO(birthDate),
        relationType: values.relationshipType,
        relationTypeOther: values.relationshipTypeOther || '',
        address: {
          street: values.street,
          intNumber: values.intNumber,
          extNumber: values.extNumber,
          city: values.city,
          zip: String(zip),
          stateId,
          municipality: values.municipality,
          neighborhood: values.neighborhood,
          country: isForeignPerson
            ? values.country
            : '',
        },
      },
    };

    await mutateBeneficiary({
      variables: {
        input,
      },
    });
  };

  const initialValues = useMemo(() => (beneficiary
    ? ({
      name: beneficiary.fullName,
      relationshipType: parseRelationshipType(beneficiary.relationType),
      relationshipTypeOther: beneficiary.relationTypeOther || '',
      isForeignPerson: beneficiary.address?.isForeign || false,
      birthDate: parseBirthDate(beneficiary.birthDate),
      // address fields
      zip: beneficiary.address?.zip,
      street: beneficiary.address?.street,
      intNumber: beneficiary.address?.intNumber,
      extNumber: beneficiary.address?.extNumber || '',
      neighborhood: beneficiary.address?.neighborhood,
      municipality: beneficiary.address?.municipality,
      city: beneficiary.address?.city || '',
      stateId: beneficiary.address?.state?.id || '',
      stateName: beneficiary.address?.state?.name || '',
      // foreign fields
      country: beneficiary.address?.country || '',
    })
    : ({
      name: '',
      relationshipType: '',
      relationTypeOther: '',
      isForeignPerson: false,
      birthDate: '',
      // address fields
      zip: '',
      street: '',
      intNumber: '',
      extNumber: '',
      neighborhood: '',
      municipality: '',
      city: '',
      stateName: '',
      // foreign fields
      country: '',
      countryName: '',
    })), [beneficiary]);

  return {
    onSubmit,
    isLoading,
    initialValues,
  };
};
