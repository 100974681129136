import React from 'react';
import {
  Box,
  Text,
  Card,
  Grid,
  Heading,
  Button,
  Image,
  Flex,
} from 'ui';
import autoinvestImg from 'assets/autoInvest.svg';
import riskGraphImg from 'assets/graph.jpg';
import customizeImg from 'assets/customize.svg';
import automaticImg from 'assets/automatic.svg';
import usersImg from 'assets/users.svg';
import { CheckboxField } from 'components/FormFields';
import { getAutoinvestTerms } from './AutoinvestTerms.html';
import { useController } from './hooks';

export const TermsAndConditions = () => {
  const {
    control,
    onSubmit,
  } = useController();

  return (
    <Grid padding="10" justifyItems="center" maxWidth="4xl" marginX="auto" gap="10">
      <Card padding="10" alignItems="center" gap="2">
        <Image src={autoinvestImg} alt="Configurar Autoinvest" />
        <Text marginBottom="5">Autoinvest</Text>
        <Heading as="h4" fontSize="3lg" fontWeight="medium">¡Felicidades!</Heading>
        <Text textAlign="center">
          Gracias a tu excelente desempeño como inversionista,
          ahora puedes utilizar Autoinvest, funcionalidad con
          la que podrás
          {' '}
          <b>automatizar tus préstamos</b>
          {' '}
          de acuerdo a
          tus preferencias de inversión.
        </Text>
      </Card>

      <Box>
        <Heading as="h3" fontSize="2xl" textAlign="center" marginBottom="5">
          Beneficios
        </Heading>
        <Card
          gap="5"
          padding="10"
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Flex alignItems="center" flexDirection="column">
            <Image src={customizeImg} boxSize="20" />
            <Text textAlign="center" marginBottom="5" marginTop="2">
              Tú decides las tasas, plazos, destinos y el monto de los
              préstamos en los que quieres invertir automáticamente.
            </Text>
          </Flex>
          <Flex alignItems="center" flexDirection="column">
            <Image src={automaticImg} boxSize="20" />
            <Text textAlign="center" marginBottom="5" marginTop="2">
              No tendrás que entrar a la plataforma para buscar e
              invertir manualmente en los préstamos que te resulten más
              atractivos.
            </Text>
          </Flex>
          <Flex alignItems="center" flexDirection="column">
            <Image src={usersImg} boxSize="20" />
            <Text textAlign="center" marginBottom="5" marginTop="2">
              Ya no perderás la oportunidad de prestar en los créditos
              que se ajusten a tus preferencias de inversión.
            </Text>
          </Flex>
        </Card>
      </Box>

      <Box width="full">
        <Heading as="h3" fontSize="2xl" textAlign="center" marginBottom="5">
          Términos y Condiciones para las Inversiones Automáticas
          <br />
          “Autoinvest”
        </Heading>
        <Box
          width="100%"
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          bgColor="gray.100"
          padding="6"
          height="xl"
          marginBottom="5"
        >
          <iframe
            height="100%"
            width="100%"
            title="Gráfico de riesgos de Autoinvest"
            srcDoc={getAutoinvestTerms({ riskImgUrl: riskGraphImg })}
            frameBorder="0"
          />
        </Box>
        <Grid as="form" onSubmit={onSubmit} gap="5" width="full">
          <CheckboxField control={control} name="accept">
            Confirmo que he leído y acepto los términos y condiciones de Autoinvest
          </CheckboxField>
          <Button type="submit" justifySelf="end">
            Empezar a configurar
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};
