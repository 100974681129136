import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';
import { ReactComponent as OrdersItemIconSVG } from 'assets/orders/menu_icon.svg';
import { ReactComponent as ConfigIcon } from 'assets/Config.svg';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: white;
  z-index: 550;
  @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
    width: calc(100% - 7rem);
    padding-right: 1.5rem;
  }
  @media (min-width: ${breakPoints.midBreakPoint}px) {
    padding-left: 20rem;
    padding-right: 1.5rem;
  }
  align-self: flex-end;
  min-height: 5.6rem;
  border-bottom: 1px solid rgba(219, 223, 227, 0.3);
  & .header__button__group {
    display: flex;
    height: 100%;
  }
  & .mobile__logo {
    max-width: 16rem;
    margin-left: 1rem;
  }
`;

export const HeaderTitle = styled.h1`
  font-weight: 400;
  color: #374d5b;
  font-size: 1.92rem;
  margin-left: 1.6rem;
  padding-bottom: 0.8rem;
`;

export const ElementGroup = styled.div`
  display: flex;
  width: 25rem;
  height: 100%;
`;

export const OrderItemIcon = styled(OrdersItemIconSVG)`
  ${({ disabled }) => (disabled
    ? css`
          & * {
            stroke: ${colors.gray.g2} !important;
          }
        `
    : '')}
`;

export const SettingsIcon = styled(ConfigIcon)`
  margin-right: 0.5rem;

  ${({ disabled }) => (disabled
    ? css`
          & * {
            stroke: ${colors.gray.g2} !important;
          }
        `
    : '')}
`;

export const HeaderButton = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  border-left: 1px solid ${colors.gray.g2};
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  outline: none;
  height: ${(props) => (props.isMoblie ? '5.2rem' : '100%')};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${colors.gray.g4};
  position: relative;
  cursor: pointer;
  transition: all ease 0.4s;
  flex: 1;
  padding: 1.4rem 1.6rem;
  height: 100%;
  ${(props) => (props.disabled
    ? css`
          cursor: not-allowed;
        `
    : '')}
  ${(props) => props.menu
    && css`
      font-size: 2rem;
      line-height: 1.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
    `}
  ${(props) => props.$totalNotificationsBadge > 0
    && css`
      &::after {
        content: '${props.$totalNotificationsBadge}';
        font-size: 1rem;
        padding: 0.1rem 0.4rem;
        position: absolute;
        font-weight: 700;
        min-width: 1.5rem;
        height: 1.5rem;
        top: 8px;
        right: 8px;
        border-radius: 5rem;
        background-color: ${colors.blue};
        color: white;
        animation-name: notification;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-delay: 0;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-fill-mode: none;
        animation-play-state: running;
      }
    `}

  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    svg {
      margin-right: 0.8em;
    }
    &:visited {
      color: inherit;
    }
  }

  &:hover {
    background-color: ${colors.gray.g0};
    color: ${colors.blue};
    ${(props) => props.invertColorSvgOnHover
    && css`
        svg * {
          fill: ${colors.blue};
        }
      `}
  }

  &:focus {
    outline: none;
  }

  background-color: ${(props) => (props.dropdownOpen ? '#f2f5f7' : 'transparent')};
  /* padding: ${(props) => (props.isMobile ? '1.2rem' : '1.4rem 1.6rem')}; */
`;

export const DropdownElement = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 4rem;
  color: ${colors.gray.g6};
  font-size: 1.4rem;
  font-weight: 500;
  border-bottom: 0.1rem solid ${colors.gray.g2};
  transition: all ease 0.4s;

  &:hover {
    background-color: ${colors.gray.g1};
  }
  & a {
    padding: 0 1.2rem;
    justify-content: flex-start;
    text-decoration: none;
    color: inherit;
    width: 100%;
    min-height: 4rem;
    &:hover {
      color: ${colors.blue};
    }
  }
  & .end__session {
    padding: 0 1.2rem;
    justify-content: flex-start;
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
    min-height: 4rem;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const DropdownLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #012e47;
`;

export const OrdersItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;
