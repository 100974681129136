import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

async function destroy({ autoinvestId, password }) {
  const path = `/v1/investor/portfolio_auto_investments/${autoinvestId}/cancel`;
  const method = 'post';
  const extraHeaders = { password };

  const { data } = await request({ method, path, extraHeaders });

  return data ? camelify(data) : null;
}

export default destroy;
