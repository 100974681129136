function getGoogleTagManagerTracker() {
  const init = (config) => {
    const { id } = config;
    /* eslint-disable */
    // STARTS code provided by Google Tag Manager
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', id);
    // ENDS code provided by Google Tag Manager
    /* eslint-enable */
  };

  const addContext = (context) => {
    const { dataLayer } = window;
    if (!dataLayer) return;
    dataLayer.push(context);
  };

  return {
    init,
    addContext,
  };
}

export default getGoogleTagManagerTracker();
