export default {
  header: {
    mb: '1.5rem',
    gap: '1rem',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '2rem',
  },
  numberText: {
    h: '2rem',
    w: '2rem',
    fontSize: '1.2rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
    borderRadius: '50%',
    textAlign: 'center',
    color: 'schema.gray.700',
    bgColor: 'schema.gray.300',
  },
};
