import React, {
  forwardRef, useCallback, useImperativeHandle, useRef,
} from 'react';
import { t } from 'i18n';
import { Grid } from 'styles';
import Link from 'components/Link';
import TextField from 'components/TextField/TextField';
import { useField } from 'react-final-form';
import debounce from 'utils/Monads/debounce';

const parseRFCField = (v) => v
  ?.slice(0, 10)
  ?.toUpperCase()
  ?.match(/[a-zA-Z0-9]/g)
  ?.join('') || '';

const parseRFCHomoClaveField = (rfc, homoClave) => `${rfc}${
  homoClave
    ?.slice(0, 3)
    ?.toUpperCase()
    ?.match(/[a-zA-Z0-9]/g)
    ?.join('') || ''
}`;

function RFCField(props, forwardedRef) {
  const { textLabel, onBlur, name = 'rfc' } = props;
  const ref = useRef();
  const {
    input: { value: rfc },
  } = useField(name);

  const triggerBlur = useCallback(() => {
    if ([ref.current].includes(document.activeElement)) return;
    onBlur();
  }, [onBlur]);

  const triggerBlurDebounced = useCallback(debounce(triggerBlur, 100), [triggerBlur]);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      ref.current.focus();
    },
  }));

  return (
    <Grid templateColumns="15rem 7rem" columnGap="1rem" rowGap="0.5rem">
      <TextField
        gridColumn="1/2"
        color="schema.gray.700"
        textLabel={textLabel}
        name={name}
        readOnly
        parse={parseRFCField}
        format={(rfcInput) => rfcInput?.slice(0, 10)}
        inputProps={{ px: '0.5rem' }}
      />
      <TextField
        textLabel=" "
        gridColumn="2/2"
        color="schema.gray.700"
        name={name}
        onBlur={triggerBlurDebounced}
        parse={(homoClave) => parseRFCHomoClaveField(rfc?.slice(0, 10), homoClave)}
        format={(rfcInput) => rfcInput?.slice(10, 13)}
        inputProps={{ px: '0.5rem' }}
        shouldDisplayTextHelper={false}
        ref={ref}
      />
      <Link
        as="a"
        href={t('Forms.customFields.RFCField.getRFCLink')}
        target="blank"
        gridColumn="1 / -1"
      >
        {t('Forms.customFields.RFCField.getRFC')}
      </Link>
    </Grid>
  );
}

export default forwardRef(RFCField);
