import axios from 'axios';
import get from 'lodash.get';
import { CrashReporter } from 'utils';

const buildYoteprestoFetcher = ({
  store,
  apiUrl,
  handleSuccessfulResponse,
  handleFailedResponse,
}) => {
  const defaultHeaders = {
    'X-Client-Platform': 'web',
  };

  const getHeaders = (extraHeaders) => {
    const { password, ...otherHeaders } = extraHeaders;
    const headers = {
      ...otherHeaders,
      ...defaultHeaders,
    };
    if (password) headers.password = encodeURIComponent(password);

    const state = store.getState();
    const {
      date,
      connection,
      'transfer-encoding': transferEncoding,
      ...authHeaders
    } = get(state, 'auth.credentials') || {};

    return {
      ...headers,
      ...authHeaders,
    };
  };

  const request = async ({
    path,
    body,
    params,
    method,
    log = false,
    extraHeaders = {},
    skipStatusHandle = [],
    ...opt
  }) => {
    const headers = getHeaders(extraHeaders);
    const url = `${apiUrl}${path}`;
    const options = {
      method,
      url,
      headers,
      data: body,
      params: params || {},
      ...opt,
    };

    try {
      const response = await axios(options);
      return handleSuccessfulResponse(response);
    } catch (error) {
      const status = get(error, 'response.status');
      if (log) {
        const message = `Request failed (Endpoint: ${method.toUpperCase()} ${path}, Status: ${status})`;
        const context = {
          message,
          method,
          params,
          path,
          body,
          status,
          response: error?.response,
        };
        CrashReporter.setContext(context);
      }
      if (skipStatusHandle.includes(status)) throw error;
      return handleFailedResponse(error, skipStatusHandle);
    }
  };

  return {
    request,
  };
};

export default buildYoteprestoFetcher;
