import { Flex } from 'styles';

export const styles = {
  header: {
    as: Flex,
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: { base: 'flex-start', md: 'center' },
    direction: { base: 'column', xs: 'row' },
  },
  title: {
    as: 'h3',
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    color: 'schema.gray.500',
  },
  badge: {
    size: 'md',
    variant: 'semi-solid',
    ml: { base: 'auto', xs: 'unset' },
  },
  actionsHeader: {
    gap: '1rem',
    ml: { base: '0', xs: 'auto' },
  },
  edit: {
    px: '1rem',
    schema: 'blue',
    $size: 'small',
    $type: 'outline',
    whiteSpace: 'nowrap',
    w: { base: '100%', xs: 'auto' },
  },
  enable: {
    px: '1rem',
    schema: 'blue',
    $size: 'small',
    $type: 'outline',
    whiteSpace: 'nowrap',
    w: { base: '100%', xs: 'auto' },
  },
  invoicingError: {
    container: {
      p: '1rem 0 2rem 0',
      m: '1rem 0 2rem 0',
      borderBottom: '1px solid',
      borderColor: 'schema.gray.200',
      alignItems: 'center',
    },
    text: {
      color: 'schema.red.500',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      p: '0 0 0 1rem',
    },
  },
};
