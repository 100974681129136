import React from 'react';
import {
  PasswordAuthorizeField,
} from 'components/PasswordAuthorizeField';
import {
  Flex,
  Box,
  Grid,
  Card,
  Button,
  Heading,
  CardBody,
  CardHeader,
  CardFooter,
  Text,
} from 'ui';
import {
  DisplayAutoinvestConfig,
} from '../DisplayAutoinvestConfig';
import { useController } from './hooks';

export const PauseAutoinvestForm = ({
  onCancel,
  onSuccess,
  ...props
}) => {
  const {
    control,
    onSubmit,
    isLoading,
    isAutoinvestActive,
  } = useController({ onSuccess });

  return (
    <Card size="md" {...props}>
      <CardHeader>
        <Heading fontSize="lg">
          {isAutoinvestActive
            ? 'Confirma la pausa de tu Autoinvest'
            : 'Activar Autoinvest'}
        </Heading>
      </CardHeader>
      <CardBody as={Grid} gap="2">
        <Heading
          as="h4"
          fontWeight="medium"
          fontSize="md"
          textAlign="center"
        >
          Criterios de préstamo
        </Heading>
        <DisplayAutoinvestConfig />
      </CardBody>
      <CardFooter as={Grid} gap="5">
        <Box as="form" onSubmit={onSubmit}>
          <PasswordAuthorizeField
            size="md"
            name="password"
            control={control}
            labelText="Contraseña"
            isDisabled={isLoading}
          />
          <Flex marginTop="5" justifyContent="space-between">
            <Button
              variant="outline"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              isDisabled={isLoading}
            >
              {isAutoinvestActive ? 'Pausar' : 'Activar'}
            </Button>
          </Flex>
          <Text textAlign="center" marginTop="2" fontWeight="500">
            {isAutoinvestActive
              ? 'Tu autoinvest esta activo, lo puedes pausar cuando tú quieras'
              : 'Tu autoinvest esta pausado, lo puedes activar cuando tú quieras'}
          </Text>
        </Box>
      </CardFooter>
    </Card>
  );
};
