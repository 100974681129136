import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';
import { parseSession } from './parser';

const validateToken = async () => {
  const method = 'get';
  const path = '/auth/validate_token';
  const config = { method, path };
  const { data } = await request(config);
  const session = camelify(data || {}).data || {};

  return parseSession(session);
};

export default validateToken;
