export default {
  container: {
    hideImageSection: true,
    pt: '2rem',
    innerContentProps: {
      px: '2rem',
    },
  },
  innerContent: {
    justifyContent: 'center',
  },
};
