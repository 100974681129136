import { useCallback } from 'react';
import { messages, REQUIRED_PASSWORD_LENGTH } from 'config/constants';
import simpleMemoize from 'utils/Monads/simpleMemoize';
import { showToastError } from 'utils/toastHandler';
import { usePasswordMutation } from './graphql/useValidatePassword';
import { ts } from '../components/PasswordValidations/constants';

const { required } = messages;

export const useFormValidator = () => {
  const onValidatePasswordError = () => {
    showToastError(ts('alerts.failedValidatePassword'));
  };
  const [validatePasswordTrigger] = usePasswordMutation({
    onError: onValidatePasswordError,
  });

  const validatePassword = simpleMemoize(async (password) => {
    if (!password) return null;
    const result = await validatePasswordTrigger({
      variables: { input: { password } },
    });
    return result?.data?.validatePassword?.errors || null;
  });

  const validatePasswordForm = useCallback(async (values) => {
    const errors = {};
    if ((values.currentPassword || '').length < REQUIRED_PASSWORD_LENGTH) {
      errors.currentPassword = ts('fields.currentPassword.errors.length', {
        length: REQUIRED_PASSWORD_LENGTH,
      });
    }
    if (values.newPassword !== values.newPasswordConfirmation) errors.newPasswordConfirmation = ts('fields.currentPassword.errors.notMatch');
    if (!values.currentPassword) errors.currentPassword = required;
    if (!values.newPassword) errors.newPassword = required;
    if (!values.newPasswordConfirmation) errors.newPasswordConfirmation = required;

    const shouldValidateAsync = [
      values.newPassword,
      !errors.currentPassword,
      !errors.newPassword,
    ].every(Boolean);

    try {
      if (shouldValidateAsync) {
        errors.conditionPasswordTypes = await validatePassword(values.newPassword);
      }
    } catch (e) {
      errors.newPassword = ts('fields.conditionPasswordTypes.errors.failedAsyncValidation');
    }

    return errors;
  }, []);

  const validate = useCallback(
    validatePasswordForm,
    [validatePasswordForm],
  );

  return {
    validate,
  };
};
