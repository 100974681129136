import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const COUNTRY_CATALOG = gql`
query Catalogs {
  catalogs {
    id
    countries {
      id
      name
    }
  }
}
`;

export const useCountryCatalog = (options = {}) => useQuery(
  COUNTRY_CATALOG,
  {
    context: {
      clientName: 'public',
    },
    ...options,
  },
);
