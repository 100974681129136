import { styled } from 'styles';

export const ContractWrapper = styled.div`
      overflow-x: auto;
      max-height: 30rem;
      padding: 2rem;

      .heading {
        font-size: 1.6rem;
        color: #677d85;
        line-height: 180%;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.4rem;
        color: #677d85;
        line-height: 150%;
        margin-bottom: 1rem;
      }

      .list-container {
        padding-left: 1.3rem;
        margin-bottom: 1rem;

        li {
          line-height: 150%;
          font-size: 1.4rem;
          color: #677d85;
        }
      }

      .to-be-bold {
        font-weight: 500;
      }

      .electronic-signature {
        font-size: 1.4rem;
        color: #677d85;
        line-height: 120%;
        font-weight: 400;
      }
`;
