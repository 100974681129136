import React, { useState } from 'react';
import { t } from 'i18n-js';
import { Box, Flex, Grid } from 'styles';
import { Text } from 'components/Typography';
import BulletThread from 'components/BulletThread/BulletThread';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { maskEmailAddress } from 'utils/maskHandler';
import SecondFactorModalHeader from './components/SecondFactorModalHeader';
import EmailOTPForm from './components/EmailOTPForm';
import ConfirmChangeOTPForm from './components/ConfirmChangeOTPForm';
import SimpleQRCode from './components/SimpleQRCode';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.2FAInstructions.externalApp', ...options });

const type = 'authenticator_app';

const ExternalApp = (props) => {
  const { onConfirmChange } = props;
  const [, { data: qrUrlData, status: requestChangeStatus }] = useReduxMutator(
    'REQUEST_CHANGE_SECOND_FACTOR',
  );
  const [session] = useReduxQuery('SESSION');
  const [bulletStage, setBulletStage] = useState(['active', 'inactive']);

  const unmaskedEmail = session?.user?.email ?? '';
  const email = maskEmailAddress(unmaskedEmail);

  const showQR = requestChangeStatus.completed && qrUrlData?.response;
  const showConfirmChange = requestChangeStatus.completed && qrUrlData?.response;
  const QRurl = qrUrlData?.response;

  return (
    <Flex direction="column" bgColor="white" h="auto">
      <SecondFactorModalHeader imageName="EXTERNAL_SECOND_FACTOR" title={ts('header')} p="3rem" />
      <Grid columnGap="2rem" p="3rem" alignItems="start" rowGap="0.3rem">
        <BulletThread
          gridArea="1/1"
          gridColumn="1"
          last={false}
          status={bulletStage[0]}
          number="1"
        />
        <Box gridArea="1/2">
          <Text mb="1.5rem">
            {ts('instructions.0.0')}
            <Text as="b">{ts('instructions.0.1', { email })}</Text>
          </Text>
          <EmailOTPForm type={type} setBulletStage={setBulletStage} />
        </Box>
        <BulletThread status={bulletStage[1]} gridArea="2/1" last={false} number="2" />
        <Box gridArea="2/2">
          <Text mb="1.5rem">
            {ts('instructions.1.0')}
            <Text
              as="a"
              color="blue"
              textDecoration="none"
              size="small"
              href={`${ts('links.googleApp')}`}
              target="_blank"
              rel="noreferrer"
            >
              {ts('instructions.1.1')}
            </Text>
            {ts('instructions.1.2')}
            <Text
              as="a"
              color="blue"
              textDecoration="none"
              size="small"
              href={`${ts('links.authy')}`}
              target="_blank"
              rel="noreferrer"
            >
              {ts('instructions.1.3')}
            </Text>
          </Text>
        </Box>
        <BulletThread status={bulletStage[1]} gridArea="3/1" last={false} number="3" />
        <Box gridArea="3/2">
          <Text>{ts('instructions.2')}</Text>
          {showQR && (
            <SimpleQRCode show={showQR} authUrl={QRurl} />
          )}
        </Box>
        <BulletThread status={bulletStage[1]} gridArea="4/1" number="4" />
        <Box gridArea="4/2">
          <Text>{ts('instructions.3')}</Text>
          {showConfirmChange && (
            <ConfirmChangeOTPForm onConfirmChange={onConfirmChange} />
          )}
        </Box>
      </Grid>
    </Flex>
  );
};

export default ExternalApp;
