import { SET_VERIFICATION_STATUS, SET_VERIFICATION_STEP } from 'actions/identityVerification/types';

const INITIAL_STATE = {
  status: '',
  verificationStep: 2,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VERIFICATION_STATUS: {
      return { ...state, status: action.payload };
    }
    case SET_VERIFICATION_STEP: {
      return { ...state, verificationStep: action.payload };
    }
    default: {
      return state;
    }
  }
};
