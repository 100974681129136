import styled from 'styled-components';
import { colors } from 'styles';

const CountdownContainer = styled.div`
  font-size: 1.4rem;
  background-color: ${colors.gray.g4};
  color: white;
  text-align: center;
  border-radius: 0.4rem;
  padding: 1rem;
  width: 20rem;
`;

export default CountdownContainer;
