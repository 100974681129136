import camelify from 'camelcase-keys-recursive';
import { request } from '../../fetchers/yotepresto';

const generateOTP = async (type = 'sms') => {
  const config = {
    path: '/v2/second_factors/generate',
    method: 'get',
    params: {
      type,
    },
  };

  const { data } = await request(config);

  return camelify(data);
};

export default generateOTP;
