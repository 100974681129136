import { useEffect } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { isDevelopment } from 'config';
import { CrashReporter } from 'utils/crashReporter';

export const useCrashReporter = () => {
  const [session] = useReduxQuery('SESSION');
  const user = session?.user;

  useEffect(() => {
    if (isDevelopment()) return;
    CrashReporter.init();
  }, []);

  useEffect(() => {
    if (isDevelopment() || !user) return;
    CrashReporter.setUser({
      id: user.id,
      email: user.email,
    });
  }, [user]);
};
