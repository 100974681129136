import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseModalIcon } from 'assets/icons/closeModal.svg';

export const CloseButtonContent = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 20px;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: none;
  }
`;

export const CloseButton = (props) => (
  <CloseButtonContent {...props}>
    <CloseModalIcon />
  </CloseButtonContent>
);
