import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const RequisitionsContainer = styled.div`
  width: 100%;
  font-size: 1.2rem;
  height: calc(100vh - 56px);
  overscroll-behavior: contain;
  overflow-y: scroll;

  .detailSideBar {
    position: absolute;
    right: 0;
    top: 5.7rem;
    background-color: ${colors.gray.g1};
    @media (max-width: 700px) {
      top: 5.5rem;
    }
    &__Content {
      width: 100%;
      height: 100%;
    }
  }
  & + #footer {
    margin-left: 27rem;
    @media (max-width: ${breakPoints.largeBreakPoint}px) {
      margin-left: 0;
    }
  }
`;

export const RequisitionsMainContent = styled.div`
  margin-left: 25rem;
`;

export default RequisitionsContainer;
