import { css } from 'styled-components';
import { isChromeMobile, isSafari } from 'config/constants';
import store from 'store';

const getBreakpoints = () => {
  const { browser } = store.getState();
  const { is } = browser;
  return is;
};

const getResponsiveStyles = ({ mobileStyle, laptopStyle, defaultStyle }) => {
  const is = getBreakpoints();
  if (is.mobile) return mobileStyle;
  if (is.laptop) return laptopStyle;
  return defaultStyle;
};

const calcHeightContentOnBrowserType = (height) => {
  if (isChromeMobile) {
    return css`
      height: calc(${height}vh - 56px);
    `;
  }
  if (isSafari()) {
    return css`
      height: calc(${height}vh - 110px);
    `;
  }
  return css`
    height: ${height}vh;
  `;
};

export { getResponsiveStyles as default, getBreakpoints, calcHeightContentOnBrowserType };
