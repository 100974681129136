export const getQualificationList = (rates) => {
  if (!rates) return [];

  let count = 1;
  const resultList = [];
  const ratesStr = rates.join('');

  if (ratesStr.match(/A/g)) {
    resultList.push({ id: count, text: 'A1 - A7 (8.9% - 14.9%)' });
    count += 1;
  }
  if (ratesStr.match(/B/g)) {
    resultList.push({ id: count, text: 'B1 - B7 (15.9% - 21.9%)' });
    count += 1;
  }
  if (ratesStr.match(/C/g)) {
    resultList.push({ id: count, text: 'C1 - C7 (22.9% - 38.9%)' });
    count += 1;
  }
  return resultList;
};

export const getMonthsList = (months) => {
  if (!months) return [];

  let count = 1;
  const resultList = [];
  months.forEach((month) => {
    resultList.push({
      id: count,
      text: month,
    });
    count += 1;
  });
  return resultList;
};

export const getDestinationsList = (purposes) => {
  if (!purposes) return [];

  let count = 1;
  const resultList = [];
  const validParams = [
    'Vivienda',
    'Gastos Personales',
    'Gastos Familiares',
    'Automóvil',
    'Educación',
    'Negocio',
    'Otros',
    'pay_debts',
  ];

  validParams.forEach((param) => {
    if (purposes.join('').includes(param)) {
      resultList.push(
        param === 'pay_debts' ? { id: count, text: 'Pagar Deudas' } : { id: count, text: param },
      );
      count += 1;
    }
  });
  return resultList;
};

export const mapPurposes = (purposes) => purposes
  .map((purpose) => {
    switch (purpose) {
      case 'Vivienda':
        return 'house';
      case 'Gastos Personales':
        return 'personal';
      case 'Gastos Familiares':
        return 'family';
      case 'Automóvil':
        return 'car';
      case 'Educación':
        return 'education';
      case 'Negocio':
        return 'business';
      case 'Otros':
        return 'other';
      case 'Pagar Deudas':
        return 'debts';
      default:
        return null;
    }
  }).filter(Boolean);

export const mapPurposesApi = (purposes) => purposes.map((purpose) => {
  switch (purpose) {
    case 'house':
      return 'Vivienda';
    case 'personal':
      return 'Gastos Personales';
    case 'family':
      return 'Gastos Familiares';
    case 'car':
      return 'Automóvil';
    case 'education':
      return 'Educación';
    case 'business':
      return 'Negocio';
    case 'other':
      return 'Otros';
    case 'debts':
      return 'pay_debts';
    default:
      return purpose;
  }
});
