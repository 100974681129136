import React, {
  forwardRef, useEffect, useImperativeHandle, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'styles';
import InputMask from 'react-input-mask';
import { useField } from 'react-final-form';
import FieldBase from '../FieldBase/FieldBase';
import { TextFieldInput } from './TextField.style';

const sizes = {
  tiny: {
    input: {
      h: '2rem',
    },
    icon: {
      h: '2rem',
    },
  },
  sm: {
    input: {
      h: '3rem',
      fontSize: '1.2rem',
    },
    icon: {
      h: '3rem',
    },
  },
  md: {
    input: {
      h: '4.2rem',
    },
    icon: {
      h: '4.5rem',
    },
  },
};

const variants = {
  outline: {
    input: {
      px: '1rem',
    },
  },
  ghost: {
    input: {
      px: '0.5rem',
      py: '0',
      borderWidth: '0',
    },
  },
};

const buildIconStyles = {
  'icon-right': {
    base: {
      position: 'relative',
    },
    input: {
      p: '1rem 4rem 1rem 1rem',
    },
  },
};

const TextField = forwardRef((props, forwardedRef) => {
  const {
    name,
    color,
    textLabel,
    type,
    size,
    variant,
    placeholder,
    parse,
    format,
    maxLength,
    readOnly,
    Icon,
    disabled,
    iconPosition,
    inputProps = {},
    mask = '',
    onBlur = () => { },
    alwaysShowMask,
    ...rest
  } = props;
  const inputRef = useRef(null);
  const iconRightRef = useRef(null);
  useField(name, { ref: forwardedRef });
  const styles = buildIconStyles[`icon-${iconPosition}`] || { base: {}, input: {}, icon: {} };
  const sizeProps = sizes[size] || sizes.md;
  const variantProps = variants[variant] || { base: {}, input: {}, icon: {} };

  useImperativeHandle(forwardedRef, () => inputRef.current);

  useEffect(() => {
    if (inputRef.current && iconRightRef.current) {
      iconRightRef.current.style.top = `${inputRef.current.offsetTop}px`;
    }
  }, [textLabel, Icon, iconPosition]);

  return (
    <FieldBase
      name={name}
      color={color}
      textLabel={textLabel}
      parse={parse}
      format={format}
      readOnly={readOnly}
      disabled={disabled}
      {...styles.base}
      {...rest}
    >
      {({ input, hasError }) => (
        <>
          <InputMask
            value={input.value}
            onChange={input.onChange}
            onBlur={(ev) => {
              input.onBlur(ev);
              onBlur();
            }}
            disabled={disabled}
            onFocus={input.onFocus}
            name={input.name}
            mask={mask}
            readOnly={readOnly}
            alwaysShowMask={alwaysShowMask}
          >
            <TextFieldInput
              type={type}
              placeholder={placeholder}
              maxLength={maxLength}
              color={color}
              isInvalid={hasError}
              disabled={disabled}
              {...styles.input}
              {...variantProps.input}
              {...sizeProps.input}
              {...inputProps}
              ref={inputRef}
              data-testid={`${props['data-testid'] || name}-input`}
            />
          </InputMask>
          {iconPosition === 'right' && (
            <Flex
              position="absolute"
              right="0"
              centered
              top="0"
              bottom="0"
              w="4rem"
              {...styles.icon}
              ref={iconRightRef}
            >
              <Icon />
            </Flex>
          )}
        </>
      )}
    </FieldBase>
  );
});

TextField.defaultProps = {
  type: 'text',
  size: 'md',
  variant: 'outline',
};

TextField.propTypes = {
  type: PropTypes.oneOf(['text', 'textarea', 'password', 'email']),
  size: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variants)),
};

export default TextField;
