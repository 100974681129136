import React from 'react';
import { Fade } from 'ui';
import { useSessionController } from './hooks/useSessionController';
import { SessionExpirationToast } from './components/SessionExpirationToast/SessionExpirationToast';

export const ActivityMonitor = () => {
  const { sessionToastParams } = useSessionController();

  const { showToast } = sessionToastParams;

  return (
    <Fade
      style={{
        zIndex: '1001',
        position: 'fixed',
      }}
      unmountOnExit
      in={showToast}
    >
      <SessionExpirationToast {...sessionToastParams} />
    </Fade>
  );
};
