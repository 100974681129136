import React from 'react';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { t } from 'i18n';
import TableContainer from './styles';
import Row from './Row';

const ReferralsTable = (props) => {
  const { referralsList } = props;

  const getEarnedAmount = () => {
    const sum = referralsList.reduce((totalAmount, referral) => totalAmount + referral.reward, 0);
    return toCurrencyWithDecimals(sum);
  };

  const getReferralsListFormatted = () => [...referralsList].map((referral, index) => {
    const formattedReferral = {
      ...referral,
      reward: toCurrencyWithDecimals(referral.reward),
      id: index,
    };

    return <Row key={formattedReferral.id} item={formattedReferral} />;
  });

  return (
    <TableContainer>
      <table className="referrals-info-table">
        <tbody>
          <tr>
            <td className="top-cell">{t('settings.referralProgram.referral')}</td>
            <td className="top-cell">{t('settings.referralProgram.inscriptionDate')}</td>
            <td className="top-cell">{t('common.status')}</td>
            <td className="top-cell">{t('settings.referralProgram.reward')}</td>
          </tr>
          {getReferralsListFormatted()}
        </tbody>
      </table>
      <div className="bottom-info">
        <div className="referrals">
          <span className="text">Referidos: </span>
          <span className="quantity-and-amount quantity">{referralsList.length}</span>
        </div>
        <div className="earned">
          <span className="text">Ganado: </span>
          <span className="quantity-and-amount amount">{getEarnedAmount()}</span>
        </div>
      </div>
    </TableContainer>
  );
};

export default ReferralsTable;
