import React from 'react';
import { Flex } from 'styles';
import { ALERTS } from 'views/DashboardContent/Alerts/Alerts';
import ContentArea from './ContentArea';

const ActiveState = (props) => {
  const {
    onApplyFilter,
    onResetFilter,
    onSearchByZellId,
    filters,
    onOpenFilterClick,
    ...boxProps
  } = props;

  return (
    <Flex position="relative" h="100%" direction="column" {...boxProps}>
      {ALERTS.map(({ id, Component }) => <Component key={id} m="2rem" />)}
      <ContentArea
        onApplyFilter={onApplyFilter}
        onResetFilter={onResetFilter}
        onSearchByZellId={onSearchByZellId}
        onOpenFilterClick={onOpenFilterClick}
        filters={filters}
      />
    </Flex>
  );
};

export default ActiveState;
