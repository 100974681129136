import React from 'react';
import {
  ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, Area,
} from 'recharts';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';

const ReturnChart = (props) => {
  const { averageRate, accountValue, isMobile } = props;

  const buildChart = () => {
    const data = [];
    const cleanAverage = averageRate / 100;
    for (let x = 6; x <= 36; x += 6) {
      const calc = (1 + cleanAverage / 12) ** x;
      const performance = parseFloat((accountValue * calc).toFixed(2));
      const objData = { month: x, performance };
      data.push(objData);
    }
    return data;
  };

  const labelFormatter = (tick) => (tick === 0 ? 'Hoy' : `${tick} meses`);

  const tickFormat = (tick) => {
    const stringTick = tick.toString();
    const strLength = stringTick.length;
    if (strLength >= 7) {
      const decimals = strLength === 7 ? 1 : 0;
      return `${(stringTick / 10 ** 6).toFixed(decimals)}M`;
    }
    if (stringTick.length >= 4) {
      const decimals = strLength === 4 ? 1 : 0;
      return `${(stringTick / 10 ** 3).toFixed(decimals)}K`;
    }
    return stringTick;
  };

  return (
    <ResponsiveContainer className="chartContainer" width="100%" height={isMobile ? 305 : 350}>
      <AreaChart
        data={buildChart()}
        margin={{
          top: 35, right: 65, left: 5, bottom: 5,
        }}
      >
        <XAxis dataKey="month" tickFormatter={labelFormatter} />
        <YAxis type="number" domain={['auto', 'auto']} tickFormatter={(tick) => tickFormat(tick)} />
        <Tooltip
          labelFormatter={labelFormatter}
          separator=" "
          formatter={(value) => toCurrencyWithDecimals(value)}
        />
        <Area
          isAnimationActive={false}
          type="natural"
          dataKey="performance"
          stroke="#00c272"
          fill="#d9f7ea"
          unit=" MX"
          name=" "
          dot
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ReturnChart;
