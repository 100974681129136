import {
  FUNDING_REQUISITION_LIST,
  UPDATE_REQUISITION_LEND_AMOUNT,
  UPDATE_REQUISITION_COUNT,
  MODIFY_TICKET_INFO,
  LOADING_REQUISITION_LIST,
  HIDE_REQUISITION,
} from 'actions/requisitions/types';

const INITIAL_STATE = {
  fundingRequisitionList: {
    requisitions: null,
  },
  updateRequisitionLendAmount: false,
  fundingRequisitionsCounter: null,
  loadingRequisitionList: false,
};

const modifyTicketInfo = (requisitions, tickets) => {
  if (!requisitions) return requisitions;

  const newRequisitions = requisitions.map((requisition) => ({
    ...requisition,
    ticket_counts: tickets.reduce(
      (totalTickets, t) => (t.requisition_id === requisition.id ? totalTickets + 1 : totalTickets),
      0,
    ),
    to_be_approved_amount: tickets.reduce(
      (amount, t) => (
        t.requisition_id === requisition.id ? Number(amount) + Number(t.amount) : amount
      ),
      0,
    ),
  }));

  return newRequisitions;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FUNDING_REQUISITION_LIST:
      return { ...state, fundingRequisitionList: action.payload };
    case UPDATE_REQUISITION_LEND_AMOUNT:
      return { ...state, updateRequisitionLendAmount: action.payload };
    case UPDATE_REQUISITION_COUNT:
      return { ...state, fundingRequisitionsCounter: action.payload };
    case MODIFY_TICKET_INFO:
      return {
        ...state,
        fundingRequisitionList: {
          requisitions: modifyTicketInfo(
            state.fundingRequisitionList.requisitions,
            action.payload.tickets,
          ),
        },
      };
    case LOADING_REQUISITION_LIST:
      return {
        ...state,
        loadingRequisitionList: action.payload.isLoading,
      };
    case HIDE_REQUISITION:
      return {
        ...state,
        fundingRequisitionList: {
          requisitions: state
            .fundingRequisitionList
            .requisitions
            ?.map((requisition) => (requisition.id === action.payload.requisitionId
              ? { ...requisition, hidden: action.payload.hidden }
              : requisition)),
        },
      };
    default:
      return state;
  }
};
