import sendToast from 'utils/toastHandler';
import camelify from 'camelcase-keys-recursive';
import { request } from './fetchers/yotepresto';

export const fetchVerificationStatus = async () => {
  const path = '/v1/kyc_informations';
  const method = 'get';
  const config = { method, path };
  const { data } = await request(config);

  return camelify(data);
};

export const updatePersonalIdStatus = async () => {
  const path = '/v2/investor/personal_id_status';
  const method = 'put';
  const config = { method, path };
  const { data } = await request(config);

  return camelify(data);
};

export const restartVerification = () => {
  try {
    const path = '/v1/kyc_informations/restart';
    const method = 'post';
    const config = { method, path };
    return request(config);
  } catch (e) {
    sendToast(
      'error',
      'Ocurrió un error al reestablecer tu estatus de verificación. Por favor intenta recargando la página.',
    );
    return null;
  }
};
