import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { useReduxQuery } from 'utils/redux-query';
import { ShowAddress, UpdateAddressView } from './components';

function Address() {
  const match = useRouteMatch();
  const [, { status: profileAddressStatus }] = useReduxQuery('PROFILE_ADDRESS');

  if (!profileAddressStatus.completed) return null;

  return (
    <Switch>
      <Route path={`${match.url}/show`} component={ShowAddress} />
      <Route path={`${match.url}/update`} component={UpdateAddressView} />
      <Redirect to={`${match.url}/show`} />
    </Switch>
  );
}

export default Address;
