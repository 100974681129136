import { useHistory } from 'react-router-dom';
import { useDisclosure } from 'ui';
import { useReduxQuery } from 'utils/redux-query';

export const useController = () => {
  const history = useHistory();
  const [autoinvestConfig, { isLoading: isFetchingAutoinvest }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const {
    isOpen: displayPauseAutoinvestForm,
    onToggle: onDisplayPauseAutoinvestForm,
  } = useDisclosure();
  const currentAutoinvestConfig = autoinvestConfig?.[0] || null;
  const isAutoinvestOff = !currentAutoinvestConfig;
  const isLoading = isFetchingAutoinvest;

  const onEditAutoinvest = () => {
    history.push('edit');
  };

  return {
    isLoading,
    isAutoinvestOff,
    onEditAutoinvest,
    currentAutoinvestConfig,
    displayPauseAutoinvestForm,
    onDisplayPauseAutoinvestForm,
  };
};
