import { REQUISITION_ATTRIBUTES } from 'config/constants';

const genericSorter = (arr, type) => [...arr]
  .sort((a, b) => a[type] - b[type]);

const bcScoreSorter = (arr) => [...arr]
  .sort((a, b) => a.credit_report.score - b.credit_report.score);

const destinationSorter = (arr) => [...arr]
  .sort((a, b) => a.destination.localeCompare(b.destination, undefined, { sensitivity: 'base' }));

const daysToEndSorter = (arr) => [...arr]
  .sort((a, b) => a.loan_detail.days_to_end - b.loan_detail.days_to_end);

const missingAmountSorter = (arr) => [...arr]
  .sort((a, b) => a.loan_detail.missing_amount - b.loan_detail.missing_amount);

const rateSorter = (arr) => genericSorter(arr, 'rate');

const sorterMap = {
  [REQUISITION_ATTRIBUTES.QUALIFICATION]: rateSorter,
  [REQUISITION_ATTRIBUTES.BC_SCORE]: bcScoreSorter,
  [REQUISITION_ATTRIBUTES.DESTINATION]: destinationSorter,
  [REQUISITION_ATTRIBUTES.DAYS_TO_END]: daysToEndSorter,
  [REQUISITION_ATTRIBUTES.MISSING_AMOUNT]: missingAmountSorter,
};

const basicReqSort = (requisitions, sortOptions) => {
  if (sortOptions === {}) return requisitions;

  const sorter = sorterMap[sortOptions.type] ?? genericSorter;

  return sorter(requisitions, sortOptions.type);
};

const RequisitionSorter = (requisitions, sortOptions) => {
  const sortedRequisitions = basicReqSort(requisitions, sortOptions);

  if (sortOptions?.sortOrder === 'asc') return [...sortedRequisitions].reverse();

  return sortedRequisitions;
};

export default RequisitionSorter;
