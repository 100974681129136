import { useHistory } from 'react-router-dom';
import { dashboardRoute } from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { USER_STATUSES } from 'config/constants';
import { useEffect } from 'react';

export const useController = () => {
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const [, { refetch: fetchPersonalArchive }] = useReduxQuery('PERSONAL_ARCHIVES');
  const shouldUserSignCER = USER_STATUSES.PROFILE_COMPLETED === session?.user?.status;

  const confirmSuccessHandler = async () => {
    fetchPersonalArchive();
  };

  useEffect(() => {
    if (!session?.user?.status) return;

    if (!shouldUserSignCER) history.push(dashboardRoute);
  }, [session, shouldUserSignCER]);

  return {
    confirmSuccessHandler,
  };
};
