import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Heading } from 'ui';

const VARIANTS = { SUBTLE: 'subtle' };

const variants = ({ colorSchema = 'gray' } = {}) => ({
  [VARIANTS.SUBTLE]: {
    bgColor: `${colorSchema}.100`,
    color: `${colorSchema}.500`,
    headerBgColor: `${colorSchema}.100`,
  },
});

export const Panel = (props) => {
  const {
    title,
    variant,
    children,
    colorSchema,
    footerComponent,
    textHeaderProps,
    innerContentProps,
    ...contentProps
  } = props;
  const variantConfig = variants({ colorSchema })[variant] ?? variants.subtle;

  return (
    <Box
      padding="0"
      width="100%"
      boxShadow="lg"
      overflow="hidden"
      borderRadius="lg"
      bgColor={variantConfig?.bgColor}
      {...contentProps}
    >
      {title && (
        <Flex
          m="0"
          p="2rem"
          boxShadow="sm"
          alignItems="center"
          h={{ base: 'auto', md: '54px' }}
          bgColor={variantConfig?.headerBgColor}
        >
          <Heading
            as="h3"
            flexGrow="1"
            fontWeight="semibold"
            fontSize="lg"
            textAlign="left"
            color={variantConfig?.color}
            {...textHeaderProps}
          >
            {title}
          </Heading>
        </Flex>
      )}
      <Flex
        w="100%"
        pt="1rem"
        bgColor="white"
        direction="column"
        px={{ base: '1rem', sm: '3rem' }}
        pb={{ base: '1rem', sm: footerComponent ? '1rem' : '2rem' }}
        {...innerContentProps}
      >
        {children}
      </Flex>
      {footerComponent && (
        <Box p="2rem" borderTop="1px solid" borderColor="gray.200">
          {footerComponent}
        </Box>
      )}

    </Box>
  );
};

Panel.propTypes = {
  colorSchema: PropTypes.string,
  footerComponent: PropTypes.node,
  textHeaderProps: PropTypes.shape({}),
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Panel.defaultProps = {
  title: null,
  colorSchema: 'gray',
  textHeaderProps: {},
  footerComponent: null,
  variant: VARIANTS.SUBTLE,
};

export default Panel;
