import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';

export const DiversificationNoticeText = styled.div`
  text-align: justify;
  text-align-last: center;
  padding: 0rem 4rem 2rem;
  color: ${colors.gray.g4};
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-align: justify;
  }
`;

export const KnowMoreSpan = styled.a`
  color: ${colors.blue};
  :hover {
    cursor: pointer;
  }
`;
