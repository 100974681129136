import React from 'react';
import { useHistory, Link as RRLink } from 'react-router-dom';
import IdentityVerification from 'views/DashboardContent/Mati';
import PATHS from 'routes';
import {
  ChevronLeftIcon, Flex, Link, Box,
} from 'ui';

function UploadIdentification() {
  const history = useHistory();

  const handleFinishProcess = () => {
    history.push(PATHS.investorSettingsLimitsPath());
  };

  return (
    <Box>
      <Flex width="100%" alignItems="center" justifyContent="flex-start" gap="5" paddingX="5" height={16}>
        <Link as={RRLink} to="../" fontSize="md">
          <ChevronLeftIcon boxSize="6" />
          Regresar
        </Link>
      </Flex>
      <IdentityVerification onFinishProcess={handleFinishProcess} />
    </Box>
  );
}

export default UploadIdentification;
