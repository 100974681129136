import React, { forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import BaseField from 'components/FieldBase/FieldBase';
import { useField } from 'react-final-form';
import { OTPInput } from './TokenField.style';

const defaultArrayValues = Array.from({ length: OTP_CODE_LENGHT_DEFAULT }).map(() => '');

const TokenField = forwardRef((props, forwardedRef) => {
  const {
    name = 'otp', fieldSize, fluid, ...baseProps
  } = props;
  const dataTestId = props['data-testid'] || name;
  const ref = useRef(forwardedRef);
  const {
    input: { value },
  } = useField(name);

  useEffect(() => {
    if (!ref.current) return;
    const valuesArray = value.length > 0 ? value.split('') : defaultArrayValues;

    ref.current.setState({
      input: defaultArrayValues.map((val, index) => valuesArray[index] || ''),
    });
  }, [value]);

  return (
    <BaseField name={name} data-testid={dataTestId} {...baseProps}>
      {({ input, hasError }) => (
        <OTPInput
          ref={ref}
          fields={OTP_CODE_LENGHT_DEFAULT}
          name={name}
          fieldSize={fieldSize}
          fluid={fluid}
          hasError={hasError}
          {...input}
          onChange={(originalValue) => {
            input.onChange(originalValue);
            if (originalValue?.length === OTP_CODE_LENGHT_DEFAULT) {
              input.onBlur();
            }
          }}
        />
      )}
    </BaseField>
  );
});

TokenField.propTypes = {
  name: PropTypes.string,
};

TokenField.defaultProps = {
  name: 'otp',
};

export default TokenField;
