import React from 'react';
import { toPercentage } from 'i18n';
import {
  Link,
  Text,
  Stack,
  HStack,
  StackDivider,
} from 'ui';

export const RateStats = ({
  qualification,
  interestRate,
  lendingRate,
  financingId,
  latePaymentRate,
}) => (
  <Stack>
    <HStack
      rowGap="5"
      marginX="5"
      spacing="2"
      width="100%"
      justifyContent="space-evenly"
      divider={<StackDivider color="gray.200" />}
    >
      <Stack flexWrap={1}>
        <Text fontSize="4xl" color="gray.700" fontWeight="bold">
          {qualification}
        </Text>
        <Text as="legend" textAlign="center">Calif.</Text>
      </Stack>
      <Stack flexWrap={1}>
        <Text fontSize="4xl" color="gray.700" fontWeight="bold" textAlign="center">
          {toPercentage(
            financingId === '4' ? interestRate : lendingRate,
            { precision: 2, strip_insignificant_zeros: true },
          )}
        </Text>
        <Text
          as="legend"
          whiteSpace="nowrap"
          textAlign="center"
        >
          Tasa ordinaria
        </Text>
      </Stack>
      {latePaymentRate && (
        <Stack flexWrap={1}>
          <Text fontSize="4xl" color="gray.700" fontWeight="bold">
            {toPercentage(
              latePaymentRate,
              { precision: 2, strip_insignificant_zeros: true },
            )}
          </Text>
          <Text
            as="legend"
            whiteSpace="nowrap"
            textAlign="center"
          >
            Tasa moratoria
          </Text>
        </Stack>
      )}
    </HStack>
    <Link
      textAlign="center"
      target="_blank"
      href="https://www.yotepresto.com/tasas-comisiones#investor"
    >
      Conocer más
    </Link>
  </Stack>
);
