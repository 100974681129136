import React from 'react';
import { t } from 'i18n';
import { Link } from 'react-router-dom';
import {
  Heading, Image, Text, Button,
} from 'components';
import { Box, Flex, Grid } from 'styles';
import { Item } from './components';
import { useController } from './hooks/useController';
import styles from './Terms.style';

const ts = (key) => t(key, { scope: 'components.CER.Terms' });

export const Terms = (props) => {
  const { confirmPath } = useController();

  return (
    <Grid {...styles.container} data-testid="CER-Terms" {...props}>
      <Heading {...styles.title}>{ts('title')}</Heading>

      <Flex {...styles.sections.notice.container}>
        <Image imageName="CER_TERMS" {...styles.sections.notice.image} />
        <Box {...styles.sections.notice.body}>
          <Text>{ts('sections.noticeBanner.body')}</Text>
        </Box>
      </Flex>

      <Grid {...styles.sections.termsListing.container}>
        <Item number={1} i18nKey="advisory" />
        <Item number={2} i18nKey="information" />
        <Item number={3} i18nKey="yieldInvestment" />
        <Item number={4} i18nKey="losses" />
        <Item number={5} i18nKey="guaranteeOfResources" />
        <Item number={6} i18nKey="liquidity" />
      </Grid>

      <Flex justifyContent="flex-end">
        <Button to={confirmPath} as={Link} {...styles.acceptButton}>
          {ts('sections.callToActions.accept')}
        </Button>
      </Flex>
    </Grid>
  );
};
