import styled from 'styled-components';

const ReturnSearchHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  & .search__header {
    width: 90%;
    margin-bottom: 3rem;
    &__label {
      padding-left: 3.3rem;
      color: #04475c;
      font-size: 1.4rem;
    }
    &__button {
      margin-top: 3rem;
      & button {
        &:hover {
          cursor: pointer;
        }
        display: flex;
        align-items: center;
        padding: 1rem;
        border: none;
        background-color: transparent;
        color: #0f7eff;
        font-size: 1.4rem;
        outline: none;
        margin-bottom: 2rem;
        & svg {
          margin-right: 1rem;
        }
      }
    }
  }
`;

export default ReturnSearchHeaderContainer;
