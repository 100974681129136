import React from 'react';
import { Icon } from 'ui';

export const PauseIcon = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g id="limits" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Configuración-/-Límites-/Identidad-5-Copy-4" transform="translate(-281.000000, -435.000000)">
        <g id="Group-3" transform="translate(281.000000, 435.000000)">
          <circle id="Oval" fill="currentColor" cx="10" cy="10" r="10" fillOpacity="0.2" />
          <g id="Group-2" transform="translate(7.000000, 6.000000)" fill="currentColor">
            <rect id="Rectangle" x="0" y="0" width="2" height="8" rx="1" />
            <rect id="Rectangle-Copy" x="4" y="0" width="2" height="8" rx="1" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
