/**
  count days within a month and year
  @param  {Number}  month   specific month
  @param  {Number}  year    specific year
  @return {Number}          count days
*/
export const countDaysInMonth = (month, year) => new Date(year, month, 0).getDate();

/**
  List all days from 1 to last day of passed month
  @param  {Number}  month   specific month
  @param  {Number}  year    specific year
  @return {Array<Number>}          all days
*/
export const getDayList = (month, year) => Array
  .from({ length: countDaysInMonth(month, year) }).map((_, index) => index + 1);

/**
  List all months names
  @return {Array<String>}   Array of month names
*/
export const getMonthList = (locales = 'es', format = 'long') => {
  const year = new Date().getFullYear();
  const monthList = [...Array(12).keys()];
  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  });

  const getMonth = (monthIndex) => ({
    id: (monthIndex + 1).toString().padStart(2, '0'),
    name: formatter.format(new Date(year, monthIndex)),
  });

  return monthList.map(getMonth);
};

/**
  Creates an array of years starting from a specific year
  @param  {Object({
    from: Number,
    span: Number
  })}  options              options includes specifications to generate list
  @return {Array<String>}   Array of years
*/
export const getYearList = ({ from = 1960, span = 99 } = {}) => [...Array(span).keys()]
  .reduce((years) => [...years, Number(years.slice(-1)) + 1], [from]);

export const defaultDateStringToISO = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split('/');
  const dateISO = new Date(year, month - 1, day).toISOString();
  return dateISO;
};
