import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import Box from 'components/Box';
import { ReactComponent as PlayVideoIcon } from 'assets/icons/play-video.svg';

export const Banner = styled.div`
  display: flex;
  border-radius: 7px;
  background-color: #f2f5f7;
  padding: 2rem 2rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    flex-wrap: wrap;
  }
`;

export const BannerIcon = styled.div`
  background-color: #ffffff;
  border-radius: 7px;
  padding: 1rem;
  margin-right: 2rem;
  margin-bottom: auto;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    margin-bottom: auto;
  }
`;

export const BannerText = styled.div`
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    width: calc(100% - 63px);
  }
`;

export const PlayVideoBox = styled(Box)`
  max-width: 120px;
  min-width: 120px;
  box-sizing: content-box;
  margin-left: 2rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    display: none;
  }
`;

export const PlayImage = styled(PlayVideoIcon)`
  height: 38px;
  width: 38px;
  min-width: 38px;
`;

export const PlayVideoButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  margin-left: 2rem;
`;

export const PlayVideoButtonMobile = styled(PlayVideoButton)`
  color: #1481fe;
  margin-left: 0;
  font-size: 1.4rem;
  display: none;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    display: initial;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  #footer {
    flex: unset;
    padding: 0 2rem;
  }

  /* TODO: remove global footer and let every section import it */
  & + #footer {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 24rem 4rem 8rem;

  .orders {
    &-banner {
      margin-bottom: 3rem;
    }

    &-subTitle {
      margin-bottom: 2rem;
    }

    &-pendingTable {
      margin-bottom: 5rem;
    }

    &-finishedTable {
      margin-bottom: 2rem;
    }

    &-noOrdersText {
      margin-bottom: 4rem;
    }
  }

  @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
    margin-left: 0;
    padding: 4rem 2rem;
  }
`;
