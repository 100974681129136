import React from 'react';
import { toCurrency } from 'utils/numberFormatters';
import AmortizationScheduleContainer from './styles';

const AmortizationSchedule = ({ amortizations }) => {
  const {
    amortization_schedule: amortizationSchedule,
    monthly_payment: monthlyPayment,
    service_fee: serviceFee,
  } = amortizations;
  return (
    <AmortizationScheduleContainer>
      <table>
        <thead>
          <tr>
            <th>Mes</th>
            <th>Capital</th>
            <th>Intereses</th>
            <th>Cuota de servicio</th>
            <th>Pago</th>
          </tr>
        </thead>
        <tbody>
          {amortizationSchedule.map((item, index) => {
            const key = `${index}_amz`;

            return (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{toCurrency(item.capital, 2)}</td>
                <td>{toCurrency(item.interest, 2)}</td>
                <td>{toCurrency(serviceFee, 2)}</td>
                <td>{toCurrency(monthlyPayment, 2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </AmortizationScheduleContainer>
  );
};

export default AmortizationSchedule;
