import { SET_STATES, SET_ZIPCODES_INFO, SET_CATALOGS } from 'actions/catalogs/types';

const INITIAL_STATE = {
  states: null,
  zipCodesInfo: {},
  activities: [],
  depositAmounts: [],
  fundingSources: [],
  moneyDestination: [],
  montlhyFundings: [],
  ocupations: [],
  sectors: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CATALOGS:
      return { ...state, ...action.payload };
    case SET_STATES:
      return { ...state, states: action.payload };
    case SET_ZIPCODES_INFO:
      return { ...state, zipCodesInfo: { ...state.zipCodesInfo, ...action.payload } };
    default:
      return state;
  }
};
