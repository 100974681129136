import { useForm } from 'react-hook-form';
import { showToastError, showToastInfo } from 'utils/toastHandler';
import { downloadFile } from 'utils/window/downloadFile';
import { tAlerts, tView } from '../constants';
import { useAccountStatement } from './graphql';
import { resolver } from '../resolver';

export const useRequestAccountStatementForm = () => {
  const {
    reset,
    control,
    setValue,
    handleSubmit,
  } = useForm({
    resolver,
    delayError: 500,
  });

  const onAccountStatementComplete = ({
    accountStatement,
  }) => {
    const fileConfig = {
      link: accountStatement.url,
      fileName: tView('downloadPDF'),
    };
    downloadFile(fileConfig);
    showToastInfo(tAlerts('info.pdfDownloaded'));
    reset();
  };

  const [
    getAccountStatement,
    { loading: isLoading },
  ] = useAccountStatement({
    onErrorCode: {
      FORBIDDEN_ERROR: () => {
        showToastError(tAlerts('errors.invalidPassword'));
      },
    },
    onCompleted: onAccountStatementComplete,
  });

  const onSubmit = handleSubmit(({
    month,
    password,
  }) => {
    getAccountStatement({
      variables: { accountStatementId: month },
      context: { password },
    });
  });

  return {
    control,
    onSubmit,
    setValue,
    isLoading,
  };
};
