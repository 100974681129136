import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Button from 'components/Button';
import { Flex } from 'styles';
import { Text } from 'components/Typography';
import SecondFactor from 'modules/SecondFactor';
import { useReduxQuery } from 'utils/redux-query';
import DotsLoader from 'components/DotsLoader';
import { useModalDisclosure } from 'hooks/ui';
import SecondFactorModal from './SecondFactorModal';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.activateCTA', ...options });

const SecondFactorCTA = (props) => {
  const { refreshData, errors, actionToAuthorize } = props;
  const { isOpen, onOpen, onClose } = useModalDisclosure();
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');

  const ActiveSecondFactor = (
    <SecondFactor
      disabled={refreshData}
      errors={errors}
      buttonText={t('orders.addOrders')}
      actionToAuthorize={(otp) => actionToAuthorize(otp)}
    />
  );

  const InactiveSecondFactor = (
    <Flex p="2rem" direction="column" rowGap="1rem">
      <Text size="small">
        {ts('title.0')}
        <Text size="small" as="b">
          {ts('title.1')}
        </Text>
      </Text>
      <Text size="small">{ts('body')}</Text>
      <Button width="100%" onClick={onOpen}>
        {ts('button')}
      </Button>
      <SecondFactorModal isOpen={isOpen} onClose={onClose} />
      <Flex justifyContent="flex-end">
        <Button disabled>{ts('disabledButton')}</Button>
      </Flex>
    </Flex>
  );

  if (!secondFactorStatus.completed) return <DotsLoader color="schema.gray.200" />;

  return (
    <>{secondFactor && secondFactorStatus.completed ? ActiveSecondFactor : InactiveSecondFactor}</>
  );
};

SecondFactorCTA.propTypes = {
  actionToAuthorize: PropTypes.func,
  refreshData: PropTypes.bool,
};

SecondFactorCTA.defaultProps = {
  actionToAuthorize: () => { },
  refreshData: false,
};

export default SecondFactorCTA;
