import { tscope } from 'i18n';
import { DEFAULT_PHONE_LENGTH, OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import { filterOnlyNumbers } from 'utils/strings';

const t = tscope('Forms.fields.errors');
const ts = tscope('Dashboard.Settings.Account.ContactsPanel.phoneNumberForm');
const wrapError = (message) => ({ message, type: 'danger' });

export const validatePhone = (phoneNumber, { phoneCode, phoneCodeValidations = {} } = {}) => {
  const lengthValidations = phoneCodeValidations[phoneCode] || {};
  const min = lengthValidations.min || DEFAULT_PHONE_LENGTH;
  const max = lengthValidations.max || DEFAULT_PHONE_LENGTH;
  const parsed = phoneNumber?.match(/(\d+)/g)?.join('');
  if (!parsed) return wrapError(t('required'));

  const lengthError = parsed.length < min || parsed.length > max;
  if (lengthError) {
    if (min === max) return wrapError(t('exactDigits', { count: min }));
    return wrapError(t('minMaxDigits', { min, max }));
  }

  return null;
};

const cleanValues = (values) => ({
  ...values,
  newPhoneNumber: filterOnlyNumbers(values.newPhoneNumber),
  currentPhoneNumber: filterOnlyNumbers(values.currentPhoneNumber),
});

export const resolver = (values, context) => {
  const {
    otp,
    newPhoneNumber,
    newPhoneCode,
    currentPhoneNumber,
    currentPhoneCode,
  } = cleanValues(values);
  const {
    cellPhoneCode,
    cellPhoneNumber,
    phoneCodeValidations,
  } = context;

  const errors = {};
  const phoneError = validatePhone(newPhoneNumber, {
    phoneCodeValidations,
    phoneCode: newPhoneCode,
  });
  if (phoneError) errors.newPhoneNumber = phoneError;
  const currentPhoneError = validatePhone(currentPhoneNumber, {
    phoneCodeValidations,
    phoneCode: currentPhoneCode,
  });
  if (currentPhoneError) errors.currentPhoneNumber = currentPhoneError;
  if (!otp) errors.otp = wrapError(t('required'));
  if (otp && otp.length !== OTP_CODE_LENGHT_DEFAULT) {
    errors.otp = wrapError(
      t('exactDigits', { count: OTP_CODE_LENGHT_DEFAULT }),
    );
  }
  const isCurrentPhone = currentPhoneNumber === cellPhoneNumber
    && cellPhoneCode === currentPhoneCode;
  if (currentPhoneNumber && !isCurrentPhone) errors.currentPhoneNumber = wrapError(ts('errors.mismatchPhone'));
  const isSamePhone = cellPhoneNumber === newPhoneNumber
  && cellPhoneCode === newPhoneCode;
  if (newPhoneNumber && isSamePhone) errors.newPhoneNumber = wrapError(ts('errors.samePhone'));

  return {
    errors,
    values,
  };
};
