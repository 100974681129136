import { tscope } from 'i18n';
import { showSuccessAlert, showWarningAlert } from 'utils/toastHandler';
import { useState } from 'react';
import { useUpdateBankAccountMutation } from '../../../../../hooks/graphql/useUpdateBankAccountMutation';

const ts = tscope('components.AddBankAccountForm');

export const useController = (props) => {
  const { bankAccount } = props;
  const { clabeAlias, bank, clabe } = bankAccount;
  const [displayValue, setDisplayValue] = useState(clabeAlias);

  const onChangeValue = (value) => {
    setDisplayValue(value);
  };

  const [updateBankAccount] = useUpdateBankAccountMutation({
    onCompleted: () => {
      showSuccessAlert({ t: ts, key: 'updateBankAccount' });
    },
    onError: () => {
      showWarningAlert({ t: ts, key: 'updateBankAccount' });
      setDisplayValue(clabeAlias);
    },
  });

  const handleUpdateBankAccount = (value) => {
    if (!value) {
      setDisplayValue(clabeAlias);
      return;
    }
    updateBankAccount({
      variables: {
        input: {
          bank,
          clabe,
          clabeAlias: value,
        },
      },
    });
  };

  return {
    displayValue,
    onChangeValue,
    handleUpdateBankAccount,
  };
};
