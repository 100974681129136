import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const EXPEDIENT_CATALOG = gql`
query EXPEDIENT_CATALOG {
  catalogs {
    id
    countries {
      id
      name
    }
  }
}
`;

export const useCatalog = (options = {}) => useQuery(
  EXPEDIENT_CATALOG,
  {
    ...options,
    context: {
      clientName: 'public',
    },
  },
);
