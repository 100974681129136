import camelify from 'camelcase-keys-recursive';
import { lmed } from 'i18n';

export const parseInitialProfile = (originalProfile) => {
  const newProfile = camelify(originalProfile);
  const { originCountry } = newProfile;

  return {
    ...newProfile,
    birthDate: lmed(newProfile.birthDate),
    isNotMexican: originCountry !== 'México',
  };
};

export const parseFinalProfile = (profile, { context }) => {
  const { reason } = profile;
  const { shouldBeProcessed } = context;
  const partialProfile = {
    curp: profile.curp,
    rfc: profile.rfc,
    birthDate: profile.birthDate,
    birthStateId: profile.isNotMexican ? undefined : profile.state?.id,
    originCountry: profile.isNotMexican ? profile.country : 'México',
  };

  if (!shouldBeProcessed) {
    return partialProfile;
  }

  const newProfileRequest = {
    personalInformationRequestChange: {
      ...partialProfile,
      firstName: profile.firstName,
      secondName: profile.secondName,
      firstLastName: profile.firstLastName,
      secondLastName: profile.secondLastName,
      motive: reason,
    },
  };

  return newProfileRequest;
};

export const parseProfileErrorsResult = (errorResult) => {
  const data = errorResult?.response?.data;
  if (!data) return {};
  if (data.error) {
    return {
      password: data.error,
    };
  }

  return {};
};
