import { t } from 'i18n';
import {
  PASSWORD_ERROR_TYPES,
  REQUIRED_PASSWORD_LENGTH,
} from 'config/constants';

export const ts = (key, options) => t(key, { scope: 'components.UpdatePasswordForm', ...options });

export const ERRORS_CONFIG = [
  {
    alwaysDisplay: true,
    key: PASSWORD_ERROR_TYPES.MIN_LENGTH,
    text: ts('requirements.length', { length: REQUIRED_PASSWORD_LENGTH }),
  },
  {
    alwaysDisplay: true,
    key: PASSWORD_ERROR_TYPES.MISSING_DIGIT,
    text: ts('requirements.digit'),
  },
  {
    alwaysDisplay: true,
    key: PASSWORD_ERROR_TYPES.SPECIAL_CHAR,
    text: ts('requirements.special'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CONTAINS_ALPHABETIC,
    text: ts('requirements.alphabetic'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CONTAINS_RESERVED_WORDS,
    text: ts('requirements.reservedWords'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CONTAINS_EMAIL,
    text: ts('requirements.email'),
  },
  {
    key: PASSWORD_ERROR_TYPES.REPEATED_CHARS,
    text: ts('requirements.repeated'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CONSECUTIVE_CHARS,
    text: ts('requirements.consecutive'),
  },
  {
    key: PASSWORD_ERROR_TYPES.SEQUENTIAL_CHARS,
    text: ts('requirements.sequential'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CURRENT_PASSWORD,
    text: ts('requirements.currentPass'),
  },
  {
    key: PASSWORD_ERROR_TYPES.CONTAINS_ID,
    text: ts('requirements.ids'),
  },
];
