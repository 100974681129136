import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { ResponsiveHelpers } from 'styles';

const { extraLargeBreakPoint, midBreakPoint } = breakPoints;

export const TitleRequisition = styled.p`
  @media (max-width: ${midBreakPoint}px) {
    display: none;
  }
`;

export const ContainerIconHide = styled.div`
  ${ResponsiveHelpers}
`;

const HeaderElementsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  &.funded {
    flex-direction: row;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    margin-top: 2.5rem;
    .closeButton {
      margin-top: 0.5rem;
      margin-left: 2.5rem;
    }
    .idRequisitionHideButton {
      margin-top: 0;
      margin-right: 1rem;
    }
    @media (max-width: ${midBreakPoint}px) {
      margin-bottom: 1rem;
      justify-content: space-between;
    }
  }
  @media (max-width: ${midBreakPoint}px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: ${extraLargeBreakPoint}px) and (min-width: ${midBreakPoint}px) {
    justify-content: flex-end;
  }
  .idRequisitionHideButton {
    float: right;
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
    right: 1rem;
    color: #346071;
    font-size: 1.4rem;
    align-items: center;
    @media (max-width: ${extraLargeBreakPoint}px) {
      float: none;
      right: auto;
      margin-top: 2rem;
    }
    .number {
      border: 1px solid #dde5e8;
      border-radius: 1.4rem;
      background-color: #f3f6f9;
      padding: 0.5rem 1rem;
      color: #0f7eff;
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
    .hideIcon {
      display: flex;
      padding: 1rem;
      border-radius: 2.5rem;
      background-color: #ffffff;
      border: 1px solid #e9f0f7;
      :hover {
        cursor: pointer;
      }
      &--icon {
        &.active {
          * {
            fill: blue;
          }
        }
      }
    }
  }
`;

export default HeaderElementsContainer;
