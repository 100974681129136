import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { breakPoints } from 'config/constants';
import colors from 'styles/colors';
import { calcHeightContentOnBrowserType } from 'utils/responsiveHandler';
import { boxHelpers } from 'styles';

export const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  height: 100vh;
  position: fixed;
  background-color: #00243a;
  font-weight: 600;
  transition: all ease 0.3s;
  z-index: 1000;
  @media (max-width: 425px) {
    width: 70%;
  }
  @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
    width: 7rem;
  }
  @media (max-width: 700px) {
    ${calcHeightContentOnBrowserType(100)}
  }
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    height: calc(100vh - 75px);
  }
  .elements {
    width: 100%;
    flex: 1;
    @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
      overflow-x: auto;
    }
  }
`;

export const BarBrand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  min-height: 7.2rem;
  line-height: 6rem;
  width: 100%;

  & img {
    max-width: 15rem;
    height: 100%;
  }
`;

export const StyledLink = styled(Link)`
  min-width: 100%;
  text-decoration: none;
  ${(props) => (props.disabled
    ? css`
          cursor: not-allowed;
        `
    : '')}
`;

export const BrandLink = styled.a`
  min-width: 100%;
  text-decoration: none;
`;

export const BarElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5.2rem;
  font-size: 1.4rem;
  color: #b9cfdd;
  text-indent: 2rem;
  transition: all ease 0.4s;
  & span {
    transition: transform ease 0.2s;
    display: flex;
    align-items: center;
    margin-left: 2rem;
    @media (max-width: 425px) {
      margin-left: 3rem;
    }
    @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
      margin-left: 0.6rem;
    }
  }

  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active span {
    transform: translateX(0.5rem);
  }

  & svg {
    * {
      fill: #b9cfdd;
    }
  }

  .icon-wrapper {
    position: relative;
    display: inline-block;
    width: 25px;
    text-align: center;
    .ready-mark {
      background-color: #00c574;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      position: absolute;
      top: -2px;
      right: 3px;
      border: 3px solid #134052;
    }
  }
  .name-wrapper {
    margin-left: -1rem;
    @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
      display: none;
    }
  }
  ${(props) => props.active
    && css`
      background-color: #012e47;
      color: white;
      span {
        @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
          margin-left: 0.6rem;
        }
      }
      & svg {
        * {
          fill: white;
        }
      }
    `}

  ${(props) => (props.disabled
    ? css`
          pointer-events: none;
          background-color: ${colors.gray.g500};
          opacity: 0.2;

          & span {
            transition: none;
          }
        `
    : '')}
   ${boxHelpers}
`;
