import React from 'react';
import {
  Grid,
  Button,
} from 'ui';
import {
  TextField,
  SelectField,
} from 'components/FormFields';
import { tscope } from 'i18n';
import { WITHDRAWAL_BANK_ACCOUNT_STATUS } from 'config/constants';
import { AddBankAccountForm } from 'components/AddBankAccountForm';
import { AuthorizeWithOTPField } from 'components/AuthorizeWithOTPField';
import { useController } from './hooks';
import { styles as s } from './WithdrawalForm.style';

const ts = tscope('components.WithdrawalForm');
const OTPFieldT = tscope('components.WithdrawalForm.fields.otp');

export const WithdrawalForm = () => {
  const {
    control,
    onSubmit,
    isDisabled,
    otpFieldProps,
    isSubmitRendered,
    bankAccountOptions,
    addBankAccountDisclosure,
    isAddBankAccountLinkDisabled,
  } = useController();

  return (
    <>
      <Grid
        {...s.container}
        as="form"
        onSubmit={onSubmit}
      >
        <TextField
          name="amount"
          maxLength={30}
          type="currency"
          control={control}
          isDisabled={isDisabled}
          labelText={ts('fields.amount.label')}
        />
        <Grid>
          <SelectField
            name="account"
            control={control}
            isDisabled={isDisabled}
            labelText={ts('fields.account.label')}
            placeholder={ts('fields.account.placeholder')}
          >
            {bankAccountOptions?.map(({
              id,
              status,
              clabeAlias,
            }) => (
              <option
                key={id}
                value={id}
                disabled={status !== WITHDRAWAL_BANK_ACCOUNT_STATUS.VALID}
              >
                {ts(`bankAccountOption.${status || 'noStatus'}`, { name: clabeAlias })}
              </option>
            ))}
          </SelectField>
          <Button
            {...s.addBankAccountButton}
            isDisabled={isAddBankAccountLinkDisabled}
            onClick={addBankAccountDisclosure.onOpen}
          >
            {ts('actions.addBankAccount')}
          </Button>
        </Grid>
        <AuthorizeWithOTPField
          t={OTPFieldT}
          name="otp"
          data-testid="otp"
          control={control}
          isDisabled={isDisabled}
          {...otpFieldProps}
        />
        {isSubmitRendered && (
          <Button
            type="submit"
            isDisabled={isDisabled}
          >
            {ts('actions.submit')}
          </Button>
        )}
      </Grid>
      <AddBankAccountForm {...addBankAccountDisclosure} />
    </>
  );
};
