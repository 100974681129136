import styled from 'styled-components';
import SignCERNoticeComponent from 'components/Banners/SignCERNotice';

export const Container = styled.div`
  width: 100%;

  & + #footer {
    display: none;
  }
`;

export const SignCERNotice = styled(SignCERNoticeComponent)`
  margin: 1rem 2rem;
`;
