import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { PieChartWrapper } from './Pie.style';

const PieChart = ({ className, ...props }) => (
  <div className={className}>
    <CircularProgressbarWithChildren {...props} />
  </div>
);

export default (props) => <PieChartWrapper as={PieChart} {...props} />;
