import React from 'react';
import Modal, { CloseButton } from 'components/Modal';
import { Flex } from 'styles';
import { Heading, Text } from 'components/Typography';
import Button from 'components/Button/Button';
import { useReduxMutator } from 'utils/redux-query';
import DotsLoader from 'components/DotsLoader';

export const DeactivateSecondFactorModal = (props) => {
  const { isOpen, onClose } = props;
  const [deactivate2FA, { status: deactivate2FAStatus }] = useReduxMutator(
    'DEACTIVATE_SECOND_FACTOR',
  );

  const handleDeactivateClick = async () => {
    await deactivate2FA();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentStyle={{ maxWidth: '40rem' }}>
      <CloseButton onClick={onClose} />
      <Flex centered p="2rem">
        <Heading as="h3" textAlign="center">
          Restablecer segundo factor
        </Heading>
      </Flex>
      <Flex direction="column" p="2rem" borderColor="schema.gray.200" borderTop="1px solid">
        <Heading as="h4" textAlign="center">
          ¡Atención!
        </Heading>
        <Text textAlign="center">Deberás seleccionar un nuevo factor de autenticación</Text>
      </Flex>
      <Flex justifyContent="center" p="2rem" gap="2rem">
        <Button
          flexGrow="1"
          flexBasis="0"
          $type="btn-link"
          type="button"
          schema="gray"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          flexGrow="1"
          flexBasis="0"
          $type="primary"
          schema="blue"
          type="button"
          onClick={handleDeactivateClick}
          disabled={deactivate2FAStatus.pending}
        >
          {deactivate2FAStatus.pending ? <DotsLoader color="schema.white" /> : 'Restablecer'}
        </Button>
      </Flex>
    </Modal>
  );
};
