import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { t } from 'i18n';
import { Grid, Flex } from 'styles';
import Button from 'components/Button/Button';
import Link from 'components/Link';
import TextField from 'components/TextField/TextField';
import { Heading } from 'components/Typography';
import Divider from 'components/Divider';
import { Content } from './RFC.styles';
import validator from './validator';
import { controllerContext } from '../../controller/context';

const tOptions = { scope: 'expedientsProfileUpdate.rfc' };
const parseRFCField = (v) => v
  ?.slice(0, 10)
  .toUpperCase()
  .match(/[a-zA-Z0-9]/g)
  ?.join('') || '';

const parseRFCHomoClaveField = (v) => v
  ?.slice(0, 3)
  .toUpperCase()
  .match(/[a-zA-Z0-9]/g)
  ?.join('') || '';

function RFC() {
  const history = useHistory();
  const {
    profile, setProfile, trackStep, STEP_NAMES,
  } = useContext(controllerContext);
  const rfcUserData = profile?.rfc?.slice(0, 10);
  const rfcHomoClave = profile?.rfc?.slice(10, 13);

  const handleOnSubmit = (values) => {
    const { rfc, homoClave } = values;

    setProfile((currentProfile) => ({
      ...currentProfile,
      rfc: [rfc, homoClave].join(''),
    }));
    trackStep({ name: STEP_NAMES.RFC, hasUserTakenForm: true });
    history.push('../show');
  };

  return (
    <Content>
      <Heading mb="1rem">{t('title', tOptions)}</Heading>
      <Heading as="p">{t('subtitle', tOptions)}</Heading>

      <Form
        onSubmit={handleOnSubmit}
        initialValues={{ rfc: rfcUserData, homoClave: rfcHomoClave }}
        validate={validator}
      >
        {({ handleSubmit, submitting }) => (
          <Grid as="form" onSubmit={handleSubmit} rowGap="2rem" mt="2rem">
            <Grid templateColumns="15rem 7rem" columnGap="1rem" rowGap="0.5rem">
              <TextField
                gridColumn="1/2"
                color="schema.gray.700"
                textLabel={t('form.rfcField.textLabel', tOptions)}
                name="rfc"
                readOnly={Boolean(rfcUserData)}
                parse={parseRFCField}
              />
              <TextField
                textLabel="‎‎‎ ‎"
                gridColumn="2/2"
                color="schema.gray.700"
                name="homoClave"
                parse={parseRFCHomoClaveField}
              />
              <Link as="a" href={t('getRFCLink', tOptions)} target="blank" gridColumn="1 / -1">
                {t('getRFC', tOptions)}
              </Link>
            </Grid>
            <Divider w="100%" color="schema.gray.300" gridArea="2 / 1 / 2 / 3" />
            <Flex justifyContent="space-between" gridArea="3 / 1 / 3 / 3">
              <Button as={RouterLink} to="../show" $type="btn-link" type="button">
                {t('form.actions.cancel', tOptions)}
              </Button>
              <Button type="submit" disabled={submitting}>
                {t('form.actions.submit', tOptions)}
              </Button>
            </Flex>
          </Grid>
        )}
      </Form>
    </Content>
  );
}

export default RFC;
