import React, { useState } from 'react';
import { useTransition } from 'react-spring';
import { connect, useDispatch } from 'react-redux';
import withSizes from 'react-sizes';
import Modal from 'components/Modal';
import Tour from 'reactour';
import { updateUserSettings } from 'apis/user';
import { breakPoints } from 'config/constants';
import DotsLoader from 'components/DotsLoader';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { setSessionSetting } from 'actions/auth/session/session';
import { LoadingRequistions, RequisitionsListContainer } from './styles';
import FirstAddToCart from '../../../ModalContent/FirstAddToCart';
import InfoPill from './InfoPill';
import List from './List';
import MobileList from './List/Mobile';
import MobileActionBar from './MobileActionBar';
import EmptyState from '../EmptyState';
import BatchActionBar from './BatchActionBar';
import getTourSteps from './tourUtils';
import getModalStyles from './modalUtils';

const RequisitionsList = (props) => {
  const {
    requisitions,
    showEmptyState,
    onToggleFilter,
    toggleRequisitionSelection,
    selectedRequisitions,
    setHiddenState,
    hiddenState,
    showHiddenRequisitions,
    showFundedRequisitions,
    updateFilter,
    appliedFilter,
    cleanSelectedRequisitions,
    handleSortChange,
    sortOptions,
    loadingRequisitions,
    openCart,
    cartItems,
    cartTotal,
    width,
    toggleFilterFlag, // TODO: Check is this prop is necessary (no one is sending it)
  } = props;
  const dispatch = useDispatch();
  const [session] = useReduxQuery('SESSION');
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const { settings } = session;
  const selectedRequisitionsQuantity = selectedRequisitions.length;
  const [selectedRequisition, setSelectedRequisition] = useState({});
  const [highlighted, setHighlighted] = useState(0);
  const openModal = sessionStatus === null && settings.firstTicketCreated === false;
  const [openTour, setOpenTour] = useState(!settings.tour);

  const highlightRequisition = (req) => {
    const { id } = req;
    setSelectedRequisition(req);
    if (id === 'close') setHighlighted(0);
    if (highlighted > 0 && highlighted === parseInt(id, 10)) setHighlighted(0);
    if (highlighted === 0) setHighlighted(parseInt(id, 10));
  };
  const mobile = width <= breakPoints.smallBreakPoint;
  const large = width <= breakPoints.largeBreakPoint;
  const extraLarge = width <= breakPoints.extraLargeBreakPoint;

  const sideBarHidden = width < 700;

  const FROM_STYLES = { height: 0 };
  const TO_STYLES = { height: sideBarHidden ? 300 : 250 };
  const transition = useTransition(highlighted, null, {
    from: FROM_STYLES,
    enter: TO_STYLES,
    leave: FROM_STYLES,
    unique: true,
  });

  const batchTransition = useTransition(selectedRequisitionsQuantity >= 1, null, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 60 },
    leave: { opacity: 0, height: 0 },
    unique: true,
  });

  const handleModalClose = async () => {
    await updateUserSettings('firstTicketCreated', true);
    dispatch(setSessionSetting('firstTicketCreated', true));
  };

  const renderMobileBar = () => transition.map(({ item, key, props: animation }) => (
    item !== 0 && (
    <MobileActionBar
      highlightRequisition={highlightRequisition}
      currentHighlighted={highlighted}
      key={key}
      animation={animation}
      sideBarHidden={sideBarHidden}
      selectedRequisition={selectedRequisition}
    />
    )
  ));

  const renderActionBar = batchTransition.map(
    ({ item, key, props: animation }) => item && (
    <BatchActionBar
      selectedRequisitions={selectedRequisitions}
      selectedRequisitionsQuantity={selectedRequisitionsQuantity}
      showHiddenRequisitions={showHiddenRequisitions}
      updateFilter={updateFilter}
      appliedFilter={appliedFilter}
      cleanSelectedRequisitions={cleanSelectedRequisitions}
      key={key}
      animation={animation}
    />
    ),
  );

  const handleTourClose = async () => {
    setOpenTour(false);
    await updateUserSettings('tour', true);
    dispatch(setSessionSetting('tour', true));
  };
  const steps = getTourSteps(handleTourClose);
  const renderEmptyState = () => <EmptyState mobile={mobile} />;
  const renderList = () => {
    if (mobile) {
      return (
        <MobileList
          requisitions={requisitions}
          showFundedRequisitions={showFundedRequisitions}
          showHiddenRequisitions={showHiddenRequisitions}
          highlightRequisition={highlightRequisition}
          highlighted={highlighted}
          handleSortChange={handleSortChange}
          sortOptions={sortOptions}
          loadingRequisitions={loadingRequisitions}
        />
      );
    }
    return (
      <List
        requisitions={requisitions}
        toggleRequisitionSelection={toggleRequisitionSelection}
        setHiddenState={setHiddenState}
        showFundedRequisitions={showFundedRequisitions}
        showHiddenRequisitions={showHiddenRequisitions}
        hiddenState={hiddenState}
        updateFilter={updateFilter}
        appliedFilter={appliedFilter}
        selectedRequisitions={selectedRequisitions}
        handleSortChange={handleSortChange}
        sortOptions={sortOptions}
        loadingRequisitions={loadingRequisitions}
        cartTotal={cartTotal}
        large={large}
        extraLarge={extraLarge}
      />
    );
  };

  const renderLoading = () => (
    <LoadingRequistions>
      <DotsLoader black />
    </LoadingRequistions>
  );

  const renderListOrEmptyState = () => {
    if (loadingRequisitions) return renderLoading();
    if (showEmptyState) return renderEmptyState();
    return renderList();
  };

  return (
    <RequisitionsListContainer>
      <InfoPill
        onToggleFilter={onToggleFilter}
        toggleFlag={toggleFilterFlag}
        mobile={mobile}
        large={large}
        cartTotal={cartTotal}
      />
      {renderListOrEmptyState()}
      {mobile ? renderMobileBar() : renderActionBar}
      {cartItems.length > 0 && (
        <Modal
          closeTimeoutMS={100}
          isOpen={openModal}
          style={getModalStyles()}
          onRequestClose={handleModalClose}
          ariaHideApp={false}
        >
          <FirstAddToCart handleModalClose={handleModalClose} openCart={openCart} />
        </Modal>
      )}
      {requisitions && requisitions.length > 0 && (
        <Tour
          isOpen={openTour}
          steps={steps}
          onRequestClose={handleTourClose}
          rounded={5}
          showNavigationNumber={false}
          closeWithMask={false}
        />
      )}
    </RequisitionsListContainer>
  );
};

const mapSizesToProps = ({ width }) => ({ width });

const mapStateToProps = ({ cart }) => {
  const { cartItems } = cart;
  return {
    cartItems,
    cartTotal: cart.cartTotal,
  };
};

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(RequisitionsList));
