import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/Typography';
import { Flex } from 'styles';
import Image from 'components/Image/Image';
import ButtonIcon from 'components/Button/ButtonIcon';

const SecondStepHeader = (props) => {
  const {
    imageName, title, onClick, ...contentProps
  } = props;

  return (
    <Flex
      borderBottom="1px solid"
      columnGap="2rem"
      borderColor="schema.gray.200"
      alignItems="center"
      onClick={onClick}
      {...contentProps}
    >
      <ButtonIcon iconName="BACK" />
      <Image imageName={imageName} boxSize="54px" h="54px" flexShrink="0" />
      <Heading as="h3">{title}</Heading>
    </Flex>
  );
};

SecondStepHeader.propTypes = {
  imageName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SecondStepHeader;
