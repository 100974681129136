import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Text } from 'components/Typography';
import { t } from 'i18n';
import { dashboardRoute } from 'routes';
import { logOutUser } from 'actions/auth/fetcher';
import Settings from 'utils/sdkYTP/investorSettings';
import { publicUrls, IDENTITY_VERIFICATION_STATUSES } from 'config/constants';
import { useReduxLazyQuery, useReduxQuery } from 'utils/redux-query';

import {
  Container,
  Content,
  Header,
  Box,
  Image,
  HeaderContent,
  FAQLink,
  HeaderLogo,
  SeeMoreLink,
  CloseSessionLink,
} from './BlockedAccount.style';

function WaitingForCommittee() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const [KYCStatus, , { lazyQuery: fetchKYCStatus }] = useReduxLazyQuery('KYC_STATUS');
  const settings = Settings(session);
  const isBlockedAccount = settings.isBlockedAccount();

  useEffect(() => {
    if (!isBlockedAccount && KYCStatus !== IDENTITY_VERIFICATION_STATUSES.BLOCKED) {
      history.push(dashboardRoute);
    }
  }, [isBlockedAccount, KYCStatus]);

  useEffect(() => {
    if (isBlockedAccount) return;
    fetchKYCStatus();
  }, [isBlockedAccount, fetchKYCStatus]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <HeaderLogo />
          <FAQLink href={publicUrls.faq} target="blank">
            {t('common.FAQ')}
          </FAQLink>
          <CloseSessionLink onClick={() => dispatch(logOutUser())}>
            {t('common.exit')}
          </CloseSessionLink>
        </HeaderContent>
      </Header>
      <Content>
        <Text as="h1" lineHeight="3.5rem" size="3rem" mb="6rem" color="#04475C" align="center">
          {t('common.errors.titleError')}
        </Text>
        <Box>
          <Image title={t('common.processingInformation')} />
          <Text color="#677D85" align={{ base: 'center', md: 'left' }}>
            <Text
              as="h2"
              lineHeight="2.5rem"
              size="2rem"
              mb="2rem"
              color="#04475C"
              align={{ base: 'center', md: 'left' }}
            >
              {t('common.blockedAccount.invalidInvestor')}
            </Text>
            {t('common.blockedAccount.invalidAccount')}
            <br />
            <br />
            <SeeMoreLink href={publicUrls.blockedInvestorAccount} target="blank">
              {t('common.blockedAccount.moreInformation')}
            </SeeMoreLink>
          </Text>
        </Box>
      </Content>
    </Container>
  );
}

export default WaitingForCommittee;
