import styled from 'styled-components';
import { colors } from 'styles';

export const AnimoZapopanContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  .container {
    max-width: 76rem;
    padding: 3rem;
  }
  h1 {
    font-size: 3rem;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    color: ${colors.gray.g6};
  }
  h3 {
    color: ${colors.gray.g6};
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 2rem;
    text-align: left;
  }
  p {
    color: ${colors.gray.g5};
    font-size: 1.7rem;
    text-align: left;
    margin-bottom: 1.8rem;
    line-height: 150%;
  }
  ol {
    padding-left: 2rem;
    li {
      color: ${colors.gray.g5};
      font-size: 1.7rem;
      text-align: left;
      margin-bottom: 1.8rem;
      line-height: 150%;
      span.example {
        padding: .6rem;
        font-weight: bold;
        font-size: 1rem;
        background: #F3F3BB;
        box-shadow: 0 2px 4px 0 rgba(55,77,91,0.17);
        border-radius: 7px;
        color: ${colors.gray.g6};
      }
    }
  }
`;
