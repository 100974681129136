import { OTP_CODE_LENGHT_DEFAULT } from 'config/constants';
import { t } from 'i18n';

export const resolver = (values) => {
  const {
    otp,
    accept,
  } = values;
  const errors = {};
  if (accept && (!otp || otp.length !== OTP_CODE_LENGHT_DEFAULT)) errors.otp = { message: t('Forms.fields.errors.required') };
  if (!otp && !accept) errors.otp = { message: t('Forms.fields.errors.acceptTerms') };
  if (!accept) errors.accept = { message: t('Forms.fields.errors.required') };

  return {
    values,
    errors,
  };
};
