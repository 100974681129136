import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import * as Table from 'components/Table/Basic';

export const { Head, Cell } = Table;

export const Body = styled(Table.Body)``;

export const Header = styled(Table.Header)`
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: repeat(3, 1fr) 1.5fr;
    padding: 0 1rem;

    div:nth-child(2) {
      order: 1;
    }

    div:nth-child(1) {
      order: 2;
    }

    div:nth-child(5) {
      order: 3;
    }

    div:nth-child(3) {
      order: 4;
    }

    div:nth-child(4),
    div:last-child {
      display: none;
    }
  }
`;

export const Row = styled(Table.Row)`
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: repeat(3, 1fr) 1.5fr;
    padding: 0 1rem;

    div:nth-child(2) {
      order: 1;
    }

    div:nth-child(1) {
      order: 2;
    }

    div:nth-child(5) {
      order: 3;
    }

    div:nth-child(3) {
      order: 4;
    }

    div:nth-child(4),
    div:last-child {
      display: none;
    }
  }
`;
