import { useEffect } from 'react';
import { useReduxSubscription } from 'utils/redux-query';
import tracker from 'utils/tracker';

export const useTracker = () => {
  const [session] = useReduxSubscription('SESSION');

  useEffect(() => {
    tracker.init();
  }, []);

  useEffect(() => {
    if (!session?.user) return;

    tracker.addContext({
      current_user: session.user.email,
    });
  }, [session?.user]);
};
