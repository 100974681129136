import React from 'react';
import {
  Grid,
  Button,
} from 'ui';
import {
  PasswordAuthorizeField,
} from 'components/PasswordAuthorizeField';
import { useController } from './hooks';

export const UpdateAutoinvestForm = ({
  onCancel = () => {},
  onSuccess = () => {},
}) => {
  const {
    control,
    onSubmit,
    isLoading,
    otpFieldProps,
  } = useController({ onSuccess });

  return (
    <Grid as="form" onSubmit={onSubmit} gap="5" width="full">
      <PasswordAuthorizeField
        {...otpFieldProps}
        name="password"
        control={control}
        isDisabled={isLoading}
        gridArea="1 / 1 / 1 / 3"
      />
      <Button
        variant="outline"
        justifySelf="start"
        onClick={onCancel}
        isDisabled={isLoading}
        gridArea="2 / 1 / 2 / 2"
      >
        Cancelar
      </Button>
      <Button
        type="submit"
        justifySelf="end"
        isDisabled={isLoading}
        gridArea="2 / 2 / 2 / 3"
      >
        Guardar cambios
      </Button>
    </Grid>
  );
};
