import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import isObject from 'utils/Object/isObject';
import { Field, useField } from 'react-final-form';
import TextField from 'components/TextField/TextField';
import SelectField from 'components/SelectField/SelectField';
import DateField from 'components/DateField/DateField';
import StateSelectField from 'components/Form/CustomFields/StatesSelectField';
import RFCField from 'components/Form/CustomFields/RFCField';
import CURPField from 'components/Form/CustomFields/CURPField';
import FullNameField from 'components/Form/CustomFields/FullNameField';
import * as Table from 'components/Table/Basic';
import { Text } from 'components/Typography';
import ButtonIcon from 'components/Button/ButtonIcon';

const INPUTS_TYPES = {
  TEXT_FIELD: TextField,
  SELECT_FIELD: SelectField,
  STATE_SELECT_FIELD: StateSelectField,
  DATE_FIELD: DateField,
  RFC_FIELD: RFCField,
  CURP_FIELD: CURPField,
  FULL_NAME_FIELD: FullNameField,
};

const statusConfigs = {
  ACTIVE: {
    colorSchema: 'schema.blue',
    rowProps: {
      templateColumns: '1fr',
    },
  },
  DANGER: { colorSchema: 'schema.red' },
  NORMAL: { colorSchema: 'schema.gray' },
};

const STATUS = Object.keys(statusConfigs).reduce(
  (acc, status) => ({ ...acc, [status]: status }),
  {},
);

const ListItemForm = (props) => {
  const {
    name,
    format,
    parse,
    inputType,
    children,
    iconName,
    textLabel,
    inputProps = {},
    onBlur = () => {},
  } = props;
  const [status, setStatus] = useState(STATUS.NORMAL);
  const { meta } = useField(name);
  const fieldRef = useRef(null);
  const InputComponent = INPUTS_TYPES[inputType] || INPUTS_TYPES.TEXT_FIELD;
  const { colorSchema, rowProps = {} } = statusConfigs[status];

  const hasError = isObject(meta.error) ? Object.keys(meta?.error).length > 0 : meta.error;

  const handleOnBlur = useCallback(() => {
    if (!hasError) {
      onBlur();
      setStatus(STATUS.NORMAL);
    }
  }, [hasError]);

  useEffect(() => {
    if ([STATUS.ACTIVE, STATUS.DANGER].includes(status) && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [status]);

  useEffect(() => {
    if (hasError) {
      setStatus(STATUS.DANGER);
    }
  }, [hasError]);

  useEffect(() => {
    if (status === STATUS.NORMAL) {
      onBlur();
    }
  }, [status]);

  return (
    <Table.Row
      templateColumns="1fr 2rem"
      px="1rem"
      borderBottom="1px solid"
      borderColor={`${colorSchema}.400`}
      hasLastBorder
      {...rowProps}
    >
      <>
        <Table.Cell>
          <Text color="schema.gray.500" fontSize="1.4rem" px="0.5rem">
            {textLabel}
          </Text>
          {[STATUS.ACTIVE, STATUS.DANGER].includes(status) ? (
            <InputComponent
              key={inputType}
              name={name}
              variant="ghost"
              size="sm"
              color="schema.gray.700"
              onBlur={handleOnBlur}
              format={format}
              parse={parse}
              iconName={iconName}
              textHelperProps={{ mb: '0' }}
              overflow="hidden"
              ref={fieldRef}
              data-testid={name}
              px="0.5rem"
              {...inputProps}
            >
              {children}
            </InputComponent>
          ) : (
            <Field name={name} format={format} parse={parse} data-testid={name}>
              {({ input }) => (
                <Text
                  h="3rem"
                  lineHeight="3rem"
                  px="0.5rem"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {input.value}
                </Text>
              )}
            </Field>
          )}
        </Table.Cell>
        {status === STATUS.NORMAL && (
          <Table.Cell display="flex" alignItems="center">
            <ButtonIcon
              iconName="EDIT"
              onClick={() => setStatus(STATUS.ACTIVE)}
              data-testid={`${name}-editButton`}
            />
          </Table.Cell>
        )}
      </>
    </Table.Row>
  );
};

ListItemForm.defaultProps = {
  inputType: 'TEXT_FIELD',
};

ListItemForm.propTypes = {
  inputType: PropTypes.oneOf(Object.keys(INPUTS_TYPES)),
};

export default ListItemForm;
