import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { Text } from 'components/Typography';
import { Box, Flex } from 'styles';
import FieldBase from '../FieldBase/FieldBase';
import { SelectFieldInput } from './SelectField.style';

const iconSet = {
  CHEVRON_DOWN: (props) => <Text as={ChevronDown} {...props} />,
};

const sizes = {
  tiny: {
    input: {
      h: '2rem',
    },
    iconBox: {
      h: '2rem',
    },
  },
  sm: {
    input: {
      h: '3rem',
    },
    iconBox: {
      h: '3rem',
    },
  },
  md: {
    input: {
      h: '4.5rem',
    },
    iconBox: {
      h: '4.5rem',
    },
  },
};

const variants = {
  ghost: {
    input: {
      px: '0.5rem',
      py: '0',
      borderWidth: '0',
    },
  },
};

const SelectField = forwardRef((props, forwardedRef) => {
  const {
    name,
    textLabel,
    variant,
    iconName,
    color = 'schema.gray.700',
    placeholder,
    parse,
    readOnly,
    children,
    disabled,
    onBlur = () => {},
    onChange = () => {},
    size,
    inputProps = {},
    contentProps = {},
    ...rest
  } = props;
  const inputRef = useRef(null);
  const iconBoxRef = useRef(null);
  const inputSize = sizes[size] || sizes.md;
  const variantProps = variants[variant] || { base: {}, input: {} };
  const DropdownIcon = iconSet[iconName] || iconSet.CHEVRON_DOWN;
  const textColor = (value) => (!value ? 'schema.gray.400' : color);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  useEffect(() => {
    if (textLabel) {
      const inputOffsetTop = inputRef.current.offsetTop - 1;
      iconBoxRef.current.style.top = `${inputOffsetTop}px`;
    } else {
      iconBoxRef.current.style.top = 0;
    }
  }, [textLabel]);

  return (
    <Flex alignItems="center" position="relative" {...contentProps}>
      <FieldBase
        color={color}
        name={name}
        textLabel={textLabel}
        parse={parse}
        disabled={disabled}
        {...rest}
        format={undefined}
        w="100%"
      >
        {({ input, hasError }) => (
          <SelectFieldInput
            type="select"
            placeholder={placeholder}
            readOnly={readOnly}
            value={input.value}
            onChange={(ev) => {
              input.onChange(ev);
              onChange();
            }}
            onBlur={(ev) => {
              input.onBlur(ev);
              onBlur(ev);
            }}
            onFocus={input.onFocus}
            name={input.name}
            color={textColor(input.value)}
            pr="3.5rem"
            w="100%"
            zIndex="1"
            bgColor="white"
            disabled={disabled}
            borderColor={hasError ? 'schema.red.500' : 'schema.gray.400'}
            {...variantProps.input}
            {...inputSize.input}
            data-testid={`${props['data-testid']}-input`}
            ref={inputRef}
            {...inputProps}
          >
            {placeholder && <Box as="option" value="">{placeholder}</Box>}
            {children}
          </SelectFieldInput>
        )}
      </FieldBase>
      {iconName
        && (
        <Flex
          centered
          position="absolute"
          w="3.5rem"
          right="0"
          zIndex="1"
          backgroundColor="transparent"
          pointerEvents="none"
          ref={iconBoxRef}
          {...inputSize.iconBox}
        >
          <DropdownIcon pointerEvents="none" />
        </Flex>
        )}
    </Flex>
  );
});

SelectField.defaultProps = {
  ...FieldBase.defaultProps,
  textLabel: '',
  iconName: 'CHEVRON_DOWN',
};

SelectField.propTypes = {
  ...FieldBase.propTypes,
  name: PropTypes.string.isRequired,
  textLabel: PropTypes.string,
  iconName: PropTypes.oneOf(Object.keys(iconSet)),
};

export default SelectField;
