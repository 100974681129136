import { gql, useMutation } from '@apollo/client';

export const PASSWORD_VALIDATOR_GQL = gql`
mutation ValidatePassword($input: ValidatePasswordMutationInput!) {
  validatePassword(input: $input) {
    errors
    isValid
    __typename
  }
}
`;

export const usePasswordMutation = (options) => useMutation(PASSWORD_VALIDATOR_GQL, options);
