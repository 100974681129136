import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { largeBreakPoint } = breakPoints;

const LeftBarContainer = styled.div`
  width: 41rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 14px 0 rgba(37, 103, 141, 0.1);
  overflow-y: scroll;
  overscroll-behavior-y: none;
  height: calc(100% + 56px);
  @media (max-width: ${largeBreakPoint}px) {
    width: 35rem;
    &.funded {
      width: 33rem;
    }
  }
`;

export default LeftBarContainer;
