import React from 'react';
import { Icon } from 'ui';

export const ReferralIcon = (props) => (
  <Icon viewBox="0 0 29 29" {...props}>
    <g id="Asset-Artboard-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="settings/icon/autoinvest-Icons/Menu/Config-Copy-7" fill="currentColor">
        <path d="M14.0556957,16.4623328 L8.4768207,16.4623328 C7.61769571,16.4623328 6.85161238,16.0140412 6.42507072,15.2636662 C6.00819572,14.5302079 6.01544572,13.6650413 6.44198739,12.9472913 C7.02923738,11.9649164 7.88957071,11.2072914 8.92390403,10.7469164 C8.54327904,10.2442498 8.32577904,9.62558317 8.32577904,8.98154153 C8.32577904,7.35995827 9.64527903,6.04166667 11.265654,6.04166667 C12.8872373,6.04166667 14.2067373,7.35995827 14.2067373,8.98154153 C14.2067373,9.6267915 13.9892373,10.2454581 13.6061957,10.7493331 C14.4351123,11.1214998 15.1649457,11.6882081 15.7195706,12.4047497 C15.923779,12.6681664 15.8754456,13.047583 15.612029,13.2517913 C15.3461956,13.4547913 14.9679873,13.4088747 14.763779,13.1442497 C14.1596123,12.3636664 13.2859873,11.8078331 12.302404,11.5794581 C12.0619457,11.5238747 11.8794873,11.3269164 11.842029,11.0816248 C11.805779,10.8375414 11.9205707,10.5946665 12.1344457,10.4702081 C12.675779,10.1536248 12.998404,9.59658317 12.998404,8.98154153 C12.998404,8.02695824 12.2214457,7.24999994 11.265654,7.24999994 C10.3110707,7.24999994 9.53411236,8.02695824 9.53411236,8.98154153 C9.53411236,9.59658317 9.85673736,10.1536248 10.3980707,10.4702081 C10.6107374,10.5946665 10.7267374,10.8375414 10.689279,11.0816248 C10.653029,11.3269164 10.4705707,11.5238747 10.228904,11.5794581 C9.05802903,11.8525414 8.08169571,12.558208 7.47994571,13.5671663 C7.27573738,13.910333 7.27332071,14.3114996 7.47511238,14.6679579 C7.68415404,15.0340829 8.05873737,15.2539996 8.4768207,15.2539996 L14.0556957,15.2539996 C14.3891957,15.2539996 14.6598623,15.5246662 14.6598623,15.8581662 C14.6598623,16.1916662 14.3891957,16.4623328 14.0556957,16.4623328" id="Fill-1" />
        <path d="M17.9195838,12.4644418 C16.4889171,12.4644418 15.325292,13.6280668 15.325292,15.0587334 C15.325292,15.9806917 15.8086254,16.8144417 16.6182088,17.2881083 C16.8320838,17.413775 16.9468754,17.6554417 16.9106254,17.9007333 C16.8731671,18.1448167 16.6907088,18.341775 16.4502504,18.3985666 C14.7863754,18.7852333 13.400417,19.7881499 12.5461253,21.2212332 C12.2271253,21.7553165 12.2235003,22.4017749 12.5352503,22.9527748 C12.8615003,23.5255248 13.4463336,23.8686915 14.1012503,23.8686915 L21.7391256,23.8686915 C22.392834,23.8686915 22.9776673,23.5267332 23.3039173,22.9527748 C23.6156673,22.4029832 23.6120423,21.7553165 23.2942507,21.2212332 C22.4387506,19.7881499 21.0515839,18.7852333 19.3889172,18.3985666 C19.1484588,18.341775 18.9660005,18.1448167 18.9285422,17.9007333 C18.8922922,17.6554417 19.0070838,17.413775 19.2209588,17.2881083 C20.0305422,16.8144417 20.5138756,15.9806917 20.5138756,15.0587334 C20.5138756,13.6280668 19.3502505,12.4644418 17.9195838,12.4644418 M21.7391258,25.0770248 L14.1012504,25.0770248 C13.0065004,25.0770248 12.0289587,24.5054832 11.4852087,23.5484832 C10.9547503,22.6156499 10.964417,21.5148582 11.508167,20.6025666 C12.3358754,19.2141916 13.5768338,18.1629417 15.0703338,17.5672333 C14.4613338,16.8857333 14.1169588,16.0048584 14.1169588,15.0587334 C14.1169588,12.9622751 15.8231255,11.2561085 17.9195839,11.2561085 C20.0160424,11.2561085 21.7222091,12.9622751 21.7222091,15.0587334 C21.7222091,16.0048584 21.3790424,16.8857333 20.7688341,17.5672333 C22.2623341,18.1629417 23.5045008,19.2141916 24.3322092,20.6025666 L24.3322092,20.6025666 C24.8759592,21.5148582 24.8844176,22.6156499 24.3539592,23.5496915 C23.8102092,24.5054832 22.8326675,25.0770248 21.7391258,25.0770248" id="Fill-4" />
        <path d="M9.414125,22.1906796 C9.38270834,22.1906796 9.35008334,22.1882629 9.31625,22.1834295 C7.03491667,21.8124712 5.194625,20.0072211 4.84058334,17.7923461 C4.788625,17.4636794 5.01216667,17.154346 5.34204167,17.1011794 C5.67191667,17.049221 5.98245834,17.273971 6.03441667,17.6026377 C6.30629167,19.3088044 7.73575,20.7020128 9.51079167,20.9895962 C9.83945834,21.0439712 10.063,21.3545129 10.0098333,21.6831795 C9.9615,21.9804295 9.70533334,22.1906796 9.414125,22.1906796" id="Fill-7" />
        <path d="M7.40164591,23.0733673 C7.15031259,23.0733673 6.91710426,22.916284 6.83010426,22.666159 C6.7225626,22.3495756 6.8893126,22.006409 7.20468759,21.897659 L8.76827087,21.3611589 L8.23177089,19.7975756 C8.12302089,19.4809922 8.28977088,19.1378256 8.60635421,19.0290756 C8.92052086,18.9203256 9.26489585,19.0882839 9.37364585,19.4036589 L10.1071042,21.538784 C10.2158542,21.854159 10.0478958,22.1973256 9.73252084,22.3060756 L7.59860424,23.0407423 C7.53335424,23.0624923 7.46689591,23.0733673 7.40164591,23.0733673" id="Fill-10" />
      </g>
    </g>
  </Icon>
);
