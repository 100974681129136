import React from 'react';
import { t } from 'i18n';
import { CURRENT_YEAR, publicUrls } from 'config/constants';
import { Grid, Flex } from 'styles';
import {
  Icon,
  Text,
  Image,
  Divider,
  ExternalLink,
} from 'components';

const ts = (key) => t(key, { scope: 'Legal.default' });

const contacts = [
  {
    id: '1',
    content: t('common.yoteprestoTel'),
    iconName: 'CELLPHONE',
  },
  {
    id: '2',
    content: t('common.inversionistaEmail'),
    iconName: 'MAIL',
  },
  {
    id: '3',
    content: t('common.yoteprestoWhatsApp'),
    iconName: 'WHATS_APP',
  },
];

const FooterDashboard = (props) => {
  const {
    noLogo,
    ...contentProps
  } = props;

  return (
    <Grid id="footer" pb="2rem" {...contentProps}>
      {!noLogo && (
        <Flex centered pt="4rem" pb="2rem">
          <Image
            w="20rem"
            alt="brand name"
            borderRadius="0"
            bgColor="transparent"
            imageName="BRAND_NAME"
          />
        </Flex>
      )}
      <Grid rowGap="1rem" padding="1rem">
        <Flex direction={{ base: 'column', sm: 'row' }} columnGap="1.5rem" justifyContent="center">
          {contacts.map(({ content, iconName, id }) => (
            <Flex key={id} centered gap="0.5rem">
              <Icon boxSize="1.6rem" iconName={iconName} borderRadius="0" color="schema.gray.400" />
              <Text color="schema.gray.500" fontSize="1.4rem">
                {content}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent="center" gap="0.5rem">
          <Icon
            mt="0.3rem"
            borderRadius="0"
            boxSize="1.6rem"
            iconName="LOCATION"
          />
          <Text
            fontSize="1.4rem"
            textAlign="center"
            color="schema.gray.500"
            width={{ base: '28rem', sm: 'auto' }}
          >
            {ts('businessAddress')}
          </Text>
        </Flex>
      </Grid>
      <Divider />
      <Flex p="1rem" centered>
        <Text
          i18nKey="footer"
          fontSize="1.4rem"
          textAlign="center"
          color="schema.gray.500"
          t={ts}
          components={{
            year: CURRENT_YEAR,
            termsLink: (
              <ExternalLink t={ts} i18nKey="terms" href={publicUrls.termConditions} />),
            privacyLink: (
              <ExternalLink t={ts} i18nKey="privacy" href={publicUrls.privacy} />
            ),
          }}
        />
      </Flex>
    </Grid>
  );
};

export default FooterDashboard;
