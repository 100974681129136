import React from 'react';
import { t, lmed } from 'i18n';
import { Text } from 'components';
import { toCurrency } from 'utils/numberFormatters';
import PATHS from 'routes';
import Link from 'components/Link';
import {
  LoandsHistoryContainer, LoansHistoryTable, Table, GoToDetailLink,
} from './styles';

const { investorRequisitionPath, investorPortfolioRequisitionPath } = PATHS;

const PreviewsLoansTable = (props) => {
  const { pastCredits, requisitionId } = props;

  const isRequisitionLiquidated = (loan) => loan.status === t('detailRequisition.loansStatus.liquidated');

  const isLoanActive = (loan) => loan.status === t('detailRequisition.loansStatus.active');

  const isLentPreviously = (loan) => loan.lent_amount > 0;

  const showSettlementTextCell = (loan) => (loan.status === t('detailRequisition.loansStatus.liquidated')
    ? lmed(loan.liquidation)
    : loan.status);

  const calculateLiquidatedLoans = () => pastCredits
    .filter((loan) => isRequisitionLiquidated(loan)).length;

  const calculateColor = (loan) => (loan.status === t('detailRequisition.loansStatus.liquidated') ? null : loan.status_color);

  const calculateDisabledButton = (loan) => requisitionId === loan.zell_app_id;

  const calculateTextButton = (loan) => (isLentPreviously(loan) ? toCurrency(loan.lent_amount) : t('detailRequisition.see'));

  const calculatePath = (loan) => {
    const goRequisition = investorRequisitionPath({ requisitionId: loan.zell_app_id });

    const goPortfolio = investorPortfolioRequisitionPath(
      { requisitionId: loan.zell_app_id },
      { dispositionId: loan.disposition_id },
    );

    if (isLoanActive(loan)) {
      return goPortfolio;
    }

    return isRequisitionLiquidated(loan) ? goPortfolio : goRequisition;
  };

  return (
    <LoandsHistoryContainer>
      {calculateLiquidatedLoans(pastCredits) > 0
        ? `${t('detailRequisition.liquidatedLoans')} (${calculateLiquidatedLoans(pastCredits)})`
        : null}
      <LoansHistoryTable>
        <Table.Header>
          <Table.Head>{t('detailRequisition.liquidatedLoansHeader.settlement')}</Table.Head>
          <Table.Head>{t('detailRequisition.liquidatedLoansHeader.qualification')}</Table.Head>
          <Table.Head isHiddenMobile>
            <Text color="schema.white">
              {t('detailRequisition.liquidatedLoansHeader.rate')}
            </Text>
          </Table.Head>
          <Table.Head isHiddenMobile>
            <Text color="schema.white">
              {t('detailRequisition.liquidatedLoansHeader.destination')}
            </Text>
          </Table.Head>
          <Table.Head>{t('detailRequisition.liquidatedLoansHeader.amount')}</Table.Head>
          <Table.Head>{t('detailRequisition.liquidatedLoansHeader.term')}</Table.Head>
          <Table.Head>{t('detailRequisition.liquidatedLoansHeader.loan')}</Table.Head>
        </Table.Header>
        <Table.Body>
          {pastCredits.map((loan) => (
            <Table.Row key={loan.zell_app_id}>
              <Table.Cell>
                <Text color={calculateColor(loan)} align="center">
                  {showSettlementTextCell(loan)}
                </Text>
              </Table.Cell>
              <Table.Cell>{loan.qualification}</Table.Cell>
              <Table.Cell isHiddenMobile>{`${loan.rate}%`}</Table.Cell>
              <Table.Cell isHiddenMobile>{loan.destination}</Table.Cell>
              <Table.Cell>{toCurrency(loan.approved_amount)}</Table.Cell>
              <Table.Cell>{loan.term}</Table.Cell>
              <Table.Cell>
                {isLentPreviously(loan) && (
                <GoToDetailLink
                  isRedirect={calculateDisabledButton(loan)}
                  as={Link}
                  to={calculatePath(loan)}
                  $type="primary-outline"
                  $size="small"
                >
                  {calculateTextButton(loan)}
                </GoToDetailLink>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </LoansHistoryTable>
    </LoandsHistoryContainer>
  );
};

export default PreviewsLoansTable;
