import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { ResponsiveHelpers } from 'styles';
import FormLayout from 'views/FormLayout';
import Box from 'components/Box';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/phone-call.svg';
import JobStepForm from 'views/RegisterFunnel/components/JobStep';

export const AtentionBox = styled(Box)`
  padding: 2rem 4rem;
  margin: 4rem auto;
`;

export const Container = styled(FormLayout)`
  @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
    .form-section {
      padding: 0 1rem;
    }
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    .form-section {
      padding: 0 2rem;
    }
  }
`;

export const JobStep = styled(JobStepForm)`
  padding-top: 0;
  min-height: auto;

  .instructions,
  form {
    width: 600px;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    .buttons {
      button,
      button:last-child {
        width: 100%;
        margin: 0;
      }
    }

    .instructions,
    form {
      width: auto;
    }
  }
`;

export const PhoneIcon = styled(PhoneCallIcon)`
  min-height: 44px;
  min-width: 44px;
  margin-right: 2rem;
`;

export const Br = styled.br`""
  ${ResponsiveHelpers}
`;
