import React from 'react';
import PropTypes from 'prop-types';
import QRGenerator from 'qrcode.react';
import { Flex } from 'styles';

const SimpleQRCode = (props) => {
  const { authUrl } = props;
  return (
    <Flex justifyContent="center" padding="1.25rem 0">
      <QRGenerator value={authUrl} size={190} renderAs="svg" />
    </Flex>
  );
};

SimpleQRCode.propTpes = {
  authUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default SimpleQRCode;
