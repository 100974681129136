import {
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { useAddBankAccountForm } from './useAddBankAccountForm';
import { useBankCatalog } from './useBankCatalog';
import { useBankAccountsQuery } from './graphql/useBankAccount';

const defaultValues = {
  clabe: '',
  alias: '',
  bank: '',
  token: '',
};

export const useController = ({
  isOpen,
  onClose,
  bankAccountId,
}) => {
  const [
    secondFactor,
    { status: { completed: is2FAComplete } },
  ] = useReduxQuery('SECOND_FACTOR');

  const {
    data: bankAccountsData,
    loading: isBankAccountLoading,
  } = useBankAccountsQuery();

  const [profile] = useReduxQuery('PROFILE');
  const bankAccounts = bankAccountsData?.withdrawalAccounts || [];
  const bankAccount = bankAccounts?.find(({ id }) => id === bankAccountId);
  const isEditing = Boolean(bankAccount);
  const telephone = profile?.cellPhoneNumber;

  const {
    bankCatalog,
    getBankOptions,
    getIsCreditCard,
    isLoadingCatalog,
    getIsBankNotFound,
  } = useBankCatalog();

  const {
    watch,
    reset,
    errors,
    control,
    onSubmit,
    setValue,
    clearErrors,
    isLoading: isLoadingForm,
  } = useAddBankAccountForm({
    bankAccount,
    bankCatalog,
    onSuccess: onClose,
  });

  const clabe = watch('clabe');
  const bankOptions = useMemo(() => getBankOptions({ clabe }), [clabe]);
  const isCreditCard = getIsCreditCard({ clabe });
  const isBankNotFound = getIsBankNotFound({ clabe });

  const isBankEditable = bankOptions.length > 1;

  const isLoading = [
    isLoadingForm,
    isBankAccountLoading,
  ].includes(true);

  const onResendOTP = useCallback(() => {
    setValue('token', '');
    clearErrors('token');
  }, []);

  const isRequestOTPDisabled = [
    errors?.clabe,
    errors?.alias,
    errors?.bank,
  ].some(Boolean);

  const {
    showSMSContent,
    otpFieldProps,
  } = useAuthorizeWithOTP({
    telephone,
    onResendOTP,
    is2FAComplete,
    isRequestOTPDisabled,
    currentSecondFactor: secondFactor,
  });

  const fieldProps = {
    bank: {
      isDisabled: isEditing || isLoading || !isBankEditable,
    },
    clabe: {
      isDisabled: isLoading || isLoadingCatalog,
    },
  };

  const isSubmitDisabled = isLoading || isBankNotFound || isCreditCard;

  useEffect(() => {
    if (!isOpen) showSMSContent();
  }, [isOpen, showSMSContent]);

  useEffect(() => {
    if (!isOpen && !bankAccount) reset(defaultValues);
    if (!bankAccount) return;
    const values = {
      clabe: bankAccount.clabe,
      alias: bankAccount.clabeAlias,
      bank: bankAccount.bank,
      token: '',
    };
    reset(values);
  }, [isOpen]);

  useEffect(() => {
    if (isBankEditable) setValue('bank', '');
    else if (bankOptions?.length) {
      setValue('bank', bankOptions[0].name);
      clearErrors('bank');
    }
  }, [
    bankOptions,
    isBankEditable,
  ]);

  return {
    clabe,
    control,
    onSubmit,
    isEditing,
    isLoading,
    fieldProps,
    bankOptions,
    otpFieldProps,
    isSubmitDisabled,
  };
};
