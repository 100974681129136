import styled, { css } from 'styled-components';
import { boxHelpers } from './helpers';
import { propToRule, shouldForwardProp } from './core/propToRule';

export const Flex = styled.div.withConfig({
  shouldForwardProp,
})`
  display: flex;

  ${propToRule('flex-direction', 'direction')}
  ${(props) => (props.centered
    ? css`
      align-items: center;
      justify-content: center;
    `
    : '')}
  ${boxHelpers}
`;

export const Box = styled.div.withConfig({
  shouldForwardProp,
})`
  ${boxHelpers}
`;

export const Grid = styled.div.withConfig({
  shouldForwardProp,
})`
  display: grid;

  ${boxHelpers}
`;

export const Image = styled.img.withConfig({
  shouldForwardProp,
})`
  ${boxHelpers}
`;
