import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const FirstAuthorizedLoanContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.gray.g8};
  font-size: 1.4rem;
  padding: 2rem;
  .icon {
    &--close {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      :hover {
        cursor: pointer;
      }
    }
    &--success {
      margin-top: 2rem;
    }
  }
  p {
    :nth-of-type(2),
    :nth-of-type(3) {
      margin-bottom: 1.5rem;
    }
  }
  & .greenText {
    color: ${colors.green};
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  & .greyContainer {
    padding: 1.5rem 2rem;
    background-color: ${colors.gray.g2};
    margin-bottom: 1rem;
    text-align: center;
  }
  & .buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    button {
      padding: 2rem 4rem;
    }

    @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
      flex-direction: column;

      button {
        margin-bottom: 1rem;

        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default FirstAuthorizedLoanContent;
