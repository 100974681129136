import { tscope } from 'i18n';
import { useState } from 'react';

const ts = tscope('settings.account.contractPanel');

export const CONTRACT_TYPES = {
  AUTO_INVEST_CONTRACT: 'AUTO_INVEST_CONTRACT',
  FEE_CONTRACT: 'FEE_CONTRACT',
  MODIFIER_CONTRACT: 'MODIFIER_CONTRACT',
};

const getContractTypeByName = (name) => {
  const nameToType = {
    autoinvest: CONTRACT_TYPES.AUTO_INVEST_CONTRACT,
    'contrato comision prestamista': CONTRACT_TYPES.FEE_CONTRACT,
    'contrato modificador prestamista': CONTRACT_TYPES.MODIFIER_CONTRACT,
  };
  return nameToType[name];
};

export const buildContractList = (contracts) => Object.entries(contracts || {})
  .filter(([, data]) => !!data)
  .map(([key, contractData]) => ({
    contractData,
    name: ts(getContractTypeByName(key)),
    type: getContractTypeByName(key),
  }));

export const useController = ({ contracts }) => {
  const [currentContract, setCurrentContract] = useState(null);
  const userContracts = buildContractList(contracts);
  const closeModal = () => setCurrentContract(null);
  const onOpenContract = (contract) => {
    setCurrentContract(contract);
  };

  return {
    closeModal,
    userContracts,
    onOpenContract,
    currentContract,
    isOpenModal: !!currentContract,
  };
};
