import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { isRequired } from 'utils/Validators/common';

function validator(values) {
  const { firstName, firstLastName } = values;

  return clearErrorsObject({
    firstName: isRequired(firstName),
    firstLastName: isRequired(firstLastName),
  });
}

export default validator;
