import React from 'react';
import { Icon } from 'ui';

export const LimitsIcon = (props) => (
  <Icon viewBox="0 0 29 29" {...props}>
    <g id="Config-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Settings/Autoinvest/First" transform="translate(-559.000000, -397.000000)">
        <g id="Group-2" transform="translate(559.000000, 397.000000)">
          <rect id="Rectangle" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="29" height="29" />
          <g id="Icons/Config/limit" fill="currentColor">
            <path d="M20.0148333,7.25 L14.670375,7.25 C14.3356667,7.25 14.0662083,7.52066667 14.0662083,7.85416667 C14.0662083,8.18766667 14.3356667,8.45833333 14.670375,8.45833333 L20.0148333,8.45833333 C20.0559167,8.45833333 20.0909583,8.47645833 20.1308333,8.4825 L8.4825,20.1308333 C8.47645833,20.0909583 8.45833333,20.0559167 8.45833333,20.0148333 L8.45833333,14.7440833 C8.45833333,14.4105833 8.18766667,14.1399167 7.85416667,14.1399167 C7.52066667,14.1399167 7.25,14.4105833 7.25,14.7440833 L7.25,20.0148333 C7.25,21.159125 8.181625,22.0895417 9.32470833,22.0895417 L14.539875,22.0895417 C14.8745833,22.0895417 15.1440417,21.8200833 15.1440417,21.485375 C15.1440417,21.151875 14.8745833,20.8812083 14.539875,20.8812083 L9.44070833,20.8812083 L20.8812083,9.4395 L20.8812083,14.7440833 C20.8812083,15.0787917 21.151875,15.34825 21.485375,15.34825 C21.8200833,15.34825 22.0895417,15.0787917 22.0895417,14.7440833 L22.0895417,9.32470833 C22.0895417,8.181625 21.159125,7.25 20.0148333,7.25" id="Fill-1" />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
