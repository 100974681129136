import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components/Typography';
import { Flex } from 'styles';
import Image from 'components/Image/Image';

const SecondFactorModalHeader = (props) => {
  const { imageName, title, ...contentProps } = props;

  return (
    <Flex
      borderBottom="1px solid"
      columnGap="2rem"
      borderColor="schema.gray.200"
      alignItems="center"
      {...contentProps}
    >
      <Image imageName={imageName} boxSize="54px" h="54px" flexShrink="0" />
      <Heading as="h3">{title}</Heading>
    </Flex>
  );
};

SecondFactorModalHeader.propTypes = {
  imageName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SecondFactorModalHeader;
