import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import Modal from 'components/Modal';

export const IntroductionVideoModal = styled(Modal)`
  padding: 0 !important;
  border: none;
  width: 640px !important;
  max-width: 640px !important;
  box-sizing: content-box;
  background-color: transparent !important;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    box-sizing: border-box;
    background-color: transparent !important;

    iframe {
      max-height: calc(100vw / 1.7778128);
      width: 100%;
      max-width: 100%;
    }
  }
`;
