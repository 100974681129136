import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import {
  Label,
  TextHelper,
  SelectInput,
  FieldContent,
} from './DateField.styles';
import { useController } from './hooks/useController';

const DateField = (props) => {
  const {
    name,
    spanYears,
    startingYearAt,
    onBlur = () => {},
    color = 'schema.gray.700',
    disabled,
    ...rest
  } = props;

  const {
    day,
    year,
    month,
    input,
    hasError,
    yearOptions,
    daysOptions,
    inputDayRef,
    inputYearRef,
    inputMonthRef,
    monthsOptions,
    statusVariant,
    onBlurHandler,
    changeDayValueHandler,
    changeYearValueHandler,
    changeMonthValueHandler,
  } = useController({
    name,
    color,
    onBlur,
    disabled,
    spanYears,
    startingYearAt,
  });

  return (
    <FieldContent disabled={disabled} {...rest}>
      <Label htmlFor="day" gridArea="1/1" {...statusVariant}>
        {t('components.dateField.day')}
      </Label>
      <SelectInput
        id="day"
        type="select"
        value={day}
        onChange={changeDayValueHandler}
        onBlur={onBlurHandler}
        onFocus={input.onFocus}
        name={input.name}
        gridArea="2/1"
        color={color}
        data-testid={`${name}-day`}
        hasError={hasError}
        disabled={disabled}
        ref={inputDayRef}
      >
        <option value="">{t('common.na-')}</option>
        {daysOptions}
      </SelectInput>
      <Label htmlFor="month" gridArea="1/2" {...statusVariant}>
        {t('components.dateField.month')}
      </Label>
      <SelectInput
        id="month"
        type="select"
        value={month}
        onChange={changeMonthValueHandler}
        onBlur={onBlurHandler}
        onFocus={input.onFocus}
        name={input.name}
        gridArea="2/2"
        ref={inputMonthRef}
        color={color}
        disabled={disabled}
        hasError={hasError}
        data-testid={`${name}-month`}
      >
        <option value="">{t('common.na-')}</option>
        {monthsOptions}
      </SelectInput>
      <Label htmlFor="year" gridArea="1/3" {...statusVariant}>
        {t('components.dateField.year')}
      </Label>
      <SelectInput
        id="year"
        type="select"
        value={year}
        onChange={changeYearValueHandler}
        onBlur={onBlurHandler}
        onFocus={input.onFocus}
        name={input.name}
        gridArea="2/3"
        color={color}
        ref={inputYearRef}
        disabled={disabled}
        hasError={hasError}
        data-testid={`${name}-year`}
      >
        <option value="">{t('common.na-')}</option>
        {yearOptions}
      </SelectInput>
      {hasError
      && (
      <TextHelper
        gridColumn="1 / 3"
        data-testid={`${name}.errorText`}
      >
        {hasError}
      </TextHelper>
      )}
    </FieldContent>
  );
};

DateField.defaultProps = {
  startingYearAt: 1960,
};

DateField.propTypes = {
  startingYearAt: PropTypes.number,
};

export default DateField;
