import React from 'react';
import ReactTooltip from 'react-tooltip';
import { P } from 'components/Typography';
import Link from 'components/Link';
import colorsMap from 'styles/colors';
import { t } from 'i18n';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { toCurrency } from 'utils/numberFormatters';
import PATHS from 'routes';
import * as Table from './style';

const { investorOrdersRequisitionPath } = PATHS;

const OrdersTable = (props) => {
  const { orders } = props;
  const displayDetailsLink = (order) => !['canceled'].includes(order.status)
    && (!['declined'].includes(order.status) || order.alreadyLent);

  return (
    <section {...props}>
      <Table.Header>
        <Table.Head>{t('orders.id')}</Table.Head>
        <Table.Head>{t('orders.creation')}</Table.Head>
        <Table.Head>{t('orders.status')}</Table.Head>
        <Table.Head>{t('orders.calRate')}</Table.Head>
        <Table.Head>
          <P isHiddenMobile>{t('orders.lendedAmount')}</P>
          <P isOnlyMobile>{t('orders.amount')}</P>
        </Table.Head>
        <Table.Head />
      </Table.Header>
      <Table.Body>
        {orders.map((order) => (
          <Table.Row key={order.createdAt}>
            <Table.Cell align="center">{order.ticketId}</Table.Cell>
            <Table.Cell align="center">
              <P>{order.createdAt}</P>
            </Table.Cell>
            <Table.Cell align="center">
              <P color={colorsMap.orderStatus[order.status]} align="center" isOnlyMobile>
                {order.humanizeStatus}
              </P>
              <P color={colorsMap.orderStatus[order.status]} align="center" isHiddenMobile>
                {order.humanizeStatus}
                {' '}
                {order.status === 'declined' && (
                <>
                  <QuestionMarkIcon
                    title=""
                    data-tip
                    data-for={`tooltip-declined-order${order.ticketId}`}
                  />
                  <ReactTooltip id={`tooltip-declined-order${order.ticketId}`}>
                    <p>{order.errorMessage}</p>
                  </ReactTooltip>
                </>
                )}
              </P>
            </Table.Cell>
            <Table.Cell align="center">{order.qualification}</Table.Cell>
            <Table.Cell align="center">{toCurrency(order.amount)}</Table.Cell>
            <Table.Cell align="center">
              {displayDetailsLink(order) && (
              <Link
                to={{
                  pathname: investorOrdersRequisitionPath(
                    { requisitionId: order.zellAppId },
                    { dispositionId: order.dispositionId },
                  ),
                }}
                color="#63B0C8"
              >
                {t('orders.seeRequisition')}
              </Link>
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </section>
  );
};

export default OrdersTable;
