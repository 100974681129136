import React from 'react';
import { tscope } from 'i18n';
import PATHS from 'routes';
import { Text } from 'components/Typography';
import { Link } from 'react-router-dom';
import AlertModal from 'components/AlertModal/AlertModal';
import { useReduxQuery } from 'utils/redux-query';

const t = tscope('Dashboard.alerts.needsUpdateInfo');

export const NeedsUpdateAlert = (props) => {
  const { alertIsOpen, closeAlert, name } = props;
  const [, { isLoading }] = useReduxQuery('PERSONAL_ARCHIVES');

  if (isLoading) return null;

  return (
    <AlertModal
      title={t('title', { name })}
      isOpen={alertIsOpen}
      onClose={closeAlert}
      status="warn"
      body={(
        <Text
          t={t}
          i18nKey="body"
          textAlign="center"
        />
      )}
      acceptText={t('action')}
      buttonProps={{
        as: Link,
        to: PATHS.cnbvPath(),
        onClick: closeAlert,
      }}
    />
  );
};
