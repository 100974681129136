import * as API from 'apis/user';
import { createAsyncAction, createActions } from 'utils/redux-query';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';

export const { update: updateProfileAction } = createActions('PROFILE');

export const fetchProfile = createAsyncAction('PROFILE', () => async () => {
  const { profile } = await API.fetchProfile();

  return profile;
});

export const updateProfile = createAsyncAction('UPDATE_PROFILE', (profile) => async (dispatch) => {
  const { profile: profileUpdated } = await API.updateProfile(profile);

  dispatch(updateProfileAction(profileUpdated, { status: 'COMPLETED', error: null }));

  return profileUpdated;
});

export const updateExpedientProfile = createAsyncAction(
  'UPDATE_EXPEDIENT_PROFILE',
  ({ profile }) => async () => {
    const { profile: profileUpdated } = await API.updateExpedientProfile({ profile });

    return profileUpdated;
  },
);

/**
 * About Address
 */

const { update: updateProfileAddressAction } = createActions('PROFILE_ADDRESS');

export const fetchProfileAddress = createAsyncAction('PROFILE_ADDRESS', () => async () => {
  const address = await API.getProfileAddress();

  return address;
});

export const createProfileAddress = createAsyncAction(
  'CREATE_PROFILE_ADDRESS',
  (address) => async (dispatch, getState) => {
    const newAddress = await API.createProfileAddress(address);

    dispatch(updateProfileAddressAction(newAddress, { status: 'COMPLETED', error: null }));

    /**
     * Refetch personal archives only if was loaded previously
     * since those mati_status & personal archives resources has same dependency status for ID
     */
    const {
      meta: { status: personalArchivesStatus },
    } = getState().PERSONAL_ARCHIVES;

    if (personalArchivesStatus === 'COMPLETED') {
      dispatch(fetchPersonalArchives());
    }

    return newAddress;
  },
);

export const updateProfileAddress = createAsyncAction(
  'UPDATE_PROFILE_ADDRESS',
  (address) => async () => {
    const { profile: newAddress } = await API.updateProfileAddress(address);
    return newAddress;
  },
);
