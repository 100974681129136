import styled from 'styled-components';
import colors from 'styles/colors';
import chevronDownSvg from 'assets/chevronDown.svg';

const SelectContainer = styled.div`
  select {
    border: 1px solid;
    border-radius: 1rem;
    border-color: ${colors.gray.g3};
    background-color: white;
    outline: none;
    width: 100%;
    height: 4.2rem;
    padding: 0 0 0 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${colors.gray.g5};
    appearance: none;
    background-color: white;
    background-image: url(${chevronDownSvg});
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem;
  }
`;

export default SelectContainer;
