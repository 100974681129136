const cardStyle = {
  bgColor: 'schema.white',
  borderRadius: '1rem',
  boxShadow: '0px 5px 9px rgba(37, 103, 141, 0.0386801)',
};

export default {
  container: {
    rowGap: '2rem',
    maxWidth: '55rem',
  },
  title: {
    textAlign: 'center',
  },
  sections: {
    notice: {
      container: {
        ...cardStyle,
        p: '2rem',
        gap: '1rem',
        alignItems: { base: 'center', sm: 'flex-start' },
        direction: { base: 'column', sm: 'row' },
      },
      image: {
        flexGrow: '0',
        boxSize: '10rem',
        flexBasis: '10rem',
      },
      body: {
        flexGrow: '1',
        flexBasis: '5rem',
      },
    },
    termsListing: {
      container: {
        ...cardStyle,
        p: { base: '2rem', sm: '4rem' },
        gap: '2rem',
      },
    },
  },
  acceptButton: {
    width: { base: '100%', sm: 'auto' },
  },
};
