import { stringToBoolean } from 'utils/strings';

const levelKeys = {
  level_one: 'levelOne',
  level_two: 'levelTwo',
  level_three: 'levelThree',
};

function parseLimitsSchema(limits) {
  if (!limits) return null;

  const currentLevelKey = levelKeys[limits.currentLevel];

  const limitsSchema = {
    ...limits,
    transactionsAmountOnMonth: Number(limits?.accountFundedAmount),
    transactionsLeftAmount: Number(limits?.accountLeftAmount),
    transactionsPercentage: Number(
      (limits.accountFundedAmount * 100) / limits?.maxLimits[currentLevelKey]?.maxOperationLimit,
    ),
    currentLimit: limits?.maxLimits[currentLevelKey],
  };

  return limitsSchema;
}

function parseWarnLevelSchema(warnLevel) {
  if (!warnLevel) return {};

  const {
    warnLevel: level,
    accountPercentage,
    maxAccountValueLimit,
    accountValueOnUdis,
    transactionsPercentage,
    accountFoundedAmount,
    accountLeftAmount,
    maxTransactionLimit,
  } = warnLevel;

  const warnLevelSchema = {
    level,
    // accountValue
    accountPercentage: Number(accountPercentage),
    maxAccountValueLimit: Number(maxAccountValueLimit),
    accountValueOnUdis: Number(accountValueOnUdis),
    // Transactions
    transactionsPercentage: Number(transactionsPercentage),
    transactionsAmountOnMonth: Number(accountFoundedAmount),
    transactionsLeftAmount: Number(accountLeftAmount),
    maxTransactionLimit: Number(maxTransactionLimit),
  };

  return warnLevelSchema;
}

const parseSettings = (settings = {}) => Object.entries(settings).reduce(
  (acc, [setting, value]) => ({ ...acc, [setting]: stringToBoolean(value) }),
  {},
);

export const parseSession = (originalSession) => {
  const {
    settings: originalSettings,
    levelWarn,
    limits,
    level,
    ...user
  } = originalSession;
  let settings = parseSettings(originalSettings);

  const blockedWrongInfo = user.status === 'partially_blocked';

  settings = settings
    ? {
      ...settings,
      needsUpdateExpedients: false,
      blockedWrongInfo,
    }
    : {};

  const invalidateLevelWarn = settings.blockedWrongInfo || settings.requiredUpdatePersonalInfo;

  return {
    user,
    settings,
    limits: limits ? parseLimitsSchema({ currentLevel: level, ...limits }) : null,
    levelWarn: invalidateLevelWarn ? null : parseWarnLevelSchema(levelWarn),
  };
};
