import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import Modal, { CloseButton } from 'components/Modal';
import { Flex } from 'styles';
import { Heading, Text } from 'components/Typography';
import Button from 'components/Button/Button';
import { useReduxMutator } from 'utils/redux-query';
import { showToastSuccess, showToastError } from 'utils/toastHandler';

const ts = (id, options) => t(id, { scope: 'Dashboard.activate2FAWizard.steps.deactivate2FA', ...options });

const Remove2FAModal = (props) => {
  const { isOpen, setIsOpen } = props;

  const [deactivate, { status: deactivateStatus }] = useReduxMutator('DEACTIVATE_SECOND_FACTOR');

  useEffect(() => {
    if (!deactivateStatus.completed || !isOpen) return;
    showToastSuccess(t('settings.security.reset2FASuccess'));
    setIsOpen(false);
  }, [deactivateStatus.completed, isOpen]);

  useEffect(() => {
    if (!deactivateStatus.failed || !isOpen) return;
    showToastError(t('settings.security.reset2FAFailure'));
  }, [deactivateStatus.failed, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setIsOpen}
      contentStyle={{ height: 'auto', maxWidth: '500px' }}
    >
      <CloseButton onClick={() => setIsOpen(false)} />
      <Flex justifyContent="center" p="3rem" borderBottom="1px solid" borderColor="schema.gray.200">
        <Heading as="h3">{ts('title')}</Heading>
      </Flex>
      <Flex justifyContent="center" alignItems="center" p="3rem" rowGap="2rem" direction="column">
        <Heading as="h4">{ts('atention')}</Heading>
        <Text>
          {' '}
          {ts('info')}
        </Text>
        <Flex justifyContent="center" columnGap="2rem">
          <Button $type="btn-link" onClick={() => setIsOpen(false)}>
            {ts('cancelButton')}
          </Button>
          <Button onClick={() => deactivate()}>{ts('okButton')}</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

Remove2FAModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Remove2FAModal;
