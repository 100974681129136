import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { tscope, jsx } from 'i18n';
import { investorSettings } from 'routes';
import WallpaperImage from 'assets/images/WithdrawalWallpaper.png';
import {
  Link,
  Card,
  Grid,
  Alert,
  Image,
  Heading,
  CardBody,
  AlertIcon,
  AlertDescription,
} from 'ui';
import { WithdrawalForm } from './components';
import FooterDashboard from '../FooterDashboard';
import { styles as s } from './Withdrawal.style';
import { ALERTS } from '../Alerts/Alerts';

const ts = tscope('Dashboard.Withdrawal');

export const Withdrawal = () => (
  <Grid {...s.container}>
    {ALERTS.map(({ id, Component }) => <Component key={id} m="2rem" />)}
    <Heading>{ts('title')}</Heading>
    <Grid {...s.content}>
      <Image {...s.wallpaper} src={WallpaperImage} />
      <Card {...s.card}>
        <CardBody {...s.cardBody}>
          <WithdrawalForm />
          <Alert {...s.bankAccountNote}>
            <AlertIcon />
            <AlertDescription>
              {jsx(
                'myBankAccountNote',
                {
                  t: ts,
                  link: (
                    <Link as={RouterLink} to={investorSettings}>
                      {ts('myBankAccountNoteLink')}
                    </Link>),
                },
              )}
            </AlertDescription>
          </Alert>
        </CardBody>
      </Card>
      <Alert {...s.noteAlert}>
        <AlertIcon />
        {ts('withdrawalNote')}
      </Alert>
    </Grid>
    <FooterDashboard />
  </Grid>
);
