import { useModalDisclosure } from 'hooks';
import { useReduxSubscription } from 'utils/redux-query';

export const useController = () => {
  const {
    isOpen,
    onClose,
    onOpen,
  } = useModalDisclosure();
  const [secondFactor, { status }] = useReduxSubscription('SECOND_FACTOR');

  return {
    isOpen,
    onClose,
    onOpen,
    status,
    secondFactor,
  };
};
