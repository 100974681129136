import styled from 'styled-components';
import { colors } from 'styles';

const ProductTypeContainer = styled.div`
  width: 100%;
  .acordeon {
    padding: 1.8rem;
    border-top: 1px solid ${colors.gray.g2};
    color: ${colors.gray.g5};
    font-size: 1.4rem;
    font-weight: 500;
    background: white;
    cursor: pointer;
    user-select: none;
    text-align: center;
    &:hover {
      background: ${colors.gray.g0};
      cursor: pointer;
    }
    .product {
      padding: 0.2rem 0.6rem;
      border-radius: 0.6rem;
      width: auto;
      border: 0;
      background: ${colors.gray.g2};
      font-weight: 600;
      margin-right: 1rem;
    }
    :focus {
      outline: none;
    }
  }
  .description {
    transition: all 0.5s ease-in-out;
    transform-origin: left top;
    max-height: 0;
    transform: scaleY(0);
    opacity: 0;
    box-sizing: border-box;
    padding: 0 1.5rem;
    p {
      font-size: 1rem;
      font-weight: 600;
    }
    &.is-open {
      transform: scaleY(1);
      max-height: 16rem;
      opacity: 1;
      padding: 1.5rem;
      &.zapopan-product {
        height: 7rem;
      }
    }
    &__moreInfo {
      text-decoration: underline;
      color: ${colors.blue};
      font-size: 1rem;
      :hover {
        cursor: pointer;
      }
      :focus {
        outline: none;
      }
      a,
      a:visited,
      a:hover,
      a:active {
        color: inherit;
      }
    }
    &__align-left {
      text-align: left;
    }
  }
`;

export default ProductTypeContainer;
