import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const TicketsTableContainer = styled.div`
  width: 100%;
  position: relative;
  display: table;
  .ticketsTable {
    &__row {
      display: table-row;
      :last-child div {
        border-bottom: none;
      }
      &.otp_code {
        position: relative;
      }
    }
    &__cell {
      display: table-cell;
      border-bottom: 1px solid #e3edf0;
      :first-child {
        padding-left: 5rem;
        text-align: left;
        position: relative;
      }
      :last-child {
        font-weight: 500;
        padding-right: 6rem;
        text-align: right;
      }
      span {
        color: red;
        font-size: 1rem;
        padding-left: 1rem;
        font-weight: bold;
        position: absolute;
        float: right;
        :hover {
          cursor: pointer;
        }
      }
    }
    &.otp_code {
      border-bottom: 1px solid #e3edf0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .secondFactor {
        line-height: 2rem;
        max-width: 50rem;
        .description__container {
          padding-top: 1rem;
          @media (min-width: ${breakPoints.extraLargeBreakPoint}px) {
            flex-direction: row;
            padding-top: 2rem;
          }
        }
      }
    }
    .otp__form {
      padding-top: 0.5rem;
      @media (max-width: ${breakPoints.extraLargeBreakPoint}px) {
        flex-direction: column;
        button {
          margin-left: 0;
          margin-top: 1rem;
          align-self: center;
        }
      }
    }
  }
`;

export default TicketsTableContainer;
