export const styles = {
  tableHeader: {
    templateColumns: {
      base: '10fr 4fr 4fr',
      xs: '10fr 2fr 2fr',
      md: '10fr 1fr 1fr',
    },
    placeItems: 'start',
    borderBottom: '1px solid',
    borderColor: 'schema.gray.200',
  },
  tableRow: {
    templateColumns: {
      base: '10fr 4fr 4fr',
      xs: '10fr 2fr 2fr',
      md: '10fr 1fr 1fr',
    },
  },
  actionCell: {
    justifySelf: 'center',
    textAlign: 'center',
  },
  fullName: {
    fontWeight: '600',
    textTransform: 'capitalize',
  },
};
