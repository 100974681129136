import styled, { css } from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors, Flex } from 'styles';

export const PromotingAppBanner = styled(Flex)`
  display: flex;
  border-radius: 4px;
  background-color: #ebf0f7;
  overflow: hidden;
  padding: 1rem 0;
  align-items: center;
  box-shadow: ${colors.shadow.card};
`;

export const PreviewAppImage = styled.img`
  margin-left: -11px;
  object-fit: contain;
  width: 180px;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 250px;
  }

  @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
    width: 180px;
  }
`;

export const TextAndStores = styled.div`
  display: flex;
  padding: 2rem 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > p {
    flex-grow: 1;
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    padding: 0 2rem;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    & > p {
      font-size: 1.5rem;
    }
  }
`;

export const StoresContainer = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    width: 49%;
  }
`;

export const Store = styled.img`
  flex-grow: 2;
  width: calc(100%);
  max-width: 12rem;
  object-fit: contain;
`;

export const P = styled.p`
  font-size: 2rem;
  display: inline;

  ${(props) => css`
    color: ${props.color};
    font-weight: ${props.weight};
    text-align: ${props.align};
  `}
`;
