import { selectTab } from 'actions/dashboard';
import { LIMIT_LEVELS, PROOF_OF_ADDRESS_STATUS } from 'config/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReduxQuery } from 'utils/redux-query';
import { SETTINGS_ITEM } from 'views/SideBar/utils';
import createSchemaMap from '../config/createSchemeMap';

export const useController = () => {
  const dispatch = useDispatch();
  const [session] = useReduxQuery('SESSION');
  const { limits, settings, user } = session || {};
  const {
    status: proofOfAddressStatus,
    rejectReason: rejectingReason,
  } = user?.proofOfAddress || {};
  const displayProofOfAddressOnReview = [
    limits.currentLevel === LIMIT_LEVELS.LEVEL_ONE,
    proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.ON_REVIEW,
  ].every(Boolean);
  const isProofOfAddressRejected = proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.REJECTED;
  const currentLevel = limits?.currentLevel;
  const isAddressOnReview = user?.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.ON_REVIEW;
  const isAddressPending = user?.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.PENDING;

  const {
    maxLimits: { disclaimer, levelOne, levelTwo },
  } = limits;

  const level1Config = createSchemaMap.step1({
    currentLevel: limits?.currentLevel,
    requirement: limits?.requirementsNextLevel,
    maxAccountValueLimit: levelOne?.maxAccountLimit,
    maxTransactionLimit: levelOne?.maxOperationLimit,
  });
  const level2Config = createSchemaMap.step2({
    isAddressPending,
    isAddressOnReview,
    proofOfAddressStatus,
    displayProofOfAddressOnReview,
    currentLevel: limits?.currentLevel,
    requirements: limits?.requirementsNextLevel,
    maxTransactionLimit: levelTwo?.maxOperationLimit,
  });
  const level3Config = createSchemaMap.step3({
    currentLevel: limits?.currentLevel,
    requirements: limits?.requirementsNextLevel,
    clabeValidated: settings?.clabeValidated,
  });

  useEffect(() => {
    dispatch(selectTab(SETTINGS_ITEM));
  }, []);

  return {
    levelOne,
    disclaimer,
    currentLevel,
    level1Config,
    level2Config,
    level3Config,
    rejectingReason,
    isProofOfAddressRejected,
    displayProofOfAddressOnReview,
  };
};
