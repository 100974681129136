import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import get from 'lodash.get';
import cls from 'classnames';
import { t } from 'i18n';
import { formatQuantityWhitK, toCurrency, toCurrencyWithDecimals } from 'utils/numberFormatters';
import {
  deleteCartTicket,
  updateTotalCart,
  updateSingleToCart,
  authorizeTickets,
  getCartList,
  getCartDetails,
  updateStatesAfterAuthorize,
} from 'actions/cart/cartActions';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/trashcan.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { showToast } from 'utils/toastHandler';
import DotsLoader from 'components/DotsLoader';
import DiversificationDescriptions from 'components/DiversificationDescriptions';
import { MAX_LENDING_AMOUNT } from 'config/constants';
// TODO remove after diversification beta has finish
import { FeaturesContext, Feature } from 'utils/featureFlags';
import SecondFactorCTA from 'views/DashboardContent/Activate2FAWizard/SecondFactorCTA';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import ButtonIcon from 'components/Button/ButtonIcon';
import CartContainer, { CheckoutContent } from './styles';
import { getListIcon } from '../DashboardContent/Requisitions/icons';

const getColor = (qualification) => {
  switch (qualification.slice(0, 1)) {
    case 'A':
      return '#00BDCA';
    case 'B':
      return '#0069FF';
    case 'C':
      return '#703CDE';
    default:
      return '#E7EBEF';
  }
};

// TODO - Refactor for functional component
class CartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      inputValuesOG: [],
      inputValues: [],
      errors: [],
      refreshData: false,
      authorizingTickets: false,
      ticketsErrors: [],
      approvedTicket: {},
      authorizedTotal: 0,
    };
    this.updateCartItems = this.updateCartItems.bind(this);
    this.updateTotal = this.updateTotal.bind(this);
    this.onDeleteTicket = this.onDeleteTicket.bind(this);
    this.onAuthorizeTicket = this.onAuthorizeTicket.bind(this);
    this.reloadCartCloseAuth = this.reloadCartCloseAuth.bind(this);
  }

  async componentDidMount() {
    const { onCloseCart, updateCartList } = this.props;
    getCartDetails();

    const element = document.getElementById('changePhoneLink');
    if (element) element.addEventListener('click', onCloseCart);
    this.setState({ refreshData: true });
    try {
      await updateCartList();
      this.setState({ refreshData: false });
      this.updateCartItems();
    } catch (e) {
      showToast('error', 'Hubo un error al cargar los tickets');
    }
  }

  componentDidUpdate(prevProps) {
    const { cartItems } = prevProps;
    const { cartItems: newCartItem } = this.props;
    if (cartItems.length !== newCartItem.length) {
      this.updateCartItems();
    }
  }

  async onDeleteTicket(ticket, index, e) {
    const { deleteTicket, updateCartTotal, cartItems } = this.props;
    this.setState({ refreshData: true });
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    try {
      await deleteTicket(ticket);

      const newTickets = cartItems.filter((nextTicket) => nextTicket.id !== ticket.id);
      const newInputValues = newTickets.map((v) => parseInt(v.amount, 10));
      const newTotal = this.updateTotal(0, index);
      this.setState({
        total: newTotal,
        inputValues: newInputValues,
        refreshData: false,
        errors: [],
      });
      updateCartTotal(newTotal);
      showToast('success', 'Solicitud eliminada del carrito');
    } catch (error) {
      showToast('error', 'Error al eliminar el ticket');
    }
    this.setState({ refreshData: false });
  }

  async onAuthorizeTicket(otp) {
    const { onCloseCart, authorizeCartTickets } = this.props;
    const { total } = this.props;
    this.setState({
      refreshData: true,
    });

    try {
      await authorizeCartTickets(otp);
      this.setState({
        refreshData: false,
        ticketsErrors: [],
        ticketsAuthorized: [],
        authorizingTickets: true,
        authorizedTotal: total,
      });
      onCloseCart();
    } finally {
      this.setState({ refreshData: false });
    }
  }

  updateTotal(value, index) {
    const { inputValues } = this.state;
    const filterValues = value ? inputValues.filter((itm, ind) => index !== ind) : inputValues;
    const newTotal = filterValues.reduce(
      (acc, current) => parseInt(acc, 10) + parseInt(current, 10),
      0,
    );
    return newTotal + value;
  }

  updateCartItems() {
    const { cartItems } = this.props;
    const errors = [];
    const inputValues = cartItems.map((elm, index) => {
      const remaining = parseInt(get(elm, 'requisition.remaining_amount'), 10);
      const amount = parseInt(elm.amount, 10);
      if (amount > remaining) {
        errors.push({
          id: 2,
          index,
          message: `El valor no debe ser mayor al faltante de la solicitud ${toCurrency(
            remaining,
          )}`,
        });
      }
      return amount;
    });
    const sumTotal = cartItems.reduce((a, b) => a + parseInt(b.amount, 10), 0);
    this.setState({
      total: sumTotal,
      inputValuesOG: inputValues,
      inputValues,
      errors,
    });
  }

  async reloadCartCloseAuth() {
    const { updateCartList } = this.props;
    this.setState({ refreshData: true, authorizingTickets: false });
    try {
      await updateCartList();
      this.updateCartItems();
      this.setState({ refreshData: false });
    } catch (e) {
      showToast('error', 'error al actualizar el carrito');
    }
  }

  render() {
    const {
      errors, refreshData, authorizingTickets, inputValues, inputValuesOG,
    } = this.state;
    const {
      onCloseCart,
      availableBalance,
      responsiveIs,
      cartItems,
      updateTicket,
      diversificationLimitAmounts,
    } = this.props;
    const isMobile = responsiveIs.mobile;
    const regex = /^[0-9\b]+$/;
    const totalInCart = cartItems.reduce((a, b) => a + parseInt(b.amount, 10), 0);

    return (
      <FeaturesContext.Consumer>
        {({ diversification }) => {
          const validateInput = async (value, index) => {
            const errArr = [];
            const { requisition, requisition_id: requisitionId, amount } = cartItems[index];
            const totalCartByRequisition = cartItems
              .filter((itm) => itm.requisition_id === requisitionId)
              .reduce((acc, valueAmount) => acc + parseInt(valueAmount.amount, 10), 0)
              - parseInt(amount, 10);
            const remainingAmount = parseInt(requisition.remaining_amount, 10);
            const totalLentValue = totalCartByRequisition + value;
            const totalCartAccumulate = cartItems.reduce((a, b) => a + parseInt(b.amount, 10), 0)
              - parseInt(cartItems[index].amount, 10)
              + value;
            const limitAmount = diversificationLimitAmounts?.[requisitionId.toString()] || 0;
            if (value > availableBalance) {
              errArr.push({
                id: 0,
                index,
                message: 'La cantidad es mayor al monto disponible',
              });
            } else if (
              totalLentValue > availableBalance
              || totalCartAccumulate > availableBalance
            ) {
              errArr.push({
                id: 4,
                index,
                message: 'El total de tus tickets es mayor a tu disponible',
              });
            }
            if (!(value % 100 === 0)) {
              errArr.push({
                id: 1,
                index,
                message: 'El valor debe ser múltiplo de 100',
              });
            }
            if (value > remainingAmount) {
              errArr.push({
                id: 2,
                index,
                message: 'El valor no debe ser mayor al faltante de la solicitud',
              });
            } else if (totalLentValue > remainingAmount) {
              errArr.push({
                id: 5,
                index,
                message: 'El total de tus tickets es mayor al restante de la solicitud',
              });
            }
            if (value < 200 && !cartItems[index]['already_borrowed?']) {
              errArr.push({
                id: 3,
                index,
                message: 'El valor debe ser mayor o igual a $200',
              });
            }
            if (value < 100 && cartItems[index]['already_borrowed?']) {
              errArr.push({
                id: 6,
                index,
                message: 'El valor debe ser mayor o igual a $100',
              });
            }
            if (totalLentValue > MAX_LENDING_AMOUNT) {
              errArr.push({
                id: 7,
                index,
                message: `Este valor no debe ser mayor que ${toCurrency(MAX_LENDING_AMOUNT)}`,
              });
            }
            if (diversification && value > limitAmount + Number(cartItems[index].amount)) {
              if (limitAmount <= 0) {
                errArr.push({
                  id: 8,
                  index,
                  message: t('listRequisition.diversification.limitReached'),
                });
              } else {
                errArr.push({
                  id: 8,
                  index,
                  message: t('listRequisition.diversification.investBetter', {
                    limitAmount: toCurrency(limitAmount),
                  }),
                });
              }
            }
            if (errArr.length > 0) {
              this.setState((pState) => ({
                ...pState,
                errors: [
                  ...pState.errors.filter(
                    (e) => e.index !== index && pState.errors.some((err) => err.id === e.id),
                  ),
                  errArr[0],
                ],
              }));
            } else {
              this.setState({
                errors: errors.filter((e) => e.index !== index),
              });
              this.setState({ refreshData: true });
              const response = await updateTicket({ id: cartItems[index].id, amount: value });
              if (!response) {
                this.setState({ refreshData: false });
              }
              this.setState((state) => ({
                refreshData: false,
                total: response ? this.updateTotal(value, index) : state.total,
              }));
            }
          };
          const updateInput = async (index, value, validate) => {
            const newValues = inputValues.map((itm, ind) => {
              if (ind === index) {
                if (regex.test(value)) {
                  if (validate && parseInt(value, 10) !== parseInt(inputValuesOG[index], 10)) {
                    inputValuesOG[index] = value;
                    this.setState({ inputValuesOG });
                    validateInput(parseInt(value, 10), index);
                  }
                  return value;
                }
                if (value === '') return validate ? inputValuesOG[ind] : '';
                if (itm) return itm;
                return inputValuesOG[ind];
              }
              if (itm === '' && !value) {
                return inputValuesOG[ind];
              }
              return itm;
            });

            this.setState({
              inputValues: newValues,
            });
          };
          const ticketsTable = () => {
            const elements = [];
            cartItems.forEach((itm, index) => {
              const { requisition } = itm;
              const { remaining_amount: remainingAmount } = requisition;
              const funded = parseInt(remainingAmount, 10) <= 0;
              const inputInvalid = errors.some((i) => i.index === index);
              elements.push(
                <tr
                  className={`cart__tableData--table--rowData${funded ? ' ticketFunded' : ''}`}
                  data-for={funded ? 'tooltipCart' : null}
                  data-tip={funded ? 'Ya se encuentra fondeada' : null}
                >
                  <td className={cls('cart__tableData--table--rowData--id', { inputInvalid })}>
                    <span>{itm.zell_app_id}</span>
                  </td>
                  <td
                    style={{ color: funded ? '#C2D4DB' : getColor(itm.qualification) }}
                    className="cart__tableData--table--rowData--qualification"
                  >
                    <b>{itm.qualification}</b>
                    {' '}
                    {`${requisition.rate}%`}
                  </td>
                  {!isMobile && (
                    <td className="cart__tableData--table--rowData--destination">
                      {getListIcon(requisition.destination)}
                    </td>
                  )}
                  <td>{requisition.term}</td>
                  {!isMobile && <td>{formatQuantityWhitK(requisition.approved_amount)}</td>}
                  {!isMobile && (
                    <td className="cart__tableData--table--rowData--won">
                      <span>{toCurrencyWithDecimals(itm.interest_won)}</span>
                    </td>
                  )}
                  <td className={cls('cart__tableData--table--rowData--input', { inputInvalid })}>
                    <div>
                      <i>$</i>
                      <input
                        type="text"
                        value={inputValues[index]}
                        onClick={() => updateInput(index)}
                        onChange={(i) => updateInput(index, i.target.value, false)}
                        onBlur={(i) => updateInput(index, i.target.value, true)}
                        disabled={funded}
                      />
                    </div>
                  </td>
                  <td className="cart__tableData--table--rowData--delete">
                    <DeleteIcon title="" onClick={(e) => this.onDeleteTicket(itm, index, e)} />
                  </td>
                </tr>,
              );
              elements.push(
                <tr>
                  <td colSpan={8}>
                    {errors.map(
                      (i) => i.index === index && (
                      <div className="cart__tableData--table--rowError">
                        <WarningIcon title="" />
                        <p>{i.message}</p>
                      </div>
                      ),
                    )}
                  </td>
                </tr>,
              );
            });

            return (
              <>
                <table className="cart__tableData--table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Calif/Tasa</th>
                      {!isMobile && <th>Destino</th>}
                      <th>Plazo</th>
                      {!isMobile && <th>Monto</th>}
                      {!isMobile && <th>Ganancia</th>}
                      <th>Prestar</th>
                      <td />
                    </tr>
                  </thead>
                  <tbody>{elements}</tbody>
                </table>
                <ReactTooltip id="tooltipCart" getContent={(dataTip) => dataTip} />
              </>
            );
          };
          return (
            <CartContainer mobile={isMobile}>
              <div className="cart__header">
                <CartIcon title="" />
                <span>Carrito de préstamos</span>
                <ButtonIcon
                  iconName="CLOSE"
                  className="cart__header--close"
                  onClick={onCloseCart}
                />
              </div>
              {!authorizingTickets && (
                <>
                  {refreshData && (
                    <div className="cart__tableData--coverUp">
                      <DotsLoader black />
                    </div>
                  )}
                  <div className={`cart__tableData ${refreshData ? 'refreshing' : ''}`}>
                    {ticketsTable()}
                  </div>
                  <Feature name="diversification">
                    <div className="cart__diversification">
                      <DiversificationDescriptions />
                    </div>
                  </Feature>
                  <CheckoutContent className="cart__bottom" $disabled={refreshData}>
                    <div className="cart__bottom__numbers">
                      <div className="cart__bottom__numbers--available">
                        Disponible
                        {' '}
                        {availableBalance ? toCurrencyWithDecimals(availableBalance) : 'loading'}
                      </div>
                      <div className="cart__bottom__numbers--total">
                        Total:
                        {' '}
                        <span>
                          <b>{toCurrency(totalInCart)}</b>
                        </span>
                      </div>
                    </div>
                    <div className="cart__bottom__auth">
                      <SecondFactorCTA
                        disabled={refreshData}
                        errors={errors}
                        buttonText={t('orders.addOrders')}
                        actionToAuthorize={(otp) => this.onAuthorizeTicket(otp)}
                      />
                    </div>
                  </CheckoutContent>
                </>
              )}
            </CartContainer>
          );
        }}
      </FeaturesContext.Consumer>
    );
  }
}

const Cart = (props) => {
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [diversificationLimitAmounts] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');

  return (
    <CartComponent
      availableBalance={statements?.saldoDisponible}
      diversificationLimitAmounts={diversificationLimitAmounts}
      {...props}
    />
  );
};

const mapStateToProps = ({ cart, browser }) => {
  const { cartItems, cartTotal } = cart;
  const { is } = browser;

  return {
    cartItems,
    cartTotal,
    responsiveIs: is,
  };
};

const mapDispatchToProps = {
  getCartDetails,
  deleteTicket: deleteCartTicket,
  updateCartTotal: updateTotalCart,
  updateTicket: updateSingleToCart,
  authorizeCartTickets: authorizeTickets,
  updateCartList: getCartList,
  updateAfterAuthorize: updateStatesAfterAuthorize,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
