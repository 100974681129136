import React from 'react';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { useReduxQuery } from 'utils/redux-query';
import PATHS from 'routes';
import { t } from 'i18n';

function SignCERNotice(props) {
  const [session] = useReduxQuery('SESSION');
  const name = session?.user?.fullName?.split(' ')[0];

  return (
    <Alert
      title={t('common.Banners.welcomeWithName', { name })}
      message={t('common.Banners.acceptCER')}
      variant="subtle-outline"
      status="warn"
      actionButtonProps={{ children: t('common.complete'), as: Link, to: PATHS.investorCerPath() }}
      iconProps={{ w: '3rem', h: '3rem' }}
      w="100%"
      {...props}
    />
  );
}

export default SignCERNotice;
