import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { t } from 'i18n';
import { LIMIT_LEVELS } from 'config/constants';
import Step from './Step';
import AsideContainer from './styles';

const Aside = ({ verificationStep }) => {
  const [session] = useReduxQuery('SESSION');
  const { limits } = session;
  const isActive = (step) => verificationStep === step;
  const isChecked = (step) => verificationStep > step;

  const checkedIdentification = limits?.currentLevel === LIMIT_LEVELS.LEVEL_ONE
    && limits?.requirementsNextLevel?.userIdValidation;

  return (
    <AsideContainer>
      <div className="title">
        <span>{t('settings.limits.aside.level_two.title')}</span>
      </div>
      <div className="steps">
        <Step
          name={t('settings.limits.aside.level_two.stepThree')}
          number={1}
          checked={checkedIdentification || isChecked(1)}
          active={isActive(1)}
        />
        <Step
          name={t('settings.limits.aside.level_two.stepOne')}
          number={2}
          checked={isChecked(2)}
          active={isActive(2)}
          last
        />
      </div>
    </AsideContainer>
  );
};

export default Aside;
