import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

const fetch = async () => {
  const path = '/v2/investor/portfolio_investments';
  const method = 'get';
  const config = { method, path, log: true };

  const { data } = await request(config);

  return camelify(data);
};

export default fetch;
