import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchProfileCatalogs } from 'actions/catalogs';
import { t } from 'i18n';
import { Flex } from 'styles';
import { Text } from 'components/Typography';
import { showToastSuccess, showToastError } from 'utils/toastHandler';
import { dashboardRoute } from 'routes';
import { useReduxQuery, useReduxMutator } from 'utils/redux-query';
import { setSessionSetting } from 'actions/auth/session';
import {
  AtentionBox, Container, JobStep, PhoneIcon, Br,
} from './SubmitOccupation.style';

function SubmitOccupation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const [updateProfile] = useReduxMutator('UPDATE_PROFILE');

  const handleUpdateOccupation = async (occupationInfo) => {
    try {
      await updateProfile(occupationInfo);
      dispatch(setSessionSetting('needsOcupation', false));
      showToastSuccess(t('login.occupationUpdated'), {
        iconName: 'success',
      });
      history.push(dashboardRoute);
    } catch (error) {
      showToastError(t('common.updateProfileFailed'));
    }
  };

  useEffect(() => {
    if (session.settings.needsOcupation) {
      dispatch(fetchProfileCatalogs());
    } else {
      history.push(dashboardRoute);
    }
  }, [session.settings.needsOcupation]);

  return (
    <Container hideImageSection pt="p4rem">
      <Flex direction="column">
        <AtentionBox direction="column" justify="flex-start">
          <Text size="2.5rem" weight="bold" color="#04475C" transform="uppercase" lineHeight="6rem">
            {t('common.atention')}
          </Text>
          <Text size="1.6rem" color="#04475C" align="center">
            {t('common.regulationDisclaimer')}
          </Text>
        </AtentionBox>
        <JobStep goNextStep={handleUpdateOccupation} onSubmitText={t('common.send')} noReferral />
        <Flex mb="8rem" centered mt="4rem">
          <PhoneIcon title={t('common.yoteprestoTel')} />
          <Text color="#134052">
            {t('common.contact1')}
            {' '}
            <Text as="strong">
              {' '}
              {t('common.yoteprestoTel')}
            </Text>
            {' '}
            {t('common.contact2')}
            {' '}
            <Br isHiddenOnSmall />
            {' '}
            {t('common.contact3')}
            {' '}
            <Text as="strong">{t('common.inversionistaEmail')}</Text>
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
}

export default SubmitOccupation;
