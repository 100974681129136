import styled from 'styled-components';
import {
  Modal as BaseModal,
} from 'components';

export const styles = {
  modalHeader: {
    justifyContent: 'flex-end',
    padding: { base: '2rem', sm: '2rem' },
    borderBottom: '1px solid',
    borderColor: 'gray.200',
  },
  title: {
    mr: '3rem',
  },
  form: {
    gap: '2rem',
    padding: { base: '2rem', sm: '2.5rem' },
  },
  textHelper: {
    padding: '0.5rem 0 0 0',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
  },
  zenfi: {
    color: 'schema.zenfi.500',
  },
  actionsContent: {
    justifyContent: 'space-between',
    gap: '1rem',
  },
  actionButton: {
    flexBasis: { base: '10rem', sm: '13rem' },
  },
  modalFooter: {
    borderTop: '1px solid',
    borderColor: 'gray.200',
    padding: { base: '2rem', sm: '2.5rem' },
  },
};

export const Modal = styled(BaseModal)`
  max-width: 450px !important;
  max-height: 99% !important;
  border-radius: 4px;
`;
