import { gql } from '@apollo/client';
import { useMutation } from 'hooks/apollo';

export const GENERATE_ACCOUNT_STATEMENTS = gql`
mutation GenerateAccountStatement($input: GenerateAccountStatementMutationInput!) {
  generateAccountStatement(input: $input) {
    user {
      id
      flags {
        id
        isAccountStatementAvailable
      }
    }
  }
}

`;

export const useGenerateAccountStatement = (options) => useMutation(
  GENERATE_ACCOUNT_STATEMENTS,
  options,
);
