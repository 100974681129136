import React from 'react';
import { Field } from 'react-final-form';
import SelectContainer from './styles';

const SelectField = ({
  options, name, value, disabled, onBlur = () => {},
}) => {
  const renderOptions = options.map((option, index) => {
    const id = `${name}_${option.value}_${index}`;
    return (
      <option value={option.value} key={id}>
        {option.label}
      </option>
    );
  });

  return (
    <Field name={name} component="select" defaultValue={value}>
      {({ input, meta }) => (
        <SelectContainer>
          <select
            {...input}
            disabled={disabled}
            onBlur={() => {
              onBlur();
              input.onBlur();
            }}
          >
            <option value="" aria-label="placeholder" />
            {renderOptions}
          </select>
          {meta.error && meta.touched && (
            <div className="error">
              <span style={{ color: 'red' }}>{meta.error}</span>
            </div>
          )}
        </SelectContainer>
      )}
    </Field>
  );
};

export default SelectField;
