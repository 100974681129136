import React, { useEffect, useMemo, useCallback } from 'react';
import isNil from 'utils/isNil';
import Button from 'components/Button/Button';
import CheckboxField from 'components/CheckboxField/CheckboxField';
import { fetchPersonalIdentifications } from 'apis/registerForm';
import DotsLoader from 'components/DotsLoader';
import { Box } from 'styles';
import { l, t } from 'i18n';
import ListItemForm from 'components/ListForm/ListItemForm';
import { useReduxQuery } from 'utils/redux-query';
import Alert from 'components/Alert';
import { useForm, useFormState } from 'react-final-form';
import debounce from 'utils/Monads/debounce';
import { isTesting } from 'config/';
import { USER } from 'config/constants';

const ts = (id) => t(id, { scope: 'Public.ConfirmProfile' });

const debounceTiming = isTesting() ? 10 : 1000;

const updateFederalRecords = async (profile) => {
  const {
    firstName, middleName, firstLastName, secondLastName, gender, stateName, birthDate,
  } = profile;

  const { rfc, curp } = await fetchPersonalIdentifications({
    firstName,
    secondName: middleName,
    firstLastName,
    secondLastName,
    gender,
    birthState: stateName,
    birthDate,
  });

  return { rfc, curp };
};

const Form = () => {
  const form = useForm();
  const { values, submitting } = useFormState();
  const [catalog] = useReduxQuery('CATALOG');
  const stateName = useMemo(() => {
    if (catalog && values?.birthStateId) {
      return catalog
        .states.find(({ id }) => String(id) === String(values.birthStateId)).name;
    }

    return null;
  }, [catalog, values?.birthStateId]);

  const birthDate = useMemo(() => {
    if (values?.birthDate) return values?.birthDate.replace(/\//g, '-');

    return null;
  }, [values?.birthDate]);

  const updateFederalRecordsDebounced = useCallback(
    debounce((profile) => {
      updateFederalRecords(profile).then((records) => {
        if (!records) return;
        const { curp, rfc } = records;

        form.change('rfc', rfc);
        form.change('curp', curp);
      });
    }, debounceTiming),
    [],
  );

  useEffect(() => {
    const cannotPerformAction = [
      values?.user?.firstName,
      values?.user?.firstLastName,
      values?.user?.secondLastName,
      values?.gender,
      birthDate,
      stateName,
    ].some((value) => !value);
    if (cannotPerformAction) return;

    const profile = {
      firstName: values.user.firstName,
      middleName: values.user.middleName,
      firstLastName: values.user.firstLastName,
      secondLastName: values.user.secondLastName,
      gender: values.gender,
      birthDate,
      stateName,
    };

    updateFederalRecordsDebounced(profile);
  }, [
    values?.user?.firstName,
    values?.user?.firstLastName,
    values?.user?.secondLastName,
    values?.gender,
    birthDate,
    stateName,
  ]);

  return (
    <>
      <Box>
        <ListItemForm
          inputType="FULL_NAME_FIELD"
          name="user"
          textLabel={ts('confirmForm.fields.name.textLabel')}
          overflow="hidden"
          format={({
            firstName, middleName, firstLastName, secondLastName,
          } = {}) => [firstName, middleName, firstLastName, secondLastName]
            .filter((name) => !isNil(name))
            .join(' ')}
        />

        <ListItemForm
          name="gender"
          inputType="SELECT_FIELD"
          format={(gender) => t(gender, { scope: 'common.genders' })}
          iconName="CHEVRON_DOWN"
          textLabel={ts('confirmForm.fields.gender.textLabel')}
        >
          <option value="male">{t('common.genders.male')}</option>
          <option value="female">{t('common.genders.female')}</option>
        </ListItemForm>

        <ListItemForm
          name="birthDate"
          inputType="DATE_FIELD"
          textLabel={ts('confirmForm.fields.birthDate.textLabel')}
          inputProps={{
            startingYearAt: new Date().getFullYear() - USER.MAX_AGE,
            spanYears: USER.MAX_AGE - USER.MIN_AGE,
          }}
          format={(date) => {
            if (!date) return '';

            const [day, month, year] = date.split('/');
            return l('date.formats.long', [month, day, year].join('/'));
          }}
        />

        <ListItemForm
          inputType="STATE_SELECT_FIELD"
          name="birthStateId"
          textLabel={ts('confirmForm.fields.birthStateId.textLabel')}
          format={(birthStateId) => catalog
            ?.states
            ?.find((state) => String(state.id) === String(birthStateId))
            ?.name}
        />

        <ListItemForm
          name="rfc"
          inputType="RFC_FIELD"
          textLabel={ts('confirmForm.fields.rfc.textLabel')}
        />

        <ListItemForm
          inputType="CURP_FIELD"
          name="curp"
          textLabel={ts('confirmForm.fields.curp.textLabel')}
        />
      </Box>

      <Alert
        status="info"
        variant="subtle"
        colorSchema="gray"
        message={ts('alerts.info.profileAcceptance')}
      />

      <CheckboxField
        id="conditions-field"
        name="termAndConditions"
        textLabel={ts('confirmForm.fields.termAndConditions.textLabel')}
      />
      <CheckboxField
        id="confirmation-field"
        name="confirmation"
        textLabel={ts('confirmForm.fields.confirmation.textLabel')}
      />

      <Button type="submit" disabled display="none" aria-hidden="true" />

      <Button type="submit" disabled={submitting} mt="2rem" data-testid="submit">
        {submitting ? <DotsLoader black /> : ts('confirmForm.actions.submit')}
      </Button>
    </>
  );
};

export default Form;
