import {
  UPDATE_CALENDAR,
  UPDATE_SCHEDULE_DATE,
  UPDATE_SCHEDULE_DAY_AMOUNT,
  TOGGLE_ASIDE_CALENDAR,
  SET_SCHEDULE_LOADING_STATUS,
} from 'actions/calendar/types';

const INITIAL_STATE = {
  summary: {
    mes: '',
    monto: '',
  },
  currentMonth: {},
  todayPayments: 0,
  monthPayments: 0,
  loadingSchedule: true,
  dayAmount: 0,
  date: {
    day: '',
    month: '',
    year: '',
  },
  lastDay: 0,
  calendarIsOpen: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_CALENDAR:
      return {
        ...state,
        summary: payload.summary,
        currentMonth: payload.schedule,
        lastDay: payload.lastDay,
        date: payload.date,
      };
    case UPDATE_SCHEDULE_DATE: {
      return {
        ...state,
        date: {
          ...state.date,
          ...payload,
        },
      };
    }
    case UPDATE_SCHEDULE_DAY_AMOUNT: {
      return {
        ...state,
        dayAmount: payload,
      };
    }
    case TOGGLE_ASIDE_CALENDAR: {
      return {
        ...state,
        calendarIsOpen: typeof payload === 'boolean' ? payload : !state.calendarIsOpen,
      };
    }
    case SET_SCHEDULE_LOADING_STATUS: {
      return {
        ...state,
        loadingSchedule: payload,
      };
    }
    default:
      return state;
  }
};
