import React, { useEffect, useState } from 'react';
import iconReferenceCode from 'assets/referenceCodeIcon.svg';
import iconClose from 'assets/close.svg';
import { animated, useTransition, config } from 'react-spring';
import { Form } from 'react-final-form';
import removeNilValues from 'utils/Object/removeNilValues';
import { showToastError } from 'utils/toastHandler';
import { reinitializeReferral } from 'actions/register/referral';
import DotsLoader from 'components/DotsLoader';
import { Grid, Image } from 'styles';
import Input from 'components/Input/Input';
import BaseField from 'components/FieldBase/FieldBase';
import { Text } from 'components/Typography';
import Box from 'components/Box';
import { t } from 'i18n';
import { useDispatch } from 'react-redux';
import { useReduxQuery, useReduxMutator } from 'utils/redux-query';
import Button from '../../../../components/Button/Button';

const tOptions = { scope: 'Public.Register.funnel.referenceCode' };

const validate = (values) => {
  const { referralCode } = values;

  return removeNilValues({
    referralCode: !referralCode ? t('form.referenceCodeField.errors.required', tOptions) : null,
  });
};

const useAnimatedInput = ({ step }) => useTransition(step, null, {
  from: {
    opacity: 0,
    maxHeight: 0,
  },
  enter: { opacity: 1, maxHeight: 140 },
  leave: { opacity: 0, maxHeight: 1 },
  config: config.default,
});

const parseReferralCode = (code = '') => (
  code
    .toUpperCase()
    ?.match(/[A-Z0-9-]/g)
    ?.join('') || ''
);

const ReferenceCode = (props) => {
  const dispatch = useDispatch();
  const [referral] = useReduxQuery('REFERRAL');
  const [createReferral, { error: createReferralError, status: createReferralStatus }] = useReduxMutator('CREATE_REFERRAL');
  const [updateReferral, { error: updateReferralError, status: updateReferralStatus }] = useReduxMutator('UPDATE_REFERRAL');
  const originalRefCode = referral ? referral.referredCode : null;
  const [step, setStep] = useState(0);
  const [refCode, setRefCode] = useState(originalRefCode);
  const codeInputProps = useAnimatedInput({ step });

  const handleSubmitReferralCode = async ({ referralCode }) => {
    if (originalRefCode) {
      setRefCode(referralCode);
      await updateReferral({ referenceCode: referralCode });
    } else {
      await createReferral({ referenceCode: referralCode });
    }

    setRefCode(referralCode);
  };

  const handleCancel = () => {
    if (originalRefCode) {
      setStep(2);
    } else {
      setStep(0);
    }
  };

  useEffect(() => {
    if (createReferralStatus.completed || updateReferralStatus.completed) {
      setStep(2);
    }
  }, [createReferralStatus.completed, updateReferralStatus.completed]);

  useEffect(() => {
    if (createReferralError) {
      setRefCode(originalRefCode);
      setStep(1);
      if (createReferralError.response.status === 422) {
        showToastError(t('form.referenceCodeField.errors.invalid', tOptions));
        dispatch(reinitializeReferral());
      }
    }
  }, [createReferralError, originalRefCode]);

  useEffect(() => {
    if (updateReferralError) {
      setRefCode(originalRefCode);
      setStep(1);
      if (updateReferralError.response.status === 422) {
        showToastError(t('form.referenceCodeField.errors.invalid', tOptions));
        dispatch(reinitializeReferral());
      }
    }
  }, [updateReferralError, originalRefCode]);

  useEffect(() => {
    if (originalRefCode) {
      setRefCode(originalRefCode);
      setStep(2);
    }
  }, [originalRefCode]);

  return (
    <Box p="0" alignItems="stretch" w="100%" {...props}>
      <Grid
        p={{ base: '1rem', sm: '2rem', md: '3rem 2rem' }}
        borderRight="1px dashed"
        borderColor="schema.gray.300"
        alignItems={{ base: 'center', md: 'start' }}
      >
        <img src={iconReferenceCode} alt="reference code icon" />
      </Grid>
      <Grid p={{ base: '1rem', sm: '2rem' }} justifyItems="start" rowGap="1rem">
        {codeInputProps.map(
          ({ item, props: animatedProps }) => (item === 0 && (
          <animated.div key="step1" style={animatedProps}>
            <Text fontSize="1.2rem" lineHeight="1.8rem" mb="1rem" data-testid="step1-title">
              {t('firstStep.title', tOptions)}
              {' '}
              <br />
              {t('firstStep.subtitle', tOptions)}
            </Text>
            <Button type="button" $type="link" $size="small" onClick={() => setStep(1)}>
              {t('firstStep.actions.next', tOptions)}
            </Button>
          </animated.div>
          ))
            || (item === 1 && (
              <animated.div key="step2" style={animatedProps}>
                <Form
                  onSubmit={handleSubmitReferralCode}
                  initialValues={{ referralCode: null }}
                  validate={validate}
                  render={({ handleSubmit, submitting }) => (
                    <Grid
                      as="form"
                      id="referral-form"
                      onSubmit={handleSubmit}
                      rowGap="1.4rem"
                      justifyItems="start"
                      data-testid="referral-form"
                    >
                      <BaseField
                        name="referralCode"
                        textLabel={t('form.referenceCodeField.textLabel', tOptions)}
                        color="schema.gray.700"
                        parse={parseReferralCode}
                      >
                        {({ input, hasError }) => (
                          <Grid templateColumns={{ base: '11rem 8.2rem', md: '12rem 8rem' }}>
                            <Input
                              borderTopRightRadius="0"
                              borderBottomRightRadius="0"
                              isInvalid={hasError}
                              data-testid="referral-input"
                              {...input}
                            />
                            <Button
                              borderTopLeftRadius="0"
                              borderBottomLeftRadius="0"
                              disabled={submitting}
                              px="1rem"
                              form="referral-form"
                              data-testid="referral-submit"
                            >
                              {submitting ? <DotsLoader /> : t('form.actions.submit', tOptions)}
                            </Button>
                          </Grid>
                        )}
                      </BaseField>
                      <Button type="button" $type="link" $size="small" onClick={handleCancel}>
                        {t('form.actions.cancel', tOptions)}
                      </Button>
                    </Grid>
                  )}
                />
              </animated.div>
            ))
            || (item === 2 && (
              <animated.div key="step3" style={animatedProps}>
                <Grid
                  templateColumns="10fr 2fr"
                  borderRadius="0.4rem"
                  bgColor="schema.gray.200"
                  p="1.5rem"
                >
                  <Text fontSize="1.4rem" lineHeight="1.8rem">
                    {t('finalStep.title', tOptions)}
                    {' '}
                    <Text
                      fontSize="1.4rem"
                      as="strong"
                      whiteSpace="nowrap"
                      data-testid="referralCode-text"
                    >
                      {refCode}
                    </Text>
                  </Text>
                  <Button
                    type="button"
                    $type="link"
                    $size="small"
                    onClick={() => setStep(1)}
                    data-testid="step3-close"
                  >
                    <Image src={iconClose} width="1rem" height="1rem" alt="close icon" />
                  </Button>
                </Grid>
              </animated.div>
            )),
        )}
      </Grid>
    </Box>
  );
};

export default ReferenceCode;
