export const styles = {
  container: {
    rowGap: 10,
    width: 'full',
    paddingTop: 10,
    placeItems: 'center',
    paddingX: { base: 0, xs: 5 },
  },
  content: {
    rowGap: 10,
    maxWidth: '5xl',
    placeItems: 'center',
    gridTemplateColumns: { base: '1fr', md: 'auto auto', lg: '448px 350px' },
  },
  wallpaper: {
    width: 'lg',
    display: { base: 'none', md: 'block' },
  },
  card: {
    maxWidth: 100,
    height: 'full',
  },
  cardBody: {
    paddingY: '7',
  },
  noteAlert: {
    status: 'warning',
    borderRadius: 'md',
    colorScheme: 'gray',
    gridColumn: '1 / -1',
  },
  bankAccountNote: {
    marginTop: 5,
    borderRadius: 'md',
  },
};
