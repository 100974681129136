import { tscope } from 'i18n';
import { useReduxQuery } from 'utils/redux-query';
import { INVOICING_STATUS } from '../../../constants';

const t = tscope('settings.account.invoicingPanel');

export const useController = () => {
  const [session] = useReduxQuery('SESSION');
  const [profile] = useReduxQuery('PROFILE');

  const isInvoicingActive = profile?.invoice;

  const needsUpdateFiscalInformation = session?.settings?.needsUpdateFiscalInformation;
  const hasInvoicingError = session?.settings?.hasInvoicingError;

  const toggleInvoicingPreview = isInvoicingActive
    ? t('actions.disable')
    : t('actions.enable');

  const badgeStatus = isInvoicingActive ? INVOICING_STATUS.ENABLED : INVOICING_STATUS.DISABLED;

  const displayActivateButton = !(isInvoicingActive && needsUpdateFiscalInformation);
  const displayUpdateFiscalContent = needsUpdateFiscalInformation
    && isInvoicingActive && !hasInvoicingError;

  const displayEditFiscalButton = isInvoicingActive;

  const displayInvoicingErrorAlert = hasInvoicingError && needsUpdateFiscalInformation
    && !isInvoicingActive;

  return {
    badgeStatus,
    displayActivateButton,
    toggleInvoicingPreview,
    displayEditFiscalButton,
    displayUpdateFiscalContent,
    displayInvoicingErrorAlert,
  };
};
