import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const RequisitionsFilterDiv = ({
  large,
  openFilter,
  wrapperRef,
  onCloseFilter,
  onToggleFilter,
  ...otherProps
}) => <div {...otherProps} />;

const RequisitionsFilterContainer = styled(RequisitionsFilterDiv)`
  &.filterSidebar {
    height: 100%;
    position: fixed;
    width: 27rem;
    background-color: ${colors.gray.g1};
    opacity: ${(props) => (props.openFilter ? '1' : '0')};
    left: ${(props) => (props.openFilter ? '20rem' : '-30rem')};
    ${(props) => (!props.large ? 'left: 20rem; opacity:1;' : null)}
    transition: all 0.5s;
    @media (max-width: ${breakPoints.largeBreakPoint}px) {
      z-index: 10;
    }
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      left: ${(props) => (props.openFilter ? '7rem' : '-30rem')};
      ${(props) => (!props.large ? 'left: 7rem; opacity:1;' : null)}
    }
    @media (max-width: 700px) {
      left: ${(props) => (props.openFilter ? '0rem' : '-30rem')};
      ${(props) => (!props.large ? 'left: 0rem; opacity:1;' : null)}
    }
  }
`;

export default RequisitionsFilterContainer;
