import { useSpring } from 'react-spring';
import { colors } from 'styles';

const getBorderColor = ({ hasError, isFocused }) => {
  if (hasError) return colors.red;
  if (isFocused) return colors.blue;
  return colors.gray.g3;
};

export const useInputAnimation = ({ isActive, hasError, isFocused }) => {
  const animation = useSpring({
    borderColor: getBorderColor({ hasError, isFocused }),
    color: isActive ? colors.gray.g6 : colors.white,
    from: { borderColor: colors.gray.g3 },
  });

  return animation;
};
