import React from 'react';
import {
  Flex,
  EditIcon,
  CheckIcon,
  IconButton,
  ButtonGroup,
  EditableInput,
  EditablePreview,
  useEditableControls,
  Tooltip,
  Editable,
} from 'ui';
import { handleOnChange } from 'components/FormFields/hooks/useFieldController';
import { style } from './EditableClabeAliasStyle';
import { useController } from './hooks/useController';

const EditableControls = () => {
  const {
    isEditing,
    getSubmitButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup {...style.iconsContainer}>
      <IconButton {...style.icon} icon={<CheckIcon />} {...getSubmitButtonProps()} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton
        {...style.icon}
        size="sm"
        ml="0"
        icon={<EditIcon color="blue.400" />}
        {...getEditButtonProps()}
      />
    </Flex>
  );
};

export const EditableClabeAlias = (props) => {
  const { bankAccount, ...contentProps } = props;

  const {
    displayValue,
    onChangeValue,
    handleUpdateBankAccount,
  } = useController({ bankAccount });

  return (
    <Tooltip label={displayValue}>
      <Editable
        value={displayValue}
        onSubmit={handleUpdateBankAccount}
        onChange={(e) => handleOnChange({ e, parseType: 'alphanumeric', onChange: onChangeValue })}
        submitOnBlur={false}
        {...style.container}
        {...contentProps}
      >
        <EditablePreview {...style.text} />
        <EditableInput {...style.text} maxLength="50" />
        <EditableControls />
      </Editable>
    </Tooltip>
  );
};
