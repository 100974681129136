import React from 'react';
import endpoints from 'config/endpoints';
import { t } from 'i18n';
import previewAppImageURI from 'assets/common/previewApp.png';
import previewApp2xImageURI from 'assets/common/previewApp2x.png';
import googlePlayImageURI from 'assets/common/GooglePlay.png';
import appStoreImageURI from 'assets/common/AppStore.png';
import {
  PromotingAppBanner,
  PreviewAppImage,
  P,
  TextAndStores,
  StoresContainer,
  Store,
} from './PromotingApp.style';

const PromotingApp = (props) => (
  <PromotingAppBanner {...props}>
    <PreviewAppImage
      src={previewAppImageURI}
      alt=""
      srcSet={`${previewAppImageURI} 1x, ${previewApp2xImageURI} 2x`}
    />
    <TextAndStores>
      <P color="#04475C" align="center">
        {t('summary.promotioningApp.welcome')}
        {' '}
        <P as="strong">{t('summary.promotioningApp.ourApp')}</P>
      </P>
      <StoresContainer>
        <a href={endpoints.public.playStoreAppUrl} target="blank">
          <Store src={googlePlayImageURI} alt="Link to Play Store" />
        </a>
        <a href={endpoints.public.appStoreAppUrl} target="blank">
          <Store src={appStoreImageURI} alt="Link to App Store" />
        </a>
      </StoresContainer>
    </TextAndStores>
  </PromotingAppBanner>
);

export default PromotingApp;
