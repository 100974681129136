export const styles = {
  container: {
    gap: 3,
  },
  counterButton: {
    variant: 'link',
    fontWeight: '500',
    justifySelf: 'end',
    animationType: 'none',
  },
  SMSContent: {
    gap: 2,
    textAlign: 'center',
    flexDirection: 'column',
  },
  disabledFactorContent: {
    flexDirection: 'column',
  },
  disabledFactorButton: {
    marginTop: 2,
    width: 'full',
  },
};
