import React, { useEffect, useState } from 'react';
import { l } from 'i18n';
import { breakPoints, firstLevelWarnsKeys } from 'config/constants';
import Modal from 'components/Modal';
import PromotingApp from 'components/Banners/PromotingApp';
import Footer from 'views/DashboardContent/FooterDashboard';
import { useReduxQuery } from 'utils/redux-query';
import { AutoInvestWidget } from 'components/AutoInvestWidget/AutoInvestWidget';
import ZenfiBanner from 'components/Banners/Zenfi';
import DashboardActiveContainer, { LimitsBanner, WaveText } from './styles';
import AccountStatement from './AccountStatement';
import Funding from './Funding';
import ProjectionChart from './ProjectionChart';
import { Help } from './Help';
import Referral from './Referral';
import ModalContent from '../ModalContent';

const DashboardActive = ({ width }) => {
  const [session] = useReduxQuery('SESSION');
  const { user, levelWarn, settings } = session;
  const availableForAutoInvest = settings?.availableForAutoinvest;
  const { referenceCode, lastSignInAt } = user;
  const [modalState, setModalState] = useState({ open: false, type: '' });
  const [isMobile, setIsMobile] = useState(width < breakPoints.smallBreakPoint);
  const displayLimitBanner = levelWarn?.level && ![
    firstLevelWarnsKeys.MULTIPLE_USERS,
    firstLevelWarnsKeys.ACCOUNT_VALUE_ON_FIFTY_PERCENT,
  ].includes(levelWarn?.level);

  useEffect(() => setIsMobile(width < breakPoints.smallBreakPoint), [width]);

  const handleModalAction = (type) => setModalState({
    type,
    open: !modalState.open,
  });

  const closeModal = () => {
    setModalState({
      type: '',
      open: false,
    });
  };

  return (
    <>
      <DashboardActiveContainer isMobile={isMobile}>
        <div className="user__info__banner">
          {referenceCode && lastSignInAt ? (
            <WaveText>
              <span>
                Bienvenido
                {' '}
                {session?.user?.fullName ?? '...'}
                {' '}
                |
                {' '}
              </span>
              <span>
                Último inicio de sesión:
                {l('date.formats.medium', lastSignInAt)}
              </span>
            </WaveText>
          ) : (
            <WaveText>Cargando...</WaveText>
          )}
        </div>
        <AccountStatement isMobile={isMobile} handleModal={handleModalAction} />
        <Funding quantity={0} isMobile={isMobile} />
        {displayLimitBanner && <LimitsBanner />}
        <ProjectionChart
          isMobile={isMobile}
          handleModal={handleModalAction}
          columStartAt={displayLimitBanner ? 4 : 3}
        />
        <AutoInvestWidget
          minHeight="15rem"
          w="100%"
          gridArea={{
            sm: '6 / 1 / 6 / 9',
            md: '5 / 1 / 5 / 5',
            lg: '3 / 6 / 3 / 9',
          }}
        />
        <ZenfiBanner gridArea={{
          sm: '5 / 1 / 5 / 9',
          md: availableForAutoInvest ? '5 / 5 / 5 / 9' : '5 / 1 / 5 / 9',
          lg: '5 / 6 / 5 / 9',
        }}
        />
        <PromotingApp gridArea={{
          sm: '3 / 4 / 3 / 9',
          lg: '4 / 6 / 4 / 9',
        }}
        />
        <Referral referenceCode={referenceCode} />
        <Help />
        <Modal isOpen={modalState.open} onRequestClose={closeModal}>
          <ModalContent modalType={modalState.type} onCloseModal={closeModal} />
        </Modal>
      </DashboardActiveContainer>
      <Footer />
    </>
  );
};

export default DashboardActive;
