import { gql } from '@apollo/client';
import { useQuery, useMutation } from 'hooks/apollo';

export const BENEFICIARY_MUTATION_GQL = gql`
mutation UpdateDonee($input: UpdateDoneeMutationInput!) {
  updateDonee(input: $input) {
    user {
      id
      donee {
        fullName,
        birthDate,
        relationType,
        relationTypeOther,
        address {
          street
          intNumber
          extNumber
          city
          zip
          municipality
          neighborhood
          country
          isForeign,
          state {
            id
            name
          }
        }
      }
    }
  }
}
`;

export const useBeneficiaryMutation = (options) => useMutation(
  BENEFICIARY_MUTATION_GQL,
  options,
);

export const BENEFICIARY_QUERY_GQL = gql`
  query {
    user {
      id
      donee {
        fullName
        birthDate
        relationType
        relationTypeOther
        address {
          street
          intNumber
          extNumber
          city
          zip
          municipality
          neighborhood
          country
          isForeign,
          state {
            id
            name
          }
        }
      }
    }
  }
`;

export const useBeneficiaryQuery = (options) => useQuery(
  BENEFICIARY_QUERY_GQL,
  options,
);
