import React from 'react';
import { t } from 'i18n';
import { IntroductionVideoModal } from './OrdersVideoModal.style';

function OrdersVideoModal(props) {
  return (
    <IntroductionVideoModal {...props}>
      <iframe
        src={t('orders.ordersVideoURL')}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={t('orders.introductionVideo')}
      />
    </IntroductionVideoModal>
  );
}

export default OrdersVideoModal;
