import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

const fetch = async () => {
  const path = '/v1/investor/account_statements';
  const method = 'get';
  const config = { method, path };

  const { data } = await request(config);

  return camelify(data);
};

export default fetch;
