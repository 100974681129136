import {
  FETCH_USER_BENEFICIARY,
  TOGGLE_INVOICE_SWITCH,
  FETCH_USER_SIGNED_CONTRACTS,
  TOGGLE_CONTRACT_MODAL,
  SET_SELECTED_CONTRACT,
  IS_FETCHING_USER_SIGNED_CONTRACTS,
  IS_FETCHING_USER_BENEFICIARY,
} from 'actions/settings/accountProfile/types';

const initialState = {
  invoiceToggled: false,
  beneficiaryData: null,
  signedContracts: null,
  showContractModal: false,
  selectedContract: null,
  isFetchingSignedContracts: false,
  isFetchingBeneficiary: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_BENEFICIARY:
      return {
        ...state,
        beneficiaryData: action.payload,
        isFetchingBeneficiary: false,
      };
    case IS_FETCHING_USER_BENEFICIARY:
      return { ...state, isFetchingBeneficiary: action.payload };
    case TOGGLE_INVOICE_SWITCH:
      return { ...state, invoiceToggled: action.payload };
    case FETCH_USER_SIGNED_CONTRACTS:
      return {
        ...state,
        signedContracts: action.payload,
        isFetchingSignedContracts: false,
      };
    case IS_FETCHING_USER_SIGNED_CONTRACTS:
      return { ...state, isFetchingSignedContracts: action.payload };
    case TOGGLE_CONTRACT_MODAL:
      return { ...state, showContractModal: action.payload };
    case SET_SELECTED_CONTRACT:
      return { ...state, selectedContract: action.payload };
    default:
      return state;
  }
};
