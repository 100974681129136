import { useRouteMatch } from 'react-router-dom';

export const useRouter = () => {
  const routeMatch = useRouteMatch();
  const ROUTES = {
    ROOT: routeMatch.path,
    CONFIRM: `${routeMatch.path}/confirm`.replace(/\/\//g, '/'),
  };

  return {
    ROUTES,
  };
};
