import React from 'react';
import { ReactComponent as LookingForINE } from 'assets/identityVerification/ine-zoom.svg';
import INEZoomURL, { ReactComponent as INEZoom } from './ineZoom.svg';
import ytpApp from './ytpApp.png';
import { ReactComponent as externalSecondFactor } from './externalSecondFactor.svg';
import { ReactComponent as secondFactor } from './secondFactor.svg';
import { ReactComponent as smsFlag } from './smsFlag.svg';
import { ReactComponent as ytpSquareLogo } from './ytpSquareLogo.svg';
import { ReactComponent as CERTerms } from './Terms.svg';
import { ReactComponent as BrandName } from './brandName.svg';
import { ReactComponent as BrandDarkName } from './brandDarkName.svg';

const imageSet = {
  BRAND_DARK_NAME: { component: BrandDarkName },
  YTP_APP: {
    component: (props) => <img src={ytpApp} alt={props.alt} {...props} />,
  },
  EXTERNAL_SECOND_FACTOR: {
    component: externalSecondFactor,
  },
  SMS_FLAG: { component: smsFlag },
  BRAND_NAME: { component: BrandName },
  SECOND_FACTOR: { component: secondFactor },
  YTP_SQUARE_LOGO: { component: ytpSquareLogo },
  LOOKING_FOR_INE: { component: LookingForINE },
  CER_TERMS: { component: CERTerms },
  INE_ZOOM: { component: INEZoom, url: INEZoomURL },
};

export default imageSet;
