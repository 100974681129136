import React from 'react';
import { t } from 'i18n';
import zenfiBannerPart1 from 'assets/common/ZenfiBannerPart1.png';
import zenfiBannerPart2 from 'assets/common/ZenfiBannerPart2.png';
import zenfiBannerPart12x from 'assets/common/ZenfiBannerPart1@2x.png';
import zenfiBannerPart22x from 'assets/common/ZenfiBannerPart2@2x.png';
import {
  ZenfiContent, ZenfiLogo, BodyText, ZenfiLink, Body, Image1, Image2,
} from './Zenfi.style';

function Zenfi(props) {
  return (
    <ZenfiContent {...props}>
      <div />
      <Image1
        src={zenfiBannerPart1}
        srcSet={`${zenfiBannerPart1} 1x, ${zenfiBannerPart12x} 2x`}
        alt=""
      />
      <Body>
        <ZenfiLogo />
        <BodyText size="body">
          {t('summary.zenfiBanner.body.part1')}
          {' '}
          <br />
          {t('summary.zenfiBanner.body.part2')}
          {' '}
          <b>
            {t('summary.zenfiBanner.body.part3')}
            {' '}
          </b>
        </BodyText>
        <a href={t('summary.zenfiBanner.storeLink')} target="blank">
          <ZenfiLink $type="zenfi" $size="small">
            {t('summary.zenfiBanner.downloadIt')}
          </ZenfiLink>
        </a>
      </Body>
      <Image2
        src={zenfiBannerPart2}
        srcSet={`${zenfiBannerPart2} 1x, ${zenfiBannerPart22x} 2x`}
        alt=""
      />
    </ZenfiContent>
  );
}

export default Zenfi;
