export const styles = {
  copied: {
    fontSize: 'sm',
    color: 'blue.500',
    cursor: 'pointer',
    fontStyle: 'italic',
  },
  notCopied: {
    cursor: 'pointer',
  },
};
