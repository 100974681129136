import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const REGIMEN_CATALOG = gql`
query Catalog {
catalogs {
  fiscalRegimes {
    id
    name
  }
}
}
`;

export const useRegimenCatalog = (options = {}) => useQuery(REGIMEN_CATALOG, {
  context: {
    clientName: 'public',
  },
  ...options,
});
