import { useEffect, useMemo } from 'react';
import { useField, useForm } from 'react-final-form';
import { useCountryCatalog } from './graphql/useCountryCatalog';

export const useController = ({
  name,
  ignoreCountries = [],
}) => {
  const { data, loading } = useCountryCatalog();
  const countryCatalog = data?.catalogs?.countries;
  const { input: countryInput } = useField(name);
  const { change } = useForm();

  const countryOptions = useMemo(() => {
    if (!countryCatalog) return [];
    const filterCountry = ignoreCountries
      ? countryCatalog.filter(({ id }) => !ignoreCountries.includes(id))
      : countryCatalog;
    const countries = filterCountry
      .map(({ id, name: countryName }) => ({
        label: countryName,
        value: id,
      }));
    return countries;
  }, [countryCatalog]);

  useEffect(() => {
    if (!countryInput.value || !countryCatalog) return;
    const country = countryCatalog
      .find(({ id: countryId }) => countryId === countryInput.value);
    if (country) change(`${name}Name`, country.name);
  }, [
    name,
    change,
    countryCatalog,
    countryInput.value,
  ]);

  return {
    countryOptions,
    isLoading: loading,
  };
};
