import { logout } from 'apis/auth';
import {
  getWebAppUrl,
  getUpdatePasswordUrl,
} from 'config';
import { storeSession, removeSession } from 'utils/session';
import { resetCache } from 'utils/redux-query/queryBase';
import * as types from './types';

export const redirectToWebApp = () => {
  window.location.href = getWebAppUrl();
};

export const redirectToUpdatePassword = () => {
  window.location.href = getUpdatePasswordUrl();
};

export const redirectToMainLogin = () => {};

export const setSession = (session) => {
  storeSession(session);
  return { type: types.SET_SESSION, payload: session };
};

export const logOutUser = () => async (dispatch) => {
  resetCache();
  removeSession();
  resetCache();
  await logout();
  dispatch({ type: types.RESTART_STATE });
  dispatch({ type: types.LOG_OUT_USER });
  dispatch({ type: types.SET_SESSION_LOADING, payload: false });
  redirectToWebApp();
};

export const setInMaintenance = (payload) => ({ type: types.SET_IN_MAINTENANCE, payload });
