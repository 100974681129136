import React from 'react';
import PropTypes from 'prop-types';
import {
  FlashNoticeContent,
  FlashNoticeBody,
  LevelUpButton,
  WarningIcon,
} from './FlashNotice.style';

const types = {
  warning: {
    Icon: WarningIcon,
  },
};

function FlashNotice(props) {
  const {
    type, message, buttonProps, ...contentProps
  } = props;
  const { Icon } = types[type];

  return (
    <FlashNoticeContent $type={type} {...contentProps}>
      <Icon />
      <FlashNoticeBody $type={type}>{message}</FlashNoticeBody>
      {buttonProps && <LevelUpButton {...buttonProps} />}
    </FlashNoticeContent>
  );
}

FlashNotice.defaultProps = {
  buttonProps: null,
};

FlashNotice.propTypes = {
  buttonProps: PropTypes.shape(LevelUpButton.propTypes),
};

export default FlashNotice;
