import { useState } from 'react';
import { useBeneficiaryForm } from './useBeneficiaryForm';
import { useValidator } from './useValidator';

export const useController = ({
  onBeneficiaryUpdated,
}) => {
  const [loadingZIP, setLoadingZIP] = useState();
  const validator = useValidator();
  const {
    onSubmit,
    isLoadingForm,
    initialValues,
  } = useBeneficiaryForm({
    onBeneficiaryUpdated,
  });

  return {
    onSubmit,
    validator,
    initialValues,
    disabledZIP: loadingZIP,
    isLoading: isLoadingForm,
    onLoadingZIP: setLoadingZIP,
  };
};
