import camelify from 'camelcase-keys-recursive';

const orderExpedient = (expedient) => {
  if (!expedient) return null;
  return {
    personalInformation: expedient.personalInformation,
    ocupation: expedient.ocupation,
    kycActivities: expedient.kycActivities,
    cer: expedient.cer,
    id: expedient.id,
    address: expedient.address,
    bankAccount: expedient.bankAccount,
  };
};

function parse(data) {
  if (!data) return null;

  const personalArchives = data ? orderExpedient(camelify(data)) : {};

  if (!personalArchives.kycActivities && !personalArchives.ocupation) {
    personalArchives.ocupation = false;
    personalArchives.kycActivities = null;
  }

  const nextRequirementTuple = Object.entries(personalArchives || {}).find(
    ([, value]) => value === false,
  );

  const [nextRequirement] = nextRequirementTuple || [null];

  personalArchives.nextRequirement = nextRequirement;

  return personalArchives;
}

export default parse;
