import React from 'react';
import styled, { css } from 'styled-components';
import { breakPoints } from 'config/constants';
import { Flex } from 'styles';

const ContainerDiv = ({ applyMobileStyles, ...otherProps }) => <Flex {...otherProps} />;
const secondFactorMobileStyles = css`
  flex-wrap: nowrap;
  flex-direction: column;
  & button {
    width: 100%;
  }
`;

const SecondFactorContainer = styled(ContainerDiv)`
  & .description__container {
    display: flex;
    padding: ${({ padding = '2rem' }) => padding};
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
      ${secondFactorMobileStyles}
    }
    ${(props) => props.applyMobileStyles && secondFactorMobileStyles}
    &__content {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.33rem;
      padding-bottom: 1rem;
      margin-right: 1rem;
      & .error__message {
        color: red;
        margin-right: 0.5rem;
      }
      & a {
        text-decoration: none;
        color: #0076ff;
        &:visited {
          text-decoration: none;
          color: #0076ff;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__action__button {
        display: flex;
        & .button__container {
          width: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
    .loader {
      text-align: center;
    }
  }
`;

export { SecondFactorContainer as default, secondFactorMobileStyles };
