import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Update from './Update';

function PhoneNumber() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/update`} component={Update} />
    </Switch>
  );
}

export default PhoneNumber;
