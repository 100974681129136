import React, { Fragment } from 'react';
import { t } from 'i18n';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'actions/auth/fetcher';
import ytplogo from 'assets/ytplogo2.svg';
import { Box, Flex, Grid } from 'styles';
import { Text } from 'components/Typography';
import { Button } from 'components/';
import Alert from 'components/Alert';

export const CorruptAccount = () => {
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logOutUser());
  };

  return (
    <Grid
      h="100vh"
      bgColor="schema.gray.100"
      template="6rem auto / auto"
    >
      <Box
        as="header"
        bgColor="schema.white"
        textAlgin="center"
      >
        <Flex
          p={{ base: '1rem', md: '2rem' }}
          mx="auto"
          maxWidth="102rem"
          justifyContent="space-between"
        >
          <Box as="img" src={ytplogo} alt="YTP Logo" maxWidth={{ base: '10rem', md: '15rem' }} />
          <Button $type="transparent" onClick={handleLogOut}>
            {t('common.closeSession')}
          </Button>
        </Flex>
      </Box>

      <Box
        p={{ base: '1rem', md: '2rem' }}
        justifySelf="center"
        maxWidth="102rem"
      >
        <Alert
          status="danger"
          variant="normal"
          title={t('common.corruptAccount.alert.title')}
          message={(
            <Text
              as={Fragment}
              i18nKey="common.corruptAccount.alert.message"
              components={{
                email: (
                  <Text
                    as="a"
                    color="schema.blue.500"
                    fontSize="inherit"
                    href={`mailto:${t('common.inversionistaEmail')}?subject=Cuenta con status null`}
                  >
                    {t('common.inversionistaEmail')}
                  </Text>
                ),
                tel: (
                  <Text
                    as="a"
                    fontSize="inherit"
                    fontWeight="500"
                    href={`tel:${t('common.yoteprestoTel')}`}
                    i18nKey="common.yoteprestoTel"
                  />
                ),
              }}
            />
          )}
        />
      </Box>
    </Grid>
  );
};
