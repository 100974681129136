import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const CATALOG_GQL = gql`
query CATALOGS {
  catalogs {
    id
    states {
      id
      constId
    }
  }
}
`;

export const useCatalog = (options) => useQuery(CATALOG_GQL, {
  context: {
    clientName: 'public',
  },
  ...options,
});
