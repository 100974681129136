import React from 'react';
import { connect } from 'react-redux';
import { toggleAsideCalendar } from 'actions/calendar/calendarActions';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import ButtonIcon from 'components/Button/ButtonIcon';
import CalendarPicker from './CalendarPicker';
import AsideCalendarContainer from './styles';

function AsideCalendar({
  date, monthAmount, dayAmount, calendarIsOpen, toggleCalendar,
}) {
  return (
    <AsideCalendarContainer className={`aside-calendar ${calendarIsOpen && 'is-open'}`}>
      <ButtonIcon iconName="CLOSE" className="close-control" onClick={toggleCalendar} />
      {date.month && <CalendarPicker />}
      <div className="payments">
        <div className="today">
          <span>Pagos programados para hoy:</span>
          <h3>{toCurrencyWithDecimals(dayAmount)}</h3>
        </div>
        <div className="month">
          <span>Pagos programados del mes:</span>
          <h3>{toCurrencyWithDecimals(monthAmount)}</h3>
        </div>
      </div>
      <div className="note">
        <span>Los pagos sólo se realizan en días hábiles.</span>
      </div>
    </AsideCalendarContainer>
  );
}

const mapStateToProps = ({ calendar }) => ({
  date: calendar.date,
  monthAmount: calendar.summary.monto,
  dayAmount: calendar.dayAmount,
  calendarIsOpen: calendar.calendarIsOpen,
});

export default connect(mapStateToProps, {
  toggleCalendar: toggleAsideCalendar,
})(AsideCalendar);
