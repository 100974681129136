import React from 'react';
import { Heading } from 'components/Typography';
import { Flex } from 'styles';
import { useReduxQuery } from 'utils/redux-query';
import { t } from 'i18n';

const MissingSecondFactor = () => {
  const [secondFactor, { status }] = useReduxQuery('SECOND_FACTOR');

  if (status.completed && secondFactor) return null;

  return (
    <>
      {status.completed && (
        <Flex justifyContent="center" p="3rem" my="3rem" bgColor="alerts.warning.bgColor">
          <Heading as="h3" color="alerts.txt_warning">
            {t('settings.security.no2FAyet')}
          </Heading>
        </Flex>
      )}
    </>
  );
};

export default MissingSecondFactor;
