import {
  GET_BANK_ACCOUNTS,
  GET_WITHDRAWABLE_BANK_ACCOUNTS,
  IS_FETCHING_BANK_ACCOUNTS,
} from 'actions/funds/types';

const initialState = {
  data: [],
  status: '',
  banksAddAccount: null,
  accountsList: null,
  isFetchingAccountsList: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_ACCOUNTS:
      return { ...state, accountsList: action.payload, isFetchingAccountsList: false };
    case IS_FETCHING_BANK_ACCOUNTS:
      return { ...state, isFetchingAccountsList: action.payload };
    case GET_WITHDRAWABLE_BANK_ACCOUNTS:
      return { ...state, banksAddAccount: action.payload };
    default:
      return state;
  }
};
