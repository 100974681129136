import styled from 'styled-components';
import { colors } from 'styles';

const MaintenanceContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  flex-direction: column;
  h1 {
    font-size: 3rem;
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    color: ${colors.gray.g6};
  }
  h2 {
    color: ${colors.gray.g4};
  }
`;

export default MaintenanceContainer;
