import React from 'react';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { t } from 'i18n';
import DetailWonContainer from './styles';

const ts = (id, options) => t(id, { scope: 'Dashboard.Cashflow', ...options });

const DetailWonModal = (props) => {
  const { dataModal } = props;
  const { accumulated } = dataModal;
  const interestCollected = accumulated.find((itm) => itm.id === '1');
  const overduePortfolio = accumulated.find((itm) => itm.id === '2');
  const payCommissions = accumulated.find((itm) => itm.id === '3');
  const portfolioLosses = accumulated.find((itm) => itm.id === '4');
  const calcTotal = (type) => parseFloat(interestCollected[type])
    + parseFloat(interestCollected[`${type}Iva`])
    + parseFloat(overduePortfolio[type])
    - (parseFloat(payCommissions[type])
      + parseFloat(payCommissions[`${type}Iva`])
      + parseFloat(portfolioLosses[type]));
  const monthTotal = calcTotal('mtd');
  const yearTotal = calcTotal('ytd');
  const accumulatedTotal = calcTotal('acumulado');
  const rowsItems = [
    {
      key: 0,
      title: ts('interestCollected'),
      month: interestCollected.mtd,
      year: interestCollected.ytd,
      accumulatedRow: interestCollected.acumulado,
    },
    {
      key: 1,
      title: ts('interestCollectedVAT'),
      month: interestCollected.mtdIva,
      year: interestCollected.ytdIva,
      accumulatedRow: interestCollected.acumuladoIva,
    },
    {
      key: 3,
      title: ts('payCommissions'),
      month: payCommissions.mtd,
      year: payCommissions.ytd,
      accumulatedRow: payCommissions.acumulado,
    },
    {
      key: 4,
      title: ts('payCommissionsVAT'),
      month: payCommissions.mtdIva,
      year: payCommissions.ytdIva,
      accumulatedRow: payCommissions.acumuladoIva,
    },
    {
      key: 6,
      title: ts('monthTotal'),
      month: monthTotal,
      year: yearTotal,
      accumulatedRow: accumulatedTotal,
    },
    {
      key: 5,
      title: ts('portfolioLosses'),
      month: portfolioLosses.mtd,
      year: portfolioLosses.ytd,
      accumulatedRow: portfolioLosses.acumulado,
    },
    {
      key: 2,
      title: ts('overduePortfolio'),
      month: overduePortfolio.mtd,
      year: overduePortfolio.ytd,
      accumulatedRow: overduePortfolio.acumulado,
    },
  ];
  const renderTableRow = rowsItems.map(({
    key, title, month, year, accumulatedRow,
  }) => (
    <tr key={key}>
      <td>{title}</td>
      <td>{toCurrencyWithDecimals(month)}</td>
      <td>{toCurrencyWithDecimals(year)}</td>
      <td>{toCurrencyWithDecimals(accumulatedRow)}</td>
    </tr>
  ));

  return (
    <DetailWonContainer>
      <table>
        <thead>
          <tr>
            <th aria-label="empty header" />
            <th>En el mes en curso</th>
            <th>En el año en curso</th>
            <th>Histórico acumulado</th>
          </tr>
        </thead>
        <tbody>{renderTableRow}</tbody>
      </table>
    </DetailWonContainer>
  );
};

export default DetailWonModal;
