import React from 'react';
import ContentLoader from 'react-content-loader';

const LinesLoader = () => (
  <ContentLoader height={100} width={200} speed={2} primaryColor="#F2F5F7" secondaryColor="#E7EBEF">
    <rect x="50" y="45" rx="5" ry="5" width="100" height="8" />
    <rect x="60" y="70" rx="5" ry="5" width="80" height="5" />
    <rect x="60" y="24" rx="5" ry="5" width="80" height="5" />
  </ContentLoader>
);

export default LinesLoader;
