import React, {
  forwardRef, useCallback, useImperativeHandle, useRef,
} from 'react';
import { t } from 'i18n';
import { Grid } from 'styles';
import Link from 'components/Link';
import TextField from 'components/TextField/TextField';
import { useField } from 'react-final-form';
import ButtonIcon from 'components/Button/ButtonIcon';
import debounce from 'utils/Monads/debounce';

const parseCURPField = (v) => v
  ?.slice(0, 16)
  ?.toUpperCase()
  ?.match(/[a-zA-Z0-9]/g)
  ?.join('') || '';

const parseCURPHomoField = (curp, homoClave) => `${curp}${
  homoClave
    ?.slice(0, 2)
    ?.toUpperCase()
    ?.match(/[a-zA-Z0-9]/g)
    ?.join('') || ''
}`;

function CURPField(props, forwardedRef) {
  const {
    textLabel, onBlur, name = 'curp', onRegenerate,
  } = props;
  const ref = useRef(forwardedRef);
  const {
    input: { value: curp },
  } = useField(name);

  const regenerateProps = onRegenerate
    ? {
      iconPosition: 'right',
      Icon: () => (
        <ButtonIcon iconName="REFRESH" color="schema.green.500" h="100%" onClick={onRegenerate} />
      ),
    }
    : {};

  const triggerBlur = useCallback(() => {
    if ([ref.current].includes(document.activeElement)) return;
    onBlur();
  }, [onBlur]);

  const triggerBlurDebounced = useCallback(debounce(triggerBlur, 100), [triggerBlur]);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => {
      ref.current.focus();
    },
  }));

  return (
    <Grid
      templateColumns={{ base: '18rem 5rem', md: '22rem 5rem' }}
      columnGap="1rem"
      rowGap="0.5rem"
    >
      <TextField
        gridColumn="1/2"
        color="schema.gray.700"
        textLabel={textLabel}
        name={name}
        readOnly
        parse={parseCURPField}
        format={(curpInput) => curpInput?.slice(0, 16)}
        inputProps={{ px: '0.5rem' }}
        {...regenerateProps}
      />
      <TextField
        textLabel=" "
        gridColumn="2/2"
        color="schema.gray.700"
        name={name}
        parse={(homoClave) => parseCURPHomoField(curp?.slice(0, 16), homoClave)}
        format={(curpInput) => curpInput?.slice(16, 18)}
        onBlur={triggerBlurDebounced}
        inputProps={{ px: '0.5rem' }}
        shouldDisplayTextHelper={false}
        ref={ref}
      />
      <Link
        as="a"
        href={t('Forms.customFields.CURPField.getCURPLink')}
        target="blank"
        gridColumn="1 / -1"
      >
        {t('Forms.customFields.CURPField.getCURP')}
      </Link>
    </Grid>
  );
}

export default forwardRef(CURPField);
