import React from 'react';
import { Flex } from 'styles';
import { Text, Heading } from 'components/Typography';
import DotsLoader from 'components/DotsLoader';
import { t } from 'i18n';

function PendingReview(props) {
  return (
    <Flex
      direction="column"
      alignItems="center"
      p="2rem"
      borderRadius="4px"
      border="1px solid"
      borderColor="schema.gray.300"
      rowGap="1rem"
      {...props}
    >
      <Heading as="h3" color="schema.gray.500" textAlign="center">
        {t('addFunds.pendingReview.title')}
      </Heading>
      <Text color="schema.gray.500" textAlign="center">
        {t('addFunds.pendingReview.body')}
      </Text>
      <DotsLoader black my="1rem" />
    </Flex>
  );
}

export default PendingReview;
