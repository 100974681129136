import { gql } from '@apollo/client';
import { useMutation } from 'hooks/apollo';

export const DELETE_BANK_ACCOUNT = gql`
mutation deleteWithdrawalAccount($input: DeleteWithdrawalAccountMutationInput!) {
  deleteWithdrawalAccount(input: $input) {
    user {
      id
      withdrawalAccounts {
        bank
        clabe
        clabeAlias
        id
        status
      }
    }
  }
}
`;

export const useDeleteBankAccountMutation = (options) => useMutation(DELETE_BANK_ACCOUNT, options);
