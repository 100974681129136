import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const { midBreakPoint, smallBreakPoint } = breakPoints;

const containerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const AsideCalendarContainer = styled(containerDiv)`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${colors.gray.g6};
  position: fixed;
  height: 100%;
  padding-top: 1em;
  background-color: ${colors.gray.g1};
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    position: relative;
  }
  @media (max-width: ${midBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
    flex-direction: row;
    padding-bottom: 14px;
    height: auto;
  }
  @media (max-width: ${midBreakPoint}px) {
    padding-top: 14px;
  }

  @media (max-width: ${smallBreakPoint}px) {
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    transition: 0.5s;
    width: 268px;
    left: -268px;
  }

  .DayPicker {
    @media (max-width: ${midBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media (max-width: ${smallBreakPoint}px) {
      margin-top: 12px;
    }
  }

  &.is-open {
    @media (max-width: ${smallBreakPoint}px) {
      left: 0;
    }
  }

  .close-control {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
    z-index: 1000;
    @media (max-width: ${smallBreakPoint}px) {
      display: block;
    }
  }

  .payments {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: ${midBreakPoint}px) {
      border-left: 1px solid #e7ebef;
    }
    .today,
    .month {
      font-size: 1.5rem;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-top: 16px;
      text-align: center;
      @media (max-width: ${midBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
        margin-top: 0;
        padding-top: 0;
      }
      @media (max-width: ${smallBreakPoint}px) {
        border-top: 1px solid #e7ebef;
        font-size: 12px;
        h3 {
          font-size: 18px;
        }
      }
      h3 {
        margin-top: 1em;
        font-size: 1.5em;
      }
    }
    .today {
      border-top: 1px solid #e7ebef;
      border-bottom: 1px solid #e7ebef;
      @media (max-width: ${midBreakPoint}px) {
        border: none;
      }
      @media (max-width: ${smallBreakPoint}px) {
        display: none;
      }
    }
  }

  .note {
    font-size: 1.2rem;
    margin-top: auto;
    padding: 18px 0 18px 0;
    text-align: center;
    @media (max-width: ${midBreakPoint}px) {
      display: none;
    }
  }
`;

export default AsideCalendarContainer;
