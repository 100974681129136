import React, { useEffect } from 'react';
import {
  LIMIT_LEVELS,
  PROOF_OF_ADDRESS_STATUS,
  USER_STATUSES,
  firstLevelWarnsKeys,
} from 'config/constants';
import { isNil } from 'utils';
import PATHS from 'routes';
import { useReduxLazyQuery, useReduxQuery } from 'utils/redux-query';
import Settings from 'utils/sdkYTP/investorSettings';
import { t } from 'i18n';
import Link from 'components/Link';
import Alert from 'components/Alert';
import LoadingScreen from './LoadingScreen';
import StepsCER from './StepsCER';
import PendingReview from './BankAccountCard/PendingReview';
import AddFundsContainer, {
  Content,
  FlashNotice,
  Title,
  MonthlyTransactions,
  RightTile,
  AddFundsBanner,
  NoticeSection,
  BankAccountCard,
  BlockedBankAccountCard,
  TransactionsLimitCard,
  Footer,
} from './AddFunds.style';
import { ALERTS } from '../Alerts/Alerts';

const { investorSettingsLimitsPath } = PATHS;

const AddFunds = () => {
  const [session] = useReduxQuery('SESSION');
  const settings = Settings(session);
  const { user, limits } = session;
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const { level } = session?.levelWarn || '';
  const [, { status: bankAccountInformationStatus }, { lazyQuery: depositInformationLazyQuery }] = useReduxLazyQuery('DEPOSIT_INFORMATION');
  const { currentLevel, transactionsLeftAmount } = limits || {};
  const displayLimitsCard = limits && currentLevel !== LIMIT_LEVELS.LEVEL_THREE;

  const displayBlockedBankAccountCard = (
    limits && bankAccountInformationStatus.completed && transactionsLeftAmount <= 0
  );

  const displayBankAccountCard = (
    bankAccountInformationStatus.completed && !displayBlockedBankAccountCard
  );

  const displayPendingReview = ![USER_STATUSES.AUTHORIZED].includes(user.status);

  const displayTransactionsCard = true;

  const displayNotice = true;

  const displayOverfundedFlashMessage = firstLevelWarnsKeys.OVERFUNDED === level;

  const displayExpedientAlert = user.status === USER_STATUSES.CONTRACT_UNSIGNED
    && user.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.ON_REVIEW;

  useEffect(() => {
    if ([USER_STATUSES.AUTHORIZED].includes(user.status)) {
      depositInformationLazyQuery();
    }
  }, [user.status]);

  if (isNil(statements?.valorCuenta) || bankAccountInformationStatus.pending) {
    return <LoadingScreen />;
  }

  if (settings.shouldSignCER()) {
    return <StepsCER />;
  }

  return (
    <AddFundsContainer>
      {displayBlockedBankAccountCard && (
        <FlashNotice
          type="warning"
          message={t('addFunds.alerts.warning.text')}
          buttonProps={{
            as: Link,
            to: investorSettingsLimitsPath(),
            $type: 'success',
            $size: 'small',
            children: t('addFunds.alerts.warning.buttonText'),
          }}
        />
      )}
      {displayOverfundedFlashMessage && (
        <FlashNotice
          type="warning"
          message={t('addFunds.alerts.danger.overfunded.body')}
          buttonProps={{
            as: Link,
            to: investorSettingsLimitsPath(),
            $type: 'success',
            $size: 'small',
            children: t('addFunds.alerts.danger.overfunded.buttonText'),
          }}
        />
      )}
      {displayExpedientAlert && (
        <Alert
          status="warn"
          width={{ base: 'auto', lg: '860px' }}
          mx={{ base: '10px', lg: 'auto' }}
          mt="10px"
          alignItems="center"
          message={t('addFunds.expedientAlert.message')}
        />
      )}
      {ALERTS.map(({ id, Component }) => <Component key={id} m="2rem" />)}
      <Content>
        <Title className="title">{t('addFunds.title')}</Title>
        <AddFundsBanner />
        {displayBankAccountCard && <BankAccountCard />}
        {displayBlockedBankAccountCard && <BlockedBankAccountCard />}
        {displayPendingReview && (
          <PendingReview
            gridArea={{ base: '', md: '3 / 1 / 5 / 1' }}
            mb={{ base: '0', md: '45rem' }}
          />
        )}
        <RightTile hidden={!displayLimitsCard && !displayTransactionsCard}>
          {displayLimitsCard && <TransactionsLimitCard />}
          {displayTransactionsCard && <MonthlyTransactions />}
        </RightTile>
        {displayNotice && (
          <NoticeSection>
            <p>{t('addFunds.notice1')}</p>
            <p>{t('addFunds.notice2')}</p>
          </NoticeSection>
        )}
      </Content>
      <Footer />
    </AddFundsContainer>
  );
};

export default AddFunds;
