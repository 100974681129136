import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CERIcon } from 'assets/addFundsCERIcon.svg';
import Button from 'components/Button';
import { investorCerRoute } from 'routes';
import { updateUserSettings } from 'apis/user';
import { t } from 'i18n';
import StepsCERContainer, { Footer } from './styles';
import Card from '../../../Card';

const StepsCER = () => {
  const history = useHistory();

  const onStartProcess = async () => {
    await updateUserSettings('cer_process_started', true);
    history.push(investorCerRoute);
  };

  return (
    <StepsCERContainer>
      <div className="message_info">
        <h1>{t('addFunds.needSignCER.title')}</h1>
        <Card>
          <CERIcon title="" />
          <p>{t('addFunds.needSignCER.body')}</p>
        </Card>
        <Button handleClick={onStartProcess} className="start_CER">
          {t('addFunds.needSignCER.start')}
        </Button>
        <p className="message_info__text">{t('addFunds.needSignCER.notice')}</p>
      </div>
      <Footer />
    </StepsCERContainer>
  );
};

export default StepsCER;
