import styled, { css } from 'styled-components';
import { breakPoints, isSafari } from 'config/constants';

const ContentArea = styled.div`
  display: flex;
  align-self: flex-end;
  flex-flow: row wrap;
  width: ${(props) => (props.isMobile ? '100%' : 'calc(100% - 20rem);')};
  @media (max-width: ${breakPoints.midBreakPoint}px) and (min-width: 700px) {
    width: calc(100% - 7rem);
  }
  height: calc(100vh - 56px);
  z-index: 500;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: contain;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div:first-of-type {
    ${isSafari()
    && css`
      height: auto;
    `}
  }
`;

export default ContentArea;
