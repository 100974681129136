import React from 'react';
import { t } from 'i18n';
import { Text, Heading } from 'components/Typography';
import Image from 'components/Image/Image';
import { Box, Flex } from 'styles';
import st from './WaitingForResponse.styles';

const i18nOptions = { scope: 'Mati.WaitingForResponse' };

const WaitingForResponse = () => (
  <Box {...st.container}>
    <Flex {...st.card}>
      <Heading
        {...st.title}
        i18nKey="title"
        data-testid="WaitingForResponse-title"
        i18nOptions={i18nOptions}
      />

      <Image {...st.image} imageName="LOOKING_FOR_INE" alt={t('image1Alt', i18nOptions)} />
      <Text
        {...st.descriptionText}
        i18nOptions={i18nOptions}
        i18nKey="description.content"
        data-testid="WaitingForResponse-description"
        components={{
          time: (
            <Text {...st.highlightedText} i18nKey="description.time" i18nOptions={i18nOptions} />
          ),
        }}
      />
    </Flex>
    <Text {...st.notice} i18nKey="notice" i18nOptions={i18nOptions} />
  </Box>
);

export default WaitingForResponse;
