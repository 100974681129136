import React from 'react';
import withSizes from 'react-sizes';

import { breakPoints } from 'config/constants';
import PaginationContainer, { PageDiv } from './styles';
import calculatePageRange, { calculatePages } from './utils';

const Pagination = (props) => {
  const {
    totalRows, currentPage = 1, handlePageChange, width, ...boxProps
  } = props;
  const mobile = width <= breakPoints.smallBreakPoint;
  const totalPages = calculatePages(totalRows);
  const pageRange = totalRows ? calculatePageRange(totalRows, currentPage, mobile) : false;

  const renderPaginationList = () => pageRange.map((page) => (
    <PageDiv
      key={page}
      id={page}
      active={page === parseInt(currentPage, 10)}
      onClick={(event) => handlePageChange(parseInt(event.target.id, 10))}
    >
      {page}
    </PageDiv>
  ));

  if (pageRange && pageRange.length > 1) {
    return (
      <PaginationContainer className="paginationContainer" {...boxProps}>
        <div className="paginationList">
          {currentPage !== 1 && (
            <div className="controlGroup">
              {!mobile && <PageDiv onClick={() => handlePageChange(1)}>&lt; &lt;</PageDiv>}
              <PageDiv onClick={() => handlePageChange(currentPage - 1)}>&lt;</PageDiv>
            </div>
          )}
          {pageRange && renderPaginationList()}
          {currentPage !== totalPages && (
            <div className="controlGroup">
              <PageDiv onClick={() => handlePageChange(currentPage + 1)}>&gt;</PageDiv>
              {!mobile && <PageDiv onClick={() => handlePageChange(totalPages)}>&gt;&gt;</PageDiv>}
            </div>
          )}
        </div>
      </PaginationContainer>
    );
  }
  return null;
};

const mapSizesToProps = ({ width }) => ({ width });

export default withSizes(mapSizesToProps)(Pagination);
