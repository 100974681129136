import { request } from 'apis/fetchers/yotepresto';
import snakify from 'snakecase-keys';
import camelify from 'camelcase-keys-recursive';

async function toggleActivation({ autoinvestConfig, otp }) {
  const {
    rates, months, purposes, amount, active, contract,
  } = autoinvestConfig;

  const path = '/v1/investor/portfolio_auto_investments';
  const method = 'post';
  const body = snakify({
    rates,
    months,
    purposes,
    amount,
    active,
    contract,
    otpCode: otp,
  });
  const { data } = await request({ method, path, body });

  return camelify(data);
}

export default toggleActivation;
