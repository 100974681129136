import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { smallBreakPoint } = breakPoints;

const WidgetDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;

const InfoWidgetContainer = styled(WidgetDiv)`
  & .info-widget {
    &__row {
      display: flex;
      font-size: 1.2rem;
      color: #6b6b6b;
      justify-content: space-between;
      border-bottom: 1px #ddd solid;
      &:first-of-type {
        font-size: 1.4rem;
        color: #555;
      }
      &:last-of-type {
        border-bottom: none;
      }
      & div {
        padding: 1.5rem 1rem;
        @media (max-height: ${smallBreakPoint}px) {
          padding: 1.3rem 1rem;
        }
      }
    }
  }
`;

export default InfoWidgetContainer;
