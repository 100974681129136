import React from 'react';
import { UpdateAddress } from '../UpdateAddress';
import { useController } from './hooks';

export const UpdateAddressView = () => {
  const {
    onSubmit,
  } = useController();

  return (
    <UpdateAddress onSubmit={onSubmit} />
  );
};
