const filterByRegex = (regex, input) => {
  if (regex.test(input)) return input;
  if (input.length === 0) return '';
  return input.slice(0, -1);
};

export const regexAcceptNumberAndDots = /^(?!.*\..*\.)[.\d]+$/;
export const regexAcceptOnlyLetters = /^[A-Za-zÀ-ÖØ-öø-ÿ ´]+$/;

export const restrictNumberTextInput = (val) => {
  const regex = /^[0-9\b]+$/;
  if (val) {
    if (val.length === 0) return '';
    if (regex.test(val)) return val;
    return val.slice(0, -1);
  }
  return '';
};

export const restrictNumbersAndLength = (val, length) => restrictNumberTextInput(val)
  .substr(0, length);

export const onlyDigits = (val) => {
  if (!val?.length) return '';
  const cleanStringArray = val.match(/\d+/g);
  return (cleanStringArray || []).join('');
};

export const onlyAlphaChars = (val) => filterByRegex(/^[A-Za-zÀ-ÖØ-öø-ÿ ´]+$/, val);

export const onlyAlphanumericChars = (val) => filterByRegex(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\b ´]+$/, val);

export const restrictDecimalNumberTextInput = (val) => {
  if (val) {
    if (val.length === 0) return '';
    if (regexAcceptNumberAndDots.test(val)) return val;
    return val.slice(0, -1);
  }
  return '';
};

export const validPositiveNegativeDecimalNumbers = (val) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\-?\d+((\.|\,)\d+)?$/;
  if (val) {
    if (val.length === 0) return '';
    if (regex.test(val) || val === '-') return val;
    return val.slice(0, -1);
  }
  return '';
};

export const birthStates = (states) => (states || [])
  .map(({ name, id }) => ({ label: name, value: id }));

export const rfcRestriction = (val) => onlyAlphanumericChars(val || '').substr(0, 13);

export const curpRestriction = (val) => onlyAlphanumericChars(val || '').substr(0, 18);

export const formatBirthdayInput = (val) => {
  const onlyNumber = onlyDigits(val.toString());
  return onlyNumber?.length === 1 ? '0'.concat(onlyNumber) : val;
};

export default restrictNumberTextInput;
