import { request } from '../../fetchers/yotepresto';
import parse from './parser';

export const getTransactions = async (params) => {
  const method = 'get';
  const path = '/v2/investor/movements';
  const response = await request({ method, path, params });
  const { data } = response;

  return parse({ data, params });
};
