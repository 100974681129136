import styled from 'styled-components';
import PieChartComponent from 'components/Charts/Pie';
import Button from 'components/Button/styles';
import { Flex } from 'styles';
import { breakPoints } from 'config/constants';

export const LimitsBannerContainer = styled.div`
  display: flex;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(38, 57, 69, 0.09);
  border: 1px solid rgba(219, 223, 227, 0.3);
  padding: 2rem 3rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    flex-direction: column;
  }
`;

export const LevelUpButton = styled(Button)`
  padding: 1.5rem 3.5rem;
  font-size: 1.6rem;
  white-space: nowrap;
`;

export const PieChart = styled(PieChartComponent)`
  margin-bottom: 1rem;
`;

export const PieChartContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
  flex-grow: 1;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-bottom: 3rem;
    margin-right: 0;
  }
`;

export const AnnounceContainer = styled(Flex)`
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-right: 2rem;
  flex-grow: 3;

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    flex-grow: 2;
  }

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-bottom: 3rem;
    align-items: center;
    margin-right: 0;
    flex-grow: 1;
  }
`;
