import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { t } from 'i18n';
import { ZIPCODE_LENGTH } from 'config/constants';
import { showToastWarn } from 'utils/toastHandler';
import { fetchZipCodeInfoCatalog } from 'apis/catalogs';
import { useField } from 'react-final-form';

export const useController = () => {
  const {
    input: { value: neighborhoodsValue },
  } = useField('neighborhoods');
  const neighborhoods = neighborhoodsValue || [];
  const displayLocation = neighborhoods?.length;

  return {
    neighborhoods,
    displayLocation,
  };
};

export const useZipField = ({
  name,
  formControl,
  shouldValidate,
}) => {
  const [isLoadingZip, setLoadingZip] = useState(false);
  const {
    input: { value: zipValue },
  } = useField(name);
  const zipsRef = useRef({});
  const isInvalidZip = !zipValue || zipValue.length < ZIPCODE_LENGTH;

  const fillLocationFields = useCallback((location) => {
    formControl.change('neighborhoods', location?.neighborhoods || []);
    formControl.change('city', location?.city || location?.municipality || '');
    formControl.change('municipality', location?.municipality || '');
    formControl.change('stateName', location?.stateName || '');
    formControl.change('stateId', location?.stateId || '');
  }, []);

  const fetchZip = useCallback(async (zip) => {
    try {
      setLoadingZip(true);
      const result = await fetchZipCodeInfoCatalog(zip);
      return result;
    } finally {
      setLoadingZip(false);
    }
  }, []);

  const validateCB = useCallback(async (zip) => {
    if (!shouldValidate) return undefined;
    const isCurrentZipInvalid = !zip || zip.length < ZIPCODE_LENGTH;
    if (isCurrentZipInvalid) return undefined;
    try {
      const location = zipsRef.current[zip] || await fetchZip(zip);
      zipsRef.current[zip] = location;
      fillLocationFields(location);
      const noLocationFound = !location || location.status === 'error';
      if (noLocationFound) {
        showToastWarn(t('common.alerts.error.loadZip'));
        return 'Invalido';
      }
      return undefined;
    } catch {
      showToastWarn(t('common.alerts.error.loadZip'));
      return 'Invalido';
    }
  }, [shouldValidate]);

  useEffect(() => {
    if (!shouldValidate) return;
    if (isInvalidZip) fillLocationFields(null);
  }, [zipValue]);

  useEffect(() => {
    validateCB(zipValue);
  }, [validateCB, zipValue]);

  return {
    validating: isLoadingZip,
  };
};
