import React from 'react';
import Link from 'components/Link';
import { t } from 'i18n';
import PATHS from 'routes';
import {
  BlockedContent, BlockedImage, Title, LimitsLink, DescriptionText,
} from './Blocked.style';

const { investorSettingsLimitsPath } = PATHS;

function Blocked(props) {
  return (
    <BlockedContent {...props}>
      <Title>{t('addFunds.blockedBankAccount.title')}</Title>
      <BlockedImage />
      <DescriptionText>{t('addFunds.blockedBankAccount.description')}</DescriptionText>
      <LimitsLink as={Link} $type="success" to={investorSettingsLimitsPath()}>
        {t('addFunds.blockedBankAccount.levelUp')}
      </LimitsLink>
    </BlockedContent>
  );
}

export default Blocked;
