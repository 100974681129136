import {
  useCallback,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18n';
import { showToastSuccess } from 'utils/toastHandler';
import { parseEventMessage } from 'utils/parseWSEventMessages';
import { createActions, useReduxSubscription } from 'utils/redux-query';
import { getRequisitionListSilent } from 'actions/requisitions';
import { WS_EVENT_NAMES } from 'config/constants';

const { update: updateTotalTicketsApproved } = createActions('TOTAL_APPROVED_TICKETS');

export const useBindCreateOrderCompleted = ({
  bindEvent,
}) => {
  const dispatch = useDispatch();
  const [, { refetch: refetchOrders }] = useReduxSubscription('ORDERS');
  const [totalTicketsApproved] = useReduxSubscription('TOTAL_APPROVED_TICKETS');

  const callback = useCallback((message) => {
    const { data } = parseEventMessage(message);
    const { ticketId, ticketAmount } = data;
    updateTotalTicketsApproved(totalTicketsApproved + 1, { status: 'COMPLETED' });
    const alertMessage = t('orders.created', { ticketID: ticketId, amount: ticketAmount });
    showToastSuccess(
      alertMessage,
      { iconName: 'success' },
    );
    dispatch(getRequisitionListSilent());
    refetchOrders();
  }, [
    dispatch,
    refetchOrders,
    totalTicketsApproved,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.ORDER_CREATED,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
