import React from 'react';
import ContentLoader from 'react-content-loader';

const AutoinvestLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={173}
    viewBox="0 0 400 173"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="48" y="12" rx="4" ry="4" width="296" height="23" />
    <rect x="49" y="50" rx="4" ry="4" width="295" height="26" />
    <rect x="50" y="91" rx="4" ry="4" width="293" height="78" />
  </ContentLoader>
);

const AutoinvestLoaderMobile = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={500}
    viewBox="0 0 400 500"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="48" y="12" rx="4" ry="4" width="296" height="38" />
    <rect x="49" y="77" rx="4" ry="4" width="295" height="62" />
    <rect x="50" y="166" rx="4" ry="4" width="293" height="323" />
  </ContentLoader>
);

export { AutoinvestLoader, AutoinvestLoaderMobile };
