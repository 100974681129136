import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n';
import { Form, Field } from 'react-final-form';
import { messages } from 'config/constants';
import SecondFactorCTA from 'views/DashboardContent/Activate2FAWizard/GenericSecondFactorCTA';
import SecondFactor from 'modules/SecondFactor';
import { TextField, Grid, Flex } from 'components';
import { removeWhiteSpaces } from 'utils/strings';
import {
  PasswordValidations,
} from './components';
import { useController } from './hooks/useController';

const ts = (key, options) => t(key, { scope: 'components.UpdatePasswordForm', ...options });
const { inputMessages } = messages;

export const ChangePassword = (props) => {
  const {
    onUpdatePasswordSuccess,
  } = props;
  const {
    validate,
    handleSubmit,
    secondFactor,
    secondFactorStatus,
  } = useController({
    onUpdatePasswordSuccess,
  });

  return (
    <Grid
      maxWidth="40rem"
      p="2rem"
      borderRadius="1rem"
      bgColor="schema.white"
      boxShadow="0 5px 9px 0 rgba(37,103,141,0.03)"
    >
      <Form
        validateOnBlur
        validate={validate}
        onSubmit={handleSubmit}
        initialValues={{
          newPassword: '',
          currentPassword: '',
          newPasswordConfirmation: '',
        }}
      >
        {({
          form,
          invalid,
          onSubmit,
          validating,
          submitting,
          modifiedSinceLastSubmit,
        }) => (
          <Flex
            direction="column"
            rowGap="1.5rem"
          >
            <Grid
              id="changePasswordForm"
              as="form"
              onSubmit={onSubmit}
              rowGap="1rem"
            >
              <TextField
                type="password"
                name="currentPassword"
                color="schema.gray.500"
                parse={removeWhiteSpaces}
                textLabel={ts('fields.currentPassword.label')}
              />
              <TextField
                type="password"
                name="newPassword"
                color="schema.gray.500"
                parse={removeWhiteSpaces}
                textLabel={ts('fields.newPassword.label')}
                placeholder={inputMessages.passwordMaxChar}
              />
              <Field name="conditionPasswordTypes">
                {({ meta }) => (
                  <PasswordValidations errors={meta.error} />
                )}
              </Field>
              <TextField
                type="password"
                color="schema.gray.500"
                name="newPasswordConfirmation"
                placeholder={inputMessages.passwordMaxChar}
                textLabel={ts('fields.newPasswordConfirmation.label')}
              />
            </Grid>
            {secondFactor && (
              <SecondFactor
                errors={{}}
                disabled={(invalid && !modifiedSinceLastSubmit) || validating || submitting}
                actionToAuthorize={(otp) => {
                  form.change('otp', otp);
                  form.submit();
                }}
                buttonText={ts('actions.submit')}
                buttonColor="success"
                applyMobileStyles
                padding="0"
              />
            )}
            {secondFactorStatus.completed && !secondFactor && (
              <SecondFactorCTA
                buttonText="Cambiar"
                cancelButtonText="Cancelar"
              />
            )}
          </Flex>
        )}
      </Form>
    </Grid>
  );
};

ChangePassword.defaultProps = {
  onUpdatePasswordSuccess: () => {},
};

ChangePassword.propTypes = {
  onUpdatePasswordSuccess: PropTypes.func,
};
