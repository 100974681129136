import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectTab } from 'actions/dashboard';
import { useReduxQuery } from 'utils/redux-query';
import { showToastError } from 'utils/toastHandler';
import { t } from 'i18n';
import { SETTINGS_ITEM } from 'views/SideBar/utils';
import ReferralsContainer from './styles';
import Instructions from './Instructions';
import ReferralsTable from './ReferralsTable';
import CodeAndSocialMedia from './CodeAndSocialMedia';
import { ReferralsTableLoader } from './Loaders';
import Footer from '../../../FooterDashboard';

const TableContainer = ({ children }) => (
  <div className="container referrals-table-container">{children}</div>
);

const Referrals = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [referrals, { status: referralStatus, error: referralsError }] = useReduxQuery('REFERRALS');
  const displayServerErrorToast = referralStatus.failed && referralsError?.response?.status;

  const renderTable = () => {
    if (referralStatus.pending) {
      return (
        <TableContainer>
          <ReferralsTableLoader />
        </TableContainer>
      );
    }

    if (referrals && referrals.length > 0) {
      return (
        <TableContainer>
          <ReferralsTable referralsList={referrals || []} />
        </TableContainer>
      );
    }

    return null;
  };

  useEffect(() => {
    dispatch(selectTab(SETTINGS_ITEM));
  }, []);

  useEffect(() => {
    if (displayServerErrorToast) {
      showToastError(t('settings.referralProgram.alerts.error.referralsServer'));
    }
  }, [displayServerErrorToast]);

  return (
    <>
      <ReferralsContainer>
        <div className="heading-container">
          <p>Haz crecer tu dinero con nuestro programa de referidos</p>
        </div>
        <div className=" container code-and-social-media-container">
          <CodeAndSocialMedia isMobile={isMobile} />
        </div>
        {renderTable()}
        <div className="container referrals-instructions-container">
          <Instructions />
        </div>
      </ReferralsContainer>
      <Footer />
    </>
  );
};

export default Referrals;
