import React from 'react';
import PropTypes from 'prop-types';
import { MI_TOKEN_YTP, EXTERNAL_APP, SMS } from '../../config/Constants';
import MiTokenYtp from '../SecondFactorStrategies/MiTokenYtp';
import ExternalApp from '../SecondFactorStrategies/ExternalApp';
import SelectSMS from '../SecondFactorStrategies/SelectSMS';

const ModalSwitch = (props) => {
  const { currentModal, onConfirmChange, onRequestClose } = props;

  switch (currentModal) {
    case MI_TOKEN_YTP: {
      return <MiTokenYtp />;
    }
    case EXTERNAL_APP: {
      return <ExternalApp onConfirmChange={onConfirmChange} />;
    }
    case SMS: {
      return <SelectSMS onConfirmChange={onConfirmChange} onRequestClose={onRequestClose} />;
    }
    default:
      return null;
  }
};

ModalSwitch.propTypes = {
  currentModal: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  onConfirmChange: PropTypes.func.isRequired,
};

ModalSwitch.defaultProps = {
  currentModal: null,
};

export default ModalSwitch;
