import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const BANK_ACCOUNTS_GQL = gql`
query BANK_ACCOUNTS_GQL {
  user {
    id
    withdrawalAccounts {
      id
      bank
      clabe
      status
      clabeAlias
    }
  }
}
`;

export const useBankAccountsQuery = (options) => useQuery(BANK_ACCOUNTS_GQL, options);
