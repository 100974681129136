import React from 'react';
import { Line } from 'rc-progress';
import { toCurrency } from 'utils/numberFormatters';
import TopItemsContainer from './styles';
import HeaderElements from '../../../Elements/HeaderElements';
import AvatarQualificationInterestStatus from '../../AvatarQualificationInterestStatus';
import RequisitionInfoGroup from '../../../Elements/RequisitionInfoGroup';

const TopItems = ({
  isMobile,
  isLoading,
  dataReq,
  fundingEndDays,
  zellID,
  pastCredits,
  isHidden,
  onHideRequisition,
  isFundedRequisition,
  remainingAmount,
  fundedPercentage,
  showVisibilityButton,
  statusCollapse,
  setStatusCollapse,
  backTo,
  numberOfCredits,
}) => {
  if (isFundedRequisition && isMobile) return null;
  return (
    <TopItemsContainer>
      <HeaderElements
        zellID={zellID}
        pastCredits={pastCredits}
        isHidden={isHidden}
        onHideRequisition={onHideRequisition}
        showHideIcon={showVisibilityButton && !isFundedRequisition}
        isLoading={isLoading}
        isMobile={isMobile}
        backTo={backTo}
        statusCollapse={statusCollapse}
        setStatusCollapse={setStatusCollapse}
        isFundedRequisition={isFundedRequisition}
        numberOfCredits={numberOfCredits}
      />
      {isMobile && (
        <>
          <AvatarQualificationInterestStatus
            dataReq={dataReq}
            isFundedRequisition={isFundedRequisition}
            isLoading={isLoading}
          />
          <div className="card mobil">
            <RequisitionInfoGroup
              dataReq={dataReq}
              isFundedRequisition={isFundedRequisition}
              isLoading={isLoading}
            />
            {!isLoading && (
              <div className="fundedBar mobil">
                <Line
                  percent={fundedPercentage}
                  strokeWidth="2"
                  strokeColor="#05C574"
                  trailColor="#FFFFFF"
                  trailWidth="2"
                  style={{ border: '1px solid #DDEAF2', borderRadius: '10px' }}
                />
                <p>
                  Faltan
                  <b>{toCurrency(remainingAmount)}</b>
                  {' '}
                  y
                  <b>{fundingEndDays}</b>
                  {' '}
                  días
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </TopItemsContainer>
  );
};

export default TopItems;
