import camelify from 'camelcase-keys-recursive';
import snakeify from 'snakecase-keys';
import { request } from '../../fetchers/yotepresto';

const validateOTP = async (OTPCode) => {
  const body = snakeify({ OTPCode });
  const config = {
    path: '/v2/second_factors/validate',
    method: 'post',
    body,
  };

  const { data } = await request(config);

  return camelify(data);
};

export default validateOTP;
