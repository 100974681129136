import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { midBreakPoint } = breakPoints;

const ElementCardContainerContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: ${colors.shadow.cards};
  color: white;
  @media (max-width: ${midBreakPoint}px) {
    height: inherit;
    width: 20rem;
  }
  &.shadowless {
    box-shadow: none;
  }
  &.avatarCard {
    max-height: 8rem;
    &.fundedDetail {
      max-width: 19.5rem;
      height: 5rem;
      margin: 0 auto;
      font-size: 1.5rem;
    }
    @media (max-width: ${midBreakPoint}px) {
      flex: inherit;
    }
  }
  &.borrowInfo {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    @media (max-width: ${midBreakPoint}px) {
      width: 100%;
      padding: 0 0 1rem;
      height: 19.6rem;
      flex-direction: column;
    }
  }
  .splitContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    :first-child {
      border-right: 1px solid rgba(1, 46, 71, 0.07);
    }
    &.fundedDetail {
      padding: 1.5rem;
      h1 {
        font-size: 1.8rem;
      }
      p {
        opacity: 100%;
      }
      &:first-child {
        flex: none;
        padding: 0 1rem;
      }
    }
  }
  &.foldingCard {
    position: relative;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2.3rem 3rem;
    color: rgb(102, 141, 157);
    width: 100%;
    .header {
      width: 100%;
      color: #668d9d;
      text-transform: uppercase;
      :focus {
        outline: none;
      }
      :hover {
        cursor: pointer;
      }
      &__icons {
        margin-right: 14px;
        float: right;
        :hover {
          cursor: pointer;
        }
        &__close {
          margin-top: -0.5rem;
        }
      }
    }
    .closeIcon {
      margin-top: -0.5rem;
    }
  }
  .financialStatusContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--incomesExpenses {
      flex: 1;
      .card {
        display: flex;
      }
      @media (max-width: ${midBreakPoint}px) {
        width: 100%;
      }
    }
    &--tableExpenses {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: ${midBreakPoint}px) {
        width: 100%;
        align-items: center;
      }
    }
    &--title {
      margin-bottom: 1.2rem;
      text-transform: uppercase;
    }
    @media (max-width: ${midBreakPoint}px) {
      flex-direction: column;
    }
  }
`;

export default ElementCardContainerContent;
