import React from 'react';
import { t } from 'i18n';
import {
  Box,
  Text,
  Heading,
} from 'ui';
import Footer from 'views/DashboardContent/FooterDashboard';
import { LIMIT_LEVELS } from 'config/constants';
import Alert from 'components/Alert';
import Scheme from '../Scheme';
import { useController } from './hooks';
import { LevelsContent } from './Levels.styles';
import { ProofOfAddressOnReview } from './components';

const Levels = () => {
  const {
    levelOne,
    disclaimer,
    currentLevel,
    level1Config,
    level2Config,
    level3Config,
    rejectingReason,
    isProofOfAddressRejected,
    displayProofOfAddressOnReview,
  } = useController();

  return (
    <>
      {displayProofOfAddressOnReview && (
        <Alert
          message={t('settings.limits.onReview.title')}
          status="warn"
          iconProps={{ w: '3rem', h: '3rem' }}
          margin="2rem 2rem 0 2rem"
        />
      )}
      <LevelsContent>
        <Box gridColumn="1 / -1">
          <Heading>{t('settings.limits.limits')}</Heading>
          <Text>{t('settings.limits.verifyInformation')}</Text>
        </Box>
        {/* TODO: Remove this element once we are sure we are no using it,
         as requirements are currently volatile */}
        {false && (
          <ProofOfAddressOnReview
            maxWidth="xl"
            gridColumn="1 / -1"
            levelConfig={levelOne}
          />
        )}
        <Scheme
          level={level1Config}
          isCurrent={currentLevel === LIMIT_LEVELS.LEVEL_ONE}
        />
        <Scheme
          level={level2Config}
          isCurrent={currentLevel === LIMIT_LEVELS.LEVEL_TWO}
          rejectingReason={rejectingReason}
          displayProofOfAddressOnReview={displayProofOfAddressOnReview}
          isProofOfAddressRejected={isProofOfAddressRejected}
        />
        <Scheme
          level={level3Config}
          isCurrent={currentLevel === LIMIT_LEVELS.LEVEL_THREE}
        />
        <div className="notes">
          <p>{t('settings.limits.notice1')}</p>
          <p>{t('settings.limits.notice2')}</p>
          <p>{disclaimer}</p>
        </div>
      </LevelsContent>
      <Footer />
    </>
  );
};

export default Levels;
