import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';
import Button from 'components/Button/Button';
import Modal from 'components/Modal';

const VIDEO_WIDTH = '120rem';
const VIDEO_HEIGHT = '67.5rem';

export const AddFundsBannerContent = styled.div`
  display: flex;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    flex-direction: column;
  }
`;

export const Body = styled.p`
  font-size: 1.6rem;
  margin-right: 2rem;
  color: ${colors.gray.g5};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-right: 0;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

export const VideoButton = styled(Button)`
  @media print {
    display: none;
  }
`;

export const AddFundsVideoModal = styled(Modal)`
  display: flex;
  width: ${VIDEO_WIDTH} !important;
  max-width: ${VIDEO_WIDTH} !important;
  height: ${VIDEO_HEIGHT} !important;
  max-height: ${VIDEO_HEIGHT}!important;

  /* adaptative design for custom video dimensions */
  @media (max-width: ${VIDEO_WIDTH}) {
    width: 100% !important;
    height: auto !important;

    iframe {
      max-height: calc(100vw / 1.777777);
      width: 100%;
      max-width: 100%;
    }
  }
`;
