import styled from 'styled-components';

const BottomLeftBarContainer = styled.div`
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
  .content {
    width: 31rem;
    text-align: center;
    color: #668d9d;
  }
`;

export default BottomLeftBarContainer;
