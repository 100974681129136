import React from 'react';
import get from 'lodash.get';
import moment from 'moment';
import { t } from 'i18n';
import DelayPaymentAndDispersionDatesContent from './styles';
import ElementCardContainer from '../ElementCardContainer';

const DelayPaymentAndDispersionDates = ({ dataReq }) => {
  const delayStates = ['Vencido', 'Atrasado'];
  const stateRequisition = get(dataReq, 'credit_detail.borrow_detail.estado');
  const grantDate = get(dataReq, 'credit_detail.borrow_detail.fecha_otorgamiento');
  const closingDate = get(dataReq, 'credit_detail.borrow_detail.fecha_limite');
  const lastPayment = get(dataReq, 'credit_detail.borrow_detail.fup');
  const textColorClass = !!stateRequisition && stateRequisition === 'Vencido' ? 'expired' : 'delayed';
  const renderDelayDays = () => {
    if (!!stateRequisition && delayStates.indexOf(stateRequisition) >= 0) {
      const dateLastPayment = moment(closingDate, 'DD-MM-YYYY');
      const totalDiffDates = moment().diff(dateLastPayment, 'days');
      return (
        <div className="delayDays">
          <p className="delayDays--text">
            Retraso de:
            {' '}
            <span className={textColorClass}>{t('common.days', { count: totalDiffDates })}</span>
          </p>
        </div>
      );
    }
    return null;
  };
  return (
    <DelayPaymentAndDispersionDatesContent>
      <p className="title">FECHAS</p>
      {renderDelayDays()}
      <div className="dispersionDays">
        <ElementCardContainer>
          <div className="dispersionDays--item">
            <p>{grantDate}</p>
            <p>{t('detailRequisition.grantDate')}</p>
          </div>
          <div className="dispersionDays--item">
            <p>{closingDate}</p>
            <p>{t('detailRequisition.closingDate')}</p>
          </div>
          <div className="dispersionDays--item">
            <p>{lastPayment}</p>
            <p>{t('detailRequisition.lastPayment')}</p>
          </div>
        </ElementCardContainer>
      </div>
    </DelayPaymentAndDispersionDatesContent>
  );
};

export default DelayPaymentAndDispersionDates;
