import React from 'react';
import {
  Link,
  withRouter,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { ChevronRightIcon } from 'ui';
import SidebarContainer, { Option } from './styles';

const Sidebar = ({ isMobile, options }) => {
  const match = useLocation();
  const isURLVisited = (url) => matchPath(match.pathname, { path: url, exact: false });

  const optionsContent = options
    .map((option) => (
      <Option
        key={option.url}
        isMobile={isMobile}
        active={isURLVisited(option.url)}
        className="option"
        bgColor={isURLVisited(option.url) ? 'gray.200' : 'gray.100'}
        color={isURLVisited(option.url) ? 'blue.500' : 'gray.500'}
      >
        <Link to={option.url}>
          <option.Icon boxSize={6} />
          {' '}
          {option.text}
          {isMobile && <ChevronRightIcon boxSize="8" marginLeft="auto" />}
        </Link>
      </Option>
    ));

  return (
    <SidebarContainer isMobile={isMobile} className="sidebar">
      <div className="sidebar__options__container">{optionsContent}</div>
    </SidebarContainer>
  );
};

export default withRouter(Sidebar);
