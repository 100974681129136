import { useRouteMatch } from 'react-router-dom';

export const useController = () => {
  const match = useRouteMatch();
  const confirmPath = `${match.url}/confirm`.replace(/\/\//g, '/');

  return {
    confirmPath,
  };
};
