export const styles = {
  modal: {
    size: 'sm',
  },
  headerModal: {
    borderBottom: '1px solid',
    borderColor: 'gray.200',
  },
  bodyModal: {
    paddingY: 6,
    width: 'full',
  },
  form: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 6,
    padding: 0,
  },
  divider: {
    borderColor: 'gray.200',
  },
  tokenField: {
    padding: 4,
    borderRadius: 'md',
    backgroundColor: 'gray.100',
  },
  tokenOTPTypeLabel: {
    color: 'gray.500',
    fontWeight: 'normal',
  },
  cancelButton: {
    width: '36',
    variant: 'ghost',
    colorScheme: 'gray',
    placeSelf: 'center',
  },
  submitButton: {
    width: '36',
    type: 'submit',
    placeSelf: 'center',
  },
  footerModal: {
    borderTop: '1px solid',
    borderColor: 'gray.200',
    justifyContent: 'center',
  },
  addAccountDisclaimer: {
    width: 60,
    fontSize: 'sm',
    color: 'gray.400',
    textAlign: 'center',
  },
  resendOTPButton: {
    variant: 'link',
    fontWeight: 'normal',
    justifySelf: 'end',
    animationType: 'none',
  },
  container: {
    width: '100%',
    paddingX: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    rowGap: 3,
    justifyContent: 'space-between',
  },
  accentText: {
    fontWeight: 'bold',
  },
  bankInfoText: {
    paddingBottom: 4,
  },
};
