import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

const fetch = async () => {
  const method = 'get';
  const path = '/v2/investor/ticket_orders/requisitions_number_with_loans';
  const config = { method, path };

  const { data } = await request(config);

  return camelify(data).requisitionsNumberWithLoans;
};

export default fetch;
