import styled from 'styled-components';

import { breakPoints } from 'config/constants';

const { smallBreakPoint } = breakPoints;
const LastSignAtBannerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 1.3rem;
  margin: 1rem 0;
  color: #93afb9;
  @media (max-width: ${smallBreakPoint}px) {
    & p {
      justify-content: flex-end;
    }
  }
  & p {
    margin-right: 1rem;
    display: flex;
    &:last-child {
      margin-right: 0;
    }
    & span {
      font-weight: 500;
    }
  }
`;

export default LastSignAtBannerContainer;
