import styled from 'styled-components';
import { colors } from 'styles';

export const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  line-height: 2rem;
  color: ${colors.schema.gray[500]};

  &:hover input ~ span {
    background-color: ${colors.schema.gray[300]};
  }

  input:checked ~ span {
    background-color: ${colors.schema.blue[500]};
    border-color: ${colors.schema.blue[500]};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 7px;
    top: 4px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 0.6rem;
  border: 1px solid;
  border-color: ${colors.schema.gray[300]};
  background-color: ${colors.schema.white};

  &:after {
    content: "";
    font-size: 0.5rem;
    position: absolute;
    display: none;
  }
`;
