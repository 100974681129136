import React from 'react';
import PropTypes from 'prop-types';
import iconSet from 'assets/icons/iconSet';
import { Text } from 'components/Typography';
import { colors } from 'styles';
import get from 'lodash.get';

const IconNotFound = () => <img alt="icon not found" />;

export const Icon = (props) => {
  const {
    color,
    minSize,
    boxSize,
    iconName,
    borderRadius,
    ...contentProps
  } = props;
  const IconComponent = iconSet[iconName]?.component ?? IconNotFound;

  return (
    <Text
      lineHeight="0px"
      overflow="hidden"
      w={boxSize}
      h={boxSize}
      color={color}
      fontSize={boxSize}
      minWidth={minSize}
      minHeight={minSize}
      borderRadius={borderRadius}
      {...contentProps}
    >
      <IconComponent
        width="100%"
        height="100%"
        color={get(colors.schema, color, colors.schema.gray[500])}
      />
    </Text>
  );
};

Icon.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(iconSet)).isRequired,
  boxSize: PropTypes.string,
  borderRadius: PropTypes.string,
};

Icon.defaultProps = {
  boxSize: '54px',
  borderRadius: '1rem',
};
