import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

async function toggleActivation({ autoinvestId, password }) {
  const path = `/v1/investor/portfolio_auto_investments/${autoinvestId}/toggle`;
  const method = 'put';
  const extraHeaders = { password };
  const { data } = await request({ method, path, extraHeaders });

  return camelify(data);
}

export default toggleActivation;
