export const calculatePages = (totalRows) => Math.ceil(totalRows / 25);

const calculatePageRange = (rows, currentPage, isMobile) => {
  const pages = calculatePages(rows);
  let range = [];

  if (isMobile) {
    const pageRange = 3;
    const pad = Math.floor(pageRange / 2);
    const startIn = currentPage < pageRange ? 1 : currentPage - pad;
    let endIn = 0;
    if (pages >= pageRange) {
      const endPage = currentPage + pad < pages ? currentPage + pad : pages;
      const nextEnd = (
        currentPage + pad < pageRange && pageRange <= pages ? pageRange : currentPage + pad
      );
      endIn = currentPage > 1 ? endPage : nextEnd;
    } else {
      endIn = pages;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = startIn; i <= endIn; i++) {
      range.push(i);
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if ((currentPage === 1 || currentPage <= 5) && pages >= 10) {
      range = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    } else if (currentPage === pages || currentPage >= pages - 5) {
      if (pages <= 9) {
        for (let i = 1; i <= pages; i += 1) {
          range.push(i);
        }
      } else {
        for (let i = 8; i >= 0; i -= 1) {
          range.push(pages - i);
        }
      }
    } else {
      const leftRange = [];
      const rightRange = [];
      const actualPage = parseInt(currentPage, 10);
      for (let i = 4; i >= 1; i -= 1) {
        const page = actualPage - i;
        if (page > 0) leftRange.push(page);
      }
      for (let i = 1; i <= 4; i += 1) {
        rightRange.push(actualPage + i);
      }
      range = [...leftRange, actualPage, ...rightRange];
    }
  }

  return range;
};

export default calculatePageRange;
