import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { t } from 'i18n';
import { Flex } from 'styles';
import { useReduxQuery } from 'utils/redux-query';
import { showToastInfo } from 'utils/toastHandler';
import { ReactComponent as PhoneIcon } from 'assets/phone.svg';
import { Text } from 'components/Typography';
import {
  BankAccountContent,
  BankAccountCardContent,
  PrintInstructionsButton,
  CopyButton,
  CopyButtonText,
  CopyButtonIcon,
  Field,
  FieldLabel,
  FieldText,
  FieldLabelNote,
  FieldHelperText,
  ContactSection,
} from './BankAccountCard.style';

function BankAccountCard(props) {
  const { ...contentProps } = props;
  const [bankAccountInformation] = useReduxQuery('DEPOSIT_INFORMATION');
  const [session] = useReduxQuery('SESSION');

  const beneficiaryFullname = session?.user?.fullName;

  const handleClickCopyButton = () => {
    window.print();
  };

  const handleCopyClipboard = () => {
    showToastInfo(t('addFunds.bankAccount.copyClipboard'));
  };

  return (
    <BankAccountContent {...contentProps}>
      <BankAccountCardContent>
        <Field>
          <FieldLabel>{t('addFunds.bankAccount.bank')}</FieldLabel>
          <FieldText>{t('addFunds.bankAccount.stp')}</FieldText>
        </Field>
        <Field>
          <FieldLabel>{t('addFunds.bankAccount.clabe')}</FieldLabel>
          <Flex justifyContent="space-between">
            <FieldText $primary>{bankAccountInformation.clabeInterna}</FieldText>
            <CopyToClipboard
              text={bankAccountInformation.clabeInterna}
              onCopy={handleCopyClipboard}
            >
              <CopyButton $size="small" $type="primary-outline">
                <CopyButtonText>{t('addFunds.bankAccount.copy')}</CopyButtonText>
                <CopyButtonIcon title="" width="2rem" height="2rem" />
              </CopyButton>
            </CopyToClipboard>
          </Flex>
        </Field>
        <Field>
          <FieldLabel>
            {t('addFunds.bankAccount.reference.part1')}
            {' '}
            <FieldLabelNote>{t('addFunds.bankAccount.reference.part2')}</FieldLabelNote>
          </FieldLabel>
          <FieldText>{bankAccountInformation.personId}</FieldText>
          <FieldHelperText>{t('addFunds.bankAccount.referenceHelper')}</FieldHelperText>
        </Field>
        <Field>
          <FieldLabel>
            {t('addFunds.bankAccount.beneficiary.part1')}
            {' '}
            <FieldLabelNote>{t('addFunds.bankAccount.beneficiary.part2')}</FieldLabelNote>
          </FieldLabel>
          <FieldText>{beneficiaryFullname}</FieldText>
        </Field>
        <Field $noBorderBottom>
          <PrintInstructionsButton $size="small" onClick={handleClickCopyButton}>
            {t('addFunds.bankAccount.downloadInstructions')}
          </PrintInstructionsButton>
        </Field>
        <Text $size="small" $type="secondary" mb="1rem">
          {t('addFunds.bankAccount.footerNotice')}
        </Text>
      </BankAccountCardContent>
      <ContactSection>
        <PhoneIcon />
        <p>
          {t('addFunds.bankAccount.contactUs')}
          {' '}
          <b>{t('common.yoteprestoTel')}</b>
        </p>
      </ContactSection>
    </BankAccountContent>
  );
}

export default BankAccountCard;
