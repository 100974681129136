export const styles = {
  container: {
    rowGap: '5',
    width: 'full',
    paddingX: '10',
    direction: 'column',
    alignItems: 'center',
  },
  title: {
    width: 'md',
    fontSize: '4xl',
  },
  subtitle: {
    width: 'md',
    fontSize: 'lg',
    fontWeight: 'medium',
  },
  content: {
    gap: '5',
    width: 'md',
    padding: '8',
    boxShadow: 'lg',
    bgColor: 'white',
    borderRadius: 'lg',
    gridTemplate: '1fr ',
  },
  cardTitle: {
    fontSize: 'xl',
    color: 'gray.700',
    gridColumn: '1 / -1',
    fontWeight: 'semibold',
  },
  alert: {
    width: 'md',
    borderRadius: 'lg',
    borderWidth: '1px',
    borderColor: 'yellow.500',
    borderStyle: 'solid',
    variant: 'subtle',
    status: 'warning',
    gridColumn: '1 / 3',
    color: 'yellow.500',
    bgColor: 'yellow.100',
  },
  addressText: {
    fontSize: 'lg',
    gridArea: '2 / 1',
    color: 'gray.700',
    fontWeight: 'normal',
  },
  proofOfAddressMandatory: {
    fontSize: 'lg',
  },
  editLink: {
    fontSize: 'lg',
    justifySelf: 'end',
    fontWeight: 'medium',
    alignSelf: 'end',
    textTransform: 'lowercase',
  },
  actions: {
    gap: '5',
    justifyContent: 'end',
  },
};
