import { useCallback } from 'react';
import { tscope } from 'i18n';
import { ZIPCODE_LENGTH } from 'config/constants';

const t = tscope('Forms.fields.errors');

export const useValidate = () => {
  const validate = useCallback((values) => {
    const {
      username,
      regimen,
      zipCode,
    } = values;
    const errors = {};

    if (!username || username.match(/^\s*$/)) errors.username = t('required');
    if (!regimen) errors.regimen = t('required');
    if (!zipCode) errors.zipCode = t('required');
    if (zipCode && zipCode.length !== ZIPCODE_LENGTH) errors.zipCode = t('length', { count: ZIPCODE_LENGTH });

    return errors;
  }, []);

  return validate;
};
