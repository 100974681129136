import React from 'react';
import { t } from 'i18n';
import CountdownContainer from './styles';

const ButtonCountdown = ({ className, remainingTime }) => (
  <CountdownContainer className={className}>
    {t('common.secondFactor.waitingFor_part1')}
    {' '}
    <b>{remainingTime}</b>
    {' '}
    {t('common.secondFactor.waitingFor_part2')}
    {' '}
    <br />
    {t('common.secondFactor.waitingFor_part3')}
  </CountdownContainer>
);

export default ButtonCountdown;
