const ETC = '...';

export const cutString = (str, maxLength, strict = false) => {
  if (!str) return '';

  const margin = strict ? 0 : ETC.length;
  if (str.length <= maxLength + margin) return str;

  return `${str.substring(0, maxLength)}${ETC}`;
};

export const concatFullName = (profile = {}) => {
  if (!profile) return '';
  const {
    firstName, secondName, firstLastName, secondLastName,
  } = profile;

  return [firstName, secondName, firstLastName, secondLastName].filter((e) => !!e).join(' ');
};

export const concatFullName2 = (profile = {}) => {
  if (!profile) return '';
  const {
    firstName, secondName: middleName, firstLastName: lastName, secondLastName,
  } = profile;

  return [firstName, middleName, lastName, secondLastName].filter((e) => !!e).join(' ');
};
