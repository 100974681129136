import { request } from '../../fetchers/yotepresto';

const fetch = async () => {
  const path = '/v1/referrals';
  const method = 'get';
  const { data } = await request({ method, path, log: true });

  return data?.referrals || [];
};

export default fetch;
