import { SET_CREDENTIAL_EMAIL } from 'actions/auth/types';
import * as API from 'apis/investor/profile/email';
import { createAsyncAction, createActions } from 'utils/redux-query';

const { patch: patchSession } = createActions('SESSION');

export const updateEmail = createAsyncAction(
  'UPDATE_PROFILE_EMAIL',
  ({ email, otpCode }) => async (dispatch, getStore) => {
    const currentUid = getStore().auth.credentials.uid;
    try {
      const result = API.update({ email, otpCode });

      dispatch({
        type: SET_CREDENTIAL_EMAIL,
        uid: email,
      });

      await result;

      const { payload: session } = getStore().SESSION;

      dispatch(
        patchSession(
          {
            ...session,
            user: {
              ...session.user,
              email,
            },
          },
          {},
        ),
      );

      return result;
    } catch (e) {
      dispatch({
        type: SET_CREDENTIAL_EMAIL,
        uid: currentUid,
      });
      throw e;
    }
  },
);
