import React, {
  useCallback,
  useEffect,
} from 'react';
import { WS_EVENT_NAMES } from 'config/constants';
import { showToastSuccess } from 'utils/toastHandler';
import { AccountStatementDocText } from '../../components';
import { useUser } from '../graphql';

export const useBindAccountStatementDocCompleted = ({
  bindEvent,
}) => {
  const [, { refetch: refetchUser }] = useUser();

  const callback = useCallback(async () => {
    await refetchUser();
    showToastSuccess(<AccountStatementDocText />, { autoClose: false });
  }, [
    refetchUser,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.ACCOUNT_STATEMENT_PDF_CREATED,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
