import styled from 'styled-components';

const ButtonContainer = styled.button`
  z-index: -1;
  box-shadow: 0 4px 9px 0 rgba(37, 103, 141, 0.07);
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: white;
  border-radius: 1.8rem;
  padding: 0.7rem 1.4rem;
  color: #346071;
  font-size: 1.4rem;
  font-weight: 600;
  transition: all 0.5s;
  height: 3rem;
  & svg {
    margin-left: 0;
    margin-right: 1.4rem;
  }
  &:hover {
    color: #0076ff;
    cursor: pointer;
    transform: translateY(-2px);
    box-shadow: 0px 6px 13px 2px rgba(37, 103, 141, 0.1);

    svg {
      fill: #0076ff;
      transition: all 0.5s;
    }
  }
  &:active {
    transform: translateY(2px);
  }
`;

export default ButtonContainer;
