import React from 'react';
import {
  CheckboxBox, CheckboxBorder, CheckboxText, CheckboxInput,
} from './styles';

const CheckboxContainer = (props) => {
  const {
    id, onChange, text, value, onBlur, name, color, ...rest
  } = props;

  return (
    <CheckboxBox {...rest}>
      <CheckboxBorder checked={value}>
        <CheckboxInput
          id={id}
          checked={value}
          name={name}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
        />
      </CheckboxBorder>
      <CheckboxText htmlFor={id} color={color}>
        {text}
      </CheckboxText>
    </CheckboxBox>
  );
};

export default CheckboxContainer;
