import { tscope } from 'i18n';
import { useCallback, useState } from 'react';
import { showToastSuccess } from 'utils/toastHandler';
import { useBeneficiaryQuery, useRemoveBeneficiary } from './graphql';

const t = tscope('settings.account.beneficiarySection');

export const useController = () => {
  const { data } = useBeneficiaryQuery();
  const beneficiary = data?.user?.donee;
  const isBeneficiaryIncomplete = !beneficiary?.address;
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const openDeleteDialog = () => setDeleteDialogOpen(true);
  const closeDeleteDialog = () => setDeleteDialogOpen(false);
  const onRemoveCompleted = useCallback(() => {
    showToastSuccess(t('alerts.success.removeBeneficiary'));
    closeDeleteDialog();
  }, []);
  const [removerBeneficiary, { loading: isDeletingBeneficiary }] = useRemoveBeneficiary({
    onCompleted: onRemoveCompleted,
  });

  const onRemoveBeneficiary = () => {
    removerBeneficiary({ variables: { input: {} } });
  };

  return {
    beneficiary,
    openDeleteDialog,
    closeDeleteDialog,
    isDeleteDialogOpen,
    onRemoveBeneficiary,
    isDeletingBeneficiary,
    isBeneficiaryIncomplete,
  };
};
