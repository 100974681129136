import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import { ReactComponent as AutoinvestIcon } from 'assets/AutoInvestIcon.svg';
import PATHS from 'routes';
import statusOptions from 'views/DashboardContent/Portfolio/ActiveState/Filter/utils';
import getStatusColors from 'views/DashboardContent/Portfolio/ActiveState/utils';
import { Tag, Flex } from 'components';
import Divider from 'components/Divider';
import { REQUISITION_STATUS } from 'config/constants';

const { investorPortfolioRequisitionPath } = PATHS;

const RequisitionItem = styled.div``;

export const Item = (props) => {
  const { detail, IdColumnBasis } = props;
  const {
    origin,
    solicitud,
    estado,
    estatus,
    montoPrestado,
    acreditado,
    porPagar,
    iDispositionId,
    tags,
  } = detail;

  const REQUISITION_DETAIL_PATH = estatus === REQUISITION_STATUS.CANCEL
    ? ''
    : investorPortfolioRequisitionPath(
      { requisitionId: solicitud },
      { dispositionId: iDispositionId },
    );

  return (
    <RequisitionItem
      as={estatus === REQUISITION_STATUS.CANCEL ? 'div' : Link}
      to={REQUISITION_DETAIL_PATH}
      className="list__body__content__item"
    >
      <Flex
        className="list__item id"
        direction={{ base: 'column', xs: 'row' }}
        grow="0 !important"
        flexBasis="6rem"
      >
        <Tag key={solicitud} fontSize="1rem" lineHeight="1.4rem" p="0.4rem 0.2rem">
          {solicitud}
        </Tag>
      </Flex>
      <Divider borderColor="schema.gray.200" h="5.2rem" position="vertical" />
      {IdColumnBasis && (
        <>
          <Flex
            className="list__item"
            display={{ base: 'none', xs: 'flex' }}
            justifyContent="center"
            flexBasis={IdColumnBasis}
            px="0.5rem"
          >
            {tags?.map((tag) => (
              <Tag
                tooltip={tag.description}
                ml={{ base: '-0.5rem', xs: '0' }}
                fontSize="1rem"
                lineHeight="1.4rem"
                key={tag.nomenclature}
              >
                {tag.nomenclature}
              </Tag>
            ))}
          </Flex>
          <Divider
            borderColor="schema.gray.200"
            display={{ base: 'none', xs: 'block' }}
            h="5.2rem"
            position="vertical"
          />
        </>
      )}
      <Flex
        className="list__item status"
        maxWidth="10rem"
        style={{ color: getStatusColors(estado) }}
      >
        <p className="inherited">
          {statusOptions.find((status) => status.value === estado)?.text || estado}
        </p>
      </Flex>
      <Divider borderColor="schema.gray.200" h="5.2rem" position="vertical" />
      <Flex className="list__item lent" flexBasis="5rem">
        <span>
          $
          {montoPrestado}
        </span>
        {' '}
        {origin && <AutoinvestIcon title="" />}
      </Flex>
      <Divider borderColor="schema.gray.200" h="5.2rem" position="vertical" />
      <Flex className="list__item owes" flexBasis="5rem">
        <p className="inherited">{toCurrencyWithDecimals(porPagar)}</p>
      </Flex>
      <Divider borderColor="schema.gray.200" h="5.2rem" position="vertical" />
      <Flex className="list__item user" flexBasis="5rem">
        <p className="inherited">{acreditado}</p>
      </Flex>
    </RequisitionItem>
  );
};
