import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PROOF_OF_ADDRESS_STATUS } from 'config/constants';
import PATHS from 'routes';
import { useReduxQuery } from 'utils/redux-query';

export const useController = () => {
  const [session] = useReduxQuery('SESSION');
  const { status: proofOfAddressStatus } = session?.user?.proofOfAddress || {};
  const history = useHistory();
  const onSubmit = () => {};

  useEffect(() => {
    if (proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.ON_REVIEW) {
      history.push(PATHS.investorSettingsLimitsPath());
    }
  }, [proofOfAddressStatus]);

  return {
    onSubmit,
  };
};
