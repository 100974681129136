import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import { toast } from 'react-toastify';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import get from 'lodash.get';
import { isNil } from 'utils';
import { getCartList } from 'actions/cart/cartActions';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import { addRequisitionToCart } from 'apis/cart';
import { messages, MAX_LENDING_AMOUNT } from 'config/constants';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { FeaturesContext } from 'utils/featureFlags';
import { toCurrency } from 'utils/numberFormatters';
import MobileActionBarContainer from './styles';

const MobileActionBar = (props) => {
  const {
    highlightRequisition,
    animation,
    sideBarHidden,
    currentHighlighted,
    getCartItems,
    selectedRequisition,
  } = props;
  const [addingToCart, setAddingToCart] = useState(false);
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const availableFunds = statements?.saldoDisponible;
  const [diversificationLimitAmounts, { refetch: refetchDiversification }] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');
  const [inputValue, setInputValue] = useState('');
  const showButton = inputValue !== '';
  const [isLoadingAmount, setIsLoadingAmount] = useState(true);
  const { diversification } = useContext(FeaturesContext);

  useEffect(() => {
    if (!statements) return;

    refetchDiversification();
  }, [statements]);

  useEffect(() => {
    setIsLoadingAmount(isNil(availableFunds));
  }, [availableFunds]);

  const handleInputChange = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setInputValue(event.target.value);
    }
  };

  const closeActionBar = () => highlightRequisition({ id: 'close' });

  const validateInput = (amount) => {
    const parsedValue = parseInt(amount, 10);
    const parsedAvailableFunds = parseInt(availableFunds, 10);
    const remainingAmount = get(selectedRequisition, 'loan_detail.missing_amount');
    const limitAmount = diversificationLimitAmounts?.[selectedRequisition?.id?.toString()] || 0;
    const getDiversificationMessage = () => (availableFunds === 0 || limitAmount !== 0
      ? `¡Mejora la diversificación de tus préstamos! Invierte,
                 como máximo ${toCurrency(limitAmount)} en esta solicitud.`
      : 'Alcanzaste la cantidad máxima a prestar en esta solicitud');
    let errors = [];
    if (parsedValue > parsedAvailableFunds) errors = [...errors, 'La cantidad a prestar es mayor a tu disponible'];
    if (parsedValue > parseInt(remainingAmount, 10)) errors = [...errors, 'La cantidad a prestar es mayor al restante del préstamo'];
    if (!amount.length) errors = [...errors, messages.required];
    if (
      !parseInt(selectedRequisition.funded_amount, 10)
      && (amount.length < 2 || parseInt(amount, 10) < 200)
    ) errors = [...errors, 'El valor debe ser mayor a 200'];
    if (!(parseInt(amount, 10) % 100 === 0)) errors = [...errors, 'El valor debe ser multiplo de 100'];
    if (parseInt(amount, 10) > MAX_LENDING_AMOUNT) errors = [...errors, `El valor no debe ser mayor a ${toCurrency(MAX_LENDING_AMOUNT)}`];
    if (diversification && amount > limitAmount) errors.push(getDiversificationMessage());
    if (errors.length === 0) errors = false;
    return errors;
  };

  const handleAddToCart = async (lendValue) => {
    setAddingToCart(true);
    if (!validateInput(lendValue)) {
      try {
        await addRequisitionToCart({
          id: currentHighlighted,
          lendValue,
        });
        getCartItems();
        closeActionBar();
        return true;
      } catch (e) {
        toast.error('Error al agregar la solicitud al carrito.');
        return false;
      }
    }
    toast.warn(() => validateInput(lendValue).map((error) => <div>{error}</div>));
    return setAddingToCart(false);
  };

  const FROM_STYLES = { width: 0, marginRight: 0 };
  const TO_STYLES = { width: 52, marginRight: 10 };

  const transition = useTransition(showButton, null, {
    from: FROM_STYLES,
    enter: TO_STYLES,
    leave: FROM_STYLES,
    unique: true,
  });

  const renderAddToCartButton = transition.map(
    ({ item, key, props: animateButton }) => item && (
      <animated.div style={{ ...animateButton }} key={key}>
        <Button
          type="button"
          color="primary"
          disabled={addingToCart || isLoadingAmount}
          onClick={() => handleAddToCart(inputValue)}
        >
          <CartIcon title="" />
        </Button>
      </animated.div>
    ),
  );
  return (
    <MobileActionBarContainer style={animation} sideBarHidden={sideBarHidden}>
      <div className="action-bar__body">
        <button type="button" className="chevron" onClick={closeActionBar}>
          <div className="chevron--icon" />
        </button>
        <p>Opciones recomendadas</p>
        {!isLoadingAmount ? (
          <>
            <div className="option__group">
              <Button
                color="info"
                type="button"
                disabled={addingToCart}
                onClick={() => handleAddToCart('200')}
              >
                <CartIcon />
                $200
              </Button>
              <Button
                color="info"
                type="button"
                disabled={addingToCart}
                onClick={() => handleAddToCart('300')}
              >
                <CartIcon />
                $300
              </Button>
              <Button
                color="info"
                type="button"
                disabled={addingToCart}
                onClick={() => handleAddToCart('500')}
              >
                <CartIcon />
                $500
              </Button>
            </div>
            <div className="action-bar--input">
              <div className="description">
                <p>Otra</p>
                <p>cantidad</p>
              </div>
              <div className="input__group">
                <span>$</span>
                {' '}
                <input type="text" value={inputValue} onChange={handleInputChange} />
              </div>
              {!isLoadingAmount && renderAddToCartButton}
            </div>
            {addingToCart && <DotsLoader black />}
          </>
        ) : (
          <DotsLoader black />
        )}
      </div>
    </MobileActionBarContainer>
  );
};

export default connect(null, {
  getCartItems: getCartList,
})(MobileActionBar);
