import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useTimer from 'utils/useTimer';
import { Text } from 'ui';
import { styles } from './CopyToClipboardText.style';

export const CopyToClipboardText = ({
  onCopy,
  children,
  timeToCopy = 2000,
  ...props
}) => {
  const { time, reinitialize } = useTimer(0);
  const isCopied = time !== 0;
  const onCopyHandler = () => {
    if (onCopy) onCopy();
    reinitialize(timeToCopy);
  };
  const style = isCopied
    ? styles.copied
    : styles.notCopied;

  return (
    <CopyToClipboard
      data-testid="copy-to-clipboard-text"
      text={children}
      onCopy={onCopyHandler}
    >
      <Text {...style} {...props}>
        {isCopied
          ? 'Copiado'
          : children}
      </Text>
    </CopyToClipboard>
  );
};
