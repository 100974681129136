export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const SET_ACCOUNT_STATEMENT = 'SET_ACCOUNT_STATEMENT';
export const FETCHING_ACCOUNT_PORTFOLIO = 'FETCHING_ACCOUNT_PORTFOLIO';
export const SET_PORTFOLIO_CACHING = 'SET_PORTFOLIO_CACHING';
export const TOGGLE_DETAILS = 'TOGGLE_DETAILS';
export const SET_SOCKET_VALUES = 'SET_SOCKET_VALUES';
export const SET_FUNDED_REQUISITIONS_IDS = 'SET_FUNDED_REQUISITIONS_IDS';
export const SET_ACCOUNT_BALANCES = 'SET_ACCOUNT_BALANCES';
export const SET_ACCOUNT_BALANCES_SIDEBAR = 'SET_ACCOUNT_BALANCES_SIDEBAR';
export const SET_ACCOUNT_STATEMENT_CACHING = 'SET_ACCOUNT_STATEMENT_CACHING';
export const SET_ACCOUNT_STATEMENT_DATA_READY = 'SET_ACCOUNT_STATEMENT_DATA_READY';
export const SET_PORTFOLIO_DATA_READY = 'SET_PORTFOLIO_DATA_READY';
