import React from 'react';
import {
  Text,
  Button,
  Popover,
  TimeIcon,
  CheckIcon,
  Container,
  PopoverBody,
  PopoverArrow,
  WarningTwoIcon,
  PopoverTrigger,
  PopoverContent,
  Heading,
  Box,
  Flex,
} from 'ui';
import { t } from 'i18n';
import { WITHDRAWAL_BANK_ACCOUNT_STATUS } from 'config/constants';
import { style } from './StatusIndicator.style';

const StatusPopover = ({ children, title }) => (
  <Popover trigger="hover">
    <PopoverTrigger>
      {children}
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <Box display="flex" flexDirection="column">
          {title && (
            <Heading fontSize="md" fontWeight="500" textAlign="center">
              {title}
            </Heading>
          )}
          <Button
            animationType="none"
            textAlign="center"
            whiteSpace="break-spaces"
            variant="link"
            as="a"
            size="sm"
            target="_blank"
            href={t('settings.bankAccounts.popoverInvalid.link')}
          >
            {t('settings.bankAccounts.popoverInvalid.linkText')}
          </Button>
        </Box>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

const STATUS = (status = WITHDRAWAL_BANK_ACCOUNT_STATUS.VALIDATING) => ({
  [WITHDRAWAL_BANK_ACCOUNT_STATUS.VALIDATING]: {
    label: t(`settings.bankAccounts.status.${WITHDRAWAL_BANK_ACCOUNT_STATUS.VALIDATING}`),
    Icon: <TimeIcon boxSize="1.4rem" color="gray.500" data-testid="timeIcon" />,
    color: 'gray.500',
  },
  [WITHDRAWAL_BANK_ACCOUNT_STATUS.VALID]: {
    label: t(`settings.bankAccounts.status.${WITHDRAWAL_BANK_ACCOUNT_STATUS.VALID}`),
    Icon: <CheckIcon boxSize="1.4rem" color="blue.500" data-testid="checkIcon" />,
    color: 'blue.500',
  },
  [WITHDRAWAL_BANK_ACCOUNT_STATUS.INVALID]: {
    label: t(`settings.bankAccounts.status.${WITHDRAWAL_BANK_ACCOUNT_STATUS.INVALID}`),
    Icon: (
      <WarningTwoIcon
        color="red.500"
        boxSize="1.4rem"
        cursor="pointer"
        data-testid="warningIcon"
      />),
    color: 'red.500',
  },
}[status]);

export const StatusIndicator = (props) => {
  const { status } = props;

  const { color, label, Icon } = STATUS(status);

  return (
    <Container
      {...style.container}
    >
      <StatusPopover>
        <Flex alignItems="center">
          <Text {...style.text} color={color}>{label}</Text>
          {Icon}
        </Flex>
      </StatusPopover>
    </Container>
  );
};
