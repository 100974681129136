import {
  css,
  colors,
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

const inputVariants = (variant) => ({
  outline: css`
      border-radius: 4px;
      border: 1px solid;
      border-color: ${colors.schema.gray[300]};
    `,
}[variant]);

const sizes = (size) => ({
  small: css`
      padding: 0.7rem 1rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
    `,
  normal: css`
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1.2rem 3rem;
    `,
  big: css`
      font-size: 1.6rem;
      line-height: 2rem;
      padding: 1.8rem 3rem;
    `,
}[size]);

const Input = styled.input.withConfig({ shouldForwardProp })`
  outline-color: ${colors.schema.blue[500]};
  outline-offset: 2px;
  padding: 0.5rem 1rem;
  z-index: 5;

  ${({ variant }) => inputVariants(variant)};
  ${({ size }) => sizes(size)};
  ${({ isInvalid }) => (isInvalid
    ? css`
          border: 1px solid ${colors.schema.red[500]};
        `
    : '')};

  ${boxHelpers}
`;

Input.defaultProps = {
  variant: 'outline',
};

export default Input;
