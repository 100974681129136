import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import Box from 'components/Box';

export const EmptyStateCardContent = styled(Box)`
  border: 1px solid ${colors.gray.g12};
  flex-direction: column;
  padding: 5rem 2rem;
  background-color: transparent;
  box-shadow: none;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    width: 30rem;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 5rem;
  text-align: center;
  color: ${colors.gray.g5};
`;

export const Description = styled.p`
  text-align: center;
  color: ${colors.gray.g5};
`;
