import camelify from 'camelcase-keys-recursive';
import snakeify from 'snakecase-keys';
import { request } from '../../fetchers/yotepresto';

const confirmChange = async (confirmChangeParams) => {
  const body = snakeify(confirmChangeParams);
  const config = {
    path: '/v2/second_factors/confirm_change',
    method: 'post',
    body,
  };

  const { data } = await request(config);

  return camelify(data);
};

export default confirmChange;
