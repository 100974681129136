import { getUserSignedContracts } from 'apis/settings/accountProfile';
import * as types from './types';

export const toggleInvoiceSwitch = (show) => ({
  type: types.TOGGLE_INVOICE_SWITCH,
  payload: show,
});

export const fetchUserSignedContracts = () => async (dispatch) => {
  dispatch({ type: types.IS_FETCHING_USER_SIGNED_CONTRACTS, payload: true });
  const response = await getUserSignedContracts();
  dispatch({
    type: types.FETCH_USER_SIGNED_CONTRACTS,
    payload: response,
  });
};

export const toggleContractModal = (show) => ({
  type: types.TOGGLE_CONTRACT_MODAL,
  payload: show,
});

export const setSelectedContract = (contract) => ({
  type: types.SET_SELECTED_CONTRACT,
  payload: contract,
});
