import React from 'react';
import { t } from 'i18n';
import ScoreSVG from 'assets/requisitions/score.svg';
import HistorySVG from 'assets/requisitions/historial.svg';
import QuerySVG from 'assets/requisitions/consultas.svg';
import AccountSVG from 'assets/requisitions/cuenta.svg';
import { CardHistoryLoader } from 'components/Loaders';
import CreditHistoryContainer from './styles';

const CreditHistory = ({ isLoading, creditInfo, isFundedRequisition }) => {
  const renderLoaders = () => (
    <div className="container">
      <div className="item">
        <CardHistoryLoader />
      </div>
      <div className="item">
        <CardHistoryLoader />
      </div>
      <div className="item">
        <CardHistoryLoader />
      </div>
      <div className="item">
        <CardHistoryLoader />
      </div>
    </div>
  );

  const renderContent = () => {
    if (!Object.keys(creditInfo).length) return <div className="emptySpace" />;
    return (
      <>
        <p className="mainContainer__content__title">
          {t('detailRequisition.titles.creditReport')}
        </p>
        <div className="container">
          <div className="item">
            <div className="item__score">
              <div className="item__number">
                <img src={ScoreSVG} alt="score icon" />
                <h4>{creditInfo.score}</h4>
              </div>
              <p className="item__text">{t('detailRequisition.score')}</p>
            </div>
          </div>
          <div className="item">
            <div className="item__history">
              <div className="item__number">
                <img src={HistorySVG} alt="history SVG" />
                <h4>{creditInfo.history_antiquity}</h4>
              </div>
              <p className="item__text">{t('detailRequisition.yearsHistory')}</p>
            </div>
          </div>
          <div className="item">
            <div className="item__consult">
              <div className="item__number">
                <img src={QuerySVG} alt="query icon" />
                <h4>{creditInfo.inquiries}</h4>
              </div>
              <p className="item__text">{t('detailRequisition.inquiries')}</p>
            </div>
          </div>
          <div className="item">
            <div className="item__account">
              <div className="item__number">
                <img src={AccountSVG} alt="query icon" />
                <h4>{creditInfo.open_accounts}</h4>
              </div>
              <p className="item__text">{t('detailRequisition.openAccounts')}</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <CreditHistoryContainer className={isFundedRequisition ? 'funded' : ''}>
      {isLoading ? renderLoaders() : renderContent()}
    </CreditHistoryContainer>
  );
};

export default CreditHistory;
