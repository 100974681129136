import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import Modal from 'components/Modal';
import { TextStyle } from 'components/Typography';
import ButtonContainer from 'components/Button/styles';
import SimpleProgressBar from 'components/ProgressBar/Simple';

export const LevelUpModalContainer = styled(Modal)`
  padding: 3rem 4rem !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 420px !important;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 2rem !important;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WelcomeText = styled.h2`
  ${TextStyle}
  color: #134052;
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  font-size: 3rem;
`;

export const BodyText = styled.p`
  ${TextStyle}
  text-align: center;
  margin-bottom: 2rem;
`;

export const LimitText = styled.p`
  ${TextStyle}
`;

export const Box = styled.div`
  border: 1.5px solid #c2d4db;
  padding: 2rem 3rem;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.04);
`;

export const LevelUpButton = styled(ButtonContainer)`
  padding: 2rem 3rem;
  background-color: #05c574;
  font-size: 1.8rem;

  &:hover {
    background-color: #05c574;
  }
`;

export const ProgressBar = styled(SimpleProgressBar)`
  margin: 1rem 0rem 0rem;
`;
