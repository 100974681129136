import React from 'react';
import PropTypes from 'prop-types';
import Modal, { CloseButton } from 'components/Modal';
import ModalSwitch from './ModalSwitch';
import SecondFactorModalFooter from './SecondFactorModalFooter';

const SecondFactorModal = (props) => {
  const { isOpen, onClose, currentModal } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentStyle={{ maxHeight: '90%' }}
    >
      <CloseButton onClick={onClose} />
      <ModalSwitch
        currentModal={currentModal}
        onRequestClose={onClose}
        onConfirmChange={onClose}
      />
      <SecondFactorModalFooter />
    </Modal>
  );
};

SecondFactorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentModal: PropTypes.string,
};

SecondFactorModal.defaultProps = {
  currentModal: null,
};

export default SecondFactorModal;
