import {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { getWSClient } from 'config';
import { isFirstRender } from 'utils/react-utils';

/**
 * auxiliar closures to know when StrictMode has rendered <App />
 * just is applied on development
 */
const firstRenderAux1 = isFirstRender();
const firstRenderAux2 = isFirstRender();

export const useClient = ({
  credentials,
}) => {
  const {
    uid,
    client,
    expiry,
    tokenType,
    accessToken,
  } = credentials || {};
  const [connectionState, setConnectionState] = useState(null);
  const isCredentialAvailable = !!accessToken;

  const preventReconnection = [
    !isCredentialAvailable,
  ].some(Boolean);

  const pusherClient = useMemo(() => {
    if (!firstRenderAux1()) return null;
    if (preventReconnection) return null;
    return getWSClient({
      uid,
      client,
      expiry,
      tokenType,
      accessToken,
    });
  }, [
    // we ignore `expiry` dep. since it cause many renders and thus reinitialize Pusher instance
    // the right way is to use other kind of credential (that does not change in a session)
    // in order to subscribe users but it needs to be changed from back-side
    uid,
    client,
    tokenType,
    accessToken,
    preventReconnection,
  ]);

  useEffect(() => {
    if (!firstRenderAux2() || !pusherClient) return () => {};
    return () => {
      pusherClient.disconnect();
    };
  }, [
    pusherClient,
  ]);

  useEffect(() => {
    if (!pusherClient) return () => {};
    const callback = (states) => { setConnectionState(states); };
    pusherClient.connection.bind(
      'state_change',
      callback,
    );
    return () => {
      pusherClient.connection.unbind('state_change', callback);
    };
  }, [
    pusherClient,
  ]);

  return {
    connectionState,
    client: pusherClient,
  };
};
