import { SUCCESSFUL_HTTP_STATUS, MAINTENANCE_HTTP_STATUS } from 'config/constants';
import { request } from './fetchers/yotepresto';

export const fetchApiStatus = async () => {
  const method = 'get';
  const path = '/healthcheck';

  const { status } = (await request({ method, path })) || {};
  const healthy = status === SUCCESSFUL_HTTP_STATUS;
  const inMaintenance = status === MAINTENANCE_HTTP_STATUS;

  return { healthy, inMaintenance };
};
