import moment from 'moment';
import { fetchPaymentSchedule } from 'apis/calendar';
import * as types from './types';

export const getPaymentSchedule = ({ year, month, day }) => async (dispatch) => {
  dispatch({ type: types.SET_SCHEDULE_LOADING_STATUS, payload: true });
  const response = await fetchPaymentSchedule({ year, month });
  if (!response) return;

  const { summary, schedule } = response;

  const monthSchedule = (schedule || []).reduce((result, payment) => {
    const paymentDay = moment(payment.fecha_pago, 'DD/MM/YYYY').format('DD');
    const prevSchedule = result[paymentDay] || [];
    return Object.assign(result, { [paymentDay]: [...prevSchedule, payment] });
  }, {});

  const monthPad = month.padStart(2, '0');
  const startAtDay = day || moment().format('DD');
  const lastDay = parseInt(moment(`${year}-${monthPad}`).endOf('month').format('D'), 10);
  const payload = {
    summary,
    schedule: monthSchedule,
    lastDay,
    date: { year, month, day: startAtDay },
    loadingSchedule: false,
  };

  dispatch({ type: types.UPDATE_CALENDAR, payload });
  dispatch({ type: types.SET_SCHEDULE_LOADING_STATUS, payload: false });
};

export const setCurrentDate = (date) => ({ type: types.UPDATE_SCHEDULE_DATE, payload: date });

export const setDayAmount = (amount) => ({
  type: types.UPDATE_SCHEDULE_DAY_AMOUNT,
  payload: amount,
});

export const toggleAsideCalendar = (value) => ({
  type: types.TOGGLE_ASIDE_CALENDAR,
  payload: value,
});
