export const getAutoInvestStatus = (autoInvestmentConfigs) => {
  if (!autoInvestmentConfigs) return null;

  if (autoInvestmentConfigs?.length === 0) return 'inactivated';

  if (autoInvestmentConfigs[0].active) return 'activated';

  if (autoInvestmentConfigs[0].active === false) return 'paused';

  return 'inactivated';
};
