import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const MobilePortfolioListContainer = styled(ContainerDiv)`
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  justify-content: center;
  & .mobile__list {
    &__body {
      width: 95%;
      & .list__body {
        &__header {
          display: flex;
          font-size: 1.1rem;
          color: #6ea0b0;
          margin-bottom: 2rem;
          & .header__item {
            display: flex;
            justify-content: center;
            flex-grow: 1;
            &.product_type {
              min-width: 1.5rem;
            }
          }
        }
        &__content {
          &__item {
            padding: 0rem 0.5rem;
            height: 5.2rem;
            display: flex;
            background-color: white;
            border-radius: 0.6rem;
            text-decoration: none;
            min-height: 5.2rem;
            align-items: center;
            box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 1rem;
            & .list__item {
              color: #346071;
              flex-grow: 1;
              height: 100%;
              justify-content: center;
              align-items: center;
              font-size: 1rem;
              font-weight: normal;
              &.product_type {
                padding-left: 0;
                padding-right: 0.3rem;
                text-align: center;

                min-width: 1.5rem;
                & span.type {
                  text-align: center;
                  font-size: 1.1rem;
                  padding: 0.2rem 0.3rem;
                  border-radius: 0.6rem;
                  width: auto;
                  border: 0;
                  background: ${colors.gray.g2};
                  font-weight: 500;
                }
              }
              &.status {
                font-weight: 600;

                text-align: center;
              }
              &.lent {
                font-weight: 500;
                display: flex;
                flex-direction: column-reverse;
                svg {
                  width: 1.9rem;
                  margin-top: -0.8rem;
                  margin-bottom: -0.4rem;
                }
              }
              &.user {
                text-align: center;
                padding: 0 0.3rem;
                p {
                  font-size: 0.9rem;
                  color: ${colors.gray.g5};
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MobilePortfolioListContainer;
