import styled from 'styled-components';
import { breakPoints } from 'config/constants';

export const OTPWidgetContainer = styled.div`
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #346071;
    font-size: 1.3rem;
    padding: 2rem;
    flex-wrap: wrap;
    & .react-code-input {
      margin: 0 -4px;
    }
    & label {
      display: flex;
      flex-direction: column;
      & .error__message {
        color: red;
        min-height: 1.5rem;
      }
    }
    & button {
      align-self: flex-start;
      margin-top: 2.0rem;
      margin-left: 1rem;
      height: 46px;
    }
    @media (max-width: ${breakPoints.smallBreakPoint}px) {
        flex-direction: column;
        & button {
          width: 100%;
          margin: 1rem 0 0 0;
        }
      }
    }
    & input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      padding: 2px !important;
      text-align: center;
      font-size: 16px !important;
      font-family: "proxima-nova", sans-serif !important;
      height: 4.4rem !important;
      border: 1px solid #a2bcc5 !important;
      border-radius: .4rem !important;
      box-shadow: none !important;
      outline: none;
      font-size: 1.5rem !important;
      color: #134052 !important;
      &:focus {
        border-color: #0076ff !important;
      }
    }
  }
  .no-label {
    width: 100%;
    height: 1.6rem;
  }
  .shrink-input {
      input{
        @media(max-width: ${breakPoints.extraSmallBreakPoint}px) {
            height: 4rem !important;
            width: 2.6rem !important;
        }
      }
    }
`;
