import { request } from 'apis/fetchers/yotepresto';
import snakify from 'snakecase-keys';
import camelify from 'camelcase-keys-recursive';

async function update({ autoinvestConfig, password }) {
  const {
    rates, months, purposes, amount,
  } = autoinvestConfig;

  const path = `/v1/investor/portfolio_auto_investments/${autoinvestConfig.id}`;
  const method = 'put';
  const extraHeaders = { password };
  const body = snakify({
    rates,
    months,
    purposes,
    amount,
  });
  const { data } = await request({
    method, path, body, extraHeaders,
  });

  return data ? camelify(data) : null;
}

export default update;
