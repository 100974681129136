import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { calcHeightContentOnBrowserType } from 'utils/responsiveHandler';
import colors from 'styles/colors';
import { ReactComponent as WhatsSVG } from 'assets/requisitions/whatsapp.svg';
import { ReactComponent as SmsSVG } from 'assets/requisitions/sms.svg';
import { ReactComponent as EnvelopSVG } from 'assets/requisitions/email.svg';
import { ReactComponent as CallSVG } from 'assets/requisitions/call.svg';
import { ReactComponent as HomeManagementSVG } from 'assets/requisitions/homemanagement.svg';

const {
  extraLargeBreakPoint,
  largeBreakPoint,
  midBreakPoint,
  smallBreakPoint,
  extraSmallBreakPoint,
} = breakPoints;
const container = ({ isMobile, ...otherProps }) => (
  <div
    {...otherProps}
    onScroll={(event) => event.stopPropagation()}
  />
);

export const WhatsappIcon = styled(WhatsSVG)`
  height: 0.8em;
`;

export const SmsIcon = styled(SmsSVG)`
  height: 0.8em;
`;

export const EnvelopIcon = styled(EnvelopSVG)`
  height: 0.8em;
`;

export const CallIcon = styled(CallSVG)`
  height: 0.8em;
`;

export const HomeManagementIcon = styled(HomeManagementSVG)`
  height: 0.8em;
`;

export const P = styled.p``;

export const DetailContentContainer = styled(container)`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  height: 94.5vh;
  position: relative;
  @media (max-width: ${midBreakPoint}px) {
    margin-left: 0;
  }
  @media (max-width: 700px) {
    ${calcHeightContentOnBrowserType(93)};
    margin-left: 0;
  }
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    height: calc(93vh - 75px);
  }
  background-color: #f8fafc;
  .fundedHeader {
    width: 100%;
    display: grid;
    &.card {
      border-radius: 0;
    }
  }
  .card {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.04);
    &.resume {
      padding: 1.5rem;
    }
    &.mobil {
      padding: 1.5rem;
      margin-top: 2.5rem;
    }
  }
  .closeButton {
    align-self: flex-start;
    margin-left: 3rem;
    margin-top: 2rem;
    text-align: center;
    color: #0076ff;
    font-size: 1.5rem;
    text-decoration: none;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 1rem;
    }
    :hover {
      cursor: pointer;
    }
  }
  .circleNavigation {
    position: absolute;
    top: calc(100% - 55%);
    width: 41px;
    height: 85px;
    background-color: #ffffff;
    border: 1px solid #cfe6ef;
    box-shadow: 0 0 22px 0 rgba(4, 71, 92, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 3.5rem;
    color: #a8c9d6;
    font-weight: normal;
    :hover {
      cursor: pointer;
    }
    &.left {
      float: left;
      border-left: 0;
      border-bottom-right-radius: 110px;
      border-top-right-radius: 110px;
      padding-left: 0.5rem;
    }
    &.right {
      right: 0;
      float: right;
      text-align: right;
      border-right: 0;
      border-bottom-left-radius: 110px;
      border-top-left-radius: 110px;
      padding-right: 0.5rem;
      svg {
        margin-left: 2rem;
      }
    }
  }
  .fundedBar {
    margin-top: 5%;
    @media (max-width: ${extraLargeBreakPoint}px) {
      margin-top: 5px;
      margin-bottom: 10px;
    }
    p {
      color: #04475c;
      font-size: 1.2rem;
      margin-top: 1.5rem;
      b {
        font-size: 18px;
        line-height: 2.2rem;
        color: #346071;
      }
    }
    &.mobil {
      margin-top: 1rem;
      p {
        margin-top: 1rem;
        text-align: right;
        b:first-child {
          margin-left: 3px;
        }
      }
    }
  }
  .messageBar {
    color: white;
    width: 100%;
    font-size: ${(props) => (props.isMobile ? '1.2rem' : '1.4rem')};
    display: ${(props) => (props.isMobile ? 'block' : 'flex')};
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    .funded,
    .amountCart {
      flex: 1;
      text-align: center;
      padding: 1.7rem 0;
      @media (max-width: ${extraLargeBreakPoint}px) {
        padding: 1.4rem 0;
      }
    }
    .funded {
      background-color: #4ed79f;
      padding-left: 1rem;
      font-size: 600;
    }
    .amountCart {
      background-color: #ecf5fe;
      border: 1px solid rgba(59, 144, 229, 0.3);
      border-right: 0;
      font-size: 600;
      color: #346071;
    }
  }
  .mainContainer {
    flex: 1;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overscroll-behavior-y: none;
    .splitContent {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      @media (max-width: ${largeBreakPoint}px) {
        flex-direction: column;
      }
      &__card {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 2.3rem 3rem;
        &__body {
          flex: 1;
          display: flex;
          flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
          align-items: center;
        }
      }
      &__left,
      &__right {
        flex: 1;
        &.funded {
          width: 100%;
        }
      }
      &__right {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: ${largeBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
          align-items: flex-start;
        }
      }
      &__icons {
        margin-right: 17px;
      }
      .financialStatus {
        display: flex;
        flex-direction: row;
        @media (max-width: ${largeBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
          flex-direction: column;
        }
        &__item {
          padding: 2rem 2.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          text-align: center;
          :first-child {
            border-right: 1px solid #eaf3f6;
            @media (max-width: ${extraSmallBreakPoint}px) {
              border-right: none;
              border-bottom: 1px solid #eaf3f6;
            }
          }
        }
        &__incomes,
        &__outcome {
          h3 {
            font-size: 2.7rem;
            line-height: 3.3rem;
          }
          @media (max-width: ${largeBreakPoint}px) and (min-width: ${smallBreakPoint}px) {
            h3,
            img {
              display: inline-block;
            }
            img {
              margin-right: 10px;
            }
          }
        }
        &__incomes {
          h3 {
            color: #00c574;
            letter-spacing: 1px;
          }
        }
        &__outcome {
          h3 {
            color: #4e77e4;
            letter-spacing: 1px;
          }
        }
      }
      .expenses {
        &__loader {
          margin-top: 5rem !important;
          align-self: center;
        }
        &__table {
          width: 100%;
          color: #04475c;
          td:first-child {
            width: 60%;
          }
          td:last-child:not(:first-child) {
            font-weight: 500;
            font-size: 1.3rem;
            text-align: right;
          }
          tr > td {
            padding: 0.6rem;
          }
        }
      }
    }
    .personalInfo {
      margin-bottom: ${(props) => (props.isMobile ? '2rem' : '6rem')};
      & .loader {
        height: 21.2rem;
        padding-top: 10rem;
      }
      &__card {
        padding: 2.3rem 3rem;
      }
      &__icons {
        margin-right: 14px;
      }
      &__table {
        border-radius: 8px;
        border: 1px solid #dde5e8;
        overflow: hidden;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @media (max-width: ${largeBreakPoint}px) and (min-width: ${midBreakPoint}px),
          (max-width: ${smallBreakPoint}px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        & > div {
          flex-grow: 1;
          min-height: 10rem;
          width: 100%;
          border: 1px solid #dde5e8;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          @media (max-width: ${smallBreakPoint}px) {
            height: 6rem;
          }
          p:last-child {
            color: #346071;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 24px;
          }
        }
      }
    }
    &__content {
      width: ${(props) => (props.isMobile ? '90%' : '85%')};
      color: #668d9d;
      @media (max-width: ${midBreakPoint}px) {
        .closeButton {
          margin-left: 0;
          margin-top: 3rem;
          font-size: 1.4rem;
        }
      }
      &__title {
        margin-bottom: 1.2rem;
        text-transform: uppercase;
        &.funded {
          color: #668d9d;
          margin-bottom: 0;
          position: relative;
          :hover {
            cursor: pointer;
          }
          :focus {
            outline: none;
          }
          span {
            float: right;
          }
        }
        &--closeIcon {
          margin-top: -0.5rem;
        }
      }
    }
  }
  .tabs {
    .react-tabs {
      &__tab-list {
        text-align: center;
        margin-bottom: -2px;
        border-color: #e0edf1;
        display: flex;
        &:first-child() {
          display: none;
        }
      }
      &__tab {
        color: #346071;
        padding: ${(props) => (props.isMobile ? '1.3rem 2rem' : '2rem 2.5rem 1.5rem 2.5rem')};
        font-size: ${(props) => (props.isMobile ? '1.4rem' : '1.6rem')};
        border-color: #e0edf1;
        line-height: ${(props) => (props.isMobile ? '1.7rem' : '2rem')};
        flex: 1;
        vertical-align: top;
        display: flex;
        justify-content: center;
        align-items: center;
        &.logCollection {
          padding: 2rem 2.5rem 2.5rem 2.5rem;
        }
        @media (max-width: ${extraLargeBreakPoint}px) {
          padding: 1rem 0.4rem;
        }
        @media (max-width: ${smallBreakPoint}px) {
          .borrowers__text {
            padding-right: 0 !important;
          }
        }
        &--selected {
          font-weight: 500;
          border-bottom: none;
          border-radius: 0;
        }
        .questions {
          &__text {
            @media (max-width: 700px) {
              width: 120px;
              margin: 0 auto;
              &.funded {
                width: 11.5rem;
              }
            }
          }
        }
        .borrowers {
          &__text {
            display: inline-block;
            width: auto;
            margin-right: 34px;
            @media (max-width: ${extraLargeBreakPoint}px) {
              width: 45%;
            }
            @media (max-width: 700px) {
              width: 8rem;
            }
          }
          &__counter {
            background-color: #05c574;
            color: white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 7px;
            top: 50%;
            margin-top: -15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            @media (max-width: ${smallBreakPoint}px) {
              width: 25px;
              height: 25px;
              right: 1rem;
              top: 50%;
              margin-top: -12px;
            }
            @media (max-width: ${extraLargeBreakPoint}px) {
              transform: translateY(-0.7rem);
            }
            @media (max-width: 700px) {
              transform: translateY(0);
            }
          }
        }
      }
      &__tab-panel {
        margin-top: ${(props) => (props.isMobile ? '-2px' : '2px')};
        background-color: white;
        padding: ${(props) => (props.isMobile ? '0' : '3rem 2rem')};
        border-bottom: 1px solid #e7edf3;
        border-left: 1px solid #e7edf3;
        border-right: 1px solid #e7edf3;
        .logCollectionContainer {
          min-height: 10rem;
          &.empty {
            width: ${(props) => (props.isMobile ? '100vw' : '100%')};
          }
          @media (max-width: ${midBreakPoint}px) {
            padding-top: 2rem;
          }
        }
      }
    }
    .payHistory {
      border-collapse: separate;
      border-spacing: 0;
      height: 90%;
      width: 80%;
      margin: 1rem auto;
      overflow: hidden;
      &.mobil {
        width: 90%;
        padding-top: 1rem;
      }
      thead {
        td {
          padding-bottom: 1rem;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          text-align: center;
          :nth-child(4),
          :nth-child(5) {
            padding-left: 3rem;
            padding-right: 3rem;
          }
        }
      }
      tbody {
        margin-top: 1rem;
        tr {
          :first-child {
            td {
              border-top: 1px solid #e3edf0;
              :first-child {
                border-top-left-radius: 8px;
              }
              :last-child {
                border-top-right-radius: 8px;
              }
            }
          }
          :last-child {
            td {
              border-bottom: 1px solid #e3edf0;
              :first-child {
                border-bottom-left-radius: 8px;
              }
              :last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
          :nth-child(even) {
            background-color: #f8f8f8;
          }
          td {
            text-align: center;
            padding-top: 1rem;
            padding-bottom: 1rem;
            :first-child {
              border-left: 1px solid #e3edf0;
            }
            :last-child {
              border-right: 1px solid #e3edf0;
              color: #04475c;
            }
            :nth-child(2) {
              color: #04475c;
            }
          }
          .dataColSpan {
            text-align: left;
            padding-left: 4rem;
          }
        }
      }
    }
    .promisesContainer {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 0 auto 2.5rem;
      padding: 2rem 2rem;
      border: solid 2px #4e77e4;
      border-radius: 15px;
      background-color: ${colors.gray.g0};
      color: #4e77e4;
      font-size: 1.3rem;
      align-items: center;
      @media (max-width: ${largeBreakPoint}px) and (min-width: ${midBreakPoint}px) {
        width: 100%;
      }
      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        width: 100%;
        max-width: 55rem;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
    .logCollectionTable {
      border-collapse: separate;
      border-spacing: 0;
      height: 90%;
      width: 80%;
      margin: 1rem auto;
      overflow: hidden;
      thead {
        td {
          padding-bottom: 1rem;
          text-align: center;
          :nth-child(2) {
            text-align: left;
          }
        }
      }
      tbody {
        margin-top: 1rem;
        tr {
          :first-child {
            td {
              border-top: 1px solid #e3edf0;

              :first-child {
                border-top-left-radius: 8px;
              }
              :last-child {
                border-top-right-radius: 8px;
              }
            }
          }
          :last-child {
            td {
              border-bottom: 1px solid #e3edf0;
              :first-child {
                border-bottom-left-radius: 8px;
              }
              :last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
          :nth-child(even) {
            background-color: #f8f8f8;
          }
          td {
            text-align: center;
            padding: 1.5rem 0.5rem;
            :first-child {
              border-left: 1px solid #e3edf0;
              text-align: left;
              padding-left: 2rem;
              svg {
                padding-right: 0.5rem;
              }
            }
            :last-child {
              border-right: 1px solid #e3edf0;
              text-align: right;
              padding-right: 1rem;
            }
            :nth-child(2) {
              color: #04475c;
              text-align: left;
            }
          }
        }
      }
      @media (max-width: ${largeBreakPoint}px) and (min-width: ${midBreakPoint}px) {
        width: 100%;
      }
      @media (max-width: ${midBreakPoint}px) {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  .footer {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #04475c;
    font-size: 1.1rem;
    line-height: 1.8rem;
    padding-bottom: ${(props) => (props.isMobile ? '10rem' : '4rem')};
    border-left: 1px solid #e7edf3;
    border-right: 1px solid #e7edf3;
    @media (max-width: ${extraLargeBreakPoint}px) {
      align-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @media (max-width: ${extraSmallBreakPoint}px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    .splitContent {
      padding-top: 4rem;
      width: 70%;
      @media (max-width: ${extraLargeBreakPoint}px) {
        width: 100%;
      }
      &__left {
        display: flex;
        flex-direction: row;
        justify-content: center;
        img {
          width: 3.2rem;
          height: 3.2rem;
          margin-right: 1.5rem;
        }
        p {
          width: 70%;
        }
      }
      &__right {
        align-items: center;
      }
    }
    &__list {
      width: ${(props) => (props.isMobile ? '90%' : '70%')};
    }
    &__paragraph {
      display: flex;
      flex-direction: row;
      img {
        margin-right: 1rem;
      }
      p {
        flex: 1;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
    }
    &__contactInfo {
      margin-top: 2rem;
      color: #04475c;
      text-align: center;
      width: 60%;
      @media (max-width: ${extraLargeBreakPoint}px) {
        width: 80%;
      }
      @media (max-width: ${extraSmallBreakPoint}px) {
        width: 100%;
      }
      &__helpMail {
        color: #0076ff;
        :visited {
          color: #0076ff;
        }
      }
      &__whatsapp {
        color: #00c574;
        font-weight: 500;
      }
    }
  }
  .tooltip {
    width: 13px;
    height: 13px;
    &.services {
      margin-left: 0.4rem;
    }
    &.sgmm {
      margin-left: 0.3rem;
    }
  }
  .contentRow {
    width: 31rem;
    text-align: center;
  }
`;
