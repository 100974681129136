import styled from 'styled-components';
import colors from 'styles/colors';
import { breakPoints } from 'config/constants';
import Box from 'components/Box';
import { ReactComponent as SecondFactorImageComponent } from 'assets/security/second-factor.svg';

export const BoxContent = styled(Box)`
  padding: 2rem 4rem;
  display: grid;
  column-gap: 1rem;
  max-width: 100%;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    column-gap: 0;
    row-gap: 1.5rem;
    justify-items: center;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  line-height: 2.6rem;
  color: ${colors.textDefault};
  grid-area: 1 / 1;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    text-align: center;
  }
`;

export const Body = styled.p`
  font-size: 1.4rem;
  color: ${colors.textDefault};
  line-height: 2rem;
  grid-area: 2 / 1;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    text-align: center;
  }
`;

export const HelpCenterLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-area: 3 / 1;
  gap: 2rem;
  margin-top: 1rem;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    flex-direction: column;
  }
`;

export const HelpCenterLink = styled.a`
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: ${colors.blue};
  text-decoration: none;
  font-weight: 500;
  text-align: center;
`;

export const SecondFactorImage = styled(SecondFactorImageComponent)`
  align-self: center;
  grid-area: 1 / 2 / span 2 / 2;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    grid-area: 5 / 1 / auto / auto;
  }
`;
