import get from 'lodash.get';
import { productTypes } from '../config/constants';

export const getProductType = (data) => {
  const product = get(data, 'collectionProduct.productType');
  if (get(data, 'lquita')) return productTypes.lquita;
  if (get(data, 'restructuring')) return productTypes.restructuring;
  if (get(data, 'zapopan')) return productTypes.zapopan;
  return productTypes[product];
};
