import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from 'ui';
import { PauseAutoinvestForm } from 'views/DashboardContent/Settings/MainArea/AutoInvest/components';

export const ToggleAutoInvestModal = (props) => {
  const { isOpen, onClose } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <PauseAutoinvestForm
          onCancel={onClose}
          onSuccess={onClose}
        />
      </ModalContent>
    </Modal>
  );
};

ToggleAutoInvestModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
