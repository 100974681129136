import React from 'react';
import {
  Grid,
} from 'ui';

export const MyAwesomeComponent = () => (
  <Grid>
    {/**
       * Jot your script down
       * <HappyCoding />
       *
       *
       *
       *
       */}

  </Grid>
);
