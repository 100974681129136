import { gql } from '@apollo/client';
import { useMutation, useQuery } from 'hooks/apollo';

export const USER_GQL = gql`
  query {
    user {
      id
      cellPhoneCode
      cellPhoneNumber
    }
  }
`;

export const useUser = (options) => useQuery(USER_GQL, options);

export const EDIT_PHONE_GQL = gql`
  mutation ($input: CellPhoneInput!) {
    updateCellPhone(input: $input) {
      user {
        id
        cellPhoneCode
        cellPhoneNumber
      }
    }
  }
`;

export const useEditPhone = (options) => useMutation(EDIT_PHONE_GQL, options);
