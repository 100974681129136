export const getAutoinvestContract = ({ fullname, signedAt }) => `
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Autoinvest contrato de activación</title>
  <style>
    @font-face {
      font-family: 'proxima-nova';
      src: url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Light.woff') format('woff');
      font-weight: 100;
      font-style: normal;
    }

    @font-face {
      font-family: 'proxima-nova';
      src: url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-LightIt.woff') format('woff');
      font-weight: 300;
      font-style: normal;
    }

    @font-face {
      font-family: 'proxima-nova';
      src: url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
    }

    @font-face {
      font-family: 'proxima-nova';
      src: url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Semibold.woff') format('woff');
      font-weight: 500;
      font-style: normal;
    }

    @font-face {
      font-family: 'proxima-nova';
      src: url('https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
    }
    html {
      font-size: 16px;
      font-family: proxima-nova, Arial, sans-serif;
      letter-spacing: 0.2pt;
      color: #4F6F7B;
    }
      b {
        color: #04475C;
        font-weight: 500;
      }
      h1 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #04475C;
      }
  </style>
</head>
<body>
  <div>
    <div id="contract">
      <h1>Autorización para realizar Inversiones Automáticas “Autoinvest”</h1>
      <p>
        Yo
        <b>${fullname}</b>
        manifiesto expresamente mi
        consentimiento para que en mi nombre y representación,
        <b>
          Comunidad de Préstamos, S.A.P.I. de C.V. Institución de Financiamiento Colectivo
        </b>
        (en lo sucesivo
        <b>“Yotepresto.com”</b>
        ) realice las
        inversiones automáticas de recursos en términos del artículo 54 de las Disposiciones de
        Carácter General Aplicables a las Instituciones de Tecnología Financiera publicadas en el
        Diario Oficial de la Federación el 10 de septiembre de 2018 modificadas mediante
        resolución publicada en el citado Diario el 25 de marzo de 2019.
      </p>
      <p>Asimismo, manifiesto que se me informó previamente:</p>
      <div>
        <ol>
          <li>La forma y términos en que se llevarían a cabo las inversiones;</li>
          <li>
            Los criterios a utilizar para seleccionar a los Solicitantes sobre los que se
            invertirán los recursos;
          </li>
          <li>
            El nivel de riesgo al cual estarán expuestas las inversiones según su metodología de
            riesgos; y
          </li>
          <li>El monto o porcentaje de recursos que podrán ser invertidos.</li>
        </ol>
      </div>
      <p>
        Por lo anterior manifiesto expresamente que estoy consciente de los riesgos propios
        derivados de ser inversionista en una institución de financiamiento colectivo y de las
        inversiones automáticas de recursos.
      </p>
      <p>
        <b>Nombre completo: </b>
        ${fullname}
      </p>
      <p>
        <b>Fecha de aceptación: </b>
        ${signedAt}
      </p>
      <p>
        <b>Firma electrónica:</b>
        Al hacer clic en el botón de confirmación, reconozco que estoy
        firmando este formulario electrónicamente, produciendo los mismos efectos que las leyes
        otorgan a la firma autógrafa.
      </p>
    </div>
  </div>
</body>
</html>
`;
