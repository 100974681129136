import { showToast } from 'utils/toastHandler';
import camelify from 'camelcase-keys-recursive';
import snakeify from 'snakecase-keys';
import { request } from './fetchers/yotepresto';

export const postFormData = async (values, referencedCode) => {
  const profile = {
    referenced_code: referencedCode || '',
    ...values,
  };
  const method = 'post';
  const path = '/v1/profile';
  const body = { profile };
  const response = await request({
    method, path, body, log: true,
  });
  return response.status;
};

export const fetchPersonalIdentifications = async (userData) => {
  const method = 'post';
  const path = '/v1/personal_identifications';
  try {
    const response = await request({
      method, path, body: snakeify(userData), log: true,
    });
    return response.data;
  } catch (e) {
    showToast('error', 'Ocurrió un problema al generar tus datos. Agrégalos manualmente');
    return false;
  }
};

export const deleteReferenceCode = async () => {
  const method = 'delete';
  const path = '/v1/referrals';
  const { data } = await request({ method, path });

  return camelify(data.referral);
};

export const getReferenceCode = async () => {
  const method = 'get';
  const path = '/v1/referrals/find';
  const { data } = await request({ method, path });

  return camelify(data.referral);
};

export const saveReferenceCode = async ({ referenceCode }) => {
  const method = 'post';
  const path = '/v1/referrals';

  const { data } = await request({ method, path, body: snakeify({ referenceCode }) });
  return camelify(data.referral);
};

export const updateReferenceCode = async ({ referenceCode }) => {
  const method = 'put';
  const path = '/v1/referrals';

  const { data } = await request({ method, path, body: snakeify({ referenceCode }) });
  return camelify(data.referral);
};
