import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { midBreakPoint } = breakPoints;

export const LevelsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto 1fr auto;
  gap: 2rem 2.8rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  padding-right: 8rem;
  padding-left: 8rem;
  max-width: 1000px;
  @media (max-width: ${midBreakPoint}px) {
    grid-template-columns: none;
    grid-template-rows: none;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .title {
    grid-column: 1 / -1;
    h2 {
      color: #04475c;
      font-size: 3.6rem;
    }
    p {
      color: #677d85;
      font-size: 1.4rem;
    }
  }
  .notes {
    grid-column: 1 / -1;
    color: #677d85;
    font-size: 1.2rem;
    margin-top: 3rem;
    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .loader {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
