import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Text } from 'components/Typography';
import { Flex } from 'styles';
import { t } from 'i18n';
import { dashboardRoute } from 'routes';
import { USER_STATUSES } from 'config/constants';
import { logOutUser } from 'actions/auth/fetcher';
import { useReduxQuery } from 'utils/redux-query';
import {
  Container,
  Content,
  Header,
  Box,
  Image,
  PhoneIcon,
  Br,
  CloseSessionLink,
} from './WaitingForCommittee.style';

function WaitingForCommittee() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [session] = useReduxQuery('SESSION');
  const { user } = session;
  const { status } = user;

  useEffect(() => {
    const notPendingOnUbCuboReview = ![
      USER_STATUSES.PLD_VALIDATING,
      USER_STATUSES.PLD_REVIEWING,
    ].includes(status);

    if (notPendingOnUbCuboReview) {
      history.push(dashboardRoute);
    }
  }, [status]);

  return (
    <Container>
      <Header>
        <CloseSessionLink onClick={() => dispatch(logOutUser())}>
          {t('common.closeSession')}
        </CloseSessionLink>
      </Header>
      <Content>
        <Text as="h1" lineHeight="3.5rem" size="3rem" mb="6rem" color="#04475C" align="center">
          {t('common.processingInformation')}
        </Text>
        <Box>
          <Image title={t('common.processingInformation')} />
          <Text color="#677D85" align={{ base: 'center', md: 'left' }}>
            {t('common.reviewingInformation')}
          </Text>
        </Box>
        <Flex mt="4rem" centered>
          <PhoneIcon title={t('common.yoteprestoTel')} />
          <Text color="#134052">
            {t('common.contact1')}
            {' '}
            <Text as="strong">
              {' '}
              {t('common.yoteprestoTel')}
            </Text>
            {' '}
            {t('common.contact2')}
            {' '}
            <Br isHiddenOnSmall />
            {' '}
            {t('common.contact3')}
            {' '}
            <Text as="strong">{t('common.pldEmail')}</Text>
          </Text>
        </Flex>
      </Content>
    </Container>
  );
}

export default WaitingForCommittee;
