import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import { t, tscope } from 'i18n';
import { userSettingsSecurityChangePhone } from 'routes';
import sendToast from 'utils/toastHandler';
import { maskPhoneNumber } from 'utils/maskHandler';
import Button from 'components/Button/Button';
import DotsLoader from 'components/DotsLoader';
import ButtonCountdown from 'components/ButtonCountdown';
import { factorTypes } from 'config/constants';
import { useReduxLazyQuery, useReduxQuery } from 'utils/redux-query';
import { Flex } from 'styles';
import { useGenerateOTP } from 'hooks';
import OTPWidget from './OTPWidget';
import SecondFactorContainer from './styles';

const ts = (id, options) => t(id, { scope: 'Dashboard.Cart.OTPSection', ...options });

export const otpFieldT = tscope('components.AuthorizeWithOTPField');

const MEX_PHONE_PREFIX = '+52';
const CODE_EXPIRATION_TIME = 120000;

const SecondFactor = ({
  errors,
  disabled,
  actionToAuthorize,
  buttonText,
  buttonColor,
  applyMobileStyles,
  children,
  shrinkInputs,
  continueButtonType = 'submit',
  isForm = true,
  smsProps = {},
  ...contentProps
}) => {
  const [profile, , { lazyQuery: lazyQueryProfile }] = useReduxLazyQuery('PROFILE');
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');
  const currentFactorType = secondFactor?.otpType;
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    time,
    sendToSMS,
    remainingSeconds,
  } = useGenerateOTP();

  const handleSendOTPSMS = async () => {
    try {
      if (!errors || Object.keys(errors)?.length) return;
      setLoading(true);
      await sendToSMS();
      setShowOTPForm(!showOTPForm);
    } finally {
      setLoading(false);
    }
  };

  const closeShowOtpForm = (expired) => {
    setOtpExpired(expired);
    setShowOTPForm(false);
  };

  const handleIncorrectCode = () => {
    sendToast('error', t('common.errors.invalidOtp'));
    setShowOTPForm(false);
  };

  const handleOTPFormSubmit = async (otp) => {
    try {
      await actionToAuthorize(otp);
    } finally {
      setLoading(false);
    }
  };

  const maskedPhoneNumber = profile
    ? `${MEX_PHONE_PREFIX} ${maskPhoneNumber(profile.cellPhoneNumber)}`
    : '...';
  const message = ts('factorTypeSMSMessage', { maskedPhoneNumber });

  const expiredMessage = (
    <>
      <span className="error__message">
        El tiempo del código enviado al
        {' '}
        {maskedPhoneNumber}
        {' '}
        caducó.
      </span>
      Genera uno nuevo.
    </>
  );

  const getButtonContent = () => {
    if (loading) return <DotsLoader />;
    if (time > 0) return otpFieldT('sendBySMSCounter', { count: remainingSeconds });
    return otpExpired ? 'Generar' : 'Continuar';
  };

  const renderDescription = () => (
    <Flex className="description__container" {...smsProps}>
      <div className="description__container__content">
        {otpExpired ? expiredMessage : message}
        {' '}
        &nbsp;
        <Link id="changePhoneLink" to={userSettingsSecurityChangePhone}>
          Cambiar número
        </Link>
      </div>
      <div className="description__container__action__button">
        <div className="button__container">
          {children}
          {time <= 0 ? (
            <Button
              type={continueButtonType}
              disabled={disabled || loading || time > 0}
              onClick={handleSendOTPSMS}
            >
              {getButtonContent()}
            </Button>
          ) : (
            <ButtonCountdown remainingTime={remainingSeconds} />
          )}
        </div>
      </div>
    </Flex>
  );

  const renderContent = () => {
    if (!currentFactorType) {
      return (
        <div className="loader">
          <DotsLoader black />
        </div>
      );
    }
    if (showOTPForm || currentFactorType !== factorTypes.SMS) {
      return (
        <OTPWidget
          isForm={isForm}
          errors={errors}
          disabled={disabled}
          buttonText={buttonText}
          handleFormSubmit={(otp) => handleOTPFormSubmit(otp)}
          closeOtpForm={closeShowOtpForm}
          handleIncorrectCode={handleIncorrectCode}
          color={buttonColor}
          expirationTime={CODE_EXPIRATION_TIME}
          shrinkInputs={shrinkInputs}
        />
      );
    }
    return renderDescription();
  };

  useEffect(() => {
    if (currentFactorType === factorTypes.SMS) {
      lazyQueryProfile();
    }
  }, [currentFactorType]);

  return (
    <SecondFactorContainer
      className="secondFactor"
      applyMobileStyles={applyMobileStyles}
      {...contentProps}
    >
      {renderContent()}
    </SecondFactorContainer>
  );
};

const mapStateToProps = ({ auth }) => ({
  otpRemainingTime: get(auth, 'otpRemainingTime'),
  otpCountdownStart: get(auth, 'otpCountdownStart'),
});

export default connect(mapStateToProps)(SecondFactor);
