import JSCookie from 'js-cookie';
import get from 'lodash.get';
import { getAppDomain } from 'config';

const DEFAULT_DOMAIN = getAppDomain();

const Cookies = JSCookie.withConverter({
  read: (value) => decodeURIComponent(value),
  write: (value) => encodeURIComponent(value),
});

export const setCookie = (name, value, options) => {
  const domain = get(options, 'domain', DEFAULT_DOMAIN);
  Cookies.set(name, value, { ...options, domain });
};

export const getCookie = (name) => Cookies.get(name);

export const removeCookie = (name, options) => {
  const domain = get(options, 'domain', DEFAULT_DOMAIN);
  Cookies.remove(name, { ...options, domain });
};
