import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import colors from 'styles/colors';
import { Text } from 'components/Typography';

export const TooltipContent = styled(ReactTooltip)`
  box-sizing: border-box !important;
  border-radius: 0.5rem !important;
  border: 0.05rem solid ${colors.gray.g12} !important;
  border-bottom-color: 0.5rem solid ${colors.gray.g12} !important;
`;

export const TooltipBody = styled(Text)`
  text-align: center;
`;
