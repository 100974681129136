import React from 'react';
import styled from 'styled-components';
import {
  colors,
  boxHelpers,
  breakPoints,
} from 'styles';
import Modal, { CloseButton } from 'components/Modal';
import { ReactComponent as CloseModalIcon } from 'assets/icons/closeModal.svg';
import { ReactComponent as WarnIcon } from 'assets/icons/info-v2.svg';
import { Heading, Text } from 'components/Typography';
import { Button } from 'ui';

export const ModalContent = styled(Modal)`
  padding: 3rem 2rem !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 2rem;
  max-width: 420px !important;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 2rem !important;
  }

  ${boxHelpers}
`;

const buildVariant = {
  normal: {
    warn: ({ colorSchema = colors.schema.yellow }) => ({
      Icon: WarnIcon,
      iconStyle: {
        color: colorSchema[500],
      },
    }),
  },
};

const AlertModal = (props) => {
  const {
    isOpen,
    onClose,
    colorSchema,
    variant = 'normal',
    status = 'info',
    title,
    body,
    acceptText = 'Aceptar',
    buttonProps = {},
  } = props;

  const statusConfig = buildVariant[variant][status]({ colorSchema, status });

  return (
    <ModalContent isOpen={isOpen} onRequestClose={onClose} {...props}>
      <CloseButton onClick={onClose}>
        <CloseModalIcon />
      </CloseButton>
      <Text as={statusConfig.Icon} w="3.2rem" h="3.2rem" {...statusConfig.iconStyle} />
      <Heading textAlign="center">{title}</Heading>
      <Text textAlign="center">{body}</Text>
      <Button {...buttonProps} size="md">{acceptText}</Button>
    </ModalContent>
  );
};

export default AlertModal;
