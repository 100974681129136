import React from 'react';
import { ReactComponent as EmptyStateGroupSvg } from 'assets/calendarEmptyState.svg';
import EmptyStateContainer from './styles';

const EmptyState = (mobile) => (
  <EmptyStateContainer mobile={mobile}>
    <EmptyStateGroupSvg title="" />
    <p className="empty__state__description__title">No hay pagos programados para hoy</p>
    <p className="empty__state__description__description">
      Comienza a prestar para poder ver pagos en tu calendario
    </p>
  </EmptyStateContainer>
);

export default EmptyState;
