import { useReduxQuery } from 'utils/redux-query';

export const useController = () => {
  const [session, { status: sessionStatus }] = useReduxQuery('SESSION');
  const [profile, { status: profileStatus }] = useReduxQuery('PROFILE');

  const isLoadingComplete = sessionStatus.completed && profileStatus.completed;
  const { invoice } = profile || {};

  const needsUpdateFiscalInformation = session?.settings?.needsUpdateFiscalInformation;
  const hasInvoicingError = session?.settings?.hasInvoicingError;

  const activeFlags = hasInvoicingError && needsUpdateFiscalInformation;
  const onlyUpdateFlag = !hasInvoicingError && needsUpdateFiscalInformation;

  const hasActiveInvoicingError = !invoice && activeFlags;

  const displayInvoicingErrorAlert = isLoadingComplete && hasActiveInvoicingError;
  const displayUpdateFiscalAlert = invoice && onlyUpdateFlag;

  return {
    displayUpdateFiscalAlert,
    displayInvoicingErrorAlert,
  };
};
