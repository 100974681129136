import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';

const actionBarContainerDiv = ({ sideBarHidden, ...otherProps }) => (
  <animated.div {...otherProps} />
);

const MobileActionBarContainer = styled(actionBarContainerDiv)`
  position: fixed;
  width: ${(props) => (props.sideBarHidden ? '100%' : 'calc(100% - 20rem)')};
  height: 35%;
  bottom: 0;
  & .action-bar {
    &__body {
      display: flex;
      font-size: 1.2rem;
      color: #04475C;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 2rem 2rem 0 0;
      box-shadow: 0 0 30px 0 rgba(37, 103, 141, 0.34);
      background-color: white;
      line-height: .6;
      & .chevron {
        width: 100%;
        border: none;
        outline: none;
        text-align: center;
        font-size: 5rem;
        color: #a8c9d6;
        background-color: transparent;
        &--icon {
          transform: translateY(-1rem);
          &:after {
            content: "\\2304";
          }
        }

        }
        & .option__group {
          margin-top: 2rem;
          display: flex;
          & button {
            display: flex;
            align-items: center;
            margin-right: 2rem;
            font-size: 1.5rem;
            color: #0076FF;
            border-color: #0076FF;
            & svg {
              margin-right: 1rem;
            }
          }
        }
        & .action-bar--input {
          margin-top: 2rem;
          width: 100%;
          align-items: center;
          display: flex;
          & .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 1rem;
            line-height: normal;
            & p {
              text-align: center;
            }
          }
          & .input__group {
            display: flex;
            flex: 1;
            height: 4.6rem;
            margin-right: 2rem;
            align-items: center;
            margin-left: 1.5rem;
            font-size: 1.5rem;
            border: 1px solid #DCE3EB;
            background-color: #F8FAFC;
            transition: all .4s ease;
            &:focus-within {
              border-color: #0076FF;
            }
            & span {
              margin-left: 1.5rem;
            }
            & input {
              margin-left: 1.5rem;
              width: 100%;
              height: 100%;
              background-color: transparent;
              border: none;
              outline: none;
            }
          }
          & button {
            margin-right: 1rem;
            & svg {
              & g {
                & g {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MobileActionBarContainer;
