import styled, { css } from 'styled-components';

const colors = {
  blue: ['#0076FF', '#005ecc', 'white'],
  red: ['#FF214E', '#ed0030', 'white'],
  green: ['#00c574', '#00df83', 'white'],
  grey: ['transparent', '#F2F5F7', 'white'],
  link: ['transparent', '#36ACFF', '#36ACFF'],
};

const getButtonColor = (color) => {
  if (color === 'success') return colors.green;
  if (color === 'error') return colors.red;
  if (color === 'cancel' || color === 'info') return colors.grey;
  if (color === 'link') return colors.link;
  return colors.blue;
};

const ButtonContainer = styled.button`
  cursor: pointer;
  background-color: ${(props) => getButtonColor(props.color)[0]};
  outline: none;
  border: none;
  border-radius: 10px;
  color: ${(props) => getButtonColor(props.color)[2]};
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.2rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => getButtonColor(props.color)[1]};
    color: ${(props) => (props.color === 'info' || props.color === 'cancel' ? '#93afb9' : 'white')};
    transform: translateY(-2px);
    box-shadow: -1px 13px 27px -16px rgba(1, 46, 71, 0.75);
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    cursor: not-allowed;
  }
  ${(props) => (props.color === 'info' || props.color === 'cancel')
    && css`
      border: 1px #93afb9 solid;
      color: #93afb9;
    `}
  &.checkboxButton {
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    background-color: #e7ebef;
    border: none;
    color: #4f6f7b;
    font-weight: 500;
    &:hover {
      font-weight: 600;
      background-color: #e7ebef;
      color: #346071;
      box-shadow: 0 12px 9px -6px rgba(1, 46, 71, 0.15);
    }
    #Ir-a-prestar {
      * {
        fill: #346071;
      }
    }
  }
  &.qualificationButton {
    padding: 0.5rem 3rem;
  }
  &.selected {
    background-color: white;
    color: #0076ff;
    font-weight: 600;
    box-shadow: 0px 6px 10px 0px rgba(1, 46, 71, 0.08);
    &:hover {
      color: #0076ff;
      font-weight: 600;
      background-color: white;
    }
    #Ir-a-prestar {
      * {
        fill: #0076ff;
      }
    }
  }

  &[disabled=''] {
    background-color: #e7ebef !important;
    color: #93afb9;
    cursor: not-allowed;
    &:hover {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      box-shadow: none;
    }
  }
`;

export default ButtonContainer;
