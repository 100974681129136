import { useForm } from 'react-hook-form';
import { t, tscope } from 'i18n';
import * as Strings from 'utils/strings';
import {
  showToastError,
  showSuccessAlert,
} from 'utils/toastHandler';
import { resolver } from '../resolver';
import { useWithdrawMutation } from './graphql';

const ts = tscope('components.WithdrawalForm');

const defaultValues = {
  otp: '',
  amount: '',
  account: '',
};

export const useWithdrawalForm = ({
  onSuccess,
  bankAccounts,
  accountStatements,
}) => {
  const {
    watch,
    reset,
    control,
    setError,
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver,
    context: {
      bankAccounts,
      accountStatements,
    },
  });

  const formValues = watch();
  const { errors } = resolver(
    formValues || defaultValues,
    {
      bankAccounts,
      accountStatements,
    },
  );

  const [withdrawFunds, { loading: isLoading }] = useWithdrawMutation({
    onErrorCode: {
      INVALID_OTP_ERROR: () => {
        setError('otp', { message: t('Forms.fields.errors.invalidOTP_Token') });
      },
      INSUFFICIENT_FUNDS_ERROR: () => {
        setError('amount', { message: ts('fields.errors.insufficientFunds') });
      },
      UNPROCESSABLE_REQUEST_ERROR: (queryError) => {
        showToastError(queryError.message);
      },
    },
    onCompleted: () => {
      showSuccessAlert({ t: ts, key: 'withdraw' });
      reset();
      onSuccess();
    },
  });

  const onSubmit = handleSubmit((values) => {
    const {
      otp,
      amount,
      account,
    } = values;
    const bankAccountClabe = bankAccounts.find(({ id }) => id === account).clabe;
    withdrawFunds({
      variables: {
        input: {
          amount: Strings.toFloatNumber(amount),
          otpCode: otp,
          clabe: bankAccountClabe,
        },
      },
    });
  });

  return {
    errors,
    control,
    onSubmit,
    setError,
    setValue,
    isLoading,
  };
};
