import React from 'react';
import {
  css,
  colors,
  styled,
  boxHelpers,
  shouldForwardProp,
} from 'styles';

const typesProgress = {
  success: css`
    background-color: ${colors.success};
  `,
  danger: css`
    background-color: ${colors.danger};
  `,
  info: css`
    background-color: ${colors.info};
  `,
  warning: css`
    background-color: ${colors.warning};
  `,
};

const sizes = {
  small: '5px',
  normal: '7px',
};

const ProgressBar = styled.div.withConfig({ shouldForwardProp })`
  border-radius: 3.5px;
  background-color: #e7ebef;
  display: flex;
  height: ${({ $size }) => sizes[$size]};

  ${boxHelpers}
`;

const Progress = styled.div`
  border-radius: 3.5px;
  width: ${(props) => props.$progress}%;
  max-width: ${(props) => props.$progress}%;
  height: ${({ $size }) => sizes[$size]};

  ${({ $type }) => typesProgress[$type]}
`;

function SimpleProgressBar(props) {
  const {
    progress, type, size, ...rest
  } = props;

  return (
    <ProgressBar $size={size} {...rest}>
      <Progress $progress={progress} $type={type} $size={size} />
    </ProgressBar>
  );
}

SimpleProgressBar.defaultProps = {
  type: 'success',
  size: 'normal',
};

export default SimpleProgressBar;
