import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import { isRequired } from 'utils/Validators/common';

const validator = (values) => {
  const { password } = values;

  return clearErrorsObject({
    password: isRequired(password),
  });
};

export default validator;
