import camelify from 'camelcase-keys-recursive';
import snakeify from 'snakecase-keys';
import { request } from '../../fetchers/yotepresto';

const requestChange = async (requestChangeParams) => {
  const body = snakeify(requestChangeParams);
  const config = {
    path: '/v2/second_factors/change_request',
    method: 'post',
    body,
  };

  const { data } = await request(config);

  return camelify(data);
};

export default requestChange;
