import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { Heading } from 'components/Typography';
import { Button, ArrowBackIcon } from 'ui';
import ReturnSearchHeaderContainer from './styles';

const ReturnSearchHeader = (props) => {
  const { onBack } = props;
  const [portfolio] = useReduxQuery('PORTFOLIO');
  const { collection } = portfolio;

  return (
    <ReturnSearchHeaderContainer>
      <div className="search__header">
        <Button
          m={4}
          fontSize="lg"
          variant="link"
          onClick={onBack}
          leftIcon={<ArrowBackIcon />}
        >
          Regresar
        </Button>
        {collection.length > 0 ? <Heading>Resultado</Heading> : null}
      </div>
    </ReturnSearchHeaderContainer>
  );
};

export default ReturnSearchHeader;
