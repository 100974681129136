import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useReduxQuery } from 'utils/redux-query';
import { useModalDisclosure } from 'hooks';
import { mapPurposes, mapPurposesApi } from '../../../getLists';
import { resolver } from '../resolver';

export const GRADE_OPTIONS = ['A', 'B', 'C'];

export const TERM_OPTIONS = ['6', '12', '18', '24', '30', '36'];

export const PURPOSE_OPTIONS = ['debts', 'house', 'family', 'business', 'personal', 'education', 'car', 'other'];

export const AMOUNT_OPTIONS = ['200', '500', '700', '1000'];

const createResetAutoinvestForm = ({ reset }) => ({ autoinvestConfig }) => {
  if (!autoinvestConfig) return;
  const {
    amount, rates, purposes, months,
  } = autoinvestConfig;
  const amount2 = Number(amount).toFixed(0);
  const isOtherAmount = !AMOUNT_OPTIONS.includes(amount2);
  const grade = GRADE_OPTIONS
    .map((gradeOption) => (rates.includes(gradeOption) ? gradeOption : null));
  const purpose = mapPurposes(purposes);

  reset({
    grade,
    purpose,
    term: months,
    amount: !isOtherAmount ? amount2 : '',
    otherAmount: isOtherAmount ? amount2 : '',
  });
};

export const useController = () => {
  const history = useHistory();
  const {
    watch,
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver,
    defaultValues: {
      grade: [],
      term: [],
      purpose: [],
      amount: '',
      otherAmount: '',
    },
  });
  const [
    autoInvestmentConfigs,
    {
      isLoading: isFetchingLoading,
      updateOptimistically,
      silentFetch: fetchAutoInvest,
    },
  ] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onClose: onConfirmModalClose,
  } = useModalDisclosure();
  const {
    isOpen: isDisplayAutoinvestConfigModalOpen,
    onOpen: onDisplayAutoinvestConfigModalOpen,
    onClose: onDisplayAutoinvestConfigModalClose,
  } = useModalDisclosure();
  const [
    gradeValue,
    termValue,
    purposeValue,
    amount,
    otherAmount,
  ] = watch(['grade', 'term', 'purpose', 'amount', 'otherAmount']);
  const autoinvestConfig = autoInvestmentConfigs?.[0] || null;
  const isAutoinvestEditing = !!autoinvestConfig?.id;
  const isLoading = isFetchingLoading;

  const onSubmit = handleSubmit(async ({
    term,
    grade,
    purpose,
    amount: _amount,
    otherAmount: _otherAmount,
  }) => {
    await updateOptimistically([{
      id: autoinvestConfig?.id,
      active: autoinvestConfig?.active,
      rates: grade,
      months: term,
      purposes: mapPurposesApi(purpose),
      amount: _amount || _otherAmount.replace(/[$,]/g, ''),
    }]);
    onConfirmModalOpen();
  });

  const isAllSelected = [
    gradeValue.length === GRADE_OPTIONS.length,
    termValue.length === TERM_OPTIONS.length,
    purposeValue.length === PURPOSE_OPTIONS.length,
  ].every(Boolean);
  const isAllSelectedPurposes = purposeValue.length === PURPOSE_OPTIONS.length;
  const isAllSelectedTerm = termValue.length === TERM_OPTIONS.length;

  const onSelectAll = () => {
    if (isAllSelected) {
      setValue('grade', []);
      setValue('term', []);
      setValue('purpose', []);
      return;
    }
    setValue('grade', GRADE_OPTIONS);
    setValue('term', TERM_OPTIONS);
    setValue('purpose', PURPOSE_OPTIONS);
    clearErrors(['grade', 'term', 'purpose']);
  };

  const onSelectAllTerm = () => {
    const value = isAllSelectedTerm ? [] : TERM_OPTIONS;
    setValue('term', value);
  };

  const onSelectAllPurposes = () => {
    const value = isAllSelectedPurposes ? [] : PURPOSE_OPTIONS;
    setValue('purpose', value);
  };

  const onCancelConfirmModal = () => {
    onConfirmModalClose();
    fetchAutoInvest();
  };

  const onSuccessEdition = () => {
    onConfirmModalClose();
    history.push('show');
  };

  useEffect(() => {
    if (amount) setValue('otherAmount', '');
  }, [amount]);

  useEffect(() => {
    if (otherAmount) setValue('amount', '');
  }, [otherAmount]);

  useEffect(() => {
    createResetAutoinvestForm({ reset })({ autoinvestConfig });
  }, [reset, autoinvestConfig]);

  return {
    control,
    onSubmit,
    isLoading,
    onSelectAll,
    isAllSelected,
    onSelectAllTerm,
    onSuccessEdition,
    autoinvestConfig,
    onSelectAllPurposes,
    isAutoinvestEditing,
    isConfirmModalOpen,
    onConfirmModalOpen,
    onConfirmModalClose,
    onCancelConfirmModal,
    isAllSelectedTerm,
    isAllSelectedPurposes,
    isDisplayAutoinvestConfigModalOpen,
    onDisplayAutoinvestConfigModalOpen,
    onDisplayAutoinvestConfigModalClose,
  };
};
