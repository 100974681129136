import styled from 'styled-components';
import { colors } from 'styles';

const DetailWonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  table {
    font-size: 1.4rem;
    border-collapse: collapse;
    margin: 0 auto;
    max-width: 55.8rem;
    padding-bottom: 0;
    thead {
      th {
        padding: 0.8rem;
        font-weight: 500;
        color: ${colors.gray.g6};
        :first-child {
          padding: 0;
          width: 21.4rem;
        }
      }
    }
    tbody {
      tr {
        :nth-child(even) {
          td {
            background: ${colors.gray.g0};
          }
        }
        :nth-child(4) {
          td {
            color: ${colors.red};
            :first-child {
              color: ${colors.gray.g6};
            }
          }
        }
        :nth-child(5) {
          td {
            color: ${colors.green};
            padding: 3rem 0.9rem;
            background-color: #f0fcf6;
            font-weight: 700 !important;
          }
        }
        :nth-child(6) {
          td {
            color: ${colors.red};
            padding: 3rem 0.9rem 0 0.9rem;
            background-color: white;
            :first-child {
              color: ${colors.gray.g6};
            }
          }
        }
        td {
          padding: 0.9rem;
          text-align: center;
          font-weight: 500;
          color: ${colors.gray.g6};
          :first-child {
            text-align: right;
          }
        }
      }
    }
  }
`;

export default DetailWonContainer;
