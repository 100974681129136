import get from 'lodash.get';

const FILTER_DESTINATION_NAMES = {
  Familiar: 'Gastos Familiares',
  Otro: 'Otros',
};

const noParse = (data) => data;

const buildSort = (property, parse) => (a, b) => (
  parse(get(a, property)) > parse(get(b, property)) ? 1 : -1
);

export const filterRequisitionsBy = (options, requisitions) => {
  const {
    showFundedRequisitions,
    showHiddenRequisitions,
    hiddenState,
    qualification,
    term,
    destination: destinationList,
  } = options;

  const qualifications = qualification || [];
  const terms = term || [];
  const parsedDestination = (destinationList || [])
    .map(
      (destination) => (
        FILTER_DESTINATION_NAMES[destination] ? FILTER_DESTINATION_NAMES[destination] : destination
      ),
    );

  return (requisitions || [])
    .filter((requisition) => {
      if (!qualifications.length) return true;
      return qualifications.includes(requisition.qualification.slice(0, 1));
    })
    .filter((requisition) => {
      if (!terms.length) return true;
      return terms.includes(requisition.term.toString());
    })
    .filter((requisition) => {
      if (!parsedDestination.length) return true;
      return parsedDestination.includes(requisition.destination);
    })
    .filter((requisition) => {
      if (showFundedRequisitions) return true;
      return !Number(requisition.funded_amount);
    })
    .filter((requisition) => {
      if (showHiddenRequisitions) return true;
      return !requisition.hidden && !hiddenState?.[requisition.id];
    });
};

const getSortFunction = (type) => {
  if (type === 'missing_amount') return buildSort('loan_detail.missing_amount', parseFloat);
  if (type === 'days_to_end') return buildSort('loan_detail.days_to_end', parseFloat);
  if (type === 'approved_amount') return buildSort('approved_amount', parseFloat);
  if (type === 'bc_score') return buildSort('credit_report.score', parseFloat);
  return buildSort(type, noParse);
};

const sortRequisitions = (options, requisitions) => {
  const { type, order } = options;
  if (!order) return requisitions;

  const sorted = requisitions.sort(getSortFunction(type));
  return order === 'desc' ? sorted.reverse() : sorted;
};

export const applyFilterToRequisitions = (options, requisitions) => {
  const filteredRequisitions = filterRequisitionsBy(options, requisitions);

  return sortRequisitions(options, filteredRequisitions);
};

const testMatch = (tests, input) => tests.some((t) => t.includes(input.toLowerCase()));

export const isSourceActivityAnEmail = (activityText) => testMatch(['e-mail', 'correo electrónico'], activityText);

export const isSourceActivityWhatsapp = (activityText) => testMatch(['whatsapp a titular'], activityText);

export const isSourceActivityAnSMS = (activityText) => testMatch(['sms'], activityText);

export const isSourceActivityAnMobile = (activityText) => testMatch(['celular', 'llamada a celular', 'llamada a referencia 3'], activityText);

export const isSourceActivityAnHomeManagement = (activityText) => testMatch(['gestión domiciliaria'], activityText);
