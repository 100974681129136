import { useCallback, useEffect, useState } from 'react';
import isNil from 'utils/isNil';

const TIMEOUT_DEFAULT_TO = 1000;

export const lowerThan1000WARN = 'useInterval: passing an interval lower than 1sec causes apps to run slowly';

export const useInterval = ({
  onFinish,
  initialTime = 0,
  interval = TIMEOUT_DEFAULT_TO,
} = {}) => {
  const [, storeTimeout] = useState(null);
  const [time, setTime] = useState(initialTime);
  const finished = !isNil(time) && time <= 0;

  const start = useCallback(() => {
    const tick = () => {
      setTime((_time) => _time - interval);
    };

    const localTimeout = setTimeout(tick, interval);
    storeTimeout((prev) => {
      if (prev) clearTimeout(prev);
      return localTimeout;
    });
    return localTimeout;
  }, [interval]);

  const reinitialize = useCallback(() => {
    setTime(initialTime);
    start();
  }, [initialTime, start]);

  useEffect(() => {
    const validTime = time > 0;
    if (!validTime) return () => { };

    const localTimeout = start();
    return () => clearTimeout(localTimeout);
  }, [time, start]);

  useEffect(() => {
    if (finished && onFinish) onFinish();
  }, [finished, onFinish]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (interval < 1000) console.warn(lowerThan1000WARN);
  }, [interval]);

  return {
    time,
    humanize: { seconds: Math.round(time / 1000) },
    finished,
    reinitialize,
    setTime,
  };
};
