import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { midBreakPoint } = breakPoints;

const DescriptionContainer = styled.div`
  margin-bottom: 4rem;
  @media (max-width: ${midBreakPoint}px) {
    margin-bottom: 3rem;
  }
  &.funded {
    margin-bottom: 2rem;
  }
  .seeMore {
    color: #0076ff;
    text-decoration: underline;
    :hover {
      cursor: pointer;
    }
    .funded {
      margin-bottom: 2rem;
    }
  }
  .descriptionCard {
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 2.3rem 3rem;
    text-align: left;
    color: #04475c;
    & p {
      font-weight: 500;
    }
    @media (max-width: ${midBreakPoint}px) {
      width: 100%;
    }
  }
`;

export default DescriptionContainer;
