import { STATUS } from '../config';

function parseMeta(meta) {
  return {
    status: {
      initial: meta.status === STATUS.INITIAL,
      pending: meta.status === STATUS.PENDING,
      completed: meta.status === STATUS.COMPLETED,
      failed: meta.status === STATUS.FAILED,
    },
    isLoading: meta.status === STATUS.PENDING,
    isCompleted: meta.status === STATUS.COMPLETED,
    error: meta.error,
    countCalls: meta.countCalls,
  };
}

export default parseMeta;
