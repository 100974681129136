export const styles = {
  modal: {
    size: 'sm',
    isCentered: true,
  },
  modalContent: {
    sx: {
      '@media print': {
        boxShadow: 'xs',
      },
    },
  },
  modalCloseButton: {
    sx: {
      '@media print': {
        display: 'none',
      },
    },
  },
  modalBody: {
    paddingY: '5',
  },
  bodyContent: {
    columnGap: '3',
    templateColumns: '1fr',
  },
  modalFooter: {
    rowGap: 3,
    flexDirection: 'column',
  },
  bodyDivider: {
    gridColumn: '1 / 3',
    marginY: '4',
  },
  label: {
    color: 'gray.700',
  },
  dateHour: {
    fontSize: 'sm',
    color: 'gray.400',
  },
  fullRow: {
    gridColumn: '1 / 3',
  },
  printButton: {
    sx: {
      '@media print': {
        display: 'none',
      },
    },
    marginTop: 5,
    size: 'sm',
    variant: 'outline',
    colorScheme: 'blue',
    justifySelf: 'center',
  },
  tokenText: {
    fontSize: 'sm',
    color: 'gray.400',
  },
  companyText: {
    fontSize: 'sm',
    color: 'gray.400',
    textAlign: 'center',
  },
};
