import {
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { getApiUrl } from 'config';

const privateSchema = createHttpLink({
  uri: `${getApiUrl()}/graphql`,
});

const publicSchema = createHttpLink({
  uri: `${getApiUrl()}/public/graphql`,
});

export const directionalLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'public',
  publicSchema,
  privateSchema,
);
