import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';
import LimitsBannerComponent from '../Banners/Limits';

const ContainerDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;
const sharedStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LimitsBanner = styled(LimitsBannerComponent)``;

const NoLoanContainer = styled(ContainerDiv)`
  width: 100%;
  & .last__sign__at {
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;
      @media (max-width: ${breakPoints.midBreakPoint}px) {
        background:none;
      }
      & span {
        margin: 0 0.5rem;
      }
  }
  & .content__container {
      display: flex;
      flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    }
  font-size: 1.2rem;
  color: ${colors.gray.g5};
  .leftContent
  {
    ${sharedStyles}
    padding: ${(props) => (props.isMobile ? '0' : '0 2rem 3rem 2rem')};
  }
  .rightContent  {
    ${sharedStyles}
    padding: 0 2rem 3rem 2rem;
  }
  .leftContent {
    background-color: white;
    align-content:center;
    .funds {
      padding: 3rem 0;
      max-width:28rem;
    }

    .greenText {
      color: ${colors.green};
      padding: 2rem 0;
    }

    p {
      font-size: 1.6rem;
      color: ${colors.gray.g5};
      padding: 1rem 0;
    }

    .leftButton {
      padding: 1rem 0;
    }

    .imageText {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .rightContent {
    h1 {
      padding: 3rem;
    }
    & .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      padding: 2rem;
      font-size: 1.3rem;
      width: 100%;
      margin-bottom: 1rem;
      box-shadow:${colors.shadow.card};
      text-align: center;
      & h3 {
        color: inherit
        margin-bottom: 1rem;
        font-size:2rem;
      }
      p {
        padding-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 160%;
      }
      & b {
        margin: 0 0.5rem;
      }
      & button {
        margin-bottom: 1rem;
      }
    }
    & .mobile {
      margin-top: 3rem;
      text-align: center;
      p:first-child {
        padding-bottom: 2rem;
      }
    }
    .videoHeader {
      font-size: 1.3rem;
      margin-top: 3rem;
      padding-bottom: 1rem;
      align-self: flex-start;
    }
    .videoHeader_mobile {
      font-size: 1.3rem;
      margin-top: 3rem;
      padding-bottom: 1rem;
      align-self: center;
    }
    .videoContainer {
      margin-bottom: 2rem;
      width: 100%;
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      background-color: white;
      width: 100%;
      margin-top: 1rem;
      font-size: 1.3rem;
      box-shadow: ${colors.shadow.card};
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: inherit;
        width: 100%;
        height: 4rem;
        transition: all .3s ease;
        border-right: 1px solid ${colors.gray.g2};
        text-align: center;
        &:last-of-type {
          border-right: none;
        }
        &:hover {
          cursor: pointer;
          background-color: #f8fafc;
          color: ${colors.blue};
        }
      }
    }
    .rightButton_mobile {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 2rem 0;
    }
  }
`;

export default NoLoanContainer;
