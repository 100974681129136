import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { toCurrencyWithDecimals, calculatePercentage } from 'utils/numberFormatters';
import { ReactComponent as AutoinvestIcon } from 'assets/AutoInvestIcon.svg';
import { Tag, Flex, Text } from 'components';
import Divider from 'components/Divider';
import { ListItem, payedColor } from './styles';

const Item = ({ requisition, IdColumnBasis, ...props }) => {
  const {
    color,
    origin,
    solicitud,
    estado,
    montoPrestado,
    pagadoCapital,
    plazo,
    calificacion,
    financingId,
    tasaAcreditado,
    tasaPrestamista,
    acreditado,
    pagadoIntereses,
    pagadoImpuestos,
    porPagar,
    tags,
  } = requisition;
  const pagado = Number(pagadoCapital) + Number(pagadoIntereses) + Number(pagadoImpuestos);
  const interestRate = String(financingId) === '4' ? tasaAcreditado : tasaPrestamista;

  return (
    <ListItem status="Liquidado" className="portfolio__list__body__item table__row" {...props}>
      <Flex flexBasis="7rem" centered>
        <Text fontSize="1.2rem">{solicitud}</Text>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      {IdColumnBasis && (
        <>
          <Flex
            flexBasis="5rem"
            centered
            className="list__item"
            justifyContent="center !important"
            columnGap="0.2rem"
          >
            {tags?.length ? (
              tags.map((tag) => (
                <Tag key={tag.nomenclature} fontSize={{ base: '1rem' }} tooltip={tag.description}>
                  {tag.nomenclature}
                </Tag>
              ))
            ) : (
              <span />
            )}
          </Flex>
          <Divider
            borderColor="schema.gray.200"
            display={{ base: 'none', xs: 'block' }}
            h="5.2rem"
            position="vertical"
          />
        </>
      )}
      <Flex flexBasis="10rem" centered className="list__item table__cell status" style={{ color }}>
        <div className="cell__content">{estado}</div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="10rem" centered className="list__item table__cell lent">
        <div className="cell__content">
          $
          {montoPrestado}
          {' '}
          {origin && <AutoinvestIcon title="" />}
        </div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="10rem" centered className="list__item table__cell payed">
        <div className="cell__content">
          <span>{toCurrencyWithDecimals(pagado)}</span>
          <span className="progressbar">
            <CircularProgressbar
              value={calculatePercentage(pagado, montoPrestado)}
              strokeWidth={17}
              styles={buildStyles({ pathColor: payedColor })}
            />
          </span>
        </div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="10rem" centered className="list__item table__cell owes">
        <div className="cell__content">
          <span>{toCurrencyWithDecimals(porPagar)}</span>
        </div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="10rem" centered className="list__item table__cell user">
        <div className="cell__content">{acreditado}</div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="10rem" centered className="list__item table__cell qualification">
        <div className="cell__content">
          <span className="qualification__text">{calificacion}</span>
          <span className="qualification__percentage">
            {Number.parseFloat(interestRate).toFixed(2)}
            %
          </span>
        </div>
      </Flex>
      <Divider
        borderColor="schema.gray.200"
        display={{ base: 'none', xs: 'block' }}
        h="5.2rem"
        position="vertical"
      />
      <Flex flexBasis="3rem" centered className="list__item table__cell term">
        <div className="cell__content">{plazo}</div>
      </Flex>
    </ListItem>
  );
};

export default Item;
