import styled from 'styled-components';
import React from 'react';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const { extraLargeBreakPoint, smallBreakPoints, extraSmallBreakPoint } = breakPoints;

export const AuthorizingLendingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  .amountBar {
    display: flex;
    justify-content: space-between;
    background-color: ${colors.greenLendingNow.background};
    border-radius: 1.6rem;
    font-size: 1.8rem;
    padding: 1rem 1.6rem;
    margin-bottom: 3rem;
    p {
      color: ${colors.green};
      width: 100%;
      text-align: left;
      b {
        position: relative;
        float: right;
      }
    }
  }
  .secondFactor {
    .description {
      &__container {
        flex-direction: column;
      }
    }
    .button {
      &__container {
        justify-content: space-around;
        @media (max-width: ${extraSmallBreakPoint}px) {
          button {
            flex: 1;
          }
        }
      }
    }
    .backButton {
      font-size: 1.4rem;
      color: #073f51;
      :hover {
        cursor: pointer;
      }
      @media (max-width: ${extraSmallBreakPoint}px) {
        flex: 1;
      }
    }
    .otp__form {
      flex-direction: column;
      button {
        align-self: flex-end;
        padding: 1.5rem 4rem;
        margin-top: 1rem;
      }
    }
  }
`;

const Container = ({ isMobile, ...props }) => <div {...props} />;
const LendingFormContainerRequisitionDetail = styled(Container)`
  text-align: ${(props) => (props.isMobile ? 'center' : 'inherit')};
  .chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    border: none;
    outline: none;
    text-align: center;
    font-size: 4rem;
    color: #a8c9d6;
    background-color: transparent;
    &--icon {
      &:after {
        content: '\\2304';
        position: absolute;
        font-size: 5rem;
        height: 2rem;
        position: absolute;
        font-size: 5rem;
        line-height: 0rem;
      }
    }
  }
  .loadingAmount {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .available {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #346071;
    margin-top: 10%;
    @media (min-width: ${extraLargeBreakPoint}px) and (min-height: ${extraSmallBreakPoint}px) {
      margin-top: 5%;
    }
    @media (max-width: ${smallBreakPoints}px) {
      margin-top: 0;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span {
        font-size: 2.3rem;
        font-weight: bold;
        padding-left: 0.5rem;
      }
    }
  }
  .borrowForm {
    font-size: 1.2rem;
    margin-top: 5%;
    @media (max-width: ${smallBreakPoints}px) {
      margin-top: 10%;
    }
    form {
      p:first-child,
      & > div {
        margin-bottom: 1.5rem;
      }
      .input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        margin-bottom: 1rem;
        @media (max-width: ${smallBreakPoints}px) {
          margin-top: 10%;
        }
        @media (max-width: ${extraLargeBreakPoint}px) {
          margin-bottom: 0;
        }
        @media (min-width: ${extraLargeBreakPoint}px) and (min-height: ${extraSmallBreakPoint}px) {
          margin-bottom: 0;
        }
        p {
          padding-right: 1rem;
          padding-top: 1rem;
          @media (max-width: ${extraLargeBreakPoint}px) {
            margin-bottom: 0;
          }
        }
        &__icon {
          width: 100%;
          font-size: 1.5rem;
          input {
            width: 100%;
            border: 1px solid #dce3eb;
            border-radius: 4px;
            background-color: #f8fafc;
            height: 44px;
            color: black;
            padding-left: 2.5rem;
            outline: none;
            &:focus {
              border-color: #0076ff;
            }
          }
          i {
            display: block;
            transform: translate(0, -170%);
            pointer-events: none;
            width: 25px;
            text-align: center;
            font-style: normal;
          }
          &.invalidInput {
            input {
              border-color: ${colors.red};
              color: ${colors.red};
            }
            i {
              color: ${colors.red};
            }
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        Button {
          :first-child {
            border-color: transparent;
            border-radius: 4px;
          }
          :last-child {
            border-color: #0076ff;
            color: #0076ff;
          }
        }
      }
    }
  }
  .dotsLoader {
    height: 5rem;
  }
`;

export const WarningLendingToast = styled.div`
  display: flex;
  align-items: center;
  svg {
    min-width: 4rem;
    padding-right: 1rem;
  }
`;

export default LendingFormContainerRequisitionDetail;
