import { useMemo } from 'react';
import { t } from 'i18n';
import { isDevelopment } from 'config';
import { showToastError } from 'utils/toastHandler';
import store from 'store';

const buildDefaultHandlers = ({ handleUnauthenticated }) => ({
  401: handleUnauthenticated,
  generic: () => {
    showToastError(t('common.alerts.error.generic'));
  },
});

const getAllErrors = (data) => {
  const errors = (data?.graphQLErrors || []).map((e) => e);
  const networkError = data?.networkError?.statusCode;
  if (networkError) errors.push({ extensions: { code: networkError } });
  return errors;
};

const errorMatcher = (allErrors, onErrorCode, defaultHandlers) => {
  const handledErrors = allErrors.reduce((previous, currentError) => {
    const handleError = onErrorCode[currentError.extensions.code]
      ?? defaultHandlers[currentError.extensions.code];

    if (!handleError) return previous;

    handleError(currentError);
    return true;
  }, false);
  return handledErrors;
};

export const useErrorHandler = () => {
  const handleUnauthenticated = () => {
    store.dispatch({
      type: 'SET_SESSION_STATUS',
      payload: { reason: 'invalid_credentials_for_api' },
      meta: { status: 'COMPLETED' },
    });
  };

  const defaultHandlers = useMemo(
    () => buildDefaultHandlers({ handleUnauthenticated }),
    [handleUnauthenticated],
  );

  return (options) => {
    const onError = (data, ...args) => {
      const onErrorCode = options?.onErrorCode || {};

      const allErrors = getAllErrors(data);

      const errorsMatched = errorMatcher(allErrors, onErrorCode, defaultHandlers);

      if (isDevelopment()) {
        // eslint-disable-next-line no-console
        console.warn('Error handler', {
          allErrors,
          errorsMatched,
          graphQLErrors: data?.graphQLErrors,
          networkError: data?.networkError,
        });
      }

      const displayGenericError = [
        !errorsMatched,
        !options.onError && data?.graphQLErrors?.error,
      ].some(Boolean);

      if (displayGenericError) defaultHandlers.generic();

      if (options.onError) options.onError(data, ...args);
    };
    return {
      ...options,
      onError,
    };
  };
};
