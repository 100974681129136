import { useCallback, useEffect } from 'react';
import { WS_EVENT_NAMES } from 'config/constants';
import { useReduxSubscription } from 'utils/redux-query';

export const useBindPortfolioBrief = ({
  bindEvent,
}) => {
  const [, { refetch }] = useReduxSubscription('FETCH_ACCOUNT_INVESTMENTS');

  const callback = useCallback(() => {
    refetch();
  }, [
    refetch,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.PORTFOLIO_BRIEF,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
