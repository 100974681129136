export const style = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
  },
  text: {
    padding: '0',
    paddingRight: '2',
  },
};
