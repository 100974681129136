import React from 'react';
import FormLayout from 'views/FormLayout';
import { Grid } from 'styles';
import Mati from 'views/DashboardContent/Mati';
import { updatePersonalIdStatus } from 'actions/identityVerification';
import { useDispatch } from 'react-redux';

function OfficialIdentifications() {
  const dispatch = useDispatch();

  const onUploadIDFinished = () => {
    dispatch(updatePersonalIdStatus());
  };

  return (
    <FormLayout hideImageSection>
      <Grid py="8rem">
        <Mati onUploadIDFinished={onUploadIDFinished} />
      </Grid>
    </FormLayout>
  );
}

export default OfficialIdentifications;
