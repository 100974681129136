import { useReduxQuery } from 'utils/redux-query';
import { USER_STATUSES } from 'config/constants';
import { INVESTOR_STATUS } from 'config/index';
import { getAutoInvestStatus } from '../utils/getAutoInvestStatus';

export const useController = () => {
  const [
    autoInvestmentConfigs,
    { status: fetchAllAIStatus },
  ] = useReduxQuery('FETCH_ALL_AUTO_INV');

  const [session] = useReduxQuery('SESSION');
  const isUserPartiallyBlocked = session?.user?.status === USER_STATUSES.PARTIALLY_BLOCKED;
  const displayAutoInvestBanner = session?.settings?.availableForAutoinvest;
  const status = session?.user?.status;

  const autoInvestStatus = fetchAllAIStatus.completed
    ? getAutoInvestStatus(autoInvestmentConfigs)
    : null;

  const shouldShowWidget = (status === INVESTOR_STATUS.AUTHORIZED) && displayAutoInvestBanner;

  return {
    fetchAllAIStatus,
    autoInvestStatus,
    shouldShowWidget,
    autoInvestmentConfigs,
    isUserPartiallyBlocked,
  };
};
