import React from 'react';
import { t, tscope, toCurrency } from 'i18n';
import { CopyToClipboardText } from 'components/CopyToClipboardText';
import { AutoInvestIcon, ReceiptIcon } from 'components/Icons';
import {
  Tr,
  Th,
  Td,
  Tag,
  Box,
  Card,
  Text,
  Table,
  Thead,
  Tbody,
  Tooltip,
  IconButton,
  TableContainer,
  Flex,
} from 'ui';
import { useController } from './hooks';
import { styles as s } from './TransactionList.style';
import { VoucherModal } from '../VoucherModal';

const ts = tscope('Dashboard.Transactions.transactionTable');

export const TransactionList = (props) => {
  const {
    transactions,
    voucherProps,
    onSetTransactionVoucher,
    transactionVoucher,
  } = useController();

  return (
    <Card {...props}>
      <TableContainer>
        <Table colorScheme="blue" size="md">
          <Thead>
            <Tr>
              <Th {...s.cell}>{ts('headers.id')}</Th>
              <Th {...s.cell}>{ts('headers.date')}</Th>
              <Th {...s.cell}>{ts('headers.type')}</Th>
              <Th {...s.cell} isNumeric>{ts('headers.amount')}</Th>
              <Th {...s.cell} isNumeric>{ts('headers.balance')}</Th>
              <Th {...s.cell} isNumeric>{ts('headers.account')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map((transaction) => (
              <Tr key={transaction.id}>
                <Td {...s.cell}>
                  <Box>
                    <Tag colorScheme="gray">
                      {transaction.id}
                    </Tag>
                    {transaction.reference && (
                    <Text fontSize="sm" fontWeight="normal">
                      {ts('headers.reference', {
                        reference:
                          transaction.reference,
                      })}
                    </Text>
                    )}
                  </Box>
                </Td>
                <Td {...s.cell} data-testid="dateCell">
                  {transaction.date}
                  {' '}
                  <Text as="span" color="gray.500" fontSize="xs">
                    {transaction.hour}
                  </Text>
                </Td>
                <Td
                  {...s.cell}
                  color={transaction.statusColor}
                >
                  {transaction.type}
                </Td>
                <Td
                  {...s.cell}
                  color={transaction.statusColor}
                  isNumeric
                >
                  <Flex alignItems="center" justifyContent="flex-end" columnGap="1">
                    {toCurrency(transaction.amount)}
                    {transaction.origin && <AutoInvestIcon boxSize="6" />}
                  </Flex>
                </Td>
                <Td
                  {...s.cell}
                  isNumeric
                >
                  {toCurrency(transaction.balance)}
                </Td>
                <Td
                  {...s.cell}
                  isNumeric
                >
                  {transaction.account && (
                    <CopyToClipboardText fontSize="sm">
                      {transaction.account}
                    </CopyToClipboardText>
                  )}
                  {!transaction.account && (
                    <Text fontSize="sm">
                      {t('common.na-')}
                    </Text>
                  )}
                </Td>
                <Td>
                  <Tooltip hasArrow label={ts('getVoucher')}>
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="blue"
                      animationType="none"
                      aria-label={ts('getVoucher')}
                      sx={{ ':hover > svg': { fill: 'blue.500' } }}
                      icon={<ReceiptIcon fill="gray.500" boxSize="4" />}
                      onClick={() => onSetTransactionVoucher(transaction)}
                    />
                  </Tooltip>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <VoucherModal
        {...voucherProps}
        transaction={transactionVoucher}
      />
    </Card>
  );
};
