import {
  useEffect,
  useCallback,
} from 'react';
import { WS_EVENT_NAMES } from 'config/constants';
import { useBankAccountsLazyQuery } from '../graphql';

export const useBindBankAccountValidated = ({
  bindEvent,
}) => {
  const { refetch: refetchUser } = useBankAccountsLazyQuery();

  const callback = useCallback(() => {
    refetchUser();
  }, [
    refetchUser,
  ]);

  useEffect(() => bindEvent(
    WS_EVENT_NAMES.BANK_ACCOUNT_VALIDATED,
    callback,
  ), [
    bindEvent,
    callback,
  ]);
};
