import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const InstructionsContainer = styled.div`
  .top-heading {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 600;
    color: #5e717e;
  }

  .listSteps {
    counter-reset: my-badass-counter;
    dt {
      position: relative;
      font-weight: 400;
      margin: 3rem auto;
      padding-left: 5rem;
      color: ${colors.gray.g5};
      font-size: 1.8rem;
      line-height: 160%;
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        font-size: 1.2em;
      }
    }
    dt:before {
      content: counter(my-badass-counter);
      counter-increment: my-badass-counter;
      position: absolute;
      left: 1.6rem;
      border: 2px solid ${colors.green};
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 100%;
      text-align: center;
      top: 0.1rem;
      font-weight: 700;
      line-height: 170%;
      color: ${colors.green};
      font-size: 1.4rem;
      @media (max-width: ${breakPoints.smallBreakPoint}px) {
        font-size: 2.6rem;
        border: 0;
      }
    }
  }

  .table-rages-and-amounts-table-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .amounts-table {
      width: 90%;
      border-collapse: collapse;
      border-spacing: 0;
      margin-bottom: 3rem;

      tbody {
        tr {
          td {
            text-align: center;
            font-size: 1.4rem;
            padding: 0.8rem;
            line-height: 1.428571429;
            vertical-align: top;
            color: #374d5b;
            border-top: 0.1rem solid #ddd;

            &.top-cell {
              border-top: none;
              font-weight: 500;
            }

            &.range {
              font-weight: 400;
              color: #677d85;
            }

            &.benefit {
              color: #0076ff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .conditions-container {
    border-top: 0.1rem solid #ddd;

    .top-heading {
      text-align: center;
      padding: 1.5rem 0;
      font-size: 1.8rem;
      color: #374d5b;
      font-weight: 500;
    }

    .conditions-list {
      padding-left: 2em;

      li {
        padding: 0.4rem;
        color: #677d85;
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }

  @media (max-width: 75rem) {
    .step-list {
      .step {
        .bullet-container {
          width: 20%;

          .bullet {
            width: 55%;
          }
        }

        .text-container {
          width: 80%;
        }
      }
    }
  }
`;

export default InstructionsContainer;
