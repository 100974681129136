import React from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/colors';
import { TooltipContent, TooltipBody } from './Tooltip.style';

function Tooltip(props) {
  const { id, children } = props;

  return (
    <TooltipContent
      place="bottom"
      backgroundColor={colors.white}
      borderColor={colors.gray.g12}
      arrowColor={colors.white}
      border
      id={`tooltip-badge${id}`}
    >
      <TooltipBody $size="small" $type="secondary">
        {children}
      </TooltipBody>
    </TooltipContent>
  );
}

Tooltip.defaultProps = {
  children: null,
};

Tooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

export default Tooltip;
