const getModalStyles = () => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgba(5,27,39,0.8)',
    zIndex: 1001,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 'none',
    maxWidth: '600px',
    width: '90%',
    transform: 'translate(-50%, -50%)',
    marginBottom: '5rem',
    backgroundColor: 'white',
  },
});

export default getModalStyles;
