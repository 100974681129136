import PATHS, {
  userSettingsAccount,
  userSettingsSecurity,
  userSettingsReferral,
  userSettingsAutoinvest,
  investorSettings,
} from 'routes';
import {
  UserIcon,
  LimitsIcon,
  UnlockIcon,
  ReferralIcon,
  AutoInvestIcon,
  AccountStatementIcon,
} from 'components/Icons';

const { investorSettingsLimitsPath } = PATHS;

export const getSidebarOptions = ({ featureFlag }) => {
  const options = [];

  if (featureFlag.Profile) {
    options.push({
      id: 'PROFILE',
      text: 'Cuenta y perfil',
      Icon: UserIcon,
      url: userSettingsAccount,
    });
  }

  if (featureFlag.Security) {
    options.push({
      id: 'SECURITY',
      text: 'Seguridad',
      Icon: UnlockIcon,
      url: userSettingsSecurity,
    });
  }

  if (featureFlag.AccountStatementDownloader) {
    options.push({
      id: 'ACCOUNT_STATEMENT',
      text: 'Estado de cuenta',
      Icon: AccountStatementIcon,
      url: `${investorSettings}/account-statement`,
    });
  }

  if (featureFlag.Referrals) {
    options.push({
      id: 'REFERRALS',
      text: 'Programa de referidos',
      Icon: ReferralIcon,
      url: userSettingsReferral,
    });
  }

  if (featureFlag.Limits) {
    options.push({
      id: 'LIMITS',
      text: 'Limites',
      Icon: LimitsIcon,
      url: investorSettingsLimitsPath(),
    });
  }

  if (featureFlag.AutoInvest) {
    options.push({
      id: 'AUTO_INVEST',
      text: 'Autoinvest',
      Icon: AutoInvestIcon,
      url: userSettingsAutoinvest,
    });
  }

  return options;
};

export default getSidebarOptions;
