import {
  colors,
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';
import inversionista from 'assets/signup/inversionista.jpg';

const Wrapper = styled.div.withConfig({ shouldForwardProp })`
  height: 100vh;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  grid-template-rows: 50px 1fr;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .header {
    background: white;
    display: flex;
    align-items: center;
    &.left {
      justify-content: center;
      .logo {
        width: 156px;
      }
    }

    &.right {
      justify-content: flex-end;
      .links {
        margin-right: 10%;
        a,
        button {
          color: ${colors.gray.g4};
          text-decoration: none;
          font-size: 1.4rem;
          margin-left: 2rem;
        }
        button {
          font-size: 1.4rem;
          text-decoration: none;
          background-color: transparent;
          border: none;
          outline: none;
          transition: all 0.3s ease;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .form-section {
    overflow-y: auto;
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      grid-column: 1 / -1;
    }
    &.full-container {
      grid-column: 1 / span 2;
    }
    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .image-section {
    background-image: url(${inversionista});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (max-width: ${breakPoints.midBreakPoint}px) {
      display: none;
    }
  }

  ${boxHelpers}
`;

export default Wrapper;
