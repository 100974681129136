import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const CodeAndSocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .code-and-social-media {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
      color: #5b727b;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;
      padding-right: 2rem;
    }

    .code-shown {
      padding: 0.7rem 1.8rem;
      font-size: 2rem;
      border: 0.1rem dashed #979797;
      color: #5b727b;
      line-height: 2.8rem;
      font-weight: 600;
      white-space: nowrap;
    }

    .copy-icon-container {
      padding: 0.9rem 1.1rem;
      margin: 1rem;
      background-color: #f2f5f7;
      border-radius: 0.3rem;
      cursor: pointer;
    }

    .facebook-icon-container {
      cursor: pointer;
    }
  }

  .copy-to-clipboard-msg {
    color: red;
  }

  .copy-to-clipboard-msg-mobile-container {
    padding: 1rem 0 2rem 0;
  }

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .code-and-social-media {
      width: 100%;

      &.social-media {
        margin-top: 1rem;
      }

      .code-shown {
        padding: 0.7rem 1rem;
        font-size: 1.2rem;
        white-space: nowrap;
      }
    }
  }
`;

export default CodeAndSocialMediaContainer;
