export const styles = {
  container: {
    gridColumn: { base: '1 / -1', lg: '6 / -1' },
    templateColumns: { md: '1fr 1fr 1fr 1.5fr' },
    gap: '2px',
    borderRadius: '1rem',
  },
  button: {
    as: 'a',
    target: 'blank',
    $type: 'btn-link',
    $size: 'small',
    color: 'gray',
    h: '5.4rem',
    bgColor: 'white',
    px: '1rem',
  },
};
