import { Integrations } from '@sentry/tracing';
import Pusher from 'pusher-js';
import snakecaseKeys from 'snakecase-keys';

const PRODUCTION_ENVS = ['PRODUCTION'];
const DEVELOPMENT = 'DEVELOPMENT';
const STAGING = 'STAGING';
const TESTING = 'TESTING';

export const INVESTOR_STATUS = {
  AUTHORIZED: 'authorized',
};

export const getEnv = () => process.env.REACT_APP_ENV;

export const onMaintenance = () => process.env.REACT_APP_ON_MAINTENANCE;

export const isProductionEnv = () => PRODUCTION_ENVS.includes(getEnv());

export const isDevelopment = () => getEnv() === DEVELOPMENT;

export const isTesting = () => getEnv() === TESTING;

export const isStaging = () => getEnv() === STAGING;

export const getApiUrl = () => process.env.REACT_APP_API_URL;

export const getPublicSiteUrl = () => process.env.REACT_APP_PUBLIC_SITE_URL;

export const getWebAppUrl = () => process.env.REACT_APP_WEB_APP_URL;

export const getBorrowerAppUrl = () => process.env.REACT_APP_BORROWER_APP_URL;

export const getMainLoginUrl = () => `${getWebAppUrl()}/sign-in`;

export const getUpdatePasswordUrl = () => `${getBorrowerAppUrl()}/update_password`;

export const forgotPasswordUrl = () => `${getWebAppUrl()}/password-recovery`;

export const getAppHost = () => window.location.hostname || window.location.host;

export const getAppSubdirectory = () => process.env.PUBLIC_URL || '';

export const getMatiWidgetURL = () => 'https://web-button.metamap.com/button.js';

export const getAppDomain = () => {
  const host = getAppHost();
  const components = host.split('.');
  return components.slice(-2).join('.');
};

export const getPusherConfig = () => ({
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  authEndpoint: `${getApiUrl()}/v2/pusher/auth`,
});

export const getGoogleTagManagerConfig = () => ({
  id: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
});

export const getSnowplowConfig = () => ({
  appId: process.env.REACT_APP_SNOWPLOW_APP_ID,
  endpoint: process.env.REACT_APP_SNOWPLOW_ENDPOINT,
  cookieDomain: `.${getAppDomain()}`,
});

export const getCrashReporterConfig = () => ({
  environment: getEnv(),
  tracesSampleRate: 1.0,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: `investor@${process.env.npm_package_version}`,
});

export const getWSClient = (credentials) => {
  const { key, ...extraOptions } = getPusherConfig();
  const options = {
    ...extraOptions,
    auth: {
      headers: snakecaseKeys(credentials),
    },
  };
  const client = new Pusher(key, options);
  return client;
};
