import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useReduxQuery } from 'utils/redux-query';
import { Flex } from 'components';
import { setMovementsFilter } from 'actions/investor/movements/movements';
import DotsLoader from 'components/DotsLoader';
import { Grid } from 'ui';
import Loading from './Loading';
import EmptyState from './EmptyState';
import Footer from '../FooterDashboard';
import MovementsFilter from './Filter';
import MovementsList from './List';
import Pagination from './Pagination';
import { ALERTS } from '../Alerts/Alerts';
import { TopBar } from './components';

function MyMovements() {
  const dispatch = useDispatch();
  const [movements, { refetch: fetchMovements, status: movementsStatus }] = useReduxQuery('MOVEMENTS');
  const filtersApplied = useSelector((state) => state.MOVEMENTS_FILTER.payload);
  const { payment_details: paymentDetails } = movements || {};
  const isNotLaptop = useSelector(({ browser }) => browser.lessThan.laptop);
  const isMobile = useSelector(({ browser }) => browser.is.mobile);
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const openFilter = () => setIsOpenFilterMenu(true);
  const closeFilter = () => setIsOpenFilterMenu(false);

  const handleApplyFilters = async (filters) => {
    const mergeFilters = { ...filtersApplied, ...filters };
    await dispatch(setMovementsFilter(mergeFilters));
    await dispatch(fetchMovements(mergeFilters));
    setIsOpenFilterMenu(false);
  };

  const handleChangePage = async (page) => {
    const filters = { ...filtersApplied, page };
    await dispatch(setMovementsFilter(filters));
    await dispatch(fetchMovements(filters));
  };

  const onResetHandler = async () => {
    await dispatch(setMovementsFilter({}));
    await dispatch(fetchMovements({}));
    setIsOpenFilterMenu(false);
  };

  const handleRefreshListing = () => {
    dispatch(fetchMovements(filtersApplied));
  };

  if (
    (movementsStatus.pending && movements === null)
    || movements?.operation === 'caching'
    || movements?.ready
  ) return <Loading data-testid="movements-caching" />;

  if (movements === null && movementsStatus.completed) {
    return (
      <Flex direction="column" w="100%">
        {ALERTS.map(({ id, Component }) => <Component key={id} p="2rem" w="100%" />)}
        <EmptyState />
      </Flex>
    );
  }

  return (
    <Grid
      h="full"
      w="full"
    >
      <MovementsFilter
        isMobile={isNotLaptop}
        onToggleFilter={() => setIsOpenFilterMenu((isOpen) => !isOpen)}
        onCloseFilter={closeFilter}
        onToggleF={isOpenFilterMenu}
        handleFilter={(filter) => handleApplyFilters({
          ...filter,
          page: 1,
        })}
        onResetClick={onResetHandler}
        paymentDetails={paymentDetails}
        filtersApplied={filtersApplied}
        transactions={movements}
        disabled={movementsStatus.pending}
      />
      {ALERTS.map(({ id, Component }) => (
        <Component
          key={id}
          my="2rem"
          mr="2rem"
          ml={{ base: '2rem', lg: '32rem', xl: '32rem' }}
        />
      ))}
      <Flex
        direction="column"
        justifyContent="flex-start"
        m={{ base: '2rem 0 0', sm: '2rem', lg: '2rem 2rem 2rem 32rem' }}
      >
        <TopBar
          openFilter={openFilter}
          handleRefreshListing={handleRefreshListing}
        />
        {movementsStatus.completed && (
          <MovementsList
            showReferenceMobile={isMobile}
            isMobile={isNotLaptop}
            lastUpdate={movements?.updated_at}
            onToggleFilter={() => setIsOpenFilterMenu(true)}
            onRefresh={handleRefreshListing}
            data-testid="movements-list"
          />
        )}
        {movementsStatus.pending && (
          <Flex centered flexGrow="1" data-testid="movements-loading">
            <DotsLoader black />
          </Flex>
        )}
        {movementsStatus.completed && (
          <Pagination
            isMobile={isNotLaptop}
            totalRows={movements?.total_rows}
            currentPage={filtersApplied.page}
            handlePageChange={handleChangePage}
          />
        )}
      </Flex>
      <Footer
        flexGrow="0"
        m={{ base: 'auto 0 0 0', sm: 'auto 0 0 0', lg: 'auto 2rem 2rem 32rem' }}
      />
    </Grid>
  );
}

export default MyMovements;
