import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { ReactComponent as UserBlockedSvg } from 'assets/identityVerification/user-blocked.svg';
import { ReactComponent as Ytplogo } from 'assets/ytplogo2.svg';

export const Container = styled.div`
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  min-height: 8rem;
  background-color: #ffffff;
  padding-right: 2rem;
  padding-left: 2rem;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    min-height: 4rem;
    padding-right: 1rem;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 8rem;
  width: ${breakPoints.largeBreakPoint}px;
`;

export const HeaderLogo = styled(Ytplogo)`
  margin-right: auto;
  max-width: 156px;
`;

export const FAQLink = styled.a`
  font-size: 1.4rem;
  color: #93afb9;
  padding: 0 1rem;
  text-decoration: none;
`;

export const CloseSessionLink = styled.button`
  background-color: transparent;
  border-width: 0;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: '500';
  color: #93afb9;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - 8rem);
  background-color: #f8fafc;
  padding: 5% 2rem;
`;

export const Box = styled.div`
  display: flex;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(38, 57, 69, 0.09);
  padding: 6rem 6rem 6rem 4rem;
  align-items: center;
  max-width: 536px;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
  }
`;

export const Image = styled(UserBlockedSvg)`
  min-width: 137px;
  min-height: 101px;
  margin-right: 3rem;

  @media (max-width: ${breakPoints.midBreakPoint}px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const SeeMoreLink = styled.a`
  text-decoration: none;
  color: #0076ff;
`;
