import React from 'react';
import {
  Switch, Route, Redirect, useRouteMatch,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';
import { Box } from 'styles';
import ProfileShow from '../../Expedients/Profile/Show/Show';
import ProfileUsernameUpdate from '../../Expedients/Profile/Update/Username/Username';
import ProfileCURPUpdate from '../../Expedients/Profile/Update/CURP/CURP';
import ProfileRFCUpdate from '../../Expedients/Profile/Update/RFC/RFC';
import ProfileBirthUpdate from '../../Expedients/Profile/Update/Birth/Birth';
import { Provider } from '../../Expedients/Profile/controller/context';

function BasicInfo(props) {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const handleFinishProcess = () => {
    dispatch(fetchPersonalArchives());
  };

  return (
    <Provider onFinishProcess={handleFinishProcess}>
      <Box maxWidth="35rem" {...props}>
        <Switch>
          <Route path={`${match.path}/show`} component={ProfileShow} />
          <Route path={`${match.path}/username/update`} component={ProfileUsernameUpdate} />
          <Route path={`${match.path}/birth/update`} component={ProfileBirthUpdate} />
          <Route path={`${match.path}/curp/update`} component={ProfileCURPUpdate} />
          <Route path={`${match.path}/rfc/update`} component={ProfileRFCUpdate} />
          <Redirect to={`${match.path}/show`} />
        </Switch>
      </Box>
    </Provider>
  );
}

export default BasicInfo;
