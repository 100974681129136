const bToObj = (b64string) => {
  // Decode the Base64 string to a binary string
  const binaryString = atob(b64string);

  // Convert the binary string to a UTF-8 string
  const utf8String = decodeURIComponent(escape(binaryString));

  // Parse the UTF-8 string as a JavaScript object
  const result = JSON.parse(utf8String);

  return result;
};

export const decodeJWT = (jwt) => {
  if (!jwt) return null;
  const [headerB64, payloadB64] = jwt.split('.');
  const header = bToObj(headerB64);
  const payload = bToObj(payloadB64);

  return {
    header,
    payload,
  };
};
