import sendToast from 'utils/toastHandler';
import { request } from './fetchers/yotepresto';

export const fetchPaymentSchedule = async ({ year, month }) => {
  const handleError = () => sendToast('error', 'Se produjo un error al obtener calendario de pagos');
  const monthPad = month.padStart(2, '0');
  const path = `/v1/investor/payment_schedules/${year}/${monthPad}`;
  const method = 'get';
  const config = { method, path };

  try {
    const response = await request(config);
    if (response.status === 200) return response.data;
    handleError();

    return null;
  } catch (err) {
    handleError();

    return null;
  }
};
