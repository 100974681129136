import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import PATHS from 'routes';
import { LIMIT_LEVELS, PROOF_OF_ADDRESS_STATUS } from 'config/constants';
import { useReduxQuery } from 'utils/redux-query';
import Levels from './Levels';
import { Address } from './UpgradeLimit/Address';
import UploadIdentification from './UpgradeLimit/UploadIdentification';
import { UpgradeLimitContainer } from './Limits.styles';

const {
  investorSettingsLimitsPath,
  investorSettingsLimitsLevelOneUpdateAddressPath,
  investorSettingsLimitsLevelOneUpdateIdentificationPath,
} = PATHS;

export const navigateToUpgradeLevelTwo = ({
  limits,
  history,
  isAddressOnReview,
  isAddressPending,
}) => {
  if (limits?.currentLevel !== LIMIT_LEVELS.LEVEL_ONE) return;

  if (!limits?.requirementsNextLevel?.userIdValidation) {
    history.push(investorSettingsLimitsLevelOneUpdateIdentificationPath());
    return;
  }

  if (!limits?.requirementsNextLevel?.address && !isAddressOnReview && !isAddressPending) {
    history.push(investorSettingsLimitsLevelOneUpdateAddressPath());
  }
};

function LimitsSettings() {
  const history = useHistory();
  const settingsLimitsLevelOneUpdateAddressMatch = useRouteMatch(
    investorSettingsLimitsLevelOneUpdateAddressPath(),
  );
  const matchIndex = useRouteMatch({ exact: true, path: investorSettingsLimitsPath() });
  const settingsLimitsLevelOneUpdateIdentificationMatch = useRouteMatch(
    investorSettingsLimitsLevelOneUpdateIdentificationPath(),
  );
  const [session] = useReduxQuery('SESSION');
  const { limits, user } = session;
  const isAddressOnReview = user?.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.ON_REVIEW;
  const isAddressPending = user?.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.PENDING;

  useEffect(() => {
    if (matchIndex) return;
    navigateToUpgradeLevelTwo({
      limits,
      history,
      isAddressOnReview,
      isAddressPending,
    });
  }, [
    isAddressOnReview,
    limits?.currentLevel,
    limits?.requirementsNextLevel?.address,
    limits?.requirementsNextLevel?.userIdValidation,
  ]);

  useEffect(() => {
    // Redirect to settings/limits path if matches some route about
    // LEVEL_ONE level but this level already was reached
    const matchSomeRoute = [
      settingsLimitsLevelOneUpdateAddressMatch,
      settingsLimitsLevelOneUpdateIdentificationMatch,
    ].some(Boolean);

    if (matchSomeRoute && limits?.currentLevel !== LIMIT_LEVELS.LEVEL_ONE) {
      history.push(investorSettingsLimitsPath());
    }
  }, [settingsLimitsLevelOneUpdateAddressMatch, limits?.currentLevel]);

  return (
    <UpgradeLimitContainer>
      <Switch>
        <Route
          path={investorSettingsLimitsLevelOneUpdateAddressPath()}
          component={Address}
        />
        <Route
          path={investorSettingsLimitsLevelOneUpdateIdentificationPath()}
          component={UploadIdentification}
        />
        <Route component={Levels} />
      </Switch>
    </UpgradeLimitContainer>
  );
}

export default LimitsSettings;
