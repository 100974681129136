import React from 'react';
import { withRouter, Link as RRLink } from 'react-router-dom';
import cls from 'classnames';
import { t, tscope } from 'i18n';
import { toCurrencyWithDecimals } from 'utils/numberFormatters';
import {
  Button,
  Alert,
  AlertIcon,
  Text,
} from 'ui';
import SchemeContainer from './styles';

const ts = tscope('components.UpdateProfileAddress');

const Scheme = ({
  level,
  isCurrent,
  isProofOfAddressRejected,
}) => (
  <SchemeContainer className={`${isCurrent ? 'current' : ''}`}>
    <div className="current-scheme ">
      <span>Esquema Actual</span>
    </div>
    <div className="card ">
      <div className="level">
        <span>
          {t('settings.limits.level')}
          {' '}
          {level.step}
        </span>
      </div>
      <div className="account-limit">
        <span className="amount header">
          {level.accountAmount && <span className="dollar">$</span>}
          {level.accountAmount
            ? toCurrencyWithDecimals(level.accountAmount).replace('$', '')
            : t('settings.limits.limitless')}
        </span>
        <div className="note">
          <span>{t('settings.limits.accountLimit')}</span>
        </div>
      </div>
      <div className={`funding-limit ${level.isCompleted ? 'active' : ''}`}>
        <span className="amount">
          {level.fundingAmount && <span className="dollar">$</span>}
          {level.fundingAmount
            ? toCurrencyWithDecimals(level.fundingAmount).replace('$', '')
            : t('settings.limits.limitless')}
        </span>
        <div className="note">
          <span>{t('settings.limits.monthlyOperation')}</span>
        </div>
      </div>
      <div className="requirements">
        <span>{t('settings.limits.requirements')}</span>
      </div>
      <div className="required-documentation">
        <div className="ul">
          {level.requirements.map((item) => (
            <div
              className={cls('li', {
                completed: item.completed,
                incomplete: !item.completed,
              })}
              key={item.id}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      {level.notice && (
      <Text fontSize="1.2rem" lineHeight="1.8rem" color="schema.gray.500">
        {level.notice}
      </Text>
      )}
      {isProofOfAddressRejected && (
        <Alert
          width="auto"
          variant="subtle"
          status="warning"
          borderWidth="1px"
          borderRadius="lg"
          color="yellow.500"
          borderStyle="solid"
          bgColor="yellow.100"
          borderColor="yellow.300"
        >
          <AlertIcon color="yellow.500" />
          <Text color="gray.700" fontSize="sm" textAlign="left">
            {ts('alerts.warning.proofOfAddress')}
          </Text>
        </Alert>
      )}
      {level.displayUpgradeButton && (
        <Button
          as={RRLink}
          marginTop="5"
          to={level.disabledUpgradeButton ? '#' : level.updgradeLevelLink}
          disabled={level.disabledUpgradeButton}
          isDisabled={level.disabledUpgradeButton}
        >
          {level.upgradeButtonText || t('settings.limits.upgrade')}
        </Button>
      )}
    </div>
  </SchemeContainer>
);

export default withRouter(Scheme);
