import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { colors, Flex } from 'styles';
import { Text } from 'components/Typography';
import Divider from 'components/Divider';

const createVariant = ({ colorSchema = 'blue' } = {}) => ({
  success: {
    bgColor: get(colors.schema, colorSchema)[50],
    color: get(colors.schema, colorSchema)[500],
    lineColor: 'schema.gray.300',
  },
  active: {
    bgColor: get(colors.schema, colorSchema)[500],
    color: colors.schema.white,
    lineColor: 'schema.gray.300',
  },
  inactive: {
    bgColor: colors.schema.white,
    color: colors.schema.gray[300],
    lineColor: 'schema.gray.300',
  },
});

const BulletThread = (props) => {
  const {
    last, status, number, colorSchema,
  } = props;
  const variant = createVariant({ colorSchema })?.[status];

  const minHeight = last ? 'auto' : '50px';

  return (
    <Flex h="100%" direction="column" alignItems="center" minHeight={minHeight}>
      <Flex
        alignItems="center"
        justifyContent="center"
        bgColor={variant?.bgColor}
        borderRadius="50%"
        w="30px"
        h="30px"
      >
        <Text as="b" size="1.6rem" color={variant?.color}>
          {number}
        </Text>
      </Flex>
      {!last && (
        <Flex flexGrow="1" my="0.5rem">
          <Divider borderWidth="0 0 0 1px" position="vertical" color={variant?.lineColor} />
        </Flex>
      )}
    </Flex>
  );
};

BulletThread.propTypes = {
  status: PropTypes.oneOf(Object.keys(createVariant())).isRequired,
  number: PropTypes.string.isRequired,
  last: PropTypes.bool,
};

BulletThread.defaultProps = {
  last: true,
};

export default BulletThread;
