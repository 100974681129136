import React from 'react';
import { Flex } from 'styles';
import { t } from 'i18n';
import { Heading } from 'components/Typography';
import ListForm from 'components/ListForm/ListForm';
import Form from './Form/Form';
import validate from './validator';
import ConfirmProfileInformationContainer from './styles';
import { useController } from './hooks/useController';

const ts = (id) => t(id, { scope: 'Public.ConfirmProfile' });

const ConfirmProfileInformation = ({ goNextStep }) => {
  const {
    initialValues,
    profileStatus,
    updateProfile,
  } = useController({ goNextStep });

  if (profileStatus.completed) {
    return (
      <Flex as={ConfirmProfileInformationContainer} px={{ base: '1rem', md: '2rem' }}>
        <div className="content">
          <Heading textAlign="center">{ts('title')}</Heading>
          <ListForm
            onSubmit={updateProfile}
            initialValues={initialValues}
            validate={validate}
            p="2rem"
            mt="2rem"
            rowGap="2rem"
          >
            {() => <Form />}
          </ListForm>
        </div>
      </Flex>
    );
  }

  return null;
};

export default ConfirmProfileInformation;
