import React from 'react';
import { Select } from 'ui';
import FieldBase from '../FieldBase/FieldBase';
import { useCatalog } from './hooks/useCatalog';

const sortFn = (a, b) => a.name.localeCompare(b.name);

function CountrySelectField(props) {
  const {
    name,
    textLabel,
    color = 'schema.gray.700',
    placeholder,
    parse,
    format,
    readOnly,
    children,
    disabled,
    searchPlaceholder,
    ...rest
  } = props;

  const { data } = useCatalog();

  const options = data
    ?.catalogs
    ?.countries
    ?.toSorted(sortFn) || [];

  return (
    <FieldBase
      color={color}
      name={name}
      textLabel={textLabel}
      parse={parse}
      format={format}
      disabled={disabled}
      {...rest}
    >
      {({ input, hasError }) => (
        <Select
          {...input}
          error={hasError}
        >
          {searchPlaceholder && (
            <option value="">{searchPlaceholder}</option>
          )}
          {options.map(({ id, name: countryName }) => (
            <option key={id} value={id}>
              {countryName}
            </option>
          ))}
        </Select>
      )}
    </FieldBase>
  );
}

export default CountrySelectField;
