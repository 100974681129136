import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getQuery, getQueryRecord, setLoadQueryTimestamp } from '../queryBase';
import parseMeta from './parseMeta';
import { validateActionFn } from './alerts';
import { STATUS } from '../config';
import { useErrorEffect } from './useErrorEffect';

const useReduxLazyQuery = (keystore) => {
  const { payload, meta } = useSelector((state) => state[keystore]);
  const dispatch = useDispatch();
  const actionStored = getQuery(keystore);

  const lazyQuery = useCallback(
    async (...args) => {
      if (getQueryRecord(keystore).initializedAt || meta.status !== STATUS.INITIAL) return;

      validateActionFn(keystore, actionStored);

      const timeStamp = Date.now();

      setLoadQueryTimestamp(keystore, timeStamp);
      await dispatch(actionStored({}, ...args));
    },
    [keystore, meta.status],
  );

  useErrorEffect({ meta });

  return [
    payload,
    {
      ...parseMeta(meta),
      lazyQuery,
    },
    {
      lazyQuery,
    },
  ];
};

export default useReduxLazyQuery;
