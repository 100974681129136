import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { userSettingsAccount } from 'routes/index';

export const NavigationResolver = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace(userSettingsAccount);
  }, [history]);

  return null;
};
