import React from 'react';
import { ReactComponent as AlertLimitsIcon } from 'assets/icons/alertLimits.svg';
import Link from 'components/Link';
import { t } from 'i18n';
import { ReactComponent as CloseModalIcon } from 'assets/icons/closeModal.svg';
import PATHS from 'routes';

import {
  UpdateAddressNotifyModalContainer,
  WelcomeText,
  RedirectToUpdateAddreesLink,
  CloseButton,
  BodyText,
} from './UpdateAddressNotifyModal.style';

const { investorSettingsUpdateAddressPath } = PATHS;

function UpdateAddressNotifyModal(props) {
  const { onClose, from } = props;

  return (
    <UpdateAddressNotifyModalContainer isOpen {...props}>
      <CloseButton onClick={onClose}>
        <CloseModalIcon />
      </CloseButton>
      <AlertLimitsIcon />
      <WelcomeText align="center">{t('settings.updateAddressNotifyModal.title')}</WelcomeText>
      <BodyText>{t('settings.updateAddressNotifyModal.body')}</BodyText>
      <RedirectToUpdateAddreesLink
        to={investorSettingsUpdateAddressPath(null, { section: from.section })}
        as={Link}
        onClick={onClose}
      >
        {t('settings.updateAddressNotifyModal.button')}
      </RedirectToUpdateAddreesLink>
    </UpdateAddressNotifyModalContainer>
  );
}

UpdateAddressNotifyModal.defaultProps = {
  from: { section: null },
};

export default UpdateAddressNotifyModal;
