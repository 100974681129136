import React from 'react';
import {
  css,
  colors,
  styled,
  boxHelpers,
  breakPoints,
  shouldForwardProp,
} from 'styles';

const PaginationDiv = ({ isMobile, active, ...otherProps }) => <div {...otherProps} />;

const sharedStyles = css`
  background-color: white;
  padding: 1rem 1.6rem;
  font-weight: 500;
  display: flex;
  margin-right: 1rem;
  font-weight: 600;
  border-radius: 2rem;
  transition: all 0.4s ease;
  color: ${colors.gray.g5};
  background-color: ${colors.gray.g2};
  &:hover {
    cursor: pointer;
    background-color: white;
    color: ${colors.blue};
    box-shadow: 0 5px 9px 0 rgba(37, 103, 141, 0.04);
    font-weight: 700;
  }
`;

const PaginationContainer = styled(PaginationDiv).withConfig({ shouldForwardProp })`
  grid-column: 2 / -1;
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    margin-left: 0;
  }
  & .controlGroup {
    display: flex;
    & div {
      ${sharedStyles};
    }
  }
  & .paginationList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  ${boxHelpers}
`;

export const PageDiv = styled(PaginationDiv)`
  ${sharedStyles}
  ${(props) => props.active
    && css`
      font-weight: 700;
      background-color: ${colors.blue};
      color: white;
      &:hover {
        background-color: ${colors.btn.blueHover};
        color: white;
      }
    `}
`;

export default PaginationContainer;
