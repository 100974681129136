import React from 'react';
import { Link } from 'react-router-dom';
import { Line } from 'rc-progress';
import { t } from 'i18n';
import { toCurrency } from 'utils/numberFormatters';
import BackArrowSVG from 'assets/backArrow.svg';
import { LinearLoader } from 'components/Loaders';
import TopLeftBarContainer from './styles';
import AvatarQualificationInterestStatus from '../../AvatarQualificationInterestStatus';
import RequisitionInfoGroup from '../../../Elements/RequisitionInfoGroup';
import ProductTypeDescription from '../../../Elements/ProductTypeDescription';

const TopLeftBarContent = ({
  isFundedRequisition,
  isLoading,
  dataReq,
  fundedPercentage,
  remainingAmount,
  fundingEndDays,
  backTo,
}) => {
  const FundedLineChart = () => {
    if (isLoading) {
      return (
        <div className="fundedLineChart">
          <LinearLoader height={10} widthLoader={100} />
        </div>
      );
    }
    return (
      <div className="fundedLineChart">
        <Line
          percent={fundedPercentage}
          strokeWidth="2"
          strokeColor="#05C574"
          trailColor="#FFFFFF"
          trailWidth="2"
          style={{
            border: '1px solid #DDEAF2',
            borderRadius: '10px',
          }}
        />
        <p>
          Faltan
          {' '}
          <b>{toCurrency(remainingAmount)}</b>
          {' '}
          y
          {' '}
          <b>{fundingEndDays}</b>
          {' '}
          días
        </p>
      </div>
    );
  };

  return (
    <TopLeftBarContainer className={isFundedRequisition ? 'funded' : ''}>
      <Link to={backTo} type="button" className="closeButton">
        <img src={BackArrowSVG} alt="back" />
        {' '}
        {t('common.backButton')}
      </Link>
      <div className="content">
        <AvatarQualificationInterestStatus
          dataReq={dataReq}
          isFundedRequisition={isFundedRequisition}
          isLoading={isLoading}
        />
        <RequisitionInfoGroup
          dataReq={dataReq}
          isFundedRequisition={isFundedRequisition}
          isLoading={isLoading}
        />
        {!isFundedRequisition && <FundedLineChart />}
      </div>
      {isFundedRequisition && <ProductTypeDescription dataReq={dataReq} />}
    </TopLeftBarContainer>
  );
};

export default TopLeftBarContent;
