import styled from 'styled-components';
import {
  Modal as BaseModal,
} from 'components';

export const Modal = styled(BaseModal)`
  max-width: 450px !important;
  max-height: 99% !important;
  border-radius: 4px;
  overflow: hidden;
`;

export const styles = {
  textHeaderProps: {
    textAlign: 'center',
  },
  textContent: {
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: 'schema.gray.500',
  },
  form: {
    gap: '1rem',
    pb: '1rem',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  field: {
    flexGrow: '1',
  },
  errorTextHelper: {
    mb: '0',
    height: '0',
  },
};
