function debounce(func, timeout = 300) {
  let timer;
  let lastResult = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      lastResult = func.apply(this, args);
    }, timeout);
    return lastResult;
  };
}

export default debounce;
