export const styles = {
  loadingContent: {
    flexGrow: '2',
    flexBasis: '0',
    bgColor: 'white',
    direction: 'column',
    borderRadius: '1rem',
    boxShadow: '0 5px 12px 0 rgba(38, 57, 69, 0.09)',
  },
  loadingInnerContent: {
    w: '100%',
    centered: true,
    borderRadius: '1rem',
    backgroundColor: 'schema.gray.300',
    h: {
      base: 'calc(100vh - 24rem)',
      sm: 'calc(100vh - 42.1rem)',
      md: 'calc(100vh - 34rem)',
    },
  },
};
