export const parseCURPField = (v) => v
  ?.slice(0, 16)
  .toUpperCase()
  .match(/[a-zA-Z0-9]/g)
  ?.join('') || '';

export const parseCURPHomoField = (v) => v
  ?.slice(0, 2)
  .toUpperCase()
  .match(/[a-zA-Z0-9]/g)
  ?.join('') || '';
