import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18n';
import Button from 'components/Button/Button';
import { Heading, Text } from 'components/Typography';
import { Icon } from 'components/Icon/Icon';
import { CardDefaultLoader } from 'components/Loaders';
import { Grid, Flex } from 'styles';
import { userSettingsAutoinvest } from 'routes/';
import { ToggleAutoInvestModal, RemoveAutoInvestModal } from './components';
import { useController, useModal } from './hooks';

const ts = (key, opts) => t(key, { scope: 'components.AutoInvestWidget', ...opts });

const colorForStatus = {
  activated: 'schema.green.500',
  paused: 'schema.blue.400',
};

export const AutoInvestWidget = (props) => {
  const pauseAIModal = useModal();
  const removeAIModal = useModal();
  const {
    autoInvestStatus,
    fetchAllAIStatus,
    shouldShowWidget,
    isUserPartiallyBlocked,
  } = useController();

  if (!fetchAllAIStatus.completed) {
    return (
      <CardDefaultLoader {...props} data-testid="autoInvestWidget-loader" />
    );
  }

  if (!shouldShowWidget) return null;

  return (
    <Grid
      p="2rem"
      rowGap="1.5rem"
      boxShadow="0px 5px 9px rgba(37, 103, 141, 0.0386801)"
      borderRadius="1rem"
      bgColor="schema.white"
      {...props}
    >
      <Flex gap="0.5rem" alignItems="center">
        <Icon iconName="AUTO_INVEST" boxSize="2.5rem" color="schema.gray.700" />
        <Heading
          t={ts}
          i18nKey="title"
          components={{
            status: <Text
              t={ts}
              as="span"
              color={colorForStatus[autoInvestStatus]}
              fontWeight="500"
              fontSize="1.4rem"
              i18nKey={`statuses.${autoInvestStatus}`}
            />,
          }}
          as="h4"
        />
      </Flex>

      <Flex>
        <Text t={ts} i18nKey="body" />
      </Flex>

      {!isUserPartiallyBlocked && (
        <Flex gap="1rem">
          {autoInvestStatus === 'inactivated' && (
            <Button as={Link} to={`${userSettingsAutoinvest}/show`} $size="small">{ts('actions.activate')}</Button>
          )}
          {autoInvestStatus === 'activated' && (
            <>
              <Button as={Link} to={`${userSettingsAutoinvest}/edit`} $type="subtle" $size="small">{ts('actions.edit')}</Button>
              <Button $type="subtle" $size="small" onClick={pauseAIModal.open}>{ts('actions.pause')}</Button>
              <Button $type="subtle" $size="small" onClick={removeAIModal.open}>{ts('actions.remove')}</Button>
            </>
          )}
          {autoInvestStatus === 'paused' && (
            <>
              <Button as={Link} to={`${userSettingsAutoinvest}/edit`} $type="subtle" $size="small">{ts('actions.edit')}</Button>
              <Button $type="subtle" $size="small" onClick={pauseAIModal.open}>{ts('actions.activate')}</Button>
              <Button $type="subtle" $size="small" onClick={removeAIModal.open}>{ts('actions.remove')}</Button>
            </>
          )}
        </Flex>
      )}

      {/* Modals begins here */}
      {['activated', 'paused'].includes(autoInvestStatus) && (
        <ToggleAutoInvestModal
          maxWidth="auto"
          autoInvestStatus={autoInvestStatus}
          isOpen={pauseAIModal.isOpen}
          onClose={pauseAIModal.close}
        />
      )}
      {!['inactivated'].includes(autoInvestStatus) && (
        <RemoveAutoInvestModal
          maxWidth="auto"
          isOpen={removeAIModal.isOpen}
          onClose={removeAIModal.close}
        />
      )}
    </Grid>
  );
};
