import clearErrorsObject from 'utils/Validators/clearErrorsObject';
import isNil from 'utils/isNil';
import { t } from 'i18n';

const ts = (name, type) => t(`Public.ConfirmProfile.confirmForm.fields.${name}.errors.${type}`, {
  defaults: [{ scope: `Forms.fields.errors.${type}` }],
});

const validate = (values) => {
  if (!values) return {};

  const {
    user: { firstName, firstLastName } = {},
    gender,
    rfc,
    curp,
    termAndConditions,
    confirmation,
  } = values;
  const errors = { user: {} };

  if (!firstName) {
    errors.user.firstName = ts('firstName', 'required');
  }

  if (!firstLastName) {
    errors.user.firstLastName = ts('firstLastName', 'required');
  }

  if (isNil(gender)) {
    errors.gender = ts('gender', 'required');
  }

  if (isNil(rfc)) {
    errors.rfc = ts('rfc', 'required');
  }

  if (isNil(curp)) {
    errors.curp = ts('curp', 'required');
  }

  if (!termAndConditions) {
    errors.termAndConditions = ts('termAndConditions', 'required');
  }

  if (!confirmation) {
    errors.confirmation = ts('confirmation', 'required');
  }

  if (rfc && rfc.length < 13) {
    errors.rfc = ts('rfc', 'shortLength');
  }

  if (curp && curp.length < 18) {
    errors.curp = ts('curp', 'shortLength');
  }

  return clearErrorsObject(errors);
};

export default validate;
