import styled from 'styled-components';
import { breakPoints } from 'config/constants';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    position: fixed;
    width: 100%;
  }
  .cartAnimatedContainer {
    position: relative;
    right: 0;
    z-index: 900;
  }

  @media print {
    header,
    #sideBar {
      display: none !important;
    }
    #contentArea {
      width: 100% !important;
      overflow-x: initial;
    }
    #footer {
      display: none;
    }
  }
`;
