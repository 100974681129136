import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { animoZapopanRoute } from 'routes';
import { getProductType } from 'utils/requisitions';
import ProductTypeContainer from './styles';

const ProductTypeDescription = ({ dataReq }) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderDescription = (product) => {
    const { type, description, moreInfo } = product;
    if (!description) return null;

    if (type === 'AZ!') {
      return (
        <div className={`description zapopan-product ${isOpen ? 'is-open' : ''}`}>
          <div className="description__align-left">
            <p>{description}</p>
            <span className="description__moreInfo">
              <Link to={animoZapopanRoute} target="new">
                {moreInfo}
              </Link>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className={`description ${isOpen ? 'is-open' : ''}`}>
        <p>{description}</p>
      </div>
    );
  };

  const productTypeObj = getProductType(dataReq);
  if (!productTypeObj) return null;

  return (
    <ProductTypeContainer>
      <div
        className="acordeon"
        role="button"
        tabIndex="0"
        onKeyPress={() => setIsOpen((prev) => !prev)}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="product">{productTypeObj.type}</span>
        <span>{productTypeObj.title}</span>
      </div>
      {renderDescription(productTypeObj)}
    </ProductTypeContainer>
  );
};

export default ProductTypeDescription;
