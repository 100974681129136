import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import ChoiceSelector from 'components/ChoiceSelector';
import { fetchProfileCatalogs } from 'actions/catalogs';
import { messages } from 'config/constants';
import { Box, Flex } from 'styles';
import { useReduxQuery } from 'utils/redux-query';
import { hasTrailingWhitespace } from 'utils/strings';
import { tscope } from 'i18n';
import ReferenceCode from '../ReferenceCode';
import StepContainer from '../../RegisterFunnel.styles';
import SaveProfileButton from '../SaveProfileButton';
import TextField from '../../../../components/TextField';

const t = tscope('RegisterFunnel.jobStep.texts');
const te = tscope('Forms.fields.errors');

const LayoutDisabled = () => (
  <Flex
    position="absolute"
    top="0"
    bottom="0"
    left="0"
    right="0"
    bgColor="schema.gray.300"
    opacity="0.3"
    borderRadius="0.5rem"
  />
);

const JobStep = ({
  goNextStep,
  hasReferenceCode,
  montlhyFundings,
  depositAmounts,
  fundingSources,
  moneyDestination,
  onConfirm = () => { },
  hasCatalogs,
}) => {
  const dispatch = useDispatch();
  const [profile] = useReduxQuery('PROFILE');
  const isDisabledForm = false;
  const montlhyFundingsList = montlhyFundings.map((item) => ({
    value: `${item.id}-montlhyFundings`,
    label: item.name,
  }));
  const depositAmountsList = depositAmounts.map((item) => ({
    value: `${item.id}-depositAmounts`,
    label: item.name,
  }));
  const fundingSourcesList = fundingSources.map((item) => ({
    value: `${item.id}-fundingSources`,
    label: item.name,
  }));
  const moneyDestinationList = moneyDestination.map((item) => ({
    value: `${item.id}-moneyDestination`,
    label: item.name,
    className: 'big-label',
  }));

  useEffect(() => {
    if (!hasCatalogs) {
      dispatch(fetchProfileCatalogs());
    }
  }, [hasCatalogs]);

  const handleFormSubmit = async (data) => {
    const profileData = {
      monthlyFundingId: data.fundingByMonth.replace('-montlhyFundings', ''),
      depositAmountId: data.amountByDeposit.replace('-depositAmounts', ''),
      fundingSourceId: data.sourceOfFunds.replace('-fundingSources', ''),
      moneyDestinationId: data.destinationOfMoney.replace('-moneyDestination', ''),
      investmentReason: data.investmentReason,
    };
    await goNextStep({ profile: profileData });
    onConfirm();
  };

  return (
    <StepContainer>
      <div className="instructions">
        <h3>{t('header')}</h3>
        <p>{t('details')}</p>
      </div>
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{
          fundingByMonth: profile?.monthlyFundingId
            ? `${profile?.monthlyFundingId}-montlhyFundings`
            : null,
          amountByDeposit: profile?.depositAmountId
            ? `${profile?.depositAmountId}-depositAmounts`
            : null,
          sourceOfFunds: profile?.fundingSourceId
            ? `${profile?.fundingSourceId}-fundingSources`
            : null,
          destinationOfMoney: profile?.moneyDestinationId
            ? `${profile?.moneyDestinationId}-moneyDestination`
            : null,
          // eslint-disable-next-line camelcase
          investmentReason: profile?.investmentReason ?? null,
        }}
        validate={(values) => {
          const { required } = messages;
          const errors = {};
          if (!values.fundingByMonth) errors.fundingByMonth_errors = required;
          if (!values.amountByDeposit) errors.amountByDeposit_errors = required;
          if (!values.sourceOfFunds) errors.sourceOfFunds_errors = required;
          if (!values.destinationOfMoney) errors.destinationOfMoney_errors = required;
          if (!values.investmentReason) errors.investmentReason = required;
          if (values?.investmentReason?.length < 10) errors.investmentReason = te('minLength', { count: 10 });
          if (hasTrailingWhitespace(values?.investmentReason || '')) errors.investmentReason = te('trailingWhitespace');
          return errors;
        }}
        render={({ handleSubmit, values, submitting }) => (
          <Box w={{ base: '100%', sm: '35rem' }}>
            <form id="profile-form" onSubmit={handleSubmit}>
              <Flex className="form-group" direction="column" position="relative">
                <label>{t('recurrence')}</label>
                <ChoiceSelector
                  name="fundingByMonth"
                  options={montlhyFundingsList}
                  formValues={values}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>{ t('amount')}</label>
                <ChoiceSelector
                  name="amountByDeposit"
                  options={depositAmountsList}
                  formValues={values}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>{t('origin')}</label>
                <ChoiceSelector
                  name="sourceOfFunds"
                  options={fundingSourcesList}
                  formValues={values}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>{t('destination')}</label>
                <ChoiceSelector
                  name="destinationOfMoney"
                  options={moneyDestinationList}
                  formValues={values}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
              <Flex className="form-group" direction="column" position="relative">
                <label>{t('reason')}</label>
                <TextField
                  type="textarea"
                  name="investmentReason"
                  length="500"
                  textArea
                  colTextArea={30}
                  rowTextArea={5}
                  disabled={isDisabledForm}
                />
                {isDisabledForm && <LayoutDisabled />}
              </Flex>
            </form>
            {hasReferenceCode && (
              <Flex className="form-group" direction="column" position="relative">
                <ReferenceCode w={{ md: '35rem' }} />
              </Flex>
            )}
            <div className="buttons">
              <SaveProfileButton title="Continuar" submitting={submitting} />
            </div>
          </Box>
        )}
      />
    </StepContainer>
  );
};

const mapStateToProps = ({ catalogs }) => ({
  montlhyFundings: catalogs.montlhyFundings,
  depositAmounts: catalogs.depositAmounts,
  fundingSources: catalogs.fundingSources,
  moneyDestination: catalogs.moneyDestination,
  hasCatalogs: Boolean(catalogs.depositAmounts.length),
});

export default connect(mapStateToProps)(JobStep);
