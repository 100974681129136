import React from 'react';
import classnames from 'classnames';
import { ReactComponent as CartIcon } from 'assets/cartIcon.svg';
import CartIconQuantityContainer from './styles';

const CartIconQuantity = (props) => {
  const { disabled } = props;
  return (
    <CartIconQuantityContainer>
      <CartIcon title="" className={classnames({ zeroTicketsIcon: disabled })} />
    </CartIconQuantityContainer>
  );
};

export default CartIconQuantity;
