import get from 'lodash.get';
import { css } from 'styled-components';
import colors, { zenfiTheme } from 'styles/colors';

const buttonTypes = {
  primary: css`
    color: ${(props) => get(colors.schema, props.color, colors.schema.white)};
    background-color: ${(props) => get(colors.schema, props.schema, colors.schema.blue)[500]};

    :hover {
      background-color: ${(props) => get(colors.schema, props.schema, colors.schema.blue)[600]};
    }
  `,

  subtle: css`
    color: ${({ schema, color }) => (color ? get(colors.schema, color, colors.schema.blue[500])
    : get(colors.schema, schema, colors.schema.blue)[500]
  )};
    background-color: ${(props) => get(colors.schema, props.schema, colors.schema.blue)[100]};

    :hover {
      background-color: ${(props) => get(colors.schema, props.schema, colors.schema.blue)[200]};
    }
  `,

  outline: css`
    color: ${(props) => get(colors.schema, props.schema, colors.schema.gray)[500]};
    background-color: transparent;
    border: 1px solid ${(props) => get(colors.schema, props.schema, colors.schema.gray)[400]};

    :hover {
      background-color: ${(props) => get(colors.schema, props.schema, colors.schema.blue)[100]};
    }
  `,

  'primary-outline': css`
    color: ${colors.blue};
    background-color: transparent;
    border: 1px solid ${colors.blue};
  `,

  success: css`
    color: white;
    background-color: ${colors.green};
    :hover {
      background-color: ${colors.btn.greenHover};
    }
  `,

  'success-outline': css`
    color: ${colors.green};
    background-color: transparent;
    border: 1px solid ${colors.green};
  `,

  danger: css`
    color: white;
    background-color: ${colors.red};
    :hover {
      background-color: ${colors.btn.redHover};
    }
  `,

  info: css`
    color: white;
    background-color: transparent;
    :hover {
      background-color: ${colors.gray.g1};
    }
  `,

  link: css`
    display: inline;
    color: ${({ colorSchema = 'blue' }) => get(colors.schema, colorSchema, colors.schema.blue)[500]};
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    height: auto;
    min-height: 1.4rem;
    font-weight: 500;
    transition: none;
    :hover {
      color: ${({ colorSchema = 'blue' }) => get(colors.schema, colorSchema, colors.schema.blue)[600]};
      transform: none;
      box-shadow: none;
    }
    :active {
      transform: none;
    }
    :disabled {
      background-color: transparent !important;
    }
  `,

  'btn-link': css`
    color: ${({ color = 'blue' }) => get(colors.schema, color, colors.schema.blue)[500]};
    border: none;
    background-color: transparent;
    font-weight: 500;
    text-decoration: none;
    :hover {
      background-color: ${({ color = 'blue' }) => get(colors.schema, color, colors.schema.blue)[100]};
      color: ${({ color = 'blue' }) => get(colors.schema, color, colors.schema.blue)[600]};
      box-shadow: none;
    }
  `,

  transparent: css`
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 0;
    height: auto;
    width: auto;
    transform: translateY(0);
    color: ${({ colorSchema = 'blue' }) => get(colors.schema, colorSchema, colors.schema.blue)[500]}}
    &:hover {
      color: ${({ colorSchema = 'blue' }) => get(colors.schema, colorSchema, colors.schema.blue)[600]};
      background-color: transparent;
    }
  `,

  zenfi: css`
    color: white;
    background-color: ${zenfiTheme.primary};
    border: none;
  `,
};

export default buttonTypes;
