import styled from 'styled-components';
import StyledBox from 'components/Box';
import { breakPoints } from 'config/constants';
import colors from 'styles/colors';
import { ReactComponent as RegulationImageSVG } from 'assets/regulation/regulation.svg';
import Button from 'components/Button/styles';
import Link from 'components/Link';

export const Container = styled.div`
  display: flex;
  padding-top: 8%;
  padding-right: 5rem;
  padding-left: 5rem;
  align-items: center;
  flex-direction: column;
  flex: 1;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 5rem;
  color: ${colors.defaultText};
  text-align: center;
`;

export const ConfirmIdentityText = styled.p`
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: ${colors.gray.g10};

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-align: center;
  }
`;

export const RegulationImage = styled(RegulationImageSVG)`
  margin-right: 2rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const Box = styled(StyledBox)`
  padding: 3.5rem 3rem;
  justify-self: center;
  margin-bottom: 6rem;

  @media (max-width: ${breakPoints.smallBreakPoint}px) {
    padding: 3rem 1.5rem;
    flex-wrap: wrap;
  }
`;

export const StartButton = styled(Button)`
  padding: 1.5rem 3rem;
  font-size: 1.8rem;
  margin-bottom: 3rem;
`;

export const ValidateLaterLink = styled(Link)`
  font-size: 1.4rem;
  color: ${colors.blue};
  font-weight: 500;
`;
