import React, { useState } from 'react';
import { t } from 'i18n';
import { Panel } from 'components';
import { useReduxQuery } from 'utils/redux-query';
import { maskEmailAddress, maskPhoneNumber } from 'utils/maskHandler';
import {
  EmailForm,
  PhoneForm,
  EditableItem,
} from './components';

const ts = (id) => t(id, { scope: 'Dashboard.Settings.Account.ContactsPanel' });

const ContactsPanel = () => {
  const [session] = useReduxQuery('SESSION');
  const [profile] = useReduxQuery('PROFILE');
  const [showPhoneForm, setShowPhoneForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);

  return (
    <Panel
      title={ts('title')}
      maxWidth="100%"
      mb="2rem"
      innerContentProps={{
        px: '1.4rem',
        pt: '1.4rem',
        pb: '1.4rem',
      }}
    >
      <EditableItem
        label={ts('items.0')}
        onEdit={() => {
          setShowEmailForm(!showEmailForm);
          setShowPhoneForm(false);
        }}
        value={maskEmailAddress(session?.user?.email)}
        isEditable={showEmailForm}
      >
        <EmailForm
          onSubmit={() => setShowEmailForm(false)}
          onCancel={() => setShowEmailForm(false)}
        />
      </EditableItem>
      <EditableItem
        label={ts('items.1')}
        onEdit={() => {
          setShowPhoneForm(!showPhoneForm);
          setShowEmailForm(false);
        }}
        value={maskPhoneNumber(profile?.cellPhoneNumber)}
        isEditable={showPhoneForm}
        isLastItem
      >
        <PhoneForm
          shouldReset={!showPhoneForm}
          onCancel={() => {
            setShowPhoneForm(false);
          }}
          onSuccess={() => {
            setShowPhoneForm(false);
          }}
        />
      </EditableItem>
    </Panel>
  );
};

export default ContactsPanel;
