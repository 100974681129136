import React from 'react';
import { t } from 'i18n';
import Step from './Step';
import AsideContainer from './styles';

const Aside = ({ verificationStep = 0 }) => {
  const isActive = (step) => verificationStep === step;
  const isChecked = (step) => verificationStep > step;

  return (
    <AsideContainer>
      <div className="steps">
        <Step
          name={t('settings.limits.aside.level_two.stepOne')}
          number={1}
          checked={isChecked(1)}
          active={isActive(1)}
          last
        />
      </div>
    </AsideContainer>
  );
};

export default Aside;
