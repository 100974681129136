import {
  useEffect,
  useMemo,
} from 'react';
import { tscope } from 'i18n';
import { useForm, useWatch } from 'react-hook-form';
import { DEFAULT_PHONE_CODE } from 'config/constants';
import { filterOnlyNumbers } from 'utils/strings';
import { showErrorAlert, showSuccessAlert } from 'utils/toastHandler';
import { useReduxMutator } from 'utils/redux-query';
import { resolver } from '../resolver';

const fieldErrorT = tscope('Forms.fields.errors');

const DEFAULT_VALUES = {
  otp: '',
  currentPhoneCode: DEFAULT_PHONE_CODE,
  currentPhoneNumber: '',
  newPhoneCode: DEFAULT_PHONE_CODE,
  newPhoneNumber: '',
};

const getIsMissingOTPOnly = (values, context) => {
  const { errors } = resolver(values, context);
  if (!errors) return false;
  return Object.entries(errors).length === 1 && errors?.otp;
};

const buildFormValues = (user) => {
  if (!user?.cellPhoneNumber) return null;
  const {
    cellPhoneCode = DEFAULT_PHONE_CODE,
  } = user;
  return {
    ...DEFAULT_VALUES,
    currentPhoneCode: cellPhoneCode,
    currentPhoneNumber: '',
  };
};

export const usePhoneForm = ({
  t,
  user,
  onSuccess,
  phoneCodes,
  shouldReset,
  cellPhoneCode,
  cellPhoneNumber,
}) => {
  const context = {
    cellPhoneCode,
    cellPhoneNumber,
    phoneCodeValidations: phoneCodes.validations,
  };
  const {
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    context,
    resolver,
    defaultValues: DEFAULT_VALUES,
  });
  const currentValues = useWatch({ control });
  const handleSuccess = () => {
    showSuccessAlert({
      t,
      key: 'phoneUpdated',
    });
    onSuccess();
  };
  const handleError = (error) => {
    if (error?.response?.status === 422) {
      setError('otp', { message: fieldErrorT('invalidOTP_Token') });
    } else {
      showErrorAlert({
        t,
        key: 'phoneUpdated',
      });
    }
  };

  const [
    editPhone,
    { status: { pending: isLoading } },
  ] = useReduxMutator('UPDATE_PROFILE_CELLPHONE', {
    onSuccess: handleSuccess,
    onError: handleError,
  });
  const isMissingOTPOnly = useMemo(
    () => getIsMissingOTPOnly(currentValues, context),
    [context, currentValues],
  );
  const handleEditPhone = ({
    otp,
    newPhoneCode,
    newPhoneNumber,
  }) => {
    editPhone({
      otp,
      cellPhoneCode: Number(filterOnlyNumbers(newPhoneCode)),
      cellPhoneNumber: filterOnlyNumbers(newPhoneNumber),
    });
  };
  const onSubmit = handleSubmit(handleEditPhone);

  useEffect(() => {
    const formValues = buildFormValues(user);
    if (formValues) reset(formValues);
  }, [
    user,
  ]);

  useEffect(() => {
    if (shouldReset) reset(DEFAULT_VALUES);
  }, [shouldReset]);

  return {
    reset,
    control,
    onSubmit,
    isLoading,
    clearErrors,
    isMissingOTPOnly,
  };
};
