import { gql } from '@apollo/client';
import { useQuery } from 'hooks/apollo';

export const CATALOG_GQL = gql`
query CATALOG_GQL {
  catalogs {
    investorBanks {
      abmNumber
      id
      name
    }
  }
}`;

export const useCatalogQuery = (options) => useQuery(CATALOG_GQL, {
  context: {
    clientName: 'public',
  },
  ...options,
});
