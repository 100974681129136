import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';

export async function fetchTransactions() {
  const method = 'get';
  const path = '/v2/investor/transactions';
  const config = { method, path };
  const { data: transactions } = await request(config);

  if (Array.isArray(transactions)) {
    return camelify(transactions);
  }

  return null;
}

export const fetchVoucher = async ({ uid }) => {
  const method = 'get';
  const path = `/v1/vouchers/${uid}`;
  const config = { method, path };
  const { data } = await request(config);
  const { originalInput } = camelify(data);
  return {
    token: originalInput,
  };
};
