import styled from 'styled-components';
import { breakPoints } from 'config/constants';

export const Content = styled.div`
  width: 34rem;

  @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
    width: 100%;
  }
`;
