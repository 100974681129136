import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { useChannel } from 'hooks/webSocket';
import { USER_STATUSES } from 'config/constants';

export const usePrivateInvestorChannel = () => {
  const [session] = useReduxQuery('SESSION');
  const {
    uid,
    client,
    expiry,
    'token-type': tokenType,
    'access-token': accessToken,
  } = useSelector(({ auth }) => auth.credentials);
  const user = session?.user;
  const isUserBlocked = session?.user?.status === USER_STATUSES.BLOCKED;
  const channelName = user ? `private-${user?.id}` : null;
  const [sessionStatus] = useReduxSubscription('SESSION_STATUS');
  const inactiveSession = !!sessionStatus;
  const [isSubscribed, setIsSubscribed] = useState(false);
  const credentials = useMemo(() => (isUserBlocked || inactiveSession
    ? {}
    : {
      uid,
      client,
      expiry,
      tokenType,
      accessToken,
    }),
  [
    uid,
    client,
    expiry,
    tokenType,
    accessToken,
    isUserBlocked,
    inactiveSession,
  ]);

  const { channel } = useChannel({
    channelName,
    credentials,
  });

  const bindEvent = useCallback((eventName, callback) => {
    if (!channel) return () => {};
    channel.bind(eventName, callback);
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [channel]);

  useEffect(() => {
    if (!channel) return;
    channel.bind('pusher:subscription_succeeded', () => {
      setIsSubscribed(true);
    });

    channel.bind('pusher:subscription_error', () => {
      setIsSubscribed(true);
    });
  }, [channel]);

  return {
    bindEvent,
    isSubscribed,
    privateInvestorChannel: channel,
  };
};

export const useEvent = (event, callback) => {
  const {
    bindEvent,
    isSubscribed,
  } = usePrivateInvestorChannel();

  useEffect(() => {
    if (!isSubscribed) return () => {};
    const unbind = bindEvent(event, callback);
    return () => { unbind(); };
  }, [
    event,
    callback,
    bindEvent,
    isSubscribed,
  ]);
};
