import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { dashboardRoute } from 'routes';
import { useReduxQuery } from 'utils/redux-query';
import { useModalDisclosure } from 'hooks/ui';
import { useAuthorizeWithOTP } from 'components/AuthorizeWithOTPField';
import { WITHDRAWAL_BANK_ACCOUNT_STATUS } from 'config/constants';
import { useWithdrawalForm } from './useWithdrawalForm';
import { useBankAccountsQuery } from './graphql/useBankAccount';

const sortBankAccounts = (bankAccounts) => {
  if (!bankAccounts) return [];
  const statusWeight = {
    [WITHDRAWAL_BANK_ACCOUNT_STATUS.INVALID]: 10,
    [WITHDRAWAL_BANK_ACCOUNT_STATUS.VALIDATING]: 20,
    [WITHDRAWAL_BANK_ACCOUNT_STATUS.VALID]: 30,
  };
  const compareFn = (a, b) => statusWeight[b.status] - statusWeight[a.status];
  return bankAccounts.toSorted(compareFn);
};

export const useController = () => {
  const history = useHistory();
  const {
    data: bankAccountsData,
    loading: isBankAccountsLoading,
  } = useBankAccountsQuery();
  const [
    secondFactor,
    { status: { completed: is2FAComplete } },
  ] = useReduxQuery('SECOND_FACTOR');
  const [accountStatements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const [profile] = useReduxQuery('PROFILE');
  const telephone = profile?.cellPhoneNumber;
  const currentFactor = secondFactor?.otpType;
  const bankAccounts = bankAccountsData?.user?.withdrawalAccounts;
  const isNotThereBakAccounts = !bankAccounts || bankAccounts.length === 0;
  const isAddBankAccountLinkDisabled = secondFactor === null;
  const addBankAccountDisclosure = useModalDisclosure();
  const bankAccountOptions = useMemo(
    () => sortBankAccounts(bankAccounts),
    [bankAccounts],
  );

  const onSuccess = () => {
    history.push(dashboardRoute);
  };

  const {
    errors,
    control,
    onSubmit,
    isLoading,
    setValue,
  } = useWithdrawalForm({
    onSuccess,
    bankAccounts,
    accountStatements,
  });

  const onResendOTP = useCallback(() => {
    setValue('otp', '');
  }, [setValue]);

  const isRequestOTPDisabled = [errors?.amount, errors?.account].some(Boolean);

  const {
    otpFieldProps,
    isOTPFieldVisible,
  } = useAuthorizeWithOTP({
    telephone,
    onResendOTP,
    is2FAComplete,
    isRequestOTPDisabled,
    currentSecondFactor: secondFactor,
  });

  const isDisabled = [
    isLoading,
    isNotThereBakAccounts,
    isBankAccountsLoading,
    secondFactor === null,
  ].some(Boolean);

  const isSubmitRendered = [
    is2FAComplete,
    isOTPFieldVisible,
    secondFactor !== null,
  ].every(Boolean);

  return {
    control,
    onSubmit,
    isDisabled,
    currentFactor,
    otpFieldProps,
    isSubmitRendered,
    bankAccountOptions,
    addBankAccountDisclosure,
    isAddBankAccountLinkDisabled,
  };
};
