import React from 'react';
import { Box } from 'ui';
import { styles } from './ContractWrapper.style';

const customCSSStyle = `
<style>
  html {
    font-size: 14px;
  }
  h4 {
    line-height: normal;
  }
  ul {
    padding-inline-start: 1rem;
  }
  table {
    font-size: 1rem !important;
    width: auto !important;
  }
  @media (max-width: 756px) {
    html {
      font-size: 12px;
    }
    body {
      margin: 0;
    }
    .contract-global-style {
      padding: 0 !important;
      font-size: 1rem !important;
    }
  }
</style>
`;

export const ContractWrapper = ({
  document,
  ...props
}) => (
  <Box
    as="iframe"
    srcDoc={`
        ${customCSSStyle}
        ${document}
      `}
    className="contract-document"
    {...props}
  />
);

export const AIContractWrapper = ({ srcDoc, ...props }) => (
  <Box
    {...styles.contractDocument}
    as="iframe"
    srcDoc={srcDoc}
    {...props}
  />
);
