import React from 'react';
import ContentLoader from 'react-content-loader';

const DailyIncomesLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={300}
    viewBox="0 0 400 300"
    preserveAspectRatio="xMidYMid meet"
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    className="daily-incomes-loader"
  >
    <rect x="3" y="16" rx="3" ry="3" width="88" height="6" />
    <rect x="341" y="18" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="1" y="73" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <rect x="6" y="117" rx="0" ry="0" width="104" height="31" />
    <rect x="144" y="117" rx="0" ry="0" width="104" height="31" />
    <rect x="282" y="117" rx="0" ry="0" width="104" height="31" />
    <rect x="6" y="156" rx="0" ry="0" width="104" height="31" />
    <rect x="144" y="156" rx="0" ry="0" width="104" height="31" />
    <rect x="282" y="156" rx="0" ry="0" width="104" height="31" />
    <rect x="6" y="198" rx="0" ry="0" width="104" height="31" />
  </ContentLoader>
);

const AsideCalendarLoader = () => (
  <ContentLoader
    speed={2}
    width={250}
    height={400}
    viewBox="0 0 250 400"
    preserveAspectRatio="xMidYMid meet"
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    className="calendar-loader"
  >
    <rect x="341" y="18" rx="3" ry="3" width="52" height="6" />
    <rect x="13" y="214" rx="3" ry="3" width="217" height="6" />
    <rect x="31" y="236" rx="3" ry="3" width="178" height="6" />
    <rect x="19" y="314" rx="3" ry="3" width="217" height="6" />
    <rect x="37" y="336" rx="3" ry="3" width="178" height="6" />
    <rect x="23" y="11" rx="0" ry="0" width="201" height="172" />
  </ContentLoader>
);

export { DailyIncomesLoader, AsideCalendarLoader };
