import * as API from 'apis/requisitions';
import { createActions, createAsyncAction } from 'utils/redux-query';

export const parseLimitAmounts = (limitAmounts) => Object.entries(limitAmounts).reduce(
  (result, [requisitionId, limitAmount]) => ({
    ...result,
    [requisitionId]: parseFloat(limitAmount),
  }),
  {},
);

export const fetch = createAsyncAction('REQUISITIONS_TOTAL_ON_FUNDING', () => () => API.getRequisitionsListAmount());

export const fetchDiversification = createAsyncAction(
  'REQUISITIONS_DIVERSIFICATION',
  () => async () => {
    const limitAmounts = await API.fetchDiversification();
    const limitAmountsParsed = parseLimitAmounts(limitAmounts);

    return limitAmountsParsed;
  },
);

const { patch: patchRequisitionsDiversification } = createActions('REQUISITIONS_DIVERSIFICATION');

export const updateLimitAmountByRequisitionId = (requisitionId, amount) => (dispatch, getState) => {
  const currentLimitAmount = getState().REQUISITIONS_DIVERSIFICATION.payload?.[requisitionId];
  const limitAmount = (
    typeof amount === 'function' ? amount(currentLimitAmount) : amount
  ).toString();

  dispatch(
    patchRequisitionsDiversification({ [requisitionId]: limitAmount }, { status: 'COMPLETED' }),
  );
};
