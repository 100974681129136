import React, { useMemo } from 'react';
import get from 'lodash.get';
import { t, toPercentage } from 'i18n';
import { colors } from 'styles';
import { CardLoader } from 'components/Loaders';
import {
  Text,
  Flex,
  Stack,
  HStack,
  VStack,
  StackDivider,
  Link,
} from 'ui';
import AvatarImageElement from '../../Elements/AvatarImageElement';
import { RateStats } from './components';

const FundedStat = ({
  statusRequisition,
  statusColorRequisition,
}) => (
  <HStack borderRadius="xl" bgColor={statusColorRequisition} divider={<StackDivider />} height="12" paddingX="5" spacing="3">
    <Text color="white" fontWeight="bold" fontSize="lg">
      {t('detailRequisition.status')}
      :
    </Text>
    <Text color="white" fontWeight="bold" fontSize="lg" whiteSpace="nowrap">
      {statusRequisition}
    </Text>
  </HStack>
);

const FundingStat = ({
  qualification,
  financingId,
  interestRate,
  lendingRate,
  latePaymentRate,
}) => {
  const colorByScore = useMemo(() => {
    if (!qualification) return colors.gray.g5;
    const score = qualification[0];
    switch (score) {
      case 'A':
        return colors.rates.a;
      case 'B':
        return colors.rates.b;
      case 'C':
        return colors.rates.c;
      default:
        return colors.gray.g5;
    }
  }, [qualification]);

  return (
    <Stack>
      <HStack
        spacing="3"
        bgColor={colorByScore}
        borderRadius="xl"
        divider={<StackDivider />}
        padding="4"
        width="full"
        justifyContent="space-evenly"
      >
        <Stack flexWrap={1}>
          <Text fontSize="3xl" color="white" fontWeight="bold">
            {qualification}
          </Text>
          <Text as="legend" color="white" textAlign="center">Calif.</Text>
        </Stack>
        <Stack flexWrap={1}>
          <Text fontSize="3xl" color="white" fontWeight="bold">
            {toPercentage(financingId === '4' ? interestRate : lendingRate, { precision: 2 })}
          </Text>
          <Text as="legend" color="white" textAlign="center" whiteSpace="nowrap">Tasa ordinaria</Text>
        </Stack>
        {latePaymentRate
          && (
          <Stack flexWrap={1}>
            <Text fontSize="3xl" color="white" fontWeight="bold">
              {toPercentage(latePaymentRate, { precision: 2 })}
            </Text>
            <Text
              as="legend"
              color="white"
              textAlign="center"
              whiteSpace="nowrap"
            >
              Tasa moratoria
            </Text>
          </Stack>
          )}
      </HStack>
      <Link
        textAlign="center"
        target="_blank"
        href="https://www.yotepresto.com/tasas-comisiones#investor"
      >
        Conocer más
      </Link>
    </Stack>
  );
};

const AvatarQualificationInterestStatus = (props) => {
  const { isLoading, isFundedRequisition, dataReq } = props;
  const gender = get(dataReq, 'gender');
  const username = get(dataReq, 'username');
  const generalDetails = get(dataReq, 'general_details');
  const qualification = get(generalDetails, 'calificacion');
  const interestRate = get(generalDetails, 'tasa_acreditado');
  const lendingRate = get(generalDetails, 'tasa_prestamista');
  const financingId = get(generalDetails, 'financing_id');
  const statusRequisition = get(dataReq, 'humanized_status');
  const statusColorRequisition = get(dataReq, 'status_color');
  const latePaymentRate = get(dataReq, 'late_payment_rate');

  if (isLoading) return <CardLoader />;

  return (
    <VStack paddingTop="5" spacing="5">
      <Flex alignItems="center" columnGap="5">
        <AvatarImageElement
          gender={gender}
          username={username}
          funded={isFundedRequisition}
          loading={isLoading}
        />
        {isFundedRequisition ? (
          <FundedStat
            statusRequisition={statusRequisition}
            statusColorRequisition={statusColorRequisition}
          />
        ) : (
          <FundingStat
            qualification={qualification}
            financingId={financingId}
            interestRate={interestRate}
            lendingRate={lendingRate}
            latePaymentRate={latePaymentRate}
          />
        )}
      </Flex>
      {isFundedRequisition && (
        <RateStats
          lendingRate={lendingRate}
          financingId={financingId}
          interestRate={interestRate}
          qualification={qualification}
          latePaymentRate={latePaymentRate}
        />
      )}
    </VStack>
  );
};

export default AvatarQualificationInterestStatus;
