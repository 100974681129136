import React from 'react';
import { t } from 'i18n';
import { DiversificationNoticeText, KnowMoreSpan } from './styles';

const DiversificationDescriptions = () => (
  <DiversificationNoticeText>
    {t('listRequisition.diversification.description')}
    <KnowMoreSpan href={t('listRequisition.diversification.knowMoreLink')} target="_blank">
      {t('listRequisition.diversification.knowMore')}
    </KnowMoreSpan>
  </DiversificationNoticeText>
);

export default DiversificationDescriptions;
