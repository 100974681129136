import { useCallback } from 'react';
import { showToastError, showToastInfo } from 'utils/toastHandler';
import { tAlerts } from '../constants';
import { useDelayGenerate } from './useDelayGenerate';
import { useGenerateAccountStatement } from './graphql';

export const useRequestLastAccountStatement = ({
  isAccountStatementAvailable,
}) => {
  const {
    saveRequestTime,
    isAvailableToRequest,
  } = useDelayGenerate({
    isAccountStatementAvailable,
  });

  const onGenerateAccountStatementCompleted = useCallback(() => {
    saveRequestTime();
    showToastInfo(tAlerts('info.accountStatementGenerated'));
  }, []);

  const onGenerateAccountStatementError = useCallback(() => {
    saveRequestTime();
    showToastError(tAlerts('errors.generateFailed'));
  }, [saveRequestTime]);

  const [
    requestLastAccountStatementMutation,
    {
      loading: isLoading,
    },
  ] = useGenerateAccountStatement({
    onCompleted: onGenerateAccountStatementCompleted,
    onError: onGenerateAccountStatementError,
  });

  const requestLastAccountStatement = () => {
    requestLastAccountStatementMutation({
      variables: {
        input: {},
      },
    });
  };

  return {
    isLoading,
    isAvailableToRequest,
    requestLastAccountStatement,
  };
};
