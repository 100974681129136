import styled from 'styled-components';

export const autoInvestDocument = styled.div`
  height: 300px;
  background: #fafafa;
  padding: 20px;
  overflow: auto;
  color: #677d85;
  font-size: 1.4rem;

  & .text-center {
    text-align: center;
    font-size: 1.6rem;
  }
  & ol {
    padding-inline-start: 2rem !important;
  }
  & ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  & a {
    &:first-of-type {
      color: #0076ff;
      transition: all 0.4s ease;
      &:hover {
        color: #00c574;
      }
    }
  }

  p {
    font-size: 13px;
    line-height: 180%;
    margin: 0 0 10px;
  }
  .h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
    margin: 1.5rem 0 0.5em;
  }
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;

    td {
      padding: 8px;
      line-height: 1.5;
      vertical-align: top;
      border-top: 1px solid #ddd;
    }
  }
`;

export const styles = {
  contractDocument: {
    overflowY: 'scroll',
    padding: '10px',
  },
};
