import React, {
  useMemo, useRef, useEffect,
} from 'react';
import {
  Redirect, Switch, withRouter, Route,
} from 'react-router-dom';
import PATHS, {
  dashboardRoute,
  investorTransactions,
  investorRemoveFunds,
  investorAddFunds,
  investorSettings,
  animoZapopanRoute,
} from 'routes';
import { useFeatureFlag } from 'utils/featureFlags';
import { useReduxLazyQuery, useReduxQuery, useReduxSubscription } from 'utils/redux-query';
import { USER_STATUSES } from 'config/constants';
import AnimoZapopan from '../AnimoZapopan';
import ContentArea from './styles';
import Summary from './Summary';
import MyMovements from './MyMovements';
import AddFunds from './AddFunds';
import Requisitions from './Requisitions';
import PortFolio from './Portfolio';
import Settings from './Settings';
import Calendar from './Calendar';
import Orders from './Orders';
import ConfirmIdentity from './Regulation/ConfirmIdentity';
import Expedients2 from './Expedients2';
import { contentAreaContext } from './DashboardContent.context';
import { Withdrawal } from './Withdrawal';

const {
  investorRequisitionsPath,
  investorPortfolioPath,
  investorOrdersPath,
  investorCalendarPath,
  investorConfirmIdentityPath,
  cnbvPath,
} = PATHS;

const DashboardContent = (props) => {
  const { isMobile } = props;
  const [session] = useReduxSubscription('SESSION');
  const user = session?.user || {};
  const [totalRequisitions, { status: fetchingTotalReqOnFunding }] = useReduxQuery(
    'REQUISITIONS_TOTAL_ON_FUNDING',
  );
  const [, { lazyQuery: requisitionsDiversificationLazyQuery }] = useReduxLazyQuery(
    'REQUISITIONS_DIVERSIFICATION',
  );

  const featureFlags = useFeatureFlag();

  const contentAreaRef = useRef(null);

  const fallBackPath = useMemo(() => {
    if (!fetchingTotalReqOnFunding.completed) {
      return null;
    }

    if (featureFlags.Requisitions && totalRequisitions > 0) {
      return investorRequisitionsPath();
    }

    return dashboardRoute;
  }, [totalRequisitions, fetchingTotalReqOnFunding.completed]);

  useEffect(() => {
    if (
      user?.status === USER_STATUSES.AUTHORIZED
      && user?.needsDiversification
    ) {
      requisitionsDiversificationLazyQuery();
    }
  }, [user?.status, user?.needsDiversification]);

  return (
    <ContentArea
      isMobile={isMobile}
      id="contentArea"
      ref={contentAreaRef}
      data-testid="dashboard-content"
    >
      <contentAreaContext.Provider value={{ contentAreaRef: contentAreaRef.current }}>
        <Switch>
          {featureFlags.Dashboard && (
            <Route exact path={dashboardRoute} render={Summary} />
          )}
          {featureFlags.Requisitions && (
            <Route path={investorRequisitionsPath()} component={Requisitions} />
          )}
          {featureFlags.Portfolio && (
            <Route path={investorPortfolioPath()} component={PortFolio} />
          )}
          {featureFlags.Transactions && (
            <Route exact path={investorTransactions} component={MyMovements} />
          )}
          {featureFlags.ScheduledPayments && (
            <Route path={investorCalendarPath()} component={Calendar} />
          )}
          {featureFlags.FundingAccount && (
            <Route exact path={investorAddFunds} component={AddFunds} />
          )}
          {featureFlags.Withdraw && (
            <Route exact path={investorRemoveFunds} component={Withdrawal} />
          )}
          {featureFlags.needsUpdateInformation && (
            <Route path={cnbvPath()} component={Expedients2} />
          )}
          <Route path={investorOrdersPath()} component={Orders} />
          <Route path={investorConfirmIdentityPath()} component={ConfirmIdentity} />
          <Route path={investorSettings} component={Settings} />
          <Route exact path={animoZapopanRoute} component={AnimoZapopan} />
          {fallBackPath && <Redirect to={fallBackPath} />}
        </Switch>
      </contentAreaContext.Provider>
    </ContentArea>
  );
};

export default withRouter(DashboardContent);
