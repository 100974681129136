import { useAuthorizeForm } from 'hooks/Authorizations';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';

export const useController = ({
  onSuccess = () => {},
}) => {
  const {
    onError,
    control,
    handleSubmit,
  } = useAuthorizeForm();
  const [autoInvestmentConfigs, { isLoading: isFetchingLoading }] = useReduxQuery('FETCH_ALL_AUTO_INV');
  const [toggleAIActivation, { isLoading: isToggleLoading }] = useReduxMutator(
    'TOGGLE_ACTIVATION_AUTO_INV',
    {
      onError,
      onSuccess,
    },
  );
  const currentAutoinvestConfig = autoInvestmentConfigs?.[0] || null;
  const isLoading = isFetchingLoading || isToggleLoading;
  const isAutoinvestActive = currentAutoinvestConfig?.active;

  const onSubmit = handleSubmit(async ({ password }) => {
    toggleAIActivation({
      autoinvestId: currentAutoinvestConfig?.id,
      password,
    });
  });

  return {
    control,
    onSubmit,
    isLoading,
    isAutoinvestActive,
  };
};
