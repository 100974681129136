const getColorStatus = (transaction) => {
  const { total } = transaction;
  if (total > 0) return 'green.500';
  if (total < 0) return 'red.500';
  return 'gray.500';
};

const breakDateFormat = (transaction) => {
  const { fecha } = transaction;
  return {
    date: fecha.slice(0, 10),
    hour: fecha.slice(11, 19),
  };
};

export const parseMovements = (movements) => {
  const { collection } = movements;
  const transactions = collection.map((transaction) => ({
    id: transaction.autorizacion,
    type: transaction.tipo,
    amount: transaction.total,
    reference: transaction.referencia,
    balance: transaction.saldo,
    account: transaction.account?.slice(-4)?.padStart(7, '.'),
    origin: transaction.origin,
    statusColor: getColorStatus(transaction),
    uid: transaction.uid,
    ...breakDateFormat(transaction),
  }));
  return transactions;
};
