import { useCallback } from 'react';
import { useUploadAddress } from './useUploadAddress';

export const useFileUploaderOptions = ({
  onUpload = () => {},
  onUploadSuccess = () => {},
} = {}) => {
  const {
    progress,
    uploadDocument,
    isFinished,
    isLoading: isUploading,
  } = useUploadAddress();

  const onSubmitFile = useCallback(async (file) => {
    onUpload();
    await uploadDocument({ file });
    onUploadSuccess();
  }, [onUpload, onUploadSuccess, uploadDocument]);

  return {
    progress,
    isFinished,
    isUploading,
    onSubmitFile,
  };
};
