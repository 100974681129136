import { useController } from 'react-hook-form';
import { filterStrangeChars, singleWhiteSpace } from 'utils/strings';

const parsedValue = (value, parseType) => {
  if (!value) return '';

  if (parseType === 'alphanumeric') {
    const filteredValue = filterStrangeChars(value);
    const cleanValue = singleWhiteSpace(filteredValue);
    return cleanValue;
  }
  return value;
};

export const handleOnChange = ({
  e,
  isFile,
  onChange,
  parseType,
  onChangeProp,
  isCheckboxGroup,
}) => {
  if (onChangeProp) onChangeProp(e);
  if (isFile) {
    const { files } = e.target;
    return onChange({
      fileName: e.target.value,
      files,
    });
  }
  if (isCheckboxGroup) {
    return onChange(e);
  }
  const originalValue = typeof e === 'string' ? e : e?.target?.value;
  const parsedInputValue = parsedValue(originalValue, parseType);
  return onChange(parsedInputValue);
};

export const useFieldController = ({
  name,
  isFile,
  control,
  parseType,
  isCheckboxGroup,
  defaultValue = '',
  onChange: onChangeProp,
}) => {
  const {
    field,
    fieldState: {
      error,
    },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onChange = (e) => handleOnChange({
    e,
    isFile,
    parseType,
    onChangeProp,
    isCheckboxGroup,
    onChange: field.onChange,
  });

  const value = isFile ? '' : field.value;

  return {
    ...field,
    value,
    error,
    onChange,
  };
};
