import React from 'react';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { tscope } from 'i18n';
import {
  Box,
  Grid,
  Flex,
  Button,
  Heading,
  Divider,
  DateField,
  TextField,
  CheckboxField,
  ChoiceSelector,
} from 'components';
import {
  CountrySelect,
  LocationPartialForm,
  ZipField,
} from 'components/Form';
import { relationshipTypes } from 'catalog/relationship';
import { singleWhiteSpace } from 'utils/strings';
import { FocusField } from 'components/FocusField';
import { useController } from './hooks';
import { styles } from './BeneficiaryForm.styles';

const t = tscope('settings.account.beneficiaryForm');
const MAX_AGE = 99;
const startAtYear = new Date().getFullYear() - MAX_AGE;
const relationOtherType = relationshipTypes.find(({ value }) => value === 'other');
const parseText = (string) => singleWhiteSpace(string);

export const BeneficiaryForm = ({
  onBeneficiaryUpdated,
}) => {
  const {
    onSubmit,
    validator,
    isLoading = false,
    onLoadingZIP,
    initialValues,
  } = useController({
    onBeneficiaryUpdated,
  });

  return (
    <Box {...styles.container}>
      <Form
        validate={validator}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({
          form,
          errors,
          values,
          validating,
          handleSubmit,
        }) => (
          <Grid
            as="form"
            onSubmit={handleSubmit}
            {...styles.form}
          >
            <FocusField form={form} errors={errors} />
            <Heading
              as="h4"
              t={t}
              i18nKey="sections.name"
            />
            <Heading
              as="p"
              t={t}
              i18nKey="sections.nameDescription"
            />
            <TextField
              name="name"
              textLabel={t('fields.name.label')}
              disabled={isLoading}
              parse={parseText}
            />
            <Heading
              as="h4"
              t={t}
              i18nKey="sections.birth"
            />
            <DateField
              name="birthDate"
              startingYearAt={startAtYear}
              spanYears={MAX_AGE}
              disabled={isLoading}
              data-testid="beneficiary.birthDate"
            />

            <Heading
              as="h4"
              t={t}
              i18nKey="sections.relationshipType"
            />
            <Box>
              <ChoiceSelector
                name="relationshipType"
                options={relationshipTypes}
                disabled={isLoading}
                data-testid="beneficiary.relationshipType"
              />
              {
                values.relationshipType === relationOtherType.value && (
                  <TextField
                    name="relationshipTypeOther"
                    textLabel={t('fields.relationshipType.label')}
                    disabled={isLoading}
                    parse={parseText}
                  />
                )
              }
            </Box>

            <Heading
              as="h4"
              t={t}
              i18nKey="sections.address"
            />
            <CheckboxField
              id="isForeignPerson"
              name="isForeignPerson"
              textLabel={t('fields.isForeignPerson.label')}
              disabled={isLoading}
              checkboxType="checkbox"
              validateFields={['zip']}
              onChange={(checked) => (checked ? form.change('country', '') : null)}
            />
            {
              /* Foreign Fields */
              values.isForeignPerson && (
                <>
                  <CountrySelect
                    name="country"
                    textLabel={t('fields.country.label')}
                    ignoreCountries={['MX']}
                    disabled={isLoading}

                  />
                  <TextField
                    name="city"
                    textLabel={t('fields.city.label')}
                    disabled={isLoading}

                  />
                </>
              )
            }

            <TextField
              {...styles.field}
              name="street"
              textLabel={t('fields.street.label')}
              disabled={isLoading}
            />

            <Grid {...styles.numberFields}>
              <TextField
                {...styles.field}
                name="extNumber"
                maxLength={6}
                textLabel={t('fields.extNumber.label')}
                disabled={isLoading}
              />
              <TextField
                {...styles.field}
                name="intNumber"
                maxLength={6}
                textLabel={t('fields.intNumber.label')}
                disabled={isLoading}
              />
              <ZipField
                {...styles.field}
                name="zip"
                formControl={form}
                disabled={isLoading}
                textLabel={t('fields.zip.label')}
                shouldValidate={values.zip && !values.isForeignPerson}
              />
            </Grid>

            {values.zip && !values.isForeignPerson && (
              <LocationPartialForm
                zipName="zip"
                formControl={form}
                onLoading={onLoadingZIP}
              />
            )}

            <Divider {...styles.divider} />

            <Flex {...styles.buttonGroup}>
              <Button
                $type="btn-link"
                disabled={isLoading}
                as={Link}
                to="../"
              >
                {t('actions.cancel')}
              </Button>
              <Button
                type="submit"
                disabled={isLoading || validating}
              >
                {t('actions.submit')}
              </Button>
            </Flex>
          </Grid>
        )}
      </Form>
    </Box>
  );
};
