import React from 'react';
import { connect } from 'react-redux';

import { selectTab, toggleSidebar } from 'actions/dashboard';

import Dashboard from './Dashboard';

const DashboardContainer = ({
  activeTab,
  sidebarOpen,
  selectTabAction,
  toggleSidebarAction,
}) => {
  const setActiveTab = (selectedTab) => {
    selectTabAction(selectedTab);
    toggleSidebarAction(false);
  };

  const handleToggleSidebar = () => {
    toggleSidebarAction(!sidebarOpen);
  };

  return (
    <Dashboard
      selectTab={setActiveTab}
      activeTab={activeTab}
      toggleSidebar={handleToggleSidebar}
      sidebarOpen={sidebarOpen}
    />
  );
};

const mapStateToProps = ({ dashboard }) => {
  const { activeTab, sidebarOpen } = dashboard;
  return { activeTab, sidebarOpen };
};

export default connect(mapStateToProps, {
  selectTabAction: selectTab,
  toggleSidebarAction: toggleSidebar,
})(DashboardContainer);
