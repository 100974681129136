export const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    color: 'gray.500',
    overflow: 'hidden',
    maxWidth: '100%',
    flexShrink: '1',
    textOverflow: 'ellipsis',
  },
  iconsContainer: {
    justifyContent: 'center',
    size: 'sm',
  },
  icon: {
    marginLeft: 1,
    variant: 'ghost',
    colorScheme: 'gray',
    borderColor: 'white',
  },
};
