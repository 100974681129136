import styled from 'styled-components';

export const PieChartWrapper = styled.div`
  max-height: 8rem;
  max-width: 8rem;

  & + [data-test-id='CircularProgressbarWithChildren__children'] {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
  }
`;
