import React from 'react';
import cls from 'classnames';

const Step = ({
  number, name, checked, active, last,
}) => (
  <div className={cls('step', { active, checked })}>
    <div className={checked ? 'checkmark' : 'badge'}>
      {!checked && number}
      {!last && <div className="separator" />}
    </div>
    <div className="name">{name}</div>
  </div>
);

export default Step;
