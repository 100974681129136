import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { smallBreakPoint } = breakPoints;

const MovementsFilterDiv = ({
  isMobile, onToggleF, onToggleFilter, ...otherProps
}) => (
  <div {...otherProps} />
);

const MovementsFilterContainer = styled(MovementsFilterDiv)`
  width: 30rem;
  display: flex;
  opacity: ${(props) => (props.onToggleF ? '1' : '0')};
  left: ${(props) => (props.onToggleF ? '20rem' : '-30rem')};
  ${(props) => (!props.isMobile ? 'left: 20rem; opacity:1;' : null)}
  transition: all 0.5s;
  height: 100%;
  background-color: ${colors.gray.g1};
  position: fixed;
  z-index: 10;
  flex-direction: column;
  @media (max-width: ${breakPoints.midBreakPoint}px) {
    left: ${(props) => (props.onToggleF ? '7rem' : '-30rem')};
    ${(props) => (!props.isMobile ? 'left: 7rem; opacity:1;' : null)}
  }
  @media (max-width: 700px) {
    left: ${(props) => (props.onToggleF ? '0rem' : '-30rem')};
    ${(props) => (!props.isMobile ? 'left: 0rem; opacity:1;' : null)}
  }
  .InfoWidgetContainer {
    padding: 2rem;
    @media (max-height: ${smallBreakPoint}px) {
      padding: 0 2rem 2rem 2rem;
    }
    .card {
      padding: 0;
    }
  }
  .closeFilter {
    margin-right: 1.4rem;
    float: right;
    font-size: 1.2rem;
    transition: all 0.3s;
    :hover {
      cursor: pointer;
      transform: translateY(-2px);
    }
    svg {
      width: 1.2rem;
    }
  }
  padding: 0;
  & .titleFilter {
    margin-top: 1.2rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    font-weight: 500;
    color: ${colors.gray.g5};
  }
  & hr {
    width: 100%;
    margin-bottom: 1rem;
    border-top: 1px solid ${colors.gray.g2};
  }
  & .formContainer {
    padding: 0 1.5rem;
    & .fieldGroup {
      display: flex;
      margin: 1.5rem -1rem;
      & .dateFilterLabel {
        font-size: 1.4rem;
        margin-left: 1rem;
        color: ${colors.gray.g5};
        font-weight: 500;
      }
      & .react-datepicker-wrapper {
        flex: 1;
        margin-bottom: 1rem;
        display: flex;
        & .react-datepicker__input-container {
          padding: 0 1rem;
          display: flex;
        }
        & input {
          width: 100%;
          font-size: 1.4rem;
          padding: 1.2rem;
          border-radius: 0.6rem;
          outline: none;
          border: 1px ${colors.gray.g3} solid;
          transition: all 0.4s ease;
          &:focus {
            border-color: ${colors.blue};
          }
        }
      }
      & .react-datepicker {
        font-size: 1rem;
        border: 0;
        box-shadow: 0 2px 6px 0 rgba(37, 103, 141, 0.14);
        &__header {
          background-color: ${colors.gray.g1};
          border-bottom: 0;
        }
        &__triangle {
          border-bottom-color: ${colors.gray.g1};
          &:before {
            border-bottom-color: ${colors.gray.g1};
            top: 0;
          }
        }
        &__navigation {
          margin: 1.2rem auto;
          &--previous {
            border-right-color: ${colors.gray.g3};
          }
          &--next {
            border-left-color: ${colors.gray.g3};
          }
        }
        &__current-month {
          color: ${colors.gray.g6};
          font-size: 1.2rem;
          margin: 1.2rem auto;
          text-transform: capitalize;
        }
        &__day-name {
          margin: 0.4rem;
          width: 2.6rem;
          height: 2.6rem;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colors.gray.g6};
          text-transform: capitalize;
        }
        &__day {
          margin: 0.4rem;
          width: 2.6rem;
          height: 2.6rem;
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 2.6rem;
          color: ${colors.gray.g5};
          border-radius: 1.3rem;
          &--disabled {
            color: ${colors.schema.gray[300]};
          }
          &--today {
            background-color: ${colors.alerts.info};
            font-weight: 700;
            color: ${colors.blue};
          }
          &--selected,
          &--keyboard-selected {
            color: white;
            background-color: ${colors.blue};
            font-weight: 700;
          }

          &--outside-month {
            color: ${colors.gray.g3};
          }
          &:hover {
            background-color: ${colors.gray.g1};
            color: ${colors.gray.g7};
          }
          &:focus {
            outline: 0;
          }
        }
      }
    }
    & .selectContainer {
      margin-bottom: 2rem;
      label {
        padding: 0;
      }
      select {
        border-radius: 0.6rem;
      }
    }
    & .buttonGroup {
      display: flex;
      justify-content: space-around;
      margin-bottom: 2rem;
      & button {
        width: 13rem;
      }
    }
  }
`;

export default MovementsFilterContainer;
