import { useQuery, gql } from '@apollo/client';

export const USER_GQL = gql`
query {
  user {
    id
    flags {
      id
      isAccountStatementAvailable
    }
    accountStatements {
      id
      periodStart
    }
  }
}
`;

export const useUser = (options) => useQuery(USER_GQL, options);
