import React, { forwardRef } from 'react';
import SelectField from 'components/SelectField/SelectField';
import { useReduxQuery } from 'utils/redux-query';

const StateSelectField = forwardRef((props, ref) => {
  const { name, ...contentProps } = props;
  const [catalog] = useReduxQuery('CATALOG');

  if (!catalog) return null;

  return (
    <SelectField
      name={name}
      variant="ghost"
      ref={ref}
      iconName="CHEVRON_DOWN"
      {...contentProps}
      format={undefined}
    >
      {catalog.states.map(({ id, name: stateName }) => (
        <option key={id} value={id}>
          {stateName}
        </option>
      ))}
    </SelectField>
  );
});

export default StateSelectField;
