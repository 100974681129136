import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { isBrowserSupported } from 'config/browser-support';
import { onMaintenance, isProductionEnv } from 'config';
import { loginRoute } from 'routes';
import { UnsupportedBrowser } from 'views/UnsupportedBrowser/styles';
import Login from 'views/Login';
import Maintenance from 'views/Maintenance';

const browserOk = isBrowserSupported();

export const Guest = () => {
  const inMaintenance = useSelector(({ auth }) => auth.inMaintenance);

  if (!browserOk) return <UnsupportedBrowser />;

  if (onMaintenance() || inMaintenance) return <Maintenance />;

  return <Switch>{!isProductionEnv() && <Route path={loginRoute} component={Login} />}</Switch>;
};
