export const getModalStyles = ({ overlayStyle, contentStyle }) => ({
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1001,
    position: 'fixed',
    width: '100%',
    backgroundColor: 'rgba(5, 27, 39, 0.8)',
    ...(overlayStyle || {}),
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 'none',
    maxHeight: '80%',
    maxWidth: '600px',
    width: '90%',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    backgroundColor: 'white',
    ...(contentStyle || {}),
  },
});
