import { useMemo } from 'react';
import { useClient } from './useClient';

export const useChannel = ({
  channelName,
  credentials,
}) => {
  const { client, connectionState } = useClient({ credentials });

  const channel = useMemo(() => {
    if (!client || !channelName || connectionState?.current !== 'connected') return null;
    return client.subscribe(channelName);
  }, [
    client,
    channelName,
    connectionState,
  ]);

  return {
    channel,
  };
};
