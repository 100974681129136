import React, { useEffect } from 'react';
import Link from 'components/Link';
import get from 'lodash.get';
import moment from 'moment';
import { connect } from 'react-redux';
import { toggleDetails } from 'actions/dashboard';
import {
  getPaymentSchedule,
  setDayAmount,
  setCurrentDate,
  toggleAsideCalendar,
} from 'actions/calendar/calendarActions';
import { ReactComponent as CircleArrow } from 'assets/circleArrow.svg';
import PATHS from 'routes';
import ButtonIcon from 'components/Button/ButtonIcon';
import { Text } from 'ui';
import EmptyState from '../EmptyState';
import DailyIncomesContainer from './styles';

const { investorCalendarRequisitionPath } = PATHS;

moment().locale('es');

function DayItem({ item }) {
  return (
    <Link
      to={investorCalendarRequisitionPath({ requisitionId: item.solicitud })}
      className="day-item"
    >
      $
      {item.monto}
    </Link>
  );
}

function DailyIncomes({
  currentMonth,
  lastDay,
  date,
  getNextSchedule,
  summary,
  setDate,
  setAmount,
  toggleCalendar,
}) {
  useEffect(() => {
    if (currentMonth && date.day && currentMonth[`${date.day}`]) {
      const amount = currentMonth[`${date.day}`].reduce((value, item) => value + Number(item.monto), 0);
      setAmount(`${amount}`);
    } else if (!currentMonth[`${date.day}`]) {
      setAmount(0);
    }
  });

  const nextDate = (ev) => {
    ev.preventDefault();
    const nextDay = parseInt(date.day, 10) + 1;
    if (lastDay >= nextDay) {
      setDate({ day: nextDay.toString().padStart(2, '0') });
    } else {
      const nDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-D').add(1, 'day');
      const nextYear = nDate.format('YYYY');
      const nextMonth = nDate.format('MM');
      getNextSchedule({ year: nextYear, month: nextMonth, day: '01' });
    }
  };

  const prevDate = (ev) => {
    ev.preventDefault();
    let prevDay = parseInt(date.day, 10) - 1;
    if (prevDay > 0) {
      setDate({ day: prevDay.toString().padStart(2, '0') });
    } else {
      const pDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-D')
        .subtract(1, 'day')
        .endOf('month');
      const prevYear = pDate.format('YYYY');
      const prevMonth = pDate.format('MM');
      prevDay = pDate.format('DD');
      getNextSchedule({ year: prevYear, month: prevMonth, day: prevDay });
    }
  };

  const renderDayPayments = (payments) => (
    <div className="day-items">
      {payments.map((item) => (
        <DayItem item={item} key={`${item['@diffgr:id']}`} />
      ))}
    </div>
  );

  const { year, month, day } = date;
  const currentDate = moment(`${year}-${month}-${day}`).locale('es');
  const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;
  const dayPayments = get(currentMonth, [day]) || [];
  const hasPayments = dayPayments.length > 0;

  return (
    <DailyIncomesContainer className="daily-incomes">
      <div className="day-header">
        <div className="group">
          <h3 className="title-large">
            Pagos
            {' '}
            {date.day}
            {' '}
            de
            {' '}
            {summary.mes}
          </h3>
          <h3 className="title-short">
            Pagos
            {' '}
            {date.day}
            {' '}
            {moment(`${date.month}`, 'MM').format('MMMM')}
          </h3>
          <div className="arrows">
            <CircleArrow title="" onClick={prevDate} className="left-arrow" />
            <CircleArrow title="" onClick={nextDate} className="right-arrow" />
          </div>
        </div>
        <ButtonIcon iconName="CALENDAR" className="calendar-circle" onClick={toggleCalendar} />
        {isWeekend && hasPayments && (
          <Text display={{ base: 'none', md: 'block' }} mx="1rem">El pago se cobrará el siguiente día hábil</Text>
        )}
      </div>
      {hasPayments ? renderDayPayments(dayPayments) : <EmptyState />}
    </DailyIncomesContainer>
  );
}

const mapStateToProps = ({ calendar }) => ({
  currentMonth: calendar.currentMonth,
  lastDay: calendar.lastDay,
  date: calendar.date,
  summary: calendar.summary,
});

const mapDispatchToProps = {
  displayDetailState: toggleDetails,
  setDate: setCurrentDate,
  getNextSchedule: getPaymentSchedule,
  setAmount: setDayAmount,
  toggleCalendar: toggleAsideCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyIncomes);
