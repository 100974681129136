import { useModalDisclosure } from 'hooks/ui';
import { useEffect, useState } from 'react';
import { useReduxQuery } from 'utils/redux-query';
import { parseMovements } from '../utils';

export const useController = () => {
  const [movements] = useReduxQuery('MOVEMENTS');
  const transactions = parseMovements(movements);
  const [transactionVoucher, setTransactionVoucher] = useState(null);
  const onCloseVoucherModal = () => { setTransactionVoucher(null); };
  const voucherProps = useModalDisclosure({ onClose: onCloseVoucherModal });

  useEffect(() => {
    if (transactionVoucher) voucherProps.onOpen();
    else voucherProps.onClose();
  }, [transactionVoucher, voucherProps]);

  return {
    transactions,
    voucherProps,
    transactionVoucher,
    onSetTransactionVoucher: setTransactionVoucher,
  };
};
