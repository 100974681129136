import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { Text } from 'components/Typography';

const ContainerDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;

export const UserNameText = styled(Text)`
  display: block;
  font-size: 2.6rem;
`;

export const AccountProfileContainer = styled(ContainerDiv)`
  width: 100%;
  overflow: hidden;

  .user-information {
    display: flex;
    flex-direction: row;
    margin: 8rem 0;
    font-size: 1.2rem;

    @media (max-width: ${breakPoints.largeBreakPoint}px) {
      margin: 2rem 0;
      flex-direction: column-reverse;
    }

    & .avatar {
      height: 6.6rem;
      width: 6.2rem;
    }

    .data-group {
      display: flex;
      flex: 1;

      & .user-name {
        font-size: 2.6rem;
        color: #00485e;
        margin-left: 2rem;
        & p {
          padding-right: 1rem;
        }
        & button {
          border: none;
          outline: none;
          background-color: transparent;
          color: #0076ff;
          font-size: 1.4rem;
          &: hover {
            cursor: pointer;
          }
        }
      }

      &.user-data {
        flex: 2;
      }

      &.session-data {
        flex: 1;
        flex-direction: column;
        align-items: flex-end;
        font-size: 1.333rem;
        color: #879ea8;
        margin-bottom: 0;
        & p:first-of-type {
          margin-bottom: 1.5rem;
        }
        @media (max-width: ${breakPoints.largeBreakPoint}px) {
          & p:first-of-type {
            margin-bottom: 1rem;
          }
          margin-bottom: 2rem;
        }
      }
    }
  }
  & .account--profile {
    &__content {
      margin: auto;
      width: 90%;
      max-width: 780px;
      & .card {
        padding: 0;
        box-shadow: -1px 0px 9px 2px rgba(37, 103, 141, 0.04);
        &__header {
          background-color: #f8fafc;
          color: #677d85;
          font-size: 1.6rem;
          padding: 2rem;
        }
        &__body {
          color: #677d85;
          font-size: 1.333rem;
          padding: 2rem;
          & .description {
            margin-bottom: 1rem;
          }
          & .float-left {
            float: left;
          }
          & .float-right {
            float: right;
          }
          & .action__button {
            margin-bottom: 1rem;
          }
          & .add-beneficiary-btn {
            display: flex;
            justify-content: center;
            margin: 0 auto;

            @media (max-width: ${breakPoints.smallBreakPoint}px) {
              width: 100%;
            }
          }
          & button {
            font-size: 1.5rem;
            padding: 1.2rem 3rem;
          }
          & .toggle__button {
            &__container {
              display: flex;
              align-items: center;
              & p {
                margin-left: 2rem;
              }
            }
            &__body {
              margin-left: auto;
              margin-right: 2rem;
            }
          }
          .invoice-title {
            font-size: 1.6rem;
          }
        }
        &__footer {
          background-color: #f8fafc;
          padding: 2rem;
          font-size: 1.33rem;
          color: #c2d4db;
        }
      }
    }
  }
`;
