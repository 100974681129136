import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { t } from 'i18n';
import { Grid } from 'styles';
import { pipe } from 'utils/Monads';
import Button from 'components/Button/Button';
import CheckboxField from 'components/CheckboxField/CheckboxField';
import DateField from 'components/DateField/DateField';
import Link from 'components/Link';
import SelectField from 'components/SelectField/SelectField';
import CountrySelectField from 'components/CountrySelectField/CountrySelectField';
import { Heading } from 'components/Typography';
import { controllerContext } from 'views/DashboardContent/Expedients/Profile/controller/context';
import Divider from 'components/Divider';
import { USER } from 'config/constants';
import { useGetCountryById } from 'views/DashboardContent/Expedients/hooks';
import { Content } from './Birth.styles';
import validator from './validator';

const tOptions = { scope: 'expedientsProfileUpdate.birth' };

function Birth() {
  const history = useHistory();
  const {
    profile,
    trackStep,
    setProfile,
    STEP_NAMES,
    statesCatalog,
  } = useContext(controllerContext);
  const { getCountryNameByName, getCountryById } = useGetCountryById();

  const initialValues = {
    birthDate: profile?.birthDate,
    state: profile?.state?.id.toString() || '',
    isNotMexican: profile?.isNotMexican || false,
    country: getCountryNameByName(profile?.country)?.id || '',
  };

  const handleOnSubmit = (values) => {
    const {
      birthDate, state: stateID, country, isNotMexican,
    } = values;

    if (isNotMexican) {
      setProfile((currentProfile) => ({
        ...currentProfile,
        isNotMexican,
        country: getCountryById(country).name,
        state: null,
        birthDate,
      }));
    } else {
      const state = statesCatalog.find(({ id }) => id.toString() === stateID);
      const newProfile = (currentProfile) => ({
        ...currentProfile,
        birthDate,
        state,
        isNotMexican,
        country: null,
      });

      setProfile(pipe(newProfile));
    }

    trackStep({ name: STEP_NAMES.BIRTH, hasUserTakenForm: true });
    history.push('../show');
  };

  return (
    <Content>
      <div>
        <Heading mb="1rem">{t('title', tOptions)}</Heading>
        <Heading as="p">{t('subtitle', tOptions)}</Heading>
      </div>
      <Form onSubmit={handleOnSubmit} initialValues={initialValues} validate={validator}>
        {({ handleSubmit, values }) => (
          <Grid as="form" onSubmit={handleSubmit} templateColumns="1fr 1fr" rowGap="2rem">
            <DateField
              name="birthDate"
              gridColumn="1/3"
              startingYearAt={new Date().getFullYear() - USER.MAX_AGE}
              spanYears={USER.MAX_AGE - USER.MIN_AGE}
            />
            <CheckboxField
              id="nationality"
              name="isNotMexican"
              textLabel={t('form.isMexicanField.textLabel', tOptions)}
              color="gray.g4"
              gridColumn="1/3"
            />
            <SelectField
              name="state"
              disabled={values.isNotMexican}
              textLabel={t('form.stateField.textLabel', tOptions)}
              contentProps={{
                gridColumn: '1/3',
              }}
            >
              <option value="">-</option>
              {statesCatalog?.map((state) => (
                <option value={state.id} key={state.id}>
                  {state.name}
                </option>
              ))}
            </SelectField>
            {values.isNotMexican && (
              <CountrySelectField
                name="country"
                textLabel={t('form.countryField.textLabel', tOptions)}
                gridColumn="1/3"
                placeholder={t('form.countryField.placeholder', tOptions)}
                searchPlaceholder={t('form.countryField.placeholderSearch', tOptions)}
              />
            )}
            <Divider w="100%" color="schema.gray.300" gridColumn="1 / -1" />
            <Button as={Link} to="../show" $type="btn-link" gridColumn="1/1" justifySelf="start">
              {t('form.actions.cancel', tOptions)}
            </Button>
            <Button type="submit" justifySelf="end" gridColumn="2/2">
              {t('form.actions.submit', tOptions)}
            </Button>
          </Grid>
        )}
      </Form>
    </Content>
  );
}

export default Birth;
