import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import { colors } from 'styles';

const { extraLargeBreakPoint, midBreakPoint, extraSmallBreakPoint } = breakPoints;

const RequisitionInfoGroupContainer = styled.div`
  margin-top: 5.5rem;
  margin-bottom: 2rem;
  @media (max-width: ${midBreakPoint}px) {
    margin-top: 0;
  }
  &.funded {
    padding-top: 1.5rem;
    margin: 2.5rem auto 5.5rem;
    @media (max-width: ${midBreakPoint}px) {
      border-top: 1px solid ${colors.gray.g2};
      width: 100%;
      margin: 0 0 3.5rem;
    }
    .groupItems {
      @media (max-width: ${midBreakPoint}px) {
        width: 80%;
      }
    }
  }
  .linearLoader {
    margin-top: 2rem;
  }
  .groupItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: ${midBreakPoint}px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: ${extraSmallBreakPoint}px) {
      width: 100%;
    }
    &__item {
      p {
        :first-child {
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-bottom: 0.7rem;
          font-weight: normal;
        }
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: bold;
          color: ${colors.gray.g6};
        }
        .product_type {
          font-size: 1.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.2rem 0.6rem;
          border-radius: 0.6rem;
          margin-left: 0.5rem;
          width: auto;
          border: 0;
          background: ${colors.gray.g2};
          color: ${colors.gray.g5};
          font-weight: 600;
          margin-right: 1rem;
        }
        p {
          margin-bottom: 0;
        }
      }

      svg {
        margin-bottom: -10px;
        width: 3rem;
        height: 3rem;
      }
      @media (max-width: ${extraLargeBreakPoint}px) {
        text-align: center;
      }
    }
  }
`;

export default RequisitionInfoGroupContainer;
