export const style = {
  nameHead: {
    paddingY: 2,
    width: 44,
    maxWidth: 44,
  },
  bankHead: {
    paddingY: 2,
    width: { base: 36, md: 40 },
    display: { base: 'none', sm: 'table-cell' },
  },
  statusHead: {
    width: 5,
    display: { base: 'none', sm: 'table-cell' },
  },
  clabeHead: {
    width: { base: 6, md: 40 },
  },
  actionsHead: {
    width: 2,
    maxWidth: 2,
    paddingX: 2,
  },
  tableCells: {
    paddingY: 2,
    paddingX: 2,
    width: 'auto',
  },
  text: {
    textAlign: 'center',
    padding: '0',
    textTransform: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  clabeText: {
    letterSpacing: '-1px',
    width: { base: 12, md: 'auto' },
    marginX: { base: 'auto', md: 'unset' },
    fontWeight: { base: '500', md: 'normal' },
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0',
  },
  icon: {
    variant: 'outline',
    colorScheme: 'gray',
    borderColor: 'white',
  },
};
