import styled from 'styled-components';

const RequisitionsFilterContainer = styled.div`
  .filterForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titleFilter {
      margin-top: 1.5rem;
      padding-bottom: 1rem;
      font-size: 1.5rem;
      line-height: 1.8rem;
      text-align: center;
      font-weight: 500;
      color: #134052;
      .closeFilter {
        margin-right: 1.4rem;
        float: right;
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          transform: translateY(-2px);
        }
        svg {
          width: 1.2rem;
        }
      }
    }
    hr {
      width: 100%;
      margin-bottom: 1rem;
      border-top: #ccdce2 solid 1px;
    }
    .contentFilter {
      width: 85%;
      .categoryFilter {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;
        .titleCategoryFilter {
          font-weight: 500;
          color: #134052;
          align-self: flex-start;
          margin-bottom: 1.5rem;
          font-size: 1.3rem;
          line-height: 1.5rem;
        }
        .inputsFilter {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
          &.destinyInputs {
            justify-content: flex-start;
            align-content: space-around;
            height: 10rem;
            button {
              margin-right: 1rem;
              .iconDestinyFilter {
                vertical-align: middle;
                width: 22px;
              }
            }
          }
        }
        &.destinyFilter {
          margin-bottom: 1rem;
        }
        .checkboxFieldsFilter {
          align-self: center;
          margin-top: 1rem;
          width: 100%;
          .checkboxInput {
            display: flex;
            flex-direction: row;
            margin-bottom: 1.5rem;
            .labelInput {
              display: flex;
              position: relative;
              padding-left: 2rem;
              margin-bottom: 12px;
              cursor: pointer;
              font-size: 2rem;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0.5rem;
                color: transparent;
                &:checked ~ .checkmark {
                  background-color: #0076ff;
                }
                &:checked ~ .checkmark:after {
                  display: block;
                }
              }
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 1.5rem;
              width: 1.5rem;
              border: 1px solid #ccdce2;
              background-color: transparent;
              border-radius: 50%;
              &:after {
                content: '';
                position: absolute;
                display: none;
              }
            }
            .labelCheckboxInput {
              padding-left: 1rem;
              font-size: 1.2rem;
              font-weight: 500;
              color: #134052;
            }
          }
        }
      }
    }
    .buttonsFilter {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;
      button:nth-child(1) {
        margin-right: 3rem;
      }
    }
  }
`;

export default RequisitionsFilterContainer;
