import {
  fetchDepositInformation as fetchDepositInformationRequest,
  getBanksWithdraws,
  getBankAccounts,
} from 'apis/funds';
import { createAsyncAction } from 'utils/redux-query';
import * as types from './types';

export const getUsersBankAccounts = () => async (dispatch) => {
  dispatch({ type: types.IS_FETCHING_BANK_ACCOUNTS, payload: true });
  let payload = [];
  const response = await getBankAccounts();
  if (response.bank_accounts) {
    payload = response.bank_accounts.map((acc) => ({
      id: acc.id,
      name: acc.name,
      clabe: acc.clabe,
      bank: acc.bank,
    }));
  }
  dispatch({ type: types.GET_BANK_ACCOUNTS, payload });
};

export const getWithdrawableBankAccounts = () => async (dispatch) => {
  const response = await getBanksWithdraws();
  dispatch({ type: types.GET_WITHDRAWABLE_BANK_ACCOUNTS, payload: response });
};

export const fetchDepositInformation = createAsyncAction('DEPOSIT_INFORMATION', () => async () => {
  const depositInformation = await fetchDepositInformationRequest();

  return depositInformation;
});
