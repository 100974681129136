import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { t, tscope } from 'i18n';
import {
  Text,
  Flex,
  Grid,
  Alert,
  Button,
  Heading,
  AlertIcon,
} from 'ui';
import { SelectField, TextField } from 'components/FormFields';
import { ZIPCODE_LENGTH } from 'config/constants';
import { DotsLoaderBox } from 'components/Loaders';
import { useController } from './hooks';
import { styles } from './UpdateAddress.styles';

const ts = tscope('components.UpdateProfileAddress');

export const UpdateAddress = ({
  onSubmit: onSubmitProp,
} = {}) => {
  const {
    control,
    onSubmit,
    isLoading,
    displayAlert,
    neighborhoods,
    rejectingReason,
    isZipCodeLoading,
    isSubmissionLoading,
  } = useController({ onSubmit: onSubmitProp });

  return (
    <Grid gap="5">
      <Heading fontSize="4xl">{ts('title')}</Heading>
      <Heading fontSize="lg" fontWeight="medium">{ts('subtitle')}</Heading>
      {displayAlert && (
        <Alert {...styles.alert}>
          <AlertIcon />
          <Text color="gray.700">
            {rejectingReason || ts('alerts.warning.proofOfAddress')}
          </Text>
        </Alert>
      )}
      <DotsLoaderBox isLoading={isSubmissionLoading}>
        <Grid
          as="form"
          onSubmit={onSubmit}
          gap="5"
          maxWidth="md"
          bgColor="white"
          borderRadius="xl"
          boxShadow="md"
          padding="10"
        >
          <TextField
            control={control}
            name="street"
            labelText={ts('fields.street.label')}
          />
          <Flex gap="5" direction={{ base: 'column', sm: 'row' }}>
            <TextField
              control={control}
              name="extNumber"
              labelText={ts('fields.extNumber.label')}
            />
            <TextField
              control={control}
              name="intNumber"
              labelText={ts('fields.intNumber.label')}
            />
            <TextField
              control={control}
              name="zip"
              type="number"
              maxLength={ZIPCODE_LENGTH}
              isDisabled={isZipCodeLoading}
              labelText={ts('fields.zip.label')}
            />
          </Flex>
          <SelectField
            control={control}
            name="neighborhood"
            labelText={ts('fields.neighborhood.label')}
            placeholder={t('common.na-')}
            isDisabled={!neighborhoods}
          >
            {neighborhoods?.map((neighborhood) => (
              <option key={neighborhood} value={neighborhood}>
                {neighborhood}
              </option>
            ))}
          </SelectField>
          <Grid gap="5">
            <TextField
              control={control}
              name="municipality"
              labelText={ts('fields.municipality.label')}
              readOnly
              isDisabled
            />
            <TextField
              control={control}
              name="city"
              labelText={ts('fields.city.label')}
              readOnly
              isDisabled
            />
            <TextField
              control={control}
              name="stateName"
              labelText={ts('fields.stateName.label')}
              readOnly
              isDisabled
            />
            <Flex justifyContent="space-between">
              <Button
                as={RRLink}
                variant="ghost"
                to="./"
              >
                {ts('actions.cancel')}
              </Button>
              <Button type="submit" isDisabled={isLoading} justifySelf="end">
                {ts('actions.submit')}
              </Button>
            </Flex>
          </Grid>
        </Grid>
      </DotsLoaderBox>
    </Grid>
  );
};
