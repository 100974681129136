import React from 'react';
import { useReduxQuery } from 'utils/redux-query';
import DotsLoader from 'components/DotsLoader';
import TransactionsCard from './TransactionsCard';
import EmptyStateCard from './EmptyStateCard';
import { MonthlyTransactionsCardContent } from './MonthlyTransactions.style';

function MonthlyTransactionsCard(props) {
  const [monthlyTransaction, { status: transactionsStatus }] = useReduxQuery('TRANSACTIONS');

  return (
    <MonthlyTransactionsCardContent {...props}>
      {transactionsStatus.pending && <DotsLoader />}
      {transactionsStatus.completed && monthlyTransaction.length > 0 && (
        <TransactionsCard transactions={monthlyTransaction} />
      )}
      {transactionsStatus.completed && monthlyTransaction.length === 0 && <EmptyStateCard />}
    </MonthlyTransactionsCardContent>
  );
}

export default MonthlyTransactionsCard;
