import { useEffect } from 'react';
import { useReduxSubscription } from 'utils/redux-query';
import { REFETCH_ACCOUNT_STATEMENTS_WAIT_FOR } from 'config/constants';

export const useAccountStatements = () => {
  const [accountStatements, { refetch: refetchAccountStatements }] = useReduxSubscription(
    'FETCH_ACCOUNT_STATEMENTS',
  );

  useEffect(() => {
    if (!accountStatements?.caching) return () => {};
    const pid = setTimeout(
      () => refetchAccountStatements(),
      REFETCH_ACCOUNT_STATEMENTS_WAIT_FOR,
    );
    return () => clearTimeout(pid);
  }, [accountStatements?.caching]);
};
