import { gql } from '@apollo/client';
import { useLazyQuery } from 'hooks/apollo';

export const REFRESH_SESSION_QUERY = gql`
  query REFRESH_SESSION {
    user {
      id
    }
  }
`;

export const useRefreshSessionQuery = (options) => useLazyQuery(REFRESH_SESSION_QUERY, options);
