import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { Form, Field } from 'react-final-form';
import { showToast } from 'utils/toastHandler';
import { ZIPCODE_LENGTH, messages } from 'config/constants';
import { fetchZipCodeInfoCatalog, fetchStatesCatalog } from 'apis/catalogs';
import TextField, { TextFieldStyled } from 'components/TextField';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import DotsLoader from 'components/DotsLoader';
import { createProfileAddress, updateProfileAddress } from 'apis/user';
import { userSettingsAccount } from 'routes';
import { t } from 'i18n';
import AddressFormContainer from './UpdateAddress.styles';

const AddressForm = (props) => {
  const { onSubmit, form: addressForm, submitting } = props;
  const [neighborhoods, setNeighborhoods] = useState([]);
  const autoFillNeighborhood = async (zip, neighborhood) => {
    const location = await fetchZipCodeInfoCatalog(zip);

    setNeighborhoods(location.neighborhoods || []);
    addressForm.change('city', location.city || location.municipality);
    addressForm.change('municipality', location.municipality);
    addressForm.change('stateName', location.stateName);
    addressForm.change('stateId', location.stateId);
    if (!neighborhood) {
      addressForm.change('neighborhood', null);
      return;
    }

    addressForm.change(
      'neighborhood',
      (location.neighborhoods || []).find(
        (nh) => String(nh).toLowerCase() === String(neighborhood).toLowerCase(),
      ),
    );
  };

  const handleOnBlurZipcodeInput = async (zipcodeFieldHelpers) => {
    const { meta, input } = zipcodeFieldHelpers;
    if (!meta.valid) return;

    autoFillNeighborhood(input.value);
  };

  return (
    <AddressFormContainer>
      <div className="title">
        <h3>{t('settings.limits.addressForm.title')}</h3>
      </div>
      <form onSubmit={onSubmit} className="controls">
        <div className="form">
          <div className="form-group">
            <label htmlFor="street">{t('settings.limits.addressForm.street')}</label>
            <TextField type="text" name="street" />
          </div>
          <div className="form-group num-cp">
            <div className="field num-ext">
              <label htmlFor="extNumber">{t('settings.limits.addressForm.extNumber')}</label>
              <TextField type="text" name="extNumber" length={6} />
            </div>
            <div className="field num-int">
              <label htmlFor="intNumber">{t('settings.limits.addressForm.intNumber')}</label>
              <TextField type="text" name="intNumber" length={6} />
            </div>
            <Field name="zip">
              {(zipcodeFieldHelpers) => (
                <div className="field zip">
                  <label htmlFor="zip">{t('settings.limits.addressForm.zip')}</label>
                  <TextFieldStyled
                    {...zipcodeFieldHelpers.input}
                    onBlur={(ev) => {
                      handleOnBlurZipcodeInput(zipcodeFieldHelpers);
                      zipcodeFieldHelpers.input.onBlur(ev);
                    }}
                    onChange={(ev) => {
                      if (ev.target.value.length <= ZIPCODE_LENGTH) {
                        zipcodeFieldHelpers.input.onChange(ev);
                      }
                    }}
                  />
                  {zipcodeFieldHelpers.meta.error && zipcodeFieldHelpers.meta.touched && (
                    <div className="error">
                      <span style={{ color: 'red' }}>{zipcodeFieldHelpers.meta.error}</span>
                    </div>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className="form-group">
            <label htmlFor="neighborhood">{t('settings.limits.addressForm.neighborhood')}</label>
            <SelectField
              type="text"
              name="neighborhood"
              options={neighborhoods?.map((neighborhood) => ({
                label: neighborhood,
                value: neighborhood,
              }))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="municipality">{t('settings.limits.addressForm.municipality')}</label>
            <TextField type="text" name="municipality" disabled />
          </div>
          <div className="form-group">
            <label htmlFor="city">{t('settings.limits.addressForm.city')}</label>
            <TextField type="text" name="city" disabled />
          </div>
          <div className="form-group">
            <label htmlFor="state">{t('settings.limits.addressForm.stateName')}</label>
            <TextField type="text" name="stateName" disabled />
          </div>
        </div>
        <div className="form-group submit">
          <Button type="submit" disabled={submitting}>
            {submitting ? <DotsLoader /> : t('settings.limits.addressForm.sendAndContinue')}
          </Button>
        </div>
      </form>
    </AddressFormContainer>
  );
};

export default () => {
  const history = useHistory();
  const query = useQuery();
  const [hasAlreadyAddress, setAlreadyAddress] = useState(false);

  const handleFormSubmit = async (address) => {
    try {
      const statesCatalog = await fetchStatesCatalog();
      const stateId = (statesCatalog || []).find(
        (state) => state.code === address.stateId.toUpperCase(),
      ).id;
      if (hasAlreadyAddress) {
        await updateProfileAddress({ ...address, stateId });
      } else {
        await createProfileAddress({ ...address, stateId });
      }
      // TODO: Update these Path variables, to the new routes schema.
      const redirectMap = { settingsAccount: userSettingsAccount };
      const redirecTo = redirectMap[query.get('section')];
      history.push(`${redirecTo}?displayInvoiceModal=true`);
    } catch (error) {
      showToast('error', t('settings.limits.errors.failedUpdateAddress'));
    }
  };

  const validateAddress = (address) => {
    const errors = {};
    if (!address.street) errors.street = messages.required;
    if (!address.extNumber) errors.extNumber = messages.required;
    if (!address.zip || address.zip.length !== ZIPCODE_LENGTH) errors.zip = messages.required;
    if (!address.neighborhood) errors.neighborhood = messages.required;
    if (!address.municipality) errors.municipality = messages.required;
    if (!address.city) errors.city = messages.required;
    if (!address.stateName) errors.stateName = messages.required;
    return errors;
  };

  return (
    <Form
      initialValues={{
        zip: '',
      }}
      onSubmit={handleFormSubmit}
      validate={validateAddress}
    >
      {({ form, submitting, handleSubmit }) => (
        <AddressForm
          form={form}
          submitting={submitting}
          onSubmit={handleSubmit}
          setAlreadyAddress={setAlreadyAddress}
        />
      )}
    </Form>
  );
};
