import React from 'react';
import TextField from 'components/TextField/TextField';
import SelectField from 'components/SelectField/SelectField';
import {
  Button,
  Heading,
} from 'components';
import DotsLoader from 'components/DotsLoader';
import { Grid } from 'styles';
import { ZIPCODE_LENGTH } from 'config/constants';
import { tscope } from 'i18n';
import {
  Center,
} from 'ui';
import { useController } from './hooks/useController';
import s from './AddressContentForm.styles';

const t = tscope('components.UpdateProfileAddress');
const commonT = tscope('common');

export const AddressContentForm = (props) => {
  const {
    values,
    submitting,
    form: addressForm,
    onSubmit = () => {},
    ...contentProps
  } = props;
  const {
    neighborhoods,
    displayLocation,
  } = useController({
    values,
    addressForm,
  });

  return (
    <Grid {...s.container} {...contentProps}>
      <Heading as="h3">{t('title')}</Heading>
      <Grid {...s.content} as="form" onSubmit={onSubmit}>
        <TextField
          {...s.field}
          name="street"
          textLabel={t('fields.street.label')}
        />
        <Grid {...s.numberFields}>
          <TextField
            {...s.field}
            name="extNumber"
            maxLength={6}
            textLabel={t('fields.extNumber.label')}
          />
          <TextField
            {...s.field}
            name="intNumber"
            maxLength={6}
            textLabel={t('fields.intNumber.label')}
          />
          <TextField
            {...s.field}
            name="zip"
            maxLength={ZIPCODE_LENGTH}
            textLabel={t('fields.zip.label')}
          />
        </Grid>
        <SelectField
          {...s.neighborhoodField}
          name="neighborhood"
          textLabel={t('fields.neighborhood.label')}
          disabled={!displayLocation}
        >
          <option key="empty" value="">{commonT('na-')}</option>
          {
            neighborhoods?.map(
              (neighborhood) => (
                <option
                  key={neighborhood}
                  value={neighborhood}
                >
                  {neighborhood}
                </option>
              ),
            )
          }
        </SelectField>

        <Grid display={displayLocation ? 'grid' : 'none'} rowGap="2rem">
          <TextField
            readOnly
            disabled
            name="municipality"
            textLabel={t('fields.municipality.label')}
            {...s.field}
          />
          <TextField
            readOnly
            disabled
            name="city"
            textLabel={t('fields.city.label')}
            {...s.field}
          />
          <TextField
            readOnly
            disabled
            name="stateName"
            textLabel={t('fields.stateName.label')}
            {...s.field}
          />
        </Grid>
        <Center>
          <Button
            {...s.submit}
            type="submit"
            disabled={submitting}
          >
            {submitting ? <DotsLoader /> : t('actions.submit')}
          </Button>
        </Center>
      </Grid>
    </Grid>
  );
};
