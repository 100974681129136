import styled from 'styled-components';

export const LastConnectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;

  margin-top: 5.4rem;
  font-size: 1.333rem;
  line-height: 16px;
`;

export const LastConnectionBrowserTitle = styled.p`
  font-weight: bold;
  margin-top: 1rem;
`;
