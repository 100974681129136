import React, { useEffect } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTab } from 'actions/dashboard';
import PATHS, { userSettingsAccount } from 'routes';
import { settingsElement } from 'views/SideBar/utils';
import { useFeatureFlag } from 'utils/featureFlags';
import { getSidebarOptions } from './Sidebar/utils';
import Sidebar from './Sidebar';
import MainArea from './MainArea';
import SettingsContainer from './styles';
import MenuLevelOne from './MainArea/Limits/UpgradeLimit/Aside';
import MenuUpdateAddressAside from './MainArea/UpdateAddress/Aside/Aside';

const {
  investorSettingsPath,
  investorSettingsLimitsLevelOneUpdateAddressPath,
  investorSettingsLimitsLevelOneUpdateIdentificationPath,
  investorSettingsUpdateAddressPath,
} = PATHS;

const Settings = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const featureFlag = useFeatureFlag();
  const items = getSidebarOptions({ featureFlag });

  useEffect(() => {
    if (pathname !== userSettingsAccount) return;
    dispatch(selectTab(settingsElement));
  }, []);

  const responsiveIs = useSelector((state) => state.browser.is);

  const renderLayout = () => (
    <>
      <Switch>
        <Route
          path={investorSettingsLimitsLevelOneUpdateAddressPath()}
          render={() => <MenuLevelOne verificationStep={2} />}
        />
        <Route
          path={investorSettingsLimitsLevelOneUpdateIdentificationPath()}
          render={() => <MenuLevelOne verificationStep={1} />}
        />
        <Route path={investorSettingsUpdateAddressPath()}>
          <MenuUpdateAddressAside />
        </Route>
        <Route path={investorSettingsPath()}>
          <Sidebar options={items} />
        </Route>
      </Switch>
      <MainArea />
    </>
  );

  return (
    <SettingsContainer className="settings__container">
      {responsiveIs.mobile ? (
        <MainArea isMobile={responsiveIs.mobile} sidebarOptions={items} />
      ) : renderLayout()}
    </SettingsContainer>
  );
};

export default Settings;
