import React from 'react';
import { tscope } from 'i18n';
import {
  Text,
  Modal,
  Button,
  Divider,
  Container,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from 'ui';
import { AuthorizeWithOTPField } from 'components/AuthorizeWithOTPField';
import { useController } from './hooks';
import { styles as s } from './DeleteBankAccount.style';

const ts = tscope('components.RemoveBankAccountForm');

export const DeleteBankAccountForm = ({
  isOpen,
  onClose,
  bankAccount,
}) => {
  const {
    control,
    onSubmit,
    isLoading,
    otpFieldProps,
  } = useController({
    isOpen,
    onClose,
    bankAccount,
  });

  return (
    <Modal
      {...s.modal}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader {...s.headerModal}>
          {ts('title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody {...s.bodyModal}>
          <Container
            {...s.form}
            as="form"
            onSubmit={onSubmit}
          >
            <Container {...s.container}>
              <Text {...s.accentText}>
                {ts('infoFields.alias')}
              </Text>
              <Text {...s.bankInfoText}>
                {bankAccount?.clabeAlias}
              </Text>
              <Text {...s.accentText}>
                {ts('infoFields.bank')}
              </Text>
              <Text {...s.bankInfoText}>
                {bankAccount?.bank}
              </Text>
              <Text {...s.accentText}>
                {ts('infoFields.clabe')}
              </Text>
              <Text {...s.bankInfoText}>
                {bankAccount?.clabe}
              </Text>
              <Divider {...s.divider} />
            </Container>
            <AuthorizeWithOTPField
              {...s.tokenField}
              t={ts}
              size="sm"
              name="token"
              control={control}
              isDisabled={isLoading}
              data-testid="tokenField"
              {...otpFieldProps}
            />
            <Container {...s.buttonContainer}>
              <Button
                {...s.cancelButton}
                onClick={onClose}
              >
                {ts('actions.cancel')}
              </Button>
              <Button
                {...s.submitButton}
                isDisabled={isLoading}
              >
                {ts('actions.submit')}
              </Button>
            </Container>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
