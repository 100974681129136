import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box } from 'styles';

export const CardDefaultLoader = (props) => (
  <Box as={ContentLoader} {...props}>
    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
  </Box>
);

export const CardLoader = (props) => (
  <ContentLoader
    height="80"
    width="160"
    speed={2}
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    style={{ width: 200 }}
    className="cardLoaderRequisition"
    {...props}
  >
    <rect x="10" y="25" rx="5" ry="5" width="90%" height="8" />
    <rect x="25" y="50" rx="5" ry="5" width="75%" height="5" />
    <rect x="25" y="5" rx="5" ry="5" width="75%" height="5" />
  </ContentLoader>
);

export const LinearLoader = ({ height, widthLoader }) => (
  <ContentLoader
    height={height}
    width={widthLoader}
    speed={2}
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    className="linearLoader"
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="5" />
  </ContentLoader>
);

export const CardHistoryLoader = () => (
  <ContentLoader
    height={70}
    width={100}
    speed={2}
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    className="linearLoader"
  >
    <rect x="0" y="50%" rx="5" ry="5" width="100%" height="8" />
  </ContentLoader>
);

export const DoubleLineLoader = () => (
  <ContentLoader height={80} width={100} speed={2} primaryColor="#F2F5F7" secondaryColor="#E7EBEF">
    <rect x="0" y="35%" rx="5" ry="5" width="100%" height="8" />
    <rect x="0" y="85%" rx="5" ry="5" width="100%" height="8" />
  </ContentLoader>
);

export const DescriptionLoader = () => (
  <ContentLoader height={8} width={100} speed={2} primaryColor="#F2F5F7" secondaryColor="#E7EBEF">
    <rect x="0" y="1" rx="2" ry="1" width="95%" height="2" />
    <rect x="0" y="6" rx="2" ry="1" width="75%" height="2" />
  </ContentLoader>
);

export const FinancialLoader = () => (
  <ContentLoader
    height="60"
    width="140"
    speed={2}
    primaryColor="#F2F5F7"
    secondaryColor="#E7EBEF"
    style={{ width: '100%' }}
  >
    <rect x="30" y="5" rx="5" ry="5" width="85" height="7" />
    <rect x="20" y="28" rx="5" ry="5" width="110" height="7" />
    <rect x="30" y="50" rx="5" ry="5" width="85" height="7" />
  </ContentLoader>
);

export { DotsLoaderBox } from './DotsLoaderBox';
