import { SET_LEVEL_REQUIREMENTS } from '../../actions/settings/limits/types';

const initialState = {
  level: 0,
  requirements: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LEVEL_REQUIREMENTS:
      return { ...state, requirements: action.payload.requirements, level: action.payload.level };
    default:
      return state;
  }
};
