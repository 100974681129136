import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ChevronLeftIcon, Button } from 'ui';

const MobileHeader = ({
  onClose,
  onToggle,
  option,
}) => {
  if (!option) return null;
  const { Icon, text } = option;

  useEffect(() => {
    if (option) onClose();
  }, [option]);

  return (
    <Button
      size="lg"
      padding={4}
      variant="ghost"
      width="full"
      animationType="none"
      onClick={onToggle}
      color="gray.700"
      justifyContent="flex-start"
      leftIcon={<ChevronLeftIcon boxSize={8} color="gray.400" />}
    >
      {text}
      <Icon boxSize={6} marginLeft={1} />
    </Button>
  );
};

export default withRouter(MobileHeader);
