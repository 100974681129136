import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const { extraSmallBreakPoint, midBreakPoint } = breakPoints;

const PaymentsGraphCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  .content {
    flex: 1;
    display: flex;
    justify-content: space-around;
    @media (max-width: ${midBreakPoint}px) {
      flex-direction: column;
    }
    &.unavailableData {
      margin-top: 3rem;
      justify-content: flex-start;
      @media (max-width: ${extraSmallBreakPoint}px) {
        margin-top: 1rem;
      }
    }
    .graph {
      flex: 1;
      @media (max-width: ${midBreakPoint}px) {
        padding: 0 2rem 3rem 2rem;
      }
      &__container {
        width: 18.9rem;
        height: 18.9rem;
        margin: 0 auto;
        @media (max-width: ${midBreakPoint}px) {
          width: auto;
          height: auto;
        }
      }
      &__numbers {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        &--item {
          :first-child {
            color: #00c66f;
            margin-left: 1.5rem;
          }
          :last-child {
            margin-right: 1.5rem;
          }
        }
        h3 {
          font-size: 3rem;
        }
      }
      &__text {
        :first-child {
          margin-bottom: 1.5rem;
        }
        p {
          text-align: center;
          :first-child {
            color: #346071;
            font-size: 2.6rem;
            font-weight: 500;
          }
          :last-child {
            color: #668d9d;
          }
        }
      }
      &--debtText {
        position: relative;
      }
    }
    .borrowInfo {
      flex: 1;
      table {
        border-collapse: collapse;
        width: 100%;
        height: 90%;
        color: #668d9d;
        th {
          border-bottom: 1px solid #e7edf3;
          padding: 0.5rem 0;
          font-weight: 500;
          color: #346071;
          :first-child {
            padding-left: 3rem;
            text-align: left;
          }
          :last-child {
            padding-right: 3rem;
            text-align: right;
            font-size: 3rem;
          }
        }
        tbody {
          tr {
            :first-child {
              color: #346071;
              td {
                font-weight: 500;
                padding-top: 0.5rem;
                :last-child {
                  font-size: 2rem;
                }
              }
            }
            td {
              :first-child {
                padding-left: 3rem;
              }
              :last-child {
                text-align: right;
                padding-right: 3rem;
              }
            }
          }
        }
      }
    }
    .tooltip.debt {
      float: right;
      position: absolute;
      margin-left: 0.3rem;
    }
  }
`;

export default PaymentsGraphCardContent;
