import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const ReferralsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8rem auto;
  width: 90%;
  max-width: 780px;

  .heading-container {
    color: #5b727b;
    line-height: 2.4rem;
    font-size: 2rem;
    margin-bottom: 3rem;
  }

  .container {
    background-color: #ffffff;
    border-radius: 0.4rem;
    margin-bottom: 3rem;
    width: 100%;
  }

  .code-and-social-media-container {
    padding: 3rem 4rem;
  }

  .referrals-table-container {
  }

  .referrals-instructions-container {
    padding: 3rem 8rem;
  }

  @media (max-width: ${breakPoints.largeBreakPoint}px) {
    margin: 0 auto;
    margin-top: 3rem;

    .heading-container {
      p {
        text-align: center;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .code-and-social-media-container {
      padding: 3rem 1.5rem;
    }

    .referrals-table-container {
      padding: 0;
    }

    .referrals-instructions-container {
      padding: 3rem 1.5rem;
    }
  }
`;

export default ReferralsContainer;
