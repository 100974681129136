import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updateUserSettings } from 'apis/user';
import { resolver } from './resolver';

export const useAddressForm = ({
  statesCatalog = [],
  profileAddress,
  createProfileAddress,
  updateProfileAddress,
  proofOfAddressStatus,
  onSubmit: onSubmitProp = () => {},
}) => {
  const {
    reset,
    watch,
    control,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    resolver,
    context: {
      proofOfAddressStatus,
    },
    defaultValues: {
      street: '',
      extNumber: '',
      intNumber: '',
      zip: '',
      neighborhood: '',
    },
  });

  const onSubmit = handleSubmit(async (address) => {
    const { stateId } = address;
    const realStateId = statesCatalog
      .find((state) => state.code === stateId.toUpperCase())
      ?.id;
    const addressPayload = {
      ...address,
      stateId: parseInt(realStateId, 10),
    };
    if (profileAddress) await updateProfileAddress(addressPayload);
    else await createProfileAddress(addressPayload);
    updateUserSettings('address_validated', true);
    onSubmitProp();
  });

  const populateLocation = (location) => {
    setValue('city', location.city || location.municipality || '');
    setValue('municipality', location.municipality || '');
    setValue('stateName', location.stateName || '');
    setValue('stateId', location.stateId || '');
  };

  useEffect(() => {
    if (!profileAddress) return;
    const neighborhood = statesCatalog
      .includes(profileAddress.neighborhood)
      ? profileAddress.neighborhood
      : '';
    reset({
      neighborhood,
      street: profileAddress.street,
      extNumber: profileAddress.extNumber,
      intNumber: profileAddress.intNumber,
      zip: profileAddress.zip,
    });
  }, [profileAddress]);

  return {
    watch,
    control,
    onSubmit,
    setError,
    clearErrors,
    populateLocation,
  };
};
