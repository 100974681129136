import React from 'react';
import PropTypes from 'prop-types';
import imageSet from 'assets/images/imageSet';
import { Flex } from 'styles';

const Image = (props) => {
  const {
    alt,
    minSize,
    boxSize,
    bgColor,
    imageName,
    objectFit,
    borderRadius,
    ...contentProps
  } = props;
  const ImageComponent = imageSet[imageName]?.component ?? (() => <img alt="not found" />);

  return (
    <Flex
      w={boxSize}
      h={boxSize}
      minWidth={minSize}
      minHeight={minSize}
      bgColor={bgColor}
      borderRadius={borderRadius}
      overflow="hidden"
      {...contentProps}
    >
      <ImageComponent width="100%" height="100%" alt={alt} style={{ objectFit }} />
    </Flex>
  );
};

Image.propTypes = {
  imageName: PropTypes.string.isRequired,
  boxSize: PropTypes.string,
  bgColor: PropTypes.string,
  borderRadius: PropTypes.string,
};

Image.defaultProps = {
  boxSize: '54px',
  bgColor: 'schema.white',
  borderRadius: '1rem',
};

export default Image;
