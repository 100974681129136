import React from 'react';
import FormLayout from 'views/FormLayout';
import DotsLoader from 'components/DotsLoader';
import { Flex } from 'styles';
import JobStep from './components/JobStep';
import InvestPlansStep from './components/InvestPlansStep';
import ConfirmProfileInformation from './components/ConfirmProfileInformation/Show';
import { STEPS } from './constants';
import { useController } from './hooks';
import { styles } from './RegisterFunnel.styles';

export const RegisterFunnel = () => {
  const {
    profile,
    goNextStep,
    currentStep,
    displayLoader,
  } = useController();

  return (
    <FormLayout
      scrollToTopKey={currentStep}
      {...styles.formLayout}
    >
      <Flex {...styles.innerContent}>
        {displayLoader
          ? (
            <div className="loader">
              <DotsLoader black />
            </div>
          )
          : (
            <>
              {currentStep === STEPS.FILL_JOB && (
                <JobStep
                  goNextStep={goNextStep}
                  profile={profile}
                />
              )}
              {currentStep === STEPS.FILL_INVESTMENT_PLAN && (
                <InvestPlansStep
                  goNextStep={goNextStep}
                  profile={profile}
                />
              )}
              {currentStep === STEPS.CONFIRM_PROFILE && (
                <ConfirmProfileInformation
                  profile={profile}
                  goNextStep={goNextStep}
                />
              )}
            </>
          )}
      </Flex>
    </FormLayout>
  );
};
