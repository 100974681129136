import styled from 'styled-components';
import { colors } from 'styles';

const AmortizationScheduleContainer = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    th {
      text-align: center;
      padding: 1.4rem 1rem;
    }
    td {
      text-align: center;
      padding: 1.4rem 1rem;
      border-bottom: 1px solid ${colors.gray.g2};
    }
    tr:last-child td {
      border: none;
    }
  }
`;

export default AmortizationScheduleContainer;
