import styled, { css } from 'styled-components';
import colors from 'styles/colors';
import { textHelper } from 'styles';

export const TextFieldInput = styled.input`
  outline: none;
  height: 4.5rem;
  color: ${colors.schema.gray[700]};
  font-size: 1.6rem;
  border: 1px solid #a2bcc5;
  border-radius: 12px;
  transition: all 0.3s ease;

  &::placeholder {
    color: ${colors.schema.gray[400]};
  }

  &:disabled {
    opacity: 0.5;
    border-color: ${colors.schema.gray[300]};
  }

  ${(props) => (props.readOnly
    ? css`
          background-color: ${colors.schema.gray[100]};
          cursor: not-allowed;
        `
    : '')}

  ${(props) => (props.isInvalid
    ? css`
          border-color: ${colors.schema.red[500]};
        `
    : '')}

  &:focus {
    border-color: #0076ff;
  }

  ${textHelper}
`;
