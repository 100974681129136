import React from 'react';
import DotsLoader from 'components/DotsLoader';
import { LoadingSection, Loader, Footer } from './LoadingScreen.styles';

function LoadingScreen() {
  return (
    <LoadingSection>
      <Loader>
        <DotsLoader black />
      </Loader>
      <Footer />
    </LoadingSection>
  );
}

export default LoadingScreen;
