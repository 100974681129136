import styled from 'styled-components';

const TableRow = styled.tr`
  td {
    color: #5b727b;

    &.reward {
      color: #00c574;
      font-weight: 500;
    }
  }
`;

export default TableRow;
