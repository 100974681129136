export const styles = {
  container: {
    rowGap: 5,
    height: 'auto',
  },
  enabledSecondFactorModal: {
    padding: '0',
  },
  addBankAccountButton: {
    paddingTop: 1,
    size: 'sm',
    variant: 'link',
    justifySelf: 'start',
    animationType: 'none',
  },
};
