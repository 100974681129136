import React from 'react';
import {
  Grid,
  Skeleton,
  Text,
} from 'ui';
import { styles } from './Row.styles';

const levelTextProp = {
  1: { paddingLeft: '0' },
  2: { paddingLeft: '4' },
};

export const Row = ({
  label,
  value,
  isLoading,
  level = 1,
  'data-testid': testId = 'row',
  ...props
}) => (
  <Grid {...styles.row} data-testid={`${testId}.container`} {...props}>
    <Text data-testid={`${testId}.label`} {...styles.rowLabel} {...levelTextProp[level]}>{label}</Text>
    <Skeleton isLoaded={!isLoading} {...levelTextProp[level]}>
      <Text data-testid={`${testId}.value`} {...styles.rowValue}>{value}</Text>
    </Skeleton>
  </Grid>
);
