import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';

const ContainerDiv = ({ ...otherProps }) => <div {...otherProps} />;

const FirstAddToCartContainer = styled(ContainerDiv)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #697f87;
  & .cart__icon__container {
    position: relative;
    margin-bottom: 2rem;
    & svg {
      height: 4rem;
      width: 4rem;
    }
    & .cart__icon__quanity {
      position: absolute;
      border-radius: 89px;
      border: 2px solid #fc6448;
      padding: 0 5px;
      top: -6px;
      font-size: 17px;
      color: #fc6448;
      background-color: white;
      left: 26px;
    }
  }
  & .ready {
    font-size: 2rem;
    margin-bottom: 6rem;
  }
  & .description {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 4rem;
  }
  & .buttons__container {
    width: 100%;
    display: flex;
    justify-content: center;

    & button {
      margin: 0 1rem;
    }

    @media (max-width: ${breakPoints.extraSmallBreakPoint}px) {
      flex-direction: column;
      align-items: stretch;

      & button {
        margin: 0;

        :last-child {
          margin-top: 1rem;
        }
      }
    }
  }
  & .button__primary {
    margin-right: 1rem;
    border-color: #00c574;
    color: #00c574;
  }
`;

export default FirstAddToCartContainer;
