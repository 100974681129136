import snakeify from 'snakecase-keys';
import camelify from 'camelcase-keys-recursive';
import { request } from 'apis/fetchers/yotepresto';

export const UPDATE_PHONE_PATH = '/v2/investor/profiles/cellphone';

const update = async ({
  otp,
  cellPhoneCode,
  cellPhoneNumber,
}) => {
  const body = snakeify({
    profile: {
      cellPhoneCode,
      cellPhoneNumber,
    },
    otpCode: otp,
  });
  const config = {
    method: 'put',
    path: UPDATE_PHONE_PATH,
    body,
  };
  const { data } = await request(config);

  return camelify(data);
};

export default update;
