import { useReduxQuery } from 'utils/redux-query';
import { useWithdrawalBankAccounts } from '../../BanksTable/hooks/graphql/useQueryWithdrawalAccount';

export const useController = (params) => {
  const { setOpenModal } = params;
  const { data, loading } = useWithdrawalBankAccounts();
  const [secondFactor] = useReduxQuery('SECOND_FACTOR');

  const bankAccounts = data?.user?.withdrawalAccounts || null;
  const hasNoAccounts = !bankAccounts?.length;
  const handleOpenModal = () => setOpenModal('true');

  return {
    loading,
    secondFactor,
    hasNoAccounts,
    handleOpenModal,
  };
};
