import { createAsyncAction, createActions } from 'utils/redux-query';
import { getReferenceCode, saveReferenceCode, updateReferenceCode } from 'apis/registerForm';

export const { reinitialize: reinitializeReferral } = createActions('REFERRAL');

export const getReferral = createAsyncAction('REFERRAL', () => async () => {
  try {
    const result = await getReferenceCode();
    return result;
  } catch (error) {
    if (error.response.status === 404) {
      return null;
    }
    throw error;
  }
});

const { update: updateReferralAction } = createActions('REFERRAL');

export const createReferral = createAsyncAction(
  'CREATE_REFERRAL',
  ({ referenceCode }) => async (dispatch) => {
    const referralUser = await saveReferenceCode({ referenceCode });
    dispatch(
      updateReferralAction(referralUser, { error: null, status: 'COMPLETED' }),
    );

    return referralUser;
  },
);

export const updateReferral = createAsyncAction(
  'UPDATE_REFERRAL',
  ({ referenceCode }) => async (dispatch) => {
    const referralUser = await updateReferenceCode({ referenceCode });
    dispatch(
      updateReferralAction(referralUser, { error: null, status: 'COMPLETED' }),
    );

    return referralUser;
  },
);
