import { useCallback } from 'react';
import { t } from 'i18n';
import { showToastError } from 'utils/toastHandler';

export const useErrorQueryHandler = ({
  setError,
}) => {
  const onInvalidCodeError = useCallback(() => {
    setError(
      'token',
      { message: t('Forms.fields.errors.invalidOTP_Token') },
      { shouldFocus: true },
    );
  }, [setError]);

  const onUnprocessableRequestError = useCallback((queryError) => {
    showToastError(queryError.message);
  }, [setError]);

  return {
    onInvalidCodeError,
    onUnprocessableRequestError,
  };
};
