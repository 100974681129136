import React from 'react';
import { connect } from 'react-redux';
import { SlideFade } from 'ui';
import withSizes from 'react-sizes';
import DetailContent from './DetailContent';

const DisplayDetails = (props) => {
  const { isShowing } = props;

  return (
    <SlideFade
      in={isShowing}
      offsetY="0"
      unmountOnExit
      style={{
        position: 'absolute',
        top: '0',
        zIndex: '100',
        width: '100%',
        overscrollBehaviorY: 'none',
        height: 'calc(100vh - 56px)',
      }}
    >
      <DetailContent {...props} />
    </SlideFade>
  );
};

const mapStateToProps = ({ dashboard }) => ({
  displayDetailState: dashboard.displayDetail,
});

const mapSizesToProps = ({ width }) => ({ width });

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(DisplayDetails));
