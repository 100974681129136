import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchPersonalArchives } from 'actions/investor/personalArchives';
import UploadIdentification from 'views/DashboardContent/Mati';

const Update = () => {
  const dispatch = useDispatch();

  const handleFinishProcess = () => {
    dispatch(fetchPersonalArchives());
  };

  return <UploadIdentification onFinishProcess={handleFinishProcess} />;
};

export default Update;
