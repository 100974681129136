import ReactCodeInput from 'react-code-input';
import styled, { css } from 'styled-components';
import { colors } from 'styles';

const FIELD_SIZE = 36;
// All the Other OTP fields in this project will be eventually deprecated.
// Please use this field for new implementations and refactors

// The otpInput component has classes for inner elements
// see https://www.npmjs.com/package/react-code-input
export const OTPInput = styled(ReactCodeInput)`
  width: ${({ fields, fieldSize = FIELD_SIZE }) => fields * { fieldSize } + (fields - 1) * 8}px;
  display: flex !important;
  justify-content: ${({ fluid }) => (fluid ? 'space-between' : 'flex-start')};

  input {
    margin-right: 8px;
    padding: 2px !important;
    text-align: center;
    font-size: 16px !important;
    font-family: 'proxima-nova', sans-serif !important;
    height: 4.4rem !important;
    border: 1px solid ${colors.schema.gray[400]} !important;
    border-radius: 0.4rem !important;
    box-shadow: none !important;
    outline: none;
    font-size: 1.5rem !important;
    color: ${colors.gray.g7} !important;
    width: ${({ fieldSize = FIELD_SIZE }) => `${fieldSize}px`};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:focus {
      border-color: ${colors.schema.blue[500]} !important;
    }

    ${({ hasError }) => (hasError
    ? css`
            border-color: ${colors.schema.red[500]} !important;
          `
    : '')}
  }
`;
