import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'config/constants';
import colors from 'styles/colors';
import LimitsBannerComponent from '../Banners/Limits';

const { smallBreakPoint, largeBreakPoint } = breakPoints;

export const LimitsBanner = styled(LimitsBannerComponent)`
  grid-column: 1 / 6;
  grid-row: 3 / auto;

  @media (max-width: ${largeBreakPoint}px) {
    grid-column: 1 / -1;
    grid-row: 6 / 7;
  }

  @media (max-width: ${smallBreakPoint}px) {
    grid-row: 3 / auto;
  }
`;

export const WaveText = styled.p`
  color: ${colors.gray.g4};
`;

const activeStateDiv = ({ isMobile, ...otherProps }) => <div {...otherProps} />;
const DashboardActiveContainer = styled(activeStateDiv)`
  width: 100%;
  height: auto;
  display: grid;
  padding: 15px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(6, auto);
  grid-gap: 1.5rem;

  .promotioningAppBanner {
    grid-row: 3;
    grid-column: 6 / -1;
  }

  @media (max-width: ${largeBreakPoint}px) {
    grid-template-rows: none;

    .promotioningAppBanner {
      grid-row: auto;
      grid-column: 4 / -1;
    }
  }

  @media (max-width: ${smallBreakPoint}px) {
    grid-template-columns: 100%;

    .promotioningAppBanner {
      grid-row-start: 6;
      grid-row-end: span 1;
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
  & .user__info__banner {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    display: flex;
    font-size: 1.3rem;
    justify-content: flex-end;
    color: #93afb9;
    & p {
      margin-right: 1rem;
      & span {
        &:last-of-type {
        }
      }
      @media (max-width: ${smallBreakPoint}px) {
        display: flex;
        flex-direction: column;
        & span {
          text-align: end;
        }
      }
    }
  }
`;

export default DashboardActiveContainer;
