import { request } from 'apis/fetchers/yotepresto';
import camelify from 'camelcase-keys-recursive';
import { parseSession } from './parser';

const create = async ({ email, password, skipStatusHandle }) => {
  const method = 'post';
  const path = '/auth/sign_in';
  const body = {
    email,
    password,
  };
  const config = {
    method, path, body, skipStatusHandle,
  };
  const { data } = await request(config);
  const session = camelify(data || {}).data || {};

  return parseSession(session);
};

export default create;
