import { t } from 'i18n';

const tsTerms = (key) => t(key, { scope: 'components.CER.Terms' });

export const TERMS_LIST = [
  {
    id: 1,
    key: 'advisory',
    stepName: 'advisory',
    title: tsTerms('sections.termsListing.advisory.title'),
    description: tsTerms('sections.termsListing.advisory.question'),
  },
  {
    id: 2,
    key: 'information',
    stepName: 'information',
    title: tsTerms('sections.termsListing.information.title'),
    description: tsTerms('sections.termsListing.information.question'),
  },
  {
    id: 3,
    key: 'yieldInvestment',
    stepName: 'yield_investment',
    title: tsTerms('sections.termsListing.yieldInvestment.title'),
    description: tsTerms('sections.termsListing.yieldInvestment.question'),
  },
  {
    id: 4,
    key: 'losses',
    stepName: 'losses',
    title: tsTerms('sections.termsListing.losses.title'),
    description: tsTerms('sections.termsListing.losses.question'),
  },
  {
    id: 5,
    key: 'guaranteeOfResources',
    stepName: 'guarantee_of_resources',
    title: tsTerms('sections.termsListing.guaranteeOfResources.title'),
    description: tsTerms('sections.termsListing.guaranteeOfResources.question'),
  },
  {
    id: 6,
    key: 'liquidity',
    stepName: 'liquidity',
    title: tsTerms('sections.termsListing.liquidity.title'),
    description: tsTerms('sections.termsListing.liquidity.question'),
  },
];
