import { gql } from '@apollo/client';
import { useMutation } from 'hooks/apollo';

export const CREATE_WITHDRAWAL = gql`
mutation CREATE_WITHDRAWAL ($input: CreateWithdrawalMutationInput!) {
  createWithdrawal(input: $input) {
    user {
      id
    }
  }
}
`;

export const useWithdrawMutation = (options) => useMutation(CREATE_WITHDRAWAL, options);
