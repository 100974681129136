import React from 'react';
import LimitsBannerComponent from 'components/Banners/Limits';
import { firstLevelWarnsKeys } from 'config/constants';
import { useReduxQuery } from 'utils/redux-query';

function LimitsBanner(props) {
  const [session] = useReduxQuery('SESSION');
  const { levelWarn } = session;

  const isTransactionWarn = levelWarn
    && [
      firstLevelWarnsKeys.TRANSACTIONS_ON_THIRTY_PERCENT,
      firstLevelWarnsKeys.TRANSACTIONS_ON_SIXTY_PERCENT,
      firstLevelWarnsKeys.TRANSACTIONS_ON_NINETY_PERCENT,
      firstLevelWarnsKeys.EXCEEDED_TRANSACTIONAL_LIMIT,
    ].includes(levelWarn.level);

  const displayAsTwoWarns = (
    levelWarn?.accountPercentage >= 50 && levelWarn?.transactionsPercentage >= 30
  );

  return isTransactionWarn || displayAsTwoWarns ? (
    <LimitsBannerComponent
      {...props}
      displayAccountValue={displayAsTwoWarns}
      warnLevel={levelWarn}
    />
  ) : null;
}

export default LimitsBanner;
