import { createActions } from 'utils/redux-query';
import { getRequisitionList } from '../requisitions';
import * as types from './types';

export const { setPayload: setIsOpenCart } = createActions('CART_IS_OPEN');

export const selectTab = (currentTab) => ({
  type: types.SET_ACTIVE_TAB,
  payload: currentTab,
});

export const toggleSidebar = (toggle) => ({
  type: types.TOGGLE_SIDEBAR,
  payload: toggle,
});

export const closeSidebar = () => ({
  type: types.CLOSE_SIDEBAR,
  payload: false,
});

export const updateSidebarAccountBalance = (data) => ({
  type: types.SET_ACCOUNT_BALANCES_SIDEBAR,
  availableBalance: data?.availableBalance || null,
  accountValue: data?.accountValue || null,
});

export const updateAccountBalances = (data) => ({
  type: types.SET_ACCOUNT_BALANCES,
  availableBalance: data?.availableBalance || null,
  accountValue: data?.accountValue || null,
  fundingBalance: data?.saldo_en_fondeo || null,
});

export const setAccountStatementCache = (payload) => (dispatch) => dispatch({
  type: types.SET_ACCOUNT_STATEMENT_CACHING,
  payload,
});

export const dataAccountStatementReady = (payload) => ({
  type: types.SET_ACCOUNT_STATEMENT_DATA_READY,
  payload,
});

export const toggleDetails = (toggleState, idRequisitionDetail, dispositionId) => (dispatch) => {
  if (toggleState) {
    getRequisitionList();
  }
  dispatch({
    type: types.TOGGLE_DETAILS,
    payload: toggleState,
    idRequisitionDetail,
    dispositionId,
  });
};

export const setSocketValues = (values) => ({ type: types.SET_SOCKET_VALUES, payload: values });

export const dataPortfolioReady = () => ({ type: types.SET_PORTFOLIO_DATA_READY, payload: true });
