import styled from 'styled-components';
import { colors } from 'styles';
import { breakPoints } from 'config/constants';

const { midBreakPoint } = breakPoints;

const SchemeContainer = styled.div`
  min-width: 25rem;

  .current-scheme {
    padding: 0.8rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 4px 4px 0 0;
    color: transparent;
    background: transparent;
    @media (max-width: ${midBreakPoint}px) {
      display: none;
    }
  }
  .card {
    border: 1px solid #ccdce2;
    text-align: center;
    padding: 2.9rem 2.9rem 2rem 2.9rem;
    color: #677d85;
    border-radius: 4px 4px 4px 4px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: ${midBreakPoint}px) {
      height: auto;
    }
  }
  .level {
    font-size: 1.4rem;
  }

  .account-limit,
  .funding-limit {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    .amount {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 3rem;
      .dollar {
        font-size: 1.6rem;
        margin-right: 0.2rem;
      }
      &.header {
        color: #05c574;
      }
    }
    .note {
      font-size: 1.2rem;
    }
  }

  .funding-limit {
    .amount {
      font-size: 2.4rem;
      font-weight: normal;
      .dollar {
        font-size: 1.4rem;
        margin-top: 0.3rem;
      }
    }
  }

  .account-limit.active {
    .amount {
      color: #93afb9;
    }
  }
  .funding-limit.active {
    .amount {
      color: #04475c;
    }
  }

  .requirements {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 1.8rem;
    margin-bottom: 2rem;
  }
  .required-documentation {
    text-align: left;
    .ul > .li {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .li {
      &.incomplete {
        color: #677d85;
      }
      &.completed:before {
        content: '✓';
        margin-right: 0.8rem;
      }
      &.incomplete:before {
        content: '●';
        margin-right: 0.8rem;
      }
    }
  }

  &.current {
    box-shadow: 0 3px 40px 0 rgba(4, 71, 92, 0.1);
    .current-scheme {
      color: ${colors.gray.g1};
      background-color: #00c66f;
      @media (max-width: ${midBreakPoint}px) {
        display: block;
      }
    }
    .card {
      background-color: white;
      border-radius: 0 0 4px 4px;
      .required-documentation {
        color: #00ae76;
      }
    }
  }
`;

export default SchemeContainer;
