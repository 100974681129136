import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import { isNil } from 'utils';
import { messages, MAX_LENDING_AMOUNT } from 'config/constants';
import {
  toggleLendingStatus,
  addSingleToCart,
  authorizingRequisitionLend,
} from 'actions/cart/cartActions';
import { toCurrency, toCurrencyWithDecimals } from 'utils/numberFormatters';
import { restrictNumberTextInput } from 'utils/inputRestrictions';
import { showToast } from 'utils/toastHandler';
import SecondFactor from 'modules/SecondFactor';
import DotsLoader from 'components/DotsLoader';
import Button from 'components/Button/Button';
import carIcon from 'assets/carIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import { FeaturesContext, Feature } from 'utils/featureFlags';
import { t } from 'i18n';
import { Flex, Box } from 'styles';
import { Heading, Text } from 'components/Typography';
import SecondFactorModal from 'views/DashboardContent/Activate2FAWizard/SecondFactorModal';
import { useReduxQuery, useReduxSubscription } from 'utils/redux-query';

import LendingFormContainerRequisitionDetail, {
  AuthorizingLendingContainer,
  WarningLendingToast,
} from './styles';

const LendingFormRequisitionDetail = (props) => {
  const dispatch = useDispatch();
  const {
    onCloseButton, mobile, requisitionData, remaining, lentAmount,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [lendingAuthorization, setLendingAuthorization] = useState(false);
  const { toggleLendingStatus: sendingData } = useSelector((state) => state.cart);
  const [isLoadingAmount, setIsLoadingAmount] = useState(true);
  const [invalidInput, setInvalidInput] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const [secondFactor, { status: secondFactorStatus }] = useReduxQuery('SECOND_FACTOR');
  const [statements] = useReduxQuery('FETCH_ACCOUNT_STATEMENTS');
  const availableFunds = statements?.saldoDisponible;
  const [diversificationLimitAmounts, { refetch: refetchDiversification }] = useReduxSubscription('REQUISITIONS_DIVERSIFICATION');
  const limitAmount = diversificationLimitAmounts?.[requisitionData?.id?.toString()] || 0;
  // TODO remove after diversification beta has finish
  const { diversification } = useContext(FeaturesContext);
  const { id } = requisitionData;
  const handleInputChange = (event) => {
    const value = restrictNumberTextInput(event.target.value);
    setInputValue(value);
    setInvalidInput(false);
  };

  useEffect(() => {
    if (!statements) return;

    refetchDiversification();
  }, [statements]);

  useEffect(() => {
    setIsLoadingAmount(isNil(availableFunds));
  }, [availableFunds]);

  const validateInput = (value) => {
    let errorArray = [];
    const totalLending = parseInt(value, 10);
    const balanceInt = parseInt(availableFunds, 10);
    const getDiversificationMessage = (limit) => (availableFunds === 0 || limit !== 0
      ? `Diversifica mejor tus préstamos. Te recomendamos prestarle como máximo la cantidad de
        hasta ${toCurrency(limitAmount)}.`
      : 'Alcanzaste la cantidad máxima a prestar en esta solicitud');

    if (!value && value !== 0) return [messages.required];
    if (totalLending > balanceInt) { errorArray = [...errorArray, 'No cuentas con los fondos suficientes']; }
    if (totalLending > remaining) { errorArray = [...errorArray, 'La cantidad a prestar es mayor al restante del préstamo']; }
    if (value < 200 && lentAmount <= 0) { errorArray = [...errorArray, 'El valor debe ser mayor a $200']; }
    if (value < 100 && lentAmount > 0) { errorArray = [...errorArray, 'El valor debe ser mayor a $100']; }
    if (!(value % 100 === 0)) errorArray = [...errorArray, 'El valor debe ser multiplo de $100'];
    if (value > MAX_LENDING_AMOUNT) {
      errorArray = [
        ...errorArray,
        `El valor no debe ser mayor a ${toCurrency(MAX_LENDING_AMOUNT)}`,
      ];
    }
    if (diversification && value > parseInt(limitAmount, 10)) {
      errorArray = [...errorArray, getDiversificationMessage(limitAmount)];
    }
    setInvalidInput(!!errorArray.length);
    return errorArray;
  };

  const warningToast = (error) => (
    <WarningLendingToast>
      <WarningIcon title="" />
      <Text color="schema.yellow.500">{error}</Text>
    </WarningLendingToast>
  );

  const onLending = async (lendValue, e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    dispatch(toggleLendingStatus(true));
    const validations = validateInput(lendValue);
    if (!validations.length && !sendingData) {
      await dispatch(addSingleToCart({ id, lendValue }, false));
      setInputValue('');
    } else {
      validations.forEach((error) => showToast('warning', warningToast(error), {
        autoClose: error.substr(0, 11) !== 'Diversifica',
      }));
    }
    dispatch(toggleLendingStatus(false));
  };

  const showAuthorizing = () => {
    const validations = validateInput(parseInt(inputValue, 10));
    if (validations.length === 0) {
      setLendingAuthorization(true);
    } else {
      const firstError = validations[0];
      const errorString = firstError?.substr(0, 11) ?? '';
      showToast('warning', warningToast(firstError), {
        autoClose: errorString !== 'Diversifica',
      });
    }
  };

  const onAuthorizingLend = async (otp) => {
    const data = {
      amount: inputValue,
      id,
      otp,
    };
    await dispatch(authorizingRequisitionLend(data));
    setInputValue('');
    setLendingAuthorization(false);
  };

  return !lendingAuthorization ? (
    <LendingFormContainerRequisitionDetail isMobile={mobile}>
      {mobile && (
        <button type="button" className="chevron" onClick={onCloseButton}>
          &#x2304;
        </button>
      )}
      {!isLoadingAmount ? (
        <>
          <div className="available">
            <div>
              <p>Disponible:</p>
              <span>{toCurrencyWithDecimals(availableFunds || 0, true)}</span>
            </div>
          </div>
          <div className="borrowForm">
            <form onSubmit={(e) => onLending(parseInt(inputValue, 10), e)}>
              <p>Elige la cantidad a prestar</p>
              {sendingData ? (
                <div className="dotsLoader">
                  {' '}
                  <DotsLoader black />
                  {' '}
                </div>
              ) : (
                <Flex columnGap={{ base: '0.5rem', md: '1rem' }} justifyContent="space-between">
                  <Button
                    display="flex"
                    type="button"
                    $type="primary-outline"
                    px={{ base: '0.5rem', md: '1rem' }}
                    columnGap={{ base: '0.5rem', md: '1rem' }}
                    onClick={(e) => onLending(200, e)}
                    disabled={sendingData}
                  >
                    <img src={carIcon} alt="iconCar" />
                    {' '}
                    $200
                  </Button>
                  <Button
                    display="flex"
                    type="button"
                    $type="primary-outline"
                    px={{ base: '0.5rem', md: '1rem' }}
                    columnGap={{ base: '0.5rem', md: '1rem' }}
                    onClick={(e) => onLending(300, e)}
                    disabled={sendingData}
                  >
                    <img src={carIcon} alt="iconCar" />
                    {' '}
                    $300
                  </Button>
                  <Button
                    display="flex"
                    type="button"
                    $type="primary-outline"
                    px={{ base: '0.5rem', md: '1rem' }}
                    columnGap={{ base: '0.5rem', md: '1rem' }}
                    onClick={(e) => onLending(500, e)}
                    disabled={sendingData}
                  >
                    <img src={carIcon} alt="iconCar" />
                    {' '}
                    $500
                  </Button>
                </Flex>
              )}
              <Feature name="diversification">
                <Text
                  color="schema.gray.500"
                  fontSize="1.2rem"
                  lineHeight="1.8rem"
                  align="center"
                  mb="1rem"
                >
                  {limitAmount
                    ? t('listRequisition.diversification.limitAmount', {
                      limitAmount: toCurrency(limitAmount),
                    })
                    : t('listRequisition.diversification.limitReached')}
                </Text>
              </Feature>
              <div className="input">
                <p>
                  Otra
                  {' '}
                  <br />
                  {' '}
                  Cantidad
                </p>
                <Flex
                  position="relative"
                  mb="2rem"
                  className={cls('input__icon', { invalidInput })}
                >
                  <Text
                    as="abbr"
                    position="absolute"
                    top="1rem"
                    left="1rem"
                    color="schema.gray.500"
                  >
                    $
                  </Text>
                  <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    name="quantity"
                  />
                </Flex>
              </div>
              <Flex columnGap="1rem" justifyContent="space-between">
                <Button type="submit" disabled={sendingData} fontSize="1.4rem" px="1rem">
                  {sendingData ? <DotsLoader /> : 'Agregar a carrito'}
                </Button>
                <Button
                  $type="outline"
                  schema="blue"
                  type="button"
                  fontSize="1.4rem"
                  onClick={() => showAuthorizing()}
                  px="1rem"
                >
                  {t('orders.addOrder')}
                </Button>
              </Flex>
            </form>
          </div>
        </>
      ) : (
        <div className="loadingAmount">
          <p>Cargando monto disponible</p>
          <DotsLoader black />
        </div>
      )}
    </LendingFormContainerRequisitionDetail>
  ) : (
    <AuthorizingLendingContainer>
      <div className="amountBar">
        <p>
          Prestar:
          {' '}
          <b>{toCurrency(inputValue)}</b>
        </p>
      </div>
      {secondFactorStatus.completed && !secondFactor?.otpType && (
        <Box gridArea="2 / 1 / 2 / 3">
          <Heading as="h3" mb="1rem" textAlign="left">
            {t('orders.enable2FA_CTANotice.title')}
          </Heading>
          <Text mb="2rem">{t('orders.enable2FA_CTANotice.description')}</Text>
          <Button type="button" $type="primary" onClick={() => setShowWizard(true)}>
            {t('orders.enable2FA_CTANotice.okButton')}
          </Button>
        </Box>
      )}
      {secondFactorStatus.completed && secondFactor?.otpType && (
        <SecondFactor
          errors
          buttonText="Prestar"
          actionToAuthorize={(otp) => onAuthorizingLend(otp)}
          buttonColor="success"
        >
          <Button className="backButton" onClick={() => setLendingAuthorization(false)}>
            Regresar
          </Button>
        </SecondFactor>
      )}
      <SecondFactorModal
        isOpen={showWizard}
        onRequestClose={() => {
          setShowWizard(false);
        }}
      />
    </AuthorizingLendingContainer>
  );
};

export default LendingFormRequisitionDetail;
