import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'i18n';
import { useReduxMutator, useReduxQuery } from 'utils/redux-query';
import { PROOF_OF_ADDRESS_STATUS } from 'config/constants';
import { showToastWarn } from 'utils/toastHandler';
import validateToken from 'apis/auth/session/fetch';
import { useFileUploaderOptions } from 'components/UpdateAddressField';
import { useAddressForm } from './useAddressForm';
import { useZipCode } from './useZipCode';
import { getIsAddressCompleted } from '../../../utils/isAddressCompleted';

const onUpsertAddressError = () => showToastWarn(t('settings.limits.errors.updateAddressFailed'));

export const useController = ({
  onSubmit: onSubmitProp,
}) => {
  const history = useHistory();
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [session, { isLoading: isSessionLoading }] = useReduxQuery('SESSION');
  const [profileAddress, { silentFetch: refetchAddress }] = useReduxQuery('PROFILE_ADDRESS');
  const [catalogData] = useReduxQuery('CATALOG');
  const [createProfileAddress, { isLoading: isCreateAddressLoading }] = useReduxMutator('CREATE_PROFILE_ADDRESS', { onError: onUpsertAddressError });
  const [updateProfileAddress, { isLoading: isUpdateAddressLoading }] = useReduxMutator('UPDATE_PROFILE_ADDRESS', { onError: onUpsertAddressError });
  const statesCatalog = catalogData?.states;
  const personalId = session?.user?.personalId;
  const noDocumentoToMatch = personalId?.type !== 'national-id';
  const proofOfAddressApproved = session
    ?.user?.proofOfAddress?.status === PROOF_OF_ADDRESS_STATUS.APPROVED;
  const {
    status: proofOfAddressStatus,
    rejectReason: rejectingReason,
  } = session?.user?.proofOfAddress || {};
  const addressFieldProps = useFileUploaderOptions();
  const idAddress = session?.user?.personalId?.address || '';
  const displayAlert = !idAddress
    ? false
    : proofOfAddressStatus === PROOF_OF_ADDRESS_STATUS.REJECTED
  && noDocumentoToMatch;
  const displayForm = proofOfAddressApproved || !noDocumentoToMatch;
  const displayConfirm = proofOfAddressApproved;
  const isAddressCompleted = useMemo(() => getIsAddressCompleted(profileAddress));

  const onSubmitCallback = async () => {
    setIsSubmissionLoading(true);
    await refetchAddress();
    // it calls directly to the API:validate_token then api will send updated token by pusher signal
    // it is delayed since data longs a bit to be updated
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await validateToken();
    onSubmitProp();
    history.push('./show');
  };

  const {
    watch,
    control,
    setError,
    onSubmit,
    clearErrors,
    populateLocation,
  } = useAddressForm({
    statesCatalog,
    profileAddress,
    createProfileAddress,
    updateProfileAddress,
    proofOfAddressStatus,
    onSubmit: onSubmitCallback,
  });

  const zip = watch('zip');
  const onZipError = () => {
    setError('zip', { type: 'danger', message: t('Forms.fields.errors.invalidZipCode') });
  };
  const onZipCompleted = () => {
    clearErrors('zip');
  };
  const {
    location,
    isLoading: isZipCodeLoading,
  } = useZipCode(zip, { onError: onZipError, onCompleted: onZipCompleted });
  const neighborhoods = location?.neighborhoods;

  const isLoading = isCreateAddressLoading
  || isUpdateAddressLoading
  || addressFieldProps.isUploading
  || isZipCodeLoading
  || isSessionLoading
  || isSubmissionLoading;

  useEffect(() => {
    if (!location) return;
    populateLocation(location);
  });

  return {
    control,
    onSubmit,
    isLoading,
    displayForm,
    displayAlert,
    neighborhoods,
    displayConfirm,
    rejectingReason,
    isZipCodeLoading,
    addressFieldProps,
    isAddressCompleted,
    isSubmissionLoading,
  };
};
